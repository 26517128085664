import { DifferenceRelative } from './DifferenceRelative'

export type ComparisonInfo = {
  sales: number
  estimatedSalesTax: number
  nonCannabisSales: number
  totalSales: number
  totalDeposits: number
  differenceRelative: DifferenceRelative
  differenceAbsolute: number
}
