import { DateTime } from 'ui/components/DateTime'
import { FrequencyLabel, StartDateTypeLabel } from 'ui/components/Labels'
import { TableColumn } from 'ui/components/Table'

import { CheckMark } from '../../../../components/CheckMark'

import * as SC from './styles'

export const columns = (): TableColumn<any>[] => [
  {
    title: 'Document name',
    align: 'left',
    render: ({ name }) => name
  },
  {
    title: 'Active',
    align: 'center',
    render: ({ active }) => <CheckMark active={active} />
  },
  {
    title: 'Internal',
    align: 'center',
    render: ({ internal }) => <CheckMark active={internal} />
  },
  {
    title: 'Frequency',
    align: 'center',
    render: ({ frequency }) => <FrequencyLabel name={frequency} />
  },
  {
    title: (
      <>
        Start
        <br />
        date type
      </>
    ),
    align: 'center',
    render: ({ start_date_type }) => <StartDateTypeLabel name={start_date_type} />
  },
  {
    title: (
      <>
        Expiration
        <br />
        delay days
      </>
    ),
    key: 'expiration_delay_days',
    align: 'center'
  },
  {
    title: 'Type',
    align: 'center',
    render: ({ type }) => <SC.LevelLabel>{type}</SC.LevelLabel>
  },
  {
    title: 'MRB',
    align: 'center',
    render: ({ mrb }) => <CheckMark active={mrb} />
  },
  {
    title: (
      <>
        MRB
        <br />
        related
      </>
    ),
    align: 'center',
    render: ({ mrb_related }) => <CheckMark active={mrb_related} />
  },
  {
    title: 'Ancillary',
    align: 'center',
    render: ({ ancillary }) => <CheckMark active={ancillary} />
  },
  {
    title: 'Hemp',
    align: 'center',
    render: ({ hemp }) => <CheckMark active={hemp} />
  },
  {
    title: 'Investment',
    align: 'center',
    render: ({ investment }) => <CheckMark active={investment} />
  },
  {
    title: 'LLC',
    align: 'center',
    render: ({ llc }) => <CheckMark active={llc} />
  },
  {
    title: 'Corporation',
    align: 'center',
    render: ({ corporation }) => <CheckMark active={corporation} />
  },
  {
    title: 'Partnership',
    align: 'center',
    render: ({ partnership }) => <CheckMark active={partnership} />
  },
  {
    title: (
      <>
        Sole
        <br />
        proprietor
      </>
    ),
    align: 'center',
    render: ({ sole_proprietor }) => <CheckMark active={sole_proprietor} />
  },
  {
    title: 'Level',
    align: 'center',
    render: ({ level }) => <SC.LevelLabel>{level}</SC.LevelLabel>
  },
  {
    title: (
      <>
        License
        <br />
        medical
      </>
    ),
    align: 'center',
    render: ({ license_medical }) => <CheckMark active={license_medical} />
  },
  {
    title: (
      <>
        License
        <br />
        recreational
      </>
    ),
    align: 'center',
    render: ({ license_recreational }) => <CheckMark active={license_recreational} />
  },
  {
    title: (
      <>
        License
        <br />
        grower
      </>
    ),
    align: 'center',
    render: ({ license_grower }) => <CheckMark active={license_grower} />
  },
  {
    title: (
      <>
        License
        <br />
        dispensary
      </>
    ),
    align: 'center',
    render: ({ license_dispensary }) => <CheckMark active={license_dispensary} />
  },
  {
    title: (
      <>
        License
        <br />
        processor
      </>
    ),
    align: 'center',
    render: ({ license_processor }) => <CheckMark active={license_processor} />
  },
  {
    title: 'Created at',
    align: 'center',
    render: ({ created_at }) => <DateTime date={created_at} />
  },
  {
    title: 'Updated at',
    align: 'center',
    render: ({ updated_at }) => <DateTime date={updated_at} />
  },
  {
    title: 'State',
    align: 'center',
    render: ({ state }) => <SC.StateLabel>{state || '---'}</SC.StateLabel>
  },
  {
    title: 'Package',
    align: 'center',
    render: ({ package: package_name }) => <SC.LevelLabel>{package_name || '---'}</SC.LevelLabel>
  },
  {
    title: (
      <>
        Tribal
        <br />
        mrb
      </>
    ),
    align: 'center',
    render: ({ tribal_mrb }) => <CheckMark active={tribal_mrb} />
  },
  {
    title: (
      <>
        Pre license
        <br />
        mrb
      </>
    ),
    align: 'center',
    render: ({ pre_license_mrb }) => <CheckMark active={pre_license_mrb} />
  },
  {
    title: (
      <>
        Due start
        <br />
        date
      </>
    ),
    align: 'center',
    render: ({ due_start_date }) => <CheckMark active={due_start_date} />
  },
  {
    title: 'Optional',
    align: 'center',
    render: ({ optional }) => <CheckMark active={optional} />
  },
  {
    title: 'Management',
    align: 'center',
    render: ({ management }) => <CheckMark active={management} />
  },
  {
    title: (
      <>
        Consumer
        <br />
        account
      </>
    ),
    align: 'center',
    render: ({ consumer_account }) => <CheckMark active={consumer_account} />
  },
  {
    title: 'Trust',
    align: 'center',
    render: ({ trust }) => <CheckMark active={trust} />
  },
  {
    title: (
      <>
        Account
        <br />
        signer
      </>
    ),
    align: 'center',
    render: ({ account_signer }) => <CheckMark active={account_signer} />
  },
  {
    title: 'Owner',
    align: 'center',
    render: ({ owner }) => <CheckMark active={owner} />
  },
  {
    title: (
      <>
        Minimal
        <br />
        ownership
        <br />
        percent
      </>
    ),
    key: 'minimal_ownership_percent',
    align: 'center'
  },
  {
    title: (
      <>
        License
        <br />
        waste
        <br />
        disposal
      </>
    ),
    align: 'center',
    render: ({ license_waste_disposal }) => <CheckMark active={license_waste_disposal} />
  },
  {
    title: (
      <>
        License
        <br />
        delivery
      </>
    ),
    align: 'center',
    render: ({ license_delivery }) => <CheckMark active={license_delivery} />
  },
  {
    title: (
      <>
        License
        <br />
        operator
      </>
    ),
    align: 'center',
    render: ({ license_operator }) => <CheckMark active={license_operator} />
  }
]
