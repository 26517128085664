import { createContext, useContext } from 'react'

import { useForm } from '@tanstack/react-form'
import { Form } from '~bank-annual-review/types/Manage'

type ManageAnnualReviewContextValue = {
  formApi: ReturnType<typeof useForm<Form, undefined>>
} | null

export const ManageAnnualReviewContext = createContext<ManageAnnualReviewContextValue>(null)

export const useAnnualReviewFormContext = () => {
  const context = useContext(ManageAnnualReviewContext)
  if (!context) {
    throw new Error('useAnnualReviewFormContext must be used within a AnnualReviewContext.Provider')
  }
  return context
}
