import { memo } from 'react'

import SC from 'styled-components'

import { CheckMark } from '../../CheckMark'
import { Tooltip } from '../../Tooltip'

interface Props {
  text: string
  value: string
  disabled?: boolean
  title?: string
  checked: boolean
  onClick: (value: string, disabled?: boolean) => any
}

const Option = SC.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 20px 7px 10px;
  white-space: nowrap;
  font-size: 13px;
  gap: 5px;
 
  &:hover {
    background: #f5f5f5;
  }
`

const DisabledOption = SC.span`
  cursor: not-allowed;
  color: #b8b8b8;
`

export const OptionLicenseItem = memo((props: Props) => {
  const { text, value, checked, disabled, title, onClick } = props
  const onOptionClick = () => {
    onClick(value, disabled)
  }

  return (
    <Option onClick={onOptionClick} role="option" data-qa={`Assigned Licenses - ${text}`}>
      <div style={{ width: '20px' }}>
        <CheckMark value={checked} rounded={false} />
      </div>
      {title ? (
        <Tooltip id={`hint${value}`} type="warning" content={title} event="hover">
          {disabled ? <DisabledOption>{text}</DisabledOption> : <span>{text}</span>}
        </Tooltip>
      ) : disabled ? (
        <DisabledOption>{text}</DisabledOption>
      ) : (
        <span>{text}</span>
      )}
    </Option>
  )
})
