import { useEffect, useMemo } from 'react'

import { UseSelectOptions } from '../types'
import { useCounter } from './useCounter'
import { useFetch } from './useFetch'
import { useInteractions } from './useInteractions'
import { useKeyboardNav } from './useKeyboardNav'
import { useManageState } from './useManageState'
import { useRequired } from './useRequired'
import { useTyping } from './useTyping'

export const useSelect = (options: UseSelectOptions) => {
  const {
    selectedValues: _selectedValues,
    data: _data,
    listRef,
    inputRef,
    serverOptions,
    required,
    error,
    disabled,
    mode,
    searchable
  } = options

  const {
    data,
    setData,
    searchText,
    setSearchText,
    isSearching,
    setIsSearching,
    activeIndex,
    setActiveIndex,
    selectedValues,
    selectValue,
    unselectValue,
    clearValues,
    selectedOptions,
    modelData,
    setModelData,
    open,
    handleOpen,
    handleClose,
    inputValue
  } = useManageState(_data ?? [], _selectedValues, serverOptions !== undefined, inputRef)

  /* reflect active index changing */
  useEffect(() => {
    const activeItem = modelData.find((el) => el.active == true)
    const item = modelData.find((el) => el.index == activeIndex)
    if (activeItem) activeItem.active = false
    if (item) item.active = true
    setModelData([...modelData])
    // run effect only for sync active index state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  useEffect(() => {
    listRef.current = listRef.current.slice(0, data.length)
    // run effect on data change only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  /* for internal use */
  const context = useMemo(
    () => ({
      open,

      data,
      setData,

      modelData,
      setModelData,

      selectValue,
      unselectValue,
      selectedValues,
      clearValues,

      activeIndex,
      setActiveIndex,

      searchText,
      setSearchText,
      setIsSearching,

      required,
      error,
      disabled,
      searchable
    }),
    [
      open,
      data,
      setData,
      modelData,
      setModelData,
      selectValue,
      unselectValue,
      selectedValues,
      clearValues,
      activeIndex,
      setActiveIndex,
      searchText,
      setSearchText,
      setIsSearching,
      required,
      error,
      disabled,
      searchable
    ]
  )

  const { count } = useCounter(context)
  const { isShowRequired, isEmpty } = useRequired(context)

  useFetch(context, { serverOptions })

  const { getReferenceProps, getOptionProps, getInputProps } = useInteractions([
    useTyping(context, { isPreferServerData: serverOptions !== undefined }),
    useKeyboardNav(context, { mode, inputRef, listRef })
  ])

  return {
    open,
    data,
    modelData,
    count,
    selectedValues,
    selectedOptions,
    activeIndex,
    searchText,
    isSearching,
    isShowRequired,
    isEmpty,

    getReferenceProps,
    getOptionProps,
    getInputProps,

    selectValue,
    unselectValue,
    clearValues,
    handleOpen,
    handleClose,

    inputValue
  }
}
