import { FC } from 'react'

import { Link, getRouteApi, useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { ButtonGroup, IconName } from 'ui/components'
import { Button, IconButton } from 'ui/components/Buttons'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { useDeleteSalesDepositConfirmation, useRecalculateSalesDeposits } from '~bank-annual-review/hooks'
import { SalesDepositItem } from '~bank-annual-review/types/AnnualReviewDetails'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

export const Actions: FC<SalesDepositItem> = ({
  bankAccountId,
  enableRecalculate,
  id: salesAndDepositId
}: SalesDepositItem) => {
  const { id: annualReviewId, companyId } = routeApi.useParams()

  const { DeleteConfirmation, openDeleteSalesDepositConfirmation } = useDeleteSalesDepositConfirmation({})

  const router = useRouter()

  const { routine: recalculateSalesDeposit } = useRecalculateSalesDeposits({
    onSuccess: async () => {
      router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  return (
    <>
      <DeleteConfirmation />
      <ButtonGroup margin="big">
        <IconButton
          face="negative"
          icon={IconName.DELETE}
          onClick={() => openDeleteSalesDepositConfirmation(Number(salesAndDepositId))}
        >
          Delete
        </IconButton>
        <Link
          to={`/annual-review/edit-sales-and-deposit/$companyId/$annualReviewId/$salesAndDepositId`}
          params={{
            companyId,
            annualReviewId,
            salesAndDepositId
          }}
          search={{ bankAccountId }}
        >
          <Button face="positive">Edit</Button>
        </Link>
        {enableRecalculate && (
          <Button face="positive" onClick={() => recalculateSalesDeposit(salesAndDepositId)}>
            Recalculate
          </Button>
        )}
      </ButtonGroup>
    </>
  )
}
