import { FF, SecuredInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const TIN = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].tin`}
      children={(field) => (
        <FF field={field} label="a. Institution TIN" input={(p) => <SecuredInput {...p} maxLength={25} />} />
      )}
    />
  )
}
