import { LabelValue, Value } from '../../styles'
import { DebtholderProps } from './DebtholderProps'

export const Debtholder = ({ contact }: DebtholderProps) => {
  const { isCompanyDebtholder } = contact
  return (
    <>
      <LabelValue>
        <div>
          Is this contact the <b>Debtholder</b>?
        </div>
        <Value>{isCompanyDebtholder ? 'Yes' : 'No'}</Value>
      </LabelValue>
    </>
  )
}
