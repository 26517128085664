import { forwardRef, memo, useCallback } from 'react'

import { CheckBox } from 'ui/components/CheckBox'

import { OptionProps } from '../../types'

import * as SC from './styles'

export const Option = memo(
  forwardRef<HTMLDivElement, OptionProps>((props: OptionProps, ref) => {
    const { label, value, selected, active, onClick, 'data-qa': dataQa, ...rest } = props

    const handleClick = useCallback(() => {
      onClick(value)
    }, [onClick, value])

    return (
      <SC.Option
        $active={active}
        role="option"
        onClick={handleClick}
        data-qa={dataQa}
        tabIndex={-1}
        ref={ref}
        {...rest}
      >
        <CheckBox value={selected} checkBoxLabel={label} />
      </SC.Option>
    )
  })
)
