import { FC, memo } from 'react'

import { IconName } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button, IconButton } from 'ui/components/Buttons'
import { Tooltip } from 'ui/components/Tooltip'

import { FooterProps } from './FooterProps'

import * as SC from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const {
    onSetRiskLevel,
    needsReviewCount,
    selectedPublicationsLength,
    onMarkAsReviewed,
    onSelectAllUnreviewed,
    onDeleteAllUnreviewed,
    isReviewButtonVisible
  } = props
  const isSomePublicationNeedsReview = (needsReviewCount || 0) > 0

  const onSelectAll = () => {
    onSelectAllUnreviewed()
  }

  const onMarkSelectedAsReviewed = () => {
    onMarkAsReviewed()
  }

  const DeleteAllUnreviewed = () => {
    onDeleteAllUnreviewed()
  }

  return (
    <SC.Box>
      <SC.Left>
        {isSomePublicationNeedsReview && (
          <SC.WrappedCheckBox
            value={needsReviewCount === selectedPublicationsLength && selectedPublicationsLength > 0}
            onChange={onSelectAll}
            checkBoxLabel="Select All"
          />
        )}
        {!isReviewButtonVisible ? null : selectedPublicationsLength < 1 ? null : (
          <IconButton onClick={onMarkSelectedAsReviewed} face="positive" icon={IconName.CHECK_WHITE}>
            Review Selected
          </IconButton>
        )}
      </SC.Left>
      <SC.Right>
        {isSomePublicationNeedsReview ? (
          <ButtonGroup margin="big">
            <IconButton onClick={DeleteAllUnreviewed} face="negative" icon={IconName.DELETE}>
              Delete All Unreviewed
            </IconButton>
            <Tooltip id="risk-level-btn-tip" type="dark" content="You have unreviewed news" event="hover">
              <Button face="positive" onClick={onSetRiskLevel} disabled>
                Set Risk Level
              </Button>
            </Tooltip>
          </ButtonGroup>
        ) : (
          <Button face="positive" onClick={onSetRiskLevel} data-qa="set-risk-level">
            Set Risk Level
          </Button>
        )}
      </SC.Right>
    </SC.Box>
  )
})
