import { FC, memo } from 'react'

import { DISABLED, RECEIVING_ONLY, SENDING_AND_RECEIVING, SENDING_ONLY } from 'commons/constants/internalTransfers'
import { FF, Info } from 'ui/components'
import { DatePicker } from 'ui/components/DatePicker'
import { Validators } from 'ui/components/Form/validators'
import { SecuredInput, TextareaInput } from 'ui/components/InputV2'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Panel } from 'ui/components/Panel'
import { MultiSelect, Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { FormProps } from './Form.types'

import { s } from './styles'

export const Form: FC<FormProps> = memo((props) => {
  const { licenses, companyName, formApi, isSubmitting, isSuccess } = props
  const { useStore, Field } = formApi
  const isDirty = useStore((s) => s.isDirty)

  return (
    <LeaveConfirmation preventLeaving={isDirty && !isSubmitting && !isSuccess}>
      <Panel rounded shadowed collapsible={false} title="Edit General information">
        <s.Inner>
          <FormRow>
            <Col>
              <Info label="Relationship Name">{companyName}</Info>
            </Col>
            <Col>
              <Field
                name="number"
                validators={{ onChange: ({ value }) => Validators.bankAccountNumber.notEmptyAndValid(value) }}
                children={(field) => (
                  <FF
                    required
                    field={field}
                    label="Account Number"
                    input={(p) => <SecuredInput data-lpignore {...p} data-qa="Account Number" />}
                  />
                )}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <s.DateBox>
                <Field
                  name="openingDate"
                  validators={{ onChange: ({ value }) => Validators.required.field(value) }}
                  children={(field) => (
                    <FF
                      required
                      field={field}
                      label="Opening Date"
                      input={(p) => <DatePicker {...p} data-qa="data-picker-input" />}
                    />
                  )}
                />
              </s.DateBox>
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="internalTransfersType"
                children={(field) => (
                  <FF
                    field={field}
                    label="Participation in the Transfers program"
                    input={(p) => (
                      <Select
                        {...p}
                        data-qa="Participation in the Transfers program"
                        options-data-qa="Participation in the Transfers program"
                        data={[
                          { label: 'Disabled', value: DISABLED },
                          { label: 'Sending & Receiving', value: SENDING_AND_RECEIVING },
                          { label: 'Sending Only', value: SENDING_ONLY },
                          { label: 'Receiving Only', value: RECEIVING_ONLY }
                        ]}
                      />
                    )}
                  />
                )}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="licenseIds"
                children={(field) => (
                  <FF label="Assigned Licenses" field={field} input={(p) => <MultiSelect {...p} data={licenses} />} />
                )}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col2x>
              <Field
                name="notes"
                children={(field) => (
                  <FF
                    field={field}
                    label="Notes"
                    input={(p) => <TextareaInput {...p} data-qa="Notes" maxLength={1000} />}
                  />
                )}
              />
            </Col2x>
          </FormRow>
        </s.Inner>
      </Panel>
    </LeaveConfirmation>
  )
})
