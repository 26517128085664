import styled from 'styled-components'
import { DownloadLink } from 'ui/components/DownloadLink'

/* TODO: find correct version of this button */
export const CSVButton = styled(DownloadLink)`
  background: #fff;
  padding: 0 32px;

  &:hover {
    background: #fff;
  }
`

export const SalesReportTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 40px;
`
