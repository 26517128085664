import { FC, memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'
import { Page } from 'ui/components/Page'
import { Search } from 'ui/components/Search'
import { DocumentsPaths } from '~bank-documents/constants'

import { DocumentListTable } from '../../components/DocumentListTable'
import { useDocumentList } from './useDocumentList'

const DocumentList: FC = memo(() => {
  const state = useDocumentList()
  const { search, initFilters, externalTableState, setSearch, alerts, onFilterChange } = state

  return (
    <Page
      title={alerts ? 'My Documents' : 'Documents'}
      actions={
        <ButtonGroup margin="small">
          <Search onSearch={setSearch} value={search} placeholder="Search" />
          <Link to={DocumentsPaths.NEW}>
            <HeaderButton size="middle">Add New</HeaderButton>
          </Link>
        </ButtonGroup>
      }
    >
      <DocumentListTable
        dataQa={TableDataQa.BP_DOCUMENTS_LIST}
        tableId="document-list-page"
        initFilters={initFilters}
        externalTableState={externalTableState}
        returnUrl={DocumentsPaths.LIST}
        onFilterChange={onFilterChange}
      />
    </Page>
  )
})

export default DocumentList
