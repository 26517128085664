import { memo } from 'react'

import SC from 'styled-components'

interface Props {
  label: string
  value: string
}

const Row = SC.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 22px;
`

const Label = SC.div`
  font-style: italic;
  margin-right: 32px;
`

const Value = SC.div`
  flex-grow: 1;
`
export const TextLine = memo(({ label, value }: Props) => (
  <Row>
    <Label>{label}</Label>
    <Value>{value}</Value>
  </Row>
))
