import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const IdNumber = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((state) => state.values.institutionActivitiesInfo[index1].noBranchInvolved)

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `institutionActivitiesInfo[${index1}].institutionActivityBranchesInfo[${index2}].idNumber` as 'institutionActivitiesInfo[0].institutionActivityBranchesInfo[0].idNumber'
      }
      children={(field) => (
        <FF field={field} label="70. RSSD Number" input={(p) => <TextInput {...p} disabled={noBranchInvolved} />} />
      )}
    />
  )
}
