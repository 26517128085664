import { useMutation, useQueryClient } from 'react-query'

import { Toast } from 'ui/components/Toast'

import * as Service from '../../service'

export const useSave = () => {
  const queryClient = useQueryClient()

  return useMutation((opts: { id: number }) => Service.SAR.sarAcknowledgeWarnings(opts), {
    onError: ({ message }: { message: string }) => {
      Toast.errorSmall(message)
    },
    onSuccess: async (_, item) => {
      await queryClient.invalidateQueries(['sar-details', { historyPage: 1, historySize: 10, id: item.id }])
    }
  })
}
