import { memo } from 'react'

import styled from 'styled-components'

import { Button } from '../Buttons'

const Box = styled.div`
  background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  padding: 60px;
`

const Message = styled.h1`
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: 44px;
  letter-spacing: 0.6px;
  margin: 0;
`

export const ErrorDetails = memo(() => {
  return (
    <Box>
      <Message>Sorry, it looks like a system error.</Message>
      <a href="/">
        <Button face="positive">Home Page</Button>
      </a>
    </Box>
  )
})
