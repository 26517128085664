import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const InternalControlNumber = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `institutionActivitiesInfo[${index}].internalControlNumber` as 'institutionActivitiesInfo[0].internalControlNumber'
      }
      children={(field) => (
        <FF label="66. Internal control/file number" field={field} input={(p) => <TextInput {...p} maxLength={50} />} />
      )}
    />
  )
}
