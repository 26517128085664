import React, { Suspense, lazy } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

const questionnaireComponents = {
  questionnaire1: lazy(() => import('./components/Questionnaire1').then((m) => ({ default: m.Questionnaire1 }))),
  questionnaire2: lazy(() => import('./components/Questionnaire2').then((m) => ({ default: m.Questionnaire2 }))),
  questionnaire3: lazy(() => import('./components/Questionnaire3').then((m) => ({ default: m.Questionnaire3 }))),
  questionnaireDemo: lazy(() =>
    import('./components/QuestionnaireDemo').then((m) => ({ default: m.QuestionnaireDemo }))
  )
}

export const Questionnaire: React.FC = () => {
  const loaderData = getRouteApi(AnnualReviewPaths.DETAILS).useLoaderData({ select: (s) => s }) as AnnualReviewDetails

  let schemaName = ''
  if (loaderData?.showQuestionnaire1) {
    schemaName = 'questionnaire1'
  } else if (loaderData?.showQuestionnaire2) {
    schemaName = 'questionnaire2'
  } else if (loaderData?.showQuestionnaire3) {
    schemaName = 'questionnaire3'
  } else if (loaderData?.showQuestionnaireDemo) {
    schemaName = 'questionnaireDemo'
  }

  const QuestionnaireView = questionnaireComponents[schemaName]

  return (
    <Suspense fallback={<div>Loading questionnaire...</div>}>
      {QuestionnaireView && (
        <div id="annual-review-q">
          <QuestionnaireView />
        </div>
      )}
    </Suspense>
  )
}
