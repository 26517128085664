import { OnboardingWebPageResponse } from 'commons/types/DTO/commons'

export const selectCompany = (companies: OnboardingWebPageResponse['companies'] | undefined, companyIndex: number) =>
  companies ? companies[companyIndex] : null

export const selectQuestionnaire = (
  companies: OnboardingWebPageResponse['companies'] | undefined,
  companyIndex: number
) => selectCompany(companies, companyIndex)?.questionnaire

export const selectLicenses = (companies: OnboardingWebPageResponse['companies'] | undefined, companyIndex: number) =>
  selectCompany(companies, companyIndex)?.licenses || []

export const selectContacts = (companies: OnboardingWebPageResponse['companies'] | undefined, companyIndex: number) =>
  selectCompany(companies, companyIndex)?.contacts || []

export const selectATMs = (companies: OnboardingWebPageResponse['companies'] | undefined, companyIndex: number) =>
  selectQuestionnaire(companies, companyIndex)?.ATMs
