import { Suspense, useCallback, useState } from 'react'

import { Outlet, useRouterState } from '@tanstack/react-router'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { LoadingCallbacksContext } from 'commons/context/loadingCallbacksContext'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

// eslint-disable-next-line max-len
import { ManageCompanyFormContextProvider } from './Routes/ManageCompany/components/ManageCompanyPage/components/ManageCompanyForm'
import { CompanySideBar } from './components/CompanySideBar'

const BankCompaniesWrapper = () => {
  const [sidebarActive, setSidebarActive] = useState(true)
  const all = useRouterState()
  const pathname = all.location?.pathname

  // TODO: how we change it? maybe use context? or layout for each page?
  const showSidebar =
    pathname.startsWith(`${COMPANIES_PATH}/`) &&
    pathname !== COMPANIES_PATH &&
    pathname !== `${COMPANIES_PATH}/` &&
    pathname.indexOf(`${COMPANIES_PATH}/edit`) === -1 &&
    pathname.indexOf(`${COMPANIES_PATH}/create`) === -1

  const loadingIsBeginning = useCallback(() => {
    setSidebarActive(false)
  }, [])

  const loadingHasEnded = useCallback(() => {
    setSidebarActive(true)
  }, [])

  return (
    <LoadingCallbacksContext.Provider
      value={{
        loadingIsBeginningCallback: loadingIsBeginning,
        loadingHasEndedCallback: loadingHasEnded
      }}
    >
      <BPLayout sidebar={showSidebar ? <CompanySideBar active={sidebarActive} /> : undefined}>
        <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
          <ManageCompanyFormContextProvider>
            <Outlet />
          </ManageCompanyFormContextProvider>
        </Suspense>
      </BPLayout>
    </LoadingCallbacksContext.Provider>
  )
}

export default BankCompaniesWrapper
