import { useCallback, useMemo } from 'react'

import { useFormData } from 'brief-form'
import { LicenseWebPageDocumentFilter } from 'commons/types/DTO/bank'

import { FilterProps } from '../FilterProps'

export const useFilter = (props: FilterProps) => {
  const { webPage, onChange } = props
  const { documentsFilter } = webPage.config

  const initialFilter: LicenseWebPageDocumentFilter = useMemo(
    () => ({
      alerts: documentsFilter.alerts,
      frequency: documentsFilter.frequency,
      internal: documentsFilter.internal ?? null,
      page: documentsFilter.page,
      size: documentsFilter.size,
      sortBy: documentsFilter.sortBy,
      sortDesc: documentsFilter.sortDesc
    }),
    []
  )

  const form = useFormData<LicenseWebPageDocumentFilter>({
    initialValue: initialFilter,
    onFormChanged: onChange
  })
  const { isDirty, config, Field, set } = form

  const reset = useCallback(() => {
    set({ reset: true })
    onChange(initialFilter)
  }, [set, onChange])

  return {
    isDirty,
    config,
    Field,
    reset
  }
}
