import { AnyRoute, Outlet, ScrollRestoration, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import {
  ContactAddDialogContainer,
  ContactEditDialogContainer
} from 'bank-contacts/src/containers/ContactMetadataManage'

const CompanyDetails = lazyRouteComponent(() => import('./CompanyDetails'))

const contactAddRoute = (route) =>
  createRoute({
    getParentRoute: () => route,
    path: `/contact/add/$contactId`,
    component: ContactAddDialogContainer
  })

const contactEditRoute = (route) =>
  createRoute({
    getParentRoute: () => route,
    path: `/contact/edit/$contactId`,
    component: ContactEditDialogContainer
  })

export const companyDetailsRoute = <T extends AnyRoute>(indexRoute: T) => {
  const route = createRoute({
    getParentRoute: () => indexRoute,
    path: `$companyId`,
    component: () => (
      <>
        <Outlet />
        <CompanyDetails />
        <ScrollRestoration getKey={(location) => location.href} />
      </>
    )
  })

  return route.addChildren([contactEditRoute(route), contactAddRoute(route)])
}
