import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Value = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((s) => s.values.suspiciousActivityInfo.cyberEventIndicators[index].type)
  const hasType = !!type

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `suspiciousActivityInfo.cyberEventIndicators[${index}].value` as 'suspiciousActivityInfo.cyberEventIndicators[0].value'
      }
      validators={{ onChange: ({ value }) => (hasType ? Validators.required.field(value) : undefined) }}
      children={(field) => (
        <FF
          required={hasType}
          label="Event Value"
          field={field}
          input={(p) => <TextInput {...p} disabled={!hasType} />}
        />
      )}
    />
  )
}
