import { FC, memo, useCallback, useState } from 'react'

import { Button } from 'ui/components/Buttons'
import { CheckBox } from 'ui/components/CheckBox'
import { Dialog, DialogFace } from 'ui/components/Dialog'

import { CustomerAcknowledgementDialogProps } from './CustomerAcknowledgementDialogProps'

import * as SC from './styles'

export const CustomerAcknowledgementDialog: FC<CustomerAcknowledgementDialogProps> = memo((props) => {
  const { id, visible, onClick } = props
  const [isAccepted, setIsAccepted] = useState(false)
  const onChange = useCallback(() => setIsAccepted(!isAccepted), [isAccepted, setIsAccepted])

  if (!visible) {
    return null
  }

  return (
    <Dialog id="customer-acknowledgement" face={DialogFace.DEFAULT} title="Customer Acknowledgement">
      <SC.Container>
        <SC.Content>
          <SC.Text>Customer Attestation to the Cole Memo Priorities set forth by the Department of Justice:</SC.Text>
          <ul>
            <li>Preventing the distribution of marijuana to minors;</li>
            <li>
              Preventing revenue from the sale of marijuana from going to criminal enterprises, gangs, and cartels;
            </li>
            <li>
              Preventing the diversion of marijuana from states where it is legal under state law in some form to other
              states;
            </li>
            <li>
              Preventing state-authorized marijuana activity from being used as a cover or pretext for the trafficking
              of other illegal drugs or other illegal activity;
            </li>
            <li>Preventing violence and the use of firearms in the cultivation and distribution of marijuana;</li>
            <li>
              Preventing drugged driving and the exacerbation of other adverse public health consequences associated
              with marijuana use;
            </li>
            <li>
              Preventing the growing of marijuana on public lands and the attendant public safety and environmental
              dangers posed by marijuana production on public lands; and
            </li>
            <li>Preventing marijuana possession or use on federal property.</li>
          </ul>
        </SC.Content>
        <SC.Content>
          <SC.Text>Customer Attestation to Bank Policies and Procedures:</SC.Text>
          <ul>
            <li>Employee screening process;</li>
            <li>Due diligence on consumers/documentation requirements;</li>
            <li>Filing of Form 8300s;</li>
            <li>Physical Security;</li>
            <li>Process for purchasing cannabis (if applicable), and list of suppliers; and</li>
            <li>Compliance with any other regulatory requirements.</li>
          </ul>
        </SC.Content>
        <SC.Content>
          <SC.Text>Account Compliance Expectations:</SC.Text>
          <ul>
            <li>
              Authorization for bank personnel to conduct on site facility visits will be granted. (Personnel will
              identify themselves by state issued ID as well as a bank business card)
            </li>
            <li>I will provide updated license information as issued or requested by the Bank.</li>
            <li>I will provide updated filings with the Secretary of State as issued or requested by the Bank.</li>
            <li>
              I will provide updated sales logs or state inventory control system access as issued or requested by the
              Bank.
            </li>
            <li>I will provide current state licensing inspection reports as issued or requested by the Bank.</li>
            <li>Any amendments or change of ownership will be communicated with the Bank.</li>
          </ul>
        </SC.Content>
        <SC.Text>
          I/We certify that our business will not engage in any activity that interferes with any one or more of the
          above-mentioned priorities. I/We certify that our business has and follows written policies and procedures for
          the above-mentioned areas. I/We certify that the business will comply with the account compliance
          expectations.
        </SC.Text>
        <SC.CTRGuideContainer>
          <h3>A CTR Reference Guide</h3>
          <SC.QuestionBlock>
            <SC.Question>
              Why is my financial institution asking me for identification and personal information?
            </SC.Question>
            <span>
              Federal law requires financial institutions to report currency (cash or coin) transactions over $10,000
              conducted by, or on behalf of, one person as well as multiple currency transactions that aggregate to be
              over $10,000 in a single day. These transactions are reported on Currency Transaction Reports (CTRs). The
              federal law requiring these reports was passed to safeguard the financial industry from threats posed by
              money laundering and other financial crime. To comply with this law, financial institutions must obtain
              personal identification information about the individual conducting the transaction such as a Social
              Security number as well as a driver’s license or other government issued document. This requirement
              applies whether the individual conducting the transaction has an account relationship with the institution
              or not.
            </span>
            <span>
              There is no general prohibition against handling large amounts of currency and the filing of a CTR is
              required regardless of the reasons for the currency transaction. The financial institution collects this
              information in a manner consistent with a customer’s right to financial privacy.
            </span>
          </SC.QuestionBlock>
          <SC.QuestionBlock>
            <SC.Question>
              Can I break up my currency transactions into multiple, smaller amounts to avoid being reported to the
              government?
            </SC.Question>
            <span>
              No. This is called “structuring”. Federal law makes it a crime to break up transactions into smaller
              amounts for the purpose of evading the CTR reporting requirement, and this may lead to a required
              disclosure from the financial institution to the government. Structuring transactions to prevent a CTR
              from being reported can result in imprisonment for not more than five years and/or a fine of up to
              $250,000. If structuring involves more than $100,000 in a twelve-month period or is performed while
              violating another law of the United States, the penalty is doubled.
            </span>
          </SC.QuestionBlock>

          <SC.Text>The following scenarios are examples of structuring.</SC.Text>
          <SC.Content>
            <SC.Text>Examples of Structured Transactions</SC.Text>
            <ul>
              <li>
                John has $15,000 in cash he obtained from selling his truck. John knows that if he deposits $15,000 in
                cash, his financial institution will be required to file a CTR. John instead deposits $7,500 in cash in
                the morning with one financial institution employee and comes back to the financial institution later in
                the day to another employee to deposit the remaining $7,500, hoping to evade the CTR reporting
                requirement.
              </li>
              <li>
                Jane needs $18,000 in cash to pay for supplies for her woodcarving business. Jane cashes a $9,000
                personal check at a financial institution on a Monday, then cashes another $9,000 personal check at the
                financial institution the following day. Jane cashed the checks separately and structured the
                transactions in an attempt to evade the CTR reporting requirement.
              </li>
              <li>
                Bob wants to place $24,000 cash he earned from his illegal activities into the financial system by using
                a wire transfer. Bob knows his financial institution will file a CTR if he purchases a wire with over
                $10,000 currency in one day. To evade the CTR reporting requirement, Bob wires the $24,000 by purchasing
                wires with currency in $6,000 increments over a short period of time, occasionally skipping days in an
                attempt to prevent the financial institution from filing a CTR.
              </li>
            </ul>
          </SC.Content>
          <CheckBox
            value={isAccepted}
            checkBoxLabel="I acknowledge and certify the expectations provided."
            onChange={() => onChange()}
          />
        </SC.CTRGuideContainer>
        <Button face="positive" disabled={!isAccepted} onClick={() => onClick(id)}>
          Submit
        </Button>
      </SC.Container>
    </Dialog>
  )
})
