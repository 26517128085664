import { PageSection, PageSectionFace } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { FormRow } from 'ui/themes/globalStyles'

import { emptySAR } from '../../../../../../../constants'
import { useSARFormContext } from '../../../../context'
import { TransactionInfo } from './TransactionInfo'

export const TransactionsInfo = () => {
  const { formApi } = useSARFormContext()

  return (
    <PageSection face={PageSectionFace.THIRD} title="Transactions Information" anchor="transactions-info">
      <formApi.Field name="transactionsInfo" mode="array">
        {(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {field.state.value.map((_, index) => (
                <TransactionInfo key={index} index={index} total={field.state.value.length} field={field} />
              ))}
              <FormRow>
                <Button
                  face="positive"
                  onClick={() => {
                    field.pushValue(emptySAR.transactionsInfo[0])
                  }}
                >
                  Add
                </Button>
              </FormRow>
            </>
          )
        }}
      </formApi.Field>
    </PageSection>
  )
}
