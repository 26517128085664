import { FC } from 'react'

import { Form } from 'brief-form'
import { Validators } from 'ui/components'
import { MetrcKeyInput } from 'ui/components/InputV2'
import { Col } from 'ui/themes/globalStyles'

import { SetupKeyFormProps } from './SetupKeyFormProps'

import { s } from './styles'

export const SetupKeyForm: FC<SetupKeyFormProps> = ({ config, Field, isPending }) => (
  <s.Wrapper $disabled={isPending}>
    <s.TitleWrapper>
      <s.Title>Please Add Metrc Key</s.Title>
      <s.SubTitle>One Metrc key may work for multiple licenses.</s.SubTitle>
    </s.TitleWrapper>
    <Form config={config}>
      <Col>
        <Field
          required
          name="key"
          label="METRC Key"
          input={MetrcKeyInput}
          inputProps={{
            'data-qa': 'METRC Key',
            autoFocus: true,
            disabled: isPending
          }}
          validator={Validators.required.field}
        />
      </Col>
    </Form>
  </s.Wrapper>
)
