import { FC } from 'react'

import { useForm } from '@tanstack/react-form'
import { useCancelOnboardingDocument } from 'commons/hooks/bank/onboardingSetupDocuments'
import { handlerServerError } from 'errors'
import { Dialog, DialogFace, DialogFooterActions, FF, TextareaInput, Toast, Validators } from 'ui/components'

import { CancelEsignLinkDocumentDialogProps } from './CancelEsignLinkDocumentDialog.types'

import { s } from './styles'

export const CancelEsignLinkDocumentDialog: FC<CancelEsignLinkDocumentDialogProps> = ({ id, onSuccess, onClose }) => {
  const { routine: cancelOnboardingDocument } = useCancelOnboardingDocument({
    onSuccess: async () => {
      onSuccess()
      onClose()
      Toast.successSmall(`The eSign Link for set of documents ID-${id} already canceled`)
    },
    onError: (error) => handlerServerError(error)
  })

  const form = useForm({
    defaultValues: { cancelReason: '' },
    onSubmit: async ({ value }) =>
      await cancelOnboardingDocument({ appDocusignEnvelopeId: id, cancelReason: value.cancelReason })
  })

  return (
    <Dialog
      face={DialogFace.PRIMARY}
      id="cancel-esign-link-onboarding-document-esign-dialog"
      onClose={onClose}
      title="Cancel eSign Link"
      onSubmit={form.handleSubmit}
      footerActions={[DialogFooterActions.SUBMIT, DialogFooterActions.CANCEL]}
    >
      <s.Inner>
        <form.Field
          name="cancelReason"
          validators={{
            onChange: ({ value }) => Validators.required.field(value)
          }}
          children={(field) => (
            <FF label="Cancel Reason" field={field} input={(props) => <TextareaInput {...props} />} />
          )}
        />
      </s.Inner>
    </Dialog>
  )
}
