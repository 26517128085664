import { getBatchFileName } from 'commons/utils'
import { Request } from 'commons/utils/request'
import { IconButton } from 'ui/components/Buttons'
import { DateTime, formatDateTime } from 'ui/components/DateTime'
import { DownloadLink } from 'ui/components/DownloadLink'
import { IconName, Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { UploadBatchPdfButton } from 'ui/components/UploadBatchPdfButton'
import { CTRBatch } from '~bank-bsa-reporting/types/CTRBatches/CTRBatch'

import { Tag } from '../../../../../../components/Tag'
import { CTRBatchesChosenItemsContextProps } from '../../context/CTRBatchesChosenItemsContext'

import { s } from './styles'

type Opts = CTRBatchesChosenItemsContextProps & {
  onShowBatch: (item: CTRBatch) => void
  openDeleteConfirmation: (id: number) => void
  isAllItemsChosen: boolean
  onToggleChooseItem?: (id: number) => void
  toggleAllItemsChoosen: () => void
}

const handleError = (message: string) => Toast.error('Error', message)

export const columns = ({
  onToggleChooseItem,
  onShowBatch,
  openDeleteConfirmation,
  isAllItemsChosen,
  chosenItemsIdList,
  toggleAllItemsChoosen
}: Opts): TableColumn<CTRBatch>[] => [
  {
    title: <s.WrappedCheckBox checkBoxLabel="" value={isAllItemsChosen} onChange={() => toggleAllItemsChoosen()} />,
    align: 'center',
    sortable: false,
    render: ({ id }) => (
      <s.WrappedCheckBox
        checkBoxLabel=""
        value={chosenItemsIdList?.indexOf(id) !== -1}
        onChange={() => (onToggleChooseItem ? onToggleChooseItem(id) : null)}
      />
    )
  },
  {
    title: 'ID',
    align: 'left',
    render: ({ id }) => (
      <Link to="/bsa/batch-details/$id" params={{ id }}>
        {id}
      </Link>
    )
  },
  {
    title: 'CTRs Date',
    align: 'center',
    render: ({ startDate }) => (
      <Link to="/bsa/ctrs" search={{ trans_date: formatDateTime(startDate) }}>
        <DateTime date={startDate} />
      </Link>
    )
  },
  {
    title: 'Status',
    align: 'left',
    render: ({ status }) => <Tag status={status} />
  },
  {
    title: 'Batch XML',
    align: 'left',
    sortable: false,
    render: ({ batchXML, id }) => {
      const fileName = batchXML && getBatchFileName(batchXML)

      if (fileName) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id, file_type: 'xml' }}
            baseUrl={Request.urls.bank.batchFileDownload}
            name={fileName}
            handleError={handleError}
          >
            <s.LongFilename title={fileName}>{fileName}</s.LongFilename>
          </DownloadLink>
        )
      }

      return null
    }
  },
  {
    title: 'Batch PDF',
    align: 'left',
    sortable: false,
    render: ({ batchPDF, id }) => {
      const fileName = batchPDF && getBatchFileName(batchPDF)

      if (fileName) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id, file_type: 'ctrx' }}
            baseUrl={Request.urls.bank.batchFileDownload}
            name={fileName}
            handleError={handleError}
          >
            <s.LongFilename title={fileName}>{fileName}</s.LongFilename>
          </DownloadLink>
        )
      }

      return <UploadBatchPdfButton batchId={id} isAcknowledgments={false} isCTR={true} />
    }
  },
  {
    title: 'Acknowledgments PDF',
    align: 'left',
    sortable: false,
    render: ({ acknowledgmentsPDF, id }) => {
      const fileName = acknowledgmentsPDF && getBatchFileName(acknowledgmentsPDF)

      if (fileName) {
        return (
          <DownloadLink
            direct
            parametersForm={{ id, file_type: 'acknowledgments' }}
            baseUrl={Request.urls.bank.batchFileDownload}
            name={fileName}
            handleError={handleError}
          >
            <s.LongFilename title={fileName}>{fileName}</s.LongFilename>
          </DownloadLink>
        )
      }

      return <UploadBatchPdfButton batchId={id} isAcknowledgments isCTR={true} />
    }
  },
  {
    title: 'CTRs',
    align: 'center',
    sortable: false,
    render: (item) =>
      item && item.itemsInfo.length ? (
        <s.TableButton onClick={() => onShowBatch(item)}>{item.itemsInfo.length}</s.TableButton>
      ) : null
  },

  {
    title: 'Action',
    align: 'center',
    sortable: false,
    render: ({ id, enableDelete }) => {
      if (enableDelete)
        return (
          <IconButton icon={IconName.DELETE} face="negative" onClick={() => openDeleteConfirmation(id)}>
            Delete
          </IconButton>
        )
      else return null
    }
  }
]
