import { useLayoutEffect } from 'react'

export const useBlockScroll = () => {
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'visible'
    }
  })
}
