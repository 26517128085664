import { OnboardingDocumentsItem } from 'commons/types/DTO/bank/applications/manage/OnboardingDocumentsItem'
import { getChipFaceByOnboardingDocumentStatus } from 'commons/utils'
import { Chip, DateTime, Link, TableColumn } from 'ui/components'

import { Actions } from './components/Actions'

import { s } from './styles'

export const columns = (applicationId: number): TableColumn<OnboardingDocumentsItem>[] => [
  {
    title: 'ID',
    align: 'left',
    render: ({ id }) => (
      <Link to="/application/$applicationId/onboarding-documents/$id/details" params={{ applicationId, id: id }}>
        ID-{id}
      </Link>
    )
  },
  {
    title: 'Documents Count',
    align: 'left',
    render: ({ documentsCount }) => <>{documentsCount}</>
  },
  {
    title: 'Status',
    align: 'left',
    render: ({ status }) => (
      <s.StatusBadgeWrapper>
        <Chip face={getChipFaceByOnboardingDocumentStatus(status)}>{status}</Chip>
      </s.StatusBadgeWrapper>
    )
  },
  {
    title: 'Created',
    align: 'left',
    render: ({ createdAt }) => <DateTime date={createdAt} withTime />
  },
  {
    title: 'Sent to Sign',
    align: 'left',
    render: ({ sentAt }) => (sentAt ? <DateTime date={sentAt} withTime /> : <>---</>)
  },
  {
    title: 'Action(s)',
    align: 'center',
    render: (item) => <Actions item={item} applicationId={applicationId} />
  }
]
