import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type WiresOutProps = {
  index: number
}

export const WiresOut = ({ index }: WiresOutProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].wiresOut` as 'transactionsInfo[0].wiresOut'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => <FF required field={field} label="Wires Out" input={(p) => <CurrencyCentInput {...p} />} />}
    />
  )
}
