import { ReadHook, useStateManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'
import { emptySAR } from '~bank-bsa-reporting/constants'
import { SarDetailsEdit } from '~bank-bsa-reporting/types'

const request = (opts: { id: number } | undefined) =>
  opts?.id ? Request.post(Request.urls.bank.sarEditMode, { id: opts?.id }) : Promise.resolve({ form: { ...emptySAR } })

const useEditPage: ReadHook<SarDetailsEdit, { id: number }> = (id) =>
  useStateManager().use(['sar-edit', id], () => request(id), { keepPreviousData: true })

export const useGetEditSAR = (id: number) => {
  const { data, isFetching } = useEditPage({ id })

  return {
    data: data?.form,
    isFetching
  }
}
