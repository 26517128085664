// n = 1299, convert to 12.99
// n = 110, convert to 1.1
// n = 100, convert to 1
// n = 54, convert to 0.54
// n = 5, convert to 0.05
// n = 0, convert to 0
export const modelCurrencyCentToView = (n: number | string | null | undefined) => {
  if (n === null || n === undefined) {
    return ''
  }
  const c = Number.parseFloat(n.toString())
  if (c === 0) {
    return '0'
  }
  return parseFloat((c / 100).toFixed(2)).toString()
}

// n = 12.99, convert to 1299
// n = 12, convert to 1200
export const viewCurrencyCentToModel = (n: string) => {
  const f = Number.parseFloat(n)
  if (isNaN(f)) {
    return null
  }
  const c = f * 100
  return Math.round(c)
}
