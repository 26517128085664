import { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { Page, PageFace } from 'ui/components'
import { InvoiceSubmittalPaths } from '~bank-invoices/constants'

import { InvoiceInformation } from './components/InvoiceInformation'
import { TaxReportTable } from './components/TaxReportTable'

const routeApi = getRouteApi(InvoiceSubmittalPaths.DETAILS)

const InvoiceDetails: FC = memo(() => {
  const invoice = routeApi.useLoaderData()

  return (
    <Page face={PageFace.SECONDARY} title="Invoice Submittal Page" subTitle={`IS-${invoice.id}`} isPending={!invoice}>
      <InvoiceInformation />
      <TaxReportTable />
    </Page>
  )
})

export default InvoiceDetails
