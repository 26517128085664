import { FC, memo } from 'react'

import { Form, UseFormDataReturnType } from 'brief-form'
import styled from 'styled-components'
import { Validators } from 'ui/components/Form/validators'
import { SecuredInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

export type PasswordFormValue = {
  oldPassword: string
  password: string
  confirmation: string
}

export interface FormProps {
  form: UseFormDataReturnType<PasswordFormValue, any>
}

const Inner = styled.div`
  padding: 30px 30px;
`

export const PasswordForm: FC<FormProps> = memo((props) => {
  const {
    form: { Field, config }
  } = props

  return (
    <Form config={config}>
      <Inner>
        <FormRow>
          <Col>
            <Field
              required
              name="oldPassword"
              input={SecuredInput}
              label="Old Password"
              validator={Validators.required.string}
              inputProps={{
                autoFocus: true,
                autoComplete: 'off'
              }}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              required
              name="password"
              input={SecuredInput}
              label="New Password"
              validator={Validators.required.string}
              inputProps={{ autoComplete: 'off' }}
            />
          </Col>
          <Col>
            <Field
              required
              name="confirmation"
              input={SecuredInput}
              label="Repeat New Password"
              validator={Validators.required.string}
              inputProps={{ autoComplete: 'off' }}
            />
          </Col>
        </FormRow>
      </Inner>
    </Form>
  )
})
