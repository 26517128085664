import { FC } from 'react'

import { IconName } from 'ui/components'

import * as SC from './styles'

const NoLicensesMessage: FC<{ show: boolean }> = ({ show }) => {
  if (!show) {
    return null
  }
  return (
    <SC.DialogSection>
      <h5>
        <strong>
          <SC.IconWrapper>
            <SC.GreenIcon name={IconName.CHECK_ROUND} /> No Available Licenses
          </SC.IconWrapper>
        </strong>
      </h5>
      <SC.GAP />
      <p>Currently, there are no licenses available to attach to bank accounts.</p>
    </SC.DialogSection>
  )
}

export default NoLicensesMessage
