import { FC, memo, useEffect } from 'react'

import { getRouteApi, useRouterState } from '@tanstack/react-router'
import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { useBankPortalContext } from 'bank-portal-context'
import { Form } from 'brief-form'
import { TableDataQa } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils'
import {
  Info,
  InfoSet,
  Layer,
  Link,
  Page,
  PageFace,
  PageSection,
  PageSectionFace,
  Spinner,
  SpinnerSize,
  Tooltip
} from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button, OutlineButton } from 'ui/components/Buttons'
import { formatDateTime } from 'ui/components/DateTime'
import { TextareaInput } from 'ui/components/InputV2'
import { Footer } from 'ui/temp/Footer'
import { LicensesTitle } from 'ui/temp/LicensesTitle'
import { Col, Col2x, FormRow, Row } from 'ui/themes/globalStyles'

import { MetrcSourceTable } from './components/MetrcSourceTable'
import { POSSourceTable } from './components/POSSourceTable'
import { RejectDialog } from './components/RejectDialog/RejectDialog'
import { UploadedFilesTable } from './components/UploadedFilesTable'
import { useRejectionReason } from './useRejectionReason'
import { useSourceDetails } from './useSourceDetails'

import { s } from './styles'

const routeApi = getRouteApi('/salesdata/$sourceId')

const SourceDetails: FC = memo(() => {
  const { sourceId } = routeApi.useParams()
  const routerState = useRouterState()
  const navigate = routeApi.useNavigate()

  const { setCustomSidebarItems } = useBankPortalContext()

  const {
    webPageData,
    invalidate,
    isFetching,
    config,
    Field,
    saveInternalNotes,
    isInternalNotesSubmitting,
    ApproveConfirmation,
    approve,
    isShowRejectDialog,
    showRejectDialog,
    hideRejectDialog,
    historyPagination
  } = useSourceDetails(+sourceId)

  const {
    config: rejectionNotesConfig,
    Field: RejectionNotesField,
    isRejectionNotesSubmitting,
    saveRejectionNotes
  } = useRejectionReason(webPageData, invalidate)

  useEffect(() => {
    if (webPageData) {
      setCustomSidebarItems([
        { label: webPageData.title, hash: 'main' },
        ...(webPageData.uploadedFiles !== undefined ? [{ label: 'Uploaded Files', hash: 'uploaded_files' }] : []),
        ...(webPageData.dailyReports !== undefined ? [{ label: 'Daily Metrc Data', hash: 'metrc' }] : []),
        ...(webPageData.dailyReportsPOS !== undefined ? [{ label: 'Daily POS Data', hash: 'pos' }] : []),
        ...(webPageData.history !== undefined ? [{ label: 'History', hash: 'history' }] : [])
      ])
    }
    return () => {
      setCustomSidebarItems([])
    }
  }, [webPageData])

  if (!webPageData) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  const returnUrl = routerState.location.pathname

  return (
    <Page
      face={PageFace.SECONDARY}
      title={webPageData.titleOverline}
      subTitle={webPageData.title}
      isPending={false}
      footer={
        <Footer>
          {webPageData.enableReject && (
            <OutlineButton face="negative" size="middle" onClick={() => showRejectDialog()}>
              Reject
            </OutlineButton>
          )}
          {webPageData.enableApprove && (
            <Button face="positive" onClick={() => approve()}>
              Approve
            </Button>
          )}
        </Footer>
      }
    >
      <PageSection
        title="Sales Data"
        anchor="main"
        face={PageSectionFace.SECONDARY}
        actions={
          <>
            {webPageData.enableEdit === false && webPageData.editButtonTooltip !== undefined && (
              <Tooltip id="edit" type="dark" content={webPageData.editButtonTooltip}>
                <Button face="neutral" disabled>
                  Edit
                </Button>
              </Tooltip>
            )}
            {webPageData.enableEdit === false && webPageData.editButtonTooltip === undefined && <></>}
            {webPageData.enableEdit && (
              <Button
                face="neutral"
                onClick={() =>
                  navigate({
                    to: '/salesdata/$sourceId/edit',
                    params: { sourceId },
                    search: { returnUrl: encodeURIComponent(returnUrl) }
                  })
                }
              >
                Edit
              </Button>
            )}
          </>
        }
      >
        <ApproveConfirmation />
        <s.StyledHr />
        <br />
        <InfoSet>
          <Row>
            <Col>
              <Info label="Total Cannabis Sales Excluding Sales Tax">
                {formatCurrencyCent(webPageData.totalCannabisSales)}
              </Info>
            </Col>
            <Col>
              <Info label="Total Non-Cannabis Sales">{formatCurrencyCent(webPageData.totalNonCannabisSales)}</Info>
            </Col>
          </Row>
          <Row>
            <Col>
              <Info label={webPageData.totalSalesTaxCollectedLabel}>
                {formatCurrencyCent(webPageData.totalSalesTaxCollected)}
              </Info>
            </Col>
            <Col>
              <Info label="Total Sales">{formatCurrencyCent(webPageData.totalSales)}</Info>
            </Col>
          </Row>
          {webPageData.infoClientNotes !== undefined && (
            <Row>
              <Col2x>
                <br />
                <Info label="Customer Notes">{webPageData.infoClientNotes}</Info>
              </Col2x>
            </Row>
          )}
        </InfoSet>
        <InfoSet legend="General Information">
          <Row>
            <Col>
              <Info label="Report">
                <Link to="/mr/$id" params={{ id: webPageData.monthlyAnalyticsId }}>
                  {webPageData.monthlyAnalyticsName}
                </Link>
              </Info>
            </Col>
            <Col>
              <Info label={`License${webPageData.infoLicenses.length > 1 ? 's' : ''}`}>
                <LicensesTitle licenses={webPageData.infoLicenses} withUrl />
              </Info>
            </Col>
          </Row>

          <Row>
            <Col>
              <Info label="Status">{webPageData.infoStatus}</Info>
            </Col>
            <Col>
              <Info label="Last Modified">{formatDateTime(webPageData.infoLastModified, true)}</Info>
            </Col>
          </Row>

          {webPageData.infoLastRejectionReason !== undefined && (
            <Row>
              <Col2x>
                <s.InfoWarning>
                  <s.InfoWarningLabel>
                    <strong>Last Rejection Reason</strong>
                  </s.InfoWarningLabel>
                  <s.InfoWarningContent>{webPageData.infoLastRejectionReason}</s.InfoWarningContent>
                </s.InfoWarning>
                <br />
              </Col2x>
            </Row>
          )}

          {webPageData.infoRejectionReason !== undefined && (
            <Form config={rejectionNotesConfig}>
              <FormRow>
                <Col2x>
                  <RejectionNotesField
                    name="rejectionReason"
                    label="Reason(s) for Rejection (visible to customer)"
                    input={TextareaInput}
                    inputProps={{}}
                  />
                </Col2x>
              </FormRow>
              {webPageData.infoEnableEditRejectionReason && (
                <FormRow>
                  <Col2x>
                    <ButtonGroup margin="small">
                      <Button onClick={() => saveRejectionNotes()} loading={isRejectionNotesSubmitting} face="positive">
                        Save Notes
                      </Button>
                    </ButtonGroup>
                  </Col2x>
                </FormRow>
              )}
            </Form>
          )}

          {webPageData.infoInternalNotes !== undefined && (
            <Form config={config}>
              <FormRow>
                <Col2x>
                  <Field
                    name="notes"
                    label={webPageData.infoInternalNotesLabel}
                    input={TextareaInput}
                    inputProps={{}}
                  />
                </Col2x>
              </FormRow>
              <FormRow>
                <Col2x>
                  <ButtonGroup margin="small">
                    <Button onClick={() => saveInternalNotes()} loading={isInternalNotesSubmitting} face="positive">
                      Save Notes
                    </Button>
                  </ButtonGroup>
                </Col2x>
              </FormRow>
            </Form>
          )}
        </InfoSet>
      </PageSection>
      {webPageData.uploadedFiles !== undefined && <UploadedFilesTable webPage={webPageData} invalidate={invalidate} />}
      {webPageData.dailyReports !== undefined && <MetrcSourceTable webPage={webPageData} />}
      {webPageData.dailyReportsPOS !== undefined && <POSSourceTable webPage={webPageData} />}
      {isShowRejectDialog && (
        <RejectDialog webPage={webPageData} hideRejectDialog={hideRejectDialog} invalidate={invalidate} />
      )}
      {webPageData.history !== undefined && (
        <PageSection title="History" anchor="history">
          <Layer rounded shadowed stretch>
            <HistoryTable
              dataQa={TableDataQa.BP_SALESDATA_SOURCE_DETAILS_HISTORY}
              loading={isFetching}
              items={webPageData?.history}
              pagination={historyPagination}
            />
          </Layer>
        </PageSection>
      )}
    </Page>
  )
})

export default SourceDetails
