import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

const CountryRenderer = ({ field }: { field: FieldApi<SarEdit, 'institutionContactInfo.country'> }) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (typeof field.state.value !== 'undefined' && field.state.value !== 'US') {
      formApi.setFieldValue('institutionContactInfo.state', '')
      formApi.setFieldMeta('institutionContactInfo.state', (stateMeta) => ({
        ...stateMeta,
        errors: [],
        errorMap: {}
      }))
    }
  }, [field.state.value, formApi])

  return (
    <FF
      required
      label="86. Country"
      field={field}
      input={(p) => (
        <Select {...p} data={usAndTerritories} data-qa="86. Country" options-data-qa="86. Country option" />
      )}
    />
  )
}

export const Country = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.country"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <CountryRenderer field={field} />}
    />
  )
}
