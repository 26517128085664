import { FF, SecuredInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const TIN = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.tin"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="77. TIN"
          field={field}
          input={(p) => <SecuredInput {...p} maxLength={25} data-qa="77. TIN" />}
        />
      )}
    />
  )
}
