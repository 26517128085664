import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { FF, IconName, TextInput, TextareaInput, Toast, Validators } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button, IconButton } from 'ui/components/Buttons'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { SpinnerSize } from 'ui/components/Spinner'
import { useUpdateDocumentFile } from '~bank-documents/service/updateDocumentFile'

import { ManageFileDialogProps } from './ManageFileDialog.types'

import { s } from './styles'

export const ManageFileDialog: FC<ManageFileDialogProps> = memo(
  ({ onClose, file, deleteInfo: { DeleteConfirmation, openDeleteConfirmation } }) => {
    const router = useRouter()

    const form = useForm({
      defaultValues: { notes: file?.notes, name: file?.name },
      onSubmit: async ({ value }) => {
        if (file) {
          routine({ id: file.id, ...value })
        }
      }
    })

    const { routine } = useUpdateDocumentFile({
      onSuccess: async () => {
        Toast.successSmall('File was successfully updated.')
        await router.invalidate()
        onClose()
      },
      onError: (error) => handlerServerError(error)
    })

    const isLoading = form.useStore((s) => s.isSubmitting)

    return (
      <Dialog face={DialogFace.PRIMARY} id="edit-file-dialog" title="Edit File" onClose={onClose}>
        {isLoading && <s.WrappedSpinner centered size={SpinnerSize.M} />}
        <DeleteConfirmation />
        <s.Box>
          <form.Field
            name="name"
            validators={{
              onSubmit: (v) => Validators.required.field(v),
              onChange: (v) => Validators.required.field(v)
            }}
            children={(nameField) => (
              <FF label="File Name" field={nameField} input={(props) => <TextInput data-qa="filename" {...props} />} />
            )}
          />
          <s.NotesWrapper>
            <form.Field
              name="notes"
              children={(nameField) => (
                <FF label="Notes" field={nameField} input={(props) => <TextareaInput data-qa="Notes" {...props} />} />
              )}
            />
          </s.NotesWrapper>
          <s.Footer>
            <ButtonGroup margin="small">
              <form.Subscribe
                children={({ canSubmit }) => (
                  <Button face="positive" disabled={!canSubmit} onClick={form.handleSubmit}>
                    Save
                  </Button>
                )}
              />
              <Button face="neutral" onClick={onClose}>
                Cancel
              </Button>
            </ButtonGroup>
            {file?.id && (
              <IconButton
                face="negative"
                icon={IconName.DELETE}
                onClick={() => openDeleteConfirmation({ fileId: file.id })}
              >
                Delete File
              </IconButton>
            )}
          </s.Footer>
        </s.Box>
      </Dialog>
    )
  }
)
