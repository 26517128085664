import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

import { FooterProps } from './ManagePageFooter.types'

import { s } from './styles'

export const ManagePageFooter = ({ onSubmit, positiveButtonText, disabledSubmitting = false }: FooterProps) => (
  <s.Box>
    <s.FooterTitle>Setup onboarding documents</s.FooterTitle>
    <ButtonGroup margin="small">
      <Button face="neutral" onClick={() => history.back()}>
        Cancel
      </Button>
      <Button face="positive" onClick={onSubmit} disabled={disabledSubmitting}>
        {positiveButtonText}
      </Button>
    </ButtonGroup>
  </s.Box>
)
