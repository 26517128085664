import { FC, memo } from 'react'

import { SalesInfo } from 'commons/types/DTO/bank/reportWebPage'

import { SalesSourcesTable } from './SalesSourcesTable'

import * as SC from './styles'

export const SalesReportTable: FC<{
  salesInfo: SalesInfo
  monthlyAnalyticsId: number
  bankAccountId: number
  title: string
}> = memo(({ salesInfo, monthlyAnalyticsId, bankAccountId, title }) => (
  <div>
    <SC.InnerSectionHeader>{title}</SC.InnerSectionHeader>
    {salesInfo.salesSourceItems.map((el, index) => (
      <SalesSourcesTable
        key={index}
        salesSourceItem={el}
        monthlyAnalyticsId={monthlyAnalyticsId}
        bankAccountId={bankAccountId}
      />
    ))}
  </div>
))
