import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'
import { ReviewDepositLogProps } from './ReviewDepositLogProps'

export const ReviewDepositLog = ({ formValue, Field }: ReviewDepositLogProps) => (
  <>
    <MainText>
      Deposits by third parties with no apparent connection to the accountholder.
      <br />
      Individuals conducting transactions for the business appear to be acting on behalf of other, undisclosed parties
      of interest.
    </MainText>
    <SecondText>Review Deposit Log for name and occupation for person conducting the deposit transaction.</SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewDepositLogRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewDepositLogWhoMakesDeposits"
          label="Who makes deposits for the business?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Owner',
                value: 'owner'
              },
              {
                label: 'Employee',
                value: 'employee'
              },
              {
                label: 'Courier',
                value: 'courier'
              },
              {
                label: 'Mobile Deposit',
                value: 'mobile_deposit'
              },
              {
                label: 'No Transactions of this Type',
                value: 'no_transactions'
              },
              {
                label: 'Other – See comments',
                value: 'other'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={formValue.reviewDepositLogWhoMakesDeposits === 'other'}
          name="reviewDepositLogComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={formValue.reviewDepositLogWhoMakesDeposits === 'other' ? Validators.required.field : undefined}
        />
      </Col2x>
    </FormRow>
  </>
)
