import { memo } from 'react'

import { IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import * as SC from './styles'

interface FooterProps {
  onDeleteBatches: () => void
  hasChosenItems: boolean
}

export const Footer = memo((props: FooterProps) => {
  const { hasChosenItems, onDeleteBatches } = props

  if (!hasChosenItems) {
    return null
  }

  return (
    <SC.Box>
      {hasChosenItems && (
        <IconButton face="negative" icon={IconName.DELETE} onClick={onDeleteBatches}>
          Delete Batch(es)
        </IconButton>
      )}
    </SC.Box>
  )
})
