import { FC, memo } from 'react'

import { Info } from 'ui/components'

import { ApplicationStatusProps } from './ApplicationStatusProps'

import * as SC from './styles'

export const ApplicationStatus: FC<ApplicationStatusProps> = memo(({ applicationStatus }) => (
  <SC.StatusHeader>
    <Info label="Application Status:">
      <em>{applicationStatus}</em>
    </Info>
  </SC.StatusHeader>
))
