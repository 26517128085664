import { memo } from 'react'

import { useBankPortalContext } from 'bank-portal-context'
import { InnerMenu } from 'ui/components/SideMenu'

export const MRSideBar = memo(() => {
  const { customSidebarItems } = useBankPortalContext()

  if (!customSidebarItems.length) {
    return null
  }

  return <InnerMenu items={customSidebarItems} />
})
