import { ReviewFormUploadWrap } from '../ApplicationControls/components'
import { ReviewsProps } from './ReviewsProps'

import { Header, Notes, Title, Wrapper } from './styles'

export const Reviews = ({ application }: ReviewsProps) => {
  if (!application.reviews) {
    return null
  }
  const { credit, compliance, martinHood } = application.reviews
  return (
    <Wrapper>
      <Header>Reviews</Header>
      <Title>Compliance Review</Title>
      <Notes>{compliance.IsDone ? compliance.Notes : 'No review has been submitted here yet.'}</Notes>

      {compliance.IsModifiable && (
        <ReviewFormUploadWrap
          application={application}
          title="Add the Compliance Review"
          onlyNotes={true}
          requiredNotes={false}
          notes={application.reviews?.compliance?.Notes || ''}
          buttonName="Edit Review"
          fieldName="internalReviewCompliance"
        />
      )}

      <Title>Martin Hood, CPA Review</Title>
      <Notes>{martinHood.IsDone ? martinHood.Notes : 'No review has been submitted here yet.'}</Notes>

      {martinHood.IsModifiable && (
        <ReviewFormUploadWrap
          application={application}
          title="Add the Martin Hood Review"
          type="Martin Hood Review"
          onlyNotes={false}
          requiredNotes={true}
          notes={application.reviews?.martinHood?.Notes || ''}
          buttonName="Add Review"
          fieldName="internalReviewMartinHood"
        />
      )}

      <Title>Credit Review</Title>
      <Notes>{credit.IsDone ? credit.Notes : 'No review has been submitted here yet.'}</Notes>
      {credit.IsModifiable && (
        <ReviewFormUploadWrap
          application={application}
          title="Add the Credit Review"
          type="Credit Review"
          onlyNotes={false}
          requiredNotes={true}
          notes={application.reviews?.credit?.Notes || ''}
          buttonName="Add Review"
          fieldName="internalReviewCredit"
        />
      )}
    </Wrapper>
  )
}
