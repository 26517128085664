import { z } from 'zod'

export const documentNewSchema = z.object({
  subjectType: z.string().nullable().default(null).catch(null),
  subjectName: z.string().nullable().default(null).catch(null),
  companyId: z.number().nullable().default(null).catch(null),
  contactId: z.number().nullable().default(null).catch(null),
  licenseId: z.number().nullable().default(null).catch(null),
  bankAccountId: z.number().nullable().default(null).catch(null),
  returnUrl: z.string().nullable().default('').catch(null)
})
