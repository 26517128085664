import { FC, memo, useCallback, useContext } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { AuthContext } from '~auth/AuthContext'
import { useSetPassword } from '~auth/hooks/user'

import { SetPasswordProps } from './SetPasswordProps'
import { SetPasswordForm } from './components'
import { SetPasswordFormValue } from './components/SetPasswordFormValue'

import * as SC from './styles'

export const SetPassword: FC<SetPasswordProps> = memo(({ logoElement, footer }) => {
  const { portalName } = useContext(AuthContext)
  const setPasswordMutation = useSetPassword(portalName)
  const key = getRouteApi('/auth/password-reset').useSearch({ select: (s) => s?.key })

  const setPassword = useCallback(
    (value: SetPasswordFormValue) =>
      setPasswordMutation.mutateAsync({
        password: value.password,
        key: key || ''
      }),
    [setPasswordMutation, key]
  )

  return (
    <SC.Box>
      <SetPasswordForm onSubmit={setPassword} logoElement={logoElement} footer={footer} />
    </SC.Box>
  )
})
