import { FC, memo } from 'react'

import { Hr } from 'ui/components/Hr'
import { InfoSet, InfoSetDirection } from 'ui/components/Info'

import { Bio, DisclosureOfOtherMarijuana, Names } from '../contacts/contact-details'
import { Debtholder } from '../contacts/role-information/Debtholder'
import { IsConductor } from '../contacts/role-information/IsConductor'
import { Owner } from '../contacts/role-information/Owner'
import { Title as ContactTitle } from '../contacts/role-information/Title'
import { AccountSigner } from '../contacts/role-information/account-signer'
import { AdditionalInformation } from '../contacts/role-information/additional-information'
import { ContactsInformationProps } from './ContactsInformationProps'

import * as SC from './styles'

export const ContactsInformation: FC<ContactsInformationProps> = memo(({ contacts }) => (
  <SC.Wrapper>
    <SC.Title>Contacts</SC.Title>
    {contacts.map((contact, index) => {
      const { companyRoleTitle, bio } = contact
      return (
        <div key={index}>
          <SC.Header>Contact #{index + 1}</SC.Header>
          <InfoSet legend="Contact Details">
            <Names contact={contact} />
            {('mobile' in contact || 'homePhone' in contact || 'otherMJRelatedBusiness' in contact) && (
              <DisclosureOfOtherMarijuana contact={contact} />
            )}
            {'bio' in contact && <Bio bio={bio} />}
          </InfoSet>
          <Hr />
          <InfoSet direction={InfoSetDirection.ROW} legend="Role Information">
            <div>
              <ContactTitle companyRoleTitle={companyRoleTitle} />
              <Owner contact={contact} />
              <Debtholder contact={contact} />
              <AccountSigner contact={contact} />
              <IsConductor contact={contact} />
              <AdditionalInformation contact={contact} />
            </div>
          </InfoSet>
        </div>
      )
    })}
  </SC.Wrapper>
))
