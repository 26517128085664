import { FC, memo } from 'react'

import { Hint } from 'ui/components/Hint'
import { Col2x } from 'ui/themes/globalStyles'

export const AddHelp: FC = memo(() => (
  <Col2x>
    <Hint>
      Please add Company details for each legal entity for which you would like to open an account. If the Company holds
      a marijuana related license(s), please add the requested information for each license. Please note if a company
      anticipates deposit activity it must have a corresponding account. The Bank will be unable to process third party
      deposits.
      <br />
      <br />
      The Bank has created a streamlined application workflow to improve efficiency and accuracy during this process. If
      you have any questions or comments, please make a note in the appropriate field above and a bank representative
      will be in touch with you shortly.
      <br />
      <br />
      Once submitted, the Bank will review the application.
    </Hint>
  </Col2x>
))
