import { FC, memo } from 'react'

import { CheckBox, Icon, IconName, IconSize, Toggle } from 'ui/components'

import { PermissionsFormProps } from './PermissionsFormProps'

import * as SC from './styles'

export const PermissionsForm: FC<PermissionsFormProps> = memo(({ permissions, Field, formValue }) => (
  <SC.Wrapper>
    <SC.Title>Assign Permissions</SC.Title>{' '}
    <SC.FormWrapper>
      {permissions.map((permission, permissionId) => (
        <SC.PermissionWrapper key={permissionId}>
          <SC.TogglePermissionWrapper>
            <SC.TogglePermissionTitle>{permission.label}</SC.TogglePermissionTitle>
            <SC.ToggleWrapper>
              {formValue[permission?.value] && permission.hint && permission.hint.length && (
                <SC.ToggleHint>
                  <Icon name={IconName.INFO} size={IconSize.S} />
                  {permission.hint}
                </SC.ToggleHint>
              )}
              <div>
                <Field
                  name={permission.value}
                  input={Toggle}
                  inputProps={{
                    noMarginRight: true
                  }}
                />
              </div>
            </SC.ToggleWrapper>
          </SC.TogglePermissionWrapper>
          <SC.CheckboxColumnWrapper
            $isOpened={Boolean(formValue[permission?.value])}
            $elementsCount={permission.nested?.length}
          >
            {permission.nested &&
              permission.nested.map((nestedPermission, nestedPermissionId) => (
                <SC.CheckboxPermission key={nestedPermissionId}>
                  <Field
                    name={nestedPermission.value}
                    input-text={nestedPermission.label}
                    input={CheckBox}
                    inputProps={{ checkBoxLabel: nestedPermission.label }}
                    key={nestedPermission.label}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </SC.CheckboxPermission>
              ))}
          </SC.CheckboxColumnWrapper>
        </SC.PermissionWrapper>
      ))}{' '}
    </SC.FormWrapper>
  </SC.Wrapper>
))
