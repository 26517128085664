import { AssignedEntitiesChangedData } from './HistoryRecordDetails.types'

// TODO: component helper
const finder =
  (id: number) =>
  ({ id: itemId }) =>
    itemId === id

// filter items that in `after` and `before` simultaneously(=== nothing changed)
// item in `before` and not in `after` marked as `unassigned`
// item not in `before` and in `after` marked as `assigned`
const getAssignedUnAssignedLists = ({
  after,
  before
}: {
  after: AssignedEntitiesChangedData[]
  before: AssignedEntitiesChangedData[]
}): {
  assignedList: AssignedEntitiesChangedData[]
  unAssignedList: AssignedEntitiesChangedData[]
} => {
  // result info
  const log = {}
  const commonList: AssignedEntitiesChangedData[] = [...before, ...after]

  const assignedList: AssignedEntitiesChangedData[] = []
  const unAssignedList: AssignedEntitiesChangedData[] = []
  commonList.forEach((item) => {
    const { id } = item
    if (log[id]) {
      return
    }

    if (!log[id]) {
      log[id] = {}
    }

    const isInBefore = !!before.find(finder(id))
    const isInAfter = !!after.find(finder(id))

    if (!isInBefore && isInAfter) {
      assignedList.push(item)
    }

    if (isInBefore && !isInAfter) {
      unAssignedList.push(item)
    }
  })

  return {
    assignedList,
    unAssignedList
  }
}

export default getAssignedUnAssignedLists
