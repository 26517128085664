import { useCallback, useState } from 'react'

import { getRouteApi, useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { InvoiceSubmittalPaths } from '~bank-invoices/constants'
import { useDeleteInvoiceSubmittalFile } from '~bank-invoices/service/deleteInvoiceSubmittalFile'

const routeApi = getRouteApi(InvoiceSubmittalPaths.DETAILS)

export const useDeleteFile = () => {
  const router = useRouter()
  const { id: invoiceSubmittalId } = routeApi.useParams()

  const [fileToDelete, setFile] = useState<{ fileId: number; fileName: string }>({ fileId: 0, fileName: '' })

  const { routine: deleteInvoiceFile } = useDeleteInvoiceSubmittalFile({
    onSuccess: async () => {
      Toast.successSmall('Invoice Submittal File was successfully deleted.')
      router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteInvoiceFileHandler = useCallback(() => {
    deleteInvoiceFile({
      invoiceSubmittalId: +invoiceSubmittalId,
      fileKeyIndex: fileToDelete.fileId
    })
  }, [deleteInvoiceFile, fileToDelete.fileId, invoiceSubmittalId])

  const { Confirmation: InvoiceFileDeleteConfirmation, open: openFilesDeleteConfirmation } = useConfirmation({
    message: `Are you sure want to delete file "${fileToDelete.fileName}."`,
    onConfirm: deleteInvoiceFileHandler,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const setFileToDelete = (fileId: number, fileName: string) => {
    setFile({ fileId, fileName })
    openFilesDeleteConfirmation()
  }

  return { InvoiceFileDeleteConfirmation, setFileToDelete }
}
