import { ApplicationWebPageDetail } from 'commons/types/DTO/bank'
import { InnerMenu } from 'ui/components/SideMenu'

type Item = {
  label: string
  hash: string
  disabled?: boolean
}

const OnboardingSideBar = ({ application }: { application: ApplicationWebPageDetail }) => {
  const { relationships, enableReviews, enableClientDocuments, showOnboardingDocuments } = application || {}

  let items: Item[] = [
    { label: 'General Information', hash: 'general' },
    { label: 'Contact with the Applicant', hash: 'contact' }
  ]

  if (relationships) {
    relationships.forEach((_, i) => {
      items.push({
        label: `Company #${i + 1}`,
        hash: `company_${i + 1}`
      })
    })
  }

  items = items
    .concat([
      {
        label: 'Document Uploading',
        hash: 'client-documents',
        disabled: !enableClientDocuments
      },
      { label: 'Reviews', hash: 'reviews', disabled: !enableReviews },
      { label: 'Internal Documents', hash: 'internal-documents' },
      ...(showOnboardingDocuments ? [{ label: 'Onboarding Documents', hash: 'onboarding-documents' }] : [])
    ])
    .filter(Boolean)

  return <InnerMenu items={items} />
}

export default OnboardingSideBar
