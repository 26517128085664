import { FC, memo, useState } from 'react'

import { useLogout } from 'auth'
import { Avatar } from 'ui/components/Avatar'
import { DropDown } from 'ui/components/DropDown'
import { IconName, IconSize } from 'ui/components/Icon'

import { AccountMenuProps } from './AccountMenuProps'

import * as SC from './styles'

export const AccountMenu: FC<AccountMenuProps> = memo(({ userName, canAccessAccountSettings }) => {
  const [open, setOpen] = useState(false)
  const { logout } = useLogout()

  return (
    <DropDown
      menuPosition={'bottom-end'}
      onVisibilityChange={setOpen}
      button={
        <SC.Row>
          <Avatar username={userName} />
          <SC.Name>{userName}</SC.Name>
          <SC.ArrowIcon $open={open} name={IconName.DOWN} size={IconSize.XS} />
        </SC.Row>
      }
    >
      {canAccessAccountSettings && (
        <SC.MenuItem to="/account-settings">
          <SC.MenuItemIcon name={IconName.SETTINGS_STROKE} /> Account Settings
        </SC.MenuItem>
      )}
      <SC.MenuItem onClick={() => logout({})} data-qa="logout-link">
        <SC.MenuItemIcon name={IconName.LOGOUT} /> Log Out
      </SC.MenuItem>
    </DropDown>
  )
})
