import { FC, memo, useMemo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Layer, Panel, Table } from 'ui/components'

import { CompanyDocumentsProps } from './CompanyDocumentsProps'
import { columns } from './columns'

import * as SC from './styles'

export const CompanyDocuments: FC<CompanyDocumentsProps> = memo((props) => {
  const {
    applicationClientDocumentsUploading,
    index,
    readonly,
    isLoading,
    onAddFiles,
    onRemoveFile,
    isDocumentDeleting
  } = props
  const { companyName, documents } = applicationClientDocumentsUploading

  const mappedAM = useMemo(
    () =>
      documents.map((el) => ({
        item: el
      })),
    [documents]
  )

  return (
    <>
      <Layer rounded shadowed>
        <Panel
          title={
            <>
              <div>
                <SC.Subtitle>{`Company #${index + 1}`}</SC.Subtitle>
                <h3>{companyName}</h3>
              </div>
            </>
          }
        >
          <Table
            dataQa={TableDataQa.ONBOARDING_FORM_COMPANY_DOCUMENTS_LIST}
            noDataMessage={'No Documents Necessary'}
            columns={columns(
              readonly,
              applicationClientDocumentsUploading.companyName,
              isLoading,
              isDocumentDeleting,

              onAddFiles,
              onRemoveFile
            ).filter(Boolean)}
            dataSource={mappedAM}
            loading={isLoading}
          />
        </Panel>
      </Layer>
      <div style={{ height: '40px' }} />
    </>
  )
})
