import { memo } from 'react'

import SC from 'styled-components'

import { Icon, IconName, IconSize } from '../Icon'

interface Props {
  title: string
  description?: string
}

const Box = SC.div`
  padding: 24px 12px;
  border-radius: 8px;
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  background: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
`

const WarningIcon = SC(Icon)`
  margin-right: 10px;
`

const Title = SC.p`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const Warning = memo(({ title, description }: Props) => (
  <Box>
    <Title>
      <WarningIcon name={IconName.ERROR_RED} size={IconSize.M} />
      <strong>{title}</strong>
    </Title>
    {description && <p>{description}</p>}
  </Box>
))
