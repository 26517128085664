import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const ActionDate = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].actionDate`}
      children={(field) => <FF field={field} label="26. Action Date" input={(p) => <DatePicker {...p} />} />}
    />
  )
}
