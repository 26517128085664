import * as SC from '../../../styles'
import { IsUSCitizenProps } from './IsUSCitizenProps'

export const IsUSCitizen = ({ isUSCitizen }: IsUSCitizenProps) => (
  <SC.LabelValue>
    <div>
      Is this individual/entity a <b>US Citizen</b>?
    </div>
    <SC.Value>{isUSCitizen ? 'Yes' : 'No'}</SC.Value>
  </SC.LabelValue>
)
