import { Suspense } from 'react'

import { Outlet } from '@tanstack/react-router'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

export const BankOnboardingWrapper = () => (
  <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
    <Outlet />
  </Suspense>
)
