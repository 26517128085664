import format from 'date-fns/format'

export const formatDateTime = (
  date: string | number | Date | null | undefined,
  withTime = false,
  historical = true,
  f?: string
) => {
  let parsedDate
  const dateType = typeof date

  if (!date) {
    return null
  }

  if (historical && !withTime && dateType === 'string') {
    const datePart = (date as string).substr(0, 10)

    if (!/^(\d{4})-(\d{2})-(\d{2})$/.test(datePart)) {
      throw new Error('Invalid date string')
    }

    const split = datePart.split('-')
    return `${split[1].padStart(2, '0')}/${split[2].padStart(2, '0')}/${split[0]}`
  }

  if (dateType === 'string' || dateType === 'number') {
    parsedDate = new Date(date)
  } else {
    parsedDate = date
  }

  if (withTime) {
    return format(parsedDate as Date, f || 'Pp')
  } else {
    return format(parsedDate as Date, f || 'MM/dd/yyyy')
  }
}

export const getHistoricalDate = (date: string) => {
  try {
    return date.split('T')[0]
  } catch (e) {
    return date
  }
}

export const getRawDate = (date: Date): string =>
  `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}T00:00:00.000Z`

export const formatTheDateYall = (date) => {
  if (!date) return null
  const [year, month, day] = date.substr(0, 10).split('-')
  return new Date(year, month - 1, day)
}
