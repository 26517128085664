import { FC, memo } from 'react'

import { useGetBankName } from 'commons/hooks/bank/organisation'
import { BankName } from 'commons/types/enums'
import { Hr } from 'ui/components/Hr'
import { Page } from 'ui/components/Page'

import { REPORTS_MODULE_PATH } from '../../constants'

import * as SC from './styles'

export const Overview: FC = memo(() => {
  const { id: bankId } = useGetBankName()
  const isSiteAuditReportAvailable = bankId === BankName.SHORE || bankId === BankName.VALLIANCE

  return (
    <Page title="Reports">
      <Hr />
      <SC.Buttons>
        <SC.WrappedButton
          text="State Inventory Control Systems API Status Report"
          url={`${REPORTS_MODULE_PATH}/state`}
        />
        <SC.WrappedButton text="Client Portal Users Activity Report" url={`${REPORTS_MODULE_PATH}/client`} />
        {isSiteAuditReportAvailable && (
          <SC.WrappedButton text="Site Audit Documents Report" url={`${REPORTS_MODULE_PATH}/site-audit-documents`} />
        )}
        <SC.WrappedButton text="Documents Report" url={`${REPORTS_MODULE_PATH}/documents`} />
        <SC.WrappedButton text="User Report" url={`${REPORTS_MODULE_PATH}/user`} />
        <SC.WrappedButton text="Relationships Report" url={`${REPORTS_MODULE_PATH}/relationships`} />
        <SC.WrappedButton text="Application Pipeline Report" url={`${REPORTS_MODULE_PATH}/application-pipeline`} />
        <SC.WrappedButton text="Email History Report" url={`${REPORTS_MODULE_PATH}/email-history`} />
        <SC.WrappedButton text="Alerts Report" url={`${REPORTS_MODULE_PATH}/alerts`} />
      </SC.Buttons>
    </Page>
  )
})
