import { useState } from 'react'
import { useQueryClient } from 'react-query'

import { BeforeFormChangeHandler } from 'brief-form'
import { useTableState } from 'ui/components/QueryTable'
import { useConfirmation } from 'ui/hooks'
import { Batch as BatchHook } from '~bank-bsa-reporting/hooks'

import { SARListForm, SARListItem } from './types'
import { useGetWebPage } from './useGetWebPage'

export const useData = () => {
  const queryClient = useQueryClient()
  const deleteMutation = BatchHook.useDelete(queryClient)
  const [sarItemForInfoView, setSarItemForInfoView] = useState<SARListItem | null>(null)
  const [sarItemToDelete, setSarItemToDelete] = useState<SARListItem | null>(null)
  const [form, setForm] = useState<SARListForm>(new SARListForm())
  const { data, isFetching, invalidate } = useGetWebPage()

  const [chosenSarItems, setChosenSarItems] = useState<SARListItem[]>([])

  const onBeforeFilterChange: BeforeFormChangeHandler<SARListForm> = ({ value, errors }) => {
    setForm({
      ...value,
      // TODO: see SARListForm comment
      batchId: value.batchId === null || value.batchId === '' ? null : +value.batchId,
      bsaTrackingId: value.bsaTrackingId !== '' ? value.bsaTrackingId : null,
      date: value.date !== '' ? value.date : null
    })

    return {
      value,
      errors
    }
  }

  const tableState = useTableState({
    onBeforeFilterChange,
    page: form.page,
    size: form.size,
    filter: form
  })

  const { open: startDeleting, Confirmation: DeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure you want to delete{' '}
        <i>{sarItemToDelete || chosenSarItems.length === 1 ? (sarItemToDelete || chosenSarItems[0]).id : 'these'}</i>
        &nbsp;Batch(es)?
      </span>
    ),
    onConfirm: () =>
      deleteMutation.mutateAsync(sarItemToDelete ? [sarItemToDelete.id] : chosenSarItems.map((i) => i.id)).then(() => {
        setChosenSarItems([])
        setSarItemToDelete(null)
        invalidate()
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const handleSarItemSelect = (item?: SARListItem) => {
    if (!item) {
      if (data?.items && chosenSarItems.length < data?.items.length) {
        setChosenSarItems(data?.items)
      } else {
        setChosenSarItems([])
      }
    } else {
      const index = chosenSarItems.findIndex((i) => i.id === item.id)
      if (index === -1) {
        setChosenSarItems(chosenSarItems.concat(item))
      } else {
        const copy = [...chosenSarItems]
        copy.splice(index, 1)
        setChosenSarItems(copy)
      }
    }
  }

  const handleDeleteFromTableMenu = (item: SARListItem) => {
    setSarItemToDelete(item)
    startDeleting()
  }

  const handleClear = () => {
    tableState.onFilterClear()
    tableState.filterForm.set({ reset: true })
    setForm(new SARListForm())
  }

  const handlePagination = (page: number, size: number) => {
    tableState.onPageChange(page, size)

    const newForm = { ...form, page, size }

    setForm(newForm)
  }

  return {
    isFetching,
    data,
    tableState,
    sarItemForInfoView,
    chosenSarItems,
    DeleteConfirmation,
    setSarItemForInfoView,
    startDeleting,
    handleSarItemSelect,
    handleDeleteFromTableMenu,
    handleClear,
    handlePagination
  }
}
