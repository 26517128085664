import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton } from 'ui/components/Buttons'
import { Search } from 'ui/components/Search'

import { ActionsProps } from './ActionsProps'

export const Actions = ({ onOpenCreateModal, searchTerm, onSearchTermChange }: ActionsProps) => (
  <ButtonGroup margin="small">
    <Search onSearch={onSearchTermChange} value={searchTerm} placeholder="Search by License Name" data-qa="Search" />
    <HeaderButton size="middle" onClick={onOpenCreateModal}>
      Add key
    </HeaderButton>
  </ButtonGroup>
)
