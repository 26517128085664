import { Panel, PanelFace } from 'ui/components'

import { TemplatePanelProps } from './TemplatePanel.types'

export const TemplatePanel = ({
  title,
  expanded = false,
  disabled = false,
  actions = null,
  children
}: TemplatePanelProps) => (
  <Panel
    title={title}
    titleTagAs="h5"
    face={PanelFace.SECONDARY}
    isArrowButton={true}
    rounded={false}
    shadowed={false}
    expanded={expanded}
    disabled={disabled}
    actions={actions}
  >
    {children}
  </Panel>
)
