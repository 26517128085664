import { FC, memo, useCallback } from 'react'

import { useFormData } from 'brief-form'
import { Request } from 'commons/utils/request'
import { useTableState } from 'query-table'
import { Page, PageFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { Table } from './components/Table'

const UserReport: FC = memo(() => {
  const tableState = useTableState({
    filter: {},
    order: [{ field: 'first_name', direction: 'ASC' }],
    pagination: {
      page: 1,
      size: 20
    }
  })

  const filterForm = useFormData<{ active: string }>({
    initialValue: {
      active: 'true'
    }
  })

  const originalExportsChange = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      tableState.pagination.change(1, tableState.pagination.size)
      originalExportsChange(v, e)
    },
    [originalExportsChange, tableState]
  )

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="User Report" />}
      actions={
        <ExportCSVButton
          reportName="User"
          url={Request.urls.bank.portalReportUserCSV}
          parametersForm={{
            active: filterForm.config.value.active.length ? JSON.parse(filterForm.config.value.active) : null,
            _options: {}
          }}
        />
      }
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Table filterForm={filterForm} tableState={tableState} />
      </Panel>
    </Page>
  )
})

export default UserReport
