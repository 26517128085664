import { useCallback, useMemo, useState } from 'react'

import { BeforeFormChangeHandler } from 'brief-form'
import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { AlertsWebPage } from 'commons/types/DTO/bank/alerts'
import { useTableState } from 'ui/components'

import { FilterValue } from '../types'

export const useComponentsEssence = (
  onAlertFilterChange: (filter: FilterAlertsWebPage) => void,
  webPage: AlertsWebPage
) => {
  const [loading, setLoading] = useState(false)
  const { config } = webPage

  const initialFilter = useMemo(
    () => ({
      annualReviewsStatus: config.AnnualReviewsStatus,
      annualReviewsOffset: config.AnnualReviewsOffset,
      annualReviewsLimit: config.AnnualReviewsLimit,
      annualReviewsCompanyID: config.AnnualReviewsCompanyID
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState<FilterAlertsWebPage>(initialFilter)

  const onBeforeFilterChange: BeforeFormChangeHandler<FilterValue> = useCallback(
    ({ value, errors }) => {
      setLoading(true)

      const filter = {
        ...tableFilter,
        annualReviewsOffset: undefined,
        annualReviewsLimit: undefined,
        annualReviewsStatus: value.status,
        annualReviewsCompanyID: value.company_id
      }

      setTableFilter(filter)

      onAlertFilterChange(filter as any)

      setLoading(false)

      return { value, errors }
    },
    [onAlertFilterChange, tableFilter]
  )

  const tableState = useTableState<FilterValue>({
    onBeforeFilterChange,
    filter: {
      status: config.AnnualReviewsStatus,
      company_id: config.AnnualReviewsCompanyID
    }
  })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        ...tableFilter,
        annualReviewsOffset: (page - 1) * size,
        annualReviewsLimit: size
      }

      setTableFilter(filter)

      await onAlertFilterChange(filter)

      setLoading(false)
    },
    [onAlertFilterChange, tableFilter, tableState]
  )

  const onClear = async () => {
    tableState.onFilterClear()

    tableState.filterForm.set({ reset: true })

    setTableFilter(initialFilter)

    setLoading(true)

    await onAlertFilterChange(initialFilter)

    setLoading(false)
  }

  return {
    loading,
    changePagination,
    onClear,
    tableState
  }
}
