import { FC, memo } from 'react'

import { usStates } from 'commons/types/dictionaries'
import { Select } from 'ui/components'
import { FieldSet } from 'ui/components/FieldSet'
import { TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { MailingAddressInformationProps } from './MailingAddressInformationProps'

import * as SC from './styles'

export const MailingAddressInformation: FC<MailingAddressInformationProps> = memo(({ Field }) => (
  <SC.Box>
    <FieldSet legend="Mailing Address">
      <FormRow>
        <Col>
          <Field
            name="mailingStreet"
            label="Street"
            input={TextInput}
            inputProps={{
              'data-qa': 'Mailing Address Street'
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            name="mailingCity"
            label="City"
            input={TextInput}
            inputProps={{
              'data-qa': 'Mailing Address City'
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            name="mailingState"
            label="State/Province"
            input={Select}
            inputProps={{
              'data-qa': 'Mailing Address State/Province',
              'options-data-qa': 'Mailing Address State/Province',
              data: usStates,
              emptyOption: { label: 'Not selected', value: '' }
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            name="mailingPostalCode"
            label="Zip/Postal Code"
            input={TextInput}
            inputProps={{
              'data-qa': 'Mailing Address Zip/Postal Code'
            }}
          />
        </Col>
      </FormRow>
    </FieldSet>
  </SC.Box>
))
