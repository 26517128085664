import { useCallback, useEffect, useMemo, useState } from 'react'

import { CompanyHistory } from 'commons/types/DTO/bank/companyDetails/companyHistory'
import { HistoryEntity } from 'commons/types/DTO/bank/historyAudit'
import { handlerServerError } from 'errors'
import { useTableState } from 'ui/components/QueryTable'

import { useFilterCompanyHistoryWebPage } from './useFilterCompanyHistoryWebPage'

export const useCompanyHistory = (
  companyId: number,
  initialCompanyHistory: HistoryEntity[],
  initialCompanyHistoryTotalCount: number
) => {
  const [loading, setLoading] = useState(false)

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      historyLimit: undefined,
      historyOffset: undefined
    }),
    []
  )

  const [webPageData, setWebPageData] = useState<CompanyHistory>({
    history: initialCompanyHistory,
    historyTotalCount: initialCompanyHistoryTotalCount
  })

  useEffect(() => {
    setWebPageData({
      history: initialCompanyHistory,
      historyTotalCount: initialCompanyHistoryTotalCount
    })
  }, [initialCompanyHistory, initialCompanyHistoryTotalCount])

  const { routine: filterCompanyHistoryWebPage } = useFilterCompanyHistoryWebPage({
    onSuccess: async (_, result) => {
      await setWebPageData(result)
    },
    onError: (error) => handlerServerError(error)
  })

  const [tableFilter, setTableFilter] = useState<{
    relationshipID: number
    historyLimit: number | undefined
    historyOffset: number | undefined
  }>(defaultFilter)

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        ...tableFilter,
        historyOffset: (page - 1) * size,
        historyLimit: size
      }

      setTableFilter(filter)

      await filterCompanyHistoryWebPage(filter)

      setLoading(false)
    },
    [filterCompanyHistoryWebPage, tableFilter, tableState]
  )

  return {
    loading,
    webPageData,
    tableState,
    changePagination
  }
}
