import { useState } from 'react'

import { useCreateClientUser, useEditClientUser } from 'commons/hooks/bank/clientUser'
import { Toast } from 'ui/components'

import { CreateClientUserFormType, EditClientUserFormType } from '../../../types'

export const useUserFormRequest = (
  closeEditClientUserDialog: () => void,
  reloadClientUserList?: () => void,
  clientUserForEditingId?: string,
  handlerFormError?: (error: any) => void
) => {
  const [isSubmittingClientUserForm, setIsSubmittingClientUserForm] = useState<boolean>(false)

  const { routine: sendCreateClientUser } = useCreateClientUser({
    onSuccess: async () => {
      Toast.successSmall('Client user was successfully created!')
      if (reloadClientUserList) {
        reloadClientUserList()
      }
      setIsSubmittingClientUserForm(false)
      closeEditClientUserDialog()
    },
    onError: (error: any) => {
      setIsSubmittingClientUserForm(false)
      if (handlerFormError) {
        handlerFormError(error)
      }
    }
  })

  const createClientUser = (item: CreateClientUserFormType) => {
    const { firstName, lastName, email, phone } = item
    setIsSubmittingClientUserForm(true)
    if (firstName && lastName && email) {
      sendCreateClientUser({
        firstName,
        lastName,
        email,
        phone,
        assignedRelationships: item.relationships,
        assignedPermissions: [...Object.keys(item).filter((k) => item[k] === true)]
      })
    }
  }

  const { routine: sendEditClientUser } = useEditClientUser({
    onSuccess: async () => {
      Toast.successSmall('Client user was successfully edited!')
      if (reloadClientUserList) {
        reloadClientUserList()
      }
      setIsSubmittingClientUserForm(false)
      closeEditClientUserDialog()
    },
    onError: (error) => {
      setIsSubmittingClientUserForm(false)
      if (handlerFormError) {
        handlerFormError(error)
      }
    }
  })

  const editClientUser = (item: EditClientUserFormType) => {
    setIsSubmittingClientUserForm(true)
    if (clientUserForEditingId && item.relationships) {
      sendEditClientUser({
        id: clientUserForEditingId,
        assignedRelationships: item.relationships,
        assignedPermissions: [...Object.keys(item).filter((k) => item[k] === true)]
      })
    }
  }

  return {
    isSubmittingClientUserForm,
    createClientUser,
    editClientUser
  }
}
