import { InternalTransfersExportList } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'
import { DateTime } from 'ui/components/DateTime'
import { MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'

import { DownloadLinkWithDecoration, FilesWrapper } from './styles'

const getClearFilename = (name: string, forShow?: boolean) => {
  const split = name.split('/')
  if (!forShow) {
    return split.reverse()[0]
  }
  const longFileName = split.reverse()[0]
  const splitLongFileName = longFileName.split('_')

  return `${splitLongFileName[0]}_${splitLongFileName[1]}_${splitLongFileName[2]}`
}

const handleError = (message: string) => Toast.error('Error', message)

type Columns = {
  onProcess: (item: InternalTransfersExportList) => void
}

export const columns = ({ onProcess }: Columns): TableColumn<InternalTransfersExportList>[] => [
  {
    title: 'Files',
    render: (item) => {
      const url = Request.urls.bank.internalTransfersExportFileDirectDownload
      return (
        <FilesWrapper>
          {item.creditFileS3Key && (
            <DownloadLinkWithDecoration
              direct
              parametersForm={{
                internal_transfers_export_id: item.id,
                file_type: 'credit'
              }}
              baseUrl={url}
              name={getClearFilename(item.creditFileS3Key)}
              handleError={handleError}
            >
              {getClearFilename(item.creditFileS3Key, true)}
            </DownloadLinkWithDecoration>
          )}
          {item.debitFileS3Key && (
            <DownloadLinkWithDecoration
              direct
              parametersForm={{
                internal_transfers_export_id: item.id,
                file_type: 'debit'
              }}
              baseUrl={url}
              name={getClearFilename(item.debitFileS3Key)}
              handleError={handleError}
            >
              {getClearFilename(item.debitFileS3Key, true)}
            </DownloadLinkWithDecoration>
          )}
          {item.CSVFileS3Key && (
            <DownloadLinkWithDecoration
              direct
              parametersForm={{
                internal_transfers_export_id: item.id
              }}
              baseUrl={url}
              name={getClearFilename(item.CSVFileS3Key)}
              handleError={handleError}
            >
              {getClearFilename(item.CSVFileS3Key, true)}
            </DownloadLinkWithDecoration>
          )}
        </FilesWrapper>
      )
    }
  },
  {
    title: 'Status',
    key: 'status',
    align: 'center'
  },
  {
    title: 'Created Date',
    align: 'center',
    render: ({ createdAt }) => <DateTime date={createdAt} withTime />
  },
  {
    title: 'Processed Date',
    align: 'center',
    render: ({ processedAt }) => <DateTime date={processedAt} withTime />
  },
  {
    title: 'Actions',
    align: 'center',
    width: '80px',
    render: (item) =>
      item.enableProcess ? (
        <TableActions
          items={[
            {
              key: 1,
              title: 'PROCESS',
              behavior: MenuItemBehavior.BUTTON,
              textFace: MenuItemTextFace.GREY,
              onClick: () => onProcess(item)
            }
          ]}
        />
      ) : null
  }
]
