import { FC, memo } from 'react'

interface Props {
  value: string
}

export const UsPhoneLabel: FC<Props> = memo(({ value }) => {
  if (!value) {
    return <span>---</span>
  }

  // Remove (, ), - characters and spaces.
  const r = value.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\s+/g, '')

  let r2

  // If phone number starts with +, try to determine country. If it's not US,
  // phone number cannot be formatted. Otherwise, clear country prefix.
  if (r.startsWith('+')) {
    if (r[1] === '1') {
      r2 = r.substr(2)
    } else {
      r2 = ''
    }
  } else {
    r2 = r
  }

  if (!r2) {
    return <span>Invalid US phone number ({value})</span>
  }

  const r3 = r2.padEnd(10, ' ')

  return (
    <span>
      ({r3[0]}
      {r3[1]}
      {r3[2]}) {r3[3]}
      {r3[4]}
      {r3[5]}-{r3[6]}
      {r3[7]}
      {r3[8]}
      {r3[9]}
    </span>
  )
})
