import { useMutation, useQueryClient } from 'react-query'
import { WriteHook, useMutationManager, useStateManager } from 'react-query-state-manager'

import { handlerServerError } from 'errors'

import * as Service from '../../service'

export const useDelete = () => {
  const queryClient = useQueryClient()

  return useMutation((ids: number[]) => Promise.all(ids.map((id) => Service.SAR.remove({ id }))), {
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      await queryClient.invalidateQueries(['tables', 'SARs List'])
    }
  })
}

export const useDeleteSAR: WriteHook<number, void> = (opts) => {
  const sm = useStateManager()

  return useMutationManager((id) => Service.SAR.remove({ id }), {
    ...opts,
    onError: (error) => handlerServerError(error),
    onSuccess: async (payload, response) => {
      await sm.invalidate(['tables', 'SARs List'])
      if (opts?.onSuccess) await opts?.onSuccess(payload, response)
    }
  })
}
