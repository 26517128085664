import styled from 'styled-components'

export const Container = styled.div`
  @media print {
    section {
      border-top-width: 0px;
      margin: 20px 0;
      break-inside: avoid;
    }
  }
`
