import { CheckBoxGroup, DatePicker, FF, RadioGroup, Select, TextInput, TextareaInput } from 'ui/components'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { questionnaire3Labels } from '~bank-annual-review/constants/questionnaireLabels'

import { useAnnualReviewFormContext } from '../../../context/ManageAnnualReviewContext'

export const Questionnaire3Form = () => {
  const { formApi } = useAnnualReviewFormContext()

  if (!formApi.state.values.questionnaire3) return null

  return (
    <>
      <PageSection title="Financials">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.financialsPeriodCollected}
              children={
                <formApi.Field
                  name="questionnaire3.financialsPeriodCollected"
                  children={(field) => <FF field={field} input={(inputProps) => <TextInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaire3Labels.financialsDepositsMatching}
              children={
                <formApi.Field
                  name="questionnaire3.financialsDepositsMatching"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Transactions">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.transactionsTypes}
              children={
                <formApi.Field
                  name="questionnaire3.transactionsTypes"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <CheckBoxGroup
                          direction="horizontal"
                          data={[
                            {
                              label: 'Cash',
                              value: 'Cash'
                            },
                            {
                              label: 'ATM',
                              value: 'ATM'
                            },
                            {
                              label: 'ACH',
                              value: 'ACH'
                            },
                            {
                              label: 'Check Deposit',
                              value: 'Check Deposit'
                            }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire3Labels.transactionsVolProjected}
              children={
                <formApi.Field
                  name="questionnaire3.transactionsVolProjected"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values?.questionnaire3?.transactionsVolProjected !== 'No' && (
                    <Q
                      children={
                        <formApi.Field
                          name="questionnaire3.transactionsVolProjectedExactly"
                          children={(field) => (
                            <FF
                              field={field}
                              input={(inputProps) => (
                                <Select
                                  data={[
                                    { label: 'Higher', value: 'Higher' },
                                    { label: 'Lower', value: 'Lower' }
                                  ]}
                                  {...inputProps}
                                />
                              )}
                            />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />

            <Q
              label={questionnaire3Labels.transactionsExpectExactly}
              children={
                <formApi.Field
                  name="questionnaire3.transactionsExpectExactly"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Increase', value: 'Increase' },
                            { label: 'Decrease', value: 'Decrease' },
                            { label: 'Stable', value: 'Stable' },
                            { label: 'Unknown', value: 'Unknown' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values?.questionnaire3?.transactionsExpectExactly === 'Unknown' && (
                    <Q
                      label={questionnaire3Labels.transactionsExpectExplain}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire3.transactionsExpectExplain"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />

            <Q
              label={questionnaire3Labels.transactionsMatchTrans}
              children={
                <formApi.Field
                  name="questionnaire3.transactionsMatchTrans"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire3.transactionsMatchTrans === 'No' && (
                    <Q
                      label={questionnaire3Labels.transactionsNotes}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire3.transactionsNotes"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Covenants/Agreements">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.covenantComplying}
              children={
                <formApi.Field
                  name="questionnaire3.covenantComplying"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire3Labels.covenantNonComplianceDesc}
              multiline
              children={
                <formApi.Field
                  name="questionnaire3.covenantNonComplianceDesc"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaire3Labels.covenantTransparent}
              children={
                <formApi.Field
                  name="questionnaire3.covenantTransparent"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Site Visits">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.siteVisitsLastVisitDate}
              children={
                <formApi.Field
                  name="questionnaire3.siteVisitsLastVisitDate"
                  children={(field) => <FF field={field} input={(inputProps) => <DatePicker {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaire3Labels.siteVisitsComplyingRegulations}
              children={
                <formApi.Field
                  name="questionnaire3.siteVisitsComplyingRegulations"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire3Labels.siteVisitsViolationRectified}
              children={
                <formApi.Field
                  name="questionnaire3.siteVisitsViolationRectified"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Ownership & Management">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.ownershipChanges}
              children={
                <formApi.Field
                  name="questionnaire3.ownershipChanges"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />

            <Q
              label={questionnaire3Labels.ownershipOFACTLOReviewed}
              children={
                <formApi.Field
                  name="questionnaire3.ownershipOFACTLOReviewed"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire3Labels.ownershipBadNews}
              children={
                <formApi.Field
                  name="questionnaire3.ownershipBadNews"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire3.ownershipBadNews === 'Yes' && (
                    <Q
                      label={questionnaire3Labels.ownershipBadNewsDesc}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire3.ownershipBadNewsDesc"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
            <Q
              label={questionnaire3Labels.ownershipBadNewsCleared}
              children={
                <formApi.Field
                  name="questionnaire3.ownershipBadNewsCleared"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire3.ownershipBadNewsCleared === 'No' && (
                    <Q
                      label={questionnaire3Labels.ownershipBadNewsClearedExplain}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire3.ownershipBadNewsClearedExplain"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Business Plans">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.businessPlansSell}
              children={
                <formApi.Field
                  name="questionnaire3.businessPlansSell"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire3.businessPlansSell === 'Yes' && (
                    <Q
                      label={questionnaire3Labels.businessPlansSellDesc}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire3.businessPlansSellDesc"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
            <Q
              label={questionnaire3Labels.businessPlansExpand}
              children={
                <formApi.Field
                  name="questionnaire3.businessPlansExpand"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire3.businessPlansExpand === 'Yes' && (
                    <Q
                      label={questionnaire3Labels.businessPlansExpandDesc}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire3.businessPlansExpandDesc"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
            <Q
              label={questionnaire3Labels.businessPlansNewAgreementsWithOther}
              children={
                <formApi.Field
                  name="questionnaire3.businessPlansNewAgreementsWithOther"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire3.businessPlansNewAgreementsWithOther === 'Yes' && (
                    <Q
                      label={questionnaire3Labels.businessPlansNewAgreementsWithOtherDesc}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire3.businessPlansNewAgreementsWithOtherDesc"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Recommendations">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.recommendationsRecommended}
              children={
                <formApi.Field
                  name="questionnaire3.recommendationsRecommended"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
    </>
  )
}
