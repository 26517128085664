import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Date = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((s) => s.values.suspiciousActivityInfo.cyberEventIndicators[index].type)

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `suspiciousActivityInfo.cyberEventIndicators[${index}].date` as 'suspiciousActivityInfo.cyberEventIndicators[0].date'
      }
      children={(field) => (
        <FF
          label="Date"
          field={field}
          input={(inputProps) => (
            <DatePicker
              {...inputProps}
              disabled={!(type && ['Command and control IP address', 'Suspicious IP address'].includes(type))}
            />
          )}
        ></FF>
      )}
    />
  )
}
