import { FC, memo } from 'react'

import { DetailsProps } from './DetailsProps'

import * as SC from './styles'

export const Details: FC<DetailsProps> = memo((props) => {
  const { permissions } = props

  if (!permissions.length) {
    return null
  }

  return (
    <>
      {permissions.map((permission, index) => (
        <div key={index}>
          {permission.label}
          <SC.Nested>
            <Details permissions={permission.nested.filter((i) => i.enabled)} />
          </SC.Nested>
        </div>
      ))}
    </>
  )
})
