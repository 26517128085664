import { FC, memo } from 'react'

import { formatDateTime } from 'ui/components/DateTime'
import { IconSize } from 'ui/components/Icon'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'
import { Col, Row } from 'ui/themes/globalStyles'

import { FilingInstitutionContactInformationProps } from './FilingInstitutionContactInformation.types'

export const FilingInstitutionContactInformation: FC<FilingInstitutionContactInformationProps> = memo((props) => {
  const { data } = props
  const {
    institutionContactInfo: {
      type,
      typeOther,
      federalRegulator,
      filerName,
      tin,
      tinType,
      idType,
      idNumber,
      address,
      city,
      country,
      state,
      zipCode,
      dba,
      internalControlNumber,
      leContactAgency,
      leContactName,
      leContactPhone,
      leContactPhoneExt,
      leContactDate,
      contactOffice,
      phone,
      phoneExt
    },
    filedDate
  } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 1. Filing Institution Contact Information" anchor="step1">
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <Row>
          <Col>
            <Info label="79. Type of financial institution">
              {type === 'Other' && typeOther}
              {type !== 'Other' && type}
            </Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="75. Primary federal regulator">{federalRegulator}</Info>
          </Col>
        </Row>
        <Row>
          <Info label="76. Filer Name	">{filerName}</Info>
        </Row>
        <Row>
          <Col>
            <Info label="77. TIN">{tin ? <TextMask text={tin} iconSize={IconSize.XS} /> : null}</Info>
          </Col>
          <Col>
            <Info label="78. TIN type">{tinType}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="81. Filing institution ID Type">{idType}</Info>
          </Col>
          <Col>
            <Info label="Number">{idNumber}</Info>
          </Col>
        </Row>
        <Row>
          <Info label="82-86. Address">
            <div>{address}</div>
            <div>{[city, country === 'US' ? state : null, zipCode].filter(Boolean).join(', ')}</div>
            <div>{country}</div>
          </Info>
        </Row>
        <Row>
          <Info label="87. Alternate name">{dba}</Info>
        </Row>
        <Row>
          <Info label="88. Internal control/file number">{internalControlNumber}</Info>
        </Row>
        <Row>
          <Col>
            <Info label="89. LE contact agency">{leContactAgency}</Info>
          </Col>
          <Col>
            <Info label="90. LE contact name">{leContactName}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="91. LE contact phone number">{leContactPhone}</Info>
          </Col>
          <Col>
            <Info label="Ext.">{leContactPhoneExt}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="92. LE contact date">{leContactDate}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="93. Contact office">{contactOffice}</Info>
          </Col>
          <Col>
            <Info label="94. Phone number">{phone}</Info>
          </Col>
        </Row>
        <Row>
          {filedDate && (
            <Col>
              <Info label="57. Date Filed">{formatDateTime(filedDate)}</Info>
            </Col>
          )}
          <Col>
            <Info label="Ext.">{phoneExt}</Info>
          </Col>
        </Row>
      </InfoSet>
    </PageSection>
  )
})
