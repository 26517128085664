import { FC, Fragment, ReactElement, ReactNode, memo, useMemo } from 'react'

import { RouterProvider } from '@tanstack/react-router'
import { PortalName } from 'commons/types/enums'
import isObject from 'lodash/isObject'
import { Spinner } from 'ui/components/Spinner'

import { AuthContext } from './AuthContext'
import { getRouter } from './authRouter'
import { IdleWarningDialog } from './components/IdleWarningDialog'
import * as SC from './components/style'
import { AUTH_PATH } from './constants'
import { useSmartRedirect } from './hooks/router'
import { useLogoutAuth, useManageUserSession } from './hooks/user'
import { SessionInfo } from './types/DTO'

interface AuthContainerProps {
  themeName: string
  portalName: PortalName
  children: ReactNode
  backgroundElement: ReactElement
  logoElement: ReactElement
  idleTimeoutInSeconds?: number
  footer?: ReactNode
  sessionInfo: SessionInfo
}

const Container: FC<AuthContainerProps> = memo(
  ({ themeName, sessionInfo, children, portalName, backgroundElement, logoElement, footer }) => {
    const { session, isFetching } = sessionInfo
    const { logout } = useLogoutAuth({ portalName })

    // check user activity and prolongation session
    // notion -> frontend: Auth flow
    const { isDialogShowed: isShowIdlePrompt, onSessionExtend } = useManageUserSession({ portalName })

    const contextValue = { themeName, portalName, logout }

    // references:
    // eslint-disable-next-line max-len
    // https://github.com/HeliosCompliance/orbit-auth/blob/master/src/hooks/helpers/saveTokenAndRedirectToProperPage.ts#L15
    // eslint-disable-next-line max-len
    // https://github.com/HeliosCompliance/orbit-auth/blob/b2a601320f5f29ec0a799421088b39e854231600/src/hooks/router/useSmartRedirect.ts#L16
    const isUserFetching = !session && isFetching
    const hasSession = session && isObject(session)
    const isOnAuthRoutes = !!window.location.pathname.startsWith('/' + AUTH_PATH)

    const router = useMemo(
      () => getRouter({ hasSession, logoElement, footer, portalName, backgroundElement }),
      [hasSession, logoElement, footer, portalName, backgroundElement]
    )

    return (
      <AuthContext.Provider value={contextValue}>
        {isOnAuthRoutes && !hasSession && backgroundElement}
        {isUserFetching && (
          <SC.SpinnerContainer>
            <Spinner centered size={64} />
          </SC.SpinnerContainer>
        )}
        {!isFetching && (
          <>
            {hasSession ? <Fragment key={session.expires_at}>{children}</Fragment> : <RouterProvider router={router} />}
            {hasSession && isShowIdlePrompt && (
              <IdleWarningDialog hasUser={hasSession} onContinue={onSessionExtend} onLogout={() => logout({})} />
            )}
          </>
        )}
      </AuthContext.Provider>
    )
  }
)

// took this hook out of AuthContainer due to it often causes rerenders of all subtree
export const AuthContainer = (props) => {
  // Detect any route changes and page load to move unauthorized user to login page,
  // or move user with restricted rights to proper route.
  const sessionInfo = useSmartRedirect(props.portalName)

  return <Container sessionInfo={sessionInfo} {...props} />
}
