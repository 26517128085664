import { FC, memo } from 'react'

import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'

import { DueTooltipProps } from './DueTooltipProps'

import { DueCount, DueRow, TooltipContent } from './styles'

export const DueTooltip: FC<DueTooltipProps> = memo((props) => {
  const {
    id,
    firstAlerted,
    initialAlerted,
    pastDue,
    lastAlerted,
    expiration_date,
    firstAlert,
    initialAlert,
    lastAlert
  } = props

  return (
    <DueRow>
      {(!!firstAlerted || !!lastAlerted || !!initialAlerted) && (
        <DueCount>
          <Tooltip
            id={`firstHint${id}`}
            type="warning"
            event="hover"
            content={
              <>
                {[
                  !!initialAlerted && `${initialAlerted} Document(s) Due in ${initialAlert} days or less`,
                  !!firstAlerted && `${firstAlerted} Document(s) Due in ${firstAlert} days or less`,
                  !!lastAlerted && `${lastAlerted} Document(s) Due in ${lastAlert} days or less`
                ]
                  .filter(Boolean)
                  .join(', ')}
              </>
            }
          >
            <TooltipContent>
              <Icon name={IconName.EXCLAMATION_ORANGE} size={IconSize.S} />{' '}
              {firstAlerted + lastAlerted + initialAlerted}
            </TooltipContent>
          </Tooltip>
        </DueCount>
      )}
      {!!pastDue && (
        <DueCount>
          <Tooltip id={`pastDue${id}`} type="error" event="hover" content={<>{`${pastDue} Past Due`}</>}>
            <TooltipContent>
              <Icon name={IconName.EXCLAMATION} size={IconSize.S} /> {pastDue}
            </TooltipContent>
          </Tooltip>
        </DueCount>
      )}
      {!expiration_date && !pastDue && (
        <DueCount>
          <Icon name={IconName.CHECK_GREEN} size={IconSize.S} />
        </DueCount>
      )}
    </DueRow>
  )
})
