import { z } from 'zod'

export const SARDetailsSearchSchema = z.object({
  company_id: z.number().int().positive().optional(),
  status: z.string().optional(),
  trans_start_date: z.string().optional(),
  trans_end_date: z.string().optional()
})

export type SARDetailsSearchSchemaType = z.infer<typeof SARDetailsSearchSchema>
