import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const State = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const country = formApi.useStore((s) => s.values.institutionActivitiesInfo[index].country)

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].state` as 'institutionActivitiesInfo[0].state'}
      validators={{
        onChange: ({ value }) =>
          country !== 'US' || !!value
            ? undefined
            : '"63. State" required when choose "United States of America" as "65. Country"'
      }}
      children={(field) => (
        <FF
          required={country === 'US'}
          label="63. State"
          field={field}
          input={(p) => <Select {...p} data={usStates} data-qa="63. State" options-data-qa="63. State option" />}
        />
      )}
    />
  )
}
