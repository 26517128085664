import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { throttle } from 'lodash'
import { CurrencyInput, FF, Tooltip } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

const CurrentAmountRenderer = ({ field }: { field: FieldApi<SarEdit, 'suspiciousActivityInfo.currentAmount'> }) => {
  const { formApi } = useSARFormContext()
  const initCurrentAmount = formApi.useStore((s) => s.values.suspiciousActivityInfo.currentAmount)

  useEffect(
    throttle(() => {
      let currentAmount = 0
      formApi.state.values.transactionsInfo?.forEach((bankAccount) => {
        Object.entries(bankAccount).forEach(([key, value]) => {
          if (typeof value === 'number' && value > 0 && key !== 'total' && key !== 'totalIn' && key !== 'totalOut')
            currentAmount += Number(value)
        })
      })

      // Don't update on the first render
      if (Math.ceil(currentAmount) !== initCurrentAmount * 100) {
        formApi.setFieldValue('suspiciousActivityInfo.currentAmount', Math.ceil(currentAmount / 100))
      }
    }, 100),
    [formApi, formApi.state.values.transactionsInfo]
  )

  return (
    <FF
      field={field}
      input={(p) => (
        <Tooltip
          content="The amount is automatically calculated based on the Transactions Information section"
          id="current_amount"
          type="dark"
        >
          <CurrencyInput {...p} disabled />
        </Tooltip>
      )}
    />
  )
}

export const CurrentAmount = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="suspiciousActivityInfo.currentAmount"
      children={(field) => <CurrentAmountRenderer field={field} />}
    />
  )
}
