// /annual-review
// /annual-review/detail/$companyId/$id
// /annual-review/edit/$companyId/$id
// /annual-review/edit-sales-and-deposit/$companyId/$annualReviewId/$salesAndDepositId
import { createRoute, redirect } from '@tanstack/react-router'

import { AnnualReviewWrapper } from './AnnualReviewWrapper'
import { annualReviewDetailsRoute } from './Routes/AnnualReview/AnnualReviewDetail'
import { annualReviewEditRoute } from './Routes/AnnualReview/AnnualReviewEdit'
import { annualReviewSalesDepositsRoute } from './Routes/AnnualReview/AnnualReviewSalesDeposits'

export const routeAnnualReviewsTree = (rootRoute: BPRootRoute) => {
  const annualReviewsIndexRoute = createRoute({
    beforeLoad: async ({ context }) => {
      if (!context.accessibleRoutingFlags.annualReviewRootAccess) {
        throw redirect({
          to: '/'
        })
      }
    },
    getParentRoute: () => rootRoute,
    path: 'annual-review',
    component: AnnualReviewWrapper
  })

  return annualReviewsIndexRoute.addChildren([
    annualReviewEditRoute<typeof annualReviewsIndexRoute>(annualReviewsIndexRoute),
    annualReviewSalesDepositsRoute<typeof annualReviewsIndexRoute>(annualReviewsIndexRoute),
    annualReviewDetailsRoute<typeof annualReviewsIndexRoute>(annualReviewsIndexRoute)
  ])
}
