import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

import { useRouter, useRouterState } from '@tanstack/react-router'
// eslint-disable-next-line max-len
import { InternalDocuments as InternalDocumentsType } from 'commons/types/DTO/bank/applications/manage/InternalDocuments'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { DataSourceItem, Table } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { useDeleteApplicationDocumentUpload } from '../../../../hooks/useDeleteApplicationDocumentUpload'
import { ReviewFormUploadWrap } from '../ApplicationControls/components'
import { InternalDocumentsProps } from './InternalDocumentsProps'
import { columns } from './columns'

import { Header, Left, Wrapper } from './styles'

type DocumentForDelete = {
  id: number
  name: string
}

export const InternalDocuments = ({ application }: InternalDocumentsProps) => {
  const {
    location: { hash }
  } = useRouterState()
  const router = useRouter()
  const { enableInternalDocumentUpload, applicationID, internalDocuments } = application
  const { deleteApplicationDocumentUpload } = useDeleteApplicationDocumentUpload()

  const mappedInternalDocuments: DataSourceItem<InternalDocumentsType>[] = useMemo(
    () =>
      !internalDocuments
        ? []
        : internalDocuments.map((el) => ({
            item: el
          })),
    [internalDocuments]
  )

  const [documentForDelete, setDocumentForDelete] = useState<DocumentForDelete | undefined>(undefined)

  const deleteInternalDocument = useCallback(async () => {
    try {
      if (documentForDelete?.id) {
        await deleteApplicationDocumentUpload(applicationID, documentForDelete?.id)
      }
      Toast.success('Internal Document has been deleted')
      router.invalidate()
    } catch (error) {
      Toast.error('A trouble was occurred while deleting')
    }
  }, [deleteApplicationDocumentUpload, router, documentForDelete, applicationID])

  const { Confirmation: DeleteInternalDocConfirmation, open: openDeleteInternalDocConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>{documentForDelete?.name || 'this'}</i> document?
      </span>
    ),
    onConfirm: () => deleteInternalDocument(),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDeleteDocument = useCallback(
    (id: number, name: string) => {
      setDocumentForDelete({ id, name })
      openDeleteInternalDocConfirmation()
    },
    [openDeleteInternalDocConfirmation]
  )

  useLayoutEffect(() => {
    if (hash === '#internal-documents') {
      document.getElementById('internal-documents')?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [hash])

  return (
    <Wrapper>
      <DeleteInternalDocConfirmation />
      <section id="internal-documents">
        <Header>
          <Left>
            <h3>Internal Documents</h3>
            <span>Not visible for an applicant </span>
          </Left>
          {enableInternalDocumentUpload && (
            <ReviewFormUploadWrap
              application={application}
              title="Internal Document Upload"
              onlyNotes={false}
              requiredNotes={false}
              notes={''}
              buttonName="Upload document"
              disabledDocumentType={false}
            />
          )}
        </Header>
        <Layer rounded shadowed stretch>
          <div id="internalDocumentsContainer" />
          <Table<InternalDocumentsType>
            dataQa={TableDataQa.BP_ONBOARDING_APPLICATION_INTERNAL_DOCUMENTS}
            columns={columns(onDeleteDocument)}
            dataSource={mappedInternalDocuments}
          />
        </Layer>
      </section>
    </Wrapper>
  )
}
