import { FC } from 'react'

import { Chip, ChipFace } from 'ui/components'

import { getAnnualReviewStatusLabel } from './getAnnualReviewStatusLabel'

export interface AnnualReviewStatusLabelProps {
  status: string
}

const stateMap: { [key: string]: ChipFace } = {
  complete: ChipFace.DEFAULT,
  completed: ChipFace.DEFAULT,
  approved: ChipFace.DEFAULT,
  new: ChipFace.PRIMARY,
  incomplete: ChipFace.DANGER
}

export const AnnualReviewStatusLabel: FC<AnnualReviewStatusLabelProps> = ({ status, ...rest }) => (
  <Chip face={stateMap[status.toLowerCase()]} data-qa="Status" {...rest}>
    {getAnnualReviewStatusLabel(status)}
  </Chip>
)
