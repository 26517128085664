// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Keys, ReadHook, useStateManager } from 'state-manager'
import { getWPDocumentList } from '~bank-documents/service/documentList'
import { DocumentListV2RequestPayload, DocumentWP2Response } from '~bank-documents/types/documentListV2'

export const useLoadDocumentListWebPage: ReadHook<
  DocumentWP2Response,
  {
    requestPayload: DocumentListV2RequestPayload
    tableId: string
  }
> = (opts) =>
  useStateManager().use(
    Keys.Document.ListTable({ tableId: opts.tableId, filterValue: opts.requestPayload }),
    () => getWPDocumentList(opts.requestPayload),
    { keepPreviousData: true }
  )
