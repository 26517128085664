import { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import { FooterProps } from './Footer.types'

import { s } from './styles'

export const Footer: FC<FooterProps> = memo(({ onDelete, isSubmitting, disabled, onSubmit }) => (
  <s.Box>
    {!!onDelete && (
      <s.DeleteButton data-qa="Delete" icon={IconName.DELETE} face="negative" onClick={onDelete}>
        Delete
      </s.DeleteButton>
    )}
    <ButtonGroup margin="small">
      <Button face="neutral" onClick={() => history.back()}>
        Cancel
      </Button>
      <Button
        data-qa="Submit"
        type="submit"
        face="positive"
        onClick={onSubmit}
        loading={isSubmitting}
        disabled={disabled}
      >
        Submit
      </Button>
    </ButtonGroup>
  </s.Box>
))
