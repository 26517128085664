import { FC } from 'react'

import { Info } from 'ui/components'
import { Col2x, Row } from 'ui/themes/globalStyles'

import { NotesFormValue } from '../../types'

export const NotesDetails: FC<{
  value: NotesFormValue
}> = ({ value }) => (
  <Row>
    <Col2x>
      <Info label="Note for the bank">{value.clientNotes}</Info>
    </Col2x>
  </Row>
)
