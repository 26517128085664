import { FC, memo, useCallback } from 'react'

import isEqual from 'lodash/isEqual'
import { Button } from 'ui/components/Buttons'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { EMPTY_PERSON_INFO } from '~bank-bsa-reporting/hooks/ctr/useManageCTR'
import { CTRPersonsInfo } from '~bank-bsa-reporting/types'

import { PersonsInvolvedInformationListProps } from './PersonsInvolvedInformationList.types'
import { PersonInvolvedInformationForm } from './components'

export const PersonsInvolvedInformationList: FC<PersonsInvolvedInformationListProps> = memo((props) => {
  const { data, errors, onChange, validateFunctionRefs, markFormAsDirty } = props
  const { personsInfo } = data

  const onChangeWrapper = useCallback(
    (i: number, v: CTRPersonsInfo, e) => {
      const valueChanged = !isEqual(v, personsInfo[i])
      const errorsChanged = !isEqual(e, errors[i])

      if (valueChanged) {
        personsInfo[i] = v
      }
      if (errorsChanged) {
        errors[i] = e
      }
      if (valueChanged || errorsChanged) {
        onChange(personsInfo, errors)
      }
    },
    [personsInfo, onChange, errors]
  )

  const onAddWrapper = useCallback(() => {
    onChange(personsInfo.concat(EMPTY_PERSON_INFO), errors)

    markFormAsDirty()
  }, [onChange, personsInfo, errors, markFormAsDirty])

  const onDeleteWrapper = useCallback(
    (index: number) => {
      const copy = [...personsInfo]
      copy.splice(index, 1)
      onChange(copy, errors)

      markFormAsDirty()
    },
    [personsInfo, onChange, errors, markFormAsDirty]
  )

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 3. Person(s) Involved Information" anchor="step3">
      {personsInfo.map((item, index) => (
        <PersonInvolvedInformationForm
          key={index}
          value={item}
          errors={errors[index]}
          onChange={onChangeWrapper}
          onDelete={personsInfo.length > 1 ? onDeleteWrapper : undefined}
          index={index}
          total={personsInfo.length}
          validateFunctionRefs={validateFunctionRefs}
          markFormAsDirty={markFormAsDirty}
        />
      ))}
      <Button face="positive" onClick={onAddWrapper}>
        Add
      </Button>
    </PageSection>
  )
})
