import { useState } from 'react'

import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { hooks } from '../../../hooks'
import { UsersWebPageResponse } from '../../../types'

export const useChangeStatus = (onChanged: (forceToLogout: boolean) => void) => {
  const [userToChangeStatus, setUserToChangeStatus] = useState<UsersWebPageResponse['users'][0] | null>(null)
  const active = userToChangeStatus?.active || false
  const name = `${userToChangeStatus?.firstName} ${userToChangeStatus?.lastName}`.trim() || 'this'

  const { routine } = hooks.useChangeActiveStatus({
    onSuccess: async (payload) => {
      onChanged(userToChangeStatus !== null && userToChangeStatus.isCurrentUser && !payload.active)
      Toast.success('Success', 'User was saved.')
    },
    onError: (error) => Toast.errorSmall(error.message)
  })

  const { Confirmation: UserStatusConfirmation, open } = useConfirmation({
    message: (
      <span>
        Are you sure want to {active ? 'deactivate' : 'activate'} <i>{name}</i> user?
      </span>
    ),
    onConfirm: () => {
      if (userToChangeStatus) {
        const { id, active } = userToChangeStatus
        routine({ id, active: !active })
      }
    },
    confirmationButtonText: active ? 'Deactivate' : 'Activate',
    isConfirmationNegative: !!active
  })

  const onChangeStatus = (user: UsersWebPageResponse['users'][0]) => {
    setUserToChangeStatus(user)
    open()
  }

  return {
    onChangeStatus,
    UserStatusConfirmation
  }
}
