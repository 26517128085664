import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useNavigate } from '@tanstack/react-router'
import { BeforeFormChangeHandler, Form, useFormData } from 'brief-form'
import styled from 'styled-components'
import { LoginButton } from 'ui/components/Buttons'
import { Validators } from 'ui/components/Form/validators'
import { Select } from 'ui/components/Select'
import { AuthForm } from '~auth/components'
import { AuthMFAState } from '~auth/types/DTO'

interface Props {
  onSubmit: (value: ChooseDeviceFormShape) => Promise<any>
  logoElement: React.ReactElement
}

export type ChooseDeviceFormShape = {
  id: number
  type: string
  name: string
}

//region Styled

const Hint = styled.div`
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.44px;
  color: #fff;
  margin-bottom: 10px;
`

const Footer = styled.div`
  margin-top: 20px;
`

//endregion

export const ChooseDeviceForm: FC<Props> = memo(({ onSubmit, logoElement }) => {
  const client = useQueryClient()
  const navigate = useNavigate()
  const mfa = client.getQueryData<AuthMFAState>(['mfa'])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')

  const onBeforeChange: BeforeFormChangeHandler<ChooseDeviceFormShape> = useCallback(
    ({ value, errors }) => {
      setError('')
      const item = (mfa?.devices || []).find((d) => +d.id === +value.id)
      if (item) {
        return {
          value: {
            id: item.id,
            name: item.phone_number || item.email || `Application #${item.id}`,
            type: item.type
          },
          errors
        }
      }

      return {
        value,
        errors
      }
    },
    [setError]
  )

  const { config, Field } = useFormData<ChooseDeviceFormShape>({
    onBeforeChange,
    initialValue: { id: mfa?.chosen?.id ?? 0, type: '', name: '' }
  })

  const { value } = config

  // If we even haven't started login process, no reason to show choose device form.
  useEffect(() => {
    if (!mfa) {
      navigate({ to: '/auth/login', replace: true })
    }
  }, [mfa, navigate])

  const submitWrapper = useCallback(() => {
    setLoading(true)
    onSubmit(value)
      .catch((error) => {
        setError(error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [value, onSubmit, setError, setLoading])

  return (
    <AuthForm error={error} logo={logoElement}>
      <Hint>Choose preferred device to get security code:</Hint>
      <Form config={config}>
        <Field
          required
          name="id"
          input={Select}
          validator={Validators.required.string}
          inputProps={{
            data: (mfa?.devices || []).map((d) => ({
              label: d.phone_number || d.email || `Application #${d.id}`,
              value: d.id
            }))
          }}
        />
      </Form>
      <Footer>
        <LoginButton onClick={submitWrapper} loading={loading}>
          SEND CODE
        </LoginButton>
      </Footer>
    </AuthForm>
  )
})
