import { forwardRef, memo, useCallback, useEffect, useState } from 'react'

import { BaseInput } from './BaseInput'
import { BaseInputProps } from './props'
import { Value } from './types'

const MAX_LENGTH_DEFAULT = 15

export const NumberInput = memo(
  forwardRef<HTMLInputElement, BaseInputProps>((props: BaseInputProps, ref) => {
    const { value, onChange, maxLength, ...rest } = props
    const [internalValue, setInternalValue] = useState(value)

    useEffect(() => {
      setInternalValue(value)
    }, [value])

    const handleChange = useCallback(
      (v: Value, error?: string) => {
        let value = v
        if (value) {
          value = value
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*?)\..*/g, '$1')
        }
        setInternalValue(value)
        if (onChange) {
          onChange(value, error)
        }
      },
      [onChange]
    )

    return (
      <BaseInput
        ref={ref}
        value={internalValue}
        onChange={handleChange}
        maxLength={maxLength || MAX_LENGTH_DEFAULT}
        {...rest}
      />
    )
  })
)
