import { FC } from 'react'

import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { Layer, PageSection } from 'ui/components'

import { HistorySectionProps } from './HistorySectionProps'

export const HistorySection: FC<HistorySectionProps> = ({ loading, historyItems, historyPagination, tableDataQa }) => (
  <PageSection title="History" anchor="history">
    <Layer rounded shadowed stretch>
      <HistoryTable dataQa={tableDataQa} loading={loading} items={historyItems} pagination={historyPagination} />
    </Layer>
  </PageSection>
)
