import { getInternalTransferList } from 'commons/service/bank/internalTransfer'
import { InternalTransferDeprecated } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'

export const getHandler = (id?: number) => (): Promise<ApiReadResponse<InternalTransferDeprecated>> =>
  id
    ? getInternalTransferList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } })
    : Promise.resolve({
        records: [
          {
            amount: 0,
            approval_date: null,
            approvals_count: 0,
            created_at: null,
            date: null,
            deleted_at: null,
            export_id: null,
            history: [],
            id: 0,
            manifest_number: null,
            notes: null,
            recipient_license_id: 0,
            required_approvals_count: 0,
            sender_license_id: 0,
            sf_external_id: null,
            status: 'pending_approval',
            updated_at: null
          }
        ]
      })
