import { memo } from 'react'

import { Validators } from 'ui/components/Form'

import { BaseInput } from './BaseInput'
import { BaseInputProps } from './props'

type BankAccountInputProps = Omit<BaseInputProps, 'value' | 'onChange'> & {
  value?: string
  onChange?: (value: string) => void
}

export const BankAccountInput = memo((props: BankAccountInputProps) => {
  return <BaseInput {...props} validator={Validators.bankAccountNumber.valid} />
})
