import styled from 'styled-components'
import { Layer } from 'ui/components/Layer'

const WrappedLayer = styled(Layer)`
  display: inline-block;
  margin-bottom: 47px;
  width: 100%;
`

const Box = styled.div`
  border-top: 2px solid #000;
`

const Title = styled.h2`
  padding: 10px 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
`

const Columns = styled.div`
  display: flex;
`

const Labels = styled.div`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  padding: 38px 0 0 0;
`

const Label = styled.div`
  height: 34px;
  padding: 9px 16px;
  font-size: 13px;
  line-height: 16px;
`

const Table = styled.table`
  border-collapse: collapse;
`

const Th = styled.th<{ $bottomBorder?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 8px 30px;
  border-right: 1px solid #fff;
  ${(p) => (p.$bottomBorder ? 'border-bottom: 1px solid #fff' : '')};

  &:last-child {
    border-right: none;
  }
`

const Td = styled.td<{ $hl?: boolean }>`
  font-size: 13px;
  line-height: 16px;
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$hl ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};

  &:last-child {
    border-right: none;
  }
`

const Stub = styled.td`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
`

const TV = styled.span<{ $negative?: boolean }>`
  color: ${(p) =>
    p.$negative ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.secondary};
`

export const s = { WrappedLayer, Box, Title, Columns, Labels, Label, Table, Th, Td, Stub, TV }
