import * as SC from '../../styles'
import { IsConductorProps } from './IsConductorProps'

export const IsConductor = ({ contact }: IsConductorProps) => {
  const { isConductor } = contact

  return 'isConductor' in contact ? (
    <SC.LabelValue>
      <div>
        Is this individual/entity a <b>Conductor</b>?
      </div>
      <SC.Value>{isConductor ? 'Yes' : 'No'}</SC.Value>
    </SC.LabelValue>
  ) : (
    <></>
  )
}
