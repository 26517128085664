import { useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { useDeleteAnnualReview } from '~bank-annual-review/service/AnnualReview/deleteAnnualReview'

type UseDeleteAnnualReviewArgs = { onSuccess?: () => void }

export const useDeleteAnnualReviewConfirmation = ({ onSuccess }: UseDeleteAnnualReviewArgs) => {
  const router = useRouter()

  const [arForDelete, setArForDelete] = useState<number | null>(null)

  const { routine: deleteAnnualReview } = useDeleteAnnualReview({
    onSuccess: async () => {
      Toast.successSmall(`Annual Review was successfully deleted.`)

      if (onSuccess) onSuccess()
      else router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  const { Confirmation, open } = useConfirmation({
    message: <>Are you sure want to delete "AR-{arForDelete}" Annual Review?</>,
    onConfirm: () => {
      if (arForDelete) deleteAnnualReview(arForDelete)
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true,
    onCancel: () => setArForDelete(null)
  })

  const openDeleteAnnualReviewConfirmation = (id: number) => {
    setArForDelete(id)
    open()
  }

  return { DeleteConfirmation: Confirmation, openDeleteAnnualReviewConfirmation }
}
