import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type CashInProps = {
  index: number
}

export const CashIn = ({ index }: CashInProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].cashIn` as 'transactionsInfo[0].cashIn'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => <FF required field={field} label="Cash In" input={(p) => <CurrencyCentInput {...p} />} />}
    />
  )
}
