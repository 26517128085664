import { FC, memo, useEffect } from 'react'

import { getRouteApi, notFound } from '@tanstack/react-router'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { useGetContactDetails } from '../../hooks/useGetContactDetails'
import { ContactDetailsPage } from './components'

const ContactDetails: FC = memo(() => {
  const contactId = getRouteApi('/contacts/$contactId').useParams({ select: (s) => Number(s.contactId) })

  const { isFetched, isFetching, data, invalidate, historyTableConfig, documentsTableConfig } =
    useGetContactDetails(contactId)

  useEffect(() => {
    if ((isFetched && !data) || contactId === 0 || isNaN(contactId)) {
      throw notFound()
    }
  }, [data, isFetched, contactId])

  if (isFetching && !data) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  if (!data) {
    return null
  }

  return (
    <ContactDetailsPage
      data={data}
      invalidate={invalidate}
      historyTableConfig={historyTableConfig}
      documentsTableConfig={documentsTableConfig}
    />
  )
})

export default ContactDetails
