import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { CurrencyCentInput, FF } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

type CurrentCumulativeRendererProps = {
  field: FieldApi<SarEdit, 'suspiciousActivityInfo.currentCumulativeAmount'>
}

const CurrentCumulativeRenderer = ({ field }: CurrentCumulativeRendererProps) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    const currentAmount = Number(formApi.state.values.suspiciousActivityInfo.currentAmount)
    const previousCumulative = Number(formApi.state.values.previousCumulativeAmount)

    formApi.setFieldValue(
      'suspiciousActivityInfo.currentCumulativeAmount',
      Math.ceil(currentAmount + previousCumulative)
    )
  }, [
    formApi,
    formApi.state.values.suspiciousActivityInfo.currentAmount,
    formApi.state.values.previousCumulativeAmount
  ])

  return <FF field={field} label="31. Current Cumulative" input={(p) => <CurrencyCentInput {...p} disabled />} />
}

export const CurrentCumulativeAmount = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="suspiciousActivityInfo.currentCumulativeAmount"
      children={(field) => <CurrentCumulativeRenderer field={field} />}
    />
  )
}
