import { useCallback, useEffect, useMemo, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import {
  useGetSalesDataSourceWebPage,
  useSalesDataClientSourceApprove,
  useSalesDataSourceSaveInternalNotes
} from 'commons/hooks/bank/salesData'
import { SalesDataSourceWebPage, SalesDataSourceWebPagePayload } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { handlerServerError } from 'errors'
import { TablePagination, Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

export const useSourceDetails = (sourceId: number) => {
  const navigate = useNavigate()
  const [isShowRejectDialog, setIsShowRejectDialog] = useState(false)
  const [webPageData, setWebPageData] = useState<SalesDataSourceWebPage | undefined>(undefined)
  const { config, Field } = useFormData<{ notes: string }>({ initialValue: { notes: '' } })
  const [filter, setFilter] = useState(new SalesDataSourceWebPagePayload(+sourceId))
  const { data, isFetched, isFetching, invalidate } = useGetSalesDataSourceWebPage(filter)

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  useEffect(() => {
    if (webPageData) {
      config.onChange({ notes: webPageData.infoInternalNotes }, config.errors || {})
    }
  }, [webPageData?.infoInternalNotes])

  const { routine: saveInternalNotes, isLoading: isInternalNotesSubmitting } = useSalesDataSourceSaveInternalNotes({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Internal Notes has been saved.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: approve, isLoading: isApproving } = useSalesDataClientSourceApprove({
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Report has been recalculated with new Sales Data.')
      navigate({ to: '/mr/$id', params: { id: webPageData?.monthlyAnalyticsId.toString() } })
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: openApproveConfirm, Confirmation: ApproveConfirmation } = useConfirmation({
    message: `Are you sure you want to proceed? Report will be recalculated.`,
    onConfirm: () => approve(sourceId),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: false
  })

  const showRejectDialog = useCallback(() => setIsShowRejectDialog(true), [])
  const hideRejectDialog = useCallback(() => setIsShowRejectDialog(false), [])

  const historyPagination: TablePagination = useMemo(
    () => ({
      total: webPageData?.historyTotalCount,
      page: filter.historyOffset / filter.historyLimit + 1,
      pageSize: filter.historyLimit,
      onChange: (page: number, size: number) =>
        setFilter({ ...filter, historyOffset: (page - 1) * size, historyLimit: size })
    }),
    [filter, webPageData]
  )

  return {
    webPageData,
    invalidate,
    isFetching,
    config,
    Field,

    saveInternalNotes: () => saveInternalNotes({ id: sourceId, internalNotes: config.value.notes }),
    isInternalNotesSubmitting,

    ApproveConfirmation,
    approve: () => openApproveConfirm(),
    isApproving,

    isShowRejectDialog,
    showRejectDialog,
    hideRejectDialog,

    historyPagination
  }
}
