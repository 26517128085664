import { FC, memo } from 'react'

import { AvatarProps } from './AvatarProps'

import { Abbr, Box, Image } from './styles'

export const Avatar: FC<AvatarProps> = memo((props) => {
  const { username, image } = props

  const abbr =
    (username || '')
      .split(/\s+/)
      .slice(0, 2)
      .map((part) => part.charAt(0))
      .join('')
      .toUpperCase() || '#'

  return (
    <Box $hasImage={!!image}>
      {image ? <Image src={image} alt={username} /> : <Abbr $oneLetter={abbr.length < 2}>{abbr}</Abbr>}
    </Box>
  )
})
