import styled from 'styled-components'
import { Layer } from 'ui/components/Layer'

const Box = styled(Layer)`
  min-width: auto;
  margin-bottom: 30px;
  border-top: 1.4px solid #000;
`

const Title = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  padding: 10px 20px;
`

const Row = styled.div`
  padding: 0 20px;
  height: 46px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 16px;

  &:nth-child(odd) {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  }
`

const Label = styled.label`
  margin-right: 100px;
`

export const s = { Box, Title, Row, Label }
