import { forwardRef, memo, useCallback } from 'react'

import { IconName, IconSize, Tooltip } from 'ui/components'

import { OptionProps } from '../../types'

import * as SC from './styles'

export const Option = memo(
  forwardRef<HTMLDivElement, OptionProps>((props: OptionProps, ref) => {
    const { label, value, active, selected, onClick, 'data-qa': dataQa, disabled, title, ...rest } = props

    const handleClick = useCallback(() => {
      onClick(value)
    }, [onClick, value])

    const disabledOption = () => (
      <SC.DisabledOption $active={active} role="option" data-qa={dataQa} ref={ref} {...rest}>
        <SC.WrappedIcon
          $active={active}
          $selected={selected}
          name={selected ? IconName.MINUS : IconName.PLUS}
          size={IconSize.M}
        />
        {label}
      </SC.DisabledOption>
    )

    const enabledOption = () => (
      <SC.Option $active={active} role="option" onClick={handleClick} data-qa={dataQa} ref={ref} {...rest}>
        <SC.WrappedIcon
          $active={active}
          $selected={selected}
          name={selected ? IconName.MINUS : IconName.PLUS}
          size={IconSize.M}
        />
        {label}
      </SC.Option>
    )

    if (disabled) {
      // we used delayShow because sometime this tooltip are too annoying, feel free to anjust it
      return title ? (
        <Tooltip id={value ? value.toString() : 'unknown'} type="warning" content={title} delayShow={1000}>
          {disabledOption()}
        </Tooltip>
      ) : (
        disabledOption()
      )
    }

    return title ? (
      <Tooltip id={value ? value.toString() : 'unknown'} type="warning" content={title}>
        {enabledOption()}
      </Tooltip>
    ) : (
      enabledOption()
    )
  })
)
