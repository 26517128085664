import { useDeleteOnboardingDocument } from 'commons/hooks/bank/onboardingSetupDocuments'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/components/Dialog/ConfirmationDialogV2'

export const useDeleteDocument = (id: number, onSuccess: () => void) => {
  const confirmation = useConfirmation()

  const { routine: deleteOnboardingDocument } = useDeleteOnboardingDocument({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall(`The set of documents ID-${id} already deleted`)
    },
    onError: (error) => handlerServerError(error)
  })

  const openDeleteConfirmation = async () => {
    if (
      await confirmation.showDeleteConfirmation({
        message: <>You are going to delete Onboarding Documents ID-{id}. Are you sure?</>,
        confirmCallback: () => deleteOnboardingDocument(id)
      })
    ) {
      await confirmation.showDoneConfirmation({
        message: <>The set of documents ID-{id} already deleted</>
      })
    }
  }

  return {
    openDeleteConfirmation
  }
}
