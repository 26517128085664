import { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { useGetBankAccount } from 'commons/hooks/bank/bankAccount'
import { useAnnualReviewSalesDeposits, useCompany } from '~bank-annual-review/hooks'

import { AnnualReviewEditSalesAndDepositForm } from './components/AnnualReviewEditSalesAndDepositForm'

const routeApi = getRouteApi('/annual-review/edit-sales-and-deposit/$companyId/$annualReviewId/$salesAndDepositId')

const AnnualReviewSalesDeposits: FC = memo(() => {
  const { companyId, annualReviewId, salesAndDepositId } = routeApi.useParams()
  const { bankAccountId } = routeApi.useSearch()

  const intAnnualReviewId = +annualReviewId
  const intCompanyId = +companyId
  const intSalesAndDepositId = +salesAndDepositId

  const company = useCompany({ id: intCompanyId })
  const bankAccount = useGetBankAccount({ id: bankAccountId || 0 })

  const { isFetching, data } = useAnnualReviewSalesDeposits({ id: intSalesAndDepositId, byId: true })
  const salesAndDeposits = data?.records

  if (!bankAccount.data || !intAnnualReviewId || !salesAndDeposits || isFetching) {
    return null
  }

  return (
    <AnnualReviewEditSalesAndDepositForm
      company={company.data?.records[0]}
      salesAndDeposits={salesAndDeposits[0]}
      salesAndDepositsId={intSalesAndDepositId}
      annualReviewId={intAnnualReviewId}
      bankAccountId={bankAccountId}
      companyId={intCompanyId}
    />
  )
})

export default AnnualReviewSalesDeposits
