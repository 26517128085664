import { useCallback, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { useInterval } from 'commons/hooks/common'
import { PortalName } from 'commons/types/enums'

import { useHeartbeat } from '../useHeartbeat'
import prolongUserSession from './prolongUserSession'
import { useGetSessionExpiresAt } from './useGetSessionExpiresAt'

export const useManageUserSession = ({
  portalName,
  sessionRefreshFrequency = 10
}: {
  portalName: PortalName
  sessionRefreshFrequency?: number // minutes
}) => {
  const { data: session } = useHeartbeat({ portalName })
  const hasUser = !!session
  const expiresIn = useGetSessionExpiresAt({ hasUser })
  const [isDialogShowed, setShowPromptConfirmation] = useState<boolean>(false)
  const [showCondition, setShowCondition] = useState<boolean>(false)

  const needToShowCondition = () => {
    const remainingSec = (expiresIn?.current?.min ?? 0) * 60 + (expiresIn?.current?.minSec ?? 0)
    const needToShow = (expiresIn?.current?.min ?? 0) < sessionRefreshFrequency && remainingSec > 0

    if (needToShow !== showCondition) {
      setShowCondition(needToShow)
    }
  }

  useInterval(needToShowCondition)

  // show/hide idle dialog
  useEffect(() => {
    setShowPromptConfirmation(showCondition)
  }, [showCondition])

  // observe user actions and once every `sessionRefreshFrequency` minutes prolongation session
  const { pause: pauseIdleTimer, start: startIdleTimer } = useIdleTimer({
    onAction: () => {
      if (showCondition) {
        return
      }
      // expiresIn?.min is invalid we send request any way
      // in order to get 401 response status and do logout
      prolongUserSession({ portalName, hasUser })
    },
    throttle: sessionRefreshFrequency * 60 * 1000,
    crossTab: true
  })

  // pause idle timer if hasn't user
  useEffect(() => {
    if (!hasUser) {
      pauseIdleTimer()
    } else {
      startIdleTimer()
    }
  }, [hasUser, pauseIdleTimer, startIdleTimer])

  // idle dialog "continue" handler
  const handlePrologSession = useCallback(async () => {
    await prolongUserSession({ portalName, hasUser })
    setShowPromptConfirmation(false)
  }, [portalName, hasUser])

  return {
    isDialogShowed,
    onSessionExtend: handlePrologSession
  }
}
