import { FC, memo } from 'react'

import { Form, UseFormDataReturnType } from 'brief-form'
import { Validators } from 'ui/components/Form/validators'
import { EmailInput, PhoneInput, SecuredInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { QR, RetryLink } from '~auth/components'

import * as SC from '../../../styles'

export type CreateDeviceFormValue = {
  type: string
  name: string
  code: string
}

export interface CreateDeviceFormProps {
  form: UseFormDataReturnType<CreateDeviceFormValue, any>
  onRetryChallenge: () => void
  challengeStartedAt: number
  totpUrl?: string
}

const postfix: { [key: string]: string } = {
  totp: ' shown in your MFA application',
  email: ' sent to your email',
  sms: ' sent you by SMS'
}

export const CreateDeviceForm: FC<CreateDeviceFormProps> = memo((props) => {
  const { form, onRetryChallenge, challengeStartedAt, totpUrl } = props
  const { config, Field } = form
  const { value } = config
  const isSms = value.type === 'sms'

  return (
    <Form config={config}>
      <SC.Box>
        <FormRow>
          <Col>
            <Field
              name="type"
              label="Device Type"
              input={Select}
              inputProps={{
                data: [
                  { label: 'Sms', value: 'sms' },
                  { label: 'E-mail', value: 'email' },
                  { label: 'Application', value: 'totp' }
                ]
              }}
            />
          </Col>
        </FormRow>
        {value.type !== 'totp' && (
          <FormRow>
            <Col>
              <Field
                required
                name="name"
                label={isSms ? 'Phone Number' : 'E-mail'}
                input={isSms ? PhoneInput : EmailInput}
                validator={Validators.required.string}
                inputProps={{}}
              />
            </Col>
          </FormRow>
        )}
        {!!challengeStartedAt && (
          <FormRow>
            <Col>
              <SC.SentHeader>Security code was {postfix[value.type]}</SC.SentHeader>
              <Field
                required
                name="code"
                label="Enter Security Code"
                input={SecuredInput}
                validator={Validators.required.string}
                inputProps={{
                  placeholder: '• • • • • •',
                  maxLength: 6,
                  'data-lpignore': true
                }}
              />
              {totpUrl ? (
                <QR url={totpUrl} />
              ) : (
                <RetryLink dark startedAt={challengeStartedAt} onRetry={onRetryChallenge} />
              )}
            </Col>
          </FormRow>
        )}
      </SC.Box>
    </Form>
  )
})
