/* eslint-disable max-len */
import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const TypeOther = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((s) => s.values.suspiciousActivityInfo.cyberEventIndicators[index].type)
  const other = type === 'Other'

  return (
    <formApi.Field
      name={
        `suspiciousActivityInfo.cyberEventIndicators[${index}].typeOther` as 'suspiciousActivityInfo.cyberEventIndicators[0].typeOther'
      }
      validators={{ onChange: ({ value }) => (other ? Validators.required.field(value) : undefined) }}
      children={(field) => (
        <FF
          required={other}
          label="Event Type Other"
          field={field}
          input={(p) => <TextInput {...p} disabled={!other} />}
        />
      )}
    />
  )
}
