import { FC } from 'react'

import { Request } from 'commons/utils/request'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { DownloadLink } from 'ui/components/DownloadLink'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'
import { Toast } from 'ui/components/Toast'

import { Batch } from '../../types'

import { s } from './styles'

type Props = {
  batch: Batch | null
  onClose: () => void
  isGenerating: boolean
}

export const DownloadXMLDialog: FC<Props> = ({ batch, onClose, isGenerating }) => {
  if (!isGenerating && batch === null) {
    return null
  }

  const batchSuccessfullyGenerated = !isGenerating && batch !== null

  const handleError = (message: string) => Toast.error('Error', message)
  const baseUrl = Request.urls.bank.batchFileDownload

  return (
    <Dialog
      face={DialogFace.DEFAULT}
      id="download-xml-dialog"
      onClose={onClose}
      title={
        <s.Title>
          {isGenerating && (
            <>
              <Icon name={IconName.INFO} size={IconSize.S} /> <span>Batch is generating...</span>
            </>
          )}
          {batchSuccessfullyGenerated && (
            <>
              <Icon name={IconName.INFO_GREEN} /> <span style={{ color: '#46d5b3' }}>Batch successfully generated</span>
            </>
          )}
        </s.Title>
      }
    >
      <s.Content>
        {isGenerating ? (
          <Spinner size={SpinnerSize.M} />
        ) : batchSuccessfullyGenerated ? (
          <DownloadLink
            direct
            parametersForm={{ id: batch?.id, file_type: 'xml' }}
            baseUrl={baseUrl}
            name={batch?.name}
            handleError={handleError}
          >
            {batch?.name}
          </DownloadLink>
        ) : null}
      </s.Content>
    </Dialog>
  )
}
