import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Link, getRouteApi } from '@tanstack/react-router'
import { Form, useFormData } from 'brief-form'
import { useGetPublicationList, useSetSmartFilter, useUpdatePublications } from 'commons/hooks/bank/publication'
import { useGetSearchCase, useUpdateSearchCase } from 'commons/hooks/bank/searchCase'
import { AdverseMediaPublication } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { useTableState } from 'query-table'
import { Keys, useStateManager } from 'state-manager'
import { IconName, IconSize, Page, PageFace, PageSectionFace, Select, Toggle, Tooltip } from 'ui/components'
import { OutlineButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'

import {
  Footer,
  GeneralInformation,
  Overlay,
  PublicationDialog,
  PublicationList,
  PublicationNotesDialog,
  RelatedEntitiesDialog,
  RiskLevelDialog
} from './components'
import { WrappedHr } from './components/styles'
import { useAdverseMediaDeletePublications } from './hooks/useAdverseMediaDeletePublications'
import {
  AdverseMediaPublicationContent,
  useAdverseMediaPublicationContent
} from './hooks/useAdverseMediaPublicationContent'

import * as SC from './styles'

interface FilterState {
  publication_status?: string
}

const routeApi = getRouteApi('/adverse-media/$id')

const SearchCase: FC = memo(() => {
  const queryClient = useQueryClient()
  const sm = useStateManager()

  const [smartFilterState, SetSmartFilterState] = useState<boolean>(false)

  const [itemsPerPage, setItemsPerPage] = useState<number>(50)
  const [isRiskLevelDialogOpened, setIsRiskLevelDialogOpened] = useState<boolean>(false)
  const [isRelatedEntitiesDialogOpened, setIsRelatedEntitiesDialogOpened] = useState<boolean>(false)
  const [selectedPublications, setSelectedPublications] = useState<number[]>([])
  const [publicationForReviewAction, setPublicationForReviewAction] = useState<AdverseMediaPublication | null>(null)
  const [publicationForView, setPublicationForView] = useState<AdverseMediaPublication | null>(null)
  const [publicationForNotesEdit, setPublicationForNotesEdit] = useState<AdverseMediaPublication | null>(null)
  const [publicationContent, setPublicationContent] = useState<null | AdverseMediaPublicationContent>(null)

  const params = routeApi.useParams()
  const navigate = routeApi.useNavigate()
  const id = +(params.id || 0)

  const { routine: setSmartFilter, isLoading: isSettingSmartFilter } = useSetSmartFilter({
    onError: (error) => handlerServerError(error)
  })

  const mutateAdverseMediaPublication = useAdverseMediaPublicationContent(setPublicationContent)
  const mutateAdverseMediaDeletePublictaions = useAdverseMediaDeletePublications()
  const tableState = useTableState({ filter: {} })
  const { config, Field } = useFormData<FilterState>({ initialValue: { publication_status: 'true' } })

  const originalChangeHandler = config.onChange
  config.onChange = (value: FilterState, e) => {
    originalChangeHandler(value, e)
    tableState.pagination.change(1, tableState.pagination.size)

    try {
      queryClient.invalidateQueries(['search-cases', id], { refetchActive: false })
      queryClient.invalidateQueries(['publications'])
    } catch (error) {
      handlerServerError(error)
    }
  }

  useEffect(() => {
    if (!id) {
      navigate({ to: '/adverse-media', replace: true })
    }
  }, [id, navigate])

  const { isFetching: isFetchingSearchCase, data: searchCaseItem } = useGetSearchCase(id)

  useEffect(() => {
    SetSmartFilterState(() => searchCaseItem?.smart_filter ?? false)
  }, [searchCaseItem, isFetchingSearchCase])

  const onChange = async (value: boolean) => {
    try {
      SetSmartFilterState(!smartFilterState)
      const updatedItem = await setSmartFilter({ id, smart: value })
      queryClient.setQueryData(['search-cases', id], { records: [updatedItem], total_count: 1 })
      await sm.invalidate(Keys.AdverseMediaSearchCase.ById(id))
    } catch (error: unknown) {
      handlerServerError(error)
    }
  }

  const { data: publicationsData, isFetching: isFetchingPublicationList } = useGetPublicationList({
    _options: {
      filters: [
        {
          field: 'case_id',
          type: 'eq',
          value: searchCaseItem?.id
        },
        {
          type: 'or',
          filters:
            config.value.publication_status === 'all'
              ? [
                  {
                    field: 'needs_review',
                    type: 'eq',
                    value: true
                  },
                  {
                    field: 'needs_review',
                    type: 'eq',
                    value: false
                  }
                ]
              : config.value.publication_status === 'true'
              ? [
                  {
                    field: 'needs_review',
                    type: 'eq',
                    value: true
                  }
                ]
              : [
                  {
                    field: 'needs_review',
                    type: 'eq',
                    value: false
                  }
                ]
        },
        {
          type: 'or',
          filters: searchCaseItem?.smart_filter
            ? [
                {
                  field: 'smart_filter',
                  type: 'eq',
                  value: 2
                },
                {
                  field: 'smart_filter',
                  type: 'eq',
                  value: 3
                }
              ]
            : [
                {
                  field: 'smart_filter',
                  type: 'eq',
                  value: 1
                },
                {
                  field: 'smart_filter',
                  type: 'eq',
                  value: 3
                }
              ]
        }
      ],
      limit: itemsPerPage,
      orders: [
        {
          direction: 'DESC',
          field: 'needs_review'
        },
        {
          direction: 'DESC',
          field: 'publication_date'
        }
      ]
    }
  })

  const needsReviewPublications =
    publicationsData &&
    publicationsData.records.filter((publication: AdverseMediaPublication) => publication.needs_review)

  const alertedPublicationsCount = searchCaseItem?.meta?.alerted_publications_count

  const { routine: updateSearchCase } = useUpdateSearchCase({
    onSuccess: async () => {
      queryClient.invalidateQueries('search-cases')
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: updatePublications, isLoading: isLoadingUpdatePublications } = useUpdatePublications({
    onSuccess: async () => {
      await sm.invalidate(Keys.AdverseMediaPublication.List)
      await sm.invalidate(Keys.AdverseMediaSearchCase.ById(id))
    },
    onError: (error) => handlerServerError(error)
  })

  const markAsReviewed = async (item?: AdverseMediaPublication) => {
    if (item) {
      try {
        await updatePublications([
          {
            notes: item.notes,
            id: item.id,
            needs_review: false
          }
        ]).then(() => {
          setSelectedPublications([])
        })
      } catch (error) {
        handlerServerError(error)
      }
    } else {
      const itemsForUpdate = selectedPublications.map((id) => {
        const publicationItem: AdverseMediaPublication = publicationsData?.records.find(
          (publication: AdverseMediaPublication) => publication.id === id
        ) as AdverseMediaPublication
        return { ...publicationItem, needs_review: false }
      })

      try {
        await updatePublications(
          itemsForUpdate.map((item) => ({
            notes: item.notes,
            id: item.id,
            needs_review: false
          }))
        ).then(() => {
          setSelectedPublications([])
        })
      } catch (error) {
        handlerServerError(error)
      }
    }
  }

  const { open: openDelete, Confirmation: ConfirmDelete } = useConfirmation({
    message: `Are you sure you want to permanently delete all unreviewed publications?`,
    onConfirm: async () => {
      if (searchCaseItem) {
        await mutateAdverseMediaDeletePublictaions.mutateAsync(searchCaseItem.id)
      }
      await sm.invalidate(Keys.AdverseMediaPublication.List)
      await sm.invalidate(Keys.AdverseMediaSearchCase.ById(id))
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const deletePublication = async () => {
    openDelete()
  }

  const markAsUnreviewed = (item: AdverseMediaPublication) => {
    setPublicationForReviewAction(item)
    startMarkingAsUnreviewed()
  }

  const updateNotes = useCallback(
    async (notes) => {
      try {
        if (searchCaseItem) {
          await updateSearchCase({ ...searchCaseItem, notes })
          searchCaseItem.notes = notes
        }
        await sm.invalidate(Keys.AdverseMediaSearchCase.ById(id))
      } catch (error) {
        handlerServerError(error)
      }
    },
    [updateSearchCase, searchCaseItem, id, sm]
  )

  const onSaveNotes = (notes: string) => {
    updateNotes(notes)
  }

  const onSubmitRiskLevel = useCallback(
    async (notes, riskLevel) => {
      setIsRiskLevelDialogOpened(false)
      try {
        if (searchCaseItem) {
          await updateSearchCase({ ...searchCaseItem, notes, risk_level: riskLevel })
        }
        await sm.invalidate(Keys.AdverseMediaSearchCase.ById(id))
      } catch (error) {
        handlerServerError(error)
      }
    },
    [updateSearchCase, searchCaseItem, id, sm]
  )

  const onSavePublicationNotes = useCallback(
    async (notes) => {
      setPublicationForNotesEdit(null)
      try {
        if (publicationForNotesEdit) {
          await updatePublications([
            {
              notes,
              id: publicationForNotesEdit?.id,
              needs_review: publicationForNotesEdit?.needs_review
            }
          ])
        }
      } catch (error) {
        handlerServerError(error)
      }
    },
    [publicationForNotesEdit, updatePublications]
  )

  const { open: startMarkingAsUnreviewed, Confirmation: MarkAsUnreviewedConfirmation } = useConfirmation({
    message: `Are you sure you want to make this article unreviewed?`,
    onConfirm: async () => {
      try {
        if (publicationForReviewAction) {
          await updatePublications([
            {
              notes: publicationForReviewAction?.notes,
              id: publicationForReviewAction?.id,
              needs_review: true
            }
          ]).then(() => {
            setPublicationForReviewAction(null)
            setPublicationForView(null)
            queryClient.invalidateQueries('search-cases')
          })
        }
      } catch (error) {
        handlerServerError(error)
      }
    },
    confirmationButtonText: 'Unreview',
    isConfirmationNegative: true
  })

  const onSelectPublication = (id?: number) => {
    if (!id) {
      if (selectedPublications.length < (needsReviewPublications?.length || 0)) {
        setSelectedPublications(
          (needsReviewPublications || []).map((publication: AdverseMediaPublication) => publication.id)
        )
      } else {
        setSelectedPublications([])
      }
    } else {
      const index = selectedPublications.findIndex((itemId) => itemId === id)
      if (index === -1) {
        const newState = [...selectedPublications, id]
        setSelectedPublications(newState)
      } else {
        const copy = [...selectedPublications]
        copy.splice(index, 1)
        setSelectedPublications(copy)
      }
    }
  }

  const onOpenPublication = (publication: AdverseMediaPublication) => {
    setPublicationForView(publication)
    mutateAdverseMediaPublication.mutateAsync(publication.id)
  }

  const onOpenPublicationNotes = (publication: AdverseMediaPublication) => {
    setPublicationForNotesEdit(publication)
  }

  const onShowRelatedEntities = () => {
    setIsRelatedEntitiesDialogOpened(true)
  }

  const onCloseRelatedEntitiesDialog = useCallback(() => {
    setIsRelatedEntitiesDialogOpened(false)
  }, [setIsRelatedEntitiesDialogOpened])

  const onSetRiskLevel = () => {
    setIsRiskLevelDialogOpened(true)
  }

  const relatedEntities = {
    companies: searchCaseItem?.meta?.company_ids,
    contacts: searchCaseItem?.meta?.contact_ids,
    externalLicenses: searchCaseItem?.meta?.external_license_ids
  }

  const loadMorePublications = () => {
    setItemsPerPage(itemsPerPage + 50)
  }

  useEffect(() => {
    if (publicationContent) {
      setPublicationForView(
        (prev) =>
          prev && {
            ...prev,
            content: publicationContent.content,
            copyright_holder: publicationContent.copyright_holder,
            copyright_notice: publicationContent.copyright_notice
          }
      )
    }
  }, [publicationContent])

  useEffect(() => {
    if (publicationContent && publicationForView?.content_type === 'url') {
      window.open(publicationContent.content, '_blank')
      setPublicationContent(null)
    }
  }, [publicationContent, publicationForView])

  const NoResultMessage = () =>
    searchCaseItem?.smart_filter ? (
      <SC.EmptyLabel>
        No Adverse Media results found. Try to switch off Smart
        <br />
        Filter to see more.
      </SC.EmptyLabel>
    ) : (
      <SC.EmptyLabel>
        No Adverse Media results found. Please try to select
        <br />
        another Publication status.
      </SC.EmptyLabel>
    )

  return (
    <Page
      face={PageFace.PRIMARY}
      title={
        <SC.Title>
          <Link to="/adverse-media">Adverse Media</Link>
          <SC.Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
          <div title={searchCaseItem?.search_term}>{searchCaseItem?.search_term}</div>
        </SC.Title>
      }
      isPending={isFetchingSearchCase}
      footer={
        <Footer
          onSetRiskLevel={onSetRiskLevel}
          onMarkAsReviewed={markAsReviewed}
          isReviewButtonVisible={(publicationsData?.total_count || 0) > 0}
          selectedPublicationsLength={selectedPublications.length}
          needsReviewCount={alertedPublicationsCount}
          onSelectAllUnreviewed={onSelectPublication}
          onDeleteAllUnreviewed={deletePublication}
        />
      }
    >
      <ConfirmDelete />
      <RelatedEntitiesDialog
        isDialogShown={isRelatedEntitiesDialogOpened}
        entities={relatedEntities}
        onClose={onCloseRelatedEntitiesDialog}
      />
      <MarkAsUnreviewedConfirmation />
      <PublicationDialog
        publication={publicationForView}
        onClose={() => {
          setPublicationForView(null)
          setPublicationForView(null)
          setPublicationContent(null)
        }}
        searchCase={searchCaseItem?.search_term}
      />
      <PublicationNotesDialog
        publication={publicationForNotesEdit}
        onClose={() => setPublicationForNotesEdit(null)}
        onSaveNotes={onSavePublicationNotes}
      />
      {isRiskLevelDialogOpened && searchCaseItem && (
        <RiskLevelDialog
          isActive={isRiskLevelDialogOpened}
          searchCase={searchCaseItem}
          onClose={() => setIsRiskLevelDialogOpened(false)}
          onSubmit={onSubmitRiskLevel}
        />
      )}

      {!!searchCaseItem && publicationsData && (
        <>
          <GeneralInformation
            item={searchCaseItem}
            updateNotes={onSaveNotes}
            ShowRelatedEntities={onShowRelatedEntities}
          />
          <SC.WrappedPageSection
            face={PageSectionFace.THIRD}
            title={'News'}
            actions={
              <SC.PoweredContainer>
                <span>Powered by Refinitiv World-Check One</span>
              </SC.PoweredContainer>
            }
            anchor="news"
          >
            <SC.FilterContainer>
              <div>
                <Form config={config}>
                  <SC.Row>
                    <Field
                      name="publication_status"
                      label="Publication status"
                      input={Select}
                      inputProps={{
                        data: [{ label: 'All', value: 'all' }].concat([
                          { label: 'Needs Review', value: 'true' },
                          { label: 'Reviewed', value: 'false' }
                        ])
                      }}
                    />
                  </SC.Row>
                </Form>
              </div>
              <SC.ToggleContainer>
                <Toggle value={smartFilterState} onChange={onChange} label="Smart Filter" />
                <Tooltip
                  id="smart-filter-hint"
                  type="dark"
                  event="hover"
                  content={
                    <SC.TooltipContent>
                      The Smart filter feature uses Artificial Intelligence through Natural Language Processing and
                      Machine learning models to greatly simplify unstructured media searches and reduce false positives
                      by focusing only on content containing risk terms that may directly impact the screened entity.
                    </SC.TooltipContent>
                  }
                >
                  <SC.SmartFilterInfoIcon name={IconName.INFO} size={IconSize.XS} />
                </Tooltip>
              </SC.ToggleContainer>
            </SC.FilterContainer>
            <WrappedHr />
            {!publicationsData?.records?.length && <NoResultMessage />}
            <PublicationList
              items={publicationsData.records}
              onOpenPublication={onOpenPublication}
              markAsReviewed={markAsReviewed}
              markAsUnreviewed={markAsUnreviewed}
              onSelectPublication={onSelectPublication}
              onOpenPublicationNotes={onOpenPublicationNotes}
              selectedPublications={selectedPublications}
              disabled={isSettingSmartFilter}
            />
            {publicationsData.total_count > itemsPerPage && (
              <OutlineButton onClick={loadMorePublications} face="positive">
                Load more
              </OutlineButton>
            )}
          </SC.WrappedPageSection>
        </>
      )}
      <Overlay visible={isSettingSmartFilter || isFetchingPublicationList || isLoadingUpdatePublications} />
    </Page>
  )
})

export default SearchCase
