import styled, { css } from 'styled-components'
import { Icon } from 'ui/components'
import { CSS } from 'ui/helpers'

export const Option = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 20px 6px 10px;
  min-height: 36px;
  gap: 10px;
  outline: none;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

  background: ${(p) =>
    p.$active ? p.theme.uiKit.colors.global.grey.grey100 : p.theme.uiKit.colors.global.general.main};

  transition: ${CSS.populateTransition(['background-color'])};
`

export const WrappedIcon = styled(Icon)<{ $active: boolean; $selected: boolean }>`
  > svg {
    fill: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

    transition: ${CSS.populateTransition(['color', 'fill'])};

    ${(p) =>
      p.$active && p.$selected
        ? css`
            fill: ${(p) => p.theme.uiKit.colors.theme.negative.main};
            color: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
          `
        : ``}

    ${(p) =>
      p.$active && !p.$selected
        ? css`
            fill: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
            color: ${(p) => p.theme.uiKit.colors.theme.brand.transparent.opacity12};
          `
        : ``}
  }
`

export const DisabledOption = styled(Option)`
  cursor: not-allowed;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  ${WrappedIcon} {
    > svg {
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
      color: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
    }
  }
`
