import { PageSection, PageSectionFace } from 'ui/components'
import { Button } from 'ui/components/Buttons'

import { emptySAR } from '../../../../../../../constants'
import { useSARFormContext } from '../../../../context'
import { InstitutionActivitiesItem } from './InstitutionActivitiesItem'

export const FinancialInstitutionWhereActivityOccurredForm = () => {
  const { formApi } = useSARFormContext()

  return (
    <PageSection
      face={PageSectionFace.THIRD}
      title="Step 2. Financial Institution Where Activity Occurred"
      anchor="step2"
    >
      <formApi.Field mode="array" name="institutionActivitiesInfo">
        {(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {field.state.value.map((_, index) => (
                <InstitutionActivitiesItem key={index} index={index} field={field} />
              ))}
              <Button
                face="positive"
                onClick={() => {
                  field.pushValue(emptySAR.institutionActivitiesInfo[0])
                }}
              >
                add
              </Button>
            </>
          )
        }}
      </formApi.Field>
    </PageSection>
  )
}
