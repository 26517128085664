import { FC, memo } from 'react'

import * as SC from '../styles'
import { useRouterState } from '@tanstack/react-router'
import { Icon, IconSize } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { DateTime, formatDateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { IconName, Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import { GeneralInformationProps } from './GeneralInformation.types'

export const GeneralInformation: FC<GeneralInformationProps> = memo((props) => {
  const { openEditPeriodDialog, data } = props
  const {
    generalInfo: {
      filingName,
      filingType,
      priorReportBsaId,
      filingNotesToFinCen,
      companyId,
      companyName,
      status,
      notes,
      transactionStartDate,
      transactionEndDate
    },
    id,
    createdAt,
    updatedAt
  } = data
  const state = useRouterState()
  const returnUrl = encodeURIComponent(state.location.pathname)

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="General Information"
      actions={
        <Link to="/bsa/sars/$id/edit" params={{ id: id }} search={{ returnUrl }}>
          <Button face="positive">Edit</Button>
        </Link>
      }
      anchor="general"
    >
      <SC.WrappedHr />
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <Row>
          <Col2x>
            <Info label="Filing Name">{filingName}</Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info label="1. Type of filing">
              {[
                filingType?.includes('filingTypeInitial') ? 'Initial report' : null,
                filingType?.includes('filingTypeCorrectAmend') ? 'Correct/amend prior report' : null,
                filingType?.includes('filingTypeContinuing') ? 'Continuing activity report' : null,
                filingType?.includes('filingTypeJoint') ? 'Joint report' : null
              ]
                .filter(Boolean)
                .join(', ')}
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Col>
            <Info label="Prior report BSA Identifier">{priorReportBsaId}</Info>
          </Col>
        </Row>
        <Row>
          <Col2x>
            <Info label="2. Filing Institution Note to FinCEN">{filingNotesToFinCen}</Info>
          </Col2x>
        </Row>
        <Row>
          <Col>
            <Info label="Company">
              <SC.WrappedLink to="/relationships/$companyId" params={{ companyId }}>
                {companyName}
              </SC.WrappedLink>
            </Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="Status">{status}</Info>
            <Info label="Created Date">
              <DateTime date={createdAt || null} withTime />
            </Info>
          </Col>
          <Col>
            <Info label="Period">
              <SC.PeriodEditRow>
                {`${formatDateTime(transactionStartDate)} - ${formatDateTime(transactionEndDate)}`}
                <Icon name={IconName.EDIT} size={IconSize.XS} onClick={openEditPeriodDialog} />
              </SC.PeriodEditRow>
            </Info>
            <Info label="Last Modified Date">
              <DateTime date={updatedAt || null} withTime />
            </Info>
          </Col>
        </Row>
        <Row>
          <Info label="Notes">{notes}</Info>
        </Row>
      </InfoSet>
    </PageSection>
  )
})
