export namespace CSS {
  export const populateFont = (paragraphStyles: {
    fontFamily: string
    fontWeight: string
    fontSize: string
    lineHeight: string
    letterSpacing: string
    textCase: string
    textDecoration: string
    paragraphSpacing: string
  }) => {
    return `
      font-family:      ${paragraphStyles.fontFamily};
      font-weight:      ${paragraphStyles.fontWeight};
      font-size:        ${paragraphStyles.fontSize};
      line-height:      ${paragraphStyles.lineHeight};
      letter-spacing:   ${paragraphStyles.letterSpacing};
      text-transform:   ${paragraphStyles.textCase};
      text-decoration:  ${paragraphStyles.textDecoration};
      margin:           ${paragraphStyles.paragraphSpacing};
    `
  }

  export const populateBoxShadow = (shadow: { x: string; y: string; blur: string; spread: string; color: string }) => {
    return `
      ${shadow.x}
      ${shadow.y}
      ${shadow.blur}
      ${shadow.spread}
      ${shadow.color}
    `
  }

  export const populateTransition = (styles: string[], time = '0.15s') => {
    const values: string[] = []
    styles.forEach((value) => {
      values.push(`${value} ${time}`)
    })
    return values.join(', ')
  }
}
