import { CompanySelect, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const CompanyId = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="generalInfo.companyId"
      validators={{ onChange: ({ value }) => Validators.required.field(value) }}
      children={(field) => (
        <FF
          required
          field={field}
          label="Company"
          input={(p) => (
            <CompanySelect
              {...p}
              hideMyCompaniesFilter={true}
              data-qa="General. Company"
              options-data-qa="General. Company option"
            />
          )}
        />
      )}
    />
  )
}
