import { useCallback, useEffect, useRef } from 'react'

import { UseFormDataReturnType, useFormData } from 'brief-form'

import { DocumentListFilterFormValue } from '../DocumentListTable/types'

export type OnFilterChangeHandler = ({ filterValue }: { filterValue: DocumentListFilterFormValue }) => void

type UseDocumentListTableFilterParams = {
  currentFilterValue: DocumentListFilterFormValue
  clearFlag: Record<PropertyKey, never>
  onFilterChange: OnFilterChangeHandler
  onSetDirty: React.Dispatch<React.SetStateAction<boolean>>
}

type UseDocumentListTableFilterReturn = {
  filterState: UseFormDataReturnType<DocumentListFilterFormValue, unknown>
}

type UseDocumentListTableFilterHook = (params: UseDocumentListTableFilterParams) => UseDocumentListTableFilterReturn

export const useDocumentListTableFilter: UseDocumentListTableFilterHook = ({
  currentFilterValue,
  clearFlag,
  onFilterChange,
  onSetDirty
}) => {
  const missFirstRender = useRef<boolean>(true)
  const filterInitialValue = useRef<DocumentListFilterFormValue | undefined>()

  const filterState = useFormData<DocumentListFilterFormValue>({
    initialValue: currentFilterValue
  })

  const originalChangeHandler = filterState.config.onChange
  filterState.config.onChange = useCallback(
    (filterValue, errors) => {
      originalChangeHandler(filterValue, errors)
      onFilterChange({ filterValue })
    },
    [originalChangeHandler, onFilterChange]
  )

  // clear filter functionality
  // TODO: explore onBeforeChangeHandler for purpose below
  useEffect(() => {
    if (missFirstRender.current) {
      missFirstRender.current = false
      return
    }
    if (filterInitialValue.current) {
      filterState.config.onChange(filterInitialValue.current, filterState.config.errors)
    }

    // observe changing ref of clearFlag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFlag])

  // persist initial filter value for clear filter functionality
  useEffect(() => {
    filterInitialValue.current = currentFilterValue
    // once save initial filter value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // visible clear filter btn in DocumentListTable->FilterPanel
  useEffect(() => {
    onSetDirty(filterState.isDirty)
  }, [filterState.isDirty, onSetDirty])

  return {
    filterState
  }
}
