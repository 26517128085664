import { Chip, ChipFace } from 'ui/components/Chip'
import { DateTime } from 'ui/components/DateTime'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'
import { AdverseMediaListResponseItem } from '~bank-adverse-media/types'

import * as SC from './styles'

export const columns: TableColumn<AdverseMediaListResponseItem>[] = [
  {
    title: 'Search Case',
    align: 'left',
    render: ({ id, searchCase }) => (
      <Link to="/adverse-media/$id" params={{ id }}>
        {searchCase}
      </Link>
    )
  },
  {
    title: 'Needs Review',
    render: ({ needsReviewCount }) =>
      !!needsReviewCount && <Chip face={ChipFace.DANGER}>{needsReviewCount > 50 ? '50+' : needsReviewCount}</Chip>
  },
  {
    title: 'Risk Level',
    render: ({ riskLevel }) => <SC.RiskLevel $riskLevel={riskLevel}>{riskLevel}</SC.RiskLevel>
  },
  {
    key: 'entityType',
    title: 'Type'
  },
  {
    title: 'Last Scan Date',
    render: ({ lastScanDate }) => <DateTime withTime date={lastScanDate} />
  },
  {
    title: 'Last Modified Date',
    render: ({ lastModifiedDate }) => <DateTime withTime date={lastModifiedDate} />
  },
  {
    title: 'Action',
    render: ({ id, needsReview, action }) => (
      <SC.ActionLink $needsReview={needsReview} to="/adverse-media/$id" params={{ id }}>
        {action}
      </SC.ActionLink>
    )
  }
]
