import { FC, memo, useCallback } from 'react'

import { DepositFromSales } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/DepositFromSales'
import { formatCurrencyCent } from 'commons/utils'

import { s } from './styles'

export const DepositsFromSalesTable: FC<DepositFromSales> = memo((props) => {
  const { priorQuarter, currentQuarter, changesPriorQuarter } = props

  const PQ = useCallback((v: string) => {
    if (v === '-1.00') {
      return 'N/A'
    }

    return v.startsWith('-') ? <s.TV $negative>({v})%</s.TV> : <s.TV>{v}%</s.TV>
  }, [])

  return (
    <s.WrappedLayer rounded shadowed>
      <s.Box>
        <s.Title>DEPOSITS FROM SALES</s.Title>
        <s.Columns>
          <s.Labels>
            <s.Label>Current Quarter</s.Label>
            <s.Label>Prior Quarter</s.Label>
            <s.Label>
              <b>% Change</b>
            </s.Label>
          </s.Labels>
          <s.Table>
            <thead>
              <tr>
                <s.Th rowSpan={2}>Total</s.Th>
                <s.Th $bottomBorder colSpan={3}>
                  Cash
                </s.Th>
                <s.Th rowSpan={2}>Internal Transfers</s.Th>
                <s.Th rowSpan={2}>Credit / Debit Cards</s.Th>
                <s.Th rowSpan={2}>Checks</s.Th>
                <s.Th rowSpan={2}>Other</s.Th>
              </tr>
              <tr>
                <s.Th>Deposits</s.Th>
                <s.Th>Invoices</s.Th>
                <s.Th>ATM Load</s.Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <s.Td>{formatCurrencyCent(currentQuarter.total, false)}</s.Td>
                <s.Td>{formatCurrencyCent(currentQuarter.cashDeposits, false)}</s.Td>
                <s.Td>{formatCurrencyCent(currentQuarter.cashInvoices, false)}</s.Td>
                <s.Td>{formatCurrencyCent(currentQuarter.cashATMLoad, false)}</s.Td>
                <s.Td>{formatCurrencyCent(currentQuarter.internalTransfers, false)}</s.Td>
                <s.Td>{formatCurrencyCent(currentQuarter.creditDebitCards, false)}</s.Td>
                <s.Td>{formatCurrencyCent(currentQuarter.checks, false)}</s.Td>
                <s.Td>{formatCurrencyCent(currentQuarter.other, false)}</s.Td>
              </tr>
              <tr>
                <s.Td>{formatCurrencyCent(priorQuarter.total, false)}</s.Td>
                <s.Td>{formatCurrencyCent(priorQuarter.cashDeposits, false)}</s.Td>
                <s.Td>{formatCurrencyCent(priorQuarter.cashInvoices, false)}</s.Td>
                <s.Td>{formatCurrencyCent(priorQuarter.cashATMLoad, false)}</s.Td>
                <s.Td>{formatCurrencyCent(priorQuarter.internalTransfers, false)}</s.Td>
                <s.Td>{formatCurrencyCent(priorQuarter.creditDebitCards, false)}</s.Td>
                <s.Td>{formatCurrencyCent(priorQuarter.checks, false)}</s.Td>
                <s.Td>{formatCurrencyCent(priorQuarter.other, false)}</s.Td>
              </tr>
              <tr>
                <s.Td $hl={changesPriorQuarter.total.alerted}>{PQ(changesPriorQuarter.total.percent)}</s.Td>
                <s.Td $hl={changesPriorQuarter.cashDeposits.alerted}>
                  {PQ(changesPriorQuarter.cashDeposits.percent)}
                </s.Td>
                <s.Td $hl={changesPriorQuarter.cashInvoices.alerted}>
                  {PQ(changesPriorQuarter.cashInvoices.percent)}
                </s.Td>
                <s.Td $hl={changesPriorQuarter.cashATMLoad.alerted}>{PQ(changesPriorQuarter.cashATMLoad.percent)}</s.Td>
                <s.Td $hl={changesPriorQuarter.internalTransfers.alerted}>
                  {PQ(changesPriorQuarter.internalTransfers.percent)}
                </s.Td>
                <s.Td $hl={changesPriorQuarter.creditDebitCards.alerted}>
                  {PQ(changesPriorQuarter.creditDebitCards.percent)}
                </s.Td>
                <s.Td $hl={changesPriorQuarter.checks.alerted}>{PQ(changesPriorQuarter.checks.percent)}</s.Td>
                <s.Td $hl={changesPriorQuarter.other.alerted}>{PQ(changesPriorQuarter.other.percent)}</s.Td>
              </tr>
            </tbody>
          </s.Table>
        </s.Columns>
      </s.Box>
    </s.WrappedLayer>
  )
})
