import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { Page, PageFace, Panel, PanelFace, Table } from 'ui/components'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { columns } from './columns'
import { Filter } from './components'
import { useApplicationPipelineReportEssence } from './useApplicationPipelineReportEssence'

const ApplicationPipelineReport = () => {
  const { isFetching, dataSource, config, filterForm, tableState, totalCount, onPageChange, onClear, statusOptions } =
    useApplicationPipelineReportEssence()

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="Application Pipeline Report" />}
      actions={
        <ExportCSVButton
          reportName="Application Pipeline"
          parametersForm={{ status: config.value.status }}
          url={Request.urls.bank.portalReportApplicationPipelineCSV}
        />
      }
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Filter
          isExpanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          onClear={onClear}
          filterForm={filterForm}
          statusOptions={statusOptions}
        />
        <Table
          dataQa={TableDataQa.BP_REPORTS_APPLICATION_PIPELINE_REPORT}
          dataSource={dataSource}
          loading={isFetching}
          columns={columns}
          pagination={{
            page: config.value.page,
            pageSize: config.value.size,
            total: totalCount,
            onChange: onPageChange
          }}
        />
      </Panel>
    </Page>
  )
}

export default ApplicationPipelineReport
