import { FC, useMemo } from 'react'

import { Warnings } from 'bank-bsa-reporting/src/routes/SAR/List/components'
import { Sars } from 'commons/types/DTO/bank/alerts/sars'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'

import { SARsListTableProps } from './SARsListTableProps'
import { columns } from './columns'
import { useComponentsEssence } from './hooks'

export const SARsListTable: FC<SARsListTableProps> = ({ SARs, SARSTotalCount, onAlertFilterChange }) => {
  const { warnings, onShowWarnings, tableState, changePagination, loading, onClose } =
    useComponentsEssence(onAlertFilterChange)

  const memoizedColumns = useMemo(() => columns({ onShowWarnings }), [onShowWarnings])

  const mappedSARs: DataSourceItem<Sars>[] = useMemo(
    () =>
      SARs.map((el) => ({
        item: el
      })),
    [SARs]
  )

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="SARs List" anchor="sar">
      <Warnings warnings={warnings} onClose={onClose} />
      <Layer rounded shadowed stretch>
        <Table<Sars>
          dataQa={TableDataQa.BP_ALERTS_SARS}
          columns={memoizedColumns}
          dataSource={mappedSARs}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: SARSTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
}
