import { FC, memo, useEffect } from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { withProfiler } from '@sentry/react'
import { AuthContainer } from 'auth'
import { FormProvider } from 'brief-form'
import { PortalName } from 'commons/types/enums'
import { useConfiguration } from 'modules/configuration'
import { MainPage } from 'modules/main'
import { ThemeProvider } from 'styled-components'
import { FormField } from 'ui/components/Form'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'
import { Themes, getUIKitTheme } from 'ui/themes/getUIKitTheme'
import * as GlobalSC from 'ui/themes/globalStyles'

import { AuthBg } from './AuthBg'
import { queryClient } from './queryClient'

const ApplicationUnWrapped: FC = () => {
  const { isFetching, configuration } = useConfiguration()

  useEffect(() => {
    document.body.dataset.theme = 'BP'
  }, [])

  if (isFetching || !configuration) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <>
      <GlobalSC.AppContainer>
        <GlobalSC.UIGlobalStyles />
        <GlobalSC.InputGlobalStyles />
        <AuthContainer
          portalName={PortalName.BP}
          backgroundElement={<AuthBg />}
          logoElement={<GlobalSC.Title>Welcome to Orbit</GlobalSC.Title>}
          idleTimeoutInSeconds={configuration.session.timeoutInMinutes * 60}
        >
          <MainPage />
        </AuthContainer>
        <ReactQueryDevtools position="bottom-right" />
      </GlobalSC.AppContainer>
    </>
  )
}

export const Application = withProfiler(
  memo(() => (
    <FormProvider
      crashIfRequiredFieldDoesNotHaveValidator
      renderFieldsDataIds
      dataIdSuffix="qa"
      fieldRenderer={FormField}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={getUIKitTheme(Themes.BP)}>
          <ApplicationUnWrapped />
        </ThemeProvider>
      </QueryClientProvider>
    </FormProvider>
  ))
)
