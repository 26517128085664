import { FC, memo } from 'react'

import { FieldSetProps } from './FieldSetProps'

import { Container, Legend } from './styles'

export const FieldSet: FC<FieldSetProps> = memo(({ children, legend, ...rest }) => (
  <Container {...rest}>
    {!!legend && <Legend>{legend}</Legend>}
    {children}
  </Container>
))
