import { IconSize, TextMask } from 'ui/components'

import { s } from './styles'

export const BankAccountPanelHeader = ({ bankAccountNumber }: { bankAccountNumber: string }) => (
  <s.BankAccountPanelHeader>
    Bank Account
    <TextMask text={bankAccountNumber} isBankAccount iconFill="black" iconSize={IconSize.S} />
  </s.BankAccountPanelHeader>
)
