import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { CompanySelect } from 'ui/components/Select'
import { DialogCol, FormRow } from 'ui/themes/globalStyles'

import { ManageAffiliatedCompanyFormProps } from './ManageAffiliatedCompanyFormProps'

export const ManageAffiliatedCompanyForm: FC<ManageAffiliatedCompanyFormProps> = memo((props) => {
  const { config, Field, validateAffiliated } = props

  return (
    <Form config={config}>
      <FormRow>
        <DialogCol>
          <Field
            required
            name="mainId"
            label="Main Account"
            input={CompanySelect}
            inputProps={{
              disabled: true,
              'data-qa': 'Main Account'
            }}
            validator={Validators.required.field}
          />
        </DialogCol>
      </FormRow>
      <FormRow>
        <DialogCol>
          <Field
            required
            name="affiliatedId"
            label="Affiliated Company"
            input={CompanySelect}
            validator={validateAffiliated}
            inputProps={{
              hideMyCompaniesFilter: true,
              disabled: !!config.value.id,
              'data-qa': 'Affiliated Company',
              'options-data-qa': 'Affiliated Company option'
            }}
          />
        </DialogCol>
      </FormRow>
      <FormRow>
        <DialogCol>
          <Field
            name="notes"
            label="Notes"
            input={TextareaInput}
            inputProps={{
              'data-qa': 'Notes',
              maxLength: 1000
            }}
          />
        </DialogCol>
      </FormRow>
    </Form>
  )
})
