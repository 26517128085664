import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const Structuring = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      validators={{
        onChangeListenTo: [
          'suspiciousActivityInfo.terroristFinancing',
          'suspiciousActivityInfo.fraud',
          'suspiciousActivityInfo.laundering',
          'suspiciousActivityInfo.identification',
          'suspiciousActivityInfo.otherActivities',
          'suspiciousActivityInfo.gaming',
          'suspiciousActivityInfo.cyber',
          'suspiciousActivityInfo.insurance',
          'suspiciousActivityInfo.mortgage',
          'suspiciousActivityInfo.securities'
        ],
        onChange: () => {
          formApi.setFieldMeta('suspiciousActivityInfo.structuring', (meta) => ({ ...meta, errors: [], errorMap: {} }))
          formApi.setFieldMeta('suspiciousActivityInfo.terroristFinancing', (meta) => ({
            ...meta,
            errors: [],
            errorMap: {}
          }))
          formApi.setFieldMeta('suspiciousActivityInfo.fraud', (meta) => ({ ...meta, errors: [], errorMap: {} }))
          formApi.setFieldMeta('suspiciousActivityInfo.laundering', (meta) => ({ ...meta, errors: [], errorMap: {} }))
          formApi.setFieldMeta('suspiciousActivityInfo.identification', (meta) => ({
            ...meta,
            errors: [],
            errorMap: {}
          }))
          formApi.setFieldMeta('suspiciousActivityInfo.otherActivities', (meta) => ({
            ...meta,
            errors: [],
            errorMap: {}
          }))
          formApi.setFieldMeta('suspiciousActivityInfo.gaming', (meta) => ({ ...meta, errors: [], errorMap: {} }))
          formApi.setFieldMeta('suspiciousActivityInfo.cyber', (meta) => ({ ...meta, errors: [], errorMap: {} }))
          formApi.setFieldMeta('suspiciousActivityInfo.insurance', (meta) => ({ ...meta, errors: [], errorMap: {} }))
          formApi.setFieldMeta('suspiciousActivityInfo.mortgage', (meta) => ({ ...meta, errors: [], errorMap: {} }))
          formApi.setFieldMeta('suspiciousActivityInfo.securities', (meta) => ({ ...meta, errors: [], errorMap: {} }))

          return undefined
        }
      }}
      name="suspiciousActivityInfo.structuring"
      children={(field) => (
        <FF
          field={field}
          label="32. Structuring"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'32. Structuring'}
              direction={'vertical'}
              data={[
                { label: 'a. Alters or cancels transaction to avoid BSA record keeping requirement', value: 'a' },
                { label: 'b. Alters or cancels transaction to avoid CTR requirement', value: 'b' },
                { label: 'c. Suspicious inquiry by customer', value: 'c' },
                { label: 'd. Transaction(s) below BSA record keeping threshold', value: 'd' },
                { label: 'e. Transaction(s) below CTR threshold', value: 'e' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
