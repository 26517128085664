import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const ZipCode = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.zipCode"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="85. ZIP Code"
          field={field}
          input={(p) => <TextInput {...p} maxLength={9} data-qa="85. ZIP Code" />}
        />
      )}
    />
  )
}
