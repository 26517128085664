import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

import { SARDetailsSearchSchema, SARDetailsSearchSchemaType } from './SARDetailsSearchSchema'

export const DetailsRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$id`,
    component: lazyRouteComponent(() => import('./Details')),
    validateSearch: (search: Record<string, string>): SARDetailsSearchSchemaType => SARDetailsSearchSchema.parse(search)
  })
