import { FC, memo } from 'react'

import { useGetBankName } from 'commons/hooks/bank/organisation'
import { BankName } from 'commons/types/enums'
import { isAdverseMediaShow } from 'commons/utils/adverseMediaShowCondition'
import { InnerMenu } from 'ui/components/SideMenu'

export const CompanySideBar: FC<{ active: boolean }> = memo((props) => {
  const { id: bankId } = useGetBankName()
  const showAM = bankId ? isAdverseMediaShow(bankId) : false
  const isValliance = bankId === BankName.VALLIANCE

  return (
    <InnerMenu
      items={[
        { label: 'General Information', hash: 'general' },
        { label: 'Documents', hash: 'documents' },
        { label: 'Contacts', hash: 'contacts' },
        { label: 'Quarterly Analytics', hash: 'qr' },
        { label: 'Affiliated Companies', hash: 'affiliated' },
        { label: 'Licenses', hash: 'licenses' },
        { label: 'Customers', hash: 'customers' },
        { label: 'Bank Accounts', hash: 'bank-accounts' },
        { label: 'Vendors', hash: 'vendors' },
        ...(!isValliance ? [{ label: 'Annual Reviews', hash: 'annual' }] : []),
        ...(showAM ? [{ label: 'Adverse Media', hash: 'adverse' }] : []),
        { label: 'History', hash: 'history' }
      ]}
      active={props.active}
    />
  )
})
