import { memo, useCallback, useState } from 'react'

import { BeforeFormChangeHandler, Form, useFormData } from 'brief-form'
import styled from 'styled-components'
import { Validators } from 'ui/components'
import { LoginButton } from 'ui/components/Buttons'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { EmailInput, InputIconPosition, PhoneInput, TextInput } from 'ui/components/InputV2'
import { FormRow } from 'ui/themes/globalStyles'
import { AuthForm } from '~auth/components'

import { DeviceTypeSelector } from './components'

interface Props {
  onSubmit: (value: CreateDeviceFormShape) => Promise<any>
  logoElement: React.ReactElement
}

export type CreateDeviceFormShape = {
  type: string
  name: string
}

const placeholders: any = {
  sms: 'Phone number',
  email: 'Email address',
  totp: ''
}

//region Styled

const Hint = styled.div`
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.44px;
  color: #fff;
  margin-bottom: 45px;
`

const LoginCol = styled.div`
  width: 314px;
`

const Footer = styled.div`
  margin-top: 20px;
`

const inputStyle = {
  background: '#fff',
  border: 'solid 1.5px transparent',
  borderRadius: '5px',
  color: '#000'
}

//endregion

export const CreateDeviceForm = memo(({ onSubmit, logoElement }: Props) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')

  const onBeforeChange: BeforeFormChangeHandler<CreateDeviceFormShape> = useCallback(
    ({ value, errors, oldValue }) => {
      setError('')

      return {
        value: {
          type: value.type,
          name: value.type === 'totp' ? 'totp' : oldValue.type === value.type ? value.name : ''
        },
        errors: {
          type: errors.type,
          name: oldValue.type === value.type ? errors.name : undefined
        }
      }
    },
    [setError]
  )

  const { config, Field } = useFormData<CreateDeviceFormShape>({
    onBeforeChange,
    initialValue: {
      type: 'sms',
      name: ''
    }
  })

  const { value } = config

  const submitWrapper = useCallback(() => {
    setLoading(true)
    onSubmit(value)
      .catch((error) => {
        setError(error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [value, onSubmit, setError, setLoading])

  return (
    <AuthForm error={error} logo={logoElement}>
      <Hint>
        Please, choose a type of your first MFA device. We will send you security code there. Later you can change
        preferred method in your account settings.
      </Hint>
      <Form config={config}>
        <FormRow>
          <LoginCol>
            <Field name="type" input={DeviceTypeSelector} inputProps={{}} />
          </LoginCol>
        </FormRow>
        {value.type !== 'totp' && (
          <FormRow>
            <Field
              required
              name="name"
              input={value.type === 'email' ? EmailInput : value.type === 'sms' ? PhoneInput : TextInput}
              validator={Validators.required.string}
              inputProps={{
                placeholder: placeholders[value.type],
                style: inputStyle,
                iconPosition: InputIconPosition.LEFT_CLOSE,
                renderIcon: (
                  <Icon name={value.type === 'email' ? IconName.ENVELOPE : IconName.PHONE} size={IconSize.M} />
                )
              }}
            />
          </FormRow>
        )}
      </Form>
      <Footer>
        <LoginButton loading={loading} onClick={submitWrapper}>
          SEND CODE
        </LoginButton>
      </Footer>
    </AuthForm>
  )
})
