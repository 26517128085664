import { FC, memo } from 'react'

import { useLoaderData, useNavigate } from '@tanstack/react-router'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { Page, PageFace } from 'ui/components/Page'

import { useCompanyDelete } from '../../../../hooks'
import { ManageCompanyForm, useManageCompanyFormContext } from './components/ManageCompanyForm'
import { Footer } from './components/ManageCompanyForm/components/Footer'

export const ManageCompanyPage: FC = memo(() => {
  const navigate = useNavigate()
  const company = useLoaderData({ strict: false })

  const { data } = useManageCompanyFormContext()

  const { DeleteConfirmation, onDeleteCompany, companyForDelete } = useCompanyDelete(() => {
    navigate({ to: COMPANIES_PATH })
  })

  return (
    <Page
      title="Company Profile"
      face={PageFace.SECONDARY}
      isPending={!company}
      subTitle={company?.name || (!!company && !company.id && 'New Company') || '...'}
      footer={
        <Footer
          returnUrl={company?.id ? `${COMPANIES_PATH}/${data?.value.id}` : COMPANIES_PATH}
          onSubmit={data?.submitHandler}
          onDelete={company?.id ? data?.deleteHandler : undefined}
          isSubmitting={data?.isSubmitting ?? false}
        />
      }
    >
      <DeleteConfirmation />
      {!!company && (
        <ManageCompanyForm initialValue={company} onDelete={onDeleteCompany} companyForDelete={companyForDelete} />
      )}
    </Page>
  )
})
