import { useCallback, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import { useDebounce } from 'commons/hooks/common'
import { Request } from 'commons/utils/request'

import { SelectContext, UseFetchOptions } from '../types'
import { useGetData } from './useGetData'

export const useFetch = (context: SelectContext, options: UseFetchOptions) => {
  const { serverOptions } = options
  const { searchText, data, setData, setIsSearching } = context

  const firstLoadSearchText = useRef(searchText)
  const queryClient = useQueryClient()
  const queryKey = 'multi-checkbox-select-data'
  const getDataMutation = useGetData(queryKey)

  const fetch = useCallback(
    async (customFilters?: any) => {
      if (serverOptions) {
        await getDataMutation.mutateAsync({
          method: serverOptions.method || 'post',
          type: Request,
          url: serverOptions.url,
          serverOptions: {
            _options: {
              offset: 0,
              limit: serverOptions.limit,
              filters: customFilters
                ? customFilters
                : searchText && serverOptions.searchFields?.length
                ? ([
                    {
                      type: 'or',
                      filters: serverOptions.searchFields.map((field) => ({
                        field,
                        type: 'like',
                        value: `%${searchText}%`
                      }))
                    }
                  ]
                    .concat(serverOptions.filters as any)
                    .filter(Boolean) as any)
                : serverOptions.filters
            }
          }
        })

        const data = queryClient.getQueryData(queryKey) as any

        // TODO: refactor it. We have to do this tricky fix, because different API endpoint returns
        // TODO: data in different formats.
        // eslint-disable-next-line
        const collection = Array.isArray(data?.records) ? data.records : Array.isArray(data) ? data : []
        const processedCollection = collection.map(serverOptions.mapper).filter(Boolean)
        setData([...processedCollection])
        setIsSearching(false)
      }
    },
    [serverOptions, searchText, queryClient, getDataMutation, setData, setIsSearching]
  )

  const debouncedFetch = useDebounce(() => fetch(), 600)

  // fetch data on searching
  useEffect(() => {
    if (firstLoadSearchText.current === searchText && data.length !== 0) {
      return
    }
    if (serverOptions) {
      setIsSearching(true)
      debouncedFetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])
}
