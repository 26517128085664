import { useCallback, useState } from 'react'

import { Company } from 'commons/types/DTO/bank'

import { CompanyOwners } from '../CompanyOwners'

export const useCompanyOwners = (
  companyId: number,
  value: string[],
  valueTitle: string,
  availableOwners: { id: string; fullName: string }[],
  onSetCompanyOwners: (opts: { companyId: number; ids: string[] }) => Promise<Company>
) => {
  const [isCompanyOwnersVisible, setIsCompanyOwnersVisible] = useState(false)
  const openCompanyOwners = useCallback(() => setIsCompanyOwnersVisible(true), [setIsCompanyOwnersVisible])
  const closeCompanyOwners = useCallback(() => setIsCompanyOwnersVisible(false), [setIsCompanyOwnersVisible])

  const CompanyOwnersDialog = useCallback(
    () => (
      <CompanyOwners
        visible={isCompanyOwnersVisible}
        onClose={closeCompanyOwners}
        valueTitle={valueTitle}
        availableOwners={availableOwners}
        companyId={companyId}
        ids={value}
        onChange={async (opts) => {
          await onSetCompanyOwners(opts)
          closeCompanyOwners()
        }}
      />
    ),
    [isCompanyOwnersVisible, closeCompanyOwners, value, onSetCompanyOwners]
  )

  return {
    CompanyOwnersDialog,
    openCompanyOwners
  }
}
