import { FC, memo, useCallback } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { FF } from 'ui/components'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { AdverseMediaListRequest, AdverseMediaListResponse } from '~bank-adverse-media/types'

import * as SC from './styles'

const routeApi = getRouteApi('/adverse-media/')

export const Filter: FC = memo(() => {
  const navigate = routeApi.useNavigate()

  const data = routeApi.useLoaderData() as AdverseMediaListResponse
  const isChanged = routeApi.useLoaderDeps({ select: (s) => s.isChanged })
  const searchParams = routeApi.useSearch()

  const formApi = useForm<Pick<AdverseMediaListRequest, 'riskLevel' | 'entityType'>>({
    defaultValues: {
      riskLevel: searchParams.riskLevel || '',
      entityType: searchParams.entityType || ''
    }
  })

  const clear = useCallback(() => {
    formApi.reset()
    navigate({ search: undefined })
  }, [])

  const updateSearchParams = (newValue: { [key in keyof AdverseMediaListRequest]?: string }) =>
    navigate({ to: '.', search: (prev) => ({ ...prev, page: 1, ...newValue }), resetScroll: false })

  return (
    <FilterPanel isDirty={isChanged} onReset={clear}>
      <SC.Box>
        <SC.Wrapper>
          <formApi.Field
            name="riskLevel"
            validators={{
              onChange: ({ value }) => {
                updateSearchParams({ riskLevel: value })
                return undefined
              }
            }}
            children={(field) => (
              <FF
                field={field}
                label="Risk Level"
                input={(inputProps) => <Select data={data?.riskLevels || []} {...inputProps} />}
              />
            )}
          />
        </SC.Wrapper>
        <SC.Wrapper>
          <formApi.Field
            name="entityType"
            validators={{
              onChange: ({ value }) => {
                updateSearchParams({ entityType: value })
                return undefined
              }
            }}
            children={(field) => (
              <FF
                field={field}
                label="Entity Type"
                input={(inputProps) => <Select data={data?.entityTypes || []} {...inputProps} />}
              />
            )}
          />
        </SC.Wrapper>
      </SC.Box>
    </FilterPanel>
  )
})
