import { getLicenseList } from 'commons/service/bank/license'
import { LicenseDeprecated } from 'commons/types/DTO/bank'
import { ApiReadResponse } from 'commons/types/api'

export const getHandler = (id?: number, companyId?: number) => (): Promise<ApiReadResponse<LicenseDeprecated>> =>
  id
    ? getLicenseList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } })
    : Promise.resolve({
        total_count: 1,
        records: [
          {
            active: null,
            city: null,
            city_tax: null,
            company_id: companyId,
            county_tax: null,
            created_at: null,
            deleted_at: null,
            expiration_date: null,
            id: 0,
            internal_transfers: false,
            issue_date: null,
            license_number: null,
            mj_retail_tax: null,
            name: '',
            phone: null,
            pos_type: null,
            pos_type_other: null,
            postal_code: null,
            sf_external_id: null,
            special_tax: null,
            state: null,
            state_tax: null,
            street_address: null,
            subtype: null,
            type: null,
            updated_at: null
          }
        ]
      })
