import { SortDirection } from 'ui/components'

import { DocumentListFilters } from '../types'
import { TableState } from '../useTableState/types'
import emptyTableState from './emptyTableState'

const calculateInitState = ({
  externalTableState,
  initFilters
}: {
  externalTableState?: Partial<TableState>
  initFilters?: DocumentListFilters
}): TableState => {
  if (initFilters?.filter) {
    return {
      ...emptyTableState,
      ...externalTableState,
      pageSize: initFilters.filter.size || emptyTableState.pageSize,
      page: initFilters.filter.page || emptyTableState.page,
      ...(initFilters.filter.sortBy
        ? {
            order: [
              {
                field: initFilters.filter.sortBy,
                direction: initFilters.filter.sortDesc ? SortDirection.DESC : SortDirection.ASC
              }
            ]
          }
        : {})
    }
  }
  return {
    ...emptyTableState,
    ...externalTableState
  }
}

export default calculateInitState
