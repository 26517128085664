import { FC, memo } from 'react'

import { SpinnerSize } from 'ui/components/Spinner'

import { ButtonProps } from './ButtonProps'

import * as SC from './styles'

export const Button: FC<ButtonProps> = memo((props) => {
  const { children, face = 'neutral', loading = false, disabled, 'data-qa': dataQA, ...rest } = props

  const data_qa = typeof children === 'string' && !dataQA ? children : dataQA

  return (
    <>
      {loading ? (
        <SC.BoxLoading disabled={true} $face={face} data-qa={data_qa} {...rest}>
          <SC.Spinner centered={true} size={SpinnerSize.XS} durationInSeconds={1.5} />
        </SC.BoxLoading>
      ) : (
        <SC.Box disabled={disabled} $face={face} data-qa={data_qa} {...rest}>
          <SC.Inner>{children}</SC.Inner>
        </SC.Box>
      )}
    </>
  )
})
