import { FC, memo } from 'react'

import { Select } from '../Select'
import { SelectListName, getOptionsByListName } from '../SingleSelectAutoSuggest'
import { OrganisationSelectProps } from './OrganisationSelectProps'

const PLACEHOLDER = 'Start entering the organization name'

export const OrganisationSelect: FC<OrganisationSelectProps> = memo((props) => {
  const { value, emptyOption, ...rest } = props

  return (
    <Select
      emptyOption={emptyOption}
      placeholder={PLACEHOLDER}
      data={(emptyOption ? [emptyOption] : []).concat(getOptionsByListName(SelectListName.ORGANISATIONS))}
      value={value}
      searchable
      {...rest}
    />
  )
})
