import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const ProductTypes = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="suspiciousActivityInfo.productTypes"
      children={(field) => (
        <FF
          field={field}
          label="45. Were any of the following product type(s) involved in the suspicious activity?"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'42. Cyber event'}
              direction={'vertical'}
              data={[
                { label: 'a. Bonds/Notes', value: 'a' },
                { label: 'b. Commercial mortgage', value: 'b' },
                { label: 'c. Commercial paper', value: 'c' },
                { label: 'd. Credit card', value: 'd' },
                { label: 'e. Debit card', value: 'e' },
                { label: 'f. Deposit account', value: 'f' },
                { label: 'g. Forex transactions', value: 'g' },
                { label: 'h. Futures/Options on futures', value: 'h' },
                { label: 'i. Hedge fund', value: 'i' },
                { label: 'j. Home equity line of credit', value: 'j' },
                { label: 'k. Home equity loan', value: 'k' },
                { label: 'l. Insurance/Annuity products', value: 'l' },
                { label: 'm. Microcap securities', value: 'm' },
                { label: 'n. Mutual fund', value: 'n' },
                { label: 'o. Options on securities', value: 'o' },
                { label: 'p. Prepaid access', value: 'p' },
                { label: 'q. Residential mortgage', value: 'q' },
                { label: 'r. Security futures products', value: 'r' },
                { label: 's. Stocks', value: 's' },
                { label: 't. Swap, hybrid, or other derivatives', value: 't' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
