import { FC, memo } from 'react'

import { Form, useFormData } from 'brief-form'
import { TextInput } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Dialog, DialogFace } from 'ui/components/Dialog'

import { ManageFileDialogProps } from './ManageFileDialog.types'

import { s } from './styles'

export const ManageFileDialog: FC<ManageFileDialogProps> = memo((props) => {
  const { onClose, isUpdating, onUpdate, fileToManage } = props
  const { config, Field } = useFormData<{ name: string }>({ initialValue: { name: fileToManage.name } })

  const updateHandler = () => {
    onUpdate(config.value.name)
  }

  return (
    <Dialog
      face={DialogFace.PRIMARY}
      id="edit-file-dialog"
      title="Edit File"
      onClose={onClose}
      customFooterActions={
        <ButtonGroup margin="small">
          <Button face="positive" data-qa="Save" loading={isUpdating} onClick={updateHandler}>
            Save
          </Button>
          <Button face="neutral" data-qa="Cancel" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <s.Box>
        <Form config={config}>
          <Field
            name="name"
            label="File Name"
            input={TextInput}
            inputProps={{
              'data-qa': 'File Name'
            }}
          />
        </Form>
      </s.Box>
    </Dialog>
  )
})
