import { FC, memo } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { Form } from 'brief-form'
import { CheckBox, CompanySelect, ContactSelect, DatePicker, MultiSelectAutoSuggest } from 'ui/components'
import { CurrencyCentInput, PercentInput, TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { CONTACTS_PATH } from '../../../../../../constants'
import { ContactMetadataManageFormProps } from './ContactMetadataManageFormProps'

import { EditLink, FormBody } from './styles'

export const ContactMetadataManageForm: FC<ContactMetadataManageFormProps> = memo((props) => {
  const { config, Field, companyId, contactId, contactName } = props

  const finalContactId = contactId || config.value.contactId
  const { hash, pathname } = useRouterState({ select: (s) => s.location })
  const returnUrl = pathname + hash

  const emptyContactOption = contactId
    ? {
        label: contactName,
        value: contactId
      }
    : undefined

  return (
    <Form config={config}>
      <FormBody>
        <FormRow>
          <Col>
            <Field
              required
              name="companyId"
              label="Company"
              input={CompanySelect}
              inputProps={{
                hideMyCompaniesFilter: true,
                disabled: !!companyId,
                'data-qa': 'Company',
                'options-data-qa': 'Company'
              }}
              validator={(v) => (v ? undefined : 'Company required')}
            />
          </Col>
          <Col>
            <Field
              required
              name="contactId"
              label="Contact"
              input={ContactSelect}
              inputProps={{
                emptyOption: emptyContactOption,
                disabled: !!contactId
              }}
              validator={(v) => (v ? undefined : 'Contact required')}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              required
              name="title"
              label="Title"
              input={TextInput}
              inputProps={{
                'data-qa': 'Title'
              }}
              validator={(v) => (v?.toString().length ? undefined : 'Title required')}
            />
          </Col>
          <Col>
            <Field
              name="permissions"
              label="Permissions"
              input={MultiSelectAutoSuggest}
              inputProps={{
                options: [
                  { label: 'Documents', value: 'documents' },
                  { label: 'Financials', value: 'financials' }
                ],
                valueTitle: 'Choose permissions',
                'data-qa': 'Permissions'
              }}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              name="isOwner"
              label="Owner"
              input={CheckBox}
              inputProps={{
                'data-qa': 'Owner'
              }}
            />
          </Col>
          <Col>
            <Field name="isDebtholder" label="Debtholder" input={CheckBox} inputProps={{}} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field name="isAccountSigner" label="Account Signer" input={CheckBox} inputProps={{}} />
          </Col>
        </FormRow>
        {config.value.isOwner && (
          <FormRow>
            <Col>
              <Field
                name="ownershipPercent"
                label="Ownership %"
                input={PercentInput}
                inputProps={{
                  'data-qa': 'Ownership %'
                }}
              />
            </Col>
            <Col>
              <Field
                name="ownershipAmount"
                label="Investment"
                input={CurrencyCentInput}
                inputProps={{
                  'data-qa': 'Investment'
                }}
              />
            </Col>
          </FormRow>
        )}

        {config.value.isDebtholder && (
          <>
            <FormRow>
              <Col>
                <Field
                  name="debtAmount"
                  label="Debt Amount"
                  input={CurrencyCentInput}
                  inputProps={{
                    defaultEmptyValue: null,
                    'data-qa': 'Debt Amount'
                  }}
                />
              </Col>
              <Col>
                <Field
                  name="debtPaymentFrequency"
                  label="Payment Frequency"
                  input={TextInput}
                  inputProps={{
                    'data-qa': 'Payment Frequency'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="debtMonthlyPayment"
                  label="Payment Amount"
                  input={CurrencyCentInput}
                  inputProps={{
                    defaultEmptyValue: null,
                    'data-qa': 'Payment Amount'
                  }}
                />
              </Col>
              <Col>
                <Field
                  name="debtMaturityDate"
                  label="Maturity Date"
                  input={DatePicker}
                  inputProps={{
                    'data-qa': 'Maturity Date'
                  }}
                />
              </Col>
            </FormRow>
          </>
        )}
        {!!finalContactId && (
          <EditLink to={`${CONTACTS_PATH}/${finalContactId}/edit?returnUrl=${encodeURIComponent(returnUrl)}`}>
            Edit Contact Info
          </EditLink>
        )}
      </FormBody>
    </Form>
  )
})
