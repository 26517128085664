import { ReactNode, useState } from 'react'

import { useNavigate, useRouter } from '@tanstack/react-router'
import { CancelEsignLinkDocumentDialog } from 'bank-onboarding-documents/src/components/CancelEsignLinkDocumentDialog'
import { useDeleteDocument } from 'bank-onboarding-documents/src/hooks'
import { useSendOnboardingDocument } from 'commons/hooks/bank/onboardingSetupDocuments'
import { Request } from 'commons/utils/request'
import {
  DownloadLink,
  IconName,
  MenuIconHoverFace,
  MenuItemBehavior,
  MenuItemTextFace,
  MenuItemType,
  TableActions,
  Toast
} from 'ui/components'
import { TableButton } from 'ui/components/Buttons'

import { ActionsProps } from './Actions.types'

export const Actions = ({ item, applicationId }: ActionsProps): ReactNode => {
  const router = useRouter()
  const navigate = useNavigate()

  const { id, canCancel, canDownload, canEdit, canSend, canDelete, canClone } = item

  const [showCancelEsignDialog, setShowCancelEsignDialog] = useState(false)

  const { openDeleteConfirmation } = useDeleteDocument(id, () => router.invalidate())

  const { routine: sendOnboardingDocument } = useSendOnboardingDocument({
    onSuccess: async () => {
      Toast.successSmall(`The set of documents ID-${id} already sent`)
      router.invalidate()
    }
  })

  let tableActionsComponent: ReactNode

  if (canDownload)
    tableActionsComponent = (
      <DownloadLink
        direct
        baseUrl={Request.urls.bank.downloadOnboardingDocuments}
        parametersForm={{ appDocusignEnvelopeId: id }}
        handleError={(error) => Toast.errorSmall(error)}
        withIcon={false}
      >
        <TableButton>Download</TableButton>
      </DownloadLink>
    )
  else
    tableActionsComponent = (
      <TableActions
        items={[
          ...(canSend
            ? [
                {
                  key: 1,
                  title: 'SEND',
                  textFace: MenuItemTextFace.BLACK,
                  behavior: MenuItemBehavior.BUTTON,
                  onClick: async () => await sendOnboardingDocument(id)
                }
              ]
            : []),
          ...(canSend ? [{ key: 2, kind: MenuItemType.DIVIDER }] : []),
          ...(canEdit
            ? [
                {
                  key: 3,
                  title: 'Edit',
                  iconName: IconName.EDIT,
                  iconHoverFace: MenuIconHoverFace.PRIMARY,
                  textFace: MenuItemTextFace.GREY,
                  behavior: MenuItemBehavior.BUTTON,
                  onClick: () =>
                    navigate({
                      to: '/application/$applicationId/onboarding-documents/$id/edit',
                      params: { applicationId, id }
                    })
                }
              ]
            : []),
          ...(canCancel
            ? [
                {
                  key: 4,
                  title: 'CANCEL ESIGN LINK',
                  behavior: MenuItemBehavior.BUTTON,
                  textFace: MenuItemTextFace.GREY,
                  onClick: () => setShowCancelEsignDialog(true)
                }
              ]
            : []),
          ...(canClone
            ? [
                {
                  key: 5,
                  title: 'CLONE',
                  behavior: MenuItemBehavior.BUTTON,
                  textFace: MenuItemTextFace.GREY
                }
              ]
            : []),
          ...(canDelete
            ? [
                {
                  key: 6,
                  title: 'Delete',
                  iconName: IconName.DELETE,
                  iconHoverFace: MenuIconHoverFace.DANGER,
                  behavior: MenuItemBehavior.BUTTON,
                  textFace: MenuItemTextFace.GREY,
                  onClick: () => openDeleteConfirmation()
                }
              ]
            : [])
        ].filter(Boolean)}
      />
    )

  return (
    <>
      {canCancel && showCancelEsignDialog && (
        <CancelEsignLinkDocumentDialog
          id={id}
          onClose={() => setShowCancelEsignDialog(false)}
          onSuccess={() => router.invalidate()}
        />
      )}
      {tableActionsComponent}
    </>
  )
}
