import { getRouteApi } from '@tanstack/react-router'
import { PageSection } from 'ui/components'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

import { SalesAndDepositTable } from './components/SalesAndDepositTable'

export const SalesDeposits = () => {
  const { salesDeposits } = getRouteApi(AnnualReviewPaths.DETAILS).useLoaderData() as AnnualReviewDetails

  return (
    <>
      {salesDeposits?.map((sd, index) => (
        <PageSection key={sd?.id} title={!index ? 'Sales & Deposit Summary Tables' : undefined}>
          <SalesAndDepositTable salesDeposit={sd} />
        </PageSection>
      ))}
    </>
  )
}
