import styled from 'styled-components'

const Question = styled.div<{ $bottomIndent?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  font-size: ${(p) => p.theme.uiKit.fontSize.bodyM};
  line-height: ${(p) => p.theme.uiKit.lineHeights.m};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: ${(props) => (props.$bottomIndent ? '42px' : '20px')};
  gap: 8px 32px;
`

const Label = styled.div<{ $labelPadding?: boolean }>`
  font-style: italic;
  padding-top: ${(props) => (props.$labelPadding ? '10px' : '0')};
`

const Value = styled.div`
  margin-top: 8px;
  flex-grow: 1;
  width: 100%;
`

export const s = { Question, Label, Value }
