import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const IDNumber = () => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((s) => s.values.institutionContactInfo?.idType)

  return (
    <formApi.Field
      name="institutionContactInfo.idNumber"
      validators={{
        onChange: ({ value }) => (type?.length > 0 ? Validators.required.field(value) : undefined)
      }}
      children={(field) => (
        <FF label="ID Number" required={type?.length > 0} field={field} input={(p) => <TextInput {...p} />} />
      )}
    />
  )
}
