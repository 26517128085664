export type LicenseWebPageDocumentFilter = {
  alerts?: string
  frequency?: string
  internal?: boolean | null
  page?: number
  size?: number
  sortBy?: string
  sortDesc?: boolean
}

export type LicenseWebPageRequest = {
  documentsFilter: LicenseWebPageDocumentFilter
  historyPage: number
  historySize: number
  id: number
  invoicesPage: number
  invoicesSize: number
}
