import { FC, memo } from 'react'

import { HistoryEntity } from 'commons/types/DTO/bank/historyAudit'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Table } from 'ui/components'

import { HistoryTableProps } from './HistoryTableProps'
import { columns } from './columns'

export const HistoryTable: FC<HistoryTableProps> = memo((props) => {
  const {
    loading,
    items,
    pagination,
    showTypeColumn = false,
    showEntityIdColumn = false,
    onClickEntityId,
    dataQa = TableDataQa.BP_ENTITY_HISTORY_LIST
  } = props

  const dataSourceItems: DataSourceItem<HistoryEntity>[] = items?.map((item) => ({
    item
  }))

  return (
    <Table<HistoryEntity>
      dataQa={dataQa}
      loading={loading}
      columns={columns(showTypeColumn, showEntityIdColumn, onClickEntityId)}
      dataSource={dataSourceItems}
      pagination={pagination}
    />
  )
})
