import { PageSection, PageSectionFace } from 'ui/components'
import { Button } from 'ui/components/Buttons'

import { emptySAR } from '../../../../../../../constants'
import { useSARFormContext } from '../../../../context'
import { SubjectInformationItem } from './SubjectInformationItem'

export const SubjectInformationForm = () => {
  const { formApi } = useSARFormContext()

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 3. Subject Information" anchor="step3">
      <formApi.Field mode="array" name="subjectsInfo">
        {(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {field.state.value.map((_, index) => (
                <SubjectInformationItem key={index} index={index} field={field} />
              ))}
              <Button
                face="positive"
                onClick={() => {
                  field.pushValue(emptySAR.subjectsInfo[0])
                }}
              >
                add
              </Button>
            </>
          )
        }}
      </formApi.Field>
    </PageSection>
  )
}
