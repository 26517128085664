import { getApplicationRelationshipBankAccounts } from 'commons/service/bank/application'
import { ApplicationRelationshipBankAccountsResponse } from 'commons/types/DTO/bank/applications'
import { Keys, ReadHook, useStateManager } from 'state-manager'

type ApplicationRelationshipBankAccountParams = {
  applicationId?: number
}

export const useApplicationFinalizationBankAccounts: ReadHook<
  ApplicationRelationshipBankAccountsResponse,
  ApplicationRelationshipBankAccountParams
> = (opts) =>
  useStateManager().use<ApplicationRelationshipBankAccountsResponse, Error>(
    Keys.ApplicationBankAccounts.RelationshipBankAccounts(opts?.applicationId || 0),
    () => getApplicationRelationshipBankAccounts(opts?.applicationId),
    {
      keepPreviousData: true
    }
  )
