import { Request } from 'commons/utils/request'
import {
  DateTime,
  DownloadLink,
  IconName,
  MenuIconHoverFace,
  MenuItemBehavior,
  MenuItemTextFace,
  TableActions,
  TableColumn,
  Toast
} from 'ui/components'

import { Files } from './types'

export const columns = (
  startDeleting: (id: number) => void,
  startUpdating: ({ id, name }: { id: number; name: string }) => void
): TableColumn<Files>[] => [
  {
    key: 'fileName',
    title: 'Filename',
    align: 'left',
    render: (item) => (
      <DownloadLink
        direct
        parametersForm={{ id: item.fileId }}
        baseUrl={Request.urls.bank.applicationClientDocumentFileDirectDownload}
        name={item.fileName}
        handleError={(message: string) => Toast.error('Error', message)}
      >
        {item.fileName}
      </DownloadLink>
    )
  },
  {
    key: 'fileCreatedAt',
    title: 'Uploaded Date',
    align: 'left',
    render: ({ fileCreatedAt }) => <DateTime date={fileCreatedAt} withTime />
  },
  {
    key: 'fileId',
    title: 'Actions',
    align: 'left',
    width: '50px',
    render: ({ fileId, fileName }) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            behavior: MenuItemBehavior.BUTTON,
            onClick: () => startUpdating({ id: fileId, name: fileName })
          },
          {
            key: 2,
            title: 'Delete',
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            behavior: MenuItemBehavior.BUTTON,
            onClick: () => startDeleting(fileId)
          }
        ]}
      />
    )
  }
]
