import { createRoot } from 'react-dom/client'

import { ConsoleLogger, Logger, SentryHandler } from 'commons/utils/logger'
import WebFont from 'webfontloader'

import { Application } from './modules/application'

const logger = Logger.getInstance()

// Initialize capturing unhandled errors by Sentry.
if ("production" === 'production' && "https://fcbab8e54bc0477b96fffaf7863583ae@o384313.ingest.sentry.io/5216733" && "0419e6da3") {
  logger.addLoggers([
    new ConsoleLogger(),
    SentryHandler.getInstance("https://fcbab8e54bc0477b96fffaf7863583ae@o384313.ingest.sentry.io/5216733", "0419e6da3", [])
  ])
} else {
  logger.addLoggers([new ConsoleLogger()])
}

WebFont.load({
  google: {
    families: ['Source Sans Pro:300,400,500,600,700&display=swap']
  }
})
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
createRoot(document.getElementById('root')).render(<Application />)
