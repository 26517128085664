import { memo, useMemo } from 'react'

import SC from 'styled-components'

import { Select } from '../Select'

interface Props {
  pageSizeOptions?: string[]
  pageSize: number
  changeSize: any
  disabled?: boolean
}

const Content = SC.div<any>`
  margin: 0 0 0 10px;
`

const pageSizeDefault = ['10', '20', '50', '100']

export const Options = memo((props: Props) => {
  const { pageSize, pageSizeOptions = pageSizeDefault, changeSize, disabled } = props
  const getPageSizeOptions = useMemo(() => {
    if (pageSizeOptions.some((option) => option.toString() === pageSize.toString())) {
      return pageSizeOptions
    }
    return pageSizeOptions.concat([pageSize.toString()]).sort((a, b) => {
      const numberA = isNaN(Number(a)) ? 0 : Number(a)
      const numberB = isNaN(Number(b)) ? 0 : Number(b)
      return numberA - numberB
    })
  }, [pageSize, pageSizeOptions])
  const onChangeSize = (value: string) => changeSize(Number(value))
  return (
    <Content>
      <Select
        value={(pageSize || getPageSizeOptions[0]).toString()}
        onChange={onChangeSize}
        disabled={disabled}
        data={getPageSizeOptions.map((value) => ({ label: value, value }))}
      />
    </Content>
  )
})
