import { memo } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { InnerMenu } from 'ui/components/SideMenu'
import { useQRDetails } from '~bank-quarterly-report/hooks/qr'

export const QRSideBar = memo(() => {
  const routeState = useRouterState()
  const pathname = routeState.location.pathname
  const id = +pathname.split('/')[2]

  const { webPageData } = useQRDetails(id)

  if (!webPageData) return null

  const items = [{ label: 'General Information', hash: 'general' }].concat(
    webPageData.bankAccounts.map((_, index) => ({ label: `Step ${index + 1}`, hash: `bank-account-${index}` })),
    { label: 'Uploaded Files', hash: 'uploaded_files' },
    { label: 'History', hash: 'history' }
  )

  if (webPageData.enableQuestionnaire) {
    items.push({ label: 'Red Flags', hash: 'red-flags' })
  }

  return <InnerMenu items={items} />
})
