import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'

import { useSARFormContext } from '../../../../../../../../context'
import { BankAccount } from './fields'

import { s } from './styles'

export const BankAccounts = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noKnownAccountsInvolved = formApi.useStore((s) => s.values.subjectsInfo[index1].noKnownAccountsInvolved)

  return (
    <formApi.Field mode="array" name={`subjectsInfo[${index1 as 0}].institutionsInfo[${index2 as 0}].accountNumbers`}>
      {(field) => {
        if (typeof field.state.value === 'undefined') {
          return null
        }

        return (
          <s.BankAccountsContainer>
            {(field.state.value || []).map((_, index3) => (
              <BankAccount
                key={`${index1}-${index2}-${index3}`}
                index1={index1}
                index2={index2}
                index3={index3}
                parentField={field}
              />
            ))}
            {!noKnownAccountsInvolved && (
              <s.BankAccountsButton>
                <IconButton
                  icon={IconName.ADD_GREEN}
                  onClick={() =>
                    field.pushValue({
                      number: '',
                      closed: false,
                      formattedNumber: ''
                    })
                  }
                />
              </s.BankAccountsButton>
            )}
          </s.BankAccountsContainer>
        )
      }}
    </formApi.Field>
  )
}
