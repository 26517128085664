import { FC, useMemo } from 'react'

import { GetLinkModal } from 'bank-onboarding/src/routes/ApplicationList/components/ApplicationsListTable/components'
import { Applications } from 'commons/types/DTO/bank/alerts/applications'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'

import { ApplicationsTableProps } from './ApplicationsTableProps'
import { columns } from './columns'
import { useComponentsEssence } from './hooks'

export const ApplicationsTable: FC<ApplicationsTableProps> = (props) => {
  const { applications, applicationsTotalCount, onAlertFilterChange } = props
  const { openGetLinkModal, showGetLinkModal, tableState, applicationId, onSort, loading, changePagination, onClose } =
    useComponentsEssence(onAlertFilterChange)

  const memoizedColumns = useMemo(() => columns(showGetLinkModal), [])

  const mappedApplications: DataSourceItem<Applications>[] = useMemo(
    () =>
      applications.map((el) => ({
        item: el
      })),
    [applications]
  )

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="Applications" anchor="applications">
      <Layer rounded shadowed stretch>
        {openGetLinkModal && applicationId && <GetLinkModal onClose={onClose} applicationId={applicationId} />}
        <Table<Applications>
          dataQa={TableDataQa.BP_ALERTS_APPLICATIONS}
          columns={memoizedColumns}
          dataSource={mappedApplications}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: applicationsTotalCount
          }}
          sorting={{ order: tableState.order, onChange: (value) => onSort(value.direction, value.field) }}
        />
      </Layer>
    </PageSection>
  )
}
