import styled, { css } from 'styled-components'
import { Icon } from 'ui/components/Icon'

const containerCss = css`
  display: flex;
  flex-direction: column;
  background: ${(p) =>
    p.theme.uiKit.colors.theme.gradient.login
      ? p.theme.uiKit.colors.theme.gradient.login
      : p.theme.uiKit.colors.theme.brand.primary};
  border-radius: 0 70px 0 70px;
  opacity: 1;
  width: 420px;
  overflow: hidden;
  z-index: 1;
`

export const Container = styled.div`
  ${containerCss}
`

export const formCss = css<{ $error: boolean }>`
  padding: ${(p) => (p.$error ? '30px' : '60px')} 53px 65px 53px;
`

export const Form = styled.form`
  ${formCss}
`

const errorCss = css`
  background: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.2px;
  padding: 21px 95px 21px 53px;
  display: flex;
  align-items: center;
`

export const Error = styled.div`
  ${errorCss}
`

export const Text = styled.span``

const infoCss = css`
  background: var(--theme-colors-theme-brand-darkest);
  color: var(--theme-colors-global-general-main);
  font: var(--theme-typography-bodyM-semiBold);
  padding: 21px 95px 21px 53px;
  display: flex;
  align-items: center;
`

export const Info = styled.div`
  ${infoCss}
`

const exclamationCss = css`
  margin-right: 16px;
  min-width: 20px;
`

export const Exclamation = styled(Icon)`
  ${exclamationCss}
`
