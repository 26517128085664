import { SetupDocumentFieldType } from 'commons/types/DTO/bank/applications/onboardingDocuments/SetupDocumentFieldType'
import { DateTime } from 'ui/components'

type ArgsType = {
  fieldName: SetupDocumentFieldType

  value: string
}

export const getCorrectValueView = ({ fieldName, value }: ArgsType) => {
  switch (fieldName) {
    case 'date':
      return <DateTime date={value} />

    default:
      return <>{value}</>
  }
}
