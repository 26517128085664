import { FieldApi } from '@tanstack/react-form'
import { BankAccountInput, CheckBox, FF, IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../../../context'

type BankAccountProps = {
  index1: number
  index2: number
  index3: number
  parentField: FieldApi<SarEdit, 'subjectsInfo[0].institutionsInfo[0].accountNumbers'>
}

export const BankAccount = (props: BankAccountProps) => {
  const { index1, index2, index3, parentField } = props
  const { formApi } = useSARFormContext()

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index3),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <formApi.Field
        name={`subjectsInfo[${index1 as 0}].institutionsInfo[${index2 as 0}].accountNumbers[${index3 as 0}].number`}
        children={(field) => (
          <FormRow>
            <Col>
              <FF field={field} input={(p) => <BankAccountInput {...p} />} label="Account number (s)" />
            </Col>
            <formApi.Field
              name={`subjectsInfo[${index1 as 0}].institutionsInfo[${index2 as 0}].accountNumbers[${
                index3 as 0
              }].closed`}
              children={(field) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="Closed" />} />
                </div>
              )}
            />
            {parentField.state.value.length > 1 && (
              <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
            )}
          </FormRow>
        )}
      />
    </>
  )
}
