import { FC, memo } from 'react'

import { ResourcePath } from 'ui/temp/ResourcePath'

import { LicensesTitleProps } from './LicensesTitleProps'

export const LicensesTitle: FC<LicensesTitleProps> = memo(({ licenses, withUrl }) => (
  <>
    {licenses.length === 1 && (
      <>
        {withUrl ? (
          <>
            <ResourcePath
              finishedPath={`/licenses/${licenses[0]?.licenseId}`}
              name={licenses[0]?.licenseNumber}
              id={licenses[0]?.licenseId}
            />
          </>
        ) : (
          <>{licenses[0].licenseNumber}</>
        )}
      </>
    )}
    {licenses.length > 1 && (
      <>
        (
        {licenses.map((el, i) => (
          <>
            {withUrl ? (
              <ResourcePath finishedPath={`/licenses/${el.licenseId}`} name={el.licenseNumber} id={el.licenseId} />
            ) : (
              <>{el.licenseNumber}</>
            )}
            {i === licenses.length - 1 ? '' : ', '}
          </>
        ))}
        )
      </>
    )}
  </>
))
