import { FC, memo } from 'react'

import { InfoSet } from 'ui/components/Info'

import { getAvailableServicesList } from '../../forms/AvailableServicesForm/helpers'
import { AvailableServicesDetailsProps } from './AvailableServicesDetailsProps'

export const AvailableServicesDetails: FC<AvailableServicesDetailsProps> = memo(({ value, state: { conditions } }) => {
  const availableServicesList = getAvailableServicesList(conditions)
  const availableServicesSelectedList = availableServicesList
    .map((item) => (value[item.name] ? item.label : undefined))
    .filter((value) => !!value)
    .join(', ')
  return <InfoSet legend="Available Services">{availableServicesSelectedList || '---'}</InfoSet>
})
