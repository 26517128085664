import { memo } from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import { Layer, PageSection } from 'ui/components'
import { DocumentsPaths } from '~bank-documents/constants'

export const HistorySection = memo(() => {
  const routeApi = getRouteApi(DocumentsPaths.PERIOD_DETAILS)
  const navigate = useNavigate({ from: DocumentsPaths.PERIOD_DETAILS })
  const flatDeps = routeApi.useRouteContext({ select: (s) => s.flatDeps })

  const data = routeApi.useLoaderData()

  return (
    <PageSection title="Document Period History">
      <Layer rounded shadowed>
        <HistoryTable
          dataQa={TableDataQa.BP_DOCUMENTS_PERIOD_HISTORY}
          items={data.history}
          loading={false}
          pagination={{
            page: flatDeps.historyPage || 1,
            pageSize: flatDeps.historySize || 10,
            onChange: (historyPage, historySize) =>
              navigate({
                from: DocumentsPaths.PERIOD_DETAILS,
                to: DocumentsPaths.PERIOD_DETAILS,
                resetScroll: false,
                replace: true,
                search: { historyPage, historySize }
              }),
            total: data.historyTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
})
