import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'

import { emptySAR } from '../../../../../../../../../constants'
import { useSARFormContext } from '../../../../../../context'
import { InstitutionActivityBranchesItem } from './InstitutionActivityBranchesItem'

export const InstitutionActivityBranchesInfo = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institutionActivitiesInfo[index].noBranchInvolved)

  return (
    <formApi.Field
      mode="array"
      name={
        // eslint-disable-next-line max-len
        `institutionActivitiesInfo[${index}].institutionActivityBranchesInfo` as 'institutionActivitiesInfo[0].institutionActivityBranchesInfo'
      }
    >
      {(field) => {
        if (typeof field.state.value === 'undefined') {
          return null
        }

        return (
          <>
            {field.state.value.map((_, index2) => (
              <InstitutionActivityBranchesItem key={index2} index={index} index2={index2} field={field} />
            ))}
            {!noBranchInvolved && (
              <IconButton
                face="positive"
                icon={IconName.ADD_GREEN}
                onClick={() => {
                  field.pushValue(emptySAR.institutionActivitiesInfo[0].institutionActivityBranchesInfo[0])
                }}
              />
            )}
          </>
        )
      }}
    </formApi.Field>
  )
}
