import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { SearchInput } from 'ui/components/InputV2'
import { CompanySelect } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const {
    form: { isDirty, config, Field },
    expanded,
    onToggle,
    relationshipOptions,
    clearHandler
  } = props

  return (
    <FilterPanel isDirty={isDirty} onReset={() => clearHandler()} expanded={expanded} onToggle={onToggle}>
      <Form config={config}>
        <SC.Columns>
          <SC.Column>
            <Field name="searchString" label="Search" input={SearchInput} inputProps={{}} />
          </SC.Column>
          <SC.Column>
            <Field
              name="companyID"
              label="Relationship"
              input={CompanySelect}
              inputProps={{
                searchable: true,
                hideMyCompaniesFilter: true,
                filters: [{ field: 'customer_status', type: 'eq', value: 'customer' }],
                preloadedOptions: relationshipOptions
              }}
            />
          </SC.Column>
        </SC.Columns>
      </Form>
    </FilterPanel>
  )
})
