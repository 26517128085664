import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const { filterForm, onClear, onToggle, isExpanded, statusOptions } = props
  const { Field } = filterForm

  return (
    <FilterPanel expanded={isExpanded} onToggle={onToggle} isDirty={filterForm.isDirty} onReset={onClear}>
      <SC.FormWrapper>
        <TableFilterRow>
          <Form config={filterForm.config}>
            <SC.FilterWrapper>
              <SC.ItemWrapper>
                <Field name="status" label="Status" input={Select} inputProps={{ data: statusOptions || [] }} />
              </SC.ItemWrapper>
            </SC.FilterWrapper>
          </Form>
        </TableFilterRow>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
