import { memo } from 'react'

import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Icon, IconName } from 'ui/components/Icon'

import { IWarnings } from '../List'

import { Content, Title } from './styles'

interface Props {
  warnings: IWarnings
  onClose: () => void
}

export const Warnings = memo(({ warnings, onClose }: Props) => {
  if (!warnings.warnings) {
    return null
  }
  return (
    <Dialog
      face={DialogFace.DEFAULT}
      id="warnings-dialog"
      onClose={onClose}
      title={
        <Title weight={700}>
          <Icon name={IconName.ERROR_RED} />
          <span>{warnings.warningsType === 'fincen' ? 'FinCEN Warning(s)' : 'Warning(s)'}</span>
        </Title>
      }
    >
      <Title weight={600} style={{ padding: '0 22px' }}>
        <span>
          {warnings.warningsType === 'fincen'
            ? 'All FinCEN warnings will be cleared once CTR is corrected and re-submitted.'
            : 'All warnings will be cleared once the CTR is Approved.'}
        </span>
      </Title>
      <Content>
        {warnings.warnings.split('\n').map((i: any) => (
          <li key={i}>{i}</li>
        ))}
      </Content>
    </Dialog>
  )
})
