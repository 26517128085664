/* eslint-disable max-len */
import { AnnualReviewQuestionnaireForm } from '~bank-annual-review/types/AnnualReviewQuestionnaireForm'

export const questionnaire2Labels: Record<keyof AnnualReviewQuestionnaireForm['questionnaire2'], string> & {
  hasMoreRevenue: string
  revenueFromMJOnly: string
  informationForMETRCDump: string
  comparingSICSAndBankDepositsRedFlag: string
  comparingSICSAndBankDepositsVariance: string
  comparingSICSAndBankDepositsComments: string
  hasMoreCashDeposits: string
  compareCashAmountDump: string
  comparingCashDepositsAndTaxReturnsRedFlag: string
  comparingCashDepositsAndTaxReturnsVariance: string
  comparingCashDepositsAndTaxReturnsComments: string
  inconsistentFinancialStatement: string
  validateContributionsDump: string
  financialStatementValidationRedFlag: string
  disguisingMRBActivity: string
  inconsistentMRBPurporting: string
  inconsistentMRBPurportingDueDiligenceCompleted: string
  ownerOutsideState: string
  ownerOutsideStateApproved: string
  adverseMediaRequired: string
  adverseMediaReviewed: string
  mrbOnFederalProperty: string
  mrbSchoolProximityConsistent: string
  mrbSchoolProximityDueDiligenceCompleted: string
} = {
  hasMoreRevenue:
    'The business receives substantially more revenue than its local competitors or than might be expected given the population demographics.',
  revenueFromMJOnly:
    'The business is unable to demonstrate that its revenue is derived exclusively from the sale of marijuana in compliance with state law, as opposed to derived from (i) the sale of other illicit drugs, (ii) the sale of marijuana not in compliance with state law, or (iii) other illegal activity.',
  informationForMETRCDump: 'Compare the prior year information for METRC and Bank Deposit activity.',
  comparingSICSAndBankDepositsRedFlag: 'Red Flag?',
  comparingSICSAndBankDepositsVariance: 'Is activity within variance?',
  comparingSICSAndBankDepositsComments: 'Comments:',
  hasMoreCashDeposits:
    'The business is depositing more cash than is commensurate with the amount of marijuana-related revenue it is reporting for federal and state tax purposes.',
  compareCashAmountDump:
    'Compare the cash amount for the prior year matching the most complete annual information for Sales Tax Returns.',
  comparingCashDepositsAndTaxReturnsRedFlag: 'Red Flag?',
  comparingCashDepositsAndTaxReturnsVariance: 'Is activity within variance?',
  comparingCashDepositsAndTaxReturnsComments: 'Comments:',
  inconsistentFinancialStatement:
    'Financial statements provided by the business to the financial institution are inconsistent with actual account activity.',
  validateContributionsDump: 'Validate contributions, distributions, loans, etc. on financial statement.',
  financialStatementValidationRedFlag: 'Red Flag?',
  financialStatementValidationVariance: 'Is there activity to validate?',
  financialStatementValidationComments: 'Comments:',
  disguisingMRBActivity:
    'A customer seeks to conceal or disguise involvement in marijuana-related business activity. (For example, the customer may be using a business with a non-descript name (e.g., a "consulting", "holding", or "management" company) that purports to engage in commercial activity unrelated to marijuana, but is depositing cash that smells like marijuana.)',
  inconsistentMRBPurporting:
    'A marijuana-related business purporting to be a "non-profit" is engaged in commercial activity inconsistent with that classification, or is making excessive payments to its manager(s) or employee(s).',
  inconsistentMRBPurportingDueDiligenceCompleted: 'Due diligence is completed during the account opening process.',
  ownerOutsideState:
    'The owner(s) or manager(s) of a marijuana-related business reside outside the state in which the business is located.',
  ownerOutsideStateApproved:
    'Due diligence is completed during the account opening process. The MED and CB&T Board of Directors have approved out-of-state ownership for cannabis companies.',
  adverseMediaRequired:
    'Review of publicly available sources and databases about the business, its owner(s), manager(s), or other related parties, reveal negative information, such as criminal record, involvement in the illegal purchase or sale of drugs, violence, or other potential connections to illicit activity.',
  adverseMediaReviewed: 'Public sources are searched by Orbit and reviewed weekly by a BSA Analyst/Officer.',
  mrbOnFederalProperty:
    'A marijuana-related business is located on federal property or the marijuana sold by the business was grown on federal property.',
  mrbSchoolProximityConsistent: 'A marijuana-related business proximity to a school is not compliant with state law.',
  mrbSchoolProximityDueDiligenceCompleted:
    'Due diligence is completed by the state in which the company operates at the time of license issuance.'
}

export const questionnaireDemoLabels: Record<keyof AnnualReviewQuestionnaireForm['questionnaireDemo'], string> = {
  covenantComplying: 'Is the company complying with bank covenants/account agreements?',
  covenantNonComplianceDesc: 'Describe any areas of non-compliance:',
  covenantTransparent: 'Is the company transparent about its operations?',
  financialsBecomingFailing: 'If condition is unstable, likelihood of company becoming solvent or failing:',
  financialsBusinessCondition: 'Current financial condition of business:',
  financialsDepositsMatching: 'Is deposit activity in line with sales?',
  financialsInvestorDebtholders: 'Investors/Debtholders:',
  financialsPeriodCollected: 'Period Collected:',
  financialsProfitabilityTrend: 'Profitability trend:',
  licensingLocChanges: 'Any location changes in the last year?',
  licensingLocChangesMmcc: 'If yes, approved by MMCC?',
  licensingNameChanges: 'Any name changes in the last year?',
  licensingNameChangesMmcc: 'If yes, approved by MMCC?',
  licensingNamePrior: 'Prior Name:',
  ownershipBadNews: 'Have there been any negative news posts about the company or its owners?',
  ownershipBadNewsCleared: 'Negative news alerts cleared by BSA/Compliance?',
  ownershipBadNewsClearedExplain: 'If no, explain status:',
  ownershipBadNewsDesc: 'If yes, please provide application details:',
  ownershipChanges: 'Has ownership of 5% or more of the company changed over the last year?',
  ownershipChangesApproved: 'Have 5% or more ownership changes been approved by the commission?',
  ownershipOFACTLOReviewed: 'Have OFAC/TLO checks been reviewed for new owners with >=5% interest?',
  ownershipProvidedForms: 'Company provided Beneficial Ownership Certification?',
  recommendationsChangeVisits: 'Recommended Site Visit Schedule:',
  recommendationsRecommended:
    'Based on the above information, do you recommend that the bank continue to offer this customer banking services?',
  recommendationsStatus: 'What status do you believe the customer has operated over the last year?',
  siteVisitsComplyingRegulations: 'Is company complying with state and local cannabis regulations verified by bank?',
  siteVisitsFrequency: 'Frequency:',
  siteVisitsLastVisitDate: 'Date of last visit:',
  siteVisitsScoresAcceptable: 'Company`s audit scores acceptable?',
  siteVisitsScoresAcceptableExactly: 'Were the company`s site audits acceptable?',
  siteVisitsShutdownRisk: 'Based on site visits, is company at risk of being shut down?',
  siteVisitsViolationRectified: 'If violation found, was it rectified?',
  transactionsExpectExactly: 'Expected volume changes:',
  transactionsExpectExplain: 'If unknown, explain:',
  transactionsMatchTrans: 'Transaction activity matches sales?',
  transactionsNotes: 'Transaction Notes:',
  transactionsTypes: 'Types of transactions performed:',
  transactionsVolProjected: 'Volumes as anticipated/projected?',
  transactionsVolProjectedExactly: ''
}

export const questionnaire1Labels: Record<keyof AnnualReviewQuestionnaireForm['questionnaire1'], string> = {
  covenantComplying: 'Is the company complying with bank covenants/account agreements?',
  covenantNonComplianceDesc: 'Describe any areas of non-compliance:',
  covenantTransparent: 'Is the company transparent about its operations?',
  financialsPeriodCollected: 'Period Collected:',
  financialsIsMaterialsRegardingIncome:
    'Were there any material concerns regarding changes in income, expenses or equity?',
  financialsMaterialsNotes: 'If yes, must fill out notes',
  financialsDepositsMatching: 'Is deposit activity in line with sales?',
  financialsCurrentConditionIsAcceptable: 'Is current financial condition of business acceptable?',
  financialsAcceptableNotes: 'If no, must fill out notes',
  licensingLocChanges: 'Any location changes in the last year?',
  licensingLocChangesMmcc: 'If yes, approved by MMCC?',
  licensingNameChanges: 'Any name changes in the last year?',
  licensingNameChangesMmcc: 'If yes, approved by MMCC?',
  licensingNamePrior: 'Prior Name:',

  ownershipBadNews: 'Have there been any negative news posts about the company or its owners?',
  ownershipBadNewsDesc: 'If yes, please provide application details:',
  ownershipHasMaterialChangesInManagementAtReviewPeriod:
    'Have there been any material changes to key personnel or executive management during the review period?',
  ownershipMaterialChangesInManagementNotes: 'If yes, provide details:',

  recommendationsChangeVisits: 'Recommended Site Visit Schedule:',
  recommendationsRecommended:
    'Based on the above information, do you recommend that the bank continue to offer this customer banking services?',
  recommendationsStatus: 'What status do you believe the customer has operated over the last year?',
  recommendationsComments: 'Comments:',
  siteVisitsFrequency: 'Frequency:',
  siteVisitsLastVisitDate: 'Date of last visit:',
  siteVisitsScoresAcceptable: 'Company`s audit scores acceptable?',
  siteVisitsShutdownRisk: 'Based on site visits, is company at risk of being shut down?',
  siteVisitsViolationRectified: 'If violation found, was it rectified?',
  siteVisitsHasStateAuditAtReviewPeriod: 'Did the company undergo a state regulatory audit during the review period?',
  siteVisitsStateAuditNotes: 'Notes:'
}

export const questionnaire3Labels: Record<keyof AnnualReviewQuestionnaireForm['questionnaire3'], string> = {
  covenantComplying: 'Is the company complying with bank covenants/account agreements?',
  covenantNonComplianceDesc: 'Describe any areas of non-compliance:',
  covenantTransparent: 'Is the company transparent about its operations?',

  financialsDepositsMatching: 'Is deposit activity in line with sales?',
  financialsPeriodCollected: 'Period Reviewed:',

  licensingLocChanges: 'Any location changes in the last year?',
  licensingLocChangesMmcc: 'If yes, approved by MMCC?',
  licensingNameChanges: 'Any name changes in the last year?',
  licensingNameChangesMmcc: 'If yes, approved by MMCC?',
  licensingNamePrior: 'Prior Name:',

  ownershipBadNews: 'Have there been any negative news posts about the company or its owners?',
  ownershipBadNewsCleared: 'Negative news alerts cleared by BSA/Compliance?',
  ownershipBadNewsClearedExplain: 'If no, explain status:',
  ownershipBadNewsDesc: 'If yes, please provide application details:',
  ownershipChanges: 'Has ownership of 10% or more of the company changed over the last year?',
  ownershipOFACTLOReviewed: 'Have OFAC/Adverse News/PEP Screening been reviewed for new owners with >=10% interest?',

  recommendationsRecommended:
    'Based on the above information, do you recommend that the bank continue to offer this customer banking services?',

  siteVisitsComplyingRegulations: 'Is company complying with state and local cannabis regulations verified by bank?',
  siteVisitsLastVisitDate: 'Date of last visit:',
  siteVisitsViolationRectified: 'If violation found, was it rectified?',

  transactionsExpectExactly: 'Expected volume changes:',
  transactionsExpectExplain: 'If unknown, explain:',
  transactionsMatchTrans: 'Transaction activity matches sales?',
  transactionsNotes: 'Transaction Notes:',
  transactionsTypes: 'Types of transactions performed:',
  transactionsVolProjected: 'Volumes as anticipated/projected?',
  transactionsVolProjectedExactly: '',

  businessPlansExpand: 'Are there any plans to expand into other states?',
  businessPlansExpandDesc: 'Comments: ',
  businessPlansNewAgreementsWithOther:
    'Are there any new agreements in place with other entities to sell, cultivate, or manufacture cannabis products?',
  businessPlansNewAgreementsWithOtherDesc: 'Comments: ',
  businessPlansSell: 'Are there any plans to sell or acquire new licenses in the next 12 months?',
  businessPlansSellDesc: 'Comments: '
}
