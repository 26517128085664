import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type NAICSCodeProps = {
  index: number
}

export const NAICSCode = ({ index }: NAICSCodeProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index}].naicsCode` as 'subjectsInfo[0].naicsCode'}
      children={(field) => <FF field={field} label="10a. NAICS Code" input={(p) => <TextInput {...p} />} />}
    />
  )
}
