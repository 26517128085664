import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const City = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].city` as 'institutionActivitiesInfo[0].city'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="62. City"
          field={field}
          input={(p) => <TextInput {...p} maxLength={50} data-qa="62. City" />}
        />
      )}
    />
  )
}
