import { RefObject, useEffect } from 'react'

import { DialogStack } from '../DialogStack'

export const useStack = (ref: RefObject<string | number>) => {
  const stack = DialogStack.getInstance()

  useEffect(() => {
    if (ref.current) {
      stack.push(ref.current)
    }
    return () => {
      stack.pop()
    }
  }, [stack, ref])
}
