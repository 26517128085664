/* eslint-disable max-len */
import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../context'

const CountryRenderer = ({
  field,
  index1,
  index2,
  noBranchInvolved
}: {
  field: FieldApi<SarEdit, 'institutionActivitiesInfo[0].institutionActivityBranchesInfo[0].country'>
  index1: number
  index2: number
  noBranchInvolved: boolean
}) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (typeof field.state.value !== 'undefined' && field.state.value !== 'US') {
      formApi.setFieldValue(
        `institutionActivitiesInfo[${index1 as 0}].institutionActivityBranchesInfo[${index2 as 0}].state`,
        ''
      )
      formApi.setFieldMeta(
        `institutionActivitiesInfo[${index1 as 0}].institutionActivityBranchesInfo[${index2 as 0}].state`,
        (stateMeta) => ({ ...stateMeta, errors: [], errorMap: {} })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.state.value, formApi])

  return (
    <FF
      required={!noBranchInvolved}
      label="74. Country"
      field={field}
      input={(p) => (
        <Select
          {...p}
          data={usAndTerritories}
          data-qa="74. Country"
          options-data-qa="74. Country option"
          disabled={noBranchInvolved}
        />
      )}
    />
  )
}

export const Country = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institutionActivitiesInfo[index1].noBranchInvolved)

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index1 as 0}].institutionActivityBranchesInfo[${index2 as 0}].country`}
      validators={{
        onChange: ({ value }) => (noBranchInvolved ? undefined : Validators.required.field(value))
      }}
      children={(field) => (
        <CountryRenderer field={field} index1={index1} index2={index2} noBranchInvolved={noBranchInvolved} />
      )}
    />
  )
}
