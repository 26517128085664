import { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { ManageLicensePage } from '../../components/ManageLicensePage'
import { useManage } from '../../hooks'

const routeApi = getRouteApi('/licenses/add')

const CreateLicense: FC = memo(() => {
  const { returnUrl, companyId } = routeApi.useSearch()

  const { license, deleteLicense, saveLicense, isSaving, cancelEdit, isFetching, companyName } = useManage(
    0,
    companyId,
    returnUrl
  )

  return (
    <BPLayout>
      {!license || isFetching ? (
        <Spinner centered size={SpinnerSize.M} />
      ) : (
        <ManageLicensePage
          license={license}
          companyName={companyName}
          onDelete={deleteLicense}
          onSave={saveLicense}
          onCancel={cancelEdit}
          isSaving={isSaving}
        />
      )}
    </BPLayout>
  )
})

export default CreateLicense
