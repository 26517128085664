import * as React from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { PageSection } from 'ui/components/Page'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

import { TextLine } from './TextLine'

export const Overview = React.memo(() => {
  const data = getRouteApi(AnnualReviewPaths.DETAILS).useLoaderData() as AnnualReviewDetails

  if (!data) return null

  const {
    overviewRelationshipName,
    overviewLastARDate,
    overviewRelatedCompanies,
    overviewDba,
    overviewLegalName,
    overviewInitialApproveDate
  } = data

  return (
    <PageSection title="Overview">
      <TextLine label="Relationship Name:" value={overviewRelationshipName || '---'} />
      <TextLine label="Licensed Entity Legal Name:" value={overviewLegalName || '---'} />
      <TextLine label="DBA (if applicable):" value={overviewDba || '---'} />
      <TextLine
        label="Related Companies:"
        value={overviewRelatedCompanies.length > 0 ? overviewRelatedCompanies.map((c) => c).join(', ') : '---'}
      />
      <TextLine label="Date of Initial Approval:" value={overviewInitialApproveDate} />
      <TextLine label="Date of Last Annual Review (if applicable):" value={overviewLastARDate} />
    </PageSection>
  )
})
