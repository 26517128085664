import { FC, memo, useRef } from 'react'

import { Button } from '../../Buttons'
import { IconName } from '../../Icon'
import { useBlockScroll, useEscPress, useStack } from '../hooks'
import { CANCEL_BUTTON, NEGATIVE_SUBMIT_BUTTON, POSITIVE_SUBMIT_BUTTON } from './constants'
import { DialogProps } from './props'

import * as SC from './styles'

export const Dialog: FC<DialogProps> = memo((props) => {
  const {
    opened,
    message,
    submitButtonText,
    buttons,
    isCallbackFinishPending,
    handleNegativeOrPositiveChoice,
    handleCancelChoice
  } = props

  const dialogRef = useRef(null)

  useStack(dialogRef)
  useEscPress(handleCancelChoice)
  useBlockScroll()

  return (
    <SC.Dialog ref={dialogRef} $opened={opened}>
      <SC.CloseButton name={IconName.CROSS_SMALL} onClick={handleCancelChoice} />
      <SC.Message>{message}</SC.Message>
      <SC.Buttons>
        {(buttons & NEGATIVE_SUBMIT_BUTTON) === NEGATIVE_SUBMIT_BUTTON && (
          <Button face={'negative'} loading={isCallbackFinishPending} onClick={handleNegativeOrPositiveChoice}>
            {submitButtonText ? submitButtonText : 'Delete'}
          </Button>
        )}
        {(buttons & POSITIVE_SUBMIT_BUTTON) === POSITIVE_SUBMIT_BUTTON && (
          <Button face={'positive'} loading={isCallbackFinishPending} onClick={handleNegativeOrPositiveChoice}>
            {submitButtonText ? submitButtonText : 'Submit'}
          </Button>
        )}
        {(buttons & CANCEL_BUTTON) === CANCEL_BUTTON && (
          <Button face={'neutral'} onClick={handleCancelChoice}>
            Cancel
          </Button>
        )}
      </SC.Buttons>
    </SC.Dialog>
  )
})
