import { Col, FormRow } from 'ui/themes/globalStyles'

import { BirthDate, FirstName, Gender, MiddleName, Suffix } from './fields'

export const EntityFields = ({ index }: { index: number }) => (
  <>
    <FormRow>
      <Col>
        <FirstName index={index} />
      </Col>
      <Col>
        <MiddleName index={index} />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <Suffix index={index} />
      </Col>
      <Col>
        <Gender index={index} />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <BirthDate index={index} />
      </Col>
    </FormRow>
  </>
)
