import { COMPANIES_PATH } from 'commons/constants/routes'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { MappedAffiliatedCompany } from './MappedAffiliatedCompany'

export const columns = (
  companyId: number,
  onEdit: (item: MappedAffiliatedCompany) => void,
  onDelete: (item: MappedAffiliatedCompany) => void
): TableColumn<MappedAffiliatedCompany>[] => [
  {
    align: 'center',
    title: 'Name',
    render: ({ parenCompanyID, childCompanyID, name }) => (
      <Link to={`${COMPANIES_PATH}/${childCompanyID === companyId ? parenCompanyID : childCompanyID}/`}>{name}</Link>
    )
  },
  {
    align: 'center',
    title: 'Notes',
    key: 'notes'
  },
  {
    title: 'Actions',
    align: 'center',
    width: '50px',
    render: (item) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onEdit(item)
          },
          {
            key: 2,
            title: 'Delete',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onDelete(item)
          }
        ]}
        dataQaAttribute={`Affiliated companies-menu-dots-button-${item.id}`}
      />
    )
  }
]
