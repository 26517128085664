import { useCallback } from 'react'

import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'
import { useSubmitApplicationApprove } from '~bank-onboarding/hooks/useSubmitApplicationApprove'

import { ApproveApplicationProps } from './ApproveApplicationProps'

export const ApproveApplication = ({ application }: ApproveApplicationProps) => {
  const router = useRouter()

  const { routine: submitApplicationApprove } = useSubmitApplicationApprove({
    onSuccess: async () => {
      Toast.success('Application has been approved')
      router.invalidate()
    },
    onError: async (error) => handlerServerError(error)
  })

  const onApproveApplication = useCallback(async () => {
    await submitApplicationApprove({ id: application.applicationID })
  }, [application.applicationID, submitApplicationApprove])

  const { Confirmation: ApproveApplicationConfirmation, open: openApproveApplicationConfirmation } = useConfirmation({
    message: <span>Are you sure want to approve Application?</span>,
    onConfirm: () => onApproveApplication(),
    confirmationButtonText: 'Approve'
  })

  return (
    <>
      <ApproveApplicationConfirmation />
      <Button face="positive" onClick={openApproveApplicationConfirmation}>
        Approve Application
      </Button>
    </>
  )
}
