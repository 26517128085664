import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { DatePicker, FilterPanel, Validators } from 'ui/components'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { Props } from './Props'

import * as SC from './styles'

export const Filter: FC<Props> = memo((props) => {
  const { filterForm, onClear, onToggle, isExpanded } = props
  const { Field } = filterForm

  return (
    <FilterPanel expanded={isExpanded} onToggle={onToggle} isDirty={filterForm.isDirty} onReset={onClear}>
      <SC.FormWrapper>
        <TableFilterRow>
          <Form config={filterForm.config}>
            <SC.Col>
              <Field
                required
                validator={Validators.required.field}
                name="date_start"
                label="From"
                input={DatePicker}
                inputProps={{
                  isClearable: false
                }}
              />
            </SC.Col>
            <SC.Col>
              <Field
                required
                validator={Validators.required.field}
                name="date_end"
                label="To"
                input={DatePicker}
                inputProps={{
                  isClearable: false
                }}
              />
            </SC.Col>
          </Form>
        </TableFilterRow>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
