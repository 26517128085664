import { useQueryClient } from 'react-query'
import { useMutationManager } from 'react-query-state-manager'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'
import { SAR } from '~bank-bsa-reporting/service'

export const useSARCancel = (id: number) => {
  const queryClient = useQueryClient()

  return useMutationManager(SAR.cancelSAR, {
    onSuccess: async () => {
      Toast.successSmall('The SAR was canceled')
      await queryClient.invalidateQueries(['tables', 'SARs List'])
      await queryClient.invalidateQueries([
        'sar-details',
        {
          historyPage: 1,
          historySize: 10,
          id
        }
      ])
    },
    onError: (error) => {
      handlerServerError(error)
    }
  })
}
