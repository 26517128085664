import { getRouteApi } from '@tanstack/react-router'
import { ButtonGroup, CheckBox, FF, IconName, Link } from 'ui/components'
import { Button, IconButton } from 'ui/components/Buttons'
import { Footer as F } from 'ui/temp/Footer'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { useDeleteAnnualReviewConfirmation } from '~bank-annual-review/hooks'

import { useAnnualReviewFormContext } from '../../context/ManageAnnualReviewContext'

const routeApi = getRouteApi(AnnualReviewPaths.EDIT)

export const Footer = () => {
  const { formApi } = useAnnualReviewFormContext()

  const navigate = routeApi.useNavigate()
  const { companyId, id } = routeApi.useParams()

  const { openDeleteAnnualReviewConfirmation, DeleteConfirmation } = useDeleteAnnualReviewConfirmation({
    onSuccess: () =>
      navigate({
        to: '/relationships/$companyId',
        params: { companyId }
      })
  })

  return (
    <>
      <DeleteConfirmation />
      <F>
        <formApi.Subscribe
          children={() => (
            <>
              <formApi.Field
                name="isCompleted"
                children={(isCompletedField) => (
                  <FF
                    field={isCompletedField}
                    input={(inputProps) => (
                      <CheckBox checkBoxLabel="Is Review Completed?" data-qa="Is Review Completed?" {...inputProps} />
                    )}
                  />
                )}
              />
              <IconButton
                face="negative"
                icon={IconName.DELETE}
                onClick={() => openDeleteAnnualReviewConfirmation(Number(id))}
              >
                Delete
              </IconButton>
              <ButtonGroup margin="small">
                <Link to={AnnualReviewPaths.DETAILS} params={{ companyId, id }}>
                  <Button face="neutral">Cancel</Button>
                </Link>
                <Button face="positive" onClick={formApi.handleSubmit}>
                  Submit
                </Button>
              </ButtonGroup>
            </>
          )}
        />
      </F>
    </>
  )
}
