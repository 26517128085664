import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { YesNoRadioGroup } from 'commons/types/enums'
import { InfoSet } from 'ui/components/Info'
import { TextareaInput } from 'ui/components/InputV2'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { QuestionnaireFormProps } from './QuestionnaireFormProps'
import { useQuestionnaireForm } from './useQuestionnaireForm'

export const QuestionnaireForm: FC<QuestionnaireFormProps> = memo(
  ({
    value,
    companyIndex,
    state: {
      isContactsForeignConnectionsEnable,
      statesLicensedLabel,
      isWholesaleProductsEnable,
      isWholesaleProductsDescEnable,
      isTypeOfProductsEnable,
      isTypeOfAncillaryProductsEnable,
      isWhoSuppliesYouEnable,
      isWhereIsYourSupplierEnable,
      isWhoDoYouSupplyGrowerEnable,
      isGrowerCustomersLocationEnable,
      isHowManyLocationsEnable,
      isOnlineOrdersForPickupEnable,
      isMinorSellingEnsureEnable,
      isPaymentMethodsEnable,
      isTransferToOtherStatesEnable,
      isTransferToOtherStatesDescEnable,
      isAreYouAffiliatedEnable,
      isAreYouAffiliatedDescEnable
    },
    onChange
  }) => {
    const { Field, config } = useQuestionnaireForm({ companyIndex, formData: value, onChange })

    return (
      <Form config={config}>
        <h3>Questionnaire</h3>
        <InfoSet>
          {isContactsForeignConnectionsEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.CONTACTS_FOREIGN_CONNECTIONS}
                  label="Have any of the contacts included above ever held a position as a senior official in a foreign
            government entity or enterprise or have had a relationship with a current or former senior foreign
            political figure?"
                  input={RadioGroup}
                  inputProps={{
                    name: `${OnboardingFormFieldNames.CONTACTS_FOREIGN_CONNECTIONS}${companyIndex}`,
                    'data-qa': 'Contacts foreign connections',
                    data: [
                      { value: YesNoRadioGroup.YES, label: 'Yes' },
                      { value: YesNoRadioGroup.NO, label: 'No' }
                    ]
                  }}
                />
              </Col>
            </FormRow>
          )}
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.STATES_LICENSED}
                label={statesLicensedLabel}
                input={TextareaInput}
                inputProps={{
                  'data-qa': 'States Licensed',
                  rows: 3
                }}
              />
            </Col2x>
          </FormRow>
          {isWholesaleProductsEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.A_WHOLESALE_PRODUCTS}
                  label="Do you wholesale any of your products?"
                  input={RadioGroup}
                  inputProps={{
                    name: `${OnboardingFormFieldNames.A_WHOLESALE_PRODUCTS}${companyIndex}`,
                    'data-qa': 'Do you wholesale any of your products?',
                    data: [
                      { value: YesNoRadioGroup.YES, label: 'Yes' },
                      { value: YesNoRadioGroup.NO, label: 'No' }
                    ]
                  }}
                />
              </Col>
            </FormRow>
          )}
          {isWholesaleProductsDescEnable && config.value.aWholesaleProducts === YesNoRadioGroup.YES && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.A_WHOLESALE_PRODUCTS_DESC}
                  label="What products and how often?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'What products and how often?',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isTypeOfProductsEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.B_TYPE_OF_PRODUCTS}
                  // eslint-disable-next-line max-len
                  label="What type of products will you be selling (medical, recreational/adult use, edibles, oils, topical lotions, CBD, etc.)?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Type of products',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isTypeOfAncillaryProductsEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.C_TYPE_OF_ANCILLARY_PRODUCTS}
                  // eslint-disable-next-line max-len
                  label="What type of ancillary products will you be selling (pipes, papers, store/brand apparel, etc.)?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Type of ancillary products',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isWhoSuppliesYouEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.WHO_SUPPLIES_YOU}
                  label="Who supplies you with your product?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Who supplies you',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isWhereIsYourSupplierEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.WHERE_IS_YOUR_SUPPLIER}
                  label="Where is your supplier located?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Where is your supplier',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isWhoDoYouSupplyGrowerEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.WHO_DO_YOUR_SUPPLY_GROWER}
                  label="If grower, who do you supply your product to? Please enter “N/A” if not applicable"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Who do you supply grower',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isGrowerCustomersLocationEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.GROWER_CUSTOMERS_LOCATION}
                  // eslint-disable-next-line max-len
                  label="If grower/processor, which states are your customers located? Please enter “N/A” if not applicable"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Grower customers location',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isHowManyLocationsEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.D_HOW_MANY_LOCATIONS}
                  label="How many locations does this company currently have in total?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'How many locations',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isOnlineOrdersForPickupEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.E_ONLINE_ORDERS_FOR_PICKUP}
                  label="Can orders be placed online for pickup or delivery?"
                  input={RadioGroup}
                  inputProps={{
                    name: `${OnboardingFormFieldNames.E_ONLINE_ORDERS_FOR_PICKUP}${companyIndex}`,
                    'data-qa': 'Can orders be placed online for pickup or delivery?',
                    data: [
                      { value: YesNoRadioGroup.YES, label: 'Yes' },
                      { value: YesNoRadioGroup.NO, label: 'No' }
                    ]
                  }}
                />
              </Col>
            </FormRow>
          )}
          {isMinorSellingEnsureEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.F_MINOR_SELLING_ENSURE}
                  label="How do you ensure you are not selling to a minor?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'How do you ensure you are not selling to a minor?',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isPaymentMethodsEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.G_PAYMENT_METHODS}
                  label="What payment methods will/does the business accept?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'What payment methods will/does the business accept?',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isTransferToOtherStatesEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.H_TRANSFER_TO_OTHER_STATES}
                  label="Do you mail or transfer products to other states or internationally?"
                  input={RadioGroup}
                  inputProps={{
                    name: `${OnboardingFormFieldNames.H_TRANSFER_TO_OTHER_STATES}${companyIndex}`,
                    'data-qa': 'Do you mail or transfer products to other states or internationally?',
                    data: [
                      { value: YesNoRadioGroup.YES, label: 'Yes' },
                      { value: YesNoRadioGroup.NO, label: 'No' }
                    ]
                  }}
                />
              </Col>
            </FormRow>
          )}
          {isTransferToOtherStatesDescEnable && config.value.hTransferToOtherStates === YesNoRadioGroup.YES && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.H_TRANSFER_TO_OTHER_STATES_DESC}
                  label="What are the requirements?"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'What are the requirements?',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isAreYouAffiliatedEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.I_ARE_YOU_AFFILIATED}
                  label="Are you affiliated with another dispensary, cultivator, or processor?"
                  input={RadioGroup}
                  inputProps={{
                    name: OnboardingFormFieldNames.I_ARE_YOU_AFFILIATED,
                    'data-qa': 'Are you affiliated with another dispensary, cultivator, or processor?',
                    data: [
                      { value: YesNoRadioGroup.YES, label: 'Yes' },
                      { value: YesNoRadioGroup.NO, label: 'No' }
                    ]
                  }}
                />
              </Col>
            </FormRow>
          )}
          {isAreYouAffiliatedDescEnable && config.value.iAreYouAffiliated === YesNoRadioGroup.YES && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.I_ARE_YOU_AFFILIATED_DESC}
                  label="Please list business name(s) and location(s)"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'What are the requirements?',
                    rows: 3
                  }}
                />
              </Col2x>
            </FormRow>
          )}
        </InfoSet>
      </Form>
    )
  }
)
