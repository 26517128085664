import { PageSection, PageSectionFace } from 'ui/components/Page'
import { LicensesTable } from '~bank-annual-review/components/LicensesTable'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'

// eslint-disable-next-line max-len
import { LicensingQuestionnaire } from './components/LicensingQuestionnaire'

export const Licensing = (): JSX.Element => (
  <PageSection title="Licensing" face={PageSectionFace.SECONDARY}>
    <LicensesTable routeApi={AnnualReviewPaths.EDIT} />
    <LicensingQuestionnaire />
  </PageSection>
)
