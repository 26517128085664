import { getDocumentFileList } from 'commons/service/client/documentFile'
import { DocumentFile } from 'commons/types/DTO/client/documentFile'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export type UseGetDocumentPeriodFileListForCompanyOpts = {
  companyIDList: number[]
  disabled?: boolean
}

export const useGetDocumentPeriodFileListForCompany: ReadHook<
  DocumentFile[],
  UseGetDocumentPeriodFileListForCompanyOpts
> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    Keys.DocumentFile.ListByCompanies(opts?.companyIDList),
    () =>
      getDocumentFileList({
        _options: {
          filters: [{ field: 'companies.id', type: 'in', value: opts?.companyIDList }]
        }
      }),
    {
      enabled: !opts?.disabled,
      keepPreviousData: true
    }
  )

  return {
    ...rest,
    data: data?.records
  }
}
