import { FC } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { DatePicker } from 'ui/components/DatePicker'
import { Validators } from 'ui/components/Form'
import { TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'

type SignedCompletionProps = {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, Questionnaire>
  ) => JSX.Element
}

export const SignedCompletion: FC<SignedCompletionProps> = ({ Field }) => (
  <>
    <SecondText>
      <i>Signed Completion</i>
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="signedCompletionName"
          label="Name"
          input={TextInput}
          inputProps={{}}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="signedCompletionDate"
          label="Date"
          input={DatePicker}
          inputProps={{}}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
  </>
)
