import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const RelationshipStatus = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const isDirector = formApi.useStore((s) => s.values.subjectsInfo[index1]?.relationshipsInfo[index2]?.director)
  const isEmployee = formApi.useStore((s) => s.values.subjectsInfo[index1]?.relationshipsInfo[index2]?.employee)
  const isOfficer = formApi.useStore((s) => s.values.subjectsInfo[index1]?.relationshipsInfo[index2]?.officer)
  const isOwner = formApi.useStore((s) => s.values.subjectsInfo[index1]?.relationshipsInfo[index2]?.owner)
  const isStatusEnabled = isDirector || isEmployee || isOfficer || isOwner

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].relationshipStatus`}
      children={(field) => (
        <FF
          field={field}
          label="25. Status of relationship"
          input={(p) => (
            <Select
              {...p}
              data={[
                { label: 'Relationship continues', value: 'Relationship continues' },
                { label: 'Terminated', value: 'Terminated' },
                { label: 'Suspended/barred', value: 'Suspended/barred' },
                { label: 'Resigned', value: 'Resigned' }
              ]}
              data-qa="84. State"
              options-data-qa="84. State option"
              disabled={!isStatusEnabled}
            />
          )}
        />
      )}
    />
  )
}
