import { useState } from 'react'

import { Connection, ConnectionLicenseMeta } from 'commons/types/DTO/bank/connections/connection'
import { TableDataQa } from 'commons/types/enums'
import { Table } from 'ui/components/Table'

import { columns } from './columns'
import { Header, KeyHistoryModal } from './components'

import { TableWrapper, Wrapper } from './styles'

type ConnectionKeyItemProps = {
  connection: Connection
}

export const ConnectionKeyItem = (props: ConnectionKeyItemProps) => {
  const { connection } = props

  const [showingHistoryModalKey, setShowingHistoryModalKey] = useState<string | undefined>(undefined)

  const mappedLicensesList = connection.licensesMeta.map((el) => ({
    item: { ...el, type: connection.type }
  }))

  return (
    <Wrapper>
      {!!showingHistoryModalKey && (
        <KeyHistoryModal
          connectionKey={connection.key}
          history={connection.history}
          onClose={() => setShowingHistoryModalKey(undefined)}
        />
      )}
      <Header
        connectionKey={connection.key}
        addedAt={connection.addedAt}
        status={connection.status}
        setShowingHistoryModalKey={setShowingHistoryModalKey}
      />
      <TableWrapper>
        <Table<ConnectionLicenseMeta>
          dataQa={TableDataQa.BP_CONNECTIONS_LICENSES}
          columns={columns}
          dataSource={mappedLicensesList}
        />
      </TableWrapper>
    </Wrapper>
  )
}
