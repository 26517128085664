import { FC, ReactElement, ReactNode, memo, useCallback, useContext, useState } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { BeforeFormChangeHandler, Form, useFormData } from 'brief-form'
import { LoginButton } from 'ui/components/Buttons'
import { IconName, IconSize } from 'ui/components/Icon'
import { FormRow } from 'ui/themes/globalStyles'
import { AuthContext } from '~auth/AuthContext'
import { AuthForm, AuthInput } from '~auth/components'

import { getInfoMessage } from './getInfoMessage'

import * as SC from './styles'

interface Props {
  onSubmit: (value: SignInFormShape) => Promise<any>
  logoElement: ReactElement
  footer: ReactNode
}

export type SignInFormShape = {
  login: string
  password: string
}

export const SignInForm: FC<Props> = memo(({ onSubmit, logoElement, footer }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const hash = useRouterState({ select: (s) => s.location.hash })

  const { themeName } = useContext(AuthContext)

  const onBeforeChange: BeforeFormChangeHandler<SignInFormShape> = useCallback(({ value, errors }) => {
    setError('')
    return {
      value,
      errors
    }
  }, [])

  const { config, validate, Field } = useFormData<SignInFormShape>({
    skipFieldsValidationOnUserInput: true,
    onBeforeChange,
    initialValue: {
      login: '',
      password: ''
    }
  })

  const { value } = config

  const submitWrapper = useCallback(() => {
    if (validate({ updateFields: true }).valid) {
      setLoading(true)
      onSubmit(value).catch((error) => {
        setError(error.message)
        setLoading(false)
      })
    }
  }, [value, validate, onSubmit, setError, setLoading])

  return (
    <AuthForm error={error} info={getInfoMessage(hash, themeName)} logo={logoElement} footer={footer}>
      <Form config={config}>
        <FormRow>
          <SC.LoginCol>
            <Field
              name="login"
              input={AuthInput}
              inputProps={{
                autoFocus: true,
                skipLocalState: true,
                placeholder: 'Email',
                icon: IconName.ENVELOPE,
                iconSize: IconSize.M,
                iconColor: '#fff',
                dataQa: 'Email',
                type: 'email'
              }}
            />
          </SC.LoginCol>
        </FormRow>
        <FormRow>
          <SC.LoginCol>
            <Field
              name="password"
              input={AuthInput}
              inputProps={{
                skipLocalState: true,
                placeholder: 'Password',
                icon: IconName.LOCK,
                iconSize: IconSize.M,
                iconColor: '#fff',
                type: 'password',
                dataQa: 'Password'
              }}
            />
          </SC.LoginCol>
        </FormRow>
      </Form>
      <SC.Footer>
        <LoginButton data-qa="Log in" loading={loading} onClick={submitWrapper}>
          LOG IN
        </LoginButton>
        <SC.ForgotLink to="/auth/forgot-password">Forgot Password?</SC.ForgotLink>
      </SC.Footer>
    </AuthForm>
  )
})
