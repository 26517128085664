import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Value = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`suspiciousActivityInfo.marketsTraded[${index}]` as 'suspiciousActivityInfo.marketsTraded[0]'}
      children={(field) => <FF label="Value" field={field} input={(p) => <TextInput {...p} />} />}
    />
  )
}
