import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../../../context'
import { ProductInstrumentDescription } from './ProductInstrumentDescription'

export const ProductInstrumentDescriptionList = () => {
  const { formApi } = useSARFormContext()

  return (
    <>
      <FormRow>
        <Col2x>
          <h5>48. Product/Instrument description (if needed)</h5>
        </Col2x>
      </FormRow>
      <formApi.Field mode="array" name="suspiciousActivityInfo.productInstrumentDesc">
        {(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {field.state.value.map((_, index) => (
                <ProductInstrumentDescription key={index} index={index} field={field} />
              ))}
              <FormRow>
                <IconButton face="positive" icon={IconName.ADD_GREEN} onClick={() => field.pushValue('')} />
              </FormRow>
            </>
          )
        }}
      </formApi.Field>
    </>
  )
}
