import { FilterValues } from 'commons/types/DTO/commons/webpagesOnboardinForm'

type D = {
  value: string
  label: string
}

export const mapFilterObjectToValueLabelArray = (obj: FilterValues[]): D[] => {
  const arr: D[] = []

  obj.forEach((el) => arr.push({ value: el?.value, label: el?.label }))

  return arr
}
