import { useCallback, useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useUploadDocumentPeriodFile } from '~bank-documents/service/uploadDocumentFile'

export const useUploadFile = ({ id, withDialog }) => {
  const router = useRouter()

  const [selectedFiles, setSelectedFiles] = useState<Array<File> | null>(null)

  const closeFileUploadDialog = () => setSelectedFiles(null)

  const { routine: uploadDocument } = useUploadDocumentPeriodFile({
    onSuccess: async () => {
      Toast.successSmall('The File was success uploaded.')
      closeFileUploadDialog()
      router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  const createDocumentFiles = useCallback(
    async ({ files, deliveredAt }: { files: File[]; deliveredAt?: string }) => {
      try {
        const createdFilesPromises: Promise<unknown>[] = []
        files.forEach(async (file) => {
          const createFilePromise = uploadDocument({ file: file, deliveredAt, periodId: id })
          createdFilesPromises.push(createFilePromise)
        })
        await Promise.all(createdFilesPromises)
      } catch (error) {
        Toast.errorSmall('Something went wrong while uploading.')
      }
    },
    [id, uploadDocument]
  )

  const onUpload = (files: File[]) => {
    if (withDialog) {
      setSelectedFiles(files)
    } else createDocumentFiles({ files })
  }

  const onSubmitFileDialog = useCallback(
    (deliveredAt: string) => (selectedFiles ? createDocumentFiles({ files: selectedFiles, deliveredAt }) : null),
    [selectedFiles, createDocumentFiles]
  )

  return {
    onUpload,
    showUploadFilesDialog: withDialog && selectedFiles && selectedFiles.length >= 1,
    closeFileUploadDialog,
    onSubmitFileDialog
  }
}
