import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { Toast, handleFormSubmissionError } from 'ui/components'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'
import { ManageInvoicePage } from '~bank-invoices/components/ManageInvoicePage'
import { InvoiceSubmittalPaths } from '~bank-invoices/constants'
import { useDeleteInvoiceSubmittalConfirmation } from '~bank-invoices/hooks/useDeleteInvoiceSubmittalConfirmation'
import { useSaveInvoiceSubmittal } from '~bank-invoices/service/saveInvoiceSubmittal'
import { ManageInvoiceSubmittalFormType } from '~bank-invoices/types/Manage'

const routeApi = getRouteApi(InvoiceSubmittalPaths.EDIT)

const EditInvoice: FC = memo(() => {
  const navigate = routeApi.useNavigate()

  const { returnUrl } = routeApi.useSearch()
  const { id } = routeApi.useParams()

  const loaderData = routeApi.useLoaderData()

  const { form, ...appearanceInfo } = loaderData

  const formApi = useForm({
    defaultValues: new ManageInvoiceSubmittalFormType(false, form),
    onSubmit: async ({ value }) => await saveInvoiceSubmittal({ ...value, licenseId: appearanceInfo.licenseId })
  })

  const { routine: saveInvoiceSubmittal } = useSaveInvoiceSubmittal({
    onSuccess: async (request) => {
      await navigate({ to: InvoiceSubmittalPaths.DETAILS, params: { id: request?.id } })
      Toast.successSmall('Invoice updated')
    },
    onError: (error) => {
      handleFormSubmissionError<ManageInvoiceSubmittalFormType>(error, formApi)
    }
  })

  const { DeleteInvoiceSubmittalConfirmation, onShowInvoiceSubmittalConfirmation } =
    useDeleteInvoiceSubmittalConfirmation({
      onSuccess: () => navigate({ to: '/licenses/$licenseId', params: { licenseId: appearanceInfo?.licenseId } })
    })

  const onCancel = () => {
    if (returnUrl) {
      navigate({ to: returnUrl })
    } else {
      navigate({ to: InvoiceSubmittalPaths.DETAILS, params: { id } })
    }
  }

  if (!loaderData) return <Spinner centered size={SpinnerSize.M} />

  return (
    <>
      <DeleteInvoiceSubmittalConfirmation />
      <ManageInvoicePage
        formApi={formApi}
        appearanceInfo={appearanceInfo}
        pageTitle={`IS-${id}`}
        panelTitle="Edit Invoice Submittal Detail"
        onDelete={() => onShowInvoiceSubmittalConfirmation({ id: Number(id) })}
        onCancel={() => onCancel()}
      />
    </>
  )
})

export default EditInvoice
