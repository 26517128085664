import { OnboardingDocumentSetupFormRequest } from 'commons/types/DTO/bank/applications/onboardingDocuments'

import { MappedCompanyFormType } from '../../../types/MappedCompanyFormType'
import { SetupDocumentFormType } from '../../../types/SetupDocumentFormType'

export const mapDataRequest = (
  formValue: { companiesData: SetupDocumentFormType[] },
  mappedCompaniesPageData: MappedCompanyFormType[]
): OnboardingDocumentSetupFormRequest => ({
  companiesData: formValue.companiesData.map((formCompany, companyIndex) => {
    const company = mappedCompaniesPageData[companyIndex]
    return {
      ...(company.companyId && { companyId: company.companyId }),
      ...(company.companyUid && { companyUid: company.companyUid }),
      companyName: company.companyName,
      relationshipTemplates: formCompany.documents
        .filter(({ isSelected }) => isSelected)
        .map((formDocument) => ({
          signers: company.signers,
          id: formDocument.id,
          name: company?.companyDocuments[formDocument.id].name,
          fields: formDocument.fields.map(({ externalKey, value }) => ({ externalKey, value }))
        })),
      bankAccounts: formCompany.bankAccounts?.map(({ bankAccountNumber, bankAccountTemplates }) => ({
        number: bankAccountNumber,
        bankAccountTemplates: bankAccountTemplates
          .filter(({ isSelected }) => isSelected)
          .map(({ id, name, fields }) => ({
            signers: company.signers,
            id,
            name,
            fields: fields.map(({ externalKey, value }) => ({ externalKey, value }))
          }))
      }))
    }
  })
})
