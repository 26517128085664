import { Request } from 'commons/utils/request'
import { ReadHook, useStateManager } from 'state-manager'

import { ContactMetadata, ContactMetadataResponse } from '../types/contact'

export const emptyMetadata: ContactMetadata = {
  companyId: 0,
  contactId: 0,
  debtAmount: 0,
  debtMaturityDate: '',
  debtMonthlyPayment: 0,
  debtPaymentFrequency: '',
  isAccountSigner: false,
  isDebtholder: false,
  isDocuments: false,
  isFinancials: false,
  isOwner: false,
  ownershipPercent: '0',
  ownershipAmount: 0,
  title: '',
  permissions: []
}

const request = (opts?: {
  companyId: number
  contactId: number
  isContactCreateFromRelationships: boolean
}): Promise<ContactMetadataResponse> =>
  opts?.contactId && !opts?.isContactCreateFromRelationships
    ? Request.post(Request.urls.bank.contactCompanyConnectionEditMode, opts)
    : Promise.resolve({
        companyName: '',
        contactName: '',
        form: {
          companyId: opts?.companyId ?? null,
          contactId: opts?.contactId ?? null,
          debtAmount: 0,
          debtMaturityDate: '',
          debtMonthlyPayment: 0,
          debtPaymentFrequency: '',
          isAccountSigner: false,
          isDebtholder: false,
          isDocuments: false,
          isFinancials: false,
          isOwner: false,
          ownershipPercent: '0',
          ownershipAmount: 0,
          title: '',
          permissions: []
        }
      })

export const useContactMetadataItem: ReadHook<
  ContactMetadataResponse,
  { companyId: number; contactId: number; isContactCreateFromRelationships: boolean }
> = (opts) =>
  useStateManager().use(['company-connection-edit-mode', opts], () => request(opts), {
    keepPreviousData: false
  })
