import { FC } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

import { FormFooterProps } from './FormFooterProps'

export const FormFooter: FC<FormFooterProps> = ({ onClose, onSetupKey, isPending }) => (
  <ButtonGroup margin="small" style={{ padding: '18px 32px' }}>
    <Button loading={isPending} disabled={isPending} face="positive" onClick={onSetupKey}>
      Connect
    </Button>
    <Button disabled={isPending} onClick={onClose}>
      Cancel
    </Button>
  </ButtonGroup>
)
