import { memo } from 'react'

import { Icon, IconFace, IconName, IconSize } from 'ui/components/Icon'

export const CheckMark = memo((props: { active: boolean }) => (
  <Icon
    face={props.active ? IconFace.ACTIVE : IconFace.DEFAULT}
    size={IconSize.S}
    name={props.active ? IconName.CHECK : IconName.CLEAR}
  />
))
