import { notFound } from '@tanstack/react-router'
import { getOnboardingWebPage } from 'commons/service/client/application'
import { ApiError } from 'commons/types'
import { OnboardingWebPageResponse } from 'commons/types/DTO/commons'

import { OnboardingFormLoaderType } from '../../types'

export const onboardingFormLoader: OnboardingFormLoaderType = async ({ portalName, params }) => {
  const { applicationId } = params

  if (typeof applicationId !== 'number' || applicationId <= 0) {
    throw notFound()
  }

  let applicationData: OnboardingWebPageResponse

  try {
    applicationData = await getOnboardingWebPage(applicationId, portalName)
  } catch (e: unknown) {
    if (e instanceof ApiError && e.httpCode === 400) {
      throw notFound()
    }
    throw e
  }

  return { applicationData, applicationId }
}
