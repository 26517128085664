import { FC, memo, useMemo } from 'react'

import { getRouteApi, useRouterState } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Table } from 'ui/components/Table'
import {
  AdverseMediaListRequest,
  AdverseMediaListResponse,
  AdverseMediaListResponseItem
} from '~bank-adverse-media/types'

import { columns } from './columns'

const routeApi = getRouteApi('/adverse-media/')

export const AdverseMediaListTable: FC = memo(() => {
  const routerState = useRouterState()
  const navigate = routeApi.useNavigate()

  const data = routeApi.useLoaderData() as AdverseMediaListResponse
  const searchParams = routeApi.useSearch() as AdverseMediaListRequest

  const dataSource: DataSourceItem<AdverseMediaListResponseItem>[] = useMemo(
    () => data.items.map((item) => ({ item, rowProps: { errorHighlight: item.riskLevel === 'Unknown' } })),
    [data]
  )

  const isLoading = useMemo(() => routerState.status === 'pending', [routerState.status])

  return (
    <Table<AdverseMediaListResponseItem>
      dataQa={TableDataQa.ADVERSE_MEDIA_LIST}
      columns={columns}
      dataSource={dataSource}
      loading={isLoading}
      pagination={{
        page: searchParams.page,
        pageSize: searchParams.size,
        total: data.total,
        onChange: (page: number, pageSize: number) => {
          navigate({ to: '.', search: (prev) => ({ ...prev, page, size: pageSize }), resetScroll: false })
        }
      }}
    />
  )
})
