import { FC, memo, useMemo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Dialog, DialogFace, Table } from 'ui/components'

import { UserRelationshipsApplicationsDialogProps } from './UserRelationshipsApplicationsDialogProps'
import { columns } from './columns'

type UserRelationshipsApplications = { id: number; name: string }

export const UserRelationshipsApplicationsDialog: FC<UserRelationshipsApplicationsDialogProps> = memo(
  ({ onClose, user }) => {
    const { relationships, name } = user

    const dataSource: DataSourceItem<UserRelationshipsApplications>[] = useMemo(
      () => relationships.map((item) => ({ item })),
      [relationships]
    )

    return (
      <Dialog
        id="user-relationships-applications-dialog"
        title={name}
        subTitle="Relationships"
        onClose={onClose}
        face={DialogFace.PRIMARY}
      >
        <Table<UserRelationshipsApplications>
          dataQa={TableDataQa.BP_CLIENT_USERS_RELATIONSHIPS_AND_APPLICATIONS}
          columns={columns}
          dataSource={dataSource}
        />
      </Dialog>
    )
  }
)
