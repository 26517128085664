import { FC } from 'react'

import { Transactions, Warnings } from 'bank-bsa-reporting/src/routes/CTR/components'
import { Ctrs } from 'commons/types/DTO/bank/alerts/ctrs'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Table } from 'ui/components/Table'

import { CTRsTableProps } from './CTRsTableProps'
import { columns } from './columns'
import { useComponentsEssence } from './hooks'

export const CTRsTable: FC<CTRsTableProps> = ({ CTRs, CTRsTotalCount, onAlertFilterChange }) => {
  const {
    tableState,
    modalItem,
    onShowWarnings,
    warnings,
    onClose,
    loading,
    changePagination,
    onSetWarnings,
    onSetModalItem
  } = useComponentsEssence(onAlertFilterChange)

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="CTRs with FinCEN warnings" anchor="ctr">
      <Warnings
        warnings={warnings}
        onClose={() =>
          onSetWarnings({
            warningsType: null,
            warnings: null
          })
        }
      />
      <Transactions
        onClose={onClose}
        transactions={modalItem.transactions}
        ctrId={modalItem.ctrId}
        ctrName={modalItem.ctrName}
      />
      <Layer rounded shadowed stretch>
        <Table<Ctrs>
          dataQa={TableDataQa.BP_ALERTS_CTRS}
          columns={columns({
            onShowTransactions: onSetModalItem,
            onShowWarnings
          })}
          dataSource={CTRs.map((el) => ({
            item: el
          }))}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: CTRsTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
}
