import { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { formatCurrencyCent } from 'commons/utils/currency'
import { Button } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Hr } from 'ui/components/Hr'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col } from 'ui/themes/globalStyles'
import { InvoiceSubmittalPaths } from '~bank-invoices/constants'

const routeApi = getRouteApi(InvoiceSubmittalPaths.DETAILS)

export const InvoiceInformation: FC = memo(() => {
  const invoice = routeApi.useLoaderData()

  const { id, amount, createdAt, notes, manifestNumber, licenseId, licenseName, date, lastModifiedAt } = invoice

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      actions={
        <Link to="/invoices/$id/edit" params={{ id }}>
          <Button face="positive">Edit</Button>
        </Link>
      }
      title="Invoice Submittal Details"
    >
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="General">
        <Col>
          <Info dataQA={`IS-${id}`} label="Invoice Name">
            IS-{id}
          </Info>
          <Info label="Amount">{formatCurrencyCent(amount)}</Info>
          <Info label="Created At">
            <DateTime withTime date={createdAt} />
          </Info>
          <Info label="Manifest Number (If Applicable)">{manifestNumber || '---'}</Info>
          <Info label="Notes">{notes || '---'}</Info>
        </Col>
        <Col>
          <Info label="License">
            {licenseId ? (
              <Link to="/licenses/$licenseId" params={{ licenseId }}>
                {licenseName}
              </Link>
            ) : (
              <span>---</span>
            )}
          </Info>
          <Info label="Date">
            <DateTime date={date} />
          </Info>
          <Info label="Last Modified At">
            <DateTime withTime date={lastModifiedAt} />
          </Info>
        </Col>
      </InfoSet>
    </PageSection>
  )
})
