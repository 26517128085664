import { FC } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { Hr, Layer, LeaveConfirmation, Page, PageFace, Panel, Toast, handleFormSubmissionError } from 'ui/components'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { useUpdateAnnualReview } from '~bank-annual-review/service/AnnualReview/updateAnnualReview'
import { AnnualReviewEditMode, Form } from '~bank-annual-review/types/Manage'

import { Footer } from './components/Footer'
import { Licensing } from './components/Licensing'
import { Overview } from './components/Overview'
import { Questionnaire } from './components/Questionnaire'
import { ManageAnnualReviewContext } from './context'

import { s } from './styles'

const routeApi = getRouteApi('/annual-review/edit/$companyId/$id')

const AnnualReviewEditContainer: FC = () => {
  const navigate = routeApi.useNavigate()
  const { id, companyId } = routeApi.useParams()

  const loaderData: AnnualReviewEditMode = routeApi.useLoaderData({ structuralSharing: true })

  const { routine } = useUpdateAnnualReview({
    onSuccess: async () => {
      navigate({ to: AnnualReviewPaths.DETAILS, params: { companyId, id } })
      Toast.successSmall('Annual Review was successfully updated.')
    },
    onError: async (error) => handleFormSubmissionError<AnnualReviewEditMode['form']>(error, formApi)
  })

  const formApi = useForm<Form>({
    defaultValues: loaderData.form,
    onSubmit: async ({ value }) => await routine(value)
  })

  return (
    <LeaveConfirmation preventLeaving={formApi.useStore((s) => s.isDirty && !s.isSubmitting && !s.isSubmitted)}>
      <ManageAnnualReviewContext.Provider value={{ formApi }}>
        <Page
          face={PageFace.SECONDARY}
          title={loaderData.title}
          subTitle={`AR-${id}`}
          isPending={!id}
          footer={<Footer />}
        >
          <Layer rounded shadowed>
            <Panel collapsible={false} title={loaderData.title}>
              <s.FormPaddingWrapper>
                <Overview />
                <Hr key={1} />
                <Licensing />
                <Questionnaire />
              </s.FormPaddingWrapper>
            </Panel>
          </Layer>
        </Page>
      </ManageAnnualReviewContext.Provider>
    </LeaveConfirmation>
  )
}

export default AnnualReviewEditContainer
