import { KeyboardEvent, memo, useCallback, useContext } from 'react'

import { SelectContext } from '../context'
import { OptionProps } from './OptionProps'
import { LiStyled } from './style'

export const Option = memo((props: OptionProps) => {
  const { children, index = 0, value, close, getItemProps, isEmpty = false, dataQA } = props

  const { selectedIndex, listRef, activeIndex, setOption } = useContext(SelectContext)
  const handleClick = useCallback(() => {
    setOption(index, value)
    close()
  }, [setOption, index, close, value])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handleClick()
      }
    },
    [handleClick]
  )

  return (
    <LiStyled
      key={index}
      ref={(node) => (listRef.current[index] = node)}
      role="option"
      aria-selected={activeIndex === index && selectedIndex === index}
      data-selected={selectedIndex === index}
      $isEmpty={isEmpty}
      $selected={selectedIndex === index}
      $active={activeIndex === index}
      {...getItemProps({
        onClick: handleClick,
        onKeyDown: handleKeyDown,
        onMouseDown: (e) => {
          e.stopPropagation()
        }
      })}
      data-qa={dataQA}
      data-testid={dataQA}
    >
      {children}
    </LiStyled>
  )
})
