import { FC, memo } from 'react'

import { getRouteApi, useRouter } from '@tanstack/react-router'
import { ButtonGroup, Link, Toast } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { HeadingTitle } from 'ui/components/HeadingTitle'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col, Row } from 'ui/themes/globalStyles'
import { AnnualReviewStatusLabel } from '~bank-annual-review/components/AnnualReviewStatusLabel'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { useUpdateAnnualReview } from '~bank-annual-review/service/AnnualReview/approveAnnualReview'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

type MappedOverviewFromResponse<T> = {
  [K in keyof T as K extends `overview${string}` ? K : never]: T[K]
}

export const Overview: FC = memo(() => {
  const router = useRouter()
  const { id, companyId } = routeApi.useParams()
  const loaderData = routeApi.useLoaderData() as MappedOverviewFromResponse<AnnualReviewDetails> &
    Pick<AnnualReviewDetails, 'canBeApproved' | 'isNew' | 'editButtonText'>

  const {
    overviewFinancialsEndDate,
    overviewFinancialsStartDate,
    overviewInitialApproveDate,
    overviewLegalName,
    overviewRelatedCompanies,
    overviewRelationshipName,
    overviewStatus,
    overviewDba,
    overviewLastARDate,
    canBeApproved,
    editButtonText
  } = loaderData

  const { routine: approve } = useUpdateAnnualReview({
    onSuccess: async () => {
      Toast.successSmall('Annual Review was sucessfully approved!')
      router.invalidate()
    }
  })

  const onApprove = () => approve(Number(id))

  return (
    <PageSection
      first
      title="Annual Review"
      face={PageSectionFace.THIRD}
      actions={
        <ButtonGroup margin="big">
          <Link to="/annual-review/edit/$companyId/$id" params={{ companyId, id }}>
            <Button face={editButtonText.toLocaleLowerCase() === 'edit' ? 'neutral' : 'positive'}>
              {editButtonText}
            </Button>
          </Link>
          {canBeApproved && (
            <Button face="positive" onClick={onApprove}>
              Approve
            </Button>
          )}
        </ButtonGroup>
      }
    >
      <HeadingTitle large={true}>Overview</HeadingTitle>
      <InfoSet direction={InfoSetDirection.ROW}>
        <Row>
          <Col>
            <Info label="Relationship Name">
              <Link to="/relationships/$companyId" params={{ companyId }}>
                {overviewRelationshipName}
              </Link>
            </Info>
          </Col>
          <Col>
            <Info label="DBA (if applicable)">{overviewDba}</Info>
          </Col>
        </Row>

        <Row>
          <Col>
            <Info label="Licensed Entity Legal Name">{overviewLegalName}</Info>
          </Col>
          <Col>
            <Info label="Related Companies">
              {overviewRelatedCompanies ? overviewRelatedCompanies.join(', ') : '———'}
            </Info>
          </Col>
        </Row>

        <Row>
          <Col>
            <Info label="Date of Initial Approval">{overviewInitialApproveDate}</Info>
          </Col>
          <Col>
            <Info label="Date of Last Annual Review">{overviewLastARDate || '---'}</Info>
          </Col>
        </Row>

        <Row>
          <Col>
            <Info label="Status">
              <AnnualReviewStatusLabel status={overviewStatus} />
            </Info>
          </Col>
          <Col>
            <Info label="Financials Period Start">{overviewFinancialsStartDate || '---'}</Info>
          </Col>
        </Row>

        <Row>
          <Col />
          <Col>
            <Info label="Financials Period End">{overviewFinancialsEndDate || '---'}</Info>
          </Col>
        </Row>
      </InfoSet>
    </PageSection>
  )
})
