import { memo, useContext } from 'react'

import { IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Footer as FooterBox } from 'ui/temp/Footer'

import { CTRBatchesChosenItemsContext } from '../../context/CTRBatchesChosenItemsContext'
import { useCTRBatchDeleteConfirmation } from '../../hooks/useCTRBatchDeleteConfirmation'

export const Footer = memo(() => {
  const { chosenItemsIdList } = useContext(CTRBatchesChosenItemsContext)

  const { openDeleteConfirmation, DeleteConfirmation } = useCTRBatchDeleteConfirmation()

  if (chosenItemsIdList?.length === 0) {
    return null
  }

  return (
    <FooterBox>
      <DeleteConfirmation />
      {chosenItemsIdList && (
        <IconButton face="negative" icon={IconName.DELETE} onClick={() => openDeleteConfirmation()}>
          Delete Batch(es)
        </IconButton>
      )}
    </FooterBox>
  )
})
