import { getRouteApi } from '@tanstack/react-router'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { questionnaire3Labels } from '~bank-annual-review/constants/questionnaireLabels'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

export const Questionnaire3 = () => {
  const questionnaire = routeApi.useLoaderData({
    select: (s) => s.showQuestionnaire3
  })

  return (
    <>
      <PageSection title="Financials">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.financialsPeriodCollected}
              children={<>{questionnaire?.financialsPeriodCollected || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.financialsDepositsMatching}
              children={<>{questionnaire?.financialsDepositsMatching || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Transactions">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.transactionsTypes}
              children={<>{questionnaire?.transactionsTypes.join(', ') || '---'}</>}
            />

            <Q
              label={questionnaire3Labels.transactionsVolProjected}
              children={
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <>{questionnaire?.transactionsVolProjected || '---'}</>
                  &nbsp;
                  {questionnaire?.transactionsVolProjected !== 'No' && (
                    <>({questionnaire?.transactionsVolProjectedExactly || '---'})</>
                  )}
                </div>
              }
            />

            <Q
              label={questionnaire3Labels.transactionsExpectExactly}
              children={<>{questionnaire?.transactionsExpectExactly || '---'}</>}
            />
            {questionnaire?.transactionsExpectExactly === 'Unknown' && (
              <Q
                label={questionnaire3Labels.transactionsExpectExplain}
                children={<>{questionnaire?.transactionsExpectExplain || '---'}</>}
              />
            )}
            <Q
              label={questionnaire3Labels.transactionsMatchTrans}
              children={<>{questionnaire?.transactionsMatchTrans || '---'}</>}
            />
            {questionnaire?.transactionsMatchTrans === 'No' && (
              <Q
                label={questionnaire3Labels.transactionsNotes}
                children={<>{questionnaire?.transactionsNotes || '---'}</>}
              />
            )}
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Covenants/Agreements">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.covenantComplying}
              children={<>{questionnaire?.covenantComplying || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.covenantNonComplianceDesc}
              children={<>{questionnaire?.covenantNonComplianceDesc || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.covenantTransparent}
              children={<>{questionnaire?.covenantTransparent || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Site Visits">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.siteVisitsLastVisitDate}
              children={<>{questionnaire?.siteVisitsLastVisitDate || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.siteVisitsComplyingRegulations}
              children={<>{questionnaire?.siteVisitsComplyingRegulations || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.siteVisitsViolationRectified}
              children={<>{questionnaire?.siteVisitsViolationRectified || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Ownership & Management">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.ownershipChanges}
              children={<>{questionnaire?.ownershipChanges || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.ownershipOFACTLOReviewed}
              children={<>{questionnaire?.ownershipOFACTLOReviewed || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.ownershipBadNews}
              children={<>{questionnaire?.ownershipBadNews || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.ownershipBadNewsDesc}
              children={<>{questionnaire?.ownershipBadNewsDesc || '---'}</>}
            />
            <Q
              label={questionnaire3Labels.ownershipBadNewsCleared}
              children={<>{questionnaire?.ownershipBadNewsCleared || '---'}</>}
            />
            {questionnaire?.ownershipBadNewsCleared === 'No' && (
              <Q
                label={questionnaire3Labels.ownershipBadNewsClearedExplain}
                children={<>{questionnaire?.ownershipBadNewsClearedExplain || '---'}</>}
              />
            )}
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Business Plans">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.businessPlansSell}
              children={<>{questionnaire?.businessPlansSell || '---'}</>}
            />
            {questionnaire?.businessPlansSell === 'Yes' && (
              <Q
                label={questionnaire3Labels.businessPlansSellDesc}
                children={<>{questionnaire?.businessPlansSellDesc || '---'}</>}
              />
            )}
            <Q
              label={questionnaire3Labels.businessPlansExpand}
              children={<>{questionnaire?.businessPlansExpand || '---'}</>}
            />
            {questionnaire?.businessPlansExpand === 'Yes' && (
              <Q
                label={questionnaire3Labels.businessPlansExpandDesc}
                children={<>{questionnaire?.businessPlansExpandDesc || '---'}</>}
              />
            )}
            <Q
              label={questionnaire3Labels.businessPlansNewAgreementsWithOther}
              children={<>{questionnaire?.businessPlansNewAgreementsWithOther || '---'}</>}
            />
            {questionnaire?.businessPlansNewAgreementsWithOther === 'Yes' && (
              <Q
                label={questionnaire3Labels.businessPlansNewAgreementsWithOtherDesc}
                children={<>{questionnaire?.businessPlansNewAgreementsWithOtherDesc || '---'}</>}
              />
            )}
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Recommendations">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire3Labels.recommendationsRecommended}
              children={<>{questionnaire?.recommendationsRecommended || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
    </>
  )
}
