import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { isEqual } from 'lodash'
import { getAdverseMediaList } from '~bank-adverse-media/service/getAdverseMediaList'

import { AdverseMediaListSchema } from './AdverseMediaListSchema'

export const listRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '/',
    validateSearch: (search) => AdverseMediaListSchema.parse(search),
    loaderDeps: (all) => ({
      search: all.search,
      isChanged: !isEqual(AdverseMediaListSchema.parse({}), all.search)
    }),
    loader: ({ deps }) => getAdverseMediaList(deps.search),
    component: lazyRouteComponent(() => import('./List'))
  })
