import styled from 'styled-components'
import { IconV2 } from 'ui/components'
import { CSS } from 'ui/helpers'

export const Box = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset100Y4)};
`

export const Header = styled.div`
  cursor: pointer;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 20px;
`

export const Content = styled.div``

export const Title = styled.div`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.h5.semiBold)};
  display: flex;
  align-items: center;
`

export const WrappedIcon = styled(IconV2)`
  margin-right: 8px;
  color: var(--theme-colors-global-general-secondary);
`

export const Action = styled.div`
  margin-left: auto;
`
