import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type ReturnedCheckOutProps = {
  index: number
}

export const ReturnedCheckOut = ({ index }: ReturnedCheckOutProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].returnedChecksOut` as 'transactionsInfo[0].returnedChecksOut'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => (
        <FF required field={field} label="Returned Checks Out" input={(p) => <CurrencyCentInput {...p} />} />
      )}
    />
  )
}
