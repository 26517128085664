import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type CheckInProps = {
  index: number
}

export const CheckIn = ({ index }: CheckInProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].checkIn` as 'transactionsInfo[0].checkIn'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => <FF required field={field} label="Checks In" input={(p) => <CurrencyCentInput {...p} />} />}
    />
  )
}
