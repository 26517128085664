import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { CompanySelect } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './FilterProps'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const { isExpanded, onClear, onToggle, form, webPage } = props
  const { config, Field, isDirty } = form

  return (
    <FilterPanel isDirty={isDirty} onReset={onClear} expanded={isExpanded} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.FilterWrapper>
            <SC.ItemWrapper>
              <Field
                name="company_id"
                label="Relationships"
                input={CompanySelect}
                inputProps={{
                  hideMyCompaniesFilter: true,
                  preloadedOptions: webPage.relationshipOptions
                }}
              />
            </SC.ItemWrapper>
          </SC.FilterWrapper>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
