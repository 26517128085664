import { ReactElement, ReactNode, memo, useCallback, useContext } from 'react'
import { useQueryClient } from 'react-query'

import { useNavigate, useRouterState } from '@tanstack/react-router'
import styled from 'styled-components'
import { AuthContext } from '~auth/AuthContext'
import { useCreateDevice, useSendChallenge, useSendSecurityCode } from '~auth/hooks/mfa'
import { useSubmitPassword } from '~auth/hooks/user'
import { AuthMFAState } from '~auth/types/DTO'

import {
  ChooseDeviceForm,
  ChooseDeviceFormShape,
  CreateDeviceForm,
  CreateDeviceFormShape,
  SecurityCodeForm,
  SecurityCodeFormFormShape,
  SignInForm,
  SignInFormShape
} from './components'

//region Styled

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

//endregion

interface SignInProps {
  logoElement: ReactElement
  footer: ReactNode
}

export const SignIn = memo(({ logoElement, footer }: SignInProps) => {
  const { portalName } = useContext(AuthContext)
  const client = useQueryClient()
  const submitPasswordMutation = useSubmitPassword(portalName)
  const createDeviceMutation = useCreateDevice(portalName)
  const sendSecurityCodeMutation = useSendSecurityCode(portalName)
  const challengeMutation = useSendChallenge(portalName)
  const mfa = client.getQueryData<AuthMFAState>(['mfa'])

  const hash = useRouterState({ select: (s) => s.location.hash })
  const navigate = useNavigate()

  const submitPassword = useCallback(
    (value: SignInFormShape) => submitPasswordMutation.mutateAsync(value),
    [submitPasswordMutation]
  )

  const createDevice = useCallback(
    (value: CreateDeviceFormShape) => createDeviceMutation.mutateAsync({ ...value, token: mfa?.token || '' }),
    [createDeviceMutation, mfa]
  )

  const sendSecurityCode = useCallback(
    (value: SecurityCodeFormFormShape) =>
      sendSecurityCodeMutation.mutateAsync({
        challengeId: mfa?.challengeId || '',
        code: value.code,
        token: mfa?.token || ''
      }),
    [sendSecurityCodeMutation, mfa]
  )

  const startChallengeForNewlySelectedDevice = useCallback(
    (value: ChooseDeviceFormShape) => {
      client.setQueryData<Partial<AuthMFAState>>(['mfa'], {
        ...mfa,
        chosen: {
          ...value,
          totpUrl: '',
          totpSecret: ''
        }
      })

      return challengeMutation
        .mutateAsync({
          deviceId: value.id,
          token: mfa?.token || ''
        })
        .then(() => {
          navigate({ to: '/auth/login', replace: true })
        })
    },
    [client, challengeMutation, mfa, navigate]
  )

  const restartChallenge = useCallback(() => {
    if (mfa?.chosen) {
      return challengeMutation
        .mutateAsync({
          deviceId: mfa.chosen.id,
          token: mfa?.token || ''
        })
        .then(() => {
          navigate({ to: '/auth/login', replace: true })
        })
    }
    return Promise.resolve(false)
  }, [challengeMutation, mfa, navigate])

  const getForm = useCallback(() => {
    // We explicitly navigated to choose device form.
    if (hash === '#choose-device') {
      return <ChooseDeviceForm onSubmit={startChallengeForNewlySelectedDevice} logoElement={logoElement} />
    }

    // If challenge is already running, show security code form.
    if (mfa?.challengeId) {
      return (
        <SecurityCodeForm onSubmit={sendSecurityCode} onRetryChallenge={restartChallenge} logoElement={logoElement} />
      )
    }

    // MFA step required and no devices were created.
    if (mfa && !mfa.devices.length) {
      return <CreateDeviceForm onSubmit={createDevice} logoElement={logoElement} />
    }

    // Fallback case, when no sign in process started, just show password form.
    return <SignInForm onSubmit={submitPassword} logoElement={logoElement} footer={footer} />
  }, [
    hash,
    mfa,
    submitPassword,
    logoElement,
    footer,
    startChallengeForNewlySelectedDevice,
    sendSecurityCode,
    restartChallenge,
    createDevice
  ])

  return <Box>{getForm()}</Box>
})
