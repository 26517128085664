import { ApiReadResponse } from 'commons/types'
import { BsaSarServer } from 'commons/types/DTO/bank/bsa'
import { Request } from 'commons/utils/request'
import { ServerOptions } from 'ui/components/QueryTable'
import { SarEdit } from '~bank-bsa-reporting/types'

export const getList = (opts: ServerOptions): Promise<ApiReadResponse<BsaSarServer>> =>
  Request.post(Request.urls.bank.sarList, opts)

export const remove = (opts: ServerOptions) => Request.delete(Request.urls.bank.sar, opts)

export const save = (opts: SarEdit) => {
  const saveFunc = opts.id ? Request.put : Request.post
  return saveFunc(Request.urls.bank.sar, opts)
}

export const transactionsZIP = (opts: ServerOptions) => Request.post(Request.urls.bank.bsaSarTransactionsZip, opts)

export const companyList = (opts: ServerOptions) => Request.post(Request.urls.bank.companyList, opts)

export const editPeriod = (opts: ServerOptions) => Request.put(Request.urls.bank.editSarPeriod, opts)

export const generateXML = (opts: ServerOptions) => Request.post(Request.urls.bank.sarFormBatch, opts)

export const approveSAR = (opts: { id: number }) => Request.patch(Request.urls.bank.bsaSarApprove, opts)
export const cancelSAR = (opts: { id: number }) => Request.patch(Request.urls.bank.bsaSarCancel, opts)
export const sarAcknowledgeWarnings = (opts: { id: number }) =>
  Request.put(Request.urls.bank.sarAcknowledgeWarnings, opts)
