import { getQRDetailsWebPage } from 'commons/service/bank/qr'
import { QRWebPagePayload, QuarterlyReportWebPage } from 'commons/types/DTO/bank/quarterlyReport'
import { Keys, ReadHook, useStateManager } from 'state-manager'

const getHandler = (payload?: QRWebPagePayload) => (): Promise<QuarterlyReportWebPage> => getQRDetailsWebPage(payload)

export const useGetQRWebPage: ReadHook<QuarterlyReportWebPage, QRWebPagePayload> = (opts) =>
  useStateManager().use(Keys.QR.ItemByOpts(opts), getHandler(opts), {
    keepPreviousData: true
  })
