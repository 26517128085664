import { FC, memo } from 'react'

import { Link, useNavigate, useParams } from '@tanstack/react-router'
import { BankAccountDocuments } from 'bank-documents'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { Button, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Page, PageFace, PageSectionFace } from 'ui/components/Page'
import { useConfirmation } from 'ui/hooks'

import { BANK_ACCOUNTS_PATH } from '../../constants'
import { hooks } from '../../hooks'
import { BreadCrumbs, GeneralInformation } from './components'
import { useBankAccountDetails } from './useDetails'

import * as SC from './styles'

const Details: FC = memo(() => {
  const navigate = useNavigate()
  const { bankAccountId } = useParams({ strict: false })
  const id = +(bankAccountId || 0)

  const { webPageData, onFilterBankAccountDetails } = useBankAccountDetails(id)

  const { routine: deleteBankAccount } = hooks.useDelete({
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      Toast.successSmall('The bank account was successfully removed.')
      navigate({
        to: `${COMPANIES_PATH}/$companyId`,
        params: { companyId: webPageData?.company.id },
        hash: 'bank-accounts'
      })
    }
  })

  const { open: openDelete, Confirmation: DeleteConfirmation } = useConfirmation({
    message: `Are you sure want to remove this bank account?`,
    onConfirm: () => deleteBankAccount({ id }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <Page
      face={PageFace.SECONDARY}
      title=""
      subTitle="Bank Account"
      actions={
        <IconButton icon={IconName.DELETE} face="neutral" onClick={openDelete}>
          Delete Page
        </IconButton>
      }
      isPending={!webPageData}
    >
      <DeleteConfirmation />
      <BreadCrumbs
        companyId={webPageData?.company.id}
        companyName={webPageData?.company.name}
        bankAccountNumber={webPageData?.number}
      />
      <SC.WrappedPageSection
        face={PageSectionFace.SECONDARY}
        title="General Information"
        anchor="general"
        actions={
          <Link
            to={`${BANK_ACCOUNTS_PATH}/$bankAccountId/edit`}
            params={{ bankAccountId: webPageData?.id }}
            search={{ returnUrl: window.location.pathname }}
          >
            <Button face="neutral">Edit</Button>
          </Link>
        }
      >
        <SC.WrappedHr />
        <GeneralInformation
          companyId={webPageData?.company.id}
          companyName={webPageData?.company.name}
          bankAccountNumber={webPageData?.number}
          isLicensed={webPageData?.licensed}
          openingDate={webPageData?.openingDate}
          internalTransferType={webPageData?.internalTransferType}
          updatedAt={webPageData?.updatedAt}
          createdAt={webPageData?.createdAt}
          notes={webPageData?.notes}
          licenses={webPageData?.licenses}
        />
      </SC.WrappedPageSection>
      {webPageData?.documents && (
        <BankAccountDocuments
          webPage={webPageData}
          documentEditCancelReturnUrl={`/bank-accounts/${webPageData?.id}`}
          onFilterBankAccountDetails={onFilterBankAccountDetails}
        />
      )}
    </Page>
  )
})

export default Details
