import styled from 'styled-components'

const BankAccountsContainer = styled.div`
  position: relative;
`

const BankAccountsButton = styled.div`
  position: absolute;
  left: 580px;
  bottom: 0;
`

export const s = { BankAccountsContainer, BankAccountsButton }
