// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { KeyboardEvent, MouseEvent, useCallback, useState } from 'react'

import { ChoiceValue } from 'ui/types'

import { ModelData, SelectContext, UseKeyboardNavOptions } from '../types'

/* find previous active index for column mode */
const whatNewActiveIndexForUp = (modelData: ModelData[], currentIndex: number, selectedValues: ChoiceValue[]) => {
  // lets understand is option under currentIndex selected, or not

  const temp = modelData.findIndex((el) => el.index === currentIndex)
  const activeItem = modelData[temp]
  const isSelected = activeItem?.selected

  if (isSelected) {
    // if selected, get previous selected option,
    // ask which index of that value in modelData, and set that index as active
    const selectedIndex = selectedValues.findIndex((el) => el === activeItem.data.value)
    let prevSelectedIndex = 0
    if (selectedIndex) {
      prevSelectedIndex = selectedIndex - 1
    }
    const prevSelectedIndexInModel = modelData.findIndex((el) => el.data.value === selectedValues[prevSelectedIndex])

    return prevSelectedIndexInModel
  } else {
    // if not selected, get previous selected option index from modelData array
    // and set that index as active
    for (let i = temp - 1; i >= 0; i--) {
      if (!modelData[i].selected) {
        return modelData[i].index
      }
    }
    return currentIndex
  }
}

/* find next active index for column mode */
const whatNewActiveIndexForDown = (modelData: ModelData[], currentIndex: number, selectedValues: ChoiceValue[]) => {
  // lets understand is option under currentIndex selected, or not

  const temp = modelData.findIndex((el) => el.index === currentIndex)
  const activeItem = modelData[temp]
  const isSelected = activeItem?.selected

  if (isSelected) {
    // if selected, get next selected option,
    // ask which index of that value in modelData, and set that index as active
    const selectedIndex = selectedValues.findIndex((el) => el === activeItem.data.value)
    let nextSelectedIndex = selectedValues.length - 1
    if (selectedIndex < nextSelectedIndex) {
      nextSelectedIndex = selectedIndex + 1
    }
    const nextSelectedIndexInModel = modelData.findIndex((el) => el.data.value === selectedValues[nextSelectedIndex])

    return nextSelectedIndexInModel
  } else {
    // if not selected, get next not selected option index from modelData array
    // and set that index as active
    for (let i = temp + 1; i < modelData.length; i++) {
      if (!modelData[i].selected) {
        return modelData[i].index
      }
    }
    return currentIndex
  }
}

export const useKeyboardNav = (context: SelectContext, options: UseKeyboardNavOptions) => {
  const { modelData, activeIndex, setActiveIndex, selectValue, unselectValue, selectedValues } = context
  const { mode, listRef } = options

  const [usingKeyboard, setUsingKeyboard] = useState(false)

  const maxIndex = modelData.length - 1

  const setActiveIndexAndScroll = useCallback(
    (index: number) => {
      if (listRef && listRef.current[index]) {
        setActiveIndex(index)
        listRef.current[index].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'nearest' })
      }
    },
    [listRef, setActiveIndex]
  )

  /* navigate next option */
  const handleArrowDown = useCallback(() => {
    let newActiveIndex = null
    if (mode === 'columns') {
      newActiveIndex = whatNewActiveIndexForDown(modelData, activeIndex, selectedValues)
    } else {
      newActiveIndex = activeIndex === null ? 0 : activeIndex + 1
    }
    if (newActiveIndex <= maxIndex) {
      setActiveIndexAndScroll(newActiveIndex)
    }
  }, [activeIndex, maxIndex, mode, modelData, setActiveIndexAndScroll, selectedValues])

  /* navigate previous option */
  const handleArrowUp = useCallback(() => {
    let newActiveIndex = null
    if (mode === 'columns') {
      newActiveIndex = whatNewActiveIndexForUp(modelData, activeIndex, selectedValues)
    } else {
      newActiveIndex = activeIndex != 0 ? activeIndex - 1 : 0
    }
    setActiveIndexAndScroll(newActiveIndex)
  }, [activeIndex, mode, modelData, setActiveIndexAndScroll, selectedValues])

  /* navigate to not selected list */
  const handleArrowLeft = useCallback(() => {
    if (mode !== 'columns') {
      return
    }
    const newActiveIndex = modelData.find((el) => !el.selected)?.index || 0
    setActiveIndexAndScroll(newActiveIndex)
  }, [mode, modelData, setActiveIndexAndScroll])

  /* navigate to selected list */
  const handleArrowRight = useCallback(() => {
    if (mode !== 'columns') {
      return
    }
    const newActiveIndex = modelData.find((el) => el.selected)?.index || 0
    setActiveIndexAndScroll(newActiveIndex)
  }, [mode, modelData, setActiveIndexAndScroll])

  /* select option */
  const handleEnter = useCallback(() => {
    const item = modelData.find((el) => el.index === activeIndex)
    if (item.data.disabled) {
      return
    }
    // if in columns mode, set next item as active in column where user select item
    if (mode === 'columns') {
      let newActiveItem = null
      const modelDataRevesed = [...modelData].reverse()
      if (item.selected) {
        // if we in column with selected items
        // tring to find prev selected item selected column
        const activeItem = modelData[activeIndex]
        const activeValueIndex = selectedValues.findIndex((el) => el === activeItem.data.value)
        let prevActiveValueIndex = 0
        if (activeValueIndex) {
          prevActiveValueIndex = activeValueIndex - 1
        } else {
          // or find next selected if we deselect first item in column
          prevActiveValueIndex = activeValueIndex + 1
          if (prevActiveValueIndex === selectedValues.length) {
            prevActiveValueIndex = selectedValues.length - 1
          }
        }
        newActiveItem = modelData.find((el) => el.data.value === selectedValues[prevActiveValueIndex])
      } else {
        // if we in column with NON selected items
        // tring to find next non selected item in data
        newActiveItem = modelData.find((el) => el.index > activeIndex && !el.selected)
        if (!newActiveItem) {
          // if no next (when user press ENTER on last option), find previous one
          newActiveItem = modelDataRevesed.find((el) => el.index != activeIndex && !el.selected)
        }
      }
      const newActiveIndex = newActiveItem ? newActiveItem.index : activeIndex
      setActiveIndexAndScroll(newActiveIndex)
    }
    if (item.selected) {
      unselectValue(item.data.value)
    } else {
      selectValue(item.data.value)
    }
  }, [activeIndex, modelData, mode, selectValue, unselectValue, setActiveIndexAndScroll, selectedValues])

  /* handle navigation */
  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      event.stopPropagation()
      setUsingKeyboard(true)
      if (event.key == 'ArrowDown') {
        handleArrowDown()
      }
      if (event.key == 'ArrowUp') {
        handleArrowUp()
      }
      if (event.key == 'ArrowLeft') {
        handleArrowLeft()
      }
      if (event.key == 'ArrowRight') {
        handleArrowRight()
      }
      if (event.key == 'Enter') {
        handleEnter()
      }
    },
    [handleArrowDown, handleArrowUp, handleEnter, handleArrowLeft, handleArrowRight]
  )

  /* simulate hover */
  const handleMouseOver = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      // if using keyboard do not set active index,
      // coz mouseenter event fires when scrolling on keyboard nav
      if (usingKeyboard) {
        return
      }
      if (!(event.target instanceof HTMLElement)) {
        return
      }

      let neededOption = null

      // if we get event directly from option
      if (event.target.getAttribute('role') == 'option') {
        neededOption = event.target
      }
      // if we get event from option child
      else if (event.target.closest('[role="option"]')) {
        neededOption = event.target.closest('[role="option"]')
      }

      // if no entire option exist (click on dropdown layout for example)
      if (!neededOption) {
        return
      }

      // find that neededOption in ref and set as active
      listRef.current.forEach((el, index) => {
        if (neededOption == el) {
          setActiveIndex(index)
          return
        }
      })
    },
    [usingKeyboard, listRef, setActiveIndex]
  )

  /* when user actually use mouse, set "not using keyboard" */
  const handleMouseMove = useCallback(() => {
    setUsingKeyboard(false)
  }, [])

  return {
    reference: {
      onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        handleKeyDown(event)
      }
    },
    option: {
      onKeyDown(event: KeyboardEvent<HTMLElement>) {
        handleKeyDown(event)
      },
      onMouseOver(event: MouseEvent<HTMLDivElement>) {
        handleMouseOver(event)
      },
      onMouseMove() {
        handleMouseMove()
      }
    }
  }
}
