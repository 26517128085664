import { useState } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { handleFormSubmissionError } from 'ui/components/Form'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSaveSAR } from '../../../../hooks/sar'

const routeApi = getRouteApi('/bsa/sars/$id/edit')

export const useSARForm = (sar?: SarEdit) => {
  const { returnUrl } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const [isSuccess, setIsSuccess] = useState(false)

  const comeBackToSarOrReturnUrl = () => {
    if (returnUrl) {
      return navigate({ to: decodeURIComponent(returnUrl) })
    } else {
      if (sar?.id) {
        return navigate({ to: '/bsa/sars/$id', params: { id: sar.id } })
      } else {
        return navigate({ to: '/bsa/sars' })
      }
    }
  }

  const { routine } = useSaveSAR({
    onSuccess: async () => {
      setIsSuccess(true)
      formApi.reset()
      setTimeout(() => {
        comeBackToSarOrReturnUrl()
      }, 0)
    },
    onError: (error) => {
      handleFormSubmissionError<SarEdit>(error, formApi)
    }
  })

  const formApi = useForm<SarEdit>({
    defaultValues: sar,
    onSubmit: async ({ value }) =>
      await routine({
        ...value
      }),
    validators: {
      onSubmit: ({ value }: { value: SarEdit }) => {
        const ti = value.suspiciousActivityInfo
        if (
          !(
            ti.structuring.length ||
            ti.terroristFinancing.length ||
            ti.fraud.length ||
            ti.laundering.length ||
            ti.identification.length ||
            ti.otherActivities.length ||
            ti.gaming.length ||
            ti.cyber.length ||
            ti.insurance.length ||
            ti.mortgage.length ||
            ti.securities.length
          )
        ) {
          const errorMessage = 'Item 32-42. At least one type of suspicious activity must be selected'
          return {
            fields: {
              'suspiciousActivityInfo.structuring': errorMessage,
              'suspiciousActivityInfo.terroristFinancing': errorMessage,
              'suspiciousActivityInfo.fraud': errorMessage,
              'suspiciousActivityInfo.laundering': errorMessage,
              'suspiciousActivityInfo.identification': errorMessage,
              'suspiciousActivityInfo.otherActivities': errorMessage,
              'suspiciousActivityInfo.gaming': errorMessage,
              'suspiciousActivityInfo.cyber': errorMessage,
              'suspiciousActivityInfo.insurance': errorMessage,
              'suspiciousActivityInfo.mortgage': errorMessage,
              'suspiciousActivityInfo.securities': errorMessage
            }
          }
        }

        value.institutionContactInfo.tin = value.institutionContactInfo.tin.replace('-', '') || ''
        value.institutionActivitiesInfo.map((el) => {
          el.tin = el?.tin?.replace('-', '') || ''
        })
        value.subjectsInfo.map((el) => {
          el.tin = el?.tin?.replace('-', '') || ''
        })

        return undefined
      },
      onSubmitAsync: async ({ value, formApi }) => {
        const otherFieldsErrors = await formApi.validateAllFields('change')
        const isAllOtherFieldsValid = otherFieldsErrors.length === 0

        if (
          isAllOtherFieldsValid &&
          !(
            value.suspiciousActivityInfo.amountUnknown ||
            value.suspiciousActivityInfo.noAmountInvolved ||
            value.suspiciousActivityInfo.currentAmount
          )
        ) {
          const element = document.querySelector(`[data-qa="Item29"]`)
          element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
        } else return undefined
      }
    }
  })

  const { useStore } = formApi
  const isDirty = useStore((state) => state.isDirty && state.isValid)
  const isFormValidating = useStore((state) => state.isFormValidating)

  return {
    formApi,
    isFormValidating,
    isFormDirty: isDirty,
    isSuccess
  }
}
