import { FC, memo } from 'react'

import { BreadCrumbsProps } from './BreadCrumbs.types'

import { s } from './styles'

export const BreadCrumbs: FC<BreadCrumbsProps> = memo(({ applicationId, idLabel }) => (
  <>
    <s.Application to="/onboarding/applications/$applicationId" params={{ applicationId: applicationId }}>
      Application ID-{applicationId}
    </s.Application>
    <s.Arrow>&gt;</s.Arrow>
    <s.OnboardingDocuments>{idLabel}</s.OnboardingDocuments>
  </>
))
