import { ChoiceOption } from 'ui/types'

export type ApplicationDocumentCreateWebpage = {
  contactsOptions: ChoiceOption[]
  licensesOptions: ChoiceOption[]
  relationshipOptions: ChoiceOption[]
}

export class ApplicationDocumentPayload {
  subjectType: string | null = null
  applicationId: number | null = null
  applicationEntityUid: string | null = null
  internal = false
  name: string | null = null
  notes: string | null = null
  optional = false

  constructor(applicationId: number) {
    this.applicationId = applicationId
  }
}
