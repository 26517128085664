import { useState } from 'react'

import { Link, useNavigate } from '@tanstack/react-router'
import { useResendOnboardingDocument, useSendOnboardingDocument } from 'commons/hooks/bank/onboardingSetupDocuments'
import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'
import { DownloadLink, IconName, Toast } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/components/Dialog/ConfirmationDialogV2'

import { CancelEsignLinkDocumentDialog } from '../../../../components/CancelEsignLinkDocumentDialog'
import { useDeleteDocument } from '../../../../hooks/useDeleteDocument'
import { ActionsProps } from './Actions.types'

export const Actions = ({ webPageData, applicationId }: ActionsProps) => {
  const navigate = useNavigate()
  const confirmation = useConfirmation()

  const { id, enableCancel, enableDelete, enableClone, enableResend, enableSend, enableDownload } = webPageData

  const { openDeleteConfirmation } = useDeleteDocument(id, () =>
    navigate({ to: '/onboarding/applications/$applicationId', params: { applicationId: applicationId } })
  )

  const { routine: sendOnboardingDocument } = useSendOnboardingDocument({
    onSuccess: async () => {
      navigate({ to: '/onboarding/applications/$applicationId', params: { applicationId: applicationId } })
      Toast.successSmall(`The set of documents ID-${id} already sent`)
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: resendOnboardingDocument } = useResendOnboardingDocument({
    onSuccess: async () => {
      navigate({ to: '/onboarding/applications/$applicationId', params: { applicationId: applicationId } })
      Toast.successSmall(`The set of documents ID-${id} already resent`)
    },
    onError: (error) => handlerServerError(error)
  })

  const handleDocumentSend = async () => {
    if (
      await confirmation.showAskConfirmation({
        message: <>Are you sure you want to&nbsp;send the set of&nbsp;documents&nbsp;ID-{id}?</>,
        submitButtonText: 'Send',
        confirmCallback: () => sendOnboardingDocument(id)
      })
    ) {
      await confirmation.showDoneConfirmation({
        message: <>The set of documents ID-{id} already sent</>
      })
    }
  }

  const handleDocumentResend = async () => {
    if (
      await confirmation.showAskConfirmation({
        message: <>Are you sure you want to&nbsp;resend the set of&nbsp;documents&nbsp;ID-{id}?</>,
        submitButtonText: 'Resend',
        confirmCallback: () => resendOnboardingDocument(id)
      })
    ) {
      await confirmation.showDoneConfirmation({
        message: <>The set of documents ID-{id} already resent</>
      })
    }
  }

  const [showCancelEsignDialog, setShowCancelEsignDialog] = useState(false)

  return (
    <ButtonGroup margin="big">
      {showCancelEsignDialog && (
        <CancelEsignLinkDocumentDialog
          id={id}
          onClose={() => setShowCancelEsignDialog(false)}
          onSuccess={() =>
            navigate({ to: '/onboarding/applications/$applicationId', params: { applicationId: applicationId } })
          }
        />
      )}

      {enableDelete && (
        <IconButton face="neutral" icon={IconName.DELETE} onClick={() => openDeleteConfirmation()}>
          Delete
        </IconButton>
      )}
      {enableCancel && (
        <IconButton face="neutral" icon={IconName.CANCEL_WHITE_ROUND} onClick={() => setShowCancelEsignDialog(true)}>
          Cancel ESign Link
        </IconButton>
      )}
      {enableSend && (
        <HeaderButton size="middle" overColoredPad={true} onClick={() => handleDocumentSend()}>
          Send
        </HeaderButton>
      )}
      {enableResend && (
        <HeaderButton size="middle" overColoredPad={true} onClick={() => handleDocumentResend()}>
          Resend
        </HeaderButton>
      )}
      {enableClone && (
        <Link to="/application/$applicationId/onboarding-documents/$id/clone" params={{ applicationId, id }}>
          <HeaderButton size="middle" overColoredPad={true}>
            Clone
          </HeaderButton>
        </Link>
      )}
      {enableDownload && (
        <DownloadLink
          direct
          baseUrl={Request.urls.bank.downloadOnboardingDocuments}
          parametersForm={{ appDocusignEnvelopeId: id }}
          handleError={() => ({})}
          withIcon={false}
        >
          <HeaderButton size="middle" overColoredPad={true}>
            Download
          </HeaderButton>
        </DownloadLink>
      )}
    </ButtonGroup>
  )
}
