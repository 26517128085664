import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'

import * as SC from './styles/TextareaInputStyles'
import { InputHint } from 'ui/core/InputHint'

import { useEmpty } from './hooks'
import { TextareaProps } from './props'

const MAXIMUM_LENGTH = undefined
const ROWS = 5

export const TextareaInput = memo(
  forwardRef<HTMLTextAreaElement, TextareaProps>((props, forwardRef) => {
    const {
      value,
      onChange,
      error,
      required,
      disabled,
      hint,
      validator,
      maxLength = MAXIMUM_LENGTH,
      rows = ROWS,
      hideHint,
      onBlur,
      style,
      ...rest
    } = props

    const inputRef = useRef<HTMLTextAreaElement | null>(null)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(forwardRef, () => inputRef.current)

    const [valueLength, setValueLength] = useState(0)

    useEffect(() => {
      setValueLength(value ? value.toString().length : 0)
    }, [value])

    const { isEmpty, handleChange: emptyHandleChange } = useEmpty(value, onChange)
    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValueLength(event.target.value.length)
        // if error set by onblur validation, do not undo that, validate on change also
        emptyHandleChange(event.target.value, error && validator ? validator(event.target.value) : null)
      },
      [error, validator, emptyHandleChange]
    )

    // validate value on blur only
    const handleBlur = useCallback(
      (event: FocusEvent<HTMLTextAreaElement>) => {
        if (validator && inputRef.current && onChange) {
          onChange(inputRef.current.value, validator(inputRef.current.value))
        }
        if (onBlur) {
          onBlur(event)
        }
      },
      [validator, onChange, onBlur]
    )

    const showRequired = required && !error && !disabled

    return (
      <SC.Wrapper role="textarea">
        <SC.InputAndRequiredWrapper $error={!!error} $disabled={disabled}>
          {maxLength !== undefined && (
            <SC.Counter data-testid="counter" $disabled={disabled}>
              {valueLength}/{maxLength}
            </SC.Counter>
          )}
          {showRequired && <SC.RequiredStyled data-testid="required" isInputEmpty={isEmpty} />}
          <SC.Textarea
            {...rest}
            style={style}
            ref={inputRef}
            value={value ?? ''}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            maxLength={maxLength}
            rows={rows}
          />
        </SC.InputAndRequiredWrapper>
        <InputHint data-testid="hint" hint={hint} disabled={disabled} hideHint={hideHint} />
      </SC.Wrapper>
    )
  })
)
