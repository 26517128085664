import { FC, useCallback } from 'react'

import isEqual from 'lodash/isEqual'
import { Button } from 'ui/components/Buttons'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { EMPTY_TRANSACTION_LOCATION_INFO } from '~bank-bsa-reporting/hooks/ctr/useManageCTR'
import { CTRTransactionLocationsInfo } from '~bank-bsa-reporting/types'

import { TransactionLocationsInformationListProps } from './TransactionLocationsInformationList.types'
import { TransactionLocationInformationForm } from './components'

export const TransactionLocationsInformationList: FC<TransactionLocationsInformationListProps> = (props) => {
  const { data, onChange, errors, validateFunctionRefs, markFormAsDirty } = props
  const { transactionLocationsInfo } = data

  const onChangeWrapper = useCallback(
    (i: number, v: CTRTransactionLocationsInfo, e) => {
      const valueChanged = !isEqual(v, transactionLocationsInfo[i])
      const errorsChanged = !isEqual(e, errors[i])

      if (valueChanged) {
        transactionLocationsInfo[i] = v
      }
      if (errorsChanged) {
        errors[i] = e
      }
      if (valueChanged || errorsChanged) {
        onChange(transactionLocationsInfo, errors)
      }
    },
    [transactionLocationsInfo, onChange, errors]
  )

  const onAddWrapper = useCallback(() => {
    onChange(transactionLocationsInfo.concat(EMPTY_TRANSACTION_LOCATION_INFO), errors)

    markFormAsDirty()
  }, [onChange, transactionLocationsInfo, errors, markFormAsDirty])

  const onDeleteWrapper = useCallback(
    (index: number) => {
      const copy = [...transactionLocationsInfo]
      copy.splice(index, 1)
      onChange(copy, errors)

      markFormAsDirty()
    },
    [transactionLocationsInfo, onChange, errors, markFormAsDirty]
  )

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 2. Transaction Location(s) Information" anchor="step2">
      {transactionLocationsInfo.map((item, index) => (
        <TransactionLocationInformationForm
          key={index}
          value={item}
          errors={errors[index]}
          onChange={onChangeWrapper}
          onDelete={transactionLocationsInfo.length > 1 ? onDeleteWrapper : undefined}
          index={index}
          total={transactionLocationsInfo.length}
          validateFunctionRefs={validateFunctionRefs}
          markFormAsDirty={markFormAsDirty}
        />
      ))}
      <Button face="positive" onClick={onAddWrapper}>
        Add
      </Button>
    </PageSection>
  )
}
