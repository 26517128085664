import { useEffect } from 'react'

import { useFormData } from 'brief-form'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'

export const useQuestionnaireForm = (
  questionnaire: Questionnaire,
  setQuestionnaire: (questionnaire: Questionnaire) => void
) => {
  const { config, Field, isDirty, set } = useFormData<Questionnaire>({ initialValue: questionnaire })

  useEffect(() => {
    if (isDirty) setQuestionnaire(config.value)
  }, [config.value])

  useEffect(() => {
    set({ reset: true, value: questionnaire })
  }, [questionnaire])

  return {
    config,
    Field
  }
}
