import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { CheckBox, Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { CurrencyCentInput, IntegerInput } from 'ui/components/InputV2'

import { AnticipatedMonthlyActivityNAFormProps } from './AnticipatedMonthlyActivityNAFormProps'
import { getAnticipatedMonthlyActivityNAList } from './helpers'
import { useAnticipatedMonthlyActivityNAForm } from './useAnticipatedMonthlyActivityNAForm'

import * as SC from './styles'

export const AnticipatedMonthlyActivityNAForm: FC<AnticipatedMonthlyActivityNAFormProps> = memo((props) => {
  const { companyIndex, value, onChange } = props
  const anticipatedMonthlyActivityNAList = getAnticipatedMonthlyActivityNAList()
  const { Field, config } = useAnticipatedMonthlyActivityNAForm({
    companyIndex,
    formData: value,
    onChange,
    anticipatedMonthlyActivityNAList
  })

  return (
    <Form config={config}>
      <InfoSet legend="Anticipated Monthly Activity">
        <SC.Table>
          <thead>
            <tr>
              <SC.TableTitle />
              <SC.TableTitle>Number of Items</SC.TableTitle>
              <SC.TableTitle>Total Amount</SC.TableTitle>
              <SC.TableTitle>N/A</SC.TableTitle>
            </tr>
          </thead>
          <tbody>
            {anticipatedMonthlyActivityNAList.map(({ label, items, amount, na }) => (
              <SC.TableRow key={label}>
                <td>{label}</td>
                <td>
                  <Field
                    required={!config.value[na]}
                    name={items as any}
                    input={IntegerInput}
                    inputProps={{
                      'data-qa': `${label} - Items`,
                      maxLength: 10,
                      disabled: config.value[na]
                    }}
                    validator={config.value[na] ? undefined : Validators.required.field}
                  />
                </td>
                <td>
                  <Field
                    required={!config.value[na]}
                    name={amount as any}
                    input={CurrencyCentInput}
                    inputProps={{
                      'data-qa': `${label} - Amount`,
                      disabled: config.value[na]
                    }}
                    validator={config.value[na] ? undefined : Validators.required.field}
                  />
                </td>
                <td>
                  <Field
                    name={na as any}
                    input={CheckBox}
                    inputProps={{
                      'data-qa': `${label} - N/A`
                    }}
                  />
                </td>
              </SC.TableRow>
            ))}
          </tbody>
        </SC.Table>
      </InfoSet>
    </Form>
  )
})
