import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const LiStyled = styled.li<{
  $selected: boolean
  $active: boolean
  $isEmpty: boolean
}>`
  cursor: pointer;
  background-color: ${(p) =>
    p.$active ? p.theme.uiKit.colors.global.grey.grey100 : p.theme.uiKit.colors.global.general.main};

  ${(p) =>
    p.$selected && !p.$isEmpty
      ? CSS.populateFont(p.theme.uiKit.typography.bodyL.semiBold)
      : CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)}

  color: ${(p) =>
    p.$isEmpty ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.general.secondary};

  transition: ${CSS.populateTransition(['background-color'])};
`
