import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const LEContactAgency = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.leContactAgency"
      children={(field) => <FF label="89. LE contact agency" field={field} input={(p) => <TextInput {...p} />} />}
    />
  )
}
