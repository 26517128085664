import { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import { FooterProps } from './Footer.types'

import { s } from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { onDelete, onCancel, onSubmit, isSubmitting } = props

  return (
    <s.Box>
      {onDelete ? (
        <s.DeleteButton icon={IconName.DELETE} face="negative" onClick={onDelete}>
          Delete Page
        </s.DeleteButton>
      ) : (
        <span />
      )}
      <ButtonGroup margin="small">
        <Button face="neutral" onClick={onCancel}>
          Cancel
        </Button>
        <Button data-qa="Save" loading={isSubmitting} onClick={onSubmit} face="positive">
          Save
        </Button>
      </ButtonGroup>
    </s.Box>
  )
})
