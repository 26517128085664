import { FC, memo } from 'react'

import { Form, useFormData } from 'brief-form'
import { adverseMediaRiskLevels } from 'commons/types/dictionaries'
import { LeaveConfirmation } from 'ui/components'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { DialogCol, FormRow } from 'ui/themes/globalStyles'

import { RiskLevelDialogProps } from './RiskLevelDialogProps'

import * as SC from './styles'

export const RiskLevelDialog: FC<RiskLevelDialogProps> = memo(({ isActive, onClose, onSubmit, searchCase }) => {
  const { notes, risk_level: riskLevel, search_term: searchTerm } = searchCase
  const { config, isDirty, Field } = useFormData<{ notes: string; riskLevel: string }>({
    initialValue: { notes, riskLevel }
  })

  const handleSubmit = () => {
    onSubmit(config.value.notes, config.value.riskLevel)
  }

  if (!isActive || !searchCase) {
    return null
  }

  return (
    <LeaveConfirmation preventLeaving={isDirty}>
      <Dialog
        face={DialogFace.DEFAULT}
        id="risk-level-dialog"
        onClose={onClose}
        title={<SC.Title>Set Risk Level for {searchTerm}</SC.Title>}
        displayCloseConfirmation={isDirty}
        footerActions={[DialogFooterActions.SUBMIT, DialogFooterActions.CANCEL]}
        onSubmit={() => handleSubmit()}
        submitDisabled={config.value.riskLevel === 'unknown'}
      >
        <SC.Content>
          <Form config={config}>
            <FormRow>
              <DialogCol>
                <Field
                  name="riskLevel"
                  label="Select Risk Level"
                  input={Select}
                  inputProps={{
                    data: adverseMediaRiskLevels.filter((item) => item.value !== 'unknown'),
                    'data-qa': 'select-risk-level',
                    'options-data-qa': 'select-risk-level'
                  }}
                />
              </DialogCol>
            </FormRow>
            <FormRow>
              <DialogCol>
                <Field
                  name="notes"
                  label="Notes"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'select-risk-level-notes'
                  }}
                />
              </DialogCol>
            </FormRow>
          </Form>
        </SC.Content>
      </Dialog>
    </LeaveConfirmation>
  )
})
