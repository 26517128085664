import { FC, memo, useCallback, useMemo, useState } from 'react'

import { GetClientUserListResponseItem } from 'commons/types/contracts/api/bank/clientUser'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { Table } from 'ui/components/Table'

import { ClientUsersTableProps } from './ClientUsersTableProps'
import { columns } from './columns'
import { Filter, UserRelationshipsApplicationsDialog } from './components'

export const ClientUsersTable: FC<ClientUsersTableProps> = memo((props) => {
  const { data, isFetching, filterForm, setEditClientUser, setRemovingClientUser, sendInviteLink, clearHandler } = props

  const [showUserRelationships, setShowUserRelationships] = useState<GetClientUserListResponseItem | undefined>(
    undefined
  )

  const memoizedColumns = useMemo(
    () => columns(setShowUserRelationships, setEditClientUser, setRemovingClientUser, sendInviteLink),
    []
  )
  const dataSource = useMemo(() => (data?.clientUsers || []).map((item) => ({ item })), [data])
  const [expanded, setExpanded] = useState(false)
  const toggle = useCallback(() => setExpanded((expanded) => !expanded), [])

  const {
    config: { onChange, value }
  } = filterForm

  const { page, pageSize } = value

  return (
    <Layer rounded shadowed stretch>
      {!!showUserRelationships && (
        <UserRelationshipsApplicationsDialog
          user={showUserRelationships}
          onClose={() => setShowUserRelationships(undefined)}
        />
      )}
      <Filter
        expanded={expanded}
        onToggle={toggle}
        relationshipOptions={data?.relationshipOptions}
        form={filterForm}
        clearHandler={clearHandler}
      />
      <Table<GetClientUserListResponseItem>
        dataQa={TableDataQa.BP_CLIENT_USERS_LIST}
        dataSource={dataSource}
        loading={isFetching}
        columns={memoizedColumns}
        pagination={{
          page,
          pageSize,
          total: data?.totalCount,
          onChange: (page: number, pageSize: number) => {
            onChange({ ...value, page, pageSize }, {})
          }
        }}
      />
    </Layer>
  )
})
