import { useState } from 'react'

import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'
import { Keys, WriteHook, useMutationManager, useStateManager } from 'state-manager'

import { ContactMetadata } from '../types/contact'

const request = (opts: ContactMetadata & { isCreating: boolean }) =>
  opts.isCreating
    ? Request.post(Request.urls.bank.contactCompanyConnection, opts)
    : Request.put(Request.urls.bank.contactCompanyConnection, opts)

const useUpdateMetadata: WriteHook<ContactMetadata & { isCreating: boolean }, ContactMetadata> = (opts) =>
  useMutationManager<ContactMetadata & { isCreating: boolean }, ContactMetadata>(request, opts)

export const useSaveContactMetadata = (onSuccess?: () => void) => {
  const sm = useStateManager()
  const [isSuccess, setIsSuccess] = useState(false)

  const { routine: saveMetadata } = useUpdateMetadata({
    onSuccess: async (opts) => {
      setIsSuccess(true)
      if (opts.companyId) {
        sm.invalidate([
          'CompanyDetailsContactsList',
          'list',
          {
            relationshipID: opts.companyId,
            contactsLimit: undefined,
            contactsOffset: undefined,
            contactsType: 'all'
          }
        ])
        sm.invalidate(Keys.CompanyDetails.WebPage(opts.companyId))
      }
      onSuccess && onSuccess()
    },
    onError: (error) => {
      handlerServerError(error)
    }
  })

  return {
    saveMetadata,
    isSuccess
  }
}
