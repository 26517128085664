import { FC, memo } from 'react'

import { DateTime } from 'ui/components'
import { IconSize } from 'ui/components/Icon'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { BusinessTypeLabel, EntityTypeLabel } from 'ui/components/Labels'
import { TextMask } from 'ui/components/TextMask'

import { AccountInformationProps } from './AccountInformationProps'

import { Column, Columns, EINInfo } from './styles'

export const AccountInformation: FC<AccountInformationProps> = memo((props) => {
  const { businessType, entityType, stateFounded, dateFounded, licenseCount, eINLabel, ein } = props

  return (
    <InfoSet legend="Account Details" direction={InfoSetDirection.ROW}>
      <Columns>
        <Column>
          <Info label="Business Type">
            <BusinessTypeLabel name={businessType} />
          </Info>
          <Info label="Entity Type">
            <EntityTypeLabel name={entityType || ''} />
          </Info>
          <Info label="State of Incorporation or Organization">{stateFounded}</Info>
        </Column>
        <Column>
          <Info label="Primary Account Opening Date">
            <DateTime date={dateFounded || ''} />
          </Info>
          <EINInfo $hasValue={!!ein} label={`${eINLabel} #`}>
            {ein ? <TextMask text={ein} iconSize={IconSize.XS} valueType="EIN" /> : '---'}
          </EINInfo>
          <Info label="Number of Licenses">{licenseCount}</Info>
        </Column>
      </Columns>
    </InfoSet>
  )
})
