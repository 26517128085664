export type OtherActivityInfo = {
  total: number
  cashWithdrawals: number
  checksDebits: number
  achDebits: number
  debitCardsCredits: number
  debitCardsDebits: number
  transferCredits: number
  transferDebits: number
  wiresIncoming: number
  wiresOutgoing: number
  sweepsIncoming: number
  sweepsOutgoing: number
  bankFees: number
  moneyOrdersPurchased: number
  cashiersChecksPurchased: number
  cbtCreditCardCharges: number
  cbtCreditCardPayments: number
}
