import { FC, memo } from 'react'

import { useGetBankName } from 'commons/hooks/bank/organisation'
import { BankName } from 'commons/types/enums'
import { DatePicker } from 'ui/components/DatePicker'
import { FieldSet } from 'ui/components/FieldSet'
import { Validators } from 'ui/components/Form'
import { Hr } from 'ui/components/Hr'
import { EINInput } from 'ui/components/InputV2/EINInput'
import { BusinessTypeSelect, Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { AccountDetailsProps } from './AccountDetailsProps'

import * as SC from './styles'

export const AccountDetails: FC<AccountDetailsProps> = memo(({ isConsumerAccount, Field }) => {
  const bankName = useGetBankName()
  const isCBT = bankName.id === BankName.CBT
  const isBison = bankName.id === BankName.BISON
  const isShore = bankName.id === BankName.SHORE

  let EntityTypesList = getOptionsByListName(SelectListName.ENTITY_TYPE)

  if (isBison) {
    EntityTypesList = getOptionsByListName(SelectListName.ENTITY_TYPE_BISON)
  } else if (isShore) {
    EntityTypesList = getOptionsByListName(SelectListName.ENTITY_TYPE_SHORE)
  }

  if (!bankName.id) {
    return null
  }

  const leftSideFields = [
    <Field
      required
      name="businessType"
      label="Business Type"
      input={BusinessTypeSelect}
      inputProps={{
        bankName: bankName.id,
        'data-qa': 'Business Type',
        'options-data-qa': 'input options'
      }}
      validator={Validators.required.field}
    />,
    <Field
      required
      name="entityType"
      label="Entity Type"
      input={Select}
      inputProps={{
        'data-qa': 'Entity Type',
        'options-data-qa': 'input options',
        data: EntityTypesList
      }}
      validator={Validators.required.field}
    />,
    <Field
      required
      name="stateFounded"
      label="State of Organization"
      input={Select}
      inputProps={{
        'data-qa': 'State of Organization',
        'options-data-qa': 'input options',
        data: getOptionsByListName(SelectListName.STATE_OF_INCORPORATION)
      }}
      validator={Validators.required.field}
    />
  ]

  const rightSideFields = [
    <Field
      name="dateFounded"
      label="Primary Account Opening Date"
      input={DatePicker}
      inputProps={{
        'data-qa': 'Primary Account Opening Date'
      }}
    />,
    <Field
      name="ein"
      label={isCBT && isConsumerAccount ? 'EIN/TIN #' : 'EIN #'}
      input={EINInput}
      inputProps={{
        autoComplete: 'new-password',
        'data-lpignore': true,
        'data-qa': isCBT && isConsumerAccount ? 'EIN/TIN #' : 'EIN #'
      }}
      required
      validator={Validators.required.string}
    />
  ]

  return (
    <FieldSet legend="Account Details">
      <SC.RowsWrapper>
        {new Array(Math.max(leftSideFields.length, rightSideFields.length)).fill(0).map((_, field_index) => (
          <FormRow key={field_index}>
            <Col>{leftSideFields[field_index] || <div />}</Col>
            <Col>{rightSideFields[field_index] || <div />}</Col>
          </FormRow>
        ))}
      </SC.RowsWrapper>
      <Hr />
    </FieldSet>
  )
})
