import { FC, memo } from 'react'

import { Request } from 'commons/utils/request'

import { SingleSelectAutoSuggest } from '../SingleSelectAutoSuggest'
import { LicenseSelectProps } from './LicenseSelectProps'

const PLACEHOLDER = 'Start entering the license name'

export const LicenseSelect: FC<LicenseSelectProps> = memo((props) => {
  const { value, emptyOption, filters, withInitialValue, ...rest } = props

  const serverOptions = withInitialValue
    ? {
        filters,
        valueItemFilter: value ? { field: 'id', type: 'eq', value } : undefined,
        url: Request.urls.bank.licenseList,
        limit: 500,
        mapper: (item) => ({ label: item.name, value: item.id }),
        searchFields: ['name']
      }
    : {
        filters,
        url: Request.urls.bank.licenseSearch,
        requestSearchString: 'searchString',
        limit: 500,
        mapper: (item) => ({ label: `${item.name} (${item.license_number})`, value: item.id })
      }

  return (
    <SingleSelectAutoSuggest
      noFirstRequest
      placeholder={PLACEHOLDER}
      options={emptyOption ? [emptyOption] : []}
      serverOptions={serverOptions}
      value={value}
      {...rest}
    />
  )
})
