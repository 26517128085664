import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { Toast, handleFormSubmissionError } from 'ui/components'
import { DocumentsPaths } from '~bank-documents/constants'
import { ManageDocumentPage } from '~bank-documents/routes/commonPages/ManageDocumentPage'
import { useSaveDocument } from '~bank-documents/service/saveDocument'
import { DocumentEditWebPageResponse, ManageDocumentFormType } from '~bank-documents/types/documentEdit'
import { mapManageDocumentRequest } from '~bank-documents/utils/mapManageDocumentRequest'

import { useDeleteDocumentConfirmation } from '../../hooks/useDeleteDocumentConfirmation'
import { bankDocumentMessages as m } from '../../messages/bankDocumentMessages'

const routeApi = getRouteApi(DocumentsPaths.EDIT)

const EditDocument: FC = memo(() => {
  const navigate = useNavigate()

  const documentId = routeApi.useParams({ select: (s) => s.documentId })

  const webPageData: DocumentEditWebPageResponse = routeApi.useLoaderData({
    select: (s) => s
  })

  const { form, ...appearanceInfo } = webPageData

  const formApi = useForm({
    defaultValues: new ManageDocumentFormType(false, form),
    onSubmit: async ({ value }) => await saveDocument({ id: Number(documentId), ...mapManageDocumentRequest(value) })
  })

  const { routine: saveDocument } = useSaveDocument({
    onSuccess: async (_, response) => {
      await navigate({ to: DocumentsPaths.DETAILS, params: { documentId: response?.id } })
      Toast.successSmall('The document was successfully updated.')
    },
    onError: (error) => {
      handleFormSubmissionError<ManageDocumentFormType>(error, formApi)
    }
  })

  const documentName = form.name

  const { openDeleteDocumentConfirmation, DeleteConfirmation } = useDeleteDocumentConfirmation({
    onSuccess: () => navigate({ to: DocumentsPaths.LIST })
  })

  return (
    <>
      <DeleteConfirmation />
      <ManageDocumentPage
        form={formApi}
        appearanceInfo={appearanceInfo}
        isNew={false}
        panelTitle={m['bankDocuments.editForm.title']}
        pageSubtitle={documentName}
        onDocumentDelete={() => openDeleteDocumentConfirmation({ id: documentId, name: documentName })}
      />
    </>
  )
})

export default EditDocument
