import { getRouteApi } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { Table } from 'ui/components'
import { IconSize } from 'ui/components/Icon'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'
import { AnnualReviewStatusLabel } from '~bank-annual-review/components/AnnualReviewStatusLabel'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { AnnualReviewDetails, SalesDepositItem } from '~bank-annual-review/types/AnnualReviewDetails'

import { columns } from './columns'
import { Actions } from './components/Actions'

import { s } from './styles'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

export const SummaryTables = (): JSX.Element => {
  const salesDeposits = routeApi.useLoaderData({
    select: (s) => s.salesDeposits
  }) as AnnualReviewDetails['salesDeposits']

  return (
    <s.Wrapper>
      <PageSection title="Sales & Deposit Summary Tables" face={PageSectionFace.THIRD}>
        {salesDeposits && salesDeposits.length > 0 ? (
          salesDeposits.map((item: SalesDepositItem) => {
            const { bankAccountId, bankAccountNumber, status, quarterLabels, depositDetail, salesSummary } = item

            const title = (
              <s.BankAccountTitle>
                Bank Account $
                <TextMask
                  isBankAccount
                  text={bankAccountNumber}
                  link={`/bank-accounts/${bankAccountId}`}
                  iconSize={IconSize.XS}
                />
              </s.BankAccountTitle>
            )

            const mappedSalesSummary = salesSummary.map((el) => ({ item: el }))

            const mappedDepositDetails = depositDetail.map((el) => ({ item: el }))

            return (
              <section key={item.id}>
                <PageSection
                  face={PageSectionFace.PRIMARY}
                  title={title}
                  title-before={<AnnualReviewStatusLabel status={status} />}
                  actions={<Actions {...item} />}
                >
                  <Layer rounded shadowed>
                    <s.Title>Sales Summary</s.Title>
                    <Table<SalesDepositItem['salesSummary'][0]>
                      dataQa={TableDataQa.BP_ANNUAL_REVIEW_DETAILS_SALES_SUMMARY}
                      columns={columns(quarterLabels)}
                      dataSource={mappedSalesSummary}
                    />
                    <s.Title>Deposit Detail</s.Title>
                    <Table<SalesDepositItem['depositDetail'][0]>
                      dataQa={TableDataQa.BP_ANNUAL_REVIEW_DETAILS_DEPOSIT_DETAIL}
                      columns={columns(quarterLabels)}
                      dataSource={mappedDepositDetails}
                    />
                  </Layer>
                </PageSection>
              </section>
            )
          })
        ) : (
          <s.Text>Nothing to show</s.Text>
        )}
      </PageSection>
    </s.Wrapper>
  )
}
