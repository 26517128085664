import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Validators } from 'ui/components/Form'
import { EmailInput, TextInput, USPhoneInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { ClientPortalInviteDialogProps } from './ClientPortalInviteDialogProps'
import { useClientPortalInviteDialog } from './useClientPortalInviteDialog'

import * as SC from './styles'

export const ClientPortalInviteDialog: FC<ClientPortalInviteDialogProps> = memo((props) => {
  const { companyName, onClose, visible, companyId, onSubmit, isPending } = props
  const { config, Field, formSubmitHandler } = useClientPortalInviteDialog(companyId, onSubmit)

  if (!visible) {
    return null
  }

  return (
    <Dialog
      id="invite-dialog"
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={`Send invite for ${companyName || '...'}`}
    >
      <SC.Inner>
        <Form config={config}>
          <FormRow>
            <Col>
              <SC.CompanyName label="Company">{companyName}</SC.CompanyName>
            </Col>
            <Col>
              <Field
                required
                name="email"
                input={EmailInput}
                label="Admin email"
                inputProps={{}}
                validator={Validators.required.string}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required
                name="firstName"
                input={TextInput}
                label="First Name"
                inputProps={{}}
                validator={Validators.required.string}
              />
            </Col>
            <Col>
              <Field
                required
                name="lastName"
                input={TextInput}
                label="Last Name"
                inputProps={{}}
                validator={Validators.required.string}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field name="phone" input={USPhoneInput} label="Phone" inputProps={{}} />
            </Col>
          </FormRow>
        </Form>
        <ButtonGroup margin="small">
          <Button face="positive" onClick={formSubmitHandler} disabled={isPending}>
            Send
          </Button>
          <Button face="neutral" onClick={onClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </SC.Inner>
    </Dialog>
  )
})
