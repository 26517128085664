import { FC, memo } from 'react'

import { useGetBankName } from 'commons/hooks/bank/organisation'
import { BankName } from 'commons/types/enums'
import { CheckBox } from 'ui/components/CheckBox'
import { FieldSet } from 'ui/components/FieldSet'
import { Validators } from 'ui/components/Form'
import { Hr } from 'ui/components/Hr'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { TextInput, USPhoneInput } from 'ui/components/InputV2'
import { Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { Tooltip } from 'ui/components/Tooltip'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { CompaniesBeingHeld } from './CompaniesBeingHeld'
import { RelationshipsProps } from './RelationshipsProps'

import * as SC from './styles'

export const Relationships: FC<RelationshipsProps> = memo((props) => {
  const bankName = useGetBankName()
  const isValliance = bankName.id === BankName.VALLIANCE
  const { isHolding, id, holdingId, Field } = props
  const holdingTooltipContent = isHolding
    ? 'Remove Subsidiaries to be a regular relationship company'
    : 'Add Subsidiaries to be a Holding Company'

  const leftSideFields = [
    <Field
      required
      name="name"
      label="Company Name"
      input={TextInput}
      inputProps={{
        'data-qa': 'Company Name'
      }}
      validator={Validators.required.string}
    />,
    <Field
      name="legalName"
      label="Entity Legal Name"
      input={TextInput}
      inputProps={{
        'data-qa': 'Entity Legal Name'
      }}
    />,
    <Field
      name="dba"
      label="DBA"
      input={TextInput}
      inputProps={{
        'data-qa': 'DBA'
      }}
    />,
    <Field
      required
      name="customerStatus"
      label="Customer Status"
      input={Select}
      inputProps={{
        'data-qa': 'Customer Status',
        'options-data-qa': 'input options',
        data: isValliance
          ? getOptionsByListName(SelectListName.CUSTOMER_STATUS_VALLIANCE)
          : getOptionsByListName(SelectListName.CUSTOMER_STATUS)
      }}
      validator={Validators.required.field}
    />
  ]

  const rightSideFields = [
    <SC.IsHoldingWrapper>
      <Field
        name="isHolding"
        label={
          <SC.HoldingLabel>
            <span>Holding Company?</span>

            <Tooltip id="holdingHint" type="warning" content={holdingTooltipContent}>
              <Icon name={IconName.EXCLAMATION} size={IconSize.S} />
            </Tooltip>
          </SC.HoldingLabel>
        }
        input={CheckBox}
        inputProps={{
          disabled: true,
          'data-qa': 'Holding Company?',
          checkBoxLabel: ''
        }}
      />
    </SC.IsHoldingWrapper>,
    <Field
      name="holdingGroupCompaniesIds"
      input={CompaniesBeingHeld}
      label="Subsidiaries"
      inputProps={{
        companyId: id,
        holdingId: holdingId,
        'data-qa': 'Holding Companies'
      }}
    />,
    <Field
      name="phone"
      label="Phone"
      input={USPhoneInput}
      inputProps={{
        'data-qa': 'Phone'
      }}
    />,
    <Field
      name="website"
      label="Website"
      input={TextInput}
      inputProps={{
        'data-qa': 'Website'
      }}
    />
  ]

  return (
    <FieldSet legend="Relationship Details">
      <SC.RowsWrapper>
        {new Array(Math.max(leftSideFields.length, rightSideFields.length)).fill(0).map((_, field_index) => (
          <FormRow key={field_index}>
            <Col>{leftSideFields[field_index] || <div />}</Col>
            <Col>{rightSideFields[field_index] || <div />}</Col>
          </FormRow>
        ))}
      </SC.RowsWrapper>
      <Hr />
    </FieldSet>
  )
})
