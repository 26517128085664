import { useCallback, useEffect, useState } from 'react'

import { notFound, useNavigate } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { useGetMRWebPage } from 'commons/hooks/bank/mr'
import { MRWebPagePayload, ReportMonthlyWebPage, SalesSourceItem } from 'commons/types/DTO/bank/reportWebPage'
import { BankSourceCreateFormValue } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { useConfirmation } from 'ui/hooks'

import { useSaveSourceAndFilesManage } from '../../hooks'

export const useBankSourceCreate = (MRId: number, licenseId: number) => {
  const navigate = useNavigate()
  const [webPageData, setWebPageData] = useState<ReportMonthlyWebPage | undefined>(undefined)
  const { config, Field } = useFormData<BankSourceCreateFormValue>({ initialValue: new BankSourceCreateFormValue() })

  const { onSubmit, isSubmitting, isFileUploading, files, addFiles, deleteFile } = useSaveSourceAndFilesManage(
    undefined,
    MRId,
    config
  )

  const originalChangeHandler = config.onChange

  const { data, isFetched } = useGetMRWebPage(new MRWebPagePayload(MRId))

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  const { open: openSubmitConfirm, Confirmation: SubmitConfirmation } = useConfirmation({
    message: (
      <>
        Are you sure you want to proceed?
        <br />
        Manual Sales Data Override will become the primary Sales Data source.
      </>
    ),
    onConfirm: () => onSubmit(),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: false
  })

  const onCancel = useCallback(async () => {
    await navigate({ to: '/mr/$id', params: { id: MRId.toString() } })
  }, [navigate, MRId])

  let sourceItem: SalesSourceItem | null = null

  if (data) {
    for (const bankAccount of data.bankAccounts) {
      if (bankAccount.showSalesRetail !== undefined) {
        const retailSourceItem = bankAccount.showSalesRetail.salesSourceItems.find((el) => el.licenseId == licenseId)
        if (retailSourceItem) {
          sourceItem = retailSourceItem
          break
        }
      }
      if (bankAccount.showSalesWholesale !== undefined) {
        const wholeSaleSourceItem = bankAccount.showSalesWholesale.salesSourceItems.find(
          (el) => el.licenseId == licenseId
        )
        if (wholeSaleSourceItem) {
          sourceItem = wholeSaleSourceItem
          break
        }
      }
    }
  }

  if (isFetched && !data) {
    throw notFound()
  }

  useEffect(() => {
    if (sourceItem) {
      originalChangeHandler(
        new BankSourceCreateFormValue(MRId, licenseId).fillFromMRSalesSourceItem(sourceItem),
        config.errors || {}
      )
    }
  }, [sourceItem])

  return {
    webPageData,
    licenseNumber: sourceItem?.licenseNumber,
    config,
    Field,
    onCancel,
    openSubmitConfirm,
    SubmitConfirmation,
    isSubmitting: isSubmitting || isFileUploading,
    files,
    addFiles,
    deleteFile
  }
}
