import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const TypeOther = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((state) => state.values.institutionActivitiesInfo[index].type)

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].typeOther` as 'institutionActivitiesInfo[0].typeOther'}
      validators={{
        onChange: ({ value }) => (type === 'Other' ? Validators.required.field(value) : undefined)
      }}
      children={(field) => (
        <FF
          required={type === 'Other'}
          field={field}
          label="Other"
          input={(p) => <TextInput {...p} disabled={type !== 'Other'} />}
        />
      )}
    />
  )
}
