import { FC, memo } from 'react'

import * as SC from './styles'

export const BlockDelimiter: FC<{ text: string; isBP?: boolean }> = memo(({ text, isBP }) => (
  <>
    {isBP ? (
      <SC.BlockDelimiterBPStyled>{text}</SC.BlockDelimiterBPStyled>
    ) : (
      <SC.BlockDelimiterCPStyled>{text}</SC.BlockDelimiterCPStyled>
    )}
  </>
))
