// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { KeyboardEvent, useCallback } from 'react'

import { useDebounce } from 'commons/hooks/common'

import { SelectContext } from '../types'

/* add searching */
export const useTyping = (
  context: SelectContext,
  options: {
    isPreferServerData: boolean
  }
) => {
  const { isPreferServerData } = options
  const { searchText, setSearchText, searchable, data, setData, setIsSearching } = context

  const searching = async () => {
    const filtered = data.filter((i) =>
      searchText.trim() ? (i.label.toLowerCase() || '').indexOf(searchText.toLowerCase()) !== -1 : true
    )
    setData(filtered)
    setIsSearching(false)
  }

  const debouncedSearching = useDebounce(() => searching(), 600)

  /* handle searching */
  const handleChange = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (!searchable) {
        return
      }
      if (event.target instanceof HTMLInputElement) {
        const searchText = event.target.value
        setSearchText(searchText)
        // filter static data
        if (!isPreferServerData) {
          setIsSearching(true)
          debouncedSearching()
        }
      }
    },
    [setSearchText, searchable, isPreferServerData, setIsSearching, debouncedSearching]
  )

  return {
    input: {
      onChange(event: KeyboardEvent<HTMLInputElement>) {
        handleChange(event)
      }
    }
  }
}
