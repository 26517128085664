import { Request } from 'commons/utils/request'
import { DateTime } from 'ui/components/DateTime'
import { DownloadLink } from 'ui/components/DownloadLink'
import { TableColumn } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'

const handleError = (message: string) => Toast.error('Error', message)

export const columns = () => (): TableColumn<any>[] => {
  const baseUrl = Request.urls.bank.parsedTransactionsDownload
  return [
    {
      title: 'File Name',
      align: 'left',
      render: ({ file_name }) => {
        const name = file_name.split('_id__')[1]
        return (
          <DownloadLink
            direct
            parametersForm={{ file_name }}
            baseUrl={baseUrl}
            name={file_name}
            handleError={handleError}
          >
            {name || file_name}
          </DownloadLink>
        )
      }
    },
    {
      title: 'Parsed Date',
      align: 'left',
      render: ({ last_modified_date }) => <DateTime date={last_modified_date} />
    }
  ]
}
