import { FormInputProps } from 'brief-form'

import { BaseInputOpts } from '../props'

type CurrencyInputOpts = BaseInputOpts & {
  allowDecimal?: boolean
  defaultEmptyValue?: null | ''
}

export type CurrencyInputProps = FormInputProps<number | string | null | undefined, CurrencyInputOpts>
