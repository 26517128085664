import { UploadedFile } from 'commons/types/DTO/bank/quarterlyReport/uploadedFile'
import { getBatchFileName } from 'commons/utils'
import { Request } from 'commons/utils/request'
import { TableButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { DownloadLink } from 'ui/components/DownloadLink'
import { TableColumn, TableColumnAlign } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'

const handleError = (message: string) => Toast.error('Error', message)
const baseUrl = Request.urls.bank.reportFileDownload

export const columns = (onDelete: (item: number) => void): TableColumn<UploadedFile>[] => [
  {
    title: 'File Name',
    align: TableColumnAlign.LEFT,
    render: (item) => {
      const fileName = item.fileName && getBatchFileName(item.fileName)

      return (
        <DownloadLink
          direct
          parametersForm={{ reportFileId: item.id, attachment: true }}
          baseUrl={baseUrl}
          name={fileName}
          handleError={handleError}
          method="put"
        >
          {fileName}
        </DownloadLink>
      )
    }
  },
  {
    title: 'Uploaded Date',
    align: TableColumnAlign.LEFT,
    render: ({ uploadedDate }) => <DateTime date={uploadedDate} withTime />
  },
  {
    title: 'Action(s)',
    align: TableColumnAlign.RIGHT,
    render: (item) =>
      item.enableDelete ? (
        <TableButton face="neutral" onClick={() => onDelete(item.id)}>
          Delete
        </TableButton>
      ) : (
        <></>
      )
  }
]
