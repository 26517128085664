import { FC, memo, useCallback, useState } from 'react'

import { useDetachContactAndCompany } from '../../hooks'
import { ContactMetadataManage } from '../ContactMetadataManage/ContactMetadataManage'
import { ContactCompaniesProps } from './ContactCompaniesProps'
import { ContactCompaniesTable } from './components'

const NO_CHOSEN_META = -1
const NEW_META = 0

export const ContactCompanies: FC<ContactCompaniesProps> = memo((props) => {
  const { data, invalidate } = props
  const {
    form: { id },
    firstName,
    lastName,
    relatedCompanies
  } = data
  const [companyId, setCompanyId] = useState(NO_CHOSEN_META)
  const { detachContactAndCompany } = useDetachContactAndCompany(invalidate)

  const onAddContactMetaData = useCallback(() => {
    setCompanyId(NEW_META)
  }, [setCompanyId])

  const onEditContactMetaData = useCallback(
    (companyId: number) => {
      setCompanyId(companyId)
    },
    [setCompanyId]
  )

  const onCloseContactMetaData = useCallback(() => {
    setCompanyId(NO_CHOSEN_META)
  }, [setCompanyId])

  return (
    <>
      {!!data && companyId !== NO_CHOSEN_META && (
        <ContactMetadataManage
          companyId={companyId}
          onClose={onCloseContactMetaData}
          contactId={id}
          contactName={`${firstName} ${lastName}`}
          onSuccess={() => {
            invalidate()
            onCloseContactMetaData()
          }}
          isContactCreateFromRelationships={companyId === 0}
        />
      )}
      <ContactCompaniesTable
        onAdd={onAddContactMetaData}
        onEdit={onEditContactMetaData}
        onDetach={detachContactAndCompany}
        relatedCompanies={relatedCompanies}
        contactId={id}
      />
    </>
  )
})
