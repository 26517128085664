import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { typesOfFinancialInstitution } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

const TypeRenderer = ({ field }: { field: FieldApi<SarEdit, 'institutionContactInfo.type'> }) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (field.state.value === 'Other') {
      formApi.setFieldMeta('institutionContactInfo.typeOther', (typeOtherMeta) => ({
        ...typeOtherMeta,
        errors: [],
        errorMap: {}
      }))
    }
  }, [field.state.value, formApi])

  return (
    <FF
      required
      field={field}
      label="79. Type of financial institution"
      input={(p) => (
        <Select
          {...p}
          data={typesOfFinancialInstitution}
          data-qa="79. Type of financial institution"
          options-data-qa="79. Type of financial institution option"
        />
      )}
    />
  )
}

export const Type = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.type"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <TypeRenderer field={field} />}
    />
  )
}
