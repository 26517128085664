import { getRouteApi } from '@tanstack/react-router'
import { DatePicker, FF } from 'ui/components'
import { HeadingTitle } from 'ui/components/HeadingTitle'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'
import { AnnualReviewStatusLabel } from '~bank-annual-review/components/AnnualReviewStatusLabel'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { AnnualReviewEditMode } from '~bank-annual-review/types/Manage'

import { useAnnualReviewFormContext } from '../../context/ManageAnnualReviewContext'

export const Overview = () => {
  const { formApi } = useAnnualReviewFormContext()

  const loaderData = getRouteApi(AnnualReviewPaths.EDIT).useLoaderData() as AnnualReviewEditMode

  const {
    dba,
    financialsEndDate,
    financialsStartDate,
    initialApproveDate,
    legalName,
    relatedCompanies,
    relationshipName,
    status
  } = loaderData

  return (
    <>
      <HeadingTitle large={true}>Overview</HeadingTitle>
      <InfoSet direction={InfoSetDirection.ROW}>
        <Row>
          <Col>
            <Info label="Relationship Name">{relationshipName}</Info>
          </Col>
          <Col>
            <Info label="DBA (if applicable)">{dba}</Info>
          </Col>
        </Row>

        <Row>
          <Col>
            <Info label="Licensed Entity Legal Name">{legalName}</Info>
          </Col>
          <Col>
            <Info label="Related Companies">{relatedCompanies ? relatedCompanies.join(', ') : '———'}</Info>
          </Col>
        </Row>

        <Row>
          <Col>
            <Info label="Date of Initial Approval">{initialApproveDate}</Info>
          </Col>
          <Col>
            <Info label="Financials Period Start">{financialsStartDate || '---'}</Info>
          </Col>
        </Row>

        <Row>
          <Col>
            <Info label="Status">
              <AnnualReviewStatusLabel status={status} />
            </Info>
          </Col>
          <Col>
            <Info label="Financials Period End">{financialsEndDate || '---'}</Info>
          </Col>
        </Row>

        <Row>
          <Col />
          <Col>
            <formApi.Field
              name="lastARDate"
              validators={{
                onChangeListenTo: ['isCompleted'],
                onChange: ({ fieldApi }) => {
                  const formState = fieldApi?.form

                  if (
                    formState.state.values &&
                    formState.state.values.isCompleted &&
                    !formState.state.values.lastARDate
                  ) {
                    const element = document.querySelector(`[data-qa="lastARDate"]`)
                    element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
                    return 'This field is required for "completed" review'
                  }

                  return undefined
                }
              }}
              children={(lastARDateField) => (
                <FF
                  label="Date of Last Annual Review"
                  input={(props) => <DatePicker data-qa="lastARDate" {...props} />}
                  field={lastARDateField}
                />
              )}
            />
          </Col>
        </Row>
      </InfoSet>
    </>
  )
}
