import { Request } from 'commons/utils/request'
import { DateTime } from 'ui/components/DateTime'
import { DownloadLink } from 'ui/components/DownloadLink'
import { IconName } from 'ui/components/Icon'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { DocumentDetailsFile } from '~bank-documents/types/documentDetailsFile'

const handleError = (message: string) => Toast.error('Error', message)

const baseUrl = Request.urls.bank.documentFileDirectDownload

export const columns = (
  openFileEditDialog: (file: DocumentDetailsFile) => void,
  openDeleteConfirmation: (opts: { fileId: number }) => void
): TableColumn<DocumentDetailsFile>[] => [
  {
    title: 'Filename',
    key: 'name',
    render: ({ id, name: fileName }) => (
      <DownloadLink
        direct
        parametersForm={{ id }}
        baseUrl={baseUrl}
        name={fileName}
        handleError={handleError}
        children={fileName}
      />
    )
  },
  {
    align: 'center',
    title: 'Uploaded Date',
    render: ({ uploadedAt }) => <DateTime date={uploadedAt} withTime />
  },
  {
    align: 'center',
    title: 'Notes',
    key: 'notes',
    render: ({ id, notes }) => <span data-qa={`notes-in-table-${id}`}>{notes}</span>
  },
  {
    title: 'Actions',
    key: 'id',
    align: 'center',
    width: '50px',
    render: (file) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            behavior: MenuItemBehavior.BUTTON,
            onClick: () => openFileEditDialog(file)
          },
          {
            key: 2,
            title: 'Delete',
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            behavior: MenuItemBehavior.BUTTON,
            onClick: () => openDeleteConfirmation({ fileId: file.id })
          }
        ]}
      />
    )
  }
]
