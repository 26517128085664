import { memo } from 'react'

import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { FilterProps } from './FilterProps'
import * as SC from './style'

const Filter = memo((props: FilterProps) => {
  const {
    form: { isDirty, config, Field, Form },
    expanded,
    onToggle,
    statuses,
    creatorList,
    ownerList,
    onClean
  } = props

  return (
    <FilterPanel isDirty={isDirty} onReset={onClean} expanded={expanded} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <TableFilterRow>
            <SC.FieldBox>
              <Field
                name="status"
                label="Status"
                input={Select}
                inputProps={{
                  data: statuses ?? []
                }}
              />
            </SC.FieldBox>
            <SC.FieldBox>
              <Field
                name="applicationsCreatedByID"
                label="Created By"
                input={Select}
                inputProps={{
                  data: creatorList ?? [],
                  searchable: true
                }}
              />
            </SC.FieldBox>
            <SC.FieldBox>
              <Field
                name="applicationsCurrentOwnerID"
                label="Current Owner"
                input={Select}
                inputProps={{
                  data: ownerList ?? [],
                  searchable: true
                }}
              />
            </SC.FieldBox>
          </TableFilterRow>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})

export { Filter }
