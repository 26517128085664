import { memo, useMemo } from 'react'

import { ClientUploadedDocuments } from 'commons/types/DTO/bank/applications/manage/ClientUploadedDocuments'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { DataSourceItem, Table } from 'ui/components/Table'

import { DocumentsTableProps } from './DocumentsTableProps'
import { columns } from './columns'

export const DocumentsTable = memo((props: DocumentsTableProps) => {
  const { documents, company, readonly, onDeleteDocument, loading } = props

  const mappedDocumentsTable: DataSourceItem<ClientUploadedDocuments>[] = useMemo(
    () =>
      documents.map((el) => ({
        item: el
      })),
    [documents]
  )

  return (
    <Layer rounded shadowed stretch>
      <div id={`applicationDocumentsContainer${company.companyID}`} />
      <Table<ClientUploadedDocuments>
        dataQa={TableDataQa.BP_ONBOARDING_APPLICATION_DOCUMENTS_LIST}
        noDataMessage="No Documents Necessary"
        loading={loading}
        columns={columns(company, readonly, onDeleteDocument)}
        dataSource={mappedDocumentsTable}
      />
    </Layer>
  )
})
