import { FC, memo } from 'react'

import { mergeArrayToString } from 'commons/utils/mergeArrayToString'
import { UsPhoneLabel } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { Hr } from 'ui/components/Hr'
import { IconSize } from 'ui/components/Icon'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'

import { InformationProps } from './InformationProps'

import { Column, Columns } from './styles'

export const Information: FC<InformationProps> = memo((props) => {
  const { data } = props

  const {
    form: { id },
    firstName,
    birthdate,
    email,
    entityName,
    lastName,
    einSsn,
    mobilePhone,
    phone,
    street,
    city,
    state,
    zipCode,
    country
  } = data
  const detailsPath = `/contacts/${id}`
  const address = mergeArrayToString(', ', [street, city, state, zipCode, country])

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="Contact Information"
      actions={
        <Link to={`${detailsPath}/edit?returnUrl=${encodeURIComponent(detailsPath)}`}>
          <Button face="positive">Edit</Button>
        </Link>
      }
    >
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="Contact Details">
        <Columns>
          <Column>
            <Info label="First Name">{firstName}</Info>
            <Info label="Birthdate">{birthdate}</Info>
            <Info label="Email">{email}</Info>
            <Info label="Entity Name">{entityName}</Info>
          </Column>
          <Column>
            <Info label="Last Name">{lastName}</Info>
            <Info label="SSN/EIN">{<TextMask text={einSsn} iconSize={IconSize.XS} valueType="SSN" />}</Info>
            <Info label="Mobile">
              <UsPhoneLabel value={mobilePhone} />
            </Info>
            <Info label="Phone">
              <UsPhoneLabel value={phone} />
            </Info>
          </Column>
        </Columns>

        {'showBio' in data && (
          <Column>
            <Info label="Bio">{data.showBio}</Info>
          </Column>
        )}
      </InfoSet>
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="Address Information">
        <Column>
          <Info label="Address">{address}</Info>
        </Column>
      </InfoSet>
      <Hr />
    </PageSection>
  )
})
