import { FC, KeyboardEvent, memo, useCallback } from 'react'

import { ToggleProps } from './ToggleProps'

import * as SC from './styles'

export const Toggle: FC<ToggleProps> = memo((props) => {
  const {
    label,
    value,
    onChange,
    disabled,
    'data-qa': dataQa,
    showOnOffLabel = false,
    noMarginRight = false,
    ...rest
  } = props
  const enable = useCallback(() => (onChange ? onChange(true, undefined) : null), [onChange])
  const disable = useCallback(() => (onChange ? onChange(false, undefined) : null), [onChange])
  const onSwitch = useCallback(() => (onChange ? onChange(!value, undefined) : null), [onChange, value])

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      switch (event.code) {
        case 'ArrowRight': {
          enable()
          break
        }
        case 'ArrowLeft': {
          disable()
          break
        }
        case 'Enter':
        case 'Space': {
          onSwitch()
          break
        }
      }
    },
    [enable, disable, onSwitch]
  )

  const Label = () => {
    if (label) {
      return (
        <SC.Label $checked={!!value} $disabled={disabled} data-qa={dataQa ? `${dataQa}-label` : undefined}>
          {label}
        </SC.Label>
      )
    } else if (showOnOffLabel) {
      return (
        <SC.Label $checked={!!value} $disabled={disabled} data-qa={dataQa ? `${dataQa}-label` : undefined}>
          {value ? 'On' : 'Off'}
        </SC.Label>
      )
    }

    return null
  }

  return (
    <SC.Box
      tabIndex={0}
      onClick={disabled ? undefined : onSwitch}
      onKeyDown={disabled ? undefined : onKeyDown}
      $checked={!!value}
      $disabled={disabled}
      $noMarginRight={noMarginRight}
      {...rest}
    >
      <SC.Inner $disabled={disabled}>
        <SC.Switcher
          $checked={!!value}
          data-switcher={value}
          $disabled={!!disabled}
          $noMarginRight={noMarginRight}
          data-qa={dataQa}
        >
          <SC.Circle $checked={!!value} $disabled={disabled} data-circle={true} />
        </SC.Switcher>
        {Label()}
      </SC.Inner>
    </SC.Box>
  )
})
