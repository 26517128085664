import { memo } from 'react'

import SC from 'styled-components'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Icon, IconName } from 'ui/components/Icon'

interface Props {
  warnings: string | null
  onClose: () => void
}

//region Styles

const Title = SC.span`
  display: flex;
  align-items: center;
  
  > span {
    display: inline-block;
    margin-left: 10px;
    color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  }
`

const Content = SC.ol`
  padding: 0 50px;
  line-height: 2;
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
`

//endregion

export const Warnings = memo(({ warnings, onClose }: Props) => {
  if (!warnings) {
    return null
  }

  return (
    <Dialog
      face={DialogFace.DEFAULT}
      id="warnings-dialog"
      onClose={onClose}
      title={
        <Title>
          <Icon name={IconName.ERROR_RED} /> <span>Warning(s)</span>
        </Title>
      }
    >
      <Content>
        {warnings.split('\n').map((i: any) => (
          <li key={i}>{i}</li>
        ))}
      </Content>
    </Dialog>
  )
})
