import { ReadHook, useStateManager } from 'react-query-state-manager'

import { api } from './api'
import { InternalTransfersDetailsWebpage, InternalTransfersDetailsWebpagePayload } from './types'

const DETAILS_WEBPAGE_KEY_ROOT = 'INTERNAL_TRANSFER_DETAILS'
const getKey = (payload?: InternalTransfersDetailsWebpagePayload) => [DETAILS_WEBPAGE_KEY_ROOT, payload]

const useDetailsWebpage: ReadHook<InternalTransfersDetailsWebpage, InternalTransfersDetailsWebpagePayload> = (
  payload,
  opts
) =>
  useStateManager().use(getKey(payload), () => api.getDetailsWebpage(payload), {
    keepPreviousData: true,
    onSuccess: opts?.onSuccess,
    onError: opts?.onError
  })

export const hooks = {
  useDetailsWebpage,
  DETAILS_WEBPAGE_KEY_ROOT
}
