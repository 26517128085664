import { Request } from 'commons/utils/request'

import { ApplicationDocumentWebpage } from './types'

export const getApplicationDocumentWebPage = (id: number, historyPage: number, historySize: number) =>
  Request.post<ApplicationDocumentWebpage>(Request.urls.bank.applicationClientDocumentWebpage, {
    id,
    historyPage,
    historySize
  })

export const documentAccept = (id: number, notes: string) =>
  Request.post(Request.urls.bank.applicationClientDocumentAccept, {
    id,
    notes
  })

export const documentFileUpdate = (id: number, name: string) =>
  Request.put(Request.urls.bank.applicationClientDocumentFileName, {
    id,
    name
  })

export const documentReject = (id: number, notes: string) =>
  Request.post(Request.urls.bank.applicationClientDocumentReject, {
    id,
    notes
  })

export const documentFileDelete = (id: number) =>
  Request.delete(Request.urls.bank.applicationClientDocumentFile, { id })

export const documentFileUpload = (documentId: number, file: File) => {
  const form = new FormData()
  form.append('applicationClientDocumentId', documentId.toString())
  form.append('document', file)
  return Request.post(Request.urls.bank.applicationClientDocumentFileUpload, form)
}
