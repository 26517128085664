import { FC, memo, useMemo } from 'react'

import { COMPANIES_PATH } from 'commons/constants/routes'
import { CompanyWebPage } from 'commons/types/DTO/bank'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Layer, Table } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'
import { Page } from 'ui/components/Page'
import { Search } from 'ui/components/Search'

import { columns } from './columns'
import { Filter } from './components/Filter'
import { useRelationships } from './hooks'

import * as SC from './styles'

const CompaniesList: FC = memo(() => {
  const {
    onClear,
    filterForm,
    tableState,
    loading,
    webPageData,
    changePagination,
    onSearch,
    onSort,
    DeleteConfirmation,
    onDeleteCompany
  } = useRelationships()

  const mappedRelationships: DataSourceItem<CompanyWebPage>[] = useMemo(
    () => (webPageData?.relationships || []).map((el) => ({ item: el })),
    [webPageData?.relationships]
  )

  const relationshipsTableColumns = useMemo(() => columns(onDeleteCompany), [])

  return (
    <>
      <DeleteConfirmation />
      <Page
        title="Relationships"
        actions={
          <SC.ActionsBox>
            <Search value={filterForm.config.value.relationshipsSearchQuery} onSearch={onSearch} placeholder="Search" />
            {webPageData?.enableAddNew && (
              <Link to={`${COMPANIES_PATH}/create`}>
                <HeaderButton size="middle">Add New</HeaderButton>
              </Link>
            )}
          </SC.ActionsBox>
        }
      >
        <Layer rounded shadowed stretch>
          <Filter
            onClear={onClear}
            form={filterForm}
            expanded={!tableState.expanded}
            onToggle={tableState.onFilterToggle}
            relationshipsScopeValues={webPageData?.relationshipsScopeValues}
            relationshipsCustomerStatusValues={webPageData?.relationshipsCustomerStatusValues}
            relationshipsEntityTypeValues={webPageData?.relationshipsEntityTypeValues}
            relationshipsBusinessTypeValues={webPageData?.relationshipsBusinessTypeValues}
            relationshipsIsHoldingValueValues={webPageData?.relationshipsIsHoldingValueValues}
          />
          <Table<CompanyWebPage>
            dataQa={TableDataQa.BP_COMPANY_LIST}
            columns={relationshipsTableColumns}
            dataSource={mappedRelationships}
            loading={loading || !webPageData}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              total: webPageData?.relationshipsTotalCount || 0,
              onChange: changePagination
            }}
            sorting={{ order: tableState.order || [], onChange: (value) => onSort(value.direction, value.field) }}
          />
        </Layer>
      </Page>
    </>
  )
})

export default CompaniesList
