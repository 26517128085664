import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type NoKnownAccountsInvolvedProps = {
  index: number
}

export const NoKnownAccountsInvolved = ({ index }: NoKnownAccountsInvolvedProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index}].noKnownAccountsInvolved` as 'subjectsInfo[0].noKnownAccountsInvolved'}
      children={(field) => (
        <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="No known accounts involved" />} />
      )}
    />
  )
}
