import { useLayoutEffect } from 'react'

import { DialogUtils } from '../dialogUtils'

export const useEscPress = (callback: () => void) => {
  const onEscPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && !DialogUtils.isFileDialogOpened()) {
      e.preventDefault()
      callback()
    }
  }

  useLayoutEffect(() => {
    document.addEventListener('keyup', onEscPress, false)

    return () => {
      document.removeEventListener('keyup', onEscPress, false)
    }
  })
}
