import { memo, useCallback, useState } from 'react'

import { getRouteApi, useRouterState } from '@tanstack/react-router'
import { Request } from 'commons/utils/request'
import { Download } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'
import { useDelete, useGetSARDetails, useSave } from '~bank-bsa-reporting/hooks/sar'

import {
  EditPeriodDialog,
  FilingInstitutionContactInformation,
  FinancialInstitutionWhereActivityOccurred,
  FincenWarnings,
  Footer,
  GeneralInformation,
  History,
  Narrative,
  SubjectInformation,
  SuspiciousActivityInformation,
  TransactionsInformation,
  Warnings
} from './components'

const routeApi = getRouteApi('/bsa/sars/$id')

const SARDetails = memo(() => {
  const state = useRouterState()
  const navigate = routeApi.useNavigate()
  const params = routeApi.useParams()

  const { data, isFetching, historyTableConfig } = useGetSARDetails(+(params.id || 0))
  const { id = 0, attention = '', finCenWarnings = '', generalInfo } = data || {}
  const { transactionStartDate = '', transactionEndDate = '' } = generalInfo || {}
  const deleteMutation = useDelete()
  const saveMutation = useSave()

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to delete #${id} SAR?`,
    onConfirm: () =>
      deleteMutation.mutateAsync([id]).then(() => {
        Toast.successSmall(`SAR #${id} was successfully deleted.`)
        navigate({ to: '/bsa/sars' })
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const resolveWarnings = useCallback(() => {
    saveMutation.mutate({ id })
  }, [id, saveMutation])

  const [openEditPeriodDialog, setOpenEditPeriodDialog] = useState(false)
  const handleError = (message: string) => Toast.error('Error', message)

  return (
    <Page
      face={PageFace.SECONDARY}
      title={id ? `SAR #${id}` : '...'}
      subTitle="SAR"
      isPending={isFetching && !data}
      actions={
        <ButtonGroup margin="big">
          <IconButton onClick={startDeleting} icon={IconName.DELETE} face="neutral" data-qa="Delete SAR">
            Delete SAR
          </IconButton>
          <Download
            direct
            mimeType="application/vnd.ms-excel"
            name={`Transactions_${id}`}
            parametersForm={{
              sar_id: id
            }}
            baseUrl={Request.urls.bank.bsaSarTransactionsXls}
            handleError={handleError}
          >
            <HeaderButton size="middle" overColoredPad={true}>
              Download Transactions
            </HeaderButton>
          </Download>
        </ButtonGroup>
      }
      footer={<Footer search={state.location.search} data={data} />}
    >
      <Confirmation />
      {openEditPeriodDialog && (
        <EditPeriodDialog
          id={id}
          initialValue={{
            startDate: new Date(transactionStartDate),
            endDate: new Date(transactionEndDate)
          }}
          onClose={() => setOpenEditPeriodDialog(false)}
        />
      )}
      {!!data && (
        <>
          {attention.length > 0 && <Warnings attention={attention} onResolve={resolveWarnings} />}
          {finCenWarnings.length > 0 && <FincenWarnings warnings={finCenWarnings} />}
          <GeneralInformation openEditPeriodDialog={() => setOpenEditPeriodDialog(true)} data={data} />
          <FilingInstitutionContactInformation data={data} />
          <FinancialInstitutionWhereActivityOccurred data={data} />
          <SubjectInformation data={data} />
          <SuspiciousActivityInformation data={data} />
          <TransactionsInformation data={data} />
          <Narrative data={data} />
          <History data={data} config={historyTableConfig} />
        </>
      )}
    </Page>
  )
})

export default SARDetails
