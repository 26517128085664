import { FC, memo } from 'react'

import { Info } from 'ui/components/Info'
import { Row } from 'ui/themes/globalStyles'

import { BioProps } from './BioProps'

export const Bio: FC<BioProps> = memo(({ bio }) => (
  <Row>
    <Info label="Bio">{bio}</Info>
  </Row>
))
