import { useCallback, useEffect, useState } from 'react'

import { useLoaderData, useRouter } from '@tanstack/react-router'
import { BankApplicationPaths } from 'commons/constants/routes'
import { ApplicationWebPageDetail } from 'commons/types/DTO/bank'
import { Toast } from 'ui/components/Toast'

import { ApplicationDetailsLoaderReturn } from '../../types'
import useApplicationFinalization from './useApplicationFinalization'

type UseApplicationDetailsHookParams = {}

type UseApplicationDetailsHookReturn = {
  application: ApplicationWebPageDetail
  applicationId: number
  isShowBankAccountsDialog: boolean
  isFinalizing: boolean

  onFinalizeApplication: () => void
  onCancel: () => void
  onSubmit: () => void
}

type UseApplicationDetailsHook = (params: UseApplicationDetailsHookParams) => UseApplicationDetailsHookReturn

const useApplicationDetails: UseApplicationDetailsHook = () => {
  const router = useRouter()
  const loaderData = useLoaderData({ from: BankApplicationPaths.DETAILS }) as ApplicationDetailsLoaderReturn
  const { application, applicationId } = loaderData

  const [isShowBankAccountsDialog, setShowBankAccountsDialog] = useState<boolean>(false)

  const { routine: finalizeApplication, isLoading: isFinalizing } = useApplicationFinalization({
    onSuccess: async () => {
      Toast.success('Application has been finalized')
      setShowBankAccountsDialog(false)
      await router.invalidate()
    },
    onError: () => {
      Toast.error('Something went wrong')
    }
  })

  useEffect(() => {
    if (application && application.enableApplicationInProgressWarning) {
      Toast.default('RFI Pending', 'Application in progress')
    }
  }, [application])

  const handleCancel = useCallback(() => setShowBankAccountsDialog(false), [])

  const handleSubmit = useCallback(async () => {
    if (!applicationId) {
      return
    }
    await finalizeApplication({ applicationId })
  }, [finalizeApplication, applicationId])

  const handleFinalization = useCallback(() => setShowBankAccountsDialog(true), [])

  return {
    application,
    applicationId,
    isShowBankAccountsDialog,
    isFinalizing,

    onCancel: handleCancel,
    onFinalizeApplication: handleFinalization,
    onSubmit: handleSubmit
  }
}

export default useApplicationDetails
