import { useCallback, useMemo } from 'react'

import { useFormData } from 'brief-form'

import { ContactDetailsRequest } from '../../../../../../../../../types'
import { FilterProps } from '../Filter.types'

export const useFilter = (props: FilterProps) => {
  const { data, onChange } = props
  const { docFilter } = data.form

  const initialFilter: ContactDetailsRequest['docFilter'] = useMemo(
    () => ({
      alerts: docFilter.alerts,
      frequency: docFilter.frequency,
      internal: docFilter.internal,
      page: docFilter.page ?? 1,
      size: docFilter.size ?? 20,
      sortBy: docFilter.sortBy,
      sortDesc: docFilter.sortDesc
    }),
    []
  )

  const form = useFormData<ContactDetailsRequest['docFilter']>({
    initialValue: initialFilter,
    onFormChanged: onChange
  })
  const { isDirty, config, Field, set } = form

  const reset = useCallback(() => {
    set({ reset: true })
    onChange(initialFilter)
  }, [set, onChange, initialFilter])

  return {
    isDirty,
    config,
    Field,
    reset
  }
}
