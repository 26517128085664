import { FC, memo } from 'react'

import { Link } from '@tanstack/react-router'
import { IconName, IconSize } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { PanelFace } from 'ui/components/Panel'

import { BSABatchesTable, Filter, Footer } from './components'
import { CTRBatchesChosenItemsContextProvider } from './context/CTRBatchesChosenItemsContext'

import { s } from './styles'

const BatchList: FC = memo(() => {
  return (
    <CTRBatchesChosenItemsContextProvider>
      <Page
        face={PageFace.PRIMARY}
        title={
          <s.Title>
            <Link to="/bsa">BSA Reporting</Link>
            <s.Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
            CTR Batch List
          </s.Title>
        }
        footer={<Footer />}
      >
        <s.TablePanel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
          <Filter />
          <BSABatchesTable />
        </s.TablePanel>
      </Page>
    </CTRBatchesChosenItemsContextProvider>
  )
})

export default BatchList
