import { useCallback, useMemo } from 'react'

import { ElementProps } from '../types'

const mergeProps = (propsList: ElementProps[], elementKey: string): Record<string, unknown> => {
  const map = new Map<string, Array<(...args: unknown[]) => void>>()

  return {
    ...propsList
      .map((value) => (value ? value[elementKey] : null))
      .reduce((acc: Record<string, unknown>, props) => {
        if (!props) {
          return acc
        }

        Object.entries(props).forEach(([key, value]) => {
          if (key.indexOf('on') === 0) {
            if (!map.has(key)) {
              map.set(key, [])
            }

            if (typeof value === 'function') {
              map.get(key)?.push(value as (...args: unknown[]) => void)

              acc[key] = (...args: unknown[]) =>
                map
                  .get(key)
                  ?.map((fn) => fn(...args))
                  .find((val) => val !== undefined)
            }
          } else {
            acc[key] = value
          }
        })

        return acc
      }, {})
  }
}

export const useInteractions = (propsList: ElementProps[]) => {
  const deps = propsList

  const getReferenceProps = useCallback(
    () => mergeProps(propsList, 'reference'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  const getOptionProps = useCallback(
    () => mergeProps(propsList, 'option'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  const getInputProps = useCallback(
    () => mergeProps(propsList, 'input'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  return useMemo(
    () => ({ getReferenceProps, getOptionProps, getInputProps }),
    [getReferenceProps, getOptionProps, getInputProps]
  )
}
