import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { TextInput } from 'ui/components/InputV2'
import { Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { LicenseAddressFormProps } from './LicenseAddressFormProps'
import { useLicenseAddressForm } from './useLicenseAddressForm'

export const LicenseAddressForm: FC<LicenseAddressFormProps> = memo(
  ({ licenseIndex, companyIndex, value, onChange }) => {
    const { Field, config } = useLicenseAddressForm({ licenseIndex, companyIndex, formData: value, onChange })

    return (
      <Form config={config}>
        <InfoSet legend="Address Information">
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.STREET}
                label="Street Address"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Street Address',
                  maxLength: 75
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.CITY}
                label="City"
                input={TextInput}
                inputProps={{
                  'data-qa': 'City',
                  maxLength: 75
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.POSTAL_CODE}
                label="Zip/Postal Code"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Zip/Postal Code',
                  maxLength: 20
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.STATE}
                label="State/Province"
                input={Select}
                inputProps={{
                  'data-qa': 'State/Province',
                  'options-data-qa': 'State/Province',
                  data: getOptionsByListName(SelectListName.STATE_OF_INCORPORATION)
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
          </FormRow>
        </InfoSet>
      </Form>
    )
  }
)
