import { memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { QueryTable } from 'ui/components/QueryTable'

import { getOptionsFilters } from '../../getOptionsFilters'
import { TableProps } from './TableProps'
import { columns } from './columns'
import { FilterForm } from './components'
import { dataSourceSelector } from './dataSourceSelector'

export const Table = memo((props: TableProps) => {
  const {
    tableState,
    onItemSelect,
    onDelete,
    chosenItems,
    isAllItemsChosen,
    onShowWarnings,
    onReturnData,
    config,
    isDirty,
    reset,
    Field
  } = props

  return (
    <>
      <QueryTable
        name="SARs List"
        dataQa={TableDataQa.BP_SAR_LIST}
        dataSources={[
          {
            key: 'list',
            url: Request.urls.bank.sarList,
            options: (_: any, filter?: any) => ({
              _options: {
                offset: 0,
                limit: tableState.size,
                filters: getOptionsFilters(filter)
              }
            })
          },
          {
            key: 'companies',
            url: Request.urls.bank.companyList,
            options: (data) => {
              if (!data?.list?.records) {
                return {
                  _options: {
                    filters: []
                  }
                }
              }

              return {
                _options: {
                  filters: [
                    {
                      field: 'id',
                      type: 'in',
                      value: data.list.records.map((i: any) => i.company_id)
                    }
                  ]
                }
              }
            }
          }
        ]}
        columns={columns({
          onItemSelect,
          chosenItems,
          isAllItemsChosen,
          onDelete,
          onShowWarnings,
          filter: config.value
        })}
        dataSourceSelector={dataSourceSelector}
        sorting={{
          order: tableState.order as any,
          onChange: tableState.onOrderChange
        }}
        pagination={{
          page: tableState.page,
          size: tableState.size,
          onChange: tableState.onPageChange
        }}
        filter={{
          value: config.value,
          component: <FilterForm isDirty={isDirty} reset={reset} Field={Field} config={config} />
        }}
        onReturnData={onReturnData}
      />
    </>
  )
})
