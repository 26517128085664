import { FC } from 'react'

import { Button } from 'ui/components/Buttons'

export const FinalizeApplication: FC<{ isFinalizing: boolean; onFinalizeApplication: () => void }> = ({
  isFinalizing,
  onFinalizeApplication
}) => (
  <Button face="positive" onClick={onFinalizeApplication} loading={isFinalizing}>
    Finalize Application
  </Button>
)
