import { DatePicker, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

export const BirthDate = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const isEntity = formApi.useStore((s) => s.values.subjectsInfo[index].isEntity)

  return (
    <formApi.Field
      name={`subjectsInfo[${index as 0}].birthdate`}
      children={(field) => (
        <FF
          field={field}
          label="19. Date of birth"
          input={(p) => <DatePicker {...p} disabled={isAllInfoUnavailable || isEntity} />}
        />
      )}
    />
  )
}
