import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'
import { emptySAR } from '~bank-bsa-reporting/constants'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../context'
import {
  Accountant,
  ActionDate,
  Agent,
  Appraiser,
  Attorney,
  Borrower,
  Customer,
  Director,
  Employee,
  NoRelationship,
  Officer,
  Other,
  OtherDesk,
  Owner,
  RelationshipStatus,
  TIN
} from './fields'

import { s } from './styles'

export const RelationshipsInfo = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <>
      <FormRow>
        <Col2x>
          <h5>24. Relationship of the subject to an institution listed in Part III or IV</h5>
        </Col2x>
      </FormRow>
      <formApi.Field
        mode="array"
        name={`subjectsInfo[${index as 0}].relationshipsInfo`}
        children={(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {(field.state.value || []).map((_, index2) => (
                <RelationshipsInfoItem index1={index} index2={index2} parentField={field} key={`${index}-${index2}`} />
              ))}

              <s.AddButton>
                <IconButton
                  icon={IconName.ADD_GREEN}
                  onClick={() => field.pushValue(emptySAR.subjectsInfo[0].relationshipsInfo[0])}
                />
              </s.AddButton>
            </>
          )
        }}
      />
    </>
  )
}

const RelationshipsInfoItem = ({
  index1,
  index2,
  parentField
}: {
  index1: number
  index2: number
  parentField: FieldApi<SarEdit, 'subjectsInfo[0].relationshipsInfo'>
}) => {
  const { formApi } = useSARFormContext()
  const other = formApi.useStore((s) => s.values.subjectsInfo[index1].relationshipsInfo[index2].other)
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index2),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <FormRow>
        <Col>
          <TIN index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <s.CheckBoxWrapper>
            <Accountant index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Agent index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Appraiser index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Attorney index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Borrower index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Customer index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
        </Col>
        <Col>
          <s.CheckBoxWrapper>
            <Director index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Employee index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <NoRelationship index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Officer index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Owner index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.CheckBoxWrapper>
            <Other index1={index1} index2={index2} />
          </s.CheckBoxWrapper>
          <s.VisibilityBox $visible={other}>
            <OtherDesk index1={index1} index2={index2} />
          </s.VisibilityBox>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <RelationshipStatus index1={index1} index2={index2} />
        </Col>
        <Col>
          <ActionDate index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        {parentField.state.value.length > 1 && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
        )}
      </FormRow>
    </>
  )
}
