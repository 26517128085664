import { DateTime, TableColumn } from 'ui/components'

import { EmailHistoryReport } from '../../types'

export const columns = (): TableColumn<EmailHistoryReport>[] => [
  {
    title: 'Sent At',
    align: 'left',
    render: ({ sent_at }) => <DateTime withTime date={sent_at} />
  },
  {
    title: 'Email address',
    key: 'email',
    align: 'left'
  },
  {
    title: 'Subject',
    key: 'subject',
    align: 'left'
  },
  {
    title: 'Status',
    align: 'left',
    render: ({ status }) => <>{status[0].toUpperCase() + status.substring(1)}</>
  },
  {
    title: 'Bounce message',
    key: 'bounce_message',
    align: 'left'
  }
]
