import { FC, memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { QueryTable } from 'ui/components'

import { TableProps } from './TableProps'
import { columns } from './columns'
import { dataSourceSelector } from './dataSourceSelector'

export const Table: FC<TableProps> = memo((props) => {
  const {
    tableState: { filterForm }
  } = props

  return (
    <>
      <QueryTable
        name="Alerts"
        dataQa={TableDataQa.BP_REPORTS_EMAIL_HISTORY_REPORT}
        dataSources={[
          {
            key: 'list',
            url: Request.urls.bank.portalReportEmailHistory,
            options: (_: any) => ({
              date_start: filterForm.config.value.date_start,
              date_end: filterForm.config.value.date_end
            })
          }
        ]}
        columns={columns}
        dataSourceSelector={dataSourceSelector}
        form={filterForm}
      />
    </>
  )
})
