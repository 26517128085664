import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const ContactOffice = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.contactOffice"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="93. Contact office"
          field={field}
          input={(p) => <TextInput {...p} data-qa="93. Contact office" />}
        />
      )}
    />
  )
}
