import { FC, memo } from 'react'

import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { AddressInformationProps } from './AddressInformationProps'

export const AddressInformation: FC<AddressInformationProps> = memo(({ license }) => {
  const { street, city, state, postalCode } = license.licenseeAddress

  return (
    <InfoSet direction={InfoSetDirection.ROW} legend="Address Information">
      <Row>
        <Col>
          <Info label="Street">{street}</Info>
        </Col>
        <Col>
          <Info label="State/Province">{state}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Zip/Postal Code">{postalCode}</Info>
        </Col>
        <Col>
          <Info label="City">{city}</Info>
        </Col>
      </Row>
    </InfoSet>
  )
})
