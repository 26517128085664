import { getRouteApi } from '@tanstack/react-router'
import { Form, useFormData } from 'brief-form'
import { handlerServerError } from 'errors'
import {
  CheckBox,
  FieldSet,
  LeaveConfirmation,
  Page,
  PageFace,
  Panel,
  Select,
  TextInput,
  TextareaInput,
  Validators
} from 'ui/components'
import { BPLayout } from 'ui/components/BPLayout'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { Footer } from './Footer'
import { usePostApplicationClientDocument } from './hooks'
import { ApplicationDocumentCreateWebpage, ApplicationDocumentPayload } from './types'

import * as SC from './styles'

const routeApi = getRouteApi('/onboarding/applications/$applicationId/document/create')

const ApplicationDocumentCreate = () => {
  const navigate = routeApi.useNavigate()
  // @todo why type not working, look at this when we get worked router types
  const { webPage, applicationId }: { webPage: ApplicationDocumentCreateWebpage; applicationId: number } =
    routeApi.useLoaderData()

  const { config, Field, isDirty, validate } = useFormData({
    initialValue: new ApplicationDocumentPayload(applicationId)
  })

  const { routine: postApplicationClientDocument, isLoading: isSubmitting } = usePostApplicationClientDocument({
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      await navigate({ to: '/onboarding/applications/$applicationId', params: { applicationId } })
    }
  })

  const handleSubmit = async () => {
    if (!validate({ updateFields: true }).valid) {
      return
    }
    await postApplicationClientDocument(config.value)
  }

  const entityData =
    config.value.subjectType === 'Relationship'
      ? webPage.relationshipOptions
      : config.value.subjectType === 'License'
      ? webPage.licensesOptions
      : config.value.subjectType === 'Contact'
      ? webPage.contactsOptions
      : []

  return (
    <BPLayout>
      <LeaveConfirmation preventLeaving={isDirty && !isSubmitting}>
        <Page
          face={PageFace.SECONDARY}
          title="Document"
          subTitle="Add New"
          footer={<Footer applicationId={applicationId} isSubmitting={isSubmitting} onSubmit={handleSubmit} />}
        >
          <Panel rounded shadowed collapsible={false} title="Add Document Information">
            <SC.PanelBody>
              <FieldSet legend="Client Document Details">
                <Form config={config}>
                  <FormRow>
                    <Col>
                      <Field
                        name="name"
                        label="Document Name"
                        input={TextInput}
                        required
                        validator={Validators.required.field}
                        inputProps={{}}
                      />
                    </Col>
                    <Col>
                      <Field name="internal" label="Internal" input={CheckBox} inputProps={{}} />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name="subjectType"
                        label="Subject Type"
                        input={Select}
                        required
                        validator={Validators.required.field}
                        inputProps={{
                          data: [
                            {
                              label: 'Relationship',
                              value: 'Relationship'
                            },
                            {
                              label: 'License',
                              value: 'License'
                            },
                            {
                              label: 'Contact',
                              value: 'Contact'
                            }
                          ]
                        }}
                      />
                    </Col>
                    <Col>
                      {config.value.subjectType && (
                        <Field
                          name="applicationEntityUid"
                          label={config.value.subjectType}
                          input={Select}
                          required
                          validator={Validators.required.field}
                          inputProps={{
                            data: entityData
                          }}
                        />
                      )}
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field name="optional" label="Optional" input={CheckBox} inputProps={{}} />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col2x>
                      <Field name="notes" label="Notes" input={TextareaInput} inputProps={{}} />
                    </Col2x>
                  </FormRow>
                </Form>
              </FieldSet>
            </SC.PanelBody>
          </Panel>
        </Page>
      </LeaveConfirmation>
    </BPLayout>
  )
}

export default ApplicationDocumentCreate
