import { Suspense, lazy } from 'react'

import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

const NextCloudContainer = lazy(() => import('./routes/NextCloudContainer/NextCloudContainer'))

export const BankNextCloudWrapper = () => (
  <BPLayout>
    <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
      <NextCloudContainer />
    </Suspense>
  </BPLayout>
)
