import { extractFilenameFromPath } from 'commons/utils'
import { Request } from 'commons/utils/request'
import {
  DownloadLink,
  IconName,
  MenuItemBehavior,
  MenuItemTextFace,
  TableActions,
  TableColumn,
  Toast
} from 'ui/components'
import { InvoiceSubmittalDetails } from '~bank-invoices/types/Details'

export const columns = ({
  invoiceSubmittalId,
  setFileToDelete
}: {
  invoiceSubmittalId: number
  setFileToDelete: (fileKeyIndex: number, fileName: string) => void
}): TableColumn<InvoiceSubmittalDetails['files'][0]>[] => [
  {
    align: 'center',
    title: 'File Name',
    render: ({ name }) => <span data-qa={`file-${name}`}>{extractFilenameFromPath(name)}</span>
  },
  {
    align: 'center',
    title: 'Download',
    render: (item) => {
      const originalFileName = extractFilenameFromPath(item.name)
      return (
        <DownloadLink
          direct
          parametersForm={{
            fileKey: item.fileKeyDeprecated,
            fileKeyIndex: item.fileKeyIndex,
            invoiceSubmittalId
          }}
          baseUrl={Request.urls.bank.invoiceSubmittalFileDownload}
          name={originalFileName}
          handleError={(error) => Toast.error(error)}
        >
          Download
        </DownloadLink>
      )
    }
  },
  {
    align: 'center',
    width: '50px',
    title: 'Actions',
    render: (item) => (
      <TableActions
        menuContainerId="invoiceSubmittalTableMenuContainer"
        items={[
          {
            key: 1,
            title: 'Delete',
            iconName: IconName.DELETE,
            behavior: MenuItemBehavior.BUTTON,
            textFace: MenuItemTextFace.GREY,
            onClick: () => setFileToDelete(item.fileKeyIndex, extractFilenameFromPath(item.name))
          }
        ]}
      />
    )
  }
]
