import { FC, useState } from 'react'

import { CompaniesPath } from 'commons/constants/routes'
import { TextButton } from 'ui/components/Buttons'
import { Info } from 'ui/components/Info'
import { Link } from 'ui/components/Link'

import { RelationshipsDialog } from './components/RelationshipsDialog'

type GetRelatedCompany = {
  relatedCompanies?: Array<{ id: number; name: string }>
  asInfoBlock: boolean
}

const Wrapper = ({ asInfoBlock, isOneCompany, children }) => {
  if (!asInfoBlock) return <>{children}</>

  return <Info label={isOneCompany ? 'Relationship' : 'Related Companies'}>{children}</Info>
}

export const RelatedCompany: FC<GetRelatedCompany> = ({ relatedCompanies, asInfoBlock = true }) => {
  const [showRelatedCompanies, setShowRelatedCompanies] = useState(false)

  const isOneCompany = relatedCompanies?.length === 1

  if (!isOneCompany) {
    return (
      <>
        {showRelatedCompanies && (
          <RelationshipsDialog relationships={relatedCompanies} onClose={() => setShowRelatedCompanies(false)} />
        )}
        <Wrapper
          asInfoBlock={asInfoBlock}
          isOneCompany={isOneCompany}
          children={<TextButton onClick={() => setShowRelatedCompanies(true)}>OPEN LIST</TextButton>}
        />
      </>
    )
  } else {
    return (
      <Wrapper
        asInfoBlock={asInfoBlock}
        isOneCompany={isOneCompany}
        children={
          <Link
            to={CompaniesPath.DETAILS}
            params={{
              companyId: relatedCompanies[0].id
            }}
          >
            {relatedCompanies[0].name}
          </Link>
        }
      />
    )
  }
}
