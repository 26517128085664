import { FC } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi, useRouteContext } from '@tanstack/react-router'
import { Toast, handleFormSubmissionError } from 'ui/components'
import { DocumentsPaths } from '~bank-documents/constants'
import { useSaveDocumentPeriod } from '~bank-documents/service/periodEdit'
import { ManagePeriodForm, ManagePeriodResponse } from '~bank-documents/types/periodEdit'

import { ManagePeriodDialog } from '../commonPages/ManagePeriodDialog'

const EditPeriod: FC = () => {
  const { isDocumentDetailsPage } = useRouteContext({ strict: false })

  const routeApi = getRouteApi(
    isDocumentDetailsPage ? DocumentsPaths.DOCUMENT_DETAILS_EDIT_PERIOD : DocumentsPaths.EDIT_PERIOD_DETAILS
  )

  const navigate = routeApi.useNavigate()

  const periodId = routeApi.useParams({ select: (s) => s.periodId })
  const documentId = routeApi.useParams({ select: (s) => s.documentId })

  const webPageData: ManagePeriodResponse = routeApi.useLoaderData()

  const { form, ...appearanceInfo } = webPageData

  const formApi = useForm({
    defaultValues: new ManagePeriodForm(false, form),
    onSubmit: async ({ value }) => await savePeriod({ id: Number(periodId), ...value })
  })

  const onClose = () => {
    if (isDocumentDetailsPage) {
      navigate({ to: DocumentsPaths.DETAILS, params: { documentId }, resetScroll: false })
    } else {
      navigate({ to: DocumentsPaths.PERIOD_DETAILS, params: { documentId, periodId }, resetScroll: false })
    }
  }

  const { routine: savePeriod } = useSaveDocumentPeriod({
    onSuccess: async () => {
      Toast.successSmall('Period was successfully updated.')

      onClose()
    },
    onError: (error) => {
      handleFormSubmissionError<ManagePeriodForm>(error, formApi)
    }
  })

  return (
    <ManagePeriodDialog
      id={periodId}
      form={formApi}
      dialogTitle="Edit Document Period"
      appearanceInfo={appearanceInfo}
      onClose={onClose}
    />
  )
}

export default EditPeriod
