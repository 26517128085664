import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { Toast, handleFormSubmissionError } from 'ui/components'
import { DocumentsPaths } from '~bank-documents/constants'
import { useSaveDocument } from '~bank-documents/service/saveDocument'
import { ManageDocumentFormType } from '~bank-documents/types/documentEdit'

import { bankDocumentMessages as m } from '../../messages/bankDocumentMessages'
import { mapManageDocumentRequest } from '../../utils/mapManageDocumentRequest'
import { ManageDocumentPage } from '../commonPages/ManageDocumentPage'

const routeApi = getRouteApi('/documents/new')

const CreateDocument: FC = memo(() => {
  const navigate = useNavigate()
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    returnUrl: _,
    subjectType,
    subjectName,
    bankAccountId,
    companyId,
    contactId,
    licenseId
  } = routeApi.useSearch()

  const form = useForm({
    defaultValues: new ManageDocumentFormType(true, undefined, {
      type: subjectType,
      id: bankAccountId || companyId || contactId || licenseId
    }),
    onSubmit: async ({ value }) => await saveDocument(mapManageDocumentRequest(value))
  })

  const { routine: saveDocument } = useSaveDocument({
    onSuccess: async (_, response) => {
      await navigate({ to: DocumentsPaths.DETAILS, params: { documentId: response.id } })
      Toast.successSmall('Document was created.')
    },
    onError: (error) => {
      handleFormSubmissionError<ManageDocumentFormType>(error, form)
    }
  })

  const presetFields = {
    fieldsForDisabled: subjectType ? ['subjectType', subjectType] : [],
    subjectName: subjectName || ''
  }

  return (
    <ManageDocumentPage
      isNew={true}
      form={form}
      pageSubtitle={m['bankDocuments.createForm.pageTitle']}
      panelTitle={m['bankDocuments.createForm.title']}
      presetFields={presetFields}
    />
  )
})

export default CreateDocument
