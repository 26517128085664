import { useLoaderData } from '@tanstack/react-router'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { questionnaireDemoLabels } from '~bank-annual-review/constants/questionnaireLabels'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

export const QuestionnaireDemo = () => {
  const questionnaire = useLoaderData({
    from: AnnualReviewPaths.DETAILS,
    select: (s) => s?.showQuestionnaireDemo
  }) as AnnualReviewDetails['showQuestionnaireDemo']

  return (
    <>
      <PageSection title="Financials">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.financialsPeriodCollected}
              children={<>{questionnaire?.financialsPeriodCollected || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.financialsBusinessCondition}
              children={<>{questionnaire?.financialsBusinessCondition || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.financialsProfitabilityTrend}
              children={<>{questionnaire?.financialsProfitabilityTrend || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.financialsBecomingFailing}
              children={<>{questionnaire?.financialsBecomingFailing || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.financialsDepositsMatching}
              children={<>{questionnaire?.financialsDepositsMatching || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.financialsInvestorDebtholders}
              children={<>{questionnaire?.financialsInvestorDebtholders || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Transactions">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.transactionsTypes}
              children={<>{questionnaire?.transactionsTypes.join(', ') || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.transactionsVolProjected}
              children={
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <>{questionnaire?.transactionsVolProjected || '---'}</>
                  &nbsp;
                  {questionnaire?.transactionsVolProjected !== 'No' && (
                    <>({questionnaire?.transactionsVolProjectedExactly || '---'})</>
                  )}
                </div>
              }
            />
            <Q
              label={questionnaireDemoLabels.transactionsExpectExactly}
              children={<>{questionnaire?.transactionsExpectExactly || '---'}</>}
            />
            {questionnaire?.transactionsExpectExactly === 'Unknown' && (
              <Q
                label={questionnaireDemoLabels.transactionsExpectExplain}
                children={<>{questionnaire?.transactionsExpectExplain || '---'}</>}
              />
            )}
            <Q
              label={questionnaireDemoLabels.transactionsMatchTrans}
              children={<>{questionnaire?.transactionsMatchTrans || '---'}</>}
            />
            {questionnaire?.transactionsMatchTrans === 'No' && (
              <Q
                label={questionnaireDemoLabels.transactionsNotes}
                children={<>{questionnaire?.transactionsNotes || '---'}</>}
              />
            )}
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Covenants/Agreements">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.covenantComplying}
              children={<>{questionnaire?.covenantComplying || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.covenantNonComplianceDesc}
              children={<>{questionnaire?.covenantNonComplianceDesc || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.covenantTransparent}
              children={<>{questionnaire?.covenantTransparent || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Site Visits">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.siteVisitsFrequency}
              children={<>{questionnaire?.siteVisitsFrequency || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsLastVisitDate}
              children={<>{questionnaire?.siteVisitsLastVisitDate || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsComplyingRegulations}
              children={<>{questionnaire?.siteVisitsComplyingRegulations || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsViolationRectified}
              children={<>{questionnaire?.siteVisitsViolationRectified || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsScoresAcceptable}
              children={<>{questionnaire?.siteVisitsScoresAcceptable || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsScoresAcceptableExactly}
              children={<>{questionnaire?.siteVisitsScoresAcceptableExactly || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsShutdownRisk}
              children={<>{questionnaire?.siteVisitsShutdownRisk || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Ownership & Management">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.ownershipChanges}
              children={<>{questionnaire?.ownershipChanges || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.ownershipProvidedForms}
              children={<>{questionnaire?.ownershipProvidedForms || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.ownershipChangesApproved}
              children={<>{questionnaire?.ownershipChangesApproved || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.ownershipOFACTLOReviewed}
              children={<>{questionnaire?.ownershipOFACTLOReviewed || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.ownershipBadNews}
              children={<>{questionnaire?.ownershipBadNews || '---'}</>}
            />
            {questionnaire?.ownershipBadNews === 'Yes' && (
              <Q
                label={questionnaireDemoLabels.ownershipBadNewsDesc}
                children={<>{questionnaire?.ownershipBadNewsDesc || '---'}</>}
              />
            )}
            <Q
              label={questionnaireDemoLabels.ownershipBadNewsCleared}
              children={<>{questionnaire?.ownershipBadNewsCleared || '---'}</>}
            />
            {questionnaire?.ownershipBadNewsCleared === 'No' && (
              <Q
                label={questionnaireDemoLabels.ownershipBadNewsClearedExplain}
                children={<>{questionnaire?.ownershipBadNewsClearedExplain || '---'}</>}
              />
            )}
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Recommendations">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.recommendationsRecommended}
              children={<>{questionnaire?.recommendationsRecommended || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.recommendationsStatus}
              children={<>{questionnaire?.recommendationsStatus || '---'}</>}
            />
            <Q
              label={questionnaireDemoLabels.recommendationsChangeVisits}
              children={<>{questionnaire?.recommendationsChangeVisits || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
    </>
  )
}
