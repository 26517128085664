import { FC, MouseEventHandler, memo } from 'react'

import SC from 'styled-components'

import { getIconFile } from './getIconFile'
import { IconFace } from './iconFace'
import { IconName } from './iconName'
import { IconSize } from './iconSize'

export interface IconProps {
  name: IconName
  size?: IconSize | number
  face?: IconFace
  fill?: string
  color?: string
  stroke?: string
  onClick?: MouseEventHandler<HTMLElement>
  $bordered?: boolean
  'data-qa'?: string
}

const RawIcon: FC<IconProps> = memo(({ name, ...rest }) => {
  const file = getIconFile(name)
  return <i dangerouslySetInnerHTML={{ __html: file }} {...rest} />
})

const primaryColors: any = {
  [IconFace.DEFAULT]: '#b8b8b8',
  [IconFace.ACTIVE]: '#00c698',
  [IconFace.DANGER]: '#b63c3c'
}

const secondaryColors: any = {
  [IconFace.DEFAULT]: '#868686',
  [IconFace.ACTIVE]: '#006a51',
  [IconFace.DANGER]: '#722626'
}

const SVG = SC(RawIcon)<{
  fill?: string
  color?: string
  stroke?: string
  size?: IconSize | number
  $bordered?: boolean
  face?: IconFace
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: ${(p) => p.size || IconSize.M}px;
  height: ${(p) => p.size || IconSize.M}px;
  ${(p) =>
    p.$bordered &&
    `
  background-color: ${p.theme.uiKit.colors.global.grey.grey300};
  border-radius: 4px;
  padding: 3px;
  `}
  transition: border-radius .1s ease-in, background-color .1s ease-in, padding .1s ease-in;
  
  > svg {
    fill: ${(p) => p.fill || primaryColors[p.face || IconFace.DEFAULT]};
    color: ${(p) => p.color || secondaryColors[p.face || IconFace.DEFAULT]};
    ${(p) => (p.stroke ? `stroke: ${p.stroke};` : '')}
    margin: 0 auto;
    width: ${(p) => p.size || IconSize.M}px;
    height: ${(p) => p.size || IconSize.M}px;
  }
`

export const Icon: FC<IconProps> = memo((props) => <SVG {...props} />)
