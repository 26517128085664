import { COMPANIES_PATH } from 'commons/constants/routes'
import { QuarterlyAnalytics } from 'commons/types/DTO/bank/alerts/quarterlyAnalytics'
import { DateTime } from 'ui/components/DateTime'
import { TableColumn } from 'ui/components/Table'

import * as SC from './styles'

export const columns = (): TableColumn<QuarterlyAnalytics>[] => [
  {
    title: 'Period',
    align: 'center',
    render: (item) => <SC.DetailsLink to={`/qr/${item.id}`}>{item.period}</SC.DetailsLink>
  },
  {
    title: 'Status',
    align: 'center',
    render: ({ status, highlightStatus }) =>
      highlightStatus ? <SC.HighlightStatus>{status}</SC.HighlightStatus> : <>{status}</>
  },
  {
    title: 'Relationship',
    align: 'center',
    render: (item) => (
      <SC.DetailsLink to={`${COMPANIES_PATH}/${item.relationshipID}`}>{item.relationship}</SC.DetailsLink>
    )
  },
  {
    title: 'Last Modified Date',
    align: 'center',
    render: (item) => !!item && <DateTime date={item.lastModifiedDate} withTime />
  }
]
