import { FC, memo } from 'react'

import { CheckBoxProps } from './CheckBoxProps'

import * as SC from './styles'

export const CheckBox: FC<CheckBoxProps> = memo((props) => {
  const {
    value,
    onChange,
    required,
    checkBoxLabel,
    disabled = false,
    hint,
    readOnly = false,
    error,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fieldDataId,
    ...rest
  } = props

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) {
      return
    }
    onChange(event.target.checked)
    event.stopPropagation()
  }

  const isDisabled = readOnly || disabled

  return (
    <>
      <SC.CheckboxItemContainer
        disabled={isDisabled}
        $error={error}
        $readOnly={readOnly}
        {...rest}
        data-testid={'checkbox-container'}
      >
        <input
          type="checkbox"
          data-testid={'checkbox-input'}
          disabled={isDisabled}
          required={required}
          checked={value ?? false}
          readOnly={readOnly}
          onChange={changeHandler}
          onClick={(e) => {
            e.stopPropagation()
          }}
        />
        <SC.Checkmark className="checkmark" $error={error} $hint={hint} />
        <SC.LabelsContainer>
          <SC.Label $error={error}>{checkBoxLabel}</SC.Label>
          <SC.Hint $error={error} disabled={isDisabled} data-testid={'checkbox-hint'}>
            <span>{hint}</span>
          </SC.Hint>
        </SC.LabelsContainer>
      </SC.CheckboxItemContainer>
    </>
  )
})
