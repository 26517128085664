import { memo } from 'react'

import { Validators } from 'ui/components/Form'

import { IntegerInput } from './IntegerInput'
import { BaseInputProps } from './props'

export const BSAIdentifierInput = memo((props: BaseInputProps) => {
  return <IntegerInput {...props} validator={Validators.string.length(14)} />
})
