import { FC } from 'react'

import { HistoryChanges } from 'commons/types/DTO/bank/historyAudit'
import { HistoryChangeTypes } from 'commons/types/enums'

import ApplicationLink from './ApplicationLink'
import AssignedEntitiesChanged from './AssignedEntitiesChanged'
import { AssignedEntitiesChangedData } from './HistoryRecordDetails.types'
import StringListChanged from './StringListChanged'
import { HistoryCreateLabel, HistoryUpdateLabel } from './components'

import { SC } from './styles'

const ChangesItem: FC<{ element: HistoryChanges }> = ({ element }) => {
  // // https://helioscompliance.atlassian.net/browse/MP-8382
  const { id, name, after, before, entityType, entityTypeLabel, mustBeMasked, subject, from, to, value, warning } =
    element

  switch (element.type) {
    case HistoryChangeTypes.SINGLE_COMMENT:
      return <SC.MultiLinerRow>{element.text || ''}</SC.MultiLinerRow>
    case HistoryChangeTypes.APPLICATION_LINK:
      return <>{id && name && <ApplicationLink id={id} name={name} />}</>
    case HistoryChangeTypes.STRING_LIST_CHANGED:
      return <StringListChanged after={after as string[]} before={before as string[]} mustBeMasked={mustBeMasked} />
    case HistoryChangeTypes.ASSIGNED_ENTITIES_CHANGED:
      return (
        <>
          {entityType && entityTypeLabel && (
            <AssignedEntitiesChanged
              entityType={entityType}
              entityTypeLabel={entityTypeLabel}
              after={after as AssignedEntitiesChangedData[]}
              before={before as AssignedEntitiesChangedData[]}
            />
          )}
        </>
      )
    case HistoryChangeTypes.CHANGED:
      return <HistoryUpdateLabel subject={subject} from={from} to={to} mustBeMasked={mustBeMasked} />
    default:
      return <HistoryCreateLabel value={value} subject={subject} mustBeMasked={mustBeMasked} warning={warning} />
  }
}

export default ChangesItem
