import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const OtherDesk = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const isNoRelationship = formApi.useStore(
    (s) => s.values.subjectsInfo[index1]?.relationshipsInfo[index2]?.noRelationship
  )

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].otherDesc`}
      children={(field) => (
        <FF field={field} input={(p) => <TextInput {...p} disabled={isNoRelationship} />} errorPosition="right" />
      )}
    />
  )
}
