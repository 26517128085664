import { WriteHook, useMutationManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'
import { NewArFormValue } from '~bank-annual-review/types/NewArFormValue'

type Opts = NewArFormValue & {
  companyId: number
}

const addAnnualReview = (annualReview: Opts) =>
  Request.post<{ id: number }, Opts>(Request.urls.bank.annualReview, annualReview)

export const useAddAnnualReview: WriteHook<Opts, { id: number }> = (opts) => useMutationManager(addAnnualReview, opts)
