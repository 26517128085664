import { FF, TextareaInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const Notes = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="generalInfo.notes"
      children={(field) => (
        <FF field={field} label="Notes" input={(p) => <TextareaInput {...p} data-qa="General. Notes" />}></FF>
      )}
    />
  )
}
