import { useCallback, useEffect, useState } from 'react'

import { useNavigate, useRouter, useRouterState } from '@tanstack/react-router'
import { DocumentsPaths } from 'bank-documents/src/constants'
import { Request } from 'commons/utils/request'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { useDeleteDocument } from '../../../../hooks/useDeleteDocument'
import { DocumentListProps } from './DocumentListProps'
import { DocumentsTable } from './components/DocumentsTable'

import * as SC from './styles'

type documentForDelete = {
  id: number
  name: string
}

export const DocumentsList = ({ application, companies }: DocumentListProps) => {
  const router = useRouter()
  const state = useRouterState()
  const pathname = state.location.pathname
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const { deleteDocument } = useDeleteDocument()
  const [documentForDelete, setDocumentForDelete] = useState<documentForDelete | undefined>(undefined)

  const handleError = (message: string) => Toast.error('Error', message)

  const onDeleteDocument = useCallback(async () => {
    setLoading(true)
    try {
      await deleteDocument(application.applicationID, documentForDelete?.id || 0)
      Toast.success('Document has been deleted')
      setDocumentForDelete(undefined)

      router.invalidate()
    } catch (error) {
      Toast.error('A trouble was occurred while deleted')
    }
    setLoading(false)
  }, [application.applicationID, deleteDocument, documentForDelete?.id, router])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>{documentForDelete?.name}</i> document?
      </span>
    ),
    onConfirm: () => onDeleteDocument(),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  useEffect(() => {
    if (documentForDelete) {
      openDeleteConfirmation()
    }
  }, [documentForDelete, openDeleteConfirmation])

  return (
    <SC.Documents>
      <DeleteConfirmation />
      <SC.Header>
        Document Uploading
        <Button face="positive" onClick={() => navigate({ to: DocumentsPaths.NEW, search: { returnUrl: pathname } })}>
          Add New
        </Button>
      </SC.Header>
      <div>
        {companies.map((company, index) => {
          const documents = application.clientUploadedDocuments.filter((item) => item.companyID === company.companyID)

          const url = Request.urls.bank.applicationZipDocumentsDownload
          return (
            <SC.Company key={company.companyID}>
              <SC.CompanyName>
                Company #{index + 1} &quot;
                {company.companyName}
                &quot;:
              </SC.CompanyName>
              <DocumentsTable
                documents={documents}
                company={company}
                applicationId={application.applicationID}
                readonly={!application.enableClientDocumentsActions}
                onDeleteDocument={(document) => {
                  setDocumentForDelete(document)
                }}
                loading={loading}
              />
              {!!documents?.length && application.enableDownloadAllDocuments && (
                <SC.DownloadLinkBtn
                  direct
                  mimeType="application/zip"
                  name={`ClientDocuments_${application.infoCompanyName}`}
                  parametersForm={{
                    applicationId: application.applicationID,
                    companyId: company.companyID
                  }}
                  baseUrl={url}
                  handleError={handleError}
                >
                  Download All Client Documents
                </SC.DownloadLinkBtn>
              )}
            </SC.Company>
          )
        })}
      </div>
    </SC.Documents>
  )
}
