import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const IsEntity = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name={`subjectsInfo[${index as 0}].isEntity`}
      validators={{
        onChange: ({ value }) => {
          if (value) {
            formApi.setFieldValue(`subjectsInfo[${index as 0}].firstName`, '')
            formApi.setFieldValue(`subjectsInfo[${index as 0}].middleName`, '')
            formApi.setFieldValue(`subjectsInfo[${index as 0}].suffix`, '')
            formApi.setFieldValue(`subjectsInfo[${index as 0}].gender`, '')
            formApi.setFieldValue(`subjectsInfo[${index as 0}].birthdate`, null)
          }

          return undefined
        }
      }}
      children={(field) => (
        <FF field={field} label="3. Check" input={(p) => <CheckBox {...p} checkBoxLabel="If entity" />} />
      )}
    />
  )
}
