import { FC, memo, useCallback } from 'react'
import Dropzone from 'react-dropzone'

import { HeaderButton } from 'ui/components/Buttons'

import { UploadButtonProps } from './UploadButtonProps'

export const UploadButton: FC<UploadButtonProps> = memo((props) => {
  const { onUpload } = props

  const onUploadWrapper = useCallback((acceptedFiles) => onUpload(acceptedFiles), [onUpload])

  return (
    <Dropzone onDrop={onUploadWrapper}>
      {({ getRootProps, getInputProps }) => (
        <HeaderButton onClick={onUploadWrapper} {...getRootProps()}>
          Upload
          <input {...getInputProps()} />
        </HeaderButton>
      )}
    </Dropzone>
  )
})
