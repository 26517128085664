import { ComparisonInfo } from './ComparisonInfo'
import { DepositsInfo } from './DepositsInfo'
import { SalesInfo } from './SalesInfo'

export type BankAccount = {
  bankAccountFormattedNumber: string
  bankAccountId: number
  bankAccountNumber: string
  comparison: ComparisonInfo
  deposits: DepositsInfo
  showSalesRetail: SalesInfo
  showSalesWholesale: SalesInfo
  showWarnings: string[]
  enableDepositsExport: boolean
}
