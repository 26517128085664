import { FC, memo } from 'react'

import { UsPhoneLabel } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { CompanyInformationDetailsProps } from './CompanyInformationDetailsProps'

import * as SC from './styles'

export const CompanyInformationDetails: FC<CompanyInformationDetailsProps> = memo(({ value }) => (
  <>
    <SC.Title>Company Information</SC.Title>
    <InfoSet legend="Relationship Details">
      <Row>
        <Col>
          <Info label="Legal Entity Name">{value[OnboardingFormFieldNames.LEGAL_NAME]}</Info>
        </Col>
        <Col>
          <Info label="DBA">{value.DBA}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Phone">
            <UsPhoneLabel value={value.phone} />
          </Info>
        </Col>
        <Col>
          <Info label="Website">{value.website}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Description">{value.description}</Info>
        </Col>
      </Row>
    </InfoSet>
  </>
))
