import { FC, memo } from 'react'

import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components'
import { PageSection } from 'ui/components/Page'

import { CompanyHistoryProps } from './ContactHistory.types'

export const ContactHistory: FC<CompanyHistoryProps> = memo((props) => {
  const { initialCompanyHistory, initialCompanyHistoryTotalCount, config } = props
  const { filter, onFilterChange, isFetching } = config
  const { page, size } = filter

  return (
    <PageSection title="Contact History" anchor="history">
      <Layer rounded shadowed stretch>
        <HistoryTable
          dataQa={TableDataQa.BP_CONTACT_HISTORY}
          loading={isFetching}
          items={initialCompanyHistory}
          pagination={{
            page,
            pageSize: size,
            onChange: onFilterChange,
            total: initialCompanyHistoryTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
})
