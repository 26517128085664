import styled from 'styled-components'
import { Button } from 'ui/components/Buttons'
import { Hr } from 'ui/components/Hr'
import { Icon } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { TextMask } from 'ui/components/TextMask'

export const Box = styled.footer`
  background: #fff;
  height: 66px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 48px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
`

export const StyledIcon = styled(Icon)`
  margin-right: 12px;
`

export const IconButton = styled(Button)`
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  path {
    transition: fill 0.15s;
  }
  &:hover {
    path {
      fill: #fff;
    }
  }
`

export const PrevLink = styled(Link)`
  margin-right: auto;
`

export const CheckBoxes = styled.div`
  display: flex;

  > * {
    width: 200px;

    &:first-child {
      margin-right: 100px;
    }
  }
`

export const WrappedHr = styled(Hr)`
  margin-bottom: 21px;
`

export const InnerPanel = styled.div`
  padding: 30px 32px;
`

export const WrappedLink = styled(Link)`
  a {
    font-size: 14px;
  }
`
export const CheckOrRadio = styled.label`
  display: flex;
  align-items: center;
  padding-bottom: 23px;

  > input {
    margin-right: 8px;
  }
`

export const Label = styled.label`
  line-height: 28px;
`

export const TextMaskInline = styled(TextMask)`
  display: inline-flex;
  margin-left: 10px;
  vertical-align: middle;
  margin-bottom: 5px;
`

export const Totals = styled.div`
  margin-top: 40px;
`

export const PeriodEditRow = styled.div`
  display: flex;
  word-break: break-word;

  > i {
    margin-left: 16px;
    cursor: pointer;
  }
`

export const EditPeriodDialogInner = styled.div`
  display: flex;
  flex-direction: row;
  padding: 27px 42px 0px 42px;
  width: 700px;
`

export const AccountNumber = styled.div`
  display: flex;
  gap: 15px;
`
