import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type PostalCodeProps = {
  index1: number
  index2: number
}

export const PostalCode = ({ index1, index2 }: PostalCodeProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={
        `subjectsInfo[${index1}].addressesInfo[${index2}].postalCode` as 'subjectsInfo[0].addressesInfo[0].postalCode'
      }
      children={(field) => (
        <FF
          field={field}
          label="14. ZIP/Postal Code"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} maxLength={9} />}
        />
      )}
    />
  )
}
