import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { FilterComponentProps } from 'query-table'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { ClientReportFilterValue } from '../../../UserReportFilterValue'

import * as SC from './styles'

export const FilterForm: FC<FilterComponentProps<ClientReportFilterValue>> = memo((props) => {
  const {
    form: { isDirty, config, set, Field },
    onToggle,
    expanded
  } = props

  return (
    <FilterPanel expanded={expanded} isDirty={isDirty} onReset={() => set({ reset: true })} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.Row>
            <Field
              name="active"
              label="Status"
              input={Select}
              inputProps={{
                'data-qa': 'Active',
                'options-data-qa': 'Active options',
                data: [
                  { label: 'All Users', value: '' },
                  { label: 'Active', value: 'true' },
                  { label: 'Disabled', value: 'false' }
                ]
              }}
            />
          </SC.Row>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
