import { memo, useCallback, useEffect, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { COMPANIES_PATH } from 'commons/constants/routes'
import { ReportMonthlyWebPage } from 'commons/types/DTO/bank/reportWebPage'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { TextareaInput } from 'ui/components/InputV2'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { ResourcePath } from 'ui/temp/ResourcePath'
import { Col, Col2x, FormRow, Row } from 'ui/themes/globalStyles'
import { SaveNotesRequest } from '~bank-quarterly-report/types'

import * as SC from './styles'

interface Props {
  webPage: ReportMonthlyWebPage
  onSaveNotes: (data: SaveNotesRequest) => Promise<any>
}

export const GeneralInformation = memo(({ webPage, onSaveNotes }: Props) => {
  const { config, Field } = useFormData<{ notes: string }>({ initialValue: { notes: webPage.infoNotes } })
  const [submitting, setSubmitting] = useState(false)

  const onSaveWrapper = useCallback(async () => {
    try {
      setSubmitting(true)
      await onSaveNotes({ id: webPage.monthlyAnalyticsId, notes: config.value.notes })
    } finally {
      setSubmitting(false)
    }
  }, [onSaveNotes, config.value, setSubmitting, webPage.monthlyAnalyticsId])

  useEffect(() => {
    config.onChange({ notes: webPage.infoNotes }, config.errors || {})
  }, [webPage.infoNotes])

  return (
    <PageSection face={PageSectionFace.SECONDARY} title="General Information" anchor="general">
      <SC.WrappedHr thin />
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <Row>
          <Col>
            <Info label="Relationship Name">
              <ResourcePath
                finishedPath={`${COMPANIES_PATH}/${webPage.infoRelationshipId}`}
                name={webPage.infoRelationshipName}
                id={webPage.infoRelationshipId}
              />
            </Info>
          </Col>
          <Col>
            <Info label="Month">{webPage.infoMonth}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="Report Status">{webPage.infoStatus}</Info>
          </Col>
        </Row>
        {webPage.infoNotes !== undefined && (
          <Form config={config}>
            <FormRow>
              <Col2x>
                <Field name="notes" label="Notes" input={TextareaInput} inputProps={{}} />
              </Col2x>
            </FormRow>
            {webPage.enableSaveNotes && (
              <FormRow>
                <Col2x>
                  <ButtonGroup margin="small">
                    <Button onClick={onSaveWrapper} loading={submitting} face="positive">
                      Save Notes
                    </Button>
                  </ButtonGroup>
                </Col2x>
              </FormRow>
            )}
          </Form>
        )}
      </InfoSet>
    </PageSection>
  )
})
