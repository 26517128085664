import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const PaymentMechanisms = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="suspiciousActivityInfo.paymentMechanisms"
      children={(field) => (
        <FF
          field={field}
          // eslint-disable-next-line max-len
          label="46. Were any of the following instrument type(s)/payment mechanism(s) involved in the suspicious activity?"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'42. Cyber event'}
              direction={'vertical'}
              data={[
                { label: "a. Bank/Cashier's check", value: 'a' },
                { label: 'b. Foreign currency', value: 'b' },
                { label: 'c. Funds transfer', value: 'c' },
                { label: 'd. Gaming instruments', value: 'd' },
                { label: 'e. Government payment', value: 'e' },
                { label: 'f. Money orders', value: 'f' },
                { label: 'g. Personal/Business check', value: 'g' },
                { label: 'h. Travelers checks', value: 'h' },
                { label: 'i. U.S. Currency', value: 'i' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
