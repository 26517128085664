import { getAnnualReviewDetailUrl, getAnnualReviewEditUrl } from 'bank-annual-review/src/Routes/AnnualReview/route'
import { AnnualReviews } from 'commons/types/DTO/bank/alerts/annualReviews'
import { MenuIconHoverFace, MenuItemAlignment, MenuItemTextFace } from 'ui/components'
import { DateTime } from 'ui/components/DateTime'
import { IconName, Link } from 'ui/components/Link'
import { TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

export const columns = (): TableColumn<AnnualReviews>[] => [
  {
    title: 'Annual Review Number',
    align: 'center',
    render: (item) => (
      <Link to={getAnnualReviewDetailUrl({ id: item.id, companyId: item.companyID })}>{item.number}</Link>
    )
  },
  {
    title: 'Company',
    align: 'left',
    render: (item) => (
      <>
        <Link to="/relationships/$companyId" params={{ companyId: item.companyID }}>
          {item.company}
        </Link>
        <div>{item.companyPhone}</div>
        <div>{item.companyFax}</div>
      </>
    )
  },
  {
    title: 'Financials Date',
    align: 'center',
    key: 'financialsDate'
  },
  {
    title: 'Status',
    align: 'center',
    key: 'status'
  },
  {
    title: 'Date Completed',
    align: 'center',
    render: ({ dateCompleted }) => dateCompleted || '---'
  },
  {
    title: 'Created Date',
    align: 'center',
    render: ({ createdDate }) => createdDate && <DateTime date={createdDate} />
  },
  {
    title: 'Action',
    align: 'center',
    render: (item) => (
      <TableActions
        menuContainerId="companyArTableMenuContainer"
        items={[
          ...(item.enableNew
            ? [
                {
                  key: 1,
                  title: item.actionName.toUpperCase(),
                  textFace: MenuItemTextFace.GREY,
                  textAlign: MenuItemAlignment.CENTER,
                  url: getAnnualReviewEditUrl({ id: item.id, companyId: item.companyID })
                }
              ]
            : []),
          ...(item.enableApprove
            ? [
                {
                  key: 2,
                  title: 'APPROVE',
                  textFace: MenuItemTextFace.GREY,
                  textAlign: MenuItemAlignment.CENTER,
                  url: getAnnualReviewDetailUrl({ id: item.id, companyId: item.companyID })
                }
              ]
            : []),
          ...(item.enableEdit
            ? [
                {
                  key: 1,
                  title: 'EDIT',
                  iconName: IconName.EDIT,
                  iconHoverFace: MenuIconHoverFace.PRIMARY,
                  textFace: MenuItemTextFace.GREY,
                  url: getAnnualReviewEditUrl({ id: item.id, companyId: item.companyID })
                }
              ]
            : [])
        ]}
      />
    )
  }
]
