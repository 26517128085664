import { useEffect } from 'react'

import axios, { AxiosError, AxiosResponse } from 'axios'
import { PortalName } from 'commons/types/enums'
import { useHeartbeat, useLogoutAuth } from '~auth/hooks/user'
import { sendSecurityCodeUrls } from '~auth/service/mfa'

import { getReturnUrl } from '../helpers'

export const useApi401Interceptor = (portalName: PortalName) => {
  const { data: session } = useHeartbeat({ portalName })
  const { logout } = useLogoutAuth({ portalName: portalName })

  // observe 401 status of axios responses and
  // if it occurs invoke `logout`
  useEffect(() => {
    const api401Intercepter = axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        if (
          error?.response?.status === 401 &&
          !!session &&
          // dont logout on wrong mfa code when adding a new device
          error.config.url !== sendSecurityCodeUrls[portalName]
        ) {
          logout({ returnUrl: getReturnUrl(window.location), clientOnly: true })
        }
        return Promise.reject(error)
      }
    )
    return () => axios.interceptors.request.eject(api401Intercepter)
  }, [session, logout, portalName])
}
