import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'
import { ReviewTheDepositActivityOutStateProps } from './ReviewTheDepositActivityOutStateProps'

export const ReviewTheDepositActivityOutState = ({ formValue, Field }: ReviewTheDepositActivityOutStateProps) => (
  <>
    <SecondText>Review the deposit activity for out-of-state checks.</SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewTheDepositActivityOutState"
          label="Were there out-of-state checks deposited?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes – See comments',
                value: 'yes'
              },
              {
                label: 'All Transactions Ok',
                value: 'all_transactions'
              },
              {
                label: 'No Transactions of this Type',
                value: 'no_transactions'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewTheDepositActivityAddressed"
          label="If noted, how was the activity addressed?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'N/A',
                value: 'n/a'
              },
              {
                label: 'Transaction cleared with communication to customer during review',
                value: 'cleared'
              },
              {
                label: 'Transaction added to Annual Review questions',
                value: 'added'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={formValue.reviewTheDepositActivityOutState === 'yes'}
          name="reviewTheDepositActivityComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={formValue.reviewTheDepositActivityOutState === 'yes' ? Validators.required.field : undefined}
        />
      </Col2x>
    </FormRow>
  </>
)
