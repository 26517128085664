import { useCallback, useEffect, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { useGetMRWebPage } from 'commons/hooks/bank/mr'
import { ReportMonthlyWebPage } from 'commons/types/DTO/bank/reportWebPage'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { MR } from '~bank-quarterly-report/hooks'
import { DepositsCreateFormValue } from '~bank-quarterly-report/types'

export const useMRWebPageDepositsManage = (MRId: number, bankAccountId: number) => {
  const navigate = useNavigate()
  const [webPageData, setWebPageData] = useState<ReportMonthlyWebPage | undefined>(undefined)
  const { config, Field } = useFormData<DepositsCreateFormValue>({ initialValue: new DepositsCreateFormValue() })
  const originalChangeHandler = config.onChange

  const { data, isFetched, invalidate } = useGetMRWebPage({
    monthlyAnalyticsId: +MRId,
    uploadedFilesLimit: 0,
    uploadedFilesOffset: 0,
    uploadedFilesSortBy: '',
    uploadedFilesSortDesc: true
  })

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  const { routine: updateDeposits, isLoading: isSubmitting } = MR.useDepositsUpdate({
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      invalidate()
      Toast.successSmall('Deposits has been saved.')
    }
  })

  const onCancel = useCallback(() => {
    navigate({ to: '/mr/$id', params: { id: `${MRId}` } })
  }, [navigate, MRId])

  const onSubmit = useCallback(
    () => updateDeposits(config.value).then(() => navigate({ to: '/mr/$id', params: { id: `${MRId}` } })),
    [updateDeposits, config.value, navigate, MRId]
  )

  const bankAccount = webPageData ? webPageData.bankAccounts.find((el) => el.bankAccountId == bankAccountId) : undefined

  useEffect(() => {
    if (bankAccount) {
      originalChangeHandler(
        new DepositsCreateFormValue().fillInfo(
          bankAccount.deposits,
          bankAccountId,
          webPageData?.monthlyAnalyticsId ?? 0
        ),
        config.errors || {}
      )
    }
  }, [bankAccount])

  return {
    webPageData,
    bankAccount,
    config,
    Field,
    onCancel,
    onSubmit,
    isSubmitting
  }
}
