import { formatCurrencyCent } from 'commons/utils'

import { Answer } from '../Answer'
import { AtmsInformationProps } from './AtmsInformationProps'
import { Column, Columns, Header, Wrapper } from './style'

export const AtmsInformation = ({ items }: AtmsInformationProps) => (
  <Wrapper>
    {items.map((atm, index) => (
      <div key={index}>
        <Header>ATM #{index + 1}</Header>
        {atm.ATMAddress && <Answer vertical label={atm.ATMAddress.question} value={atm.ATMAddress.answer} />}
        <Columns>
          <Column>
            {atm.ATMMaxAmount && (
              <Answer label={atm.ATMMaxAmount.question} value={formatCurrencyCent(atm.ATMMaxAmount.answer)} />
            )}
          </Column>
          <Column>
            {atm.ATMAvgAmountReplenished && (
              <Answer
                label={atm.ATMAvgAmountReplenished.question}
                value={formatCurrencyCent(atm.ATMAvgAmountReplenished.answer)}
              />
            )}
          </Column>
        </Columns>
        {atm.ATMReplenishFrequency && (
          <Answer vertical label={atm.ATMReplenishFrequency.question} value={atm.ATMReplenishFrequency.answer} />
        )}
        <Answer label={atm.ATMFundsFromBank.question} value={atm.ATMFundsFromBank.answer ? 'Yes' : 'No'} />
        {!atm.ATMFundsFromBank.answer && atm.ATMFundsFromBankFalseDesc && (
          <Answer
            vertical
            label={atm.ATMFundsFromBankFalseDesc.question}
            value={atm.ATMFundsFromBankFalseDesc.answer}
          />
        )}
      </div>
    ))}
  </Wrapper>
)
