import { CSSProperties } from 'react'

import styled, { css } from 'styled-components'

import { CSS } from '../../helpers'
import { Icon } from '../Icon'
import { Spinner as UnstyledSpinner } from '../Spinner'
import { HEIGHT, MAX_OPTIONS_VISIBLE, OPTION_LIST_PADDING, OPTION_MIN_WIDTH } from './constants'

export const WrappedIcon = styled(Icon)<{ $open: boolean }>`
  transform: ${(p) => (p.$open ? 'rotate(180deg)' : 'none')};
  margin-right: 11px;
  position: absolute;
  right: 0;

  top: 13.5px;

  height: 12px;
  svg {
    height: 12px;
  }
`

export const InputStyled = styled.input<{ $opened: boolean; disabled: boolean; readOnly: boolean }>`
  appearance: none;
  border: none;
  outline: none;
  background: none;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  cursor: ${(p) => (p.$opened && !p.readOnly ? 'auto' : p.disabled ? 'not-allowed' : 'pointer')};
  font: inherit;
`

export const InputForMultiSelect = styled(InputStyled)`
  padding-right: 65px;
  padding-left: 13px;
`

export const InputForSelect = styled(InputStyled)`
  padding-right: 30px;
  padding-left: 13px;
`

export const Counter = styled.span<{ $error: boolean }>`
  position: absolute;

  text-align: center;
  background: ${(p) =>
    p.$error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.grey.grey500};

  color: ${(p) => p.theme.uiKit.colors.global.general.main};

  border-radius: 11px;
  right: 35px;
  top: 8px;
  width: 22px;
  height: 22px;

  pointer-events: none;
  user-select: none;
`

export const Spinner = styled(UnstyledSpinner)`
  position: absolute;
  text-align: center;
  right: 35px;
  top: 8px;
  left: unset;
  border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500} transparent
    ${(p) => p.theme.uiKit.colors.global.grey.grey500} transparent;
  pointer-events: none;
  user-select: none;
`

export const Clear = styled(Icon)``

export const DropDown = styled.div<{ $column: boolean; $isSearching?: boolean }>`
  padding: 0;
  margin: 0;
  user-select: none;
  outline: 0;

  display: flex;
  align-items: stretch;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: ${(p) => (p.$column ? 'column' : 'row')};

  width: 100%;

  ${(p) =>
    p.$isSearching
      ? css`
          opacity: 0.5;
          transition: ${CSS.populateTransition(['opacity'])};
        `
      : null}
`

export const PopperStyles = (isCollapsed: boolean): CSSProperties => ({
  maxHeight: `${HEIGHT * MAX_OPTIONS_VISIBLE + 2 * OPTION_LIST_PADDING}px`,
  minWidth: `${OPTION_MIN_WIDTH}px`,
  overflowY: 'auto',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  listStyleType: 'none',
  background: '#fff',
  padding: `${isCollapsed ? 0 : OPTION_LIST_PADDING}px 0`,
  borderRadius: '8px'
})

export const UlStyled = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
  li {
    min-height: ${HEIGHT}px;
    display: flex;
    align-items: center;
    padding: ${(HEIGHT * 1) / 8}px 10px;
  }
`

export const SelectedLabel = styled.div<{ disabled?: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding-right: 30px;
  padding-left: 13px;
  padding-top: 9px;
  display: inline-block;
`

export const ReferenceOuter = styled.div<{
  $isEmpty: boolean
  $error: boolean
  disabled: boolean
}>`
  cursor: pointer;
  outline: none;
  user-select: none;

  position: relative;
  padding: 0;
  width: 100%;
  height: ${HEIGHT}px;
  display: inline-block;
  vertical-align: bottom;

  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  border: 1px solid transparent;
  border-radius: 4px;

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

  color: ${(p) =>
    p.$isEmpty ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.general.secondary};

  ${InputStyled} {
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

    color: ${(p) =>
      p.$isEmpty ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.general.secondary};

    ::placeholder {
      ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};
      color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
      opacity: 1;
    }
  }

  ${WrappedIcon} {
    svg {
      color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
      fill: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
    }
  }

  transition: ${CSS.populateTransition(['border', 'background'])};

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }

  ${(p) =>
    p.$error
      ? css`
          &,
          &:hover,
          &:focus,
          &:focus-within {
            color: ${p.$isEmpty ? p.theme.uiKit.colors.theme.negative.main : 'inherit'};
            background: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
            border-color: ${(p) => p.theme.uiKit.colors.theme.negative.main};

            ${InputStyled} {
              ::placeholder {
                color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
                opacity: 1;
              }
            }

            ${WrappedIcon} {
              svg {
                color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
                fill: ${(p) => p.theme.uiKit.colors.theme.negative.main};
              }
            }
          }
        `
      : null}

  ${(p) =>
    p.disabled
      ? css`
          &,
          &:hover,
          &:focus {
            cursor: not-allowed;
            background: ${p.theme.uiKit.colors.global.general.main};
            border-color: ${p.theme.uiKit.colors.global.grey.grey300};

            color: ${p.$isEmpty ? p.theme.uiKit.colors.global.grey.grey500 : p.theme.uiKit.colors.global.grey.grey700};

            ${WrappedIcon} {
              svg {
                color: ${p.theme.uiKit.colors.global.grey.grey500};
                fill: ${p.theme.uiKit.colors.global.grey.grey500};
              }
            }
          }
        `
      : null}
`

export const SelectedRows = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
`

export const SelectedRow = styled.div<{ $disabled: boolean }>`
  padding: 2px 8px 2px 5px;
  border-radius: 2.87px;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  color: ${(p) =>
    p.$disabled ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.general.secondary};
  cursor: pointer;
  opacity: 0.9;
  &:hover {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  }
  transition: ${CSS.populateTransition(['background'])};
`

export const DeleteIcon = styled(Icon)`
  margin-right: 8px;
  position: relative;
  top: 1px;
  path {
    fill: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  }
`
