import { FC, memo, useCallback, useMemo } from 'react'

import { ReportFile } from 'commons/types/DTO/bank'
import { UploadedFile } from 'commons/types/DTO/bank/quarterlyReport/uploadedFile'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Layer, PageSection, Table } from 'ui/components'

import { UploadedFilesTableWebPageProps } from './UploadedFilesTableWebPageProps'
import { columns } from './columns'
import { UploadButton } from './components/UploadButton/UploadButton'
import { useDeleteReportFile, useUploadReportFileCreate } from './hooks'

export const UploadedFilesTableWebPage: FC<UploadedFilesTableWebPageProps> = memo(
  ({
    uploadedFiles,
    uploadedFilesTableState,
    uploadedFilesTotalCount,
    changePagination,
    tableKey,
    reportMonthlyId,
    reportQuarterlyId,
    loading = false,
    invalidate
  }) => {
    const { createReportFile } = useUploadReportFileCreate(tableKey, invalidate, reportQuarterlyId)
    const { deleteReportFile } = useDeleteReportFile(tableKey, invalidate, reportQuarterlyId)

    const onFilesUpload = useCallback(
      async (files: File[]) => {
        const filePromises: Promise<ReportFile>[] = []
        files.forEach((file) => {
          filePromises.push(
            createReportFile({
              file,
              reportMonthlyId: reportMonthlyId ?? 0,
              reportQuarterlyId: reportQuarterlyId ?? 0
            })
          )
        })
        await Promise.all(filePromises)
      },
      [createReportFile, reportMonthlyId, reportQuarterlyId]
    )

    const onFileDelete = useCallback(
      (id: number) => {
        deleteReportFile(id)
      },
      [deleteReportFile]
    )

    const fileTableColumns = useMemo(() => columns(onFileDelete), [onFileDelete])

    const mappedUploadedFiles: DataSourceItem<UploadedFile>[] = useMemo(
      () =>
        uploadedFiles.map((el) => ({
          item: el
        })),
      [uploadedFiles]
    )

    return (
      <PageSection title="Uploaded Files" anchor="uploaded_files" actions={<UploadButton onUpload={onFilesUpload} />}>
        <Layer rounded shadowed stretch>
          <Table<UploadedFile>
            loading={loading}
            dataQa={TableDataQa.BP_QUARTERLY_ANALYTICS_UPLOADED_FILES}
            columns={fileTableColumns}
            dataSource={mappedUploadedFiles}
            pagination={{
              page: uploadedFilesTableState.page,
              pageSize: uploadedFilesTableState.size,
              onChange: changePagination,
              total: uploadedFilesTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
