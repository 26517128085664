import { useCallback, useMemo, useState } from 'react'

import { FilterCompanyBankAccountsWebPage } from 'commons/service/bank/companyDetails'
import { BankAccount } from 'commons/types/DTO/bank/companyDetails/bankAccount'
import { handlerServerError } from 'errors'
import { Toast, useTableState } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { hooks as bankAccountHooks } from '@orbit/bank-accounts'

export const useComponentsEssence = (
  companyId: number,
  filterCompanyBankAccountsWebPage: (filter: FilterCompanyBankAccountsWebPage) => void,
  bankAccountsTotalCount: number
) => {
  const [loading, setLoading] = useState(false)

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      bankAccountsOffset: undefined,
      bankAccountsLimit: undefined
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState<{
    relationshipID: number
    bankAccountsOffset: number | undefined
    bankAccountsLimit: number | undefined
  }>(defaultFilter)

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = { ...tableFilter, bankAccountsOffset: (page - 1) * size, bankAccountsLimit: size }

      setTableFilter(filter)

      await filterCompanyBankAccountsWebPage(filter)

      setLoading(false)
    },
    [filterCompanyBankAccountsWebPage, tableFilter, tableState]
  )

  const getCompanyBankAccountswAfterDeleteWebPage = () => {
    setLoading(true)

    const isDeletingBankAccountsLastOnPage = bankAccountsTotalCount === (tableFilter?.bankAccountsOffset || 0) + 1

    const offset = isDeletingBankAccountsLastOnPage
      ? (tableFilter?.bankAccountsOffset || 0) - (tableFilter?.bankAccountsLimit || 0)
      : tableFilter.bankAccountsOffset

    filterCompanyBankAccountsWebPage({
      ...tableFilter,
      bankAccountsOffset: offset || 0
    })

    tableState.onPageChange(isDeletingBankAccountsLastOnPage ? tableState.page - 1 : tableState.page, tableState.size)

    const filter = { ...tableFilter, bankAccountsOffset: offset || 0 }

    setTableFilter(filter)

    setLoading(false)
  }

  const [accountToDelete, setAccountToDelete] = useState<BankAccount | undefined>(undefined)

  const { routine: deleteAccount } = bankAccountHooks.useDelete({
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      getCompanyBankAccountswAfterDeleteWebPage()
      Toast.successSmall(`Bank Account "${accountToDelete?.accountNumber}" was successfully deleted.`)
    }
  })

  const { open, Confirmation } = useConfirmation({
    message: `Are you sure want to remove this bank account?`,
    onConfirm: () => (accountToDelete?.id ? deleteAccount({ id: accountToDelete?.id }) : null),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startDeleting = useCallback(
    (item: any) => {
      setAccountToDelete(item)
      open()
    },
    [open, setAccountToDelete]
  )

  return {
    loading,
    changePagination,
    tableState,
    Confirmation,
    startDeleting
  }
}
