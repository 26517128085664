import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { selectStateListWithAll } from 'commons/types/dictionaries'
import { FilterComponentProps } from 'query-table'
import { TextInput } from 'ui/components/InputV2'
import { CompanySelect, Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { SICSAPIStatusReportFilterValue } from './types'

import * as SC from './styles'

export const SICSAPIStatusReportFilterForm: FC<FilterComponentProps<SICSAPIStatusReportFilterValue>> = memo(
  ({ form: { isDirty, config, set, Field }, expanded, onToggle }) => (
    <FilterPanel expanded={expanded} isDirty={isDirty} onReset={() => set({ reset: true })} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.Row>
            <Field
              name="relationship_id"
              label="Relationship"
              input={CompanySelect}
              inputProps={{
                defaultOption: { label: 'All', value: 0 },
                'data-qa': 'Relationship',
                'options-data-qa': 'Relationship',
                hideMyCompaniesFilter: true
              }}
            />
            <Field
              name="license_name"
              label="License Name"
              input={TextInput}
              inputProps={{
                'data-qa': 'License Name'
              }}
            />
            <Field
              name="license_number"
              label="License Number"
              input={TextInput}
              inputProps={{
                'data-qa': 'License Number'
              }}
            />
            <Field
              name="license_state"
              label="License State"
              input={Select}
              inputProps={{
                data: selectStateListWithAll,
                'data-qa': 'License State',
                'options-data-qa': 'License State'
              }}
            />
            <Field
              name="connected"
              label="Connection Status"
              input={Select}
              inputProps={{
                data: [
                  { label: 'All', value: null },
                  { label: 'Connected', value: true },
                  { label: 'Not Connected', value: false }
                ],
                'data-qa': 'Connected',
                'options-data-qa': 'Connected'
              }}
            />
          </SC.Row>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
)
