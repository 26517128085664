import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type IsAllInfoUnavailableProps = {
  index: number
}

export const IsAllInfoUnavailable = ({ index }: IsAllInfoUnavailableProps) => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name={`subjectsInfo[${index}].isAllInfoUnavailable` as 'subjectsInfo[0].isAllInfoUnavailable'}
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="if all critical* subject information is unavailable" />}
        />
      )}
    />
  )
}
