import styled, { css, keyframes } from 'styled-components'

import { IconV2 } from '../../Icon'

const InAnimationOverlay = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const OutAnimationOverlay = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`

const InAnimationDialog = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const OutAnimationDialog = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
  }
`

export const Overlay = styled.div<{ $opened: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1005;
  background: rgba(0, 0, 0, 0.4);

  ${(p) =>
    p.$opened
      ? css`
          opacity: 1;
          animation: ${InAnimationOverlay} 150ms ease-in;
        `
      : css`
          opacity: 0;
          animation: ${OutAnimationOverlay} 150ms ease-out;
        `};
`

export const Dialog = styled.div<{ $opened: boolean }>`
  background: var(--theme-colors-global-general-main);
  width: 478px;
  box-shadow: var(--theme-boxShadows-offset-offset100Y4);
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 80px;
  padding-bottom: 60px;
  border-radius: 12px;
  position: relative;

  ${(p) =>
    p.$opened
      ? css`
          opacity: 1;
          animation: ${InAnimationDialog} 200ms ease-in;
        `
      : css`
          opacity: 0;
          animation: ${OutAnimationDialog} 200ms ease-out;
        `};
`

export const Message = styled.div`
  font: var(--theme-typography-h5-semiBold);
  text-align: center;
`

export const Buttons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 32px;
  justify-content: center;
`

export const CloseButton = styled(IconV2)`
  position: absolute;
  top: 18px;
  right: 18px;
  color: var(--theme-colors-global-grey-grey500);
  cursor: pointer;

  transition: color 0.25s;

  &:hover {
    color: var(--theme-colors-global-grey-grey700);
  }
`
