import { useCallback, useEffect, useState } from 'react'

import { Value } from '../types'
import { IsEmpty } from '../utils'

export const useEmpty = (value: Value, onChange) => {
  const [isEmpty, setISEmpty] = useState(IsEmpty(value))

  // sync empty state with value
  useEffect(() => {
    setISEmpty(IsEmpty(value))
  }, [value])

  // handle change to set empty status and send value above
  const handleChange = useCallback(
    (value: string, error: string | null | undefined) => {
      if (value) {
        setISEmpty(false)
      } else {
        setISEmpty(true)
      }
      onChange(value, error)
    },
    [onChange]
  )

  return {
    isEmpty,
    handleChange
  }
}
