import { formatCurrencyCent } from 'commons/utils'
import { TableColumn } from 'ui/components/Table'
import { SalesDepositItem } from '~bank-annual-review/types/AnnualReviewDetails/response.types'

export const columns = (
  quartersLabels: string[]
): TableColumn<SalesDepositItem['salesSummary'][0] | SalesDepositItem['depositDetail'][0]>[] => [
  {
    title: 'Name',
    key: 'name',
    align: 'left'
  },
  {
    title: quartersLabels[0],
    key: 'quarter1',
    align: 'right',
    render: ({ quarter1 }) => <>{typeof quarter1 !== undefined ? formatCurrencyCent(quarter1) : null}</>
  },
  {
    title: quartersLabels[1],
    key: 'quarter2',
    align: 'right',
    render: ({ quarter2 }) => <>{typeof quarter2 !== undefined ? formatCurrencyCent(quarter2) : null}</>
  },
  {
    title: quartersLabels[2],
    key: 'quarter3',
    align: 'right',
    render: ({ quarter3 }) => <>{typeof quarter3 !== undefined ? formatCurrencyCent(quarter3) : null}</>
  },
  {
    title: quartersLabels[3],
    key: 'quarter4',
    align: 'right',
    render: ({ quarter4 }) => <>{typeof quarter4 !== undefined ? formatCurrencyCent(quarter4) : null}</>
  },
  {
    title: 'Total',
    key: 'total',
    align: 'right',
    render: ({ total }) => <>{formatCurrencyCent(total)}</>
  }
]
