import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useCreateEnvelopeOnboardingDocuments } from 'commons/hooks/bank/onboardingSetupDocuments'
import { OnboardingDocumentCreateModeWebPage } from 'commons/types/DTO/bank/applications/onboardingDocuments'

import { ManageContainer } from '../../containers/ManageContainer'

const routeApi = getRouteApi('/application/$applicationId/onboarding-documents/setup')

const SetupDocumentsCreate = () => {
  const navigate = useNavigate()

  const applicationId = routeApi.useParams({ select: (s) => s.applicationId })
  const webPageData: OnboardingDocumentCreateModeWebPage = routeApi.useLoaderData({ select: (s) => s.webPageData })

  const { routine: create } = useCreateEnvelopeOnboardingDocuments({
    onSuccess: () =>
      navigate({
        from: '/application/$applicationId/onboarding-documents/setup',
        to: '/onboarding/applications/$applicationId',
        params: applicationId
      })
  })

  const onSubmit = (form) => create({ ...form, applicationId: Number(applicationId) })

  return (
    <ManageContainer
      webPageData={webPageData}
      pageTitle={`Application ID-${applicationId}`}
      pageSubTitle="Setup Onboarding Documents"
      onSubmitForm={onSubmit}
      footerSubmitButtonText="Create Onboarding Documents"
    />
  )
}

export default SetupDocumentsCreate
