import { Info } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const Status = () => {
  const { formApi } = useSARFormContext()
  const status = formApi.useStore((s) => s.values.generalInfo?.status)

  return <Info label="Status">{status}</Info>
}
