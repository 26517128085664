import { phoneTypeTypes } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type PhoneTypeProps = {
  index1: number
  index2: number
}

export const PhoneType = ({ index1, index2 }: PhoneTypeProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1}].phoneNumbers[${index2}].phoneType` as 'subjectsInfo[0].phoneNumbers[0].phoneType'}
      children={(field) => (
        <FF
          field={field}
          label="20. Type"
          input={(p) => <Select {...p} emptyOption={EMPTY_OPTION} data={phoneTypeTypes} />}
        />
      )}
    />
  )
}
