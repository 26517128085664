import { SalesSourceItemInfo } from './SalesSourceItemInfo'

export type SalesSourceItem = {
  licenseId: number
  licenseNumber: string
  enableOverride: boolean
  showOverrideButtonTooltip: string
  enableSendTask: boolean
  sources: SalesSourceItemInfo[]
  showOverrideTotalCannabisSales: number
  showOverrideTotalNonCannabisSales: number
  showOverrideTotalSalesTaxCollected: number
}
