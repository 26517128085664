import { MfaDevice } from 'commons/types/DTO/bank'
import { DataSourceItem } from 'ui/components'

export type MfaDevices = {
  id: number
  name: string
  createdAt: Date
  lastUsedAt: Date
  type: string
  default: boolean
}

export const selector = (data: { main: { records: MfaDevice[] } }): DataSourceItem<MfaDevices>[] => {
  const records = data?.main?.records || []

  return records.map((record) => ({
    item: {
      id: record.id,
      name: record.email || record.phone_number || `Application #${record.id}`,
      createdAt: record.created_at,
      lastUsedAt: record.last_used_at as Date,
      type: record.type,
      default: record.default
    }
  }))
}
