import { FC, memo } from 'react'

import { HintFace, HintProps } from './HintProps'

import * as SC from './styles'

export const Hint: FC<HintProps> = memo(({ children, face = HintFace.DEFAULT, ...rest }) => (
  <SC.Hint {...rest} face={face}>
    {children}
  </SC.Hint>
))
