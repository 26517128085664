import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type AddressProps = {
  index1: number
  index2: number
}

export const Address = ({ index1, index2 }: AddressProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1}].addressesInfo[${index2}].address` as 'subjectsInfo[0].addressesInfo[0].address'}
      children={(field) => (
        <FF
          field={field}
          label="11. Address"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} maxLength={100} />}
        />
      )}
    />
  )
}
