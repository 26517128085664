import { useCallback, useMemo, useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { Document } from 'commons/types/DTO/bank/companyDetails/document'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useTableState } from 'ui/components/QueryTable'
import { useConfirmation } from 'ui/hooks'
import { useDeleteDocument } from '~bank-documents/service/deleteDocument'

import { BankAccountDetailsPage, BankAccountsDocumentsFilter } from '@orbit/bank-accounts'

export const useComponentsEssence = (
  filterBankAccountDetails: (payload: BankAccountsDocumentsFilter) => void,
  webPage: BankAccountDetailsPage
) => {
  const router = useRouter()
  const { documentsFilter } = webPage.config
  const [loading, setLoading] = useState(false)

  const initialFilter: BankAccountsDocumentsFilter = useMemo(
    () => ({
      alerts: documentsFilter?.alerts ?? null,
      internal: documentsFilter?.internal ?? null,
      frequency: documentsFilter?.frequency ?? null,
      page: documentsFilter?.page ?? null,
      size: documentsFilter?.size ?? null,
      sortBy: documentsFilter?.sortBy ?? null,
      sortDesc: documentsFilter?.sortDesc ?? null
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState<BankAccountsDocumentsFilter>(initialFilter)

  const tableState = useTableState({
    filter: {},
    order: [
      {
        field: documentsFilter?.sortBy ? documentsFilter.sortBy : undefined,
        direction: documentsFilter?.sortDesc ? 'DESC' : 'ASC'
      }
    ]
  })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        ...tableFilter,
        page,
        size
      }

      setTableFilter(filter)

      filterBankAccountDetails(filter)

      setLoading(false)
    },
    [filterBankAccountDetails, tableFilter, tableState]
  )

  const onFilterChange = async (value: BankAccountsDocumentsFilter) => {
    tableState.filterForm.set({ value })

    setLoading(true)

    const filter = {
      ...tableFilter,
      ...value,
      page: 1
    }

    setTableFilter(filter)
    filterBankAccountDetails(filter)

    setLoading(false)
  }

  const filterForm = useFormData({
    initialValue: initialFilter
  })

  const originalChangeHandler = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      onFilterChange({ ...tableFilter, ...v })
      originalChangeHandler(v, e)
    },
    [originalChangeHandler, tableState]
  )

  const clearFilter = useCallback(async () => {
    filterBankAccountDetails(initialFilter)
    setTableFilter(initialFilter)
    filterForm.set({ reset: true })
  }, [filterForm, filterBankAccountDetails, initialFilter])

  const onSort = useCallback(
    async (direction: 'ASC' | 'DESC', field) => {
      const isDesc = direction === 'DESC'

      tableState.onOrderChange({ field, direction })

      setLoading(true)

      const filter: BankAccountsDocumentsFilter = {
        ...tableFilter,
        size: tableState.size,
        page: tableState.page,
        sortBy: field,
        sortDesc: isDesc
      }

      filterBankAccountDetails(filter)

      setLoading(false)
    },
    [filterBankAccountDetails, tableState]
  )

  const [documentForDelete, setDocumentForDelete] = useState<any>(undefined)
  const { routine: deleteMutation } = useDeleteDocument({
    onSuccess: async () => {
      filterBankAccountDetails({
        ...tableFilter,
        page: tableState.page
      })
      setLoading(false)
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteDocument = useCallback(async () => {
    try {
      await deleteMutation(documentForDelete?.id || 0)

      router.invalidate()
      Toast.successSmall(`Document was successfully deleted.`)
    } catch (error) {
      handlerServerError(error)
    }
  }, [deleteMutation, documentForDelete, router])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: (
      <>
        Are you sure want to delete
        <br />
        <i>{documentForDelete?.name}</i> document?
      </>
    ),
    onConfirm: deleteDocument,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDeleteDocument = (document: Document | undefined) => {
    setDocumentForDelete(document)
    openDeleteConfirmation()
  }

  return {
    loading,
    tableState,
    filterForm,
    changePagination,
    onSort,
    onDeleteDocument,
    DeleteConfirmation,
    openDeleteConfirmation,
    deleteDocument,
    clearFilter
  }
}
