import { FC } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { MainMenu } from 'ui/components'
import { MenuItemProps } from 'ui/components/SideMenu/types'

import { Bar } from '../Bar'
import { Header } from '../Header'
import PageContainer from './PageContainer'
import PageContent from './PageContent'

export const Page: FC<{
  children: React.ReactNode
  headerChildren: JSX.Element
  barMinimized: boolean
  menuItems: Array<MenuItemProps>
  menuSecondaryMenuPane: React.ReactElement | null
  onToggleBar: () => void
}> = ({ children, barMinimized, headerChildren, menuItems, menuSecondaryMenuPane, onToggleBar }) => {
  const { pathname } = useRouterState({ select: (s) => s.location })

  // TODO: think about good way
  const isPrintPage = pathname.indexOf('/print') > 0

  return (
    <PageContainer $barMinimized={barMinimized}>
      <Header>{headerChildren}</Header>
      {!isPrintPage && (
        <Bar minimized={barMinimized} onToggleBar={onToggleBar}>
          {menuItems && (
            <MainMenu
              items={menuItems}
              minimized={barMinimized}
              secondaryMenuPane={barMinimized ? null : menuSecondaryMenuPane}
            />
          )}
        </Bar>
      )}
      <PageContent>{children}</PageContent>
    </PageContainer>
  )
}
