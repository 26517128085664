import { FC, memo, useCallback, useEffect, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { AnnualReviewPaths } from '~bank-annual-review/constants'

import { Questionnaire } from '../Questionnaire'
import { Licensing } from './components/Licensing'
import { Overview } from './components/Overview'
import { SalesDeposits } from './components/SalesDeposits'

import * as SC from './styles'

const AnnualReviewPrint: FC = memo(() => {
  const loaderData = getRouteApi(AnnualReviewPaths.DETAILS).useLoaderData()

  const closeAfterPrint = useCallback(() => {
    setTimeout(() => {
      window.close()
    }, 0)
  }, [])

  useEffect(() => {
    window.addEventListener('afterprint', closeAfterPrint)
    return () => {
      window.removeEventListener('afterprint', closeAfterPrint)
    }
  }, [closeAfterPrint])

  const [isFullyLoaded, setIsFullyLoaded] = useState(false)

  useEffect(() => {
    if (loaderData && isFullyLoaded) {
      window.print()
    }
  }, [loaderData, isFullyLoaded === true])

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (window.document.querySelector('#annual-review-q')) {
        setIsFullyLoaded(true)
        observer.disconnect()
      }
    })

    observer.observe(window.document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  if (!loaderData) return null

  return (
    <SC.Container>
      <Overview />
      <Licensing />
      <Questionnaire />
      <SalesDeposits />
    </SC.Container>
  )
})

export default AnnualReviewPrint
