import { FC, memo } from 'react'

import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'

import { DueTooltipProps } from './DueTooltipProps'

import * as SC from './styles'

export const DueTooltip: FC<DueTooltipProps> = memo((props) => {
  const { id, initialAlerted, firstAlerted, lastAlerted, pastDue } = props

  return (
    <SC.DueRow>
      {(!!firstAlerted.count || !!lastAlerted.count || !!initialAlerted.count) && (
        <SC.DueCount>
          <Tooltip
            id={`firstHint${id}`}
            type="warning"
            event="hover"
            content={
              <>
                {[
                  !!initialAlerted.count && initialAlerted.label,
                  !!firstAlerted.count && firstAlerted.label,
                  !!lastAlerted.count && lastAlerted.label
                ]
                  .filter(Boolean)
                  .join(', ')}
              </>
            }
          >
            <SC.TooltipContent>
              <Icon name={IconName.EXCLAMATION_ORANGE} size={IconSize.S} />{' '}
              {firstAlerted.count + lastAlerted.count + initialAlerted.count}
            </SC.TooltipContent>
          </Tooltip>
        </SC.DueCount>
      )}
      {!!pastDue.count && (
        <SC.DueCount>
          <Tooltip id={`pastDue${id}`} type="error" event="hover" content={<>{`${pastDue.label} Past Due`}</>}>
            <SC.TooltipContent>
              <Icon name={IconName.EXCLAMATION} size={IconSize.S} /> {pastDue.count}
            </SC.TooltipContent>
          </Tooltip>
        </SC.DueCount>
      )}
      {!pastDue.count && !initialAlerted.count && !firstAlerted.count && !lastAlerted.count && (
        <SC.DueCount>
          <Icon name={IconName.CHECK_GREEN} size={IconSize.S} />
        </SC.DueCount>
      )}
    </SC.DueRow>
  )
})
