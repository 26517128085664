import { FC } from 'react'

import { AdverseMediaSearchCase } from 'commons/types/DTO/bank'

import { RiskLevelSpan } from './styles'

const riskLevelValues = {
  unknown: {
    label: 'Unknown',
    color: '#B63C3C'
  },
  low: {
    label: 'Low',
    color: '#45D5B4'
  },
  acknowledged_medium: {
    label: 'Acknowledged Medium',
    color: '#B63C3C'
  },
  medium: {
    label: 'Medium',
    color: '#B63C3C'
  },
  acknowledged_high: {
    label: 'Acknowledged High',
    color: '#FA5252'
  },
  high: {
    label: 'High',
    color: '#FA5252'
  }
}

interface Props {
  riskLevel: AdverseMediaSearchCase['risk_level']
}

export const RiskLevel: FC<Props> = ({ riskLevel }) => {
  const { color, label } = riskLevelValues[riskLevel!]
  return <RiskLevelSpan style={{ color: color }}>{label}</RiskLevelSpan>
}
