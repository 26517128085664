import { SalesRetail } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/SalesRetail'
import { Link } from 'ui/components'

import * as SC from './styles'

export const renderLicensesList = (sales: SalesRetail) =>
  sales.licenses.map((license, i) => (
    <span key={i}>
      {license.id !== 0 ? (
        <Link to="/licenses/$licenseId" params={{ licenseId: license.id }}>
          {license.licenseNumber}
        </Link>
      ) : (
        <>{license.licenseNumber}</>
      )}
      {i === sales.licenses.length - 1 ? '' : ', '}
    </span>
  ))

export const getPercentValue = (v: string) => {
  if (v === '-1.00') {
    return 'N/A'
  }

  return v.startsWith('-') ? <SC.TV $negative>({v})%</SC.TV> : <SC.TV>{v}%</SC.TV>
}
