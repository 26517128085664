import { InputHTMLAttributes, ReactNode } from 'react'

import { FormInputProps } from 'brief-form'
import { InputHintProps } from 'ui/core/InputHint'

import { InputIconPosition, Value } from '../types'

export type BaseInputOpts = InputHintProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
    // inside icon in the input
    renderIcon?: ReactNode
    iconPosition?: InputIconPosition
    validator?: (v: string | number | null) => string | undefined
    'data-lpignore'?: boolean
    'data-qa'?: string | number
    'data-testid'?: string
    toUpper?: boolean
  }

export type BaseInputProps = FormInputProps<Value, BaseInputOpts>
