import { COMPANIES_PATH, LICENSES_PATH } from 'commons/constants/routes'
import { ConnectionLicenseMeta } from 'commons/types/DTO/bank/connections'
import { Link } from 'ui/components'
import { TableColumn } from 'ui/components/Table'

export const columns: TableColumn<ConnectionLicenseMeta>[] = [
  {
    title: 'License',
    align: 'left',
    render: ({ licenseID, licenseName }: ConnectionLicenseMeta) => (
      <Link to={`${LICENSES_PATH}/${licenseID}`}>{licenseName}</Link>
    )
  },
  {
    title: 'Relationship',
    render: ({ relationshipID, relationshipName }: ConnectionLicenseMeta) => (
      <Link to={`${COMPANIES_PATH}/${relationshipID}`}>{relationshipName}</Link>
    )
  },
  {
    title: 'Type',
    key: 'type'
  },
  {
    title: 'Last Update',
    align: 'right',
    key: 'lastUpdatedAt'
  }
]
