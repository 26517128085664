import { FC, memo } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { BankSourceCreateFormValue, SalesDataSourceWebPage } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { formatCurrencyCent } from 'commons/utils'
import { FieldSet, IconName, IconSize, Info, Tooltip, Validators } from 'ui/components'
import { CurrencyCentInput, TextareaInput } from 'ui/components/InputV2'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import * as SC from './styles'

export const SalesDataSourceForm: FC<{
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, BankSourceCreateFormValue>
  ) => JSX.Element
  value: BankSourceCreateFormValue
  webPage?: SalesDataSourceWebPage
}> = memo(({ Field, value, webPage }) => (
  <FieldSet>
    <FormRow>
      <Col>
        <SC.FieldWithIcon>
          <Field
            required
            name="totalCannabisSales"
            input={CurrencyCentInput}
            label="Total Cannabis Sales Excluding Sales Tax"
            validator={Validators.required.number()}
            inputProps={{
              'data-qa': 'totalCannabisSales'
            }}
          />
          <SC.IconWrapper>
            <Tooltip
              id="totalCannabisSales"
              type="dark"
              event="hover"
              content="Please enter the total amount of only cannabis product sales for the month in this area"
            >
              <SC.InfoIcon name={IconName.INFO} size={IconSize.S} />
            </Tooltip>
          </SC.IconWrapper>
        </SC.FieldWithIcon>
      </Col>
      <Col>
        <SC.FieldWithIcon>
          <Field
            required
            name="totalNonCannabisSales"
            input={CurrencyCentInput}
            label="Total Non-Cannabis Sales"
            validator={Validators.required.number()}
            inputProps={{
              'data-qa': 'totalNonCannabisSales'
            }}
          />
          <SC.IconWrapper>
            <Tooltip
              id="totalNonCannabisSales"
              type="dark"
              event="hover"
              content="Please enter the total amount of only non-cannabis sales for the month in this area"
            >
              <SC.InfoIcon name={IconName.INFO} size={IconSize.S} />
            </Tooltip>
          </SC.IconWrapper>
        </SC.FieldWithIcon>
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <SC.FieldWithIcon>
          <Field
            required
            name="totalSalesTaxCollected"
            input={CurrencyCentInput}
            label={
              webPage?.totalSalesTaxCollectedLabel
                ? webPage.totalSalesTaxCollectedLabel
                : 'Estimated Total Sales Tax Collected'
            }
            validator={Validators.required.number()}
            inputProps={{
              'data-qa': 'totalSalesTaxCollected'
            }}
          />
          <SC.IconWrapper>
            <Tooltip
              id="totalSalesTaxCollected"
              type="dark"
              event="hover"
              content="Please enter the total sales tax amount for all sales (cannabis and non-cannabis sales during the
                month"
            >
              <SC.InfoIcon name={IconName.INFO} size={IconSize.S} />
            </Tooltip>
          </SC.IconWrapper>
        </SC.FieldWithIcon>
      </Col>
      <Col>
        <Info label="Total Sales">
          <SC.FieldWithIcon>
            {value.totalCannabisSales !== null &&
              value.totalNonCannabisSales !== null &&
              value.totalSalesTaxCollected !== null && (
                <>
                  {formatCurrencyCent(
                    value.totalCannabisSales + value.totalNonCannabisSales + value.totalSalesTaxCollected
                  )}
                </>
              )}
            <Tooltip
              id="totalSales"
              type="dark"
              event="hover"
              content="Automatically calculated by summing previous categories"
            >
              <SC.InfoIcon name={IconName.INFO} size={IconSize.S} />
            </Tooltip>
          </SC.FieldWithIcon>
        </Info>
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          name="internalNotes"
          input={TextareaInput}
          label="Internal Notes"
          inputProps={{
            'data-qa': 'internalNotes'
          }}
        />
      </Col2x>
    </FormRow>
  </FieldSet>
))
