import { FieldApi } from '@tanstack/react-form'
import { FF, IconName, Info, UrlInput } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../context'

export const WebSite = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index as 0}].websites`}
      children={(field) => {
        if (typeof field.state.value === 'undefined') {
          return null
        }

        return (
          <Info label="22a. Website (URL) address">
            {(field.state.value || []).map((_, index2) => (
              <WebSiteItem key={`${index}-${index2}`} index1={index} index2={index2} parentField={field} />
            ))}

            <IconButton icon={IconName.ADD_GREEN} onClick={() => field.pushValue('')} />
          </Info>
        )
      }}
    />
  )
}

const WebSiteItem = ({
  index1,
  index2,
  parentField
}: {
  index1: number
  index2: number
  parentField: FieldApi<SarEdit, 'subjectsInfo[0].websites'>
}) => {
  const { formApi } = useSARFormContext()
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index2),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <formApi.Field
        name={`subjectsInfo[${index1 as 0}].websites[${index2 as 0}]`}
        children={(field) => (
          <FormRow>
            <Col>
              <FF field={field} input={(p) => <UrlInput {...p} />} />
            </Col>
            {parentField.state.value.length > 1 && (
              <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
            )}
          </FormRow>
        )}
      />
    </>
  )
}
