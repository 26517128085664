import { COMPANIES_PATH } from 'commons/constants/routes'
import { Link, TableColumn } from 'ui/components'

export const columns: TableColumn<{ id: number; name: string }>[] = [
  {
    title: 'Relationship link',
    key: 'name',
    align: 'left',
    color: '#868686',
    width: '292px',
    render: (item) => {
      return <Link to={`${COMPANIES_PATH}/${item.id}`}>{item.name}</Link>
    }
  }
]
