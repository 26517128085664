import { memo } from 'react'

import IMask from 'imask'

import { BaseMaskedInput } from './BaseMaskedInput'
import { BaseInputProps } from './props'

export const DateInput = memo((props: BaseInputProps) => {
  const mask = IMask.createMask({
    mask: Date,
    pattern: '`m{/}`d{/}Y',
    format: function (date: Date) {
      let day = date.getDate().toString()
      let month = (date.getMonth() + 1).toString()
      const year = date.getFullYear()

      if (date.getDate() < 10) day = `0${day}`
      if (date.getMonth() < 10) month = `0${month}`

      return [month, day, year].join('/')
    },
    parse: function (str: string) {
      const monthDayYear = str.split('/')
      return new Date(parseInt(monthDayYear[2]), parseInt(monthDayYear[0]) - 1, parseInt(monthDayYear[1]))
    }
  })
  return <BaseMaskedInput {...props} mask={mask} />
})
