import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { getAnnualReviewDetailUrl } from 'bank-annual-review/src/Routes/AnnualReview/route'
import { AddNewArDialog, NewArFormValue } from 'bank-annual-review/src/components'
import { useAddAnnualReview } from 'bank-annual-review/src/hooks/AnnualReview/useAddAnnualReview'
import { AnnualReview } from 'commons/types/DTO/bank/companyDetails/annualReview'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, PageSection, Table, Toast } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'

import { CompanyAnnualReviewTableProps } from './CompanyAnnualReviewTableProps'
import { columns } from './columns'
import { useComponentsEssence } from './hooks/useComponentsEssence'
import { useFilterCompanyAnnualReviewWebPage } from './hooks/useFilterCompanyAnnualReviewWebPage'

export const CompanyAnnualReviewTable: FC<CompanyAnnualReviewTableProps> = memo(
  ({ companyId, initialAnnualReviews, initialAnnualReviewsTotalCount, companyName }) => {
    const navigate = useNavigate()

    const [webPageData, setWebPageData] = useState({
      annualReviews: initialAnnualReviews,
      annualReviewsTotalCount: initialAnnualReviewsTotalCount
    })

    useEffect(() => {
      setWebPageData({
        annualReviews: initialAnnualReviews,
        annualReviewsTotalCount: initialAnnualReviewsTotalCount
      })
    }, [initialAnnualReviews, initialAnnualReviewsTotalCount])

    const { routine: filterCompanyAnnualReviewWebPage } = useFilterCompanyAnnualReviewWebPage({
      onSuccess: async (...[, result]) => setWebPageData(result),
      onError: (error) => handlerServerError(error)
    })

    const { changePagination, loading, tableState, DeleteConfirmation, onDeleteReview } = useComponentsEssence(
      companyId,
      filterCompanyAnnualReviewWebPage,
      webPageData.annualReviewsTotalCount
    )

    const memoizedColumns = useMemo(() => columns({ onDeleteReview, companyId }), [onDeleteReview, companyId])

    const mappedAnnualReviews: DataSourceItem<AnnualReview>[] = useMemo(
      () =>
        webPageData.annualReviews.map((el) => ({
          item: el
        })),
      [webPageData]
    )

    const { routine: addAnnualReview } = useAddAnnualReview({
      onSuccess: async (
        ...[, response] // (payload, response) -> (...args) => const [, response] = args
      ) => {
        Toast.successSmall('Annual Review was created.')
        if (response.id) {
          navigate({ to: getAnnualReviewDetailUrl({ companyId, id: response.id }) })
        }
      },
      onError: (error) => handlerServerError(error)
    })

    const [showAddModal, setShowAddModal] = useState(false)

    const onAddReview = useCallback(
      async (formValue: NewArFormValue) => await addAnnualReview({ ...formValue, companyId }),
      [addAnnualReview, companyId]
    )

    return (
      <PageSection
        title="Annual Reviews"
        anchor="annual"
        actions={
          <HeaderButton size="middle" onClick={() => setShowAddModal(true)}>
            Add New
          </HeaderButton>
        }
      >
        <DeleteConfirmation />
        {showAddModal && (
          <AddNewArDialog closeDialog={() => setShowAddModal(false)} onSave={onAddReview} companyName={companyName} />
        )}
        <Layer rounded shadowed stretch>
          <Table<AnnualReview>
            dataQa={TableDataQa.BP_COMPANY_ANNUAL_REVIEW}
            columns={memoizedColumns}
            dataSource={mappedAnnualReviews}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.annualReviewsTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
