import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { CheckBox, FF } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

type NoAmountInvolvedRendererProps = {
  field: FieldApi<SarEdit, 'suspiciousActivityInfo.noAmountInvolved'>
}

const NoAmountInvolvedRenderer = ({ field }: NoAmountInvolvedRendererProps) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (field.state.value) {
      formApi.setFieldValue('suspiciousActivityInfo.amountUnknown', false)
      formApi.setFieldMeta('suspiciousActivityInfo.currentAmount', (meta) => ({
        ...meta,
        errors: [],
        errorMap: {}
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.state.value])

  return <FF field={field} input={(p) => <CheckBox {...p} checkBoxLabel="No amount involved" />} />
}

export const NoAmountInvolved = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="suspiciousActivityInfo.noAmountInvolved"
      children={(field) => <NoAmountInvolvedRenderer field={field} />}
    />
  )
}
