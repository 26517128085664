import { formatCurrencyCent } from 'commons/utils'
import { TextMask } from 'ui/components/TextMask'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

import { s } from './styles'

interface SalesAndDepositTableProps {
  salesDeposit: AnnualReviewDetails['salesDeposits'][0]
}

export const SalesAndDepositTable = ({ salesDeposit }: SalesAndDepositTableProps): JSX.Element | null => {
  return (
    <div>
      <s.Title>
        Bank Account $ <TextMask text={salesDeposit.bankAccountNumber} isBankAccount alwaysHidden />
      </s.Title>
      <s.Hr />

      <s.Text>
        <b>Sales summary</b>
      </s.Text>
      <br />
      <br />

      <s.Table>
        <thead>
          <tr>
            <s.Th>
              <s.Text>Name</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[0]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[1]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[2]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[3]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>Total</s.Text>
            </s.Th>
          </tr>
        </thead>

        <tbody>
          {salesDeposit.salesSummary.map((salesItem, index) => (
            <tr key={'salesSummary' + index}>
              <s.Td>
                <s.Text>{salesItem.name}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(salesItem.quarter1 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(salesItem.quarter2 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(salesItem.quarter3 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(salesItem.quarter4 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(salesItem.total || 0)}</s.Text>
              </s.Td>
            </tr>
          ))}
        </tbody>
      </s.Table>
      <s.Hr />
      <br />

      <s.Text>
        <b>Deposit detail</b>
      </s.Text>
      <br />
      <br />

      <s.Table>
        <thead>
          <tr>
            <s.Th>
              <s.Text>Name</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[0]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[1]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[2]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>{salesDeposit.quarterLabels[3]}</s.Text>
            </s.Th>
            <s.Th>
              <s.Text>Total</s.Text>
            </s.Th>
          </tr>
        </thead>
        <tbody>
          {salesDeposit.depositDetail.map((depositDetail, index) => (
            <tr key={'depositDetail' + index}>
              <s.Td>
                <s.Text>{depositDetail.name}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(depositDetail.quarter1 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(depositDetail.quarter2 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(depositDetail.quarter3 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(depositDetail.quarter4 || '')}</s.Text>
              </s.Td>
              <s.Td>
                <s.Text>{formatCurrencyCent(depositDetail.total || 0)}</s.Text>
              </s.Td>
            </tr>
          ))}
        </tbody>
      </s.Table>
    </div>
  )
}
