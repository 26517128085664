import { AnticipatedMonthlyActivityConditions } from '../../types/state'
import { AnticipatedMonthlyActivity } from './AnticipatedMonthlyActivityFormProps'

export const amountDataSource = [
  { label: 'Up to $100', value: 10000 },
  { label: 'Up to $500', value: 50000 },
  { label: 'Up to $1000', value: 100000 },
  { label: 'Up to $5000', value: 500000 },
  { label: 'Up to $25,000', value: 2500000 },
  { label: 'Up to $100,000', value: 10000000 },
  { label: 'Up to $250,000', value: 25000000 },
  { label: 'Up to $500,000', value: 50000000 },
  { label: '$1M+', value: 100000000 }
]

export const frequencyDataSource = [
  { label: 'Periodic', value: 'periodic' },
  { label: 'Daily', value: 'daily' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Annually', value: 'annually' }
]

export const getAnticipatedMonthlyActivityList = ({
  isAnticipatedActivityAchOriginatingRequired,
  isAnticipatedActivityCheckDepositsEnable,
  isAnticipatedActivityRemoteDepositsEnable,
  isAnticipatedActivityWiresDomesticOnlyEnable,
  isAnticipatedActivityWiresDomesticEnable,
  isAnticipatedActivityWiresInternationalEnable,
  isAnticipatedActivityAtmSettlementEnable
}: AnticipatedMonthlyActivityConditions): AnticipatedMonthlyActivity[] =>
  [
    {
      label: 'ACH Deposits',
      active: 'anticipatedActivityACHDeposits',
      amount: 'anticipatedActivityACHDepositsAmount',
      frequency: 'anticipatedActivityACHDepositsFrequency',
      dataQa: 'Anticipated Monthly Activity - ACH Deposits'
    },
    {
      label: `ACH Originating (debits)${isAnticipatedActivityAchOriginatingRequired ? '*' : ''}`,
      active: 'anticipatedActivityACHOriginating',
      amount: 'anticipatedActivityACHOriginatingAmount',
      frequency: 'anticipatedActivityACHOriginatingFrequency',
      dataQa: 'ACH Originating (debits)'
    },
    {
      label: 'ATM Withdrawals',
      active: 'anticipatedActivityATMWithdrawals',
      amount: 'anticipatedActivityATMWithdrawalsAmount',
      frequency: 'anticipatedActivityATMWithdrawalsFrequency',
      dataQa: 'ATM Withdrawals'
    },
    {
      label: 'Cash Deposits',
      active: 'anticipatedActivityCashDeposits',
      amount: 'anticipatedActivityCashDepositsAmount',
      frequency: 'anticipatedActivityCashDepositsFrequency',
      dataQa: 'Cash Deposits'
    },
    isAnticipatedActivityCheckDepositsEnable && {
      label: 'Check Deposits',
      active: 'anticipatedActivityCheckDeposits',
      amount: 'anticipatedActivityCheckDepositsAmount',
      frequency: 'anticipatedActivityCheckDepositsFrequency',
      dataQa: 'Check Deposits'
    },
    {
      label: 'Cash Withdrawals',
      active: 'anticipatedActivityCashWithdrawals',
      amount: 'anticipatedActivityCashWithdrawalsAmount',
      frequency: 'anticipatedActivityCashWithdrawalsFrequency',
      dataQa: 'Cash Withdrawals'
    },
    {
      label: 'Check Withdrawals (petty cash)',
      active: 'anticipatedActivityCheckWithdrawals',
      amount: 'anticipatedActivityCheckWithdrawalsAmount',
      frequency: 'anticipatedActivityCheckWithdrawalsFrequency',
      dataQa: 'Check Withdrawals (petty cash)'
    },
    isAnticipatedActivityRemoteDepositsEnable && {
      label: 'Remote Deposit Capture*',
      active: 'anticipatedActivityRemoteDeposits',
      amount: 'anticipatedActivityRemoteDepositsAmount',
      frequency: 'anticipatedActivityRemoteDepositsFrequency',
      dataQa: 'Remote Deposit Capture*'
    },
    isAnticipatedActivityWiresDomesticOnlyEnable && {
      label: 'Wire Transfer - Domestic Only*',
      active: 'anticipatedActivityWiresDomesticOnly',
      amount: 'anticipatedActivityWiresDomesticOnlyAmount',
      frequency: 'anticipatedActivityWiresDomesticOnlyFrequency',
      dataQa: 'Wire Transfer - Domestic Only'
    },
    isAnticipatedActivityWiresDomesticEnable && {
      label: 'Wire Transfer - Domestic',
      active: 'anticipatedActivityWiresDomestic',
      amount: 'anticipatedActivityWiresDomesticAmount',
      frequency: 'anticipatedActivityWiresDomesticFrequency',
      dataQa: 'Wire Transfer - Domestic'
    },
    isAnticipatedActivityWiresInternationalEnable && {
      label: 'Wire Transfer - Foreign',
      active: 'anticipatedActivityWiresInternational',
      amount: 'anticipatedActivityWiresInternationalAmount',
      frequency: 'anticipatedActivityWiresInternationalFrequency',
      dataQa: 'Anticipated Monthly Activity - Wire Transfer - Foreign'
    },
    isAnticipatedActivityAtmSettlementEnable && {
      label: 'ATM Settlement',
      active: 'anticipatedActivityATMSettlement',
      amount: 'anticipatedActivityATMSettlementAmount',
      frequency: 'anticipatedActivityATMSettlementFrequency',
      dataQa: 'ATM Settlement'
    }
  ].filter((item): item is AnticipatedMonthlyActivity => Boolean(item))
