import { memo, useCallback, useContext } from 'react'

import { BPLayout } from 'ui/components/BPLayout'
import { HeaderButton } from 'ui/components/Buttons'
import { Page, PageFace } from 'ui/components/Page'
import { useSetNewPassword } from '~auth/hooks/user'

import { AuthContext } from '../../AuthContext'
import { ChangePasswordForm, MFADevices } from './components'
import { useChangePasswordForm } from './useChangePasswordForm'

const AccountSettings = memo(() => {
  const { openPasswordForm, closePasswordForm, passwordFormVisible } = useChangePasswordForm()
  const { portalName } = useContext(AuthContext)
  const passwordMutation = useSetNewPassword(portalName)
  const submitNewPassword = useCallback(
    // TODO: remove this "as any". We have to use it because password reset form only was updated
    // for BP, but this hook is also used for CP and BO.
    (v: { oldPassword: string; password: string }) => passwordMutation.mutateAsync(v as any),
    [passwordMutation]
  )

  return (
    <BPLayout>
      <Page
        face={PageFace.SECONDARY}
        title="Account Settings"
        actions={
          <HeaderButton size="middle" overColoredPad={true} onClick={openPasswordForm}>
            CHANGE PASSWORD
          </HeaderButton>
        }
      >
        <ChangePasswordForm visible={passwordFormVisible} onClose={closePasswordForm} onSubmit={submitNewPassword} />
        <MFADevices />
      </Page>
    </BPLayout>
  )
})

export default AccountSettings
