import { FC, memo } from 'react'

import { HistoryTable } from 'bank-entity-history'
import { HistoryEntity } from 'commons/types/DTO/bank/historyAudit'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components'
import { PageSection, PageSectionFace } from 'ui/components/Page'

type Props = {
  initialCompanyHistory: HistoryEntity[]
  initialCompanyHistoryTotalCount: number
  config: {
    isFetching: boolean
    filter: {
      page: number
      size: number
    }
    onFilterChange: (page: number, size: number) => void
  }
}

export const History: FC<Props> = memo((props) => {
  const { initialCompanyHistory, initialCompanyHistoryTotalCount, config } = props
  const { filter, onFilterChange, isFetching } = config
  const { page, size } = filter

  return (
    <PageSection face={PageSectionFace.THIRD} title="History" anchor="history">
      <Layer rounded shadowed stretch>
        <HistoryTable
          dataQa={TableDataQa.BP_BSA_REPORTING_CTR_HISTORY}
          loading={isFetching}
          items={initialCompanyHistory}
          pagination={{
            page,
            pageSize: size,
            onChange: onFilterChange,
            total: initialCompanyHistoryTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
})
