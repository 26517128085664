import { Suspense } from 'react'

import { Outlet } from '@tanstack/react-router'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

export const BankContactsWrapper = () => (
  <BPLayout>
    <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
      <Outlet />
    </Suspense>
  </BPLayout>
)
