import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const PhoneNumber = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.phone"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="94. Phone number"
          field={field}
          input={(p) => <TextInput {...p} maxLength={16} data-qa="94. Phone number" />}
        />
      )}
    />
  )
}
