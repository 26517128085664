import { FC, memo } from 'react'

import { Request } from 'commons/utils/request'

import { SingleSelectAutoSuggest } from '../SingleSelectAutoSuggest'
import { ContactSearchItem, ContactSelectProps } from './ContactSelectProps'

const PLACEHOLDER = 'Start entering the contact name'

export const ContactSelect: FC<ContactSelectProps> = memo((props) => {
  const { value, emptyOption, filters, ...rest } = props

  return (
    <SingleSelectAutoSuggest
      noFirstRequest
      placeholder={PLACEHOLDER}
      options={emptyOption ? [emptyOption] : []}
      serverOptions={{
        filters,
        url: Request.urls.bank.contactSearch,
        requestSearchString: 'searchString',
        limit: 50,
        mapper: (item: ContactSearchItem) => ({
          label: `${item.firstName || ''} ${item.lastName}`.trim() || item.email || `Contact #${item.id}`,
          value: item.id
        })
      }}
      value={value}
      {...rest}
    />
  )
})
