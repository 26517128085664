import { Company } from 'commons/types/DTO/bank'

export interface ManageCompanyFormProps {
  initialValue: Company
  onDelete: (company: { id: number; name: string }) => void
  companyForDelete: {
    id: number | null
    name: string | null
  } | null
}
