import { sarSubjectIdentificationTypes } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

export const IdForm = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idForm`}
      validators={{
        onChange: ({ value }) => {
          if (value !== 'Other') {
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idFormOther`, '')
            formApi.setFieldMeta(`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idFormOther`, (stateMeta) => ({
              ...stateMeta,
              errors: [],
              errorMap: {}
            }))
          }

          return undefined
        }
      }}
      children={(field) => (
        <FF
          field={field}
          label="Type"
          input={(p) => (
            <Select
              {...p}
              data={sarSubjectIdentificationTypes}
              emptyOption={{ label: 'Not selected', value: '' }}
              disabled={isAllInfoUnavailable}
            />
          )}
        ></FF>
      )}
    />
  )
}
