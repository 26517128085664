import { memo } from 'react'

import { Validators } from 'ui/components/Form'

import { BaseInput } from './BaseInput'
import { BaseInputProps } from './props'

type UrlInputProps = Omit<BaseInputProps, 'value' | 'onChange'> & {
  value?: string
  onChange?: (value: string, error?: string) => void
}

export const UrlInput = memo((props: UrlInputProps) => {
  return <BaseInput {...props} validator={Validators.url.valid} />
})
