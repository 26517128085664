import { FormApi, ReactFormApi } from '@tanstack/react-form'

import { ManagePeriodResponse } from './response.types'

export class ManagePeriodForm {
  startDate: string | undefined
  endDate: string | undefined
  deliveredAt?: string | null
  notes?: string

  constructor(isNew: boolean, initialValue?: ManagePeriodResponse['form']) {
    if (isNew) {
      this.startDate = undefined
      this.endDate = undefined
      this.notes = undefined
    } else if (initialValue) {
      this.startDate = initialValue.startDate
      this.endDate = initialValue.endDate
      this.notes = initialValue.notes
      this.deliveredAt = initialValue.deliveredAt ?? undefined
    }
  }
}

export type ManagePeriodFormApiType = FormApi<ManagePeriodForm, undefined> & ReactFormApi<ManagePeriodForm, undefined>
