import { UseWebPageProps, WebPage } from 'commons/types/DTO/bank/connections'
import { Request } from 'commons/utils/request'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetConnectionsWebPage: ReadHook<WebPage, UseWebPageProps> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    Keys.Connection.List(opts?.connectionsLimit, opts?.connectionsOffset, opts?.connectionsSearchQuery),
    () =>
      Request.post(Request.urls.bank.connectionsListWebpages, {
        connectionsLimit: opts?.connectionsLimit,
        connectionsOffset: opts?.connectionsOffset,
        connectionsSearchQuery: opts?.connectionsSearchQuery
      }),
    { keepPreviousData: true }
  )

  return {
    ...rest,
    data
  }
}
