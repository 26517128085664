import { FC, memo } from 'react'

import { CheckMark } from 'ui/components/CheckMark'

import { AnticipatedMonthlyProps } from './AnticipatedMonthlyProps'

import * as SC from './styles'

export const AnticipatedMonthly: FC<AnticipatedMonthlyProps> = memo(
  ({ questionnaire: { anticipatedMonthlyActivityVariant } }) => (
    <SC.Activity>
      <thead>
        <tr>
          <SC.Column1 />
          <SC.Column2>Number Of Items</SC.Column2>
          <SC.Column3>Total Amount</SC.Column3>
          <SC.Column4>N/A</SC.Column4>
        </tr>
      </thead>
      <tbody>
        {anticipatedMonthlyActivityVariant.map((el) => (
          <tr key={el.name}>
            <td>{el.name}</td>
            <td>{el.items || '---'}</td>
            <td>{el.amount || '---'}</td>
            <td>
              <CheckMark rounded={false} value={el.NA} />
            </td>
          </tr>
        ))}
      </tbody>
    </SC.Activity>
  )
)
