import { FC, memo } from 'react'

import * as SC from '../styles'
import { Link } from '@tanstack/react-router'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { useSARApprove, useSARCancel } from '~bank-bsa-reporting/hooks/sar'

import { FooterProps } from './Footer.types'

export const Footer: FC<FooterProps> = memo((props) => {
  const { search, data } = props
  const { id = 0, nextId, previousId, canApprove, canCancel } = data || {}
  const { routine: approveSAR, isLoading: isApproving } = useSARApprove(id)
  const { routine: cancelSAR, isLoading: isCanceling } = useSARCancel(id)

  return (
    <SC.Box>
      {previousId ? (
        <SC.PrevLink to="/bsa/sars/$id" params={{ id: previousId }} search={search}>
          <SC.IconButton face="neutral">
            <div>
              <SC.StyledIcon name={IconName.ARROW_PREVIOUS} />
              Prev
            </div>
          </SC.IconButton>
        </SC.PrevLink>
      ) : (
        <span />
      )}
      <ButtonGroup margin="small">
        {nextId && (
          <Link to="/bsa/sars/$id" params={{ id: nextId }} search={search}>
            <SC.IconButton face="neutral">
              <div>
                <SC.StyledIcon name={IconName.ARROW_NEXT} /> Next
              </div>
            </SC.IconButton>
          </Link>
        )}
        {canCancel && (
          <Button face="neutral" disabled={isCanceling} onClick={() => cancelSAR({ id })}>
            Cancel SAR
          </Button>
        )}
        {canApprove && (
          <Button face="positive" disabled={isApproving} onClick={() => approveSAR({ id })}>
            Approve
          </Button>
        )}
      </ButtonGroup>
    </SC.Box>
  )
})
