import { memo } from 'react'

import IMask from 'imask'

import { BaseMaskedInput } from './BaseMaskedInput'
import { BaseInputProps } from './props'

export const PhoneInput = memo((props: BaseInputProps) => {
  const mask = IMask.createMask({
    mask: '+0 (000) 000-00-00',
    lazy: true
  })
  return <BaseMaskedInput {...props} mask={mask} />
})
