import { FC, memo } from 'react'

import { TextMask } from 'ui/components/TextMask'

import { BreadCrumbsProps } from './BreadCrumbsProps'

import * as SC from './styles'

export const BreadCrumbs: FC<BreadCrumbsProps> = memo(({ companyId, companyName, bankAccountNumber = '' }) => (
  <SC.Box>
    <SC.Company to="/relationships/$companyId" params={{ companyId: companyId?.toString() }} hash="bank-accounts">
      {companyName}
    </SC.Company>
    <SC.Arrow>&gt;</SC.Arrow>
    <SC.Wrapper>
      <SC.Account>Bank Account</SC.Account>
      <TextMask isBankAccount alwaysHidden text={bankAccountNumber} />
    </SC.Wrapper>
  </SC.Box>
))
