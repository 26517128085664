import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const LEContactPhone = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.leContactPhone"
      children={(field) => (
        <FF
          label="91. LE contact phone number (Include Area Code)"
          field={field}
          input={(p) => <TextInput {...p} maxLength={16} />}
        />
      )}
    />
  )
}
