import { CSSProperties, FC, memo } from 'react'

import { FormInputProps } from 'brief-form'
import { ChoiceOption } from 'commons/types/DTO/commons'
import { Request } from 'commons/utils/request'

import { MultiSelectAutoSuggest } from './MultiSelectAutoSuggest'

export type CompanyMultiSelectProps = FormInputProps<
  number[] | null,
  {
    filters?: {
      field: string
      type: string
      value: string | number | boolean
    }[]
    // PortalName.BP, PortalName.CP
    portal?: 'bank' | 'client'
    options?: ChoiceOption[]
    containerStyle?: CSSProperties
    opts?: any
    'data-qa'?: string
    'data-qa-input'?: string
  }
>

export const CompanyMultiSelect: FC<CompanyMultiSelectProps> = memo((props) => {
  const { value, onChange, opts, ...rest } = {
    ...props
  }

  const dataQA = rest['data-qa'] || (opts ? opts['data-qa'] : undefined)
  const dataQAInput = rest['data-qa-input'] || (opts ? opts['data-qa-input'] : undefined)

  if (rest?.options) {
    return (
      <MultiSelectAutoSuggest
        {...rest}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={onChange}
        valueTitle="Add Company"
        options={rest?.options}
        data-qa={dataQA}
        data-qa-input={dataQAInput}
      />
    )
  } else {
    const mergedFilters = opts?.filters ? opts.filters : rest?.filters

    return (
      <MultiSelectAutoSuggest
        {...rest}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={onChange}
        valueTitle="Add Company"
        serverOptions={{
          filters: mergedFilters,
          // there no company-list-endpoint on CP
          url: Request.urls.bank.companyList,
          limit: 10,
          mapper: (item) => ({ label: item.name, value: item.id }),
          searchFields: ['name'],
          method: 'post'
        }}
        data-qa={dataQA}
        data-qa-input={dataQAInput}
      />
    )
  }
})
