import { FC, memo, useCallback, useContext, useEffect, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { BeforeFormChangeHandler, Form, useFormData } from 'brief-form'
import { useGetOrganisationByLocation } from 'commons/hooks/client/organisation'
import { useBank } from 'commons/hooks/common'
import { PortalName } from 'commons/types/enums'
import { LoginButton } from 'ui/components/Buttons'
import { Validators } from 'ui/components/Form'
import { IconName, IconSize } from 'ui/components/Icon'
import { FormRow } from 'ui/themes/globalStyles'
import { AuthContext } from '~auth/AuthContext'
import { AuthForm, AuthInput } from '~auth/components'

import { ResetPasswordFormProps } from './ResetPasswordFormProps'

import * as SC from './styles'

export type ResetPasswordFormValue = {
  login: string
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = memo(({ onSubmit, logoElement, footer }) => {
  const onBeforeChange: BeforeFormChangeHandler<ResetPasswordFormValue> = useCallback(({ value, errors }) => {
    setError('')

    return {
      value,
      errors
    }
  }, [])

  const { config, isValid, Field, validate } = useFormData<ResetPasswordFormValue>({
    onBeforeChange,
    initialValue: { login: '' }
  })

  const { value } = config
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const { authOrganisationId } = useGetOrganisationByLocation()
  const { name: bankName } = useBank(authOrganisationId || 0)
  const { portalName } = useContext(AuthContext)
  const portalNameText = portalName === PortalName.CP ? 'Client Portal' : 'Bank Portal'

  const expired = getRouteApi('/auth/forgot-password').useSearch({ select: (s) => s?.expired })

  useEffect(() => {
    const isExpired = expired

    if (isExpired) {
      setError(
        `Your ${bankName} ${portalNameText} link has expired. Please enter your email below to receive a new link.`
      )
    }
  }, [bankName, expired, portalNameText])

  const submitWrapper = useCallback(() => {
    if (validate({ updateFields: true }).valid) {
      setLoading(true)
      onSubmit(value)
        .catch((error) => {
          setError(error.message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [onSubmit, validate, value])

  return (
    <AuthForm error={error} logo={logoElement} footer={footer}>
      <Form config={config}>
        <FormRow>
          <SC.LoginCol>
            <Field
              required
              name="login"
              input={AuthInput}
              validator={Validators.email.requiredAndValid}
              inputProps={{
                autoFocus: true,
                skipLocalState: true,
                placeholder: 'Email',
                icon: IconName.ENVELOPE,
                iconSize: IconSize.M,
                iconColor: '#fff',
                type: 'email'
              }}
            />
          </SC.LoginCol>
        </FormRow>
      </Form>
      <SC.Footer>
        <LoginButton onClick={submitWrapper} loading={loading} disabled={!isValid}>
          RESET
        </LoginButton>
        <SC.LogInLink to="/auth/login">Return to Log In</SC.LogInLink>
      </SC.Footer>
    </AuthForm>
  )
})
