import { FormApi, ReactFormApi } from '@tanstack/react-form'

import { InvoiceSubmittalEditMode } from './InvoiceSubmittalEditMode'

export class ManageInvoiceSubmittalFormType {
  amount?: number
  date?: string | Date
  id?: number
  manifestNumber?: string
  notes?: string

  constructor(isNew: boolean, form?: InvoiceSubmittalEditMode['form']) {
    if (isNew) {
      this.amount = 0
      this.date = new Date()
      this.id = 0
      this.manifestNumber = ''
      this.notes = ''
    } else {
      this.amount = form?.amount
      this.date = form?.date
      this.id = form?.id
      this.manifestNumber = form?.manifestNumber
      this.notes = form?.notes
    }
  }
}

export type ManageDocumentFormApiType = FormApi<ManageInvoiceSubmittalFormType, undefined> &
  ReactFormApi<ManageInvoiceSubmittalFormType, undefined>
