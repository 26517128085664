import { COMPANIES_PATH } from 'commons/constants/routes'
import { CompanyWebPage } from 'commons/types/DTO/bank'
import { IconName } from 'ui/components/Icon'
import { BusinessTypeLabel, CustomerStatusLabel, EntityTypeLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

export const columns = (onDeleteCompany: (company: any) => void): TableColumn<CompanyWebPage>[] => [
  {
    title: 'Account Name',
    render: ({ id, accountName }) => <Link to={`${COMPANIES_PATH}/${id}`}>{accountName}</Link>,
    sortable: true,
    key: 'accountName',
    align: 'left'
  },
  {
    align: 'left',
    title: 'DBA',
    key: 'dba'
  },
  {
    align: 'left',
    title: 'Holding Company',
    sortable: true,
    key: 'holdingCompany',
    render: ({ holdingID, holdingCompany }) =>
      holdingCompany ? <Link to={`${COMPANIES_PATH}/${holdingID}`}>{holdingCompany}</Link> : ''
  },
  {
    align: 'center',
    title: 'Business Type',
    render: ({ businessType }) => <BusinessTypeLabel name={businessType} />
  },
  {
    align: 'center',
    title: 'Entity Type',
    render: ({ entityType }) => <EntityTypeLabel name={entityType} />
  },
  {
    align: 'center',
    title: 'Customer Status',
    render: ({ customerStatus }) => <CustomerStatusLabel name={customerStatus} />
  },
  {
    title: 'Actions',
    align: 'center',
    width: '50px',
    render: (item) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            url: `${COMPANIES_PATH}/edit/${item.id}`
          },
          {
            key: 2,
            title: 'Delete',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onDeleteCompany({ id: item.id, name: item.accountName })
          }
        ]}
      />
    )
  }
]
