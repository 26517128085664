// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { MonthPicker } from 'ui/components'
import { Dialog, DialogFace, DialogFooterActions, DialogOverlay } from 'ui/components/Dialog'
import { FF, Validators } from 'ui/components/Form'
import { Info } from 'ui/components/Info'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { FormRow } from 'ui/themes/globalStyles'
import { NewArFormValue } from '~bank-annual-review/types/NewArFormValue'

import { NewArDialogProps } from './NewArDialogProps'

import * as SC from './styles'

export const AddNewArDialog: FC<NewArDialogProps> = memo((props) => {
  const { closeDialog, onSave, companyName } = props

  const form = useForm<NewArFormValue>({
    defaultValues: {
      financialsStartDate: null,
      financialsEndDate: null
    },
    onSubmit: async ({ value }) => onSave(value)
  })

  return (
    <LeaveConfirmation
      ignoreUrlHashChanging={true}
      onLeave={closeDialog}
      preventLeaving={form.useStore((s) => s.isDirty && !s.isSubmitted && !s.isSubmitting)}
    >
      <Dialog
        id="add-new-ar-dialog"
        title="Create New Annual Review"
        face={DialogFace.PRIMARY}
        overlay={DialogOverlay.DARK}
        headerStyle={undefined}
        onClose={closeDialog}
        headerActions={undefined}
        stickyFooter={undefined}
        footerActions={[DialogFooterActions.ADD, DialogFooterActions.CANCEL]}
        onSubmit={form.handleSubmit}
        isSubmitting={form.useStore((s) => s.isSubmitting)}
      >
        <SC.DialogContent>
          <Info label="Company">{companyName}</Info>
          <FormRow>
            <SC.Column>
              <form.Field
                name="financialsStartDate"
                validators={{
                  onSubmit: ({ value }) => Validators.required.field(value),
                  onChange: ({ value, fieldApi }) => {
                    const actualFinancialsEndDate = fieldApi.form.state.values.financialsEndDate
                    if (!actualFinancialsEndDate && value) {
                      const newFinancialsEndDate = new Date(value)
                      newFinancialsEndDate.setUTCFullYear(newFinancialsEndDate.getUTCFullYear() + 1)
                      newFinancialsEndDate.setUTCMonth(newFinancialsEndDate.getUTCMonth() - 1)
                      newFinancialsEndDate.setUTCDate(1)
                      form.setFieldValue('financialsEndDate', newFinancialsEndDate.toISOString(), {
                        dontUpdateMeta: false
                      })
                    }
                    return undefined
                  }
                }}
                children={(financialsStartDateField) => (
                  <FF
                    required
                    field={financialsStartDateField}
                    label="Financials Period Start"
                    input={(inputProps) => <MonthPicker data-qa="Financials Period Start" {...inputProps} />}
                  />
                )}
              />
            </SC.Column>
          </FormRow>
          <FormRow>
            <SC.Column>
              <form.Field
                name="financialsEndDate"
                validators={{
                  onSubmit: ({ value }) => Validators.required.field(value)
                }}
                children={(financialsStartDateField) => (
                  <FF
                    required
                    field={financialsStartDateField}
                    label="Financials Period End"
                    input={(inputProps) => <MonthPicker data-qa="Financials Period End" {...inputProps} />}
                  />
                )}
              />
            </SC.Column>
          </FormRow>
        </SC.DialogContent>
      </Dialog>
    </LeaveConfirmation>
  )
})
