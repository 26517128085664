import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Owner = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const isNoRelationship = formApi.useStore(
    (s) => s.values.subjectsInfo[index1]?.relationshipsInfo[index2]?.noRelationship
  )

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].owner`}
      children={(field) => (
        <FF
          field={field}
          input={(p) => (
            <CheckBox {...p} checkBoxLabel="l. Owner or Controlling Shareholder" disabled={isNoRelationship} />
          )}
        />
      )}
    />
  )
}
