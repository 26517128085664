import { TablePagination, TableSorting } from '../types'
import { DataSourceItem } from './DataSourceItem'
import { TableColumn } from './tableColumn'

export interface TableProps<T> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  columns: TableColumn<T>[]
  dataSource: DataSourceItem<T>[]
  loading?: boolean
  noDataMessage?: string
  sorting?: TableSorting
  pagination?: TablePagination
  dataQa: string
}
