import { FC, memo, useEffect, useState } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { Layer, Table } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { ManageAffiliatedCompany } from '../ManageAffiliatedCompany/ManageAffiliatedCompany'
import { AffiliatedCompaniesTableProps } from './AffiliatedCompaniesTableProps'
import { MappedAffiliatedCompany } from './MappedAffiliatedCompany'
import { columns } from './columns'
import { useComponentEssence } from './hooks'
import { useFilterCompanyAffiliatedCompaniesWebPage } from './hooks/useFilterCompanyAffiliatedCompaniesWebPage'

export const AffiliatedCompaniesTable: FC<AffiliatedCompaniesTableProps> = memo((props) => {
  const { companyId, initialAffiliatedCompanies, initialAffiliatedCompaniesTotalCount } = props
  const [webPageData, setWebPageData] = useState({
    affiliatedCompanies: initialAffiliatedCompanies,
    affiliatedCompaniesTotalCount: initialAffiliatedCompaniesTotalCount
  })

  useEffect(() => {
    setWebPageData({
      affiliatedCompanies: initialAffiliatedCompanies,
      affiliatedCompaniesTotalCount: initialAffiliatedCompaniesTotalCount
    })
  }, [initialAffiliatedCompanies, initialAffiliatedCompaniesTotalCount])

  const { routine: filterCompanyAffiliatedCompaniesWebPage } = useFilterCompanyAffiliatedCompaniesWebPage({
    onSuccess: async (_, result) => {
      await setWebPageData(result)
    },
    onError: (error) => handlerServerError(error)
  })

  const {
    tableState,
    loading,
    changePagination,
    DeleteConfirmation,
    openDeleteConfirmation,
    manageData: { relation, closeManageDialog, onSubmit, isLoading, openAddDialog, openEditDialog }
  } = useComponentEssence(companyId, filterCompanyAffiliatedCompaniesWebPage, webPageData.affiliatedCompaniesTotalCount)

  return (
    <>
      <ManageAffiliatedCompany
        initialValue={relation}
        onClose={closeManageDialog}
        onSubmit={onSubmit}
        isSubmitting={isLoading}
      />
      <DeleteConfirmation />
      <PageSection
        face={PageSectionFace.PRIMARY}
        title="Affiliated Companies"
        id="affiliated"
        actions={
          <HeaderButton size="middle" onClick={openAddDialog}>
            Add New
          </HeaderButton>
        }
      >
        <Layer rounded shadowed stretch>
          <Table<MappedAffiliatedCompany>
            dataQa={TableDataQa.BP_COMPANY_AFFILIATED_COMPANIES}
            dataSource={webPageData.affiliatedCompanies.map((el) => ({ item: el }))}
            columns={columns(companyId, openEditDialog, openDeleteConfirmation)}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.affiliatedCompaniesTotalCount
            }}
          />
        </Layer>
      </PageSection>
    </>
  )
})
