import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 30px;
`

const Title = styled.div`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.53px;
  padding: 12px 16px;
  color: #000000;
  border-top: 2px solid #000000;

  @media print {
    border-top-width: 0px;
  }
`

const Text = styled.p`
  margin: 0 auto;
  font-size: 14px;
  font-weight: normal;
`

const BankAccountTitle = styled.p`
  display: flex;
`

export const s = { Wrapper, Title, Text, BankAccountTitle }
