import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react'

import { useApplicationFinalizationBankAccounts, useSaveRelationshipBankAccounts } from 'commons/hooks/bank/application'
import { ApplicationRelationshipLicense } from 'commons/types/DTO/bank/applications'
import { ChoiceOption } from 'commons/types/DTO/commons'
import { handlerServerError } from 'errors'
import isArray from 'lodash/isArray'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import {
  AddBankAccountValidateFunctionRefs,
  ApplicationBankAccountsFormDataList,
  ConnectBankAccountToLicensesFormOnChangeState,
  RelationshipBankAccountFormDataRecord
} from '../../types'
import * as helpers from './helpers'
import { FooterState, LicensesCurrentState } from './types'
import { useAddBankAccountDialogState } from './useAddBankAccountDialogState'

type RemoveBankAccountHandler = (params: { index: number }) => void

type BankAccountHandlers = {
  removeBankAccount: RemoveBankAccountHandler
  formOnChange: ConnectBankAccountToLicensesFormOnChangeState
  addBankAccount: () => void
  closeDialog: () => void
}

type UseAddBankAccountsDialogReturn = {
  footerState: FooterState
  handlers: BankAccountHandlers
  bankAccountFormsData: {
    validateFunctionRefs: MutableRefObject<AddBankAccountValidateFunctionRefs>
    formDataList: ApplicationBankAccountsFormDataList // dict with new
    assignedLicensesSelectData: ChoiceOption[]
  }
  ui: {
    FinalizeConfirmation: () => JSX.Element | null
    CancelConfirmation: () => JSX.Element | null
    formExpandedList: Record<string, boolean>
    showFormErrors: boolean
    titleDialogRelationshipsCursor: string
    subTitleRelationshipName: string
    bankAccountFormList: RelationshipBankAccountFormDataRecord[]
    licenses: ApplicationRelationshipLicense[]
    noLicenses: boolean
    currentCompanyIndex: number

    isRelationshipsLoaded: boolean
    isDisabledAddAccountButton: boolean
    isDisabledAddButtonHint: boolean
    isShowAddNewFrom: boolean
    isFormsDisabled: boolean

    addTooltipContent: string
    totalNumberOfRelationships: number
  }
}

type UseAddBankAccountsDialogParams = {
  isFinalizing: boolean
  applicationId: number
  onCancel: () => void
  onSubmit: () => void
}

export const useAddBankAccountsDialog = ({
  isFinalizing,
  applicationId,
  onCancel,
  onSubmit
}: UseAddBankAccountsDialogParams): UseAddBankAccountsDialogReturn => {
  const {
    data: relationshipsBankAccounts,
    invalidate: invalidateBankAccounts,
    isFetching: isRelationshipsBankAccountsLoading
  } = useApplicationFinalizationBankAccounts({
    applicationId
  })

  /* state manager */
  const { state: dialogState, handlers: dialogHandlers } = useAddBankAccountDialogState({
    relationshipsBankAccounts
  })

  /* data manipulation */
  const { routine: saveRelationshipBankAccounts, isLoading: isSaveRelationshipBankAccountsLoading } =
    useSaveRelationshipBankAccounts({
      onSuccess: async () => {
        dialogHandlers.setShowCustomErrors(false)
        Toast.successSmall('Bank accounts were successfully updated.')
      },
      onError: (errorResponse) => {
        handlerServerError(errorResponse)
        dialogHandlers.setShowCustomErrors(true)
      }
    })

  const validateFunctionRefs = useRef<AddBankAccountValidateFunctionRefs>({})
  const { relationships } = dialogState.relationshipsBankAccounts || { selectedRelationship: 0 }

  const { selectedRelationshipIndex, formDataList } = dialogState
  const currentRelationship = isArray(relationships) ? relationships[selectedRelationshipIndex] : undefined

  const handleRemoveBankAccount: RemoveBankAccountHandler = useCallback(
    ({ index }) => dialogHandlers.removeBankAccount(index),
    [dialogHandlers]
  )

  const handleFormOnChange: ConnectBankAccountToLicensesFormOnChangeState = useCallback(
    (onChangeParams) => dialogHandlers.formOnChange(onChangeParams),
    [dialogHandlers]
  )

  /*
  replace new form validation from `handleFormOnChange` to useEffect. 
  validate in `handleFormOnChange` shows invalid form status in case all fields filled in correctly
  */
  const newForm = dialogState.formDataList['new']
  const newFormValidationData = validateFunctionRefs.current['new']
  useEffect(() => {
    const isNewBankAccountFormValid = helpers.validateFormByKey({
      formValue: newForm,
      formValidateData: newFormValidationData,
      withFormUpdate: false
    })
    if (dialogState.isAddBankAccountFormValid === isNewBankAccountFormValid) {
      return
    }
    dialogHandlers.setAddBankAccountFormValid(isNewBankAccountFormValid)
    // missed newFormValidationData is ref data
  }, [newForm, dialogHandlers, dialogState.isAddBankAccountFormValid])

  const submitForms = useCallback(
    async ({ withNew = true }: { withNew?: boolean }) => {
      if (!currentRelationship?.id && !currentRelationship?.entityUID) {
        return
      }
      if (!relationshipsBankAccounts) {
        return
      }
      const savePayload = helpers.mapBankAccountsFormValuesToSavePayload({
        applicationId,
        relationshipId: currentRelationship.id,
        relationshipUid: currentRelationship.entityUID,
        formsData: dialogState.formDataList,
        withNew,
        isIsolated: relationshipsBankAccounts.useIsolated
      })

      await saveRelationshipBankAccounts(savePayload)
    },
    [
      relationshipsBankAccounts,
      currentRelationship,
      applicationId,
      dialogState.formDataList,
      saveRelationshipBankAccounts
    ]
  )

  const handleAddBankAccount = useCallback(() => {
    // add to Form Data List from the new BA form
    dialogHandlers.addToFormDataListBankAccountFromNewForm()
  }, [dialogHandlers])

  // current relationship's licenses, come from BE
  const licenses = useMemo(() => (currentRelationship ? currentRelationship.licenses : []), [currentRelationship])

  // licenses BE <- licenses from BA forms
  const licensesCurrentState: LicensesCurrentState = useMemo(
    () => helpers.getLicensesCurrentState({ licenses, formsData: dialogState.formDataList }),
    [licenses, dialogState.formDataList]
  )
  // used in license's select
  const assignedLicensesSelectData = useMemo(
    () =>
      helpers.getAssignedLicensesDataForFormInput({
        relationshipLicenses: currentRelationship?.licenses,
        formDataList
      }),
    [formDataList, currentRelationship]
  )

  const validateAndSaveData = useCallback(
    async ({ woSelectedRelationship = false }: { woSelectedRelationship?: boolean }) => {
      const { shouldTerminate, shouldSetErrors } = helpers.doFormsValidate({
        formDataList: dialogState.formDataList,
        formsValidateData: validateFunctionRefs.current,
        licensesCurrentState
      })

      dialogHandlers.setShowCustomErrors(shouldSetErrors)
      // after invalidate - `setinit` action do not select relationship from response
      // need in prev relationship flow
      dialogHandlers.setWOSelectedRelationship({ woSelectedRelationship })
      if (shouldTerminate) {
        return false
      }
      if (dialogState.isOldDirty) {
        await submitForms({ withNew: false })
        await invalidateBankAccounts()
      }
      return true
    },
    [
      dialogHandlers,
      dialogState.formDataList,
      licensesCurrentState,
      submitForms,
      invalidateBankAccounts,
      dialogState.isOldDirty
    ]
  )

  const prevRelationshipHandler = useCallback(async () => {
    const prevIndex = dialogState.selectedRelationshipIndex - 1
    if (dialogState.isOldDirty) {
      const isSuccess = await validateAndSaveData({ woSelectedRelationship: true })
      if (!isSuccess) {
        return
      }
    }
    // reset forms
    Object.keys(validateFunctionRefs.current).forEach((formKey) => {
      validateFunctionRefs.current[formKey].formData.set({ reset: true })
    })
    dialogHandlers.setShowCustomErrors(false)
    dialogHandlers.selectRelationship(prevIndex)
  }, [dialogState.isOldDirty, dialogState.selectedRelationshipIndex, dialogHandlers, validateAndSaveData])

  const nextRelationshipHandler = useCallback(async () => {
    const isSuccess = await validateAndSaveData({})
    if (!isSuccess) {
      return
    }

    dialogHandlers.selectNextRelationship()
  }, [dialogHandlers, validateAndSaveData])

  const { Confirmation: FinalizeConfirmation, open: openFinalizeConfirmation } = useConfirmation({
    message: `Are you sure you want to proceed?`,
    onConfirm: onSubmit,
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: true
  })

  const { Confirmation: CancelConfirmation, open: openCancelConfirmation } = useConfirmation({
    message: `You have unsaved data. If you close this window, the changes will be lost. Are you sure?`,
    onConfirm: () => onCancel(),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: true
  })

  const handleCloseDialog = useCallback(() => {
    if (dialogState.isOldDirty) {
      openCancelConfirmation()
      return
    }
    onCancel()
  }, [onCancel, dialogState.isOldDirty, openCancelConfirmation])

  const bankAccountFormList = useMemo(() => {
    const numberKeys = Object.keys(dialogState.formDataList).filter((key) => key !== 'new')
    return numberKeys.map((key) => dialogState.formDataList[key])
  }, [dialogState.formDataList])

  const currentCompanyIndex = selectedRelationshipIndex + 1
  const totalNumberOfRelationships = relationships?.length || 0
  const titleDialogRelationshipsCursor = `Company (${currentCompanyIndex}/${totalNumberOfRelationships})`
  const subTitleRelationshipName = currentRelationship?.name || ''

  const noLicenses = !licenses.length

  const isAllLicensesAdded = !helpers.isExistsUnAssigned({ licenses: licensesCurrentState.licensesWithoutNewForm })
  const isDisabledAddAccountButton = !dialogState.isAddBankAccountFormValid
  const isDisabledAddButtonHint = !isAllLicensesAdded
  const addTooltipContent = helpers.getAddTooltipContent({ isAllLicensesAdded })

  const isLoading = isRelationshipsBankAccountsLoading || isSaveRelationshipBankAccountsLoading
  const isFooterSaving = isLoading
  const isFormsDisabled = isLoading

  const isNextDisabled = false
  const isShowAddNewFrom = !isAllLicensesAdded

  const isRelationshipsLoaded = !!dialogState.relationshipsBankAccounts
  const isShowSubmitBtn = dialogState.isReadyForSubmit && selectedRelationshipIndex === totalNumberOfRelationships - 1
  return {
    footerState: {
      isPrevDisabled: selectedRelationshipIndex < 1,
      isNextDisabled,
      isSaving: isFooterSaving,
      isFinalizing,
      isShowPrevBtn: selectedRelationshipIndex > 0,
      isShowSubmitBtn,
      onPrev: prevRelationshipHandler,
      onNext: nextRelationshipHandler,
      onFinish: openFinalizeConfirmation
    },
    handlers: {
      removeBankAccount: handleRemoveBankAccount,
      formOnChange: handleFormOnChange,
      addBankAccount: handleAddBankAccount,
      closeDialog: handleCloseDialog
    },
    bankAccountFormsData: {
      assignedLicensesSelectData,
      validateFunctionRefs,
      formDataList: dialogState.formDataList
    },
    ui: {
      FinalizeConfirmation,
      CancelConfirmation,
      formExpandedList: dialogState.formExpandedList,
      showFormErrors: dialogState.showFormErrors,
      titleDialogRelationshipsCursor,
      subTitleRelationshipName,
      isRelationshipsLoaded,
      bankAccountFormList,
      isFormsDisabled,
      isShowAddNewFrom,
      licenses: licensesCurrentState.licensesWithoutNewForm,
      noLicenses,
      currentCompanyIndex,
      totalNumberOfRelationships: dialogState.relationshipsBankAccounts?.relationships.length || 0,
      isDisabledAddAccountButton,
      isDisabledAddButtonHint,
      addTooltipContent
    }
  }
}
