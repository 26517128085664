import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

const CountryRenderer = ({
  field,
  index
}: {
  field: FieldApi<SarEdit, 'institutionActivitiesInfo[0].country'>
  index: number
}) => {
  const { formApi } = useSARFormContext()
  const stateKeyName = `institutionActivitiesInfo[${index}].state` as 'institutionActivitiesInfo[0].state'

  useEffect(() => {
    if (typeof field.state.value !== 'undefined' && field.state.value !== 'US') {
      formApi.setFieldValue(stateKeyName, '')
      formApi.setFieldMeta(stateKeyName, (stateMeta) => ({
        ...stateMeta,
        errors: [],
        errorMap: {}
      }))
    }
  }, [field.state.value, formApi, stateKeyName])

  return (
    <FF
      required
      label="65. Country"
      field={field}
      input={(p) => (
        <Select {...p} data={usAndTerritories} data-qa="65. Country" options-data-qa="65. Country option" />
      )}
    />
  )
}

export const Country = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].country` as 'institutionActivitiesInfo[0].country'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <CountryRenderer field={field} index={index} />}
    />
  )
}
