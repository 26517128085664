import { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import { FooterProps } from './FooterProps'

import * as SC from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { onDelete, onCancel, onSubmit, isSubmitting, disabled, isSarEditForm } = props

  return (
    <SC.Box>
      {isSarEditForm && (
        <SC.DeleteButton icon={IconName.CAN} face="negative" onClick={onDelete} data-qa="Delete Page">
          Delete Page
        </SC.DeleteButton>
      )}
      <ButtonGroup margin="small">
        <Button face="neutral" onClick={onCancel} data-qa="Cancel">
          Cancel
        </Button>
        <Button loading={isSubmitting} disabled={disabled} onClick={onSubmit} face="positive" data-qa="Save">
          Save
        </Button>
      </ButtonGroup>
    </SC.Box>
  )
})
