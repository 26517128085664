import { sarSubjectRoleInSuspiciousActivityTypes } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type RoleInActivityProps = {
  index: number
}

export const RoleInActivity = ({ index }: RoleInActivityProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index}].roleInActivity` as 'subjectsInfo[0].roleInActivity'}
      children={(field) => (
        <FF
          field={field}
          label="28. Subject's role in suspicious activity"
          input={(p) => <Select {...p} data={sarSubjectRoleInSuspiciousActivityTypes} emptyOption={EMPTY_OPTION} />}
        />
      )}
    />
  )
}
