import { FC, memo } from 'react'

import { useBankPortalContext } from 'bank-portal-context'
import { Request } from 'commons/utils/request'
import isArray from 'lodash/isArray'
import { ChoiceOption } from 'ui/types'

import { SingleSelectAutoSuggest } from '../SingleSelectAutoSuggest'
import { CompanySelectProps } from './CompanySelectProps'

const PLACEHOLDER = 'Start entering the company name'

export const CompanySelect: FC<CompanySelectProps> = memo((props: CompanySelectProps) => {
  const { value, emptyOption, defaultOption, hideMyCompaniesFilter, filters, isSearchRequest = false, ...rest } = props

  const {
    layout: { assignedCompaniesIDs }
  } = useBankPortalContext()

  const options: ChoiceOption[] = rest?.preloadedOptions || []

  if (defaultOption) {
    options.push(defaultOption)
  }

  if (assignedCompaniesIDs.length && !hideMyCompaniesFilter) {
    options.push({
      label: 'My',
      value: assignedCompaniesIDs
    })
  }

  const serverOptions = isSearchRequest
    ? {
        filters,
        url: Request.urls.bank.companySearch,
        requestSearchString: 'searchString',
        limit: 500,
        mapper: (item) => ({ label: `${item.companyName}`, value: item.id })
      }
    : {
        filters,
        valueItemFilter:
          typeof value !== 'string' ? { field: 'id', type: isArray(value) ? 'in' : 'eq', value } : undefined,
        url: Request.urls.bank.companyList,
        limit: 500,
        mapper: (item) => ({ label: item.name, value: item.id }),
        searchFields: ['name']
      }

  return (
    <SingleSelectAutoSuggest
      noFirstRequest
      placeholder={PLACEHOLDER}
      options={options}
      serverOptions={serverOptions}
      value={value}
      {...rest}
    />
  )
})
