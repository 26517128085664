import { FC, memo } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'

import { WarningsProps } from './Warnings.types'

import { s } from './styles'

export const Warnings: FC<WarningsProps> = memo(({ attention, onResolve }) => (
  <s.Box>
    <s.Title $weight={700}>
      <s.WarningIcon name={IconName.ERROR_RED} size={IconSize.M} /> Warning(s)
    </s.Title>
    <s.List>
      {attention.split('\n').map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </s.List>
    <s.ResolveButton onClick={onResolve}>Mark all warnings as resolved</s.ResolveButton>
  </s.Box>
))
