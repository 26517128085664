export type LicenseManageRequest = {
  id?: number | null
  name?: string | null
  active?: boolean | null
  companyId?: number
  type?: string | null
  licenseNumber?: string | null
  subtype?: string | null
  issueDate?: string | null
  posType?: string | null
  posTypeOther?: string | null
  phone?: string | null
  cityTax?: number | null
  countyTax?: number | null
  stateTax?: number | null
  mjRetailTax?: number | null
  specialTax?: number | null
  streetAddress?: string | null
  state?: string | null
  city?: string | null
  postalCode?: string | null
}
