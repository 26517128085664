import { Info } from 'ui/components'
import { CompanySelect, Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { FilterProps } from './Filter.types'

import { s } from './styles'

export const Filter = (props: FilterProps) => {
  const { statusOptions, isDirty, toggle, expanded, value, set } = props

  return (
    <FilterPanel isDirty={isDirty} onReset={() => set({ reset: true })} expanded={expanded} onToggle={toggle}>
      <s.Columns>
        <s.Column>
          <Info label="Status">
            <Select
              value={value.status}
              data={statusOptions}
              onChange={(v) => set({ newValue: { status: v as string } })}
            />
          </Info>
        </s.Column>
        <s.Column>
          <Info label="Company">
            <CompanySelect
              hideMyCompaniesFilter
              value={value.relationshipId}
              onChange={(v) => set({ newValue: { relationshipId: v as number } })}
              emptyOption={{ label: 'Clear filter', value: null }}
            />
          </Info>
        </s.Column>
      </s.Columns>
    </FilterPanel>
  )
}
