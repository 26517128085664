import { FC } from 'react'

import { Link } from '@tanstack/react-router'
import { IconName, IconSize } from 'ui/components'

import * as SC from './styles'

export const ReportBreadcrumbs: FC<{ title: string }> = ({ title }) => (
  <SC.Title>
    <Link to="/reports">
      <h1>Reports</h1>
    </Link>
    <SC.Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
    <h1>{title}</h1>
  </SC.Title>
)
