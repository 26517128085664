import { FC, memo, useCallback, useState } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { HeaderButton } from 'ui/components/Buttons'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Table } from 'ui/components/Table'
import { useConfirmation } from 'ui/hooks'

import { ContactRelatedCompanies } from '../../../../types'
import { ContactCompaniesTableProps } from './ContactCompaniesTableProps'
import { columns } from './columns'
import { selector } from './selector'

export const ContactCompaniesTable: FC<ContactCompaniesTableProps> = memo((props) => {
  const { onAdd, onEdit, onDetach, relatedCompanies, contactId } = props
  const [item, setItem] = useState<ContactRelatedCompanies | null>(null)

  const { open, Confirmation: DeleteConfirmation } = useConfirmation({
    message: `Are you sure want to detach contact from ${item?.name} company?`,
    onConfirm: () => {
      if (item) {
        onDetach(item.id, contactId)
      }
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startContactDelete = useCallback(
    (item: ContactRelatedCompanies) => {
      setItem(item)
      open()
    },
    [setItem, open]
  )

  return (
    <PageSection
      face={PageSectionFace.PRIMARY}
      title="Related Companies"
      actions={
        <HeaderButton size="middle" onClick={onAdd}>
          Add New
        </HeaderButton>
      }
    >
      <DeleteConfirmation />
      <Layer rounded shadowed>
        <Table
          dataQa={TableDataQa.BP_CONTACT_RELATED_COMPANIES}
          columns={columns(onEdit, startContactDelete)}
          dataSource={selector(relatedCompanies)}
        />
      </Layer>
    </PageSection>
  )
})
