import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'
import { ReviewTheCashActiviyProps } from './ReviewTheCashActiviyProps'

export const ReviewTheCashActivity = ({ formValue, Field }: ReviewTheCashActiviyProps) => (
  <>
    <MainText>Deposits apparently structured to avoid Currency Transaction Report (CTR) requirements.</MainText>
    <SecondText>
      Review the cash activity amounts for repeated deposits/withdrawals at the $10,000.00 threshold.
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewTheCashActivityRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewTheCashActivityStructuringDeposits"
          label="Has the business been structuring?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'No Transactions of This Type',
                value: 'no_transactions'
              },
              {
                label: 'All Transactions Ok',
                value: 'all_transactions_ok'
              },
              {
                label: 'Transactions addressed with communication to customer during review',
                value: 'transactions_addressed'
              },
              {
                label: 'Other – See comments',
                value: 'other'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={formValue.reviewTheCashActivityStructuringDeposits === 'other'}
          name="reviewTheCashActivityComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.reviewTheCashActivityStructuringDeposits === 'other' ? Validators.required.field : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
