import { Suspense, lazy } from 'react'

import { AnyRoute, createRoute, notFound, useLoaderData } from '@tanstack/react-router'
import { getOnboardingDocumentDetailsWebPage } from 'commons/service/bank/onboardingDocuments'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { Sidebar } from '../../components/Sidebar'

const SetupDocumentsViewDetails = lazy(() => import('./SetupDocumentsViewDetails'))

export const setupDocumentsViewDetailsRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '$id/details',
    validateSearch: (search: Record<string, unknown>): { page: number; size: number } => ({
      page: Number(search?.page) || 1,
      size: Number(search?.size) || 10
    }),
    loaderDeps: ({ search }) => search,
    loader: async ({ params, deps }: any) => {
      const response: any = await getOnboardingDocumentDetailsWebPage({
        id: Number(params?.id) || 0,
        historyPage: deps.page,
        historySize: deps.size
      })

      if (!response?.companies) {
        throw notFound()
      }

      return {
        webPageData: response
      }
    },
    component: () => {
      const { webPageData } = useLoaderData({ strict: false })

      if (!webPageData) throw notFound()

      return (
        <BPLayout sidebar={<Sidebar companies={webPageData.companies} withGeneralAndHistorySections={true} />}>
          <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
            <SetupDocumentsViewDetails />
          </Suspense>
        </BPLayout>
      )
    }
  })
