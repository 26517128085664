import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'
import { useSubjectInformationItemContext } from '../SubjectInformationItemContext'

type EntityNameOrLastNameProps = {
  index: number
}

export const EntityNameOrLastName = ({ index }: EntityNameOrLastNameProps) => {
  const { formApi } = useSARFormContext()
  const entityName = `subjectsInfo[${index}].entityName` as 'subjectsInfo[0].entityName'
  const lastName = `subjectsInfo[${index}].lastName` as 'subjectsInfo[0].lastName'
  const isEntity = formApi.useStore((s) => s.values.subjectsInfo[index].isEntity)
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={isEntity ? entityName : lastName}
      validators={{
        onChange: ({ value }) => (isAllInfoUnavailable ? undefined : Validators.required.field(value))
      }}
      children={(field) => (
        <FF
          required={!isAllInfoUnavailable}
          field={field}
          label="4. Individual's last name or entity's legal name"
          input={(p) => (
            <TextInput
              {...p}
              data-qa="4. Individual's last name or entity's legal name"
              disabled={isAllInfoUnavailable}
            />
          )}
        ></FF>
      )}
    />
  )
}
