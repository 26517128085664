import { FC } from 'react'

const CheckboxContainer: FC = () => (
  <svg width="30" height="74" viewBox="0 0 30 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38 -59C33.5817 -59 30 -55.4183 30 -51V7.29668e-07C30 
    5.52285 25.5228 10 20 10V10C8.95431 10 0 18.9543 0 30V44C0 55.0457 8.95431 64 20 64V64C25.5228 64 30 
    68.4772 30 74V125C30 129.418 33.5817 133 38 133H52C56.4183 133 60 129.418 60 125V-51C60 -55.4183 56.4183 
    -59 52 -59H38Z"
      fill="white"
    />
  </svg>
)

export default CheckboxContainer
