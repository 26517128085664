import { FC, memo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { Page, PageFace } from 'ui/components/Page'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'

import { HeaderActions } from './components/HeaderActions'
import { History } from './components/History'
import { Licensing } from './components/Licensing'
import { Overview } from './components/Overview'
import { Questionnaire } from './components/Questionnaire'
import { SummaryTables } from './components/SummaryTables'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

const AnnualReviewDetails: FC = memo(() => {
  const { id } = routeApi.useParams()

  const loadData = routeApi.useLoaderData()

  return (
    <Page
      isPending={!loadData}
      title="MRB Annual Relationship Review"
      face={PageFace.SECONDARY}
      subTitle={`AR-${id}`}
      actions={<HeaderActions />}
    >
      {!!loadData && (
        <>
          <Overview />
          <Licensing />
          <Questionnaire />
          <SummaryTables />
          <History />
        </>
      )}
    </Page>
  )
})

export default AnnualReviewDetails
