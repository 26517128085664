import { useCallback, useMemo, useState } from 'react'

import { useDeleteLicense } from 'commons/hooks/bank/license'
import { FilterCompanyLicensesWebPage } from 'commons/service/bank/companyDetails'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useTableState } from 'ui/components/QueryTable'
import { useConfirmation } from 'ui/hooks'

import { MappedLicense } from '../MappedLicense'

export const useComponentEssence = (
  companyId: number,
  filterCompanyLicensesWebPage: (filter: FilterCompanyLicensesWebPage) => void,
  licensesTotalCount: number
) => {
  const [loading, setLoading] = useState(false)

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      licensesOffset: undefined,
      licensesLimit: undefined
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState<{
    relationshipID: number
    licensesOffset: number | undefined
    licensesLimit: number | undefined
  }>(defaultFilter)

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = { ...tableFilter, licensesOffset: (page - 1) * size, licensesLimit: size }

      setTableFilter(filter)

      await filterCompanyLicensesWebPage(filter)

      setLoading(false)
    },
    [filterCompanyLicensesWebPage, tableFilter, tableState]
  )

  const getCompanyLicensesAfterDeleteWebPage = () => {
    setLoading(true)

    const isDeletingLicensesLastOnPage = licensesTotalCount === (tableFilter.licensesOffset || 0) + 1

    const offset = isDeletingLicensesLastOnPage
      ? (tableFilter?.licensesOffset || 0) - (tableFilter?.licensesLimit || 0)
      : tableFilter.licensesOffset

    filterCompanyLicensesWebPage({
      ...tableFilter,
      licensesOffset: offset
    })

    tableState.onPageChange(isDeletingLicensesLastOnPage ? tableState.page - 1 : tableState.page, tableState.size)

    const filter = { ...tableFilter, licensesOffset: offset }

    setTableFilter(filter)

    setLoading(false)
  }

  const [licenseToDelete, setLicenseToDelete] = useState<MappedLicense | undefined>(undefined)
  const { routine: deleteLicense } = useDeleteLicense({
    onSuccess: async () => {
      getCompanyLicensesAfterDeleteWebPage()
      Toast.successSmall(`License "${licenseToDelete?.name}" was deleted.`)
    },
    onError: (error) => handlerServerError(error)
  })

  const { Confirmation: DeleteConfirmation, open } = useConfirmation({
    message: `Are you sure want to delete "${licenseToDelete?.name || 'this'}" license?`,
    onConfirm: () => (licenseToDelete?.id ? deleteLicense(licenseToDelete?.id) : Promise.resolve()),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const openDeleteConfirmation = useCallback(
    (item: MappedLicense) => {
      setLicenseToDelete(item)
      open()
    },
    [open, setLicenseToDelete]
  )

  return { loading, tableState, changePagination, openDeleteConfirmation, DeleteConfirmation }
}
