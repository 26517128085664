import { useState } from 'react'

import { DialogFace, Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { documentFileDelete, documentFileUpdate, documentFileUpload } from '../services'

export const useFilesActions = (documentId: number, invalidate: () => Promise<void>) => {
  const [isUploading, setIsUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isShowingManageDialog, setIsShowingManageDialog] = useState(false)
  const [fileToManage, setFileToManage] = useState({ id: 0, name: '' })
  const [fileToBeDeleted, setFileToBeDeleted] = useState<number | null>(null)

  const uploadFiles = async (files: File[]) => {
    setIsUploading(true)
    for (const file of files) {
      await documentFileUpload(documentId, file).catch((err: Error) => {
        Toast.errorSmall(err.message)
      })
    }
    await invalidate()
    setIsUploading(false)
  }

  const updateFile = async (name: string) => {
    setIsUpdating(true)
    await documentFileUpdate(fileToManage.id, name)
    await invalidate()
    setIsUpdating(false)
    setIsShowingManageDialog(false)
  }

  const deleteFile = async () => {
    setIsDeleting(true)
    if (fileToBeDeleted) {
      await documentFileDelete(fileToBeDeleted)
    }
    await invalidate()
    setIsDeleting(false)
  }

  const { open: openDeleteConfirmation, Confirmation: DeleteConfirmation } = useConfirmation({
    face: DialogFace.DEFAULT,
    message: 'Are you sure you want to delete this file?',
    onConfirm: deleteFile,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startDeleting = (id: number) => {
    setFileToBeDeleted(id)
    openDeleteConfirmation()
  }

  const startUpdating = ({ id, name }: { id: number; name: string }) => {
    setFileToManage({ id, name })
    setIsShowingManageDialog(true)
  }

  return {
    isUploading,
    isDeleting,
    isUpdating,
    updateFile,
    uploadFiles,
    startDeleting,
    DeleteConfirmation,
    isShowingManageDialog,
    setIsShowingManageDialog,
    startUpdating,
    fileToManage
  }
}
