import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { DatePicker, Select } from 'ui/components'
import { FilterPanel } from 'ui/components/Table'

import { Props } from './FilterForm.types'
import { useFilter } from './useFilter'

import { s } from './styles'

export const FilterForm: FC<Props> = memo((props) => {
  const { onGenerateCTRs, data, tableConfig } = props
  const { onFilterChange } = tableConfig
  const { filingTypeOptions, statusOptions } = data

  const { isDirty, config, Field, reset } = useFilter({ data, onChange: onFilterChange, onClear: tableConfig.onClear })

  return (
    <FilterPanel isDirty={isDirty} onReset={reset}>
      <s.FormWrapper>
        <Form config={config}>
          <s.Row>
            <Field name="transactionDate" label="Transaction Date" input={DatePicker} inputProps={{}} />
            <s.GenerateButton disabled={!config.value.transactionDate} face="neutral" onClick={onGenerateCTRs}>
              Generate CTRs
            </s.GenerateButton>
            <Field
              name="status"
              label="Status"
              input={Select}
              inputProps={{
                data: statusOptions
              }}
            />
            <Field
              name="filingType"
              label="Filing Type"
              input={Select}
              inputProps={{
                data: filingTypeOptions
              }}
            />
          </s.Row>
        </Form>
      </s.FormWrapper>
    </FilterPanel>
  )
})
