import { memo } from 'react'

import { EntityHistory, HistoryEntityType } from 'bank-entity-history'
import { TableDataQa } from 'commons/types/enums'
import { Dialog, DialogFace } from 'ui/components/Dialog'

import { EntityHistoryWrapper } from './style'

interface Props {
  id: number
  onClose: () => void
  searchTerm: string
}

export const SearchCaseHistoryDialog = memo((props: Props) => {
  const { id, onClose, searchTerm } = props

  return (
    <Dialog
      id="search-case-history"
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={searchTerm}
      subTitle="Search Case History"
      headerStyle={{
        padding: '12px 40px'
      }}
      box="#adverse-general-information"
    >
      <EntityHistoryWrapper>
        <EntityHistory
          hideFilter
          name="company"
          dataQa={TableDataQa.BP_ADVERSE_MEDIA_SEARCH_CASE_HISTORY}
          id={id}
          type={HistoryEntityType.ADVERSE_MEDIA_CASE}
        />
      </EntityHistoryWrapper>
    </Dialog>
  )
})
