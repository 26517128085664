import { FC } from 'react'

import { SubMenuItem } from 'commons/types/DTO/commons'
import { InnerMenu } from 'ui/components/SideMenu'

import { SidebarProps } from './Sidebar.types'

export const Sidebar: FC<SidebarProps> = ({ companies, withGeneralAndHistorySections = false }) => {
  let items: SubMenuItem[] = []

  items = items.concat(
    companies?.map((company) => ({
      label: company.companyName,
      hash: company.companyName.toLowerCase().replace(/\s+/g, '-')
    }))
  )

  if (withGeneralAndHistorySections) {
    items.unshift({
      label: 'General',
      hash: 'general'
    })
    items.push({
      label: 'History',
      hash: 'history'
    })
  }

  return <InnerMenu items={items} />
}
