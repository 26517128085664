import { useGetOnboardingDocumentSigners } from 'commons/hooks/bank/onboardingSetupDocuments'
import { OnboardingDocumentSignerItem } from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Dialog, DialogFace, Table } from 'ui/components'

import { SignersListModalProps } from './SignersListModal.types'
import { columns } from './columns'

export const SignersListModal = ({ onClose, id }: SignersListModalProps) => {
  const { data, isFetching } = useGetOnboardingDocumentSigners(id)

  const mappedItems: DataSourceItem<OnboardingDocumentSignerItem>[] | undefined = data?.items?.map((elem) => ({
    item: elem
  }))

  return (
    <Dialog id="onboarding-document-signers" face={DialogFace.PRIMARY} title="Signers" onClose={() => onClose()}>
      <Table
        dataQa={TableDataQa.BP_ONBOARDING_DOCUMENTS_SIGNERS}
        columns={columns}
        dataSource={mappedItems ?? []}
        loading={isFetching}
      />
    </Dialog>
  )
}
