import { memo } from 'react'

import { BaseInput } from './BaseInput'
import { BaseInputProps } from './props'

export const MetrcKeyInput = memo((props: BaseInputProps) => {
  return (
    <BaseInput
      {...props}
      validator={(value: string) => (value.length === 48 ? undefined : 'Metrc key should be 48 symbols length')}
    />
  )
})
