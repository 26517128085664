import { FC, memo } from 'react'

import { SubMenuItem } from 'commons/types/DTO/commons'
import { InnerMenu } from 'ui/components/SideMenu'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { LicenseSideBarProps } from './LicenseSideBarProps'

export const LicenseSideBar: FC<LicenseSideBarProps> = memo((props) => {
  const { availableItems } = props
  const items: SubMenuItem[] = []

  if (!availableItems) {
    return <Spinner centered size={SpinnerSize.M} />
  }
  if (availableItems.includes('general-info')) {
    items.push({ label: 'General Information', hash: 'general' })
  }
  if (availableItems.includes('invoice-submittal')) {
    items.push({ label: 'Invoice Submittal', hash: 'invoices' })
  }
  if (availableItems.includes('documents')) {
    items.push({ label: 'Documents', hash: 'documents' })
  }
  if (availableItems.includes('history')) {
    items.push({ label: 'History', hash: 'history' })
  }

  return <InnerMenu items={items} />
})
