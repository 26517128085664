import { FC, memo } from 'react'

import { Icon, IconName } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'

import * as SC from './styles'

interface Props {
  id: number
  note?: string
}

export const LastNote: FC<Props> = memo((props) => {
  const { id, note } = props

  return (
    <>
      {note ? (
        <Tooltip
          id={`last-note-tip-${id}`}
          type="dark"
          content={<SC.NoteTooltipContent>{note}</SC.NoteTooltipContent>}
          event="hover"
          place="top"
        >
          <Icon name={IconName.DIALOG_GREEN} />
        </Tooltip>
      ) : (
        <Icon name={IconName.DIALOG} />
      )}
    </>
  )
})
