import { FC, memo, useMemo } from 'react'

import { DailyReport } from 'commons/types/DTO/bank/salesDataSourceWebPage/DailyReport'
import { TableDataQa } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils'
import { DataSourceItem, IconName, IconSize, Layer, PageSection, Table } from 'ui/components'

import { POSSourceTableProps } from './POSSourceTableProps'

import { ErrorIcon } from './styles'

export const POSSourceTable: FC<POSSourceTableProps> = memo((props) => {
  const { webPage } = props

  const dataSource: DataSourceItem<DailyReport>[] = useMemo(
    () =>
      webPage.dailyReportsPOS.map((daily) => ({
        item: daily,
        rowProps: { errorHighlight: daily.alert.length > 0 }
      })),
    [webPage.dailyReportsPOS]
  )

  return (
    <PageSection title="Daily POS Data" anchor="pos">
      <Layer shadowed rounded>
        <Table<DailyReport>
          dataQa={TableDataQa.BP_SALESDATA_SOURCE_DETAILS_POS_SOURCE}
          columns={[
            {
              title: 'Date',
              key: 'date',
              sortable: false,
              align: 'left'
            },
            {
              title: 'Alert',
              sortable: false,
              align: 'left',
              render: ({ alert }) => (
                <>
                  {alert.length > 0 && (
                    <>
                      <ErrorIcon size={IconSize.S} name={IconName.ERROR} /> {alert}
                    </>
                  )}
                </>
              )
            },
            {
              title: 'Total Sales Amount $',
              sortable: false,
              align: 'left',
              render: ({ totalSold }) => formatCurrencyCent(totalSold)
            }
          ]}
          dataSource={dataSource}
        />
      </Layer>
    </PageSection>
  )
})
