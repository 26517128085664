import { Reducer, useCallback, useMemo, useReducer } from 'react'

import { TableSorting, TableSortingChangeHandler } from 'ui/components'

import calculateInitState from '../helpers/calculateInitState'
import { DocumentListFilters } from '../types'
import { tableStateReducer } from './tableStateReducer'
import {
  PaginationChangeHandler,
  TableState,
  TableStateAction,
  TableStatePagination,
  onChangeTableState
} from './types'

type UseTableStateHookParams = {
  externalTableState?: Partial<TableState>
  initFilters?: DocumentListFilters
}

type UseTableStateHookReturn = {
  expanded: boolean
  shouldInvalidate: boolean
  shouldClearFilter: Record<PropertyKey, never>
  pagination: TableStatePagination
  sorting: TableSorting
  handlers: {
    handleWebPage: ({ total }: { total: number }) => void
    setFirstPage: () => void
    onInvalidate: () => void
    offInvalidate: () => void
    onFilterExpanded: (expanded: boolean) => void
    onFilterClearClick: () => void
  }
}

type UseTableStateHook = (params: UseTableStateHookParams) => UseTableStateHookReturn

export const useTableState: UseTableStateHook = ({ externalTableState, initFilters }) => {
  const [tableState, tableStateDispatch] = useReducer<Reducer<TableState, TableStateAction>>(
    tableStateReducer,
    calculateInitState({ externalTableState, initFilters })
  )

  const handlePaginationChange: PaginationChangeHandler = useCallback(
    (page, size) => tableStateDispatch(onChangeTableState({ page, pageSize: size, shouldInvalidate: true })),
    []
  )

  const handleSortingChange: TableSortingChangeHandler = useCallback((value) => {
    tableStateDispatch(onChangeTableState({ order: [value], shouldInvalidate: true }))
  }, [])

  const handleWebPage = useCallback(
    ({ total }: { total: number }) => tableStateDispatch(onChangeTableState({ total, shouldInvalidate: false })),
    []
  )
  // rename
  const offInvalidate = useCallback(() => tableStateDispatch(onChangeTableState({ shouldInvalidate: false })), [])
  const onInvalidate = useCallback(() => tableStateDispatch(onChangeTableState({ shouldInvalidate: true })), [])

  const handleFilterSetExpanded = useCallback(
    (expanded: boolean) => tableStateDispatch(onChangeTableState({ expanded, shouldInvalidate: false })),
    []
  )

  const setFirstPage = useCallback(() => tableStateDispatch(onChangeTableState({ page: 1 })), [])

  const handleFilterClearClick = useCallback(
    () => tableStateDispatch(onChangeTableState({ shouldClearFilter: {} })),
    []
  )

  const handlers = useMemo(
    () => ({
      handleWebPage,
      offInvalidate,
      onInvalidate,
      onFilterExpanded: handleFilterSetExpanded,
      setFirstPage,
      onFilterClearClick: handleFilterClearClick
    }),
    [handleWebPage, onInvalidate, offInvalidate, handleFilterSetExpanded, setFirstPage, handleFilterClearClick]
  )

  return {
    shouldInvalidate: tableState.shouldInvalidate,
    expanded: tableState.expanded,
    shouldClearFilter: tableState.shouldClearFilter,
    pagination: {
      page: tableState.page,
      pageSize: tableState.pageSize,
      total: tableState.total,
      onChange: handlePaginationChange
    },
    sorting: {
      order: tableState.order,
      onChange: handleSortingChange // TableSortingChangeHandler
    },
    handlers
  }
}
