import { Profiler as ReactProfiler, memo, useCallback } from 'react'

interface Props {
  id: string
  name: string
  children: any
  suspended?: boolean
}

export const Profiler = memo((props: Props) => {
  const { id, name, children, suspended } = props

  if (suspended) {
    return children
  }

  const onRender = useCallback(
    (
      id: string,
      phase: 'mount' | 'update',
      actualDuration: number,
      baseDuration: number,
      startTime: number,
      commitTime: number
    ) => {
      // eslint-disable-next-line no-console
      console.log(name, {
        Id: id,
        Phase: phase,
        Duration: actualDuration,
        'Base Duration': baseDuration,
        'Start Time': startTime,
        'Commit Time': commitTime
      })
    },
    [name]
  )

  return (
    <ReactProfiler id={id} onRender={onRender}>
      {children}
    </ReactProfiler>
  )
})
