import { FC } from 'react'

import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Tooltip } from 'ui/components/Tooltip'

import { s } from './styles'

enum TooltipCfg {
  pastDue = 'Past Due'
}

export const AlertsDueTooltip: FC<{ id: number; label: string }> = ({ id, label }) => (
  <s.DueRow>
    <Tooltip
      id={`firstHint${id}`}
      type={label === TooltipCfg.pastDue ? 'error' : 'warning'}
      event="hover"
      content={label}
    >
      <s.TooltipContent>
        {label && (
          <Icon
            name={label === TooltipCfg.pastDue ? IconName.EXCLAMATION : IconName.EXCLAMATION_ORANGE}
            size={IconSize.S}
          />
        )}
      </s.TooltipContent>
    </Tooltip>
  </s.DueRow>
)
