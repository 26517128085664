import { useRouterState } from '@tanstack/react-router'
import { InnerMenu } from 'ui/components/SideMenu'

const CTRSideBar = () => {
  const pathname = useRouterState({ select: (s) => s.location.pathname })

  const items = [
    { label: 'General Information', hash: 'general' },
    { label: 'Step 1', hash: 'step1' },
    { label: 'Step 2', hash: 'step2' },
    { label: 'Step 3', hash: 'step3' },
    { label: 'Step 4', hash: 'step4' }
  ]

  if (!pathname.endsWith('/edit')) {
    items.push({ label: 'History', hash: 'history' })
  }

  return <InnerMenu items={items} />
}

export default CTRSideBar
