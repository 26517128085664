import { LicenseTypeLabel } from 'ui/components/Labels'
import { TableColumn } from 'ui/components/Table'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

export const columns = (): TableColumn<AnnualReviewDetails['licensingItems'][number]>[] => [
  {
    title: 'License Type',
    align: 'left',
    render: ({ type }) => <LicenseTypeLabel name={type} />
  },
  {
    title: 'Issue Date',
    align: 'center',
    render: ({ issueDate }) => <>{issueDate}</>
  },
  {
    title: 'Expiration Date',
    align: 'center',
    render: ({ expirationDate }) => <>{expirationDate}</>
  },
  {
    title: 'Number',
    align: 'center',
    key: 'licenseNumber'
  },
  {
    title: 'Location',
    align: 'center',
    key: 'location'
  }
]
