import styled from 'styled-components'

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const Th = styled.td`
  text-align: center;
  font-weight: bold;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

  @media print {
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    -webkit-print-color-adjust: exact;
  }
`

const Td = styled.td`
  text-align: center;
  border: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px;
`
const Text = styled.span``
const Title = styled.h3``
const Hr = styled.div`
  width: 100%;
  border-bottom: 2px solid #000;
  margin-bottom: 20px;
  transform: 'translateY(-2px)';
`

export const s = { Table, Th, Td, Text, Title, Hr }
