import { createContext, useContext } from 'react'

import { useForm } from '@tanstack/react-form'
import { SarEdit } from '~bank-bsa-reporting/types'

export type ManageSARContextValue = {
  formApi: ReturnType<typeof useForm<SarEdit, undefined>>
}

export const ManageSARContext = createContext<ManageSARContextValue | undefined>(undefined)

export const useSARFormContext = () => {
  const context = useContext(ManageSARContext)
  if (!context) {
    throw new Error('useSARFormContext must be used within a ManageSARContext.Provider')
  }
  return context
}
