import { useCallback, useEffect, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import isBoolean from 'lodash/isBoolean'
import { Select, Validators } from 'ui/components'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { FileSelector } from 'ui/components/FileSelector'
import { TextInput, TextareaInput } from 'ui/components/InputV2'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { DialogCol, FormRow } from 'ui/themes/globalStyles'
import { ChoiceOption } from 'ui/types'

import { ReviewFormUploadFormData } from './ReviewFormUploadFormData'
import { ReviewFormUploadProps } from './ReviewFormUploadProps'

import { Content, Files } from './styles'

export const ReviewFormUpload = ({
  onHideModal,
  onSave,
  onlyNotes,
  type,
  requiredNotes,
  title,
  notes,
  disabledDocumentType,
  internalDocumentNames
}: ReviewFormUploadProps) => {
  const { config, Field, isValid, isDirty } = useFormData<ReviewFormUploadFormData>({
    initialValue: {
      notes: notes || '',
      type: type,
      document_name: undefined,
      file: undefined
    }
  })

  const dataSource: ChoiceOption[] = []
  const [isSubmitting, setIsSubmitting] = useState(false)

  for (const key in internalDocumentNames) {
    dataSource.push({
      label: internalDocumentNames[key],
      value: key
    })
  }

  const [files, setFiles] = useState<File[]>([])
  const disabledByFiles = !onlyNotes && !files.length

  useEffect(() => {
    if (files.length) {
      config.onChange({ ...config.value, file: files[0] }, config.errors)
    }
  }, [files])

  const onSaveWrapper = useCallback(() => {
    setIsSubmitting(true)
    onSave(config.value, () => {
      setIsSubmitting(false)
    })
  }, [onSave, config.value])

  return (
    <LeaveConfirmation preventLeaving={isDirty && !isSubmitting}>
      <Dialog
        id="review-form-upload"
        onClose={onHideModal}
        face={DialogFace.PRIMARY}
        title={title}
        onSubmit={onSaveWrapper}
        footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
        submitDisabled={!isDirty || !isValid || disabledByFiles || isSubmitting}
      >
        <Content>
          <Form config={config}>
            {!onlyNotes && (
              <FormRow>
                <DialogCol>
                  <Field
                    name="type"
                    label="Document Type"
                    input={Select}
                    inputProps={{
                      data: dataSource,
                      disabled: isBoolean(disabledDocumentType) ? disabledDocumentType : true,
                      'data-qa': 'document_type',
                      'options-data-qa': 'document_type_item'
                    }}
                  />
                </DialogCol>
              </FormRow>
            )}

            {!onlyNotes && config.value.type === 'other' && (
              <FormRow>
                <DialogCol>
                  <Field
                    name="document_name"
                    label="Document Name"
                    input={TextInput}
                    inputProps={{
                      'data-qa': 'document_name'
                    }}
                  />
                </DialogCol>
              </FormRow>
            )}
            <FormRow>
              <DialogCol>
                <Field
                  name="notes"
                  label="Notes"
                  required={requiredNotes}
                  input={TextareaInput}
                  validator={requiredNotes ? Validators.required.field : undefined}
                  inputProps={{
                    'data-qa': 'notes'
                  }}
                />
              </DialogCol>
            </FormRow>
            {!onlyNotes && (
              <FormRow>
                <DialogCol>
                  <Files>
                    <FileSelector onFilesSelect={setFiles} mode="single-select" />
                    {!files.length && <span data-qa="filename">No File Chosen</span>}
                    {files.length > 0 && <span data-qa="filename">{files[0].name}</span>}
                  </Files>
                </DialogCol>
              </FormRow>
            )}
          </Form>
        </Content>
      </Dialog>
    </LeaveConfirmation>
  )
}
