import { FC, memo } from 'react'

import * as SC from '../styles'
import { formatCurrencyCent } from 'commons/utils'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { TextMask } from 'ui/components/TextMask'
import { Col, Row } from 'ui/themes/globalStyles'

import { FinancialInstitutionWhereActivityOccurredProps } from './FinancialInstitutionWhereActivityOccurred.types'

export const FinancialInstitutionWhereActivityOccurred: FC<FinancialInstitutionWhereActivityOccurredProps> = memo(
  (props) => {
    const { data } = props
    const { institutionActivitiesInfo } = data

    return (
      <PageSection
        face={PageSectionFace.THIRD}
        title="Step 2. Financial Institution Where Activity Occurred"
        anchor="step2"
      >
        {institutionActivitiesInfo?.map((item, index) => {
          const {
            type,
            typeOther,
            federalRegulator,
            idType,
            idNumber,
            roleInTransaction,
            legalName,
            dba,
            tin,
            tinType,
            address,
            city,
            country,
            state,
            zipCode,
            internalControlNumber,
            lossToFinInstitution,
            noBranchInvolved,
            institutionActivityBranchesInfo
          } = item

          return (
            <Panel
              rounded
              shadowed
              key={index}
              collapsible={false}
              title={`Part III Information about Institution ${index + 1} of ${institutionActivitiesInfo?.length}`}
              face={PanelFace.PRIMARY}
            >
              <SC.InnerPanel>
                <Row>
                  <Col>
                    <Info label="51. Type of financial institution">
                      {type === 'Other' && typeOther}
                      {type !== 'Other' && type}
                    </Info>
                  </Col>
                  <Col>
                    <Info label="52. Primary federal regulator">{federalRegulator}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="55. Financial institution identification Type">{idType}</Info>
                  </Col>
                  <Col>
                    <Info label="Number">{idNumber}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="56. Financial institution's role in transaction">{roleInTransaction}</Info>
                  </Col>
                  <Col>
                    <Info label="57. Legal name of financial institution">{legalName}</Info>
                  </Col>
                </Row>
                <Row>
                  <Info label="58. Alternate Name">{dba}</Info>
                </Row>
                <Row>
                  <Col>
                    <Info label="59. TIN">{tin ? <TextMask text={tin} iconSize={IconSize.XS} /> : null}</Info>
                  </Col>
                  <Col>
                    <Info label="60. TIN type">{tinType}</Info>
                  </Col>
                </Row>
                <Row>
                  <Info label="61-65. Address">
                    <div>{address}</div>
                    <div>{[city, country === 'US' ? state : null, zipCode].filter(Boolean).join(', ')}</div>
                    <div>{country}</div>
                  </Info>
                </Row>
                <Row>
                  <Info label="66. Internal control/file number">{internalControlNumber}</Info>
                </Row>
                <Row>
                  <Info label="67. Loss to financial institution">{formatCurrencyCent(lossToFinInstitution)}</Info>
                </Row>
                {!noBranchInvolved && (
                  <>
                    <SC.WrappedHr />
                    <Info>Branch where activity occurred information</Info>
                    {institutionActivityBranchesInfo?.map((item, index: number) => {
                      const { roleInTransaction, idNumber, address, city, country, state, zipCode } = item

                      return (
                        <div key={index}>
                          <Row>
                            <Col>
                              <Info label="68. Branch's role in transaction">{roleInTransaction}</Info>
                            </Col>
                            <Col>
                              <Info label="70. RSSD Number">{idNumber}</Info>
                            </Col>
                          </Row>
                          <Row>
                            <Info label="69. 71-74. Address of branch or office where activity occurred	">
                              <div>{address}</div>
                              <div>
                                {[city, country === 'US' ? state : null, zipCode].filter(Boolean).join(', ') || '-'}
                              </div>
                              <div>{country}</div>
                            </Info>
                          </Row>
                        </div>
                      )
                    })}
                  </>
                )}
              </SC.InnerPanel>
            </Panel>
          )
        })}
      </PageSection>
    )
  }
)
