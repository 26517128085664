import { FF, SecuredInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const TIN = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].tin` as 'institutionActivitiesInfo[0].tin'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="59. TIN"
          field={field}
          input={(p) => <SecuredInput {...p} maxLength={25} data-qa="59. TIN" />}
        />
      )}
    />
  )
}
