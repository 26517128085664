import { FC } from 'react'

import { DocumentStatuses } from 'commons/types/DTO/commons'

import { Chip } from '../../Chip'
import { DocumentPeriodStatusLabel } from '../DocumentPeriodStatusLabel'
import { getDocumentPeriodChip } from './getDocumentPeriodStatusChip'

export const DocumentPeriodStatusChip: FC<{
  name: DocumentStatuses
  withoutMargins?: boolean
}> = ({ name, withoutMargins }) => (
  <Chip face={getDocumentPeriodChip(name)} withoutMargins={withoutMargins}>
    <DocumentPeriodStatusLabel name={name} />
  </Chip>
)
