import { FC, memo } from 'react'

import * as SC from '../styles'
import { Form } from 'brief-form'
import { BusinessTypes } from 'commons/types/enums'
import { Icon, IconName, IconSize, Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { TextareaInput } from 'ui/components/InputV2'
import { EINInput } from 'ui/components/InputV2/EINInput'
import { Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { Tooltip } from 'ui/components/Tooltip'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { mapFilterObjectToValueLabelArray } from '../helpers'
import { CompanyAccountDetailsFormProps } from './CompanyAccountDetailsFormProps'
import { useCompanyAccountDetailsForm } from './useCompanyAccountDetailsForm'

export const CompanyAccountDetailsForm: FC<CompanyAccountDetailsFormProps> = memo((props) => {
  const {
    companyIndex,
    value,
    state: { isConsumerAccountAvailable },
    availableBusinessTypes,
    availableEntityTypes,
    onChange,
    businessTypesDescription
  } = props
  const { Field, config } = useCompanyAccountDetailsForm({ companyIndex, formData: value, onChange })
  const BusinessTypesList = mapFilterObjectToValueLabelArray(availableBusinessTypes)
  const EntityTypesList = mapFilterObjectToValueLabelArray(availableEntityTypes)

  return (
    <Form config={config}>
      <InfoSet legend="Account Details">
        <FormRow>
          <Col>
            <SC.BusinessTypeTooltip>
              <Field
                name={OnboardingFormFieldNames.BUSINESS_TYPE}
                label="Business Type"
                input={Select}
                inputProps={{
                  'data-qa': 'Business Type',
                  'options-data-qa': 'Business Type options',
                  data: BusinessTypesList
                }}
                required
                validator={Validators.required.field}
              />
              {businessTypesDescription.length > 0 && (
                <Tooltip
                  id="businessType"
                  type="dark"
                  event="hover"
                  content={
                    <SC.BusinessTypeTooltipContent>
                      {businessTypesDescription.map((el) => (
                        <li key={el.title}>
                          <b>{el.title}</b>
                          &nbsp;
                          {el.description}
                        </li>
                      ))}
                    </SC.BusinessTypeTooltipContent>
                  }
                >
                  <SC.BusinessTypeTooltipIcon tabIndex={0}>
                    <Icon name={IconName.INFO} size={IconSize.S} />
                  </SC.BusinessTypeTooltipIcon>
                </Tooltip>
              )}
            </SC.BusinessTypeTooltip>
          </Col>
          <Col>
            <Field
              name={OnboardingFormFieldNames.ENTITY_TYPE}
              label="Entity Type"
              input={Select}
              inputProps={{
                'data-qa': 'Entity Type',
                'options-data-qa': 'Entity Type options',
                data: EntityTypesList
              }}
              required
              validator={Validators.required.field}
            />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <Field
              name={OnboardingFormFieldNames.STATE_OF_INCORPORATION}
              label="State of Incorporation or Organization"
              input={Select}
              inputProps={{
                'data-qa': 'State of Incorporation or Organization',
                'options-data-qa': 'State of Incorporation or Organization options',
                data: getOptionsByListName(SelectListName.STATE_OF_INCORPORATION)
              }}
              required
              validator={Validators.required.field}
            />
          </Col>
          <Col>
            <Field
              name={OnboardingFormFieldNames.EIN}
              label={isConsumerAccountAvailable && config.value.businessType ? 'EIN/TIN #' : 'EIN #'}
              input={EINInput}
              inputProps={{
                'data-qa': isConsumerAccountAvailable && config.value.businessType ? 'EIN/TIN #' : 'EIN #'
              }}
              required
              validator={Validators.required.field}
            />
          </Col>
        </FormRow>
        {config.value.businessType === BusinessTypes.PRE_LICENSE_MRB && (
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.PRE_LICENSE_MRB_INFO}
                label="Please list the pending license application submission date and license type."
                input={TextareaInput}
                inputProps={{
                  'data-qa': 'Pre license mrb info'
                }}
                required
                validator={Validators.required.field}
              />
            </Col2x>
          </FormRow>
        )}
      </InfoSet>
    </Form>
  )
})
