import { notFound } from '@tanstack/react-router'
import { ApiError } from 'commons/types'
import isInteger from 'lodash/isInteger'

import { getApplicationDocumentCreateWebPage } from './services'
import { ApplicationDocumentCreateWebpage } from './types'

export const applicationDocumentCreateLoader = async ({ params }) => {
  const { applicationId } = params
  const applicationIdRequest = parseInt(applicationId, 10)

  if (!isInteger(applicationIdRequest)) {
    throw notFound()
  }

  let webPage: ApplicationDocumentCreateWebpage | null = null

  try {
    webPage = await getApplicationDocumentCreateWebPage(applicationIdRequest)
  } catch (e) {
    if (e instanceof ApiError && e.httpCode === 400) {
      // let's show 404
      webPage = null
    }
    throw e
  }

  if (!webPage) {
    throw notFound()
  }

  return { webPage, applicationId: applicationIdRequest }
}
