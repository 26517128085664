import { BANK_ACCOUNTS, COMPANIES_PATH, CONTACTS_PATH, DOCUMENTS_PATH, LICENSES_PATH } from 'commons/constants/routes'
import { Document } from 'commons/types/DTO/bank/companyDetails/document'
import { DateTime } from 'ui/components'
import { CheckMark } from 'ui/components/CheckMark'
import { IconName, IconSize } from 'ui/components/Icon'
import { FrequencyLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { TextMask } from 'ui/components/TextMask'

import { DueTooltip } from './components/DueTooltip/DueTooltip'

import * as SC from './styles'

export const columns = (
  initialAlert: number,
  firstAlert: number,
  lastAlert: number,
  onDeleteDocument: (document: Document | undefined) => void,
  returnUrl?: string
): TableColumn<Document>[] =>
  [
    {
      title: 'Document Name',
      sortable: true,
      key: 'documentName',
      align: 'left',
      render: (item) => (
        <Link to={`${DOCUMENTS_PATH}/${item.id}`}>
          <SC.CellLongString>{item.documentName}</SC.CellLongString>
        </Link>
      )
    },
    {
      title: 'Internal',
      sortable: true,
      key: 'isInternal',
      render: ({ isInternal }) => (isInternal ? <CheckMark value rounded /> : null)
    },
    {
      title: 'Subject',
      align: 'left',
      render: (item) => {
        let id = item.contactID
        const companyId = item.subject.subjectID

        if (item.subjectType === 'company') {
          id = companyId
        } else if (item.subjectType === 'license') {
          id = item.subject.subjectID
        } else if (item.subjectType === 'bank_account') {
          id = item.subject.subjectID
        }

        return getSubjectType({
          id,
          companyId,
          label: item.subject.subjectLabel,
          name: item.subject.subjectName,
          type: item.subjectType
        })
      }
    },
    {
      title: 'Frequency',
      sortable: true,
      key: 'frequency',
      align: 'left',
      render: ({ frequency }) => <FrequencyLabel name={frequency} />
    },
    {
      title: 'Expiration Date',
      sortable: true,
      key: 'expirationDate',
      align: 'left',
      render: ({ expirationDate }) => <DateTime date={expirationDate} />
    },
    {
      align: 'left',
      title: 'Alerts',
      render: (item: Document) => (
        <DueTooltip
          id={item.id}
          pastDue={item.pastDueAlertCount}
          firstAlerted={item.firstAlertCount}
          initialAlerted={item.beforeAlertCount}
          lastAlerted={item.lastAlertCount}
          expiration_date={item.expirationDate}
          firstAlert={firstAlert}
          initialAlert={initialAlert}
          lastAlert={lastAlert}
        />
      )
    },
    {
      title: 'Actions',
      width: '50px',
      render: (item: Document) => (
        <TableActions
          items={[
            {
              key: 1,
              title: 'Edit',
              iconName: IconName.EDIT,
              iconHoverFace: MenuIconHoverFace.PRIMARY,
              textFace: MenuItemTextFace.GREY,
              url: `${DOCUMENTS_PATH}/${item?.id}/edit?returnUrl=${returnUrl}`
            },
            {
              key: 2,
              title: 'Delete',
              behavior: MenuItemBehavior.BUTTON,
              iconName: IconName.DELETE,
              iconHoverFace: MenuIconHoverFace.DANGER,
              textFace: MenuItemTextFace.GREY,
              onClick: () => onDeleteDocument(item)
            }
          ]}
        />
      )
    }
  ].filter(Boolean) as TableColumn<Document>[]

type Subject = {
  label: string
  name: string
  type: string
  id: number
  companyId: number
}

const getSubjectType = (subject: Subject) => {
  switch (subject.type) {
    case 'company':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={`${COMPANIES_PATH}/${subject.id}`}>{subject.name}</Link>
        </>
      )
    case 'license':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={`${LICENSES_PATH}/${subject.id}`}>{subject.name}</Link>
        </>
      )
    case 'bank_account':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={`${BANK_ACCOUNTS}/${subject.id}`}>
            <TextMask isBankAccount text={subject.name} iconSize={IconSize.XS} />
          </Link>
        </>
      )
    case 'contact':
      return (
        <>
          <div>{subject.label}</div>
          <Link to={`${CONTACTS_PATH}/${subject.id}`}>{subject.name}</Link>
        </>
      )
    default:
      return <></>
  }
}
