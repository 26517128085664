import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'

import { Request } from 'commons/utils/request'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'

import { Select, ServerSelectOption } from '../Select'
import { useGetData } from '../hooks/useGetData'
import { SingleSelectAutoSuggestProps } from './SingleSelectAutoSuggestProps'
import { getDropDownOptions } from './getDropDownOptions'

/**
 * Component can work in several modes to properly show data - predefined list name (listName parameter),
 * explicit options list (options parameter), serverOptions(API options to fetch and locally store items to be
 * shown in drop down). "listName" has biggest priority, next preferred parameter is "options",
 * lastly "serverOptions" parameter will be concerned.
 */

export const SingleSelectAutoSuggest: FC<SingleSelectAutoSuggestProps> = memo((props) => {
  const {
    value,
    onChange,
    required,
    searchable,
    listName,
    options,
    emptyOption,
    placeholder,
    serverOptions,
    apiAxios,
    extra,
    noFirstRequest,
    ...rest
  } = props

  const [serverItems, setServerItems] = useState<ServerSelectOption[]>([])
  const [term, setTerm] = useState('')

  const dropDownOptions = useMemo(
    () => getDropDownOptions({ listName, extra, options, serverItems }),
    [listName, extra, options, serverItems]
  )

  const queryKey = 'single-select-auto-suggest-data'
  const getDataMutation = useGetData(queryKey)
  const queryClient = useQueryClient()

  useEffect(() => () => queryClient.removeQueries(queryKey), [queryClient, queryKey])

  const onSearch = debounce(
    useCallback((term: string) => {
      setTerm(term)
    }, []),
    500
  )

  const fetchValueItem = useCallback(async () => {
    if (serverOptions && serverOptions.valueItemFilter) {
      await getDataMutation.mutateAsync({
        type: apiAxios || Request,
        url: serverOptions.url,
        serverOptions: {
          _options: {
            offset: 0,
            limit: serverOptions.limit,
            filters: [serverOptions.valueItemFilter].concat(serverOptions.filters as any).filter(Boolean)
          }
        }
      })

      const data = queryClient.getQueryData(queryKey) as any

      return data && data.records ? data.records : []
    }
    return []
  }, [serverOptions])

  const fetchOptions = useCallback(
    async (byValue?: boolean) => {
      if (serverOptions) {
        const requestOptions = serverOptions?.requestSearchString
          ? {
              [serverOptions?.requestSearchString]: term
            }
          : {
              _options: {
                offset: 0,
                limit: serverOptions.limit,
                filters:
                  term && serverOptions.searchFields
                    ? ([
                        {
                          type: 'or',
                          filters: serverOptions.searchFields.map((field) => ({
                            field,
                            type: 'like',
                            value: `%${term}%`
                          }))
                        }
                      ]
                        .concat(serverOptions.filters as any)
                        .filter(Boolean) as any)
                    : undefined
              }
            }

        !byValue &&
          (await getDataMutation.mutateAsync({
            type: apiAxios || Request,
            url: serverOptions.url,
            serverOptions: requestOptions
          }))

        const data = queryClient.getQueryData(queryKey) as any

        const collection = data && data.records ? data.records : []
        const valueItem = await fetchValueItem()

        setServerItems(uniqBy(collection.concat(valueItem).map(serverOptions.mapper), 'value') as any)
      }
    },
    [serverOptions, term, fetchValueItem]
  )

  const customOnChange = (value: any) => {
    if (!value) {
      setTerm('')
    }
    if (onChange) {
      onChange(value)
    }
  }

  useEffect(() => {
    if (noFirstRequest && value) {
      fetchOptions(true)
    }
  }, [value, noFirstRequest])

  useEffect(() => {
    if (!serverOptions) {
      return
    }
    if (!noFirstRequest) {
      fetchOptions()
      return
    }

    if (term) {
      fetchOptions()
    }
  }, [
    serverOptions && serverOptions.filters ? serverOptions.filters.map((f) => JSON.stringify(f)).join() : undefined,
    term
  ])

  useEffect(() => {
    if (!value) {
      // this cause clearing option list on value update ouside (typically in the filter form on clear action)
      setTerm('')
    }
    if (!value && !term) {
      setServerItems([])
    }
  }, [value, term])

  if (listName && options?.length) {
    throw new Error('Either "listName" or "options" parameter should be provided.')
  }

  if (listName && serverOptions) {
    throw new Error('Either "listName" or "serverOptions" parameter should be provided.')
  }

  return (
    <Select
      {...rest}
      placeholder={placeholder}
      emptyOption={emptyOption}
      searchable={searchable}
      data={dropDownOptions}
      onSearch={serverOptions ? onSearch : undefined}
      required={required}
      value={value}
      onChange={customOnChange}
    />
  )
})
