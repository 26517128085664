import { DepositsFromSales } from './DepositsFromSales'
import { OtherActivityInfo } from './OtherActivityInfo'

export type DepositsInfo = {
  enableEdit: boolean
  showEditButtonTooltip: string
  depositsFromSales: DepositsFromSales
  showOtherActivity: OtherActivityInfo
  notes: string
}
