import { ContactAdditionalInformation } from 'commons/types/DTO/commons'

import { ContactAdditionalInformationFormValue } from '../../../../types'
import floatOrNull from './floatOrNull'

export const mapContactAdditionalInformationForm = ({
  debtholderDebtAmount,
  debtholderPaymentFrequency,
  debtholderPaymentAmount,
  debtholderMaturityDate,
  ownerInvestmentAmount,
  ownerOwnershipPercent,
  birthdate,
  EINSSN,
  street,
  city,
  state,
  country,
  postalCode,
  mailingStreet,
  mailingCity,
  mailingState,
  mailingCountry,
  mailingPostalCode,
  USCitizen
}: ContactAdditionalInformationFormValue): ContactAdditionalInformation => {
  let ownerOwnershipPercentString: number | string | null = floatOrNull(ownerOwnershipPercent)
  if (ownerOwnershipPercentString !== null) {
    ownerOwnershipPercentString = ownerOwnershipPercentString.toString()
  }

  return {
    debtholderDebtAmount: floatOrNull(debtholderDebtAmount),
    debtholderPaymentFrequency,
    debtholderPaymentAmount: floatOrNull(debtholderPaymentAmount),
    debtholderMaturityDate: debtholderMaturityDate === '' ? null : debtholderMaturityDate,
    ownerInvestmentAmount: floatOrNull(ownerInvestmentAmount),
    ownerOwnershipPercent: ownerOwnershipPercentString,
    birthdate: birthdate === '' ? null : birthdate,
    EINSSN,
    street,
    city,
    state,
    country,
    postalCode,
    mailingStreet,
    mailingCity,
    mailingState,
    mailingCountry,
    mailingPostalCode,
    USCitizen
  }
}
