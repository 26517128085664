import { FC, memo, useEffect, useMemo, useState } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { KEY_CONNECTIONS_PATH, LICENSES_PATH } from 'commons/constants/routes'
import { License } from 'commons/types/DTO/bank/companyDetails/license'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, PageSection, PageSectionFace, Table } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'

import { CompanyLicensesTableProps } from './CompanyLicensesTableProps'
import { columns } from './columns'
import { useComponentEssence } from './hooks/useComponentsEssence'
import { useFilterCompanyLicensesWebPage } from './hooks/useFilterCompanyLicensesWebPage'

export const CompanyLicensesTable: FC<CompanyLicensesTableProps> = memo(
  ({ companyId, initialLicenses, initialLicensesTotalCount, isAPIConnectionColumnEnabled }) => {
    const navigate = useNavigate()
    const [webPageData, setWebPageData] = useState({
      licenses: initialLicenses,
      licensesTotalCount: initialLicensesTotalCount
    })

    useEffect(() => {
      setWebPageData({
        licenses: initialLicenses,
        licensesTotalCount: initialLicensesTotalCount
      })
    }, [initialLicenses, initialLicensesTotalCount])

    const { routine: filterCompanyLicensesWebPage } = useFilterCompanyLicensesWebPage({
      onSuccess: async (_, result) => {
        await setWebPageData(result)
      },
      onError: (error) => handlerServerError(error)
    })

    const { openDeleteConfirmation, DeleteConfirmation, loading, tableState, changePagination } = useComponentEssence(
      companyId,
      filterCompanyLicensesWebPage,
      webPageData.licensesTotalCount
    )

    const navigateToConnections = () => navigate({ to: KEY_CONNECTIONS_PATH, replace: true })

    const memoizedColumns = useMemo(
      () => columns(openDeleteConfirmation, isAPIConnectionColumnEnabled, navigateToConnections),
      []
    )

    const mappedLicenses: DataSourceItem<License>[] = useMemo(
      () =>
        webPageData.licenses.map((el) => ({
          item: el
        })),
      [webPageData]
    )

    return (
      <PageSection
        face={PageSectionFace.PRIMARY}
        title="Licenses"
        id="licenses"
        actions={
          <HeaderButton
            size="middle"
            onClick={() =>
              navigate({
                to: `${LICENSES_PATH}/add?returnUrl=${encodeURIComponent(
                  window.location.pathname + window.location.hash
                )}&companyId=${companyId}`
              })
            }
          >
            Add New
          </HeaderButton>
        }
      >
        <DeleteConfirmation />
        <Layer rounded shadowed stretch>
          <Table<License>
            dataQa={TableDataQa.BP_COMPANY_LICENSES}
            columns={memoizedColumns}
            dataSource={mappedLicenses}
            loading={loading}
            pagination={{
              page: tableState.page,
              pageSize: tableState.size,
              onChange: changePagination,
              total: webPageData.licensesTotalCount
            }}
          />
        </Layer>
      </PageSection>
    )
  }
)
