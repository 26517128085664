import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const Address = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institutionActivitiesInfo[index1].noBranchInvolved)

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `institutionActivitiesInfo[${index1}].institutionActivityBranchesInfo[${index2}].address` as 'institutionActivitiesInfo[0].institutionActivityBranchesInfo[0].address'
      }
      children={(field) => (
        <FF
          field={field}
          label="69. Address of branch or office where activity occurred"
          input={(p) => <TextInput {...p} disabled={noBranchInvolved} />}
        />
      )}
    />
  )
}
