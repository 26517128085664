import { FC } from 'react'

import { KeySetupResultProps } from './KeySetupResultProps'

import {
  BoldText,
  LicenseLabel,
  LicenseMessage,
  LicenseMessageWrapper,
  LicenseName,
  LicensesWrapper,
  Title,
  Wrapper
} from './styles'

export const KeySetupResult: FC<KeySetupResultProps> = ({ keySetupResult }) => {
  const mappedPassedValidationLicenses =
    keySetupResult.passedValidation && keySetupResult.passedValidation.length > 0
      ? keySetupResult.passedValidation.map((el, index) => (
          <LicenseLabel key={index}>
            <LicenseName>
              {el?.licenseName} ({el?.licenseNumber}) / <BoldText>{el?.companyName}</BoldText>
            </LicenseName>
          </LicenseLabel>
        ))
      : null

  const mappedFailedValidationLicenses =
    keySetupResult.failedValidation && keySetupResult.failedValidation.length > 0
      ? keySetupResult.failedValidation.map((el, index) => (
          <LicenseLabel key={index}>
            <LicenseMessageWrapper>
              <LicenseName>
                {el?.licenseName} ({el?.licenseNumber}) / <BoldText>{el?.companyName}</BoldText>
              </LicenseName>
              <LicenseMessage>{el.message}</LicenseMessage>
            </LicenseMessageWrapper>
          </LicenseLabel>
        ))
      : null

  return (
    <Wrapper>
      {keySetupResult.passedValidation && keySetupResult.passedValidation.length > 0 && (
        <div>
          <Title $isSuccess={true}>Connected licenses:</Title>
          <LicensesWrapper>{mappedPassedValidationLicenses}</LicensesWrapper>
        </div>
      )}
      {keySetupResult.failedValidation && keySetupResult.failedValidation.length > 0 && (
        <div>
          <Title $isSuccess={false}>The following licenses failed to connect:</Title>
          <LicensesWrapper>{mappedFailedValidationLicenses}</LicensesWrapper>
        </div>
      )}
    </Wrapper>
  )
}
