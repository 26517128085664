import { forwardRef, memo, useEffect, useState } from 'react'

import { modelCurrencyCentToView, viewCurrencyCentToModel } from 'commons/utils'
import IMask from 'imask'

import { BaseMaskedInput } from './BaseMaskedInput'
import { CurrencyCentInputProps } from './props'
import { InputIconPosition } from './types'

const DOLLAR_SYMBOL = '$'
// limit input length, coz we are easy to overflow Int type
const MAX_LENGTH = 15

export const CurrencyCentInput = memo(
  forwardRef<HTMLInputElement, CurrencyCentInputProps>((props: CurrencyCentInputProps, ref) => {
    const { value, allowDecimal = true, onChange, ...rest } = props

    const [viewValue, setViewValue] = useState(modelCurrencyCentToView(value))

    useEffect(() => {
      setViewValue(modelCurrencyCentToView(value))
    }, [value])

    const handleChange = (value: string) => {
      setViewValue(value)
    }

    const handleBlur = () => {
      if (onChange) {
        onChange(viewCurrencyCentToModel(viewValue))
      }
    }

    const mask = IMask.createMask({
      mask: Number,
      thousandsSeparator: ',',
      scale: allowDecimal ? 2 : 0,
      radix: '.'
    })

    return (
      <BaseMaskedInput
        ref={ref}
        value={viewValue}
        onChange={handleChange}
        onBlur={handleBlur}
        {...rest}
        mask={mask}
        renderIcon={DOLLAR_SYMBOL}
        iconPosition={InputIconPosition.RIGHT_CLOSE}
        maxLength={MAX_LENGTH}
      />
    )
  })
)
