import React, { Suspense, lazy, useEffect, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { ARQuestionnaireFormSchemaName } from '~bank-annual-review/types/AnnualReviewQuestionnaireForm'
import { Form } from '~bank-annual-review/types/Manage'

const routeApi = getRouteApi(AnnualReviewPaths.EDIT)

const questionnaireComponents = {
  questionnaire1: lazy(() =>
    import('./components/Questionnaire1Form').then((m) => ({ default: m.Questionnaire1Form }))
  ),
  questionnaire2: lazy(() =>
    import('./components/Questionnaire2Form').then((m) => ({ default: m.Questionnaire2Form }))
  ),
  questionnaire3: lazy(() =>
    import('./components/Questionnaire3Form').then((m) => ({ default: m.Questionnaire3Form }))
  ),
  questionnaireDemo: lazy(() =>
    import('./components/QuestionnaireDemoForm').then((m) => ({ default: m.QuestionnaireDemoForm }))
  )
}

export const Questionnaire: React.FC = () => {
  const form = routeApi.useLoaderData({
    select: (s) => s.form
  }) as Form

  const [schemaName, setSchemaName] = useState<ARQuestionnaireFormSchemaName | null>(null)

  useEffect(() => {
    const foundSchema = Object.keys(form).find((key) =>
      ['questionnaire1', 'questionnaire2', 'questionnaire3', 'questionnaireDemo'].includes(key)
    ) as ARQuestionnaireFormSchemaName

    setSchemaName(foundSchema)
  }, [form])

  if (!schemaName) return null

  const QuestionnaireForm = questionnaireComponents[schemaName]

  return (
    <Suspense fallback={<div>Loading questionnaire...</div>}>
      <QuestionnaireForm />
    </Suspense>
  )
}
