import { useCallback } from 'react'

import {
  BANK_BISON_ID,
  BANK_CBT_ID,
  BANK_FLAGLER_ID,
  BANK_HERRING_ID,
  BANK_INB_ID,
  BANK_MPB_ID,
  BANK_SHORE_ID,
  BANK_VALLIANCE_ID
} from 'commons/constants/banks'

export const useGetOrganisationByLocation = () => {
  const hash = window.location.hash

  const isINB =
    hash === '#inb' ||
    window.location.host === 'portal.inb.com' ||
    window.location.host === 'clients-inb.staging.helioscompliance.com'

  const isMetroPhoenix = hash === '#mpb' || window.location.host === 'portal.metrophoenixbank.com'

  const isHerring =
    hash === '#herring' ||
    window.location.host === 'mrbportal.herringbank.com' ||
    window.location.host === 'clients-herring.staging.helioscompliance.com'

  const isFlagler = hash === '#flagler' || window.location.host === 'clients-flagler.staging.helioscompliance.com'

  const isCBT =
    window.location.host === 'customerportal.colobank.com' ||
    window.location.host === 'clients-cbt.staging.helioscompliance.com' ||
    hash === '#cbt'

  const isValliance =
    hash === '#valliance' ||
    window.location.host === 'portal.vbank.com' ||
    window.location.host === 'clients-valliance.staging.helioscompliance.com'

  const isShore =
    hash === '#shore' ||
    window.location.host === 'select.portal.shoreunitedbank.com' ||
    window.location.host === 'clients-shore.staging.helioscompliance.com'

  const isBison =
    hash === '#bison' ||
    window.location.host === 'portal.bisonstatebank.com' ||
    window.location.host === 'clients-bison.staging.helioscompliance.com'

  const getOrganisationId = useCallback(() => {
    if (isShore) return BANK_SHORE_ID
    if (isINB) return BANK_INB_ID
    if (isMetroPhoenix) return BANK_MPB_ID
    if (isHerring) return BANK_HERRING_ID
    if (isFlagler) return BANK_FLAGLER_ID
    if (isCBT) return BANK_CBT_ID
    if (isValliance) return BANK_VALLIANCE_ID
    if (isBison) return BANK_BISON_ID
    return null
  }, [isShore, isINB, isMetroPhoenix, isHerring, isFlagler, isCBT, isValliance, isBison])

  return {
    authOrganisationId: getOrganisationId()
  }
}
