import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type CorroborativeStatementToFilerProps = {
  index: number
}

export const CorroborativeStatementToFiler = ({ index }: CorroborativeStatementToFilerProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index}].corroborativeStatementToFiler` as 'subjectsInfo[0].corroborativeStatementToFiler'}
      children={(field) => (
        <FF
          field={field}
          label="23. Corroborative statement to filer?"
          input={(p) => (
            <Select
              {...p}
              data={[
                { label: 'Yes', value: 'Yes' },
                { label: 'No', value: 'No' }
              ]}
              emptyOption={EMPTY_OPTION}
            />
          )}
        ></FF>
      )}
    />
  )
}
