import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const PhoneNumberExt = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.phoneExt"
      children={(field) => <FF label="Ext." field={field} input={(p) => <TextInput {...p} maxLength={6} />} />}
    />
  )
}
