import { FC, memo, useMemo } from 'react'

import { ColumnMultiSelect } from 'ui/components/Select'

import { UserMultiSelectProps } from './UserMultiSelectProps'

export const UserMultiSelect: FC<UserMultiSelectProps> = memo((props) => {
  const { value, onChange, options, ...rest } = props

  const data = useMemo(() => options.map((item) => ({ label: item.fullName, value: item.id })), [options])

  return <ColumnMultiSelect {...rest} value={value} onChange={onChange} placeholder="Add user" searchable data={data} />
})
