import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

export const IdCountry = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const state = formApi.useStore((s) => s.values.subjectsInfo[index1]?.idsInfo[index2]?.idState)

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idCountry`}
      validators={{
        onChange: ({ value }) => {
          if (typeof value !== 'undefined' && value !== 'US' && state.length > 0) {
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idState`, '')
            formApi.setFieldMeta(`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idState`, (stateMeta) => ({
              ...stateMeta,
              errors: [],
              errorMap: {}
            }))
          }

          return undefined
        }
      }}
      children={(field) => (
        <FF
          field={field}
          label="Country"
          input={(p) => (
            <Select
              {...p}
              data={usAndTerritories}
              emptyOption={{ label: 'Not selected', value: '' }}
              disabled={isAllInfoUnavailable}
            />
          )}
        />
      )}
    />
  )
}
