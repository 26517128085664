import { FormPaths, OnboardingFormFieldNames } from '../../../../enums'
import { CompanyFormValues } from '../../../../types/state'
import { ParseFieldPathReturn } from '../parseSubmitErrorPath'
import { fromAtm } from './fromAtm'
import { fromContact } from './fromContact'
import { fromLicense } from './fromLicense'

export const setNewCompanyFromErrors = ({
  oldCompany,
  parsedPathInfo,
  errors
}: {
  oldCompany: CompanyFormValues
  parsedPathInfo: ParseFieldPathReturn
  errors: string[]
}): CompanyFormValues => {
  const { formName, fieldName } = parsedPathInfo
  const common = {
    oldCompany,
    errors,
    formName: parsedPathInfo.formName as FormPaths,
    fieldName: parsedPathInfo.fieldName as OnboardingFormFieldNames
  }
  if (!fieldName || !formName) {
    return oldCompany
  }
  if (parsedPathInfo.isLicense) {
    return fromLicense({ ...common, licenseIndex: parsedPathInfo.licenseIndex })
  }

  if (parsedPathInfo.isContact) {
    return fromContact({ ...common, contactIndex: parsedPathInfo.contactIndex })
  }

  if (parsedPathInfo.isAtm) {
    return fromAtm({ ...common, atmIndex: parsedPathInfo.contactIndex })
  }

  const newCompany: CompanyFormValues = { ...oldCompany }
  // show changes in form
  newCompany[formName] = { formValues: {}, formErrors: {}, ...newCompany[formName] }
  newCompany[formName].formErrors = { ...newCompany[formName].formErrors, [fieldName]: errors.join('.') }
  return newCompany
}
