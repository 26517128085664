import { getRouteApi } from '@tanstack/react-router'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { questionnaire1Labels } from '~bank-annual-review/constants/questionnaireLabels'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

export const Questionnaire1 = () => {
  const questionnaire = routeApi.useLoaderData({
    select: (s) => s.showQuestionnaire1
  })

  return (
    <>
      <PageSection title="Financials">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.financialsPeriodCollected}
              multiline
              children={<>{questionnaire?.financialsPeriodCollected || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.financialsIsMaterialsRegardingIncome}
              multiline
              children={<>{questionnaire?.financialsIsMaterialsRegardingIncome || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.financialsMaterialsNotes}
              multiline
              children={<>{questionnaire?.financialsMaterialsNotes || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.financialsCurrentConditionIsAcceptable}
              children={<>{questionnaire?.financialsCurrentConditionIsAcceptable || '---'}</>}
            />
            {questionnaire?.financialsCurrentConditionIsAcceptable === 'No' && (
              <Q
                label={questionnaire1Labels.financialsAcceptableNotes}
                multiline
                children={<>{questionnaire?.financialsAcceptableNotes || '---'}</>}
              />
            )}
            <Q
              label={questionnaire1Labels.financialsDepositsMatching}
              children={<>{questionnaire?.financialsDepositsMatching || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Covenants/Agreements">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.covenantComplying}
              children={<>{questionnaire?.covenantComplying || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.covenantNonComplianceDesc}
              multiline
              children={<>{questionnaire?.covenantNonComplianceDesc || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.covenantTransparent}
              children={<>{questionnaire?.covenantTransparent || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Site Visits">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.siteVisitsFrequency}
              children={<>{questionnaire?.siteVisitsFrequency || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.siteVisitsLastVisitDate}
              children={<>{questionnaire?.siteVisitsLastVisitDate || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.siteVisitsViolationRectified}
              children={<>{questionnaire?.siteVisitsViolationRectified || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.siteVisitsScoresAcceptable}
              children={<>{questionnaire?.siteVisitsScoresAcceptable || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.siteVisitsShutdownRisk}
              children={<>{questionnaire?.siteVisitsShutdownRisk || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.siteVisitsHasStateAuditAtReviewPeriod}
              children={<>{questionnaire?.siteVisitsHasStateAuditAtReviewPeriod || '---'}</>}
            />
            {questionnaire?.siteVisitsHasStateAuditAtReviewPeriod === 'Yes' && (
              <Q
                label={questionnaire1Labels.siteVisitsStateAuditNotes}
                multiline
                children={<>{questionnaire?.siteVisitsStateAuditNotes || '---'}</>}
              />
            )}
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Ownership & Management">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.ownershipBadNews}
              children={<>{questionnaire?.ownershipBadNews || '---'}</>}
            />
            {questionnaire?.ownershipBadNews === 'Yes' && (
              <Q
                label={questionnaire1Labels.ownershipBadNewsDesc}
                multiline
                children={<>{questionnaire?.ownershipBadNewsDesc || '---'}</>}
              />
            )}
            <Q
              label={questionnaire1Labels.ownershipHasMaterialChangesInManagementAtReviewPeriod}
              children={<>{questionnaire?.ownershipHasMaterialChangesInManagementAtReviewPeriod || '---'}</>}
            />
            {questionnaire?.ownershipHasMaterialChangesInManagementAtReviewPeriod === 'Yes' && (
              <Q
                label={questionnaire1Labels.ownershipMaterialChangesInManagementNotes}
                multiline
                children={<>{questionnaire?.ownershipMaterialChangesInManagementNotes || '---'}</>}
              />
            )}
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Recommendations">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.recommendationsRecommended}
              children={<>{questionnaire?.recommendationsRecommended || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.recommendationsStatus}
              children={<>{questionnaire?.recommendationsStatus || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.recommendationsComments}
              multiline
              children={<>{questionnaire?.recommendationsComments || '---'}</>}
            />
            <Q
              label={questionnaire1Labels.recommendationsChangeVisits}
              children={<>{questionnaire?.recommendationsChangeVisits || '---'}</>}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
    </>
  )
}
