import { FC } from 'react'

import { useBankPortalContext } from 'bank-portal-context'
import { IconName, Tooltip } from 'ui/components'

import { MainBarLogo } from '../Logo'

import * as SC from './styles'

export const Bar: FC<{
  minimized: boolean
  children: React.ReactNode
  onToggleBar: () => void
}> = ({ minimized, children, onToggleBar }) => {
  const {
    activateZendeskWidget: onHelpClick,
    isOnlyApplicationsAndAccountSettingsAccessible,
    layout: { nextCloudRedirectURL }
  } = useBankPortalContext()

  return (
    <SC.BarContainer minimized={minimized}>
      <SC.BarHeaderContainer>
        <SC.MenuToggleIcon onClick={onToggleBar} name={minimized ? IconName.MENU_MINIMIZED : IconName.MENU} />
        <MainBarLogo linkForLogo={'/'} isMinimized={minimized} />
      </SC.BarHeaderContainer>
      <SC.BarContent>{children}</SC.BarContent>
      <SC.Footer>
        <SC.HelpButton>
          <Tooltip id="need-help-tooltip" type="dark" place="right" content="Need help?" isVisible={minimized}>
            <SC.HelpButtonContent onClick={onHelpClick}>
              <SC.ButtonsIcon name={IconName.HELP} />
              {!minimized && <SC.HelpLabel $minimized={minimized}>Need help?</SC.HelpLabel>}
            </SC.HelpButtonContent>
          </Tooltip>
        </SC.HelpButton>

        {!minimized && !isOnlyApplicationsAndAccountSettingsAccessible && (
          <SC.NextCloudButton $minimized={minimized}>
            <Tooltip id="next-cloud-tooltip" type="dark" place="right" content="Share files">
              <SC.ButtonsIcon name={IconName.NEXT_CLOUD} onClick={() => window.open(nextCloudRedirectURL)} />
            </Tooltip>
          </SC.NextCloudButton>
        )}
      </SC.Footer>
    </SC.BarContainer>
  )
}
