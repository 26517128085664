import { FilesIsolated } from 'commons/types/DTO/commons'
import { DateTime, TableColumn } from 'ui/components'

import { Name } from '../Name'

export const columns = (readonly: boolean, onDelete: (id: number) => void): TableColumn<FilesIsolated>[] => [
  {
    title: 'File Name',
    key: 'fileName',
    align: 'left',
    render: (record) => <Name id={record.fileId} name={record.fileName} readonly={readonly} onDelete={onDelete} />
  },
  {
    title: 'Upload date',
    key: 'fileCreatedAt',
    align: 'right',
    render: (record) => <DateTime date={record.fileCreatedAt} withTime />
  }
]
