import { getRouteApi } from '@tanstack/react-router'
import SC from 'styled-components'
import {
  AnnualReviewQuestionColumn as Column,
  AnnualReviewQuestion as Q,
  AnnualReviewQuestionsSet as Set
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

const Questions = SC.div`
  margin-top: 34px;
`

export const LicensingQuestionnaire = (): JSX.Element | null => {
  const formData = getRouteApi(AnnualReviewPaths.DETAILS).useLoaderData({
    select: (s) => s
  }) as AnnualReviewDetails

  let questionnaire
  if (formData?.showQuestionnaire1) {
    questionnaire = formData?.showQuestionnaire1
  } else if (formData?.showQuestionnaire3) {
    questionnaire = formData?.showQuestionnaire3
  } else if (formData?.showQuestionnaireDemo) {
    questionnaire = formData?.showQuestionnaireDemo
  }

  if (!questionnaire) return null

  return (
    <Questions>
      <Set>
        <Column>
          <Q label="Any location changes in the last year?">{questionnaire?.licensingLocChanges || '---'}</Q>
        </Column>
        <Column>
          {questionnaire?.licensingLocChangesMmcc && (
            <Q label="If yes, approved by MMCC?">{questionnaire?.licensingLocChangesMmcc || '---'}</Q>
          )}
        </Column>
      </Set>
      <Set>
        <Column>
          <Q label="Any name changes in the last year?">{questionnaire?.licensingNameChanges || '---'}</Q>
        </Column>
        <Column>
          {questionnaire?.licensingNameChangesMmcc && (
            <Q label="If yes, approved by MMCC?">{questionnaire?.licensingNameChangesMmcc || '---'}</Q>
          )}
        </Column>
      </Set>
      <Set>
        <Column>
          {questionnaire?.licensingNamePrior && <Q label="Prior Name:">{questionnaire?.licensingNamePrior || '---'}</Q>}
        </Column>
      </Set>
    </Questions>
  )
}
