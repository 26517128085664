import { memo } from 'react'

import SC from 'styled-components'

import { Icon, IconName, IconSize } from '../../Icon'
import { Tooltip } from '../../Tooltip'

interface Props {
  text: string
  value: string
  disabled?: boolean
  title?: string
  checked: boolean
  onClick: (value: string, disabled?: boolean) => any
}

const Option = SC.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7px 20px 7px 10px;
  white-space: nowrap;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  gap: 5px;
 
  &:hover {
    background: #f5f5f5;
  }
`

const DisabledOption = SC.span`
  cursor: not-allowed;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
`

const IconWrapper = SC(Icon)`
  flex-shrink: 0
`

export const OptionItem = memo((props: Props) => {
  const { text, value, checked, disabled, title, onClick } = props
  const onOptionClick = () => onClick(value, disabled)

  return (
    <Option onClick={onOptionClick} role="option" data-qa={text}>
      <IconWrapper name={checked ? IconName.MINUS_FILLED : IconName.PLUS_FILLED} size={IconSize.XS} />
      {title ? (
        <Tooltip id={`hint${value}`} type="warning" content={title} event="hover">
          {disabled ? <DisabledOption>{text}</DisabledOption> : <span>{text}</span>}
        </Tooltip>
      ) : disabled ? (
        <DisabledOption>{text}</DisabledOption>
      ) : (
        <span>{text}</span>
      )}
    </Option>
  )
})
