import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { emptySAR } from '~bank-bsa-reporting/constants'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../context'
import { BankAccounts, TIN } from './fields'

export const InstitutionInfo = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      mode="array"
      name={`subjectsInfo[${index as 0}].institutionsInfo`}
      children={(field) => {
        if (typeof field.state.value === 'undefined') {
          return null
        }

        return (
          <>
            {(field.state.value || []).map((_, index2) => (
              <InstitutionInfoItem index1={index} index2={index2} parentField={field} key={`${index}-${index2}`} />
            ))}

            <IconButton
              icon={IconName.ADD_GREEN}
              onClick={() => field.pushValue(emptySAR.subjectsInfo[0].institutionsInfo[0])}
            />
          </>
        )
      }}
    />
  )
}

const InstitutionInfoItem = ({
  index1,
  index2,
  parentField
}: {
  index1: number
  index2: number
  parentField: FieldApi<SarEdit, 'subjectsInfo[0].institutionsInfo'>
}) => {
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index2),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <FormRow>
        <Col>
          <TIN index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <BankAccounts index1={index1} index2={index2} />
      <FormRow>
        {parentField.state.value.length > 1 && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
        )}
      </FormRow>
    </>
  )
}
