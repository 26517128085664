import { FC, memo } from 'react'

import { Form, useFormData } from 'brief-form'
import { useSalesDataClientSourceTaskSending } from 'commons/hooks/bank/salesData'
import { SalesDataClientSourceSendTaskPayload } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { handlerServerError } from 'errors'
import { Dialog, DialogFace, DialogFooterActions, LeaveConfirmation, TextareaInput } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { FormContentWrapper } from 'ui/temp/Dialogs/FormContentWrapper'
import { DialogCol, FormRow } from 'ui/themes/globalStyles'

import { SendTaskDialogProps } from './SendTaskDialogProps'

export const SendTaskDialog: FC<SendTaskDialogProps> = memo((props) => {
  const { licenseId, monthlyAnalyticsId, invalidate, onClose } = props

  const { isDirty, config, Field } = useFormData<SalesDataClientSourceSendTaskPayload>({
    initialValue: {
      licenseId,
      monthlyAnalyticsId,
      notes: ''
    }
  })

  const { routine: sendClientSourceTask, isLoading: isClientSourceTaskSending } = useSalesDataClientSourceTaskSending({
    onSuccess: async () => {
      invalidate()
      onClose()
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: openSendTask, Confirmation: SendTaskConfirmation } = useConfirmation({
    message: `Are you sure you want to Send Task?`,
    onConfirm: () =>
      sendClientSourceTask({
        licenseId: licenseId,
        monthlyAnalyticsId: monthlyAnalyticsId,
        notes: config.value.notes
      }),
    confirmationButtonText: 'Send Task',
    isConfirmationNegative: false
  })

  return (
    <LeaveConfirmation preventLeaving={isDirty && !isClientSourceTaskSending}>
      <SendTaskConfirmation />
      <Dialog
        id="send-task"
        title="Send Task"
        face={DialogFace.PRIMARY}
        footerActions={[DialogFooterActions.SUBMIT, DialogFooterActions.CANCEL]}
        isSubmitting={isClientSourceTaskSending}
        onClose={onClose}
        onSubmit={openSendTask}
      >
        <Form config={config}>
          <FormContentWrapper>
            <FormRow>
              <DialogCol>
                <Field
                  name="notes"
                  label="Notes"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Send Task Notes'
                  }}
                />
              </DialogCol>
            </FormRow>
          </FormContentWrapper>
        </Form>
      </Dialog>
    </LeaveConfirmation>
  )
})
