export const licensePosTypes = [
  { value: 'adilas', label: 'Adilas' },
  { value: 'biotrackthc', label: 'BioTrackTHC' },
  { value: 'dutchie', label: 'Dutchie' },
  { value: 'greenbits', label: 'Greenbits' },
  { value: 'flowhub', label: 'Flowhub' },
  { value: 'mj_freeway', label: 'MJ Freeway' },
  { value: 'leaf_logix', label: 'Leaf Logix' },
  { value: 'thsuite', label: 'THSuite' },
  { value: 'other', label: 'Other' },
  { value: 'manual', label: 'Manual' }
]

export const getLicensePosTypesList = () => licensePosTypes
