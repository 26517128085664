import { FC } from 'react'

import styled from 'styled-components'

const riskLevelValues = {
  Unknown: {
    label: 'Unknown',
    color: '#B63C3C'
  },
  Low: {
    label: 'Low',
    color: '#45D5B4'
  },
  ['Acknowledged medium']: {
    label: 'Acknowledged Medium',
    color: '#B63C3C'
  },
  Medium: {
    label: 'Medium',
    color: '#B63C3C'
  },
  ['Acknowledged high']: {
    label: 'Acknowledged High',
    color: '#FA5252'
  },
  High: {
    label: 'High',
    color: '#FA5252'
  }
}

//region Styled

const RiskLevelSpan = styled.span`
  font-weight: bold;
`
//endregion

export type RiskLevelType = 'Unknown' | 'Low' | 'Acknowledged medium' | 'Medium' | 'Acknowledged high' | 'High'

interface Props {
  riskLevel: RiskLevelType
}

export const RiskLevel: FC<Props> = ({ riskLevel }) => {
  if (!riskLevel) {
    return null
  }

  const { color, label } = riskLevelValues[riskLevel]
  return <RiskLevelSpan style={{ color: color }}>{label}</RiskLevelSpan>
}
