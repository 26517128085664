import { FC, memo } from 'react'

import { CashInOutProps } from './CashInOut.types'

import { s } from './styles'

export const CashInOut: FC<CashInOutProps> = memo(({ title, rows }) => (
  <s.Box rounded shadowed>
    {title && <s.Title>{title}</s.Title>}
    <div>
      {rows.map((item, index) => (
        <s.Row key={index}>
          <s.Label>{item.label}</s.Label>
          <span>{item.value}</span>
        </s.Row>
      ))}
    </div>
  </s.Box>
))
