import { Request } from 'commons/utils/request'
import { ReadHook, useStateManager } from 'state-manager'
import { CTREditResponse, CTRPersonsInfo, CTRTransactionLocationsInfo } from '~bank-bsa-reporting/types'

/**
 * null values for WithCheckboxInput
 */

export const EMPTY_TRANSACTION_LOCATION_INFO: CTRTransactionLocationsInfo = {
  address: '',
  cashIn: 0,
  cashOut: 0,
  city: '',
  country: '',
  dba: '',
  ein: null,
  federalRegulator: '',
  formattedEin: '',
  idNumber: '',
  idType: '',
  legalName: '',
  state: '',
  type: '',
  zipCode: ''
}

export const EMPTY_PERSON_INFO: CTRPersonsInfo = {
  accountNumbersIn: [],
  accountNumbersOut: [],
  address: undefined,
  alternateName: '',
  birthdate: '',
  businessType: '',
  cashIn: 0,
  cashOut: 0,
  city: undefined,
  country: undefined,
  email: '',
  entityName: undefined,
  firstName: undefined,
  formattedTin: '',
  gender: '',
  idCountry: '',
  idForm: '',
  idNumber: '',
  idState: '',
  isEntity: false,
  lastName: undefined,
  middleName: '',
  multipleTransactions: false,
  naicsCode: '',
  phone: '',
  phoneExt: '',
  postalCode: undefined,
  state: undefined,
  suffix: '',
  tin: '',
  tinType: '',
  type: ''
}

const request = (opts: { id: number }) =>
  opts.id
    ? Request.post(Request.urls.bank.ctrEdit, opts)
    : Promise.resolve({
        form: {
          generalInfo: {
            filingName: '',
            filingType: '',
            nameDesc: '',
            notes: '',
            priorReportBsaId: '',
            status: 'new'
          },
          id: 0,
          institutionContactInfo: {
            address: '',
            city: '',
            contactOffice: '',
            country: '',
            dba: '',
            ein: '',
            federalRegulator: '',
            formattedEin: '',
            idNumber: '',
            idType: '',
            legalName: '',
            phone: '',
            phoneExt: '',
            state: '',
            type: '',
            typeOther: '',
            zipCode: ''
          },
          personsInfo: [EMPTY_PERSON_INFO],
          transactionInfo: {
            cashInA: 0,
            cashInB: 0,
            cashInC: 0,
            cashInD: 0,
            cashInE: 0,
            cashInF: 0,
            cashInG: 0,
            cashInH: 0,
            cashInI: 0,
            cashInTotal: 0,
            cashInTypeZ: '',
            cashInZ: 0,
            cashOutA: 0,
            cashOutB: 0,
            cashOutC: 0,
            cashOutD: 0,
            cashOutE: 0,
            cashOutF: 0,
            cashOutG: 0,
            cashOutH: 0,
            cashOutI: 0,
            cashOutJ: 0,
            cashOutTotal: 0,
            cashOutTypeZ: '',
            cashOutZ: 0,
            foreignIn: [],
            foreignOut: [],
            sourceAggregated: false,
            sourceArmored: false,
            sourceAtm: false,
            sourceMail: false,
            sourceNight: false,
            sourceSharedBranching: false,
            transactionDate: ''
          },
          transactionLocationsInfo: [EMPTY_TRANSACTION_LOCATION_INFO]
        }
      })

export const useManageCTR: ReadHook<CTREditResponse, { id: number }> = (opts) =>
  useStateManager().use(['ctr-edit', opts?.id], () => request({ id: opts?.id || 0 }), {
    keepPreviousData: false
  })
