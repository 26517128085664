import { SarEdit } from '~bank-bsa-reporting/types'

export const emptySAR: SarEdit = {
  generalInfo: {
    companyId: 0,
    companyName: '',
    filingName: '',
    filingNotesToFinCen: '',
    filingType: [],
    notes: '',
    priorReportBsaId: '',
    status: '',
    transactionEndDate: null,
    transactionStartDate: null
  },
  id: 0,
  institutionActivitiesInfo: [
    {
      address: '',
      city: '',
      country: '',
      dba: '',
      federalRegulator: '',
      formattedTIN: '',
      idNumber: '',
      idType: '',
      institutionActivityBranchesInfo: [
        {
          address: '',
          city: '',
          country: '',
          idNumber: '',
          roleInTransaction: '',
          state: '',
          zipCode: ''
        }
      ],
      internalControlNumber: '',
      legalName: '',
      lossToFinInstitution: 0,
      noBranchInvolved: false,
      roleInTransaction: '',
      state: '',
      tin: '',
      tinType: '',
      type: '',
      typeOther: '',
      zipCode: ''
    }
  ],
  institutionContactInfo: {
    address: '',
    city: '',
    contactOffice: '',
    country: '',
    dba: '',
    federalRegulator: '',
    filerName: '',
    formattedTin: '',
    idNumber: '',
    idType: '',
    internalControlNumber: '',
    leContactAgency: '',
    leContactDate: null,
    leContactName: '',
    leContactPhone: '',
    leContactPhoneExt: '',
    phone: '',
    phoneExt: '',
    state: '',
    tin: '',
    tinType: '',
    type: '',
    typeOther: '',
    zipCode: ''
  },
  narrative: '',
  previousCumulativeAmount: 0,
  subjectsInfo: [
    {
      addressesInfo: [
        {
          address: '',
          city: '',
          country: '',
          postalCode: '',
          state: ''
        }
      ],
      alternateNames: [],
      businessType: '',
      corroborativeStatementToFiler: '',
      emails: [],
      formattedTIN: '',
      idsInfo: [
        {
          idCountry: '',
          idForm: '',
          idFormOther: '',
          idNumber: '',
          idState: ''
        }
      ],
      institutionsInfo: [
        {
          accountNumbers: [
            {
              closed: false,
              formattedNumber: '',
              number: ''
            }
          ],
          formattedTIN: '',
          tin: ''
        }
      ],
      isAllInfoUnavailable: false,
      isEntity: false,
      naicsCode: '',
      noKnownAccountsInvolved: false,
      phoneNumbers: [
        {
          phoneExt: '',
          phoneNumber: '',
          phoneType: ''
        }
      ],
      relationshipsInfo: [
        {
          accountant: false,
          actionDate: null,
          agent: false,
          appraiser: false,
          attorney: false,
          borrower: false,
          customer: false,
          director: false,
          employee: false,
          formattedTIN: '',
          noRelationship: false,
          officer: false,
          other: false,
          otherDesc: '',
          owner: false,
          relationshipStatus: '',
          tin: ''
        }
      ],
      roleInActivity: '',
      tin: '',
      tinType: '',
      websites: [],
      birthdate: null,
      entityName: '',
      firstName: '',
      gender: '',
      lastName: '',
      middleName: '',
      suffix: ''
    }
  ],
  suspiciousActivityInfo: {
    amountUnknown: false,
    commodityTypes: [],
    currentAmount: 0,
    currentCumulativeAmount: 0,
    cusipNumbers: [],
    cyber: [],
    cyberEventIndicators: [],
    dateRangeFrom: null,
    dateRangeTo: null,
    fraud: [],
    gaming: [],
    identification: [],
    insurance: [],
    ipAddresses: [],
    laundering: [],
    marketsTraded: [],
    mortgage: [],
    noAmountInvolved: false,
    otherActivities: [],
    paymentMechanisms: [],
    productInstrumentDesc: [],
    productTypes: [],
    securities: [],
    structuring: [],
    terroristFinancing: [],
    fraudOther: '',
    identificationOther: '',
    launderingOther: '',
    otherActivitiesOther: '',
    paymentMechanismsOther: '',
    productTypesOther: '',
    structuringOther: '',
    terroristFinancingOther: '',
    cyberOther: '',
    mortgageOther: '',
    securitiesOther: '',
    gamingOther: '',
    insuranceOther: ''
  },
  transactionsInfo: [
    {
      accountNumber: '',
      achIn: 0,
      achOut: 0,
      cashIn: 0,
      cashOut: 0,
      checkIn: 0,
      checkOut: 0,
      debitCardOut: 0,
      formattedAccountNumber: '',
      miscIn: 0,
      miscOut: 0,
      returnedChecksOut: 0,
      totalBankFees: 0,
      transferIn: 0,
      transferOut: 0,
      wiresIn: 0,
      wiresOut: 0,
      withdrawalsOut: 0,
      totalIn: 0,
      totalOut: 0
    }
  ]
}
