import { FC, memo, useCallback, useRef, useState } from 'react'

import { Popper } from 'ui/components/Popper'

import { DotsButton } from './components/DotsButton'

import * as SC from './styles'

type MenuPosition = 'bottom-start' | 'bottom-end'

export interface DropDownProps {
  menuPosition: MenuPosition
  button?: React.ReactNode
  children?: React.ReactNode
  onVisibilityChange?: (visible: boolean) => void
  containerSelectors?: string[]
  dotsButtonQaAttribute?: string
}

export const DropDown: FC<DropDownProps> = memo((props) => {
  const { children, button, menuPosition, onVisibilityChange, dotsButtonQaAttribute } = props
  const [opened, setOpened] = useState(false)
  const anchorRef = useRef<any>(null)
  const menuRef = useRef<any>(null)

  const handleVisibility = useCallback(
    (visible: boolean) => {
      setOpened(visible)
      if (onVisibilityChange) {
        onVisibilityChange(visible)
      }
    },
    [setOpened, onVisibilityChange]
  )

  return (
    <Popper
      placement={menuPosition}
      opened={opened}
      onOpen={() => handleVisibility(true)}
      onClose={() => handleVisibility(false)}
      style={{ margin: '4px 0' }}
      render={({ close }) => (
        <SC.Menu ref={menuRef} aria-hidden={!open} onClick={() => close()}>
          {children}
        </SC.Menu>
      )}
    >
      <SC.Box data-qa="account-dropdown" role="menu">
        {button ? (
          <SC.ButtonWrapper ref={anchorRef}>{button}</SC.ButtonWrapper>
        ) : (
          <DotsButton ref={anchorRef} opened={opened} qaAttribute={dotsButtonQaAttribute} />
        )}
      </SC.Box>
    </Popper>
  )
})
