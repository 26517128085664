import { FC, memo, useCallback, useContext } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { HeaderButton } from 'ui/components/Buttons'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { QueryTable } from 'ui/components/QueryTable'
import { AuthContext } from '~auth/AuthContext'
import { useSetDefaultDevice } from '~auth/hooks/mfa'

import { columns } from './columns'
import { CreateDeviceDialog } from './components'
import { dataSources } from './dataSources'
import { selector } from './selector'
import { useCreation } from './useCreation'
import { useDeletion } from './useDeletion'

export const MFADevices: FC = memo(() => {
  const { portalName } = useContext(AuthContext)
  const defaultDeviceMutation = useSetDefaultDevice(portalName)
  const { Confirmation, startDeleting } = useDeletion(portalName)
  const {
    challengeStartedAt,
    totpUrl,
    resetChallenge,
    retryChallenge,
    sendSecurityCode,
    isCreationDialogOpen,
    openCreationDialog,
    closeCreationDialog,
    createDevice
  } = useCreation(portalName)

  const setDefaultDevice = useCallback((id: number) => defaultDeviceMutation.mutate({ id }), [defaultDeviceMutation])

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="MFA Devices"
      actions={
        <HeaderButton size="middle" onClick={openCreationDialog}>
          Add New
        </HeaderButton>
      }
    >
      <Confirmation />
      <CreateDeviceDialog
        challengeStartedAt={challengeStartedAt}
        totpUrl={totpUrl}
        onClose={closeCreationDialog}
        open={isCreationDialogOpen}
        onCreateDevice={createDevice}
        onResetChallenge={resetChallenge}
        onRetryChallenge={retryChallenge}
        onSendCode={sendSecurityCode}
      />
      <QueryTable
        layerProperties={{ rounded: true, shadowed: true }}
        name="mfa-devices-table"
        dataQa={TableDataQa.MFA_DEVICES}
        columns={columns(startDeleting, setDefaultDevice)}
        dataSources={dataSources(portalName)}
        dataSourceSelector={selector}
        sorting={{
          order: [
            {
              field: 'id',
              direction: 'DESC'
            }
          ],
          onChange: () => ({})
        }}
      />
    </PageSection>
  )
})
