import { FC } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { Toast, handleFormSubmissionError } from 'ui/components'
import { DocumentsPaths } from '~bank-documents/constants'
import { ManagePeriodDialog } from '~bank-documents/routes/commonPages/ManagePeriodDialog'
import { useSaveDocumentPeriod } from '~bank-documents/service/periodEdit'
import { DocumentStatuses } from '~bank-documents/types/enums/DocumentStatuses'
import { ManagePeriodForm } from '~bank-documents/types/periodEdit'

const PeriodCreate: FC = () => {
  const routeApi = getRouteApi(DocumentsPaths.NEW_PERIOD)

  const navigate = routeApi.useNavigate()

  const documentId = routeApi.useParams({ select: (s) => s.documentId })
  const documentName = routeApi.useLoaderData({ select: (s) => s.name })

  const formApi = useForm({
    defaultValues: new ManagePeriodForm(true),
    onSubmit: async ({ value }) => await savePeriod({ documentId: Number(documentId), ...value })
  })

  const onClose = () => navigate({ to: DocumentsPaths.DETAILS, params: { documentId }, resetScroll: false })

  const { routine: savePeriod } = useSaveDocumentPeriod({
    onSuccess: async () => {
      Toast.successSmall('Document period was created.')

      onClose()
    },
    onError: (error) => {
      handleFormSubmissionError<ManagePeriodForm>(error, formApi)
    }
  })

  if (!documentName) return null

  return (
    <ManagePeriodDialog
      form={formApi}
      appearanceInfo={{ overviewDocumentName: documentName, status: DocumentStatuses.NEW }}
      dialogTitle="Add New Period"
      onClose={onClose}
    />
  )
}

export default PeriodCreate
