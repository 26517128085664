import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

type CityProps = {
  index1: number
  index2: number
}

export const City = ({ index1, index2 }: CityProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1}].addressesInfo[${index2}].city` as 'subjectsInfo[0].addressesInfo[0].city'}
      children={(field) => (
        <FF
          field={field}
          label="12. City"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} maxLength={50} />}
        />
      )}
    />
  )
}
