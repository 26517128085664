import { FieldApi } from '@tanstack/react-form'
import { usAndTerritories } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const CountryRenderer = ({ field }: { field: FieldApi<SarEdit, 'subjectsInfo[0].addressesInfo[0].country'> }) => {
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <FF
      field={field}
      label="15. Country"
      input={(p) => (
        <Select
          {...p}
          data={usAndTerritories}
          emptyOption={{ label: 'Not selected', value: '' }}
          disabled={isAllInfoUnavailable}
          data-qa="15. Country"
          options-data-qa="15. Country option"
        />
      )}
    />
  )
}

export const Country = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const state = formApi.useStore((state) => state.values.subjectsInfo[index1].addressesInfo[index2].state)

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].addressesInfo[${index2 as 0}].country`}
      validators={{
        onChange: ({ value }) => {
          if (typeof value !== 'undefined' && value !== 'US' && state.length > 0) {
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].addressesInfo[${index2 as 0}].state`, '')
            formApi.setFieldMeta(`subjectsInfo[${index1 as 0}].addressesInfo[${index2 as 0}].state`, (stateMeta) => ({
              ...stateMeta,
              errors: [],
              errorMap: {}
            }))
          }

          return undefined
        }
      }}
      children={(field) => <CountryRenderer field={field} />}
    />
  )
}
