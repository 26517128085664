import { FC, memo, useEffect, useState } from 'react'

import { notFound } from '@tanstack/react-router'

import { PageProps, defaultProps } from './PageProps'
import { Content, Header } from './components'

import * as SC from './styles'

export const Page: FC<PageProps> = memo((props) => {
  const { isNotFound, isPending, face, title, subTitle, children, actions, footer, breadCrumbs, noPadding, ...rest } = {
    ...defaultProps,
    ...props
  }

  const [wasPending, setWasPending] = useState(false)

  useEffect(() => {
    if (isPending) {
      setWasPending(true)
    }
    if (!isPending && wasPending && isNotFound) {
      throw notFound()
    }
  }, [isPending, setWasPending, wasPending, isNotFound])

  const renderBoxHeaderAndContent = () => (
    <>
      {(title || subTitle || actions) && <Header title={title} subTitle={subTitle} face={face} actions={actions} />}
      {!!breadCrumbs && <SC.BreadCrumbsWrapper>{breadCrumbs}</SC.BreadCrumbsWrapper>}
      <Content isPending={isPending} face={face} noPadding={noPadding}>
        {children}
      </Content>
    </>
  )

  return (
    <SC.Box {...rest} data-helios-page $widthFooter={!!footer}>
      {footer ? (
        <SC.BoxWithFooterInner>{renderBoxHeaderAndContent()}</SC.BoxWithFooterInner>
      ) : (
        <>{renderBoxHeaderAndContent()}</>
      )}
      {!!footer && footer}
    </SC.Box>
  )
})
