import { getRouteApi } from '@tanstack/react-router'
import { Form, useFormData } from 'brief-form'
import { handlerServerError } from 'errors'
import {
  CheckBox,
  FieldSet,
  Info,
  LeaveConfirmation,
  Page,
  PageFace,
  Panel,
  TextInput,
  TextareaInput,
  Validators
} from 'ui/components'
import { BPLayout } from 'ui/components/BPLayout'
import { Col, Col2x, FormRow, Row } from 'ui/themes/globalStyles'

import { Footer } from './Footer'
import { usePutApplicationClientDocument } from './hooks'
import { ApplicationDocumentEditWebpage } from './types'

import * as SC from './styles'

const routeApi = getRouteApi('/onboarding/applications/$applicationId/document/$documentId/edit')

const ApplicationDocumentEdit = () => {
  const navigate = routeApi.useNavigate()
  // @todo why type not working, look at this when we get worked router types
  const {
    webPage,
    applicationId,
    documentId
  }: { webPage: ApplicationDocumentEditWebpage; applicationId: number; documentId: number } = routeApi.useLoaderData()

  const { config, Field, isDirty, validate } = useFormData({
    initialValue: webPage.form
  })

  const { routine: putApplicationClientDocument, isLoading: isSubmitting } = usePutApplicationClientDocument({
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      await navigate({
        to: '/onboarding/applications/$applicationId/document/$documentId',
        params: { applicationId, documentId }
      })
    }
  })

  const handleSubmit = async () => {
    if (!validate({ updateFields: true }).valid) {
      return
    }
    await putApplicationClientDocument(config.value)
  }

  return (
    <BPLayout>
      <LeaveConfirmation preventLeaving={isDirty && !isSubmitting}>
        <Page
          face={PageFace.SECONDARY}
          title="Document"
          subTitle="Add New"
          footer={
            <Footer
              applicationId={applicationId}
              documentId={documentId}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit}
            />
          }
        >
          <Panel rounded shadowed collapsible={false} title="Edit Document Information">
            <SC.PanelBody>
              <FieldSet legend="Client Document Details">
                <Form config={config}>
                  <FormRow>
                    <Col>
                      <Field
                        name="name"
                        label="Document Name"
                        input={TextInput}
                        required
                        validator={Validators.required.field}
                        inputProps={{}}
                      />
                    </Col>
                  </FormRow>
                  <Row>
                    <Col>
                      <Info label="Subject Type">{webPage.subject.type}</Info>
                    </Col>
                    <Col>
                      <Info label={webPage.subject.type}>{webPage.subject.name}</Info>
                    </Col>
                  </Row>
                  <FormRow>
                    <Col>
                      <Field name="internal" label="Internal" input={CheckBox} inputProps={{}} />
                    </Col>
                    <Col>
                      <Field name="optional" label="Optional" input={CheckBox} inputProps={{}} />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col2x>
                      <Field name="notes" label="Notes" input={TextareaInput} inputProps={{}} />
                    </Col2x>
                  </FormRow>
                </Form>
              </FieldSet>
            </SC.PanelBody>
          </Panel>
        </Page>
      </LeaveConfirmation>
    </BPLayout>
  )
}

export default ApplicationDocumentEdit
