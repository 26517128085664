import { useCallback } from 'react'

import { TablePagination, TableSorting, UseFilterStateOpts, useFilterFormState } from 'ui/components/Table'

import { hooks } from '../hooks'
import { Payload } from '../types'

const emptyFilter: Payload = {
  page: null,
  size: null,
  relationshipId: null,
  status: null,
  sortBy: null,
  sortDesc: null
}

export const useReportPage = () => {
  const onBeforeChange: UseFilterStateOpts<Payload>['onBeforeChange'] = useCallback((value, oldValue) => {
    if (value.relationshipId !== oldValue.relationshipId || value.status !== oldValue.status) {
      return { ...value, page: 1 }
    }
    return undefined
  }, [])

  const filter = useFilterFormState({
    onBeforeChange,
    initialValue: emptyFilter
  })

  const { data, isFetching } = hooks.usePage(filter.value, {
    onSuccess: (response) => {
      if (filter.value.page === null && typeof response.form.page === 'number') {
        filter.set({ newValue: response.form, reset: true })
      }
    }
  })

  let sorting: TableSorting | undefined = undefined
  if (filter.value.sortBy) {
    sorting = {
      order: [{ field: filter.value.sortBy, direction: filter.value.sortDesc ? 'DESC' : 'ASC' }],
      onChange: (order) => {
        filter.set({ newValue: { sortBy: order.field, sortDesc: order.direction === 'DESC' } })
      }
    }
  }

  const pagination: TablePagination = {
    page: filter.value.page || null,
    pageSize: filter.value.size || null,
    total: data?.total,
    onChange: (page, size) => {
      filter.set({ newValue: { page, size } })
    }
  }

  return {
    data,
    isFetching,
    pagination,
    filter,
    sorting
  }
}
