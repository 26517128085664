import { memo } from 'react'

import styled from 'styled-components'

import { PageSectionFace } from '../pageSectionFace'

interface Props {
  face?: PageSectionFace
  title?: React.ReactNode
  titleBefore?: React.ReactNode
  titleAfter?: React.ReactNode
  actions?: React.ReactNode
  first?: boolean
}

//region Styled

const Box = styled.header<{ face?: PageSectionFace; first?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(p) => (p.face === PageSectionFace.THIRD ? '68px' : '72px')};
  margin: ${(p) => (p.face === PageSectionFace.THIRD ? '0 -50px 20px' : '0')};
  padding: ${(p) => (p.face === PageSectionFace.THIRD ? '0 50px' : '0')};
  background-color: ${(p) =>
    p.face === PageSectionFace.THIRD ? p.theme.uiKit.colors.global.grey.grey300 : 'transparent'};
  ${(p) => (p.face === PageSectionFace.THIRD && p.first ? 'margin-top: -20px;' : '')};

  border-bottom: ${(p) =>
    p.face === PageSectionFace.PRIMARY ? `1px solid ${p.theme.uiKit.colors.global.grey.grey300}` : 'none'};
`

const BoxPrimary = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--theme-colors-global-grey-grey300);
`

const Heading = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
`

//endregion

export const Header = memo(({ title, titleBefore, titleAfter, face, actions, first }: Props) => {
  switch (face) {
    case PageSectionFace.PRIMARY:
      return (
        <BoxPrimary>
          <Heading>
            {!!titleBefore && <div>{titleBefore}</div>}
            <h4 data-qa={title}>{title}</h4>
            {!!titleAfter && <div>{titleAfter}</div>}
          </Heading>
          {!!actions && <Actions>{actions}</Actions>}
        </BoxPrimary>
      )
    default:
      return (
        <Box face={face} first={first}>
          <Heading>
            {!!titleBefore && <div>{titleBefore}</div>}
            <h3 data-qa={title}>{title}</h3>
            {!!titleAfter && <div>{titleAfter}</div>}
          </Heading>
          {!!actions && <Actions>{actions}</Actions>}
        </Box>
      )
  }
})
