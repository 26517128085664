import { Layer, Page, Spinner, SpinnerSize } from 'ui/components'

import { Actions, Filter, ManageUserDialog, ResetPassword, UsersTable } from './components'
import { NEW_USER } from './constants'
import { useUsers } from './hooks'

export default () => {
  const { data, isFetching, filter, resetFilter, onSaved, edit, changeStatus, resetPassword } = useUsers()
  const { onEdit, userToEdit, closeEditDialog } = edit
  const { UserStatusConfirmation, onChangeStatus } = changeStatus
  const { userToResetPassword, closeResetPasswordDialog, onResetPassword } = resetPassword
  const { active, page, size, searchTerm, onSearch, onActiveChange, onPageChange } = filter

  if (isFetching && !page) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  // Don't render page until filter contains values returned from API.
  if (!page || !data) {
    return null
  }

  const { activeValues, enableCreate, enableRoleSelector, roles, users, totalCount } = data

  return (
    <Page
      title="All Users"
      actions={
        <Actions
          searchTerm={searchTerm}
          onSearch={onSearch}
          onAddUser={enableCreate ? () => onEdit(NEW_USER) : undefined}
        />
      }
    >
      <UserStatusConfirmation />
      <ResetPassword user={userToResetPassword} onClose={closeResetPasswordDialog} />
      <ManageUserDialog
        enableRoleSelector={enableRoleSelector}
        initialValue={userToEdit}
        onClose={closeEditDialog}
        roles={roles}
        onSaved={onSaved}
      />
      <Layer rounded shadowed stretch>
        <Filter active={active} onActiveChange={onActiveChange} activeValues={activeValues} onReset={resetFilter} />
        <UsersTable
          users={users}
          onEdit={onEdit}
          onChangeStatus={onChangeStatus}
          onResetPassword={onResetPassword}
          onPageChange={onPageChange}
          page={page}
          pageSize={size}
          total={totalCount}
        />
      </Layer>
    </Page>
  )
}
