import { FC, memo } from 'react'

import { LayerProps } from './LayerProps'

import { Container } from './styles'

export const Layer: FC<LayerProps> = memo((props) => {
  const { children, rounded, shadowed, stretch, onClick, ...rest } = props

  return (
    <Container
      {...rest}
      rounded={rounded || false}
      shadowed={shadowed || false}
      stretch={stretch || true}
      onClick={onClick}
    >
      {children}
    </Container>
  )
})
