import { FC, memo } from 'react'

import { ScrollRestoration } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { Page, Panel, PanelFace } from 'ui/components'

import { HistoryTable } from '../../components/HistoryTable/HistoryTable'
import { Filter } from './components'
import { useHistoryAuditList } from './hooks/useHistoryAuditList'

const HistoryAuditList: FC = memo(() => {
  const {
    isFetching,
    items,
    filterForm,
    tableState,
    actionOptions,
    bankUserOptions,
    entityTypeOptions,
    pagination,
    onClear,
    onClickEntityId
  } = useHistoryAuditList()

  return (
    <Page title="Activity Audit">
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <ScrollRestoration />
        <Filter
          form={filterForm}
          expanded={tableState?.expanded}
          onToggle={tableState.onFilterToggle}
          onClear={onClear}
          actionOptions={actionOptions}
          bankUserOptions={bankUserOptions}
          entityTypeOptions={entityTypeOptions}
        />
        {items && (
          <HistoryTable
            dataQa={TableDataQa.BP_ENTITY_HISTORY_LIST}
            onClickEntityId={onClickEntityId}
            loading={isFetching}
            items={items}
            pagination={pagination}
          />
        )}
      </Panel>
      <ScrollRestoration />
    </Page>
  )
})

export default HistoryAuditList
