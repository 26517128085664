import { AvailableServicesConditions } from '../../types/state'
import { AvailableService } from './AvailableServicesFormProps'

export const getAvailableServicesList = ({
  isAvailServiceCheckingOperatingEnable,
  isAvailServiceCheckingPayrollEnable,
  isAvailServiceCheckingEnable,
  isBusinessSavingsEnable,
  isAvailServiceAchOriginationEnable,
  isAvailServiceMobileDepositEnable,
  isWireTransferEnable,
  isAvailServiceRollingCoinChangeOrderEnable,
  isAvailServiceRemoteDepositCaptureEnable,
  isAvailServiceEscrowEnable,
  isAvailServiceCreditCardEnable,
  isAvailServiceWireDomesticEnable,
  isAvailServiceWireDomesticOnlyEnable,
  isAvailServiceWireForeignEnable,
  isAvailServicePersonalCheckingEnable,
  isAvailServicePersonalSavingsEnable,
  isAnticipatedActivityAtmSettlementEnable
}: AvailableServicesConditions): AvailableService[] =>
  [
    isAvailServiceCheckingOperatingEnable && {
      name: 'availServiceCheckingOperating',
      label: 'Checking - Operating',
      dataQa: 'Available Services - Checking - Operating'
    },
    isAvailServiceCheckingPayrollEnable && {
      name: 'availServiceCheckingPayroll',
      label: 'Checking - Payroll',
      dataQa: 'Checking - Payroll'
    },
    isAvailServiceCheckingEnable && {
      name: 'availServiceChecking',
      label: 'Business Checking',
      dataQa: 'Business Checking'
    },
    {
      name: 'availServiceSavings',
      label: isBusinessSavingsEnable ? 'Business Savings' : 'Savings',
      dataQa: isBusinessSavingsEnable ? 'Business Savings' : 'Savings'
    },
    isAvailServiceAchOriginationEnable && {
      name: 'availServiceACHOrigination',
      label: 'ACH Origination',
      dataQa: 'ACH Origination'
    },
    isAvailServiceMobileDepositEnable && {
      name: 'availServiceMobileDeposit',
      label: 'Mobile Deposit',
      dataQa: 'Mobile Deposit'
    },
    isWireTransferEnable && {
      name: 'availServiceWire',
      label: 'Wire Transfer',
      dataQa: 'Wire Transfer'
    },
    isAvailServiceRollingCoinChangeOrderEnable && {
      name: 'availServiceRollingCoinChangeOrder',
      label: 'Rolled Coin/Change Order',
      dataQa: 'Rolled Coin/Change Order'
    },
    isAvailServiceRemoteDepositCaptureEnable && {
      name: 'availServiceRemoteDepositCapture',
      label: 'Remote Deposit Capture',
      dataQa: 'Available Services - Remote Deposit Capture'
    },
    isAvailServiceEscrowEnable && {
      name: 'availServiceEscrow',
      label: 'Escrow',
      dataQa: 'Escrow'
    },
    {
      name: 'availServiceOnlineMobileBanking',
      label: 'Online/Mobile Banking',
      dataQa: 'Online/Mobile Banking'
    },
    {
      name: 'availServiceBillPay',
      label: 'Bill Pay',
      dataQa: 'Bill Pay'
    },
    {
      name: 'availServiceDebitCard',
      label: 'Debit Card',
      dataQa: 'Debit Card'
    },
    isAvailServiceCreditCardEnable && {
      name: 'availServiceCreditCard',
      label: 'Credit Card',
      dataQa: 'Credit Card'
    },
    isAvailServiceWireDomesticEnable && {
      name: 'availServiceWireDomestic',
      label: 'Wire Transfer - Domestic',
      dataQa: 'Wire Transfer - Domestic'
    },
    isAvailServiceWireDomesticOnlyEnable && {
      name: 'availServiceWireDomesticOnly',
      label: 'Wire Transfer - Domestic Only',
      dataQa: 'Wire Transfer - Domestic Only'
    },
    isAvailServiceWireForeignEnable && {
      name: 'availServiceWireForeign',
      label: 'Wire Transfer - Foreign',
      dataQa: 'Wire Transfer - Foreign'
    },
    isAvailServicePersonalCheckingEnable && {
      name: 'availServicePersonalChecking',
      label: 'Personal Checking',
      dataQa: 'Personal Checking'
    },
    isAvailServicePersonalSavingsEnable && {
      name: 'availServicePersonalSavings',
      label: 'Personal Savings',
      dataQa: 'Personal Savings'
    },
    isAnticipatedActivityAtmSettlementEnable && {
      name: 'availServiceATMSettlement',
      label: 'ATM Settlement',
      dataQa: 'ATM Settlement'
    }
  ].filter((item): item is AvailableService => Boolean(item))
