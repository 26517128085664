import { FC } from 'react'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { useDeleteInvoiceSubmittal } from '~bank-invoices/service/deleteInvoiceSubmittal'

type UseDeleteInvoiceSubmittalConfirmationReturn = {
  DeleteInvoiceSubmittalConfirmation: () => JSX.Element | null
  onShowInvoiceSubmittalConfirmation: (openParams: { id: number }) => void
}

type ConfirmationOpenParams = { id?: number }

const Message: FC<ConfirmationOpenParams> = (props) => (
  <span>
    Are you sure want to delete invoice submittal <i>IS-{props.id}</i>?
  </span>
)

export const useDeleteInvoiceSubmittalConfirmation = ({
  onSuccess
}: {
  onSuccess: () => void
}): UseDeleteInvoiceSubmittalConfirmationReturn => {
  const { routine: deleteInvoiceSubmittal } = useDeleteInvoiceSubmittal({
    onSuccess: async () => {
      Toast.successSmall('Invoice Submittal was successfully deleted.')
      onSuccess()
    },
    onError: (error) => handlerServerError(error)
  })

  const { Confirmation: DeleteInvoiceSubmittalConfirmation, open: onShowInvoiceSubmittalConfirmation } =
    useConfirmation<ConfirmationOpenParams>({
      message: <Message />,
      onConfirm: (params) => deleteInvoiceSubmittal(Number(params?.id)),
      confirmationButtonText: 'Delete',
      isConfirmationNegative: true
    })

  return { DeleteInvoiceSubmittalConfirmation, onShowInvoiceSubmittalConfirmation }
}
