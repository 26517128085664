import { FC, memo } from 'react'

import { CurrencyLabelProps } from './CurrencyLabelProps'

export const CurrencyLabel: FC<CurrencyLabelProps> = memo((props) => {
  const { value, minimumFractionDigits = 0, maximumFractionDigits = 0 } = props
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits
  })

  return <span>{value ? formatter.format(value) : '---'}</span>
})
