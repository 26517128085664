import { FC, memo } from 'react'

import * as SC from '../styles'
import { AdverseMediaPublication } from 'commons/types/DTO/bank'

import { Publication } from '../Publication/Publication'
import { PublicationListProps } from './PublicationListProps'

export const PublicationList: FC<PublicationListProps> = memo(
  ({
    items,
    markAsReviewed,
    markAsUnreviewed,
    selectedPublications,
    onSelectPublication,
    onOpenPublication,
    onOpenPublicationNotes,
    disabled
  }) => (
    <SC.PublicationList>
      {items.map((item: AdverseMediaPublication) => (
        <Publication
          key={item.id}
          value={item}
          onOpenPublication={onOpenPublication}
          onOpenNotes={onOpenPublicationNotes}
          onSelectPublication={onSelectPublication}
          isPublicationSelected={selectedPublications.includes(item.id)}
          reviewAction={item.needs_review ? markAsReviewed : markAsUnreviewed}
          disabled={disabled}
        />
      ))}
    </SC.PublicationList>
  )
)
