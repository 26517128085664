import { FC } from 'react'

import { InternalTransfersExportList } from 'commons/types/DTO/bank'
import { TableDataQa } from 'commons/types/enums'
import { Table } from 'ui/components/Table'

import { InternalTransferExportsTableProps } from './InternalTransferExportsTableProps'
import { columns } from './columns'
import { Filter } from './components/Filter'
import { useTable } from './hooks'

export const InternalTransferExportsTable: FC<InternalTransferExportsTableProps> = (props) => {
  const {
    items,
    tableState: { filter, pagination },
    filterForm,
    setLoading,
    loading,
    totalCount,
    statuses
  } = props

  const { ProcessConfirmation, startInternalTransferExport, mappedInternalTransfersExport } = useTable(
    items,
    setLoading
  )

  return (
    <>
      <ProcessConfirmation />
      <Filter form={filterForm} expanded={filter.expanded} onToggle={filter.toggle} statuses={statuses} />
      <Table<InternalTransfersExportList>
        dataQa={TableDataQa.BP_INTERNAL_TRANSFERS_EXPORTS_LIST}
        columns={columns({ onProcess: startInternalTransferExport })}
        dataSource={mappedInternalTransfersExport}
        loading={loading}
        pagination={{
          total: totalCount,
          page: pagination.page,
          pageSize: pagination.size,
          onChange: (page: number, size: number) => {
            pagination.change(page, size)
          }
        }}
      />
    </>
  )
}
