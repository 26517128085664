import { useCallback } from 'react'

import { useFormData } from 'brief-form'
import { LicenseManageRequest } from 'commons/types/DTO/bank'
import { updateFormAfterApiError } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { transformEditLicenseFormValues } from './formHelper'

export const useBehavior = (
  initial: LicenseManageRequest,
  deleteHandler: (id: number | null | undefined) => Promise<void>,
  saveHandler: (payload: LicenseManageRequest) => Promise<LicenseManageRequest>
) => {
  if (!initial.id) initial.active = true
  const { config, Field, Form, validate } = useFormData<LicenseManageRequest>({ initialValue: initial })

  const deleteLicense = useCallback(() => deleteHandler(initial.id), [initial, deleteHandler])

  const { Confirmation, open } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete License <i>{initial.name}</i>?
      </span>
    ),
    onConfirm: deleteLicense,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const saveLicense = useCallback(() => {
    const e = validate({ updateFields: true })
    if (!e.valid) {
      const fieldName = Object.keys(e.errors)[0]
      if (config.registeredFields.current) {
        const ref = config.registeredFields.current[fieldName]?.ref
        if (ref) {
          ref.current.scrollIntoView()
        }
      }
      return Promise.resolve(config.value)
    }
    return saveHandler({ ...config.value, ...transformEditLicenseFormValues(config.value) }).catch((error) => {
      updateFormAfterApiError(config.value, config.errors, error, config.onChange)
      return Promise.resolve(config.value)
    })
  }, [validate, config, saveHandler])

  return {
    Form,
    Field,
    config,
    saveLicense,
    Confirmation,
    deleteLicense: open
  }
}
