import { BankAccountDeprecated } from 'commons/types/DTO/bank'
import { bankAccountNumberClear } from 'commons/utils'
import { ReadHook, useStateManager } from 'state-manager'

import { getHandler } from './getHandler'

export type UseGetBankAccountOpts = {
  id: number
  companyId?: number
}

export const useGetBankAccount: ReadHook<BankAccountDeprecated, UseGetBankAccountOpts> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(['bank-accounts', opts?.id], getHandler(opts?.id, opts?.companyId || 0), {
    keepPreviousData: true
  })

  if (data && data.records.length) {
    data.records[0].number = bankAccountNumberClear(data.records[0].number)
  }

  return {
    ...rest,
    data: data ? data.records[0] : undefined
  }
}
