import { FC, memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Table } from 'ui/components/Table'

import { UsersTableProps } from './UsersTable.types'
import { columns } from './columns'

export const UsersTable: FC<UsersTableProps> = memo((props) => {
  const { page, pageSize, total, onPageChange, users } = props

  return (
    <Table
      dataQa={TableDataQa.BP_USERS_LIST}
      columns={columns(props)}
      dataSource={users.map((item) => ({ item }))}
      pagination={{
        page,
        pageSize,
        total,
        onChange: onPageChange
      }}
    />
  )
})
