import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import { Value } from './fields'

type MarketWhereTradedProps = {
  index: number
  field: FieldApi<SarEdit, 'suspiciousActivityInfo.cusipNumbers'>
}

export const CUSIPNumber = ({ index, field }: MarketWhereTradedProps) => {
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => field.removeValue(index),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <FormRow>
        <Col>
          <Value index={index} />
        </Col>
      </FormRow>
      {field.state.value.length > 1 && (
        <FormRow>
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
        </FormRow>
      )}
    </>
  )
}
