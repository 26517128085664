import { FC, memo } from 'react'

import { TextBadgeProps } from '../props'

import * as SC from './styles'

export const TextBadge: FC<TextBadgeProps> = memo((props) => {
  const { isNumber = false, color, children } = props

  switch (color) {
    case 'default':
      return <SC.TextBadgeDefault $isNumber={isNumber}>{children}</SC.TextBadgeDefault>
    case 'black':
      return <SC.TextBadgeBlack $isNumber={isNumber}>{children}</SC.TextBadgeBlack>
    case 'white':
      return <SC.TextBadgeWhite $isNumber={isNumber}>{children}</SC.TextBadgeWhite>
    case 'positive':
      return <SC.TextBadgePositive $isNumber={isNumber}>{children}</SC.TextBadgePositive>
    case 'disabled':
      return <SC.TextBadgeDisabled $isNumber={isNumber}>{children}</SC.TextBadgeDisabled>
    case 'warning':
      return <SC.TextBadgeWarning $isNumber={isNumber}>{children}</SC.TextBadgeWarning>
    case 'warning_secondary':
      return <SC.TextBadgeWarningSecondary $isNumber={isNumber}>{children}</SC.TextBadgeWarningSecondary>
    case 'error':
      return <SC.TextBadgeError $isNumber={isNumber}>{children}</SC.TextBadgeError>
    case 'none':
      return <></>
    default:
      return <></>
  }
})
