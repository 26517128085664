import { useCallback, useEffect, useState } from 'react'

import { handlerServerError } from 'errors'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { useGetApplicationLink } from '../../hooks/useGetApplicationLink'
import { GetLinkModalProps } from './GetLinkModalProps'

import { CopyButton, ModalContent, Row, UrlInput } from './styles'

export const GetLinkModal = ({ onClose, applicationId }: GetLinkModalProps) => {
  const [isCopied, setCopied] = useState(false)
  const [url, setUrl] = useState('')
  const { getLink } = useGetApplicationLink()

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(url)
    setCopied(true)
  }, [url])

  useEffect(() => {
    getLink(applicationId).then(
      (data) => setUrl(data.link),
      (error: ErrorEvent) => {
        onClose()
        handlerServerError(error)
      }
    )
  }, [])

  return (
    <Dialog id="get-link" onClose={onClose} face={DialogFace.PRIMARY} title="Application link for the customer">
      <ModalContent>
        <Row>
          {!url && <Spinner centered size={SpinnerSize.M} />}
          {url && (
            <>
              <UrlInput readOnly value={url} onChange={() => null} />
              <CopyButton face="positive" onClick={onCopy}>
                {isCopied ? 'Copied' : 'Copy'}
              </CopyButton>
            </>
          )}
        </Row>
      </ModalContent>
    </Dialog>
  )
}
