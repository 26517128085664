export const getInfoMessage = (hash: string, themeName?: string) => {
  if (hash === 'password-set') {
    return 'We’ve just sent you an Email to reset your password.'
  }
  if (themeName === 'Shore') {
    return (
      'Shore United Bank’s Select Client Portal will be moving to select.portal.shoreunitedbank.com. ' +
      'You will be automatically redirected but please take a moment to bookmark the new website for direct access ' +
      'in the future.'
    )
  }
  return undefined
}
