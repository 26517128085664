import { FC, ReactNode, memo } from 'react'

import SC from 'styled-components'

import { ChipFace } from './ChipFace'

export interface ChipProps {
  children: ReactNode
  face?: ChipFace
  withoutMargins?: boolean
}

const getBackgroundColor = (
  successColor: string,
  defaultColor: string,
  dangerColor: string,
  warningColor: string,
  face?: ChipFace
) => {
  if (face === ChipFace.PRIMARY) {
    return successColor
  }
  if (face === ChipFace.DANGER) {
    return dangerColor
  }
  if (face === ChipFace.WARNING) {
    return warningColor
  }
  return defaultColor
}

//region Styles

const Box = SC.div<{ $face?: ChipFace; $withoutMargins: boolean }>`
  display: inline-block;
  background: ${(p) =>
    getBackgroundColor(
      p.theme.uiKit.colors.theme.brand.primary,
      p.theme.uiKit.colors.global.grey.grey500,
      p.theme.uiKit.colors.theme.negative.main,
      p.theme.uiKit.colors.theme.warning.main,
      p.$face
    )};
  padding: 2px 10px;
  border-radius: 100px;
  font-size: 13px;
  color: #fff;
  text-transform: uppercase;
  ${(p) =>
    !p.$withoutMargins &&
    `  
    margin-right: 8px;
    margin-bottom: 8px;
  `}

`

//endregion

export const Chip: FC<ChipProps> = memo(({ face, children, withoutMargins = false, ...rest }) => (
  <Box $face={face} $withoutMargins={withoutMargins} {...rest}>
    {children}
  </Box>
))
