import { FC, memo, useCallback, useRef } from 'react'

import { useBlocker } from '@tanstack/react-router'
import { LEAVE_CONFIRMATION_MESSAGE } from 'commons/constants/common'

import { LeaveConfirmationContext } from './LeaveConfirmationContext'
import { LeaveConfirmationProps } from './LeaveConfirmationProps'

export const LeaveConfirmation: FC<LeaveConfirmationProps> = memo((props: LeaveConfirmationProps) => {
  const { preventLeaving, children } = props

  const ref = useRef<boolean | null>(null)

  const showLeaveConfirmation = useCallback(() => {
    if (preventLeaving) {
      ref.current = true
      return confirm(LEAVE_CONFIRMATION_MESSAGE)
    }
    return true
  }, [preventLeaving])

  useBlocker({
    shouldBlockFn: () => {
      if (ref.current || !preventLeaving) return false

      const shouldLeave = confirm(LEAVE_CONFIRMATION_MESSAGE)

      return !shouldLeave
    }
  })

  return (
    <LeaveConfirmationContext.Provider value={{ showLeaveConfirmation }}>{children}</LeaveConfirmationContext.Provider>
  )
})
