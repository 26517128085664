import { useMemo } from 'react'

import { OnboardingDocumentsItem } from 'commons/types/DTO/bank/applications/manage/OnboardingDocumentsItem'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components'
import { DataSourceItem, Table } from 'ui/components/Table'

import { OnboardingDocumentsListProps } from './OnboardingDocumentsList.types'
import { columns } from './columns'

import { s } from './styles'

export const OnboardingDocumentsList = ({ application }: OnboardingDocumentsListProps) => {
  const { applicationID, onboardingDocuments } = application

  const mappedOnboardingDocuments: DataSourceItem<OnboardingDocumentsItem>[] | undefined = useMemo(
    () => onboardingDocuments?.map((item) => ({ item })),
    [onboardingDocuments]
  )

  return (
    <section id="onboarding-documents">
      <s.Header>Onboarding Documents</s.Header>
      <Layer rounded shadowed stretch>
        <Table<OnboardingDocumentsItem>
          dataQa={TableDataQa.BP_ONBOARDING_SETUPPING_DOCUMENTS_LIST}
          columns={columns(applicationID)}
          dataSource={mappedOnboardingDocuments ?? []}
        />
      </Layer>
    </section>
  )
}
