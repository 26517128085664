import { ReviewFormUploadWrap } from '../ReviewFormUploadWrap'
import { ComitteeFormUploadProps } from './ComitteeFormUploadProps'

export const ComitteeFormUpload = ({ application }: ComitteeFormUploadProps) => (
  <ReviewFormUploadWrap
    application={application}
    title="Please Upload the Committee form"
    onlyNotes={false}
    requiredNotes={true}
    type="Preliminary Committee Approval Form"
    notes=""
    buttonName="Committee form Upload"
  />
)
