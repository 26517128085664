import { FC, memo, useCallback } from 'react'

import { Request } from 'commons/utils/request'
import { Icon, IconName } from 'ui/components'

import { NameProps } from './NameProps'

import * as SC from './styles'

export const Name: FC<NameProps> = memo((props) => {
  const { name, id, readonly, onDelete } = props
  const baseUrl = Request.urls.client.documentFileDirectDownload

  const onDeleteHandler = useCallback(() => {
    onDelete(id)
  }, [id, onDelete])

  return (
    <SC.FileName>
      {!readonly && (
        <SC.DeleteButton onClick={onDeleteHandler}>
          <Icon name={IconName.DELETE_RED_CROSS} size={22} />
        </SC.DeleteButton>
      )}
      <SC.DownloadFile
        direct
        baseUrl={baseUrl}
        name={name}
        handleError={() => ({})}
        parametersForm={{ id: id }}
        withIcon={false}
      >
        {name}
      </SC.DownloadFile>
    </SC.FileName>
  )
})
