import { ctrTINTypes } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const TINType = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].tinType` as 'institutionActivitiesInfo[0].tinType'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="60. TIN type"
          field={field}
          input={(p) => (
            <Select {...p} data={ctrTINTypes} data-qa="60. TIN type" options-data-qa="60. TIN type option" />
          )}
        />
      )}
    />
  )
}
