import { FC, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { LeaveConfirmation } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Layer } from 'ui/components/Layer'
import { Link } from 'ui/components/Link'
import { Page, PageFace } from 'ui/components/Page'
import { Panel } from 'ui/components/Panel'
import { useUpdateAnnualReviewSalesDeposits } from '~bank-annual-review/hooks'

import { getAnnualReviewDetailUrl } from '../../../route'
import { AnnualReviewEditSalesAndDepositFormProps } from './AnnualReviewEditSalesAndDepositFormProps'
import { Deposits } from './Deposit'
import { RelationshipDetail } from './RelationshipDetail'
import { SalesAndDepositFormValue } from './SalesAndDepositFormValue'
import { generateFormData } from './generateFormData'
import { generateSubmitModel } from './generateSubmitModel'

import { Footer, PanelBody } from './styles'

export const AnnualReviewEditSalesAndDepositForm: FC<AnnualReviewEditSalesAndDepositFormProps> = (props) => {
  const { company, salesAndDepositsId, annualReviewId, bankAccountId, salesAndDeposits, companyId } = props
  const updateSalesDepositsMutation = useUpdateAnnualReviewSalesDeposits(companyId, annualReviewId)

  const { config, Field, isDirty, validate } = useFormData<SalesAndDepositFormValue>({
    initialValue: generateFormData(salesAndDeposits)
  })

  const [isLeaveConfirmationNeeded, setIsLeaveConfirmationNeeded] = useState(true)

  const onSubmit = () => {
    if (validate({ updateFields: true }).valid) {
      if (salesAndDeposits) {
        setIsLeaveConfirmationNeeded(false)
        updateSalesDepositsMutation.mutate(generateSubmitModel(config.value, salesAndDeposits))
      }
    }
  }

  return (
    <LeaveConfirmation preventLeaving={isLeaveConfirmationNeeded && isDirty}>
      <Page
        face={PageFace.SECONDARY}
        title="Sales & Deposit Summary"
        subTitle={`SDS-${salesAndDepositsId}`}
        footer={
          <Footer>
            <ButtonGroup margin="small">
              <Link to={getAnnualReviewDetailUrl({ companyId: companyId, id: annualReviewId })}>
                <Button face="neutral">Cancel</Button>
              </Link>
              <Button face="positive" onClick={onSubmit}>
                Submit
              </Button>
            </ButtonGroup>
          </Footer>
        }
      >
        <Form config={config}>
          <Layer rounded shadowed>
            <Panel collapsible={false} title="Edit Information">
              <PanelBody>
                {company && (
                  <RelationshipDetail
                    Field={Field}
                    company={company}
                    id={salesAndDepositsId}
                    annualReviewId={annualReviewId}
                    bank_account_id={bankAccountId}
                  />
                )}
                <Deposits Field={Field} quarterNumber={1} />
                <Deposits Field={Field} quarterNumber={2} />
                <Deposits Field={Field} quarterNumber={3} />
                <Deposits Field={Field} quarterNumber={4} />
              </PanelBody>
            </Panel>
          </Layer>
        </Form>
      </Page>
    </LeaveConfirmation>
  )
}
