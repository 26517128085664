import { getRouteApi } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Layer, Table } from 'ui/components'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

import { columns } from './columns'

type LicensingItem = AnnualReviewDetails['licensingItems'][number]

export const LicensesTable = ({ routeApi = AnnualReviewPaths.DETAILS }: { routeApi?: string }): JSX.Element | null => {
  const licensingItems = getRouteApi(routeApi).useLoaderData({
    select: (s) => s.licensingItems
  }) as AnnualReviewDetails['licensingItems']

  if (!licensingItems) return null

  const mappedLicensingItems: DataSourceItem<LicensingItem>[] = licensingItems.map((el) => ({ item: el }))

  return (
    <Layer rounded shadowed>
      <Table<LicensingItem>
        dataQa={TableDataQa.BP_ANNUAL_REVIEW_LICENSING}
        columns={columns()}
        dataSource={mappedLicensingItems}
      />
    </Layer>
  )
}
