import { FC, memo, useState } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Dialog, DialogFace, Link, Table } from 'ui/components'

import { ClientUser } from '../../types'
import { TableProps } from './AlertsTable.types'
import { columns } from './columns'

export const AlertsTable: FC<TableProps> = memo((props) => {
  const { data, pagination, isFetching } = props
  const { records } = data

  const [showClientUsers, setShowClientUsers] = useState(false)
  const [clientUsers, setClientUsers] = useState<ClientUser[]>([])

  const showClientUsersCallback = (clientUsers: ClientUser[]) => {
    setClientUsers(clientUsers)
    setShowClientUsers(true)
  }

  return (
    <>
      <Table
        dataQa={TableDataQa.BP_REPORTS_ALERTS_REPORT}
        columns={columns(showClientUsersCallback)}
        dataSource={records.map((item) => ({ item }))}
        pagination={pagination}
        loading={isFetching}
      />
      {showClientUsers && (
        <Dialog
          id="client-users"
          face={DialogFace.PRIMARY}
          title="Client Users"
          onClose={() => setShowClientUsers(false)}
        >
          <Table<ClientUser>
            dataQa={TableDataQa.BP_REPORTS_ALERTS_REPORT_CLIENT_USERS}
            columns={[
              {
                align: 'left',
                title: 'Name',
                key: 'name'
              },
              {
                align: 'left',
                title: 'Email',
                render: ({ email }) => <Link to={`mailto:${email}`}>{email}</Link>
              }
            ]}
            dataSource={clientUsers.map((el) => ({ item: el }))}
          />
        </Dialog>
      )}
    </>
  )
})
