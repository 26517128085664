import { FC, memo } from 'react'

import { CheckBox } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'

import { getAnticipatedMonthlyActivityNAList } from '../../forms/AnticipatedMonthlyActivityNAForm/helpers'
import * as SC from '../../forms/AnticipatedMonthlyActivityNAForm/styles'
import { AnticipatedMonthlyActivityNADetailsProps } from './AnticipatedMonthlyActivityNADetailsProps'

export const AnticipatedMonthlyActivityNADetails: FC<AnticipatedMonthlyActivityNADetailsProps> = memo(({ value }) => {
  const anticipatedMonthlyActivityNAList = getAnticipatedMonthlyActivityNAList()

  // TODO: https://helioscompliance.atlassian.net/browse/MP-7205
  const filteredAnticipatedMonthlyActivityNAList = anticipatedMonthlyActivityNAList.filter((el) => value[el.na])

  return (
    <InfoSet legend="Anticipated Monthly Activity">
      <SC.Table>
        <thead>
          <tr>
            <SC.TableTitle />
            <SC.TableTitle>Number of Items</SC.TableTitle>
            <SC.TableTitle>Total Amount</SC.TableTitle>
            <SC.TableTitle>N/A</SC.TableTitle>
          </tr>
        </thead>
        <tbody>
          {filteredAnticipatedMonthlyActivityNAList.length > 0 ? (
            <>
              {filteredAnticipatedMonthlyActivityNAList.map(({ label, items, amount, na }) => (
                <tr key={label}>
                  <td>{label}</td>
                  <td>
                    <Info>{value[items]}</Info>
                  </td>
                  <td>
                    <Info>{value[amount]}</Info>
                  </td>
                  <td>
                    <CheckBox value={value[na]} readOnly checkBoxLabel="" />
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td>
                <em>No selected options</em>
              </td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </SC.Table>
    </InfoSet>
  )
})
