import { FF, PageSection, PageSectionFace, TextareaInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../context'

export const Narrative = () => {
  const { formApi } = useSARFormContext()

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 5. Narrative" anchor="step5">
      <div style={{ paddingTop: 20 }}>
        <formApi.Field
          name="narrative"
          validators={{ onChange: ({ value }) => Validators.required.field(value) }}
          children={(field) => (
            <FF
              required
              field={field}
              input={(p) => <TextareaInput {...p} data-qa="Narrative" rows={25} maxLength={20000} />}
            />
          )}
        />
      </div>
    </PageSection>
  )
}
