import { ChangeEvent, FocusEvent, forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef } from 'react'

import * as SC from './styles/BaseInputStyles'

import { InputHint } from '../../core/InputHint'
import { useEmpty } from './hooks'
import { BaseInputProps } from './props'

export const BaseInput = memo(
  forwardRef<HTMLInputElement, BaseInputProps>((props: BaseInputProps, forwardRef) => {
    const {
      value,
      onChange,
      error,
      required,
      autoFocus,
      onBlur,
      disabled,
      hint,
      renderIcon,
      iconPosition,
      validator,
      hideHint,
      toUpper,
      className,
      ...rest
    } = props

    const inputRef = useRef<HTMLInputElement | null>(null)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useImperativeHandle(forwardRef, () => inputRef.current)

    const { isEmpty, handleChange: emptyHandleChange } = useEmpty(value, onChange)

    useEffect(() => {
      const timeout = setTimeout(() => {
        if (autoFocus && inputRef.current) {
          inputRef.current.focus()
        }
      }, 0)

      return () => clearTimeout(timeout)
    }, [autoFocus])

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (toUpper) {
          event.target.value = event.target.value.toUpperCase()
        }
        // if error set by onblur validation, do not undo that, validate on change also
        emptyHandleChange(event.target.value, error && validator ? validator(event.target.value) : null)
      },
      [error, validator, emptyHandleChange, toUpper]
    )

    // validate value on blur only
    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        if (validator && inputRef.current && onChange) {
          onChange(inputRef.current.value, validator(inputRef.current.value))
        }
        if (onBlur) {
          onBlur(event)
        }
      },
      [inputRef, validator, onChange, onBlur]
    )

    const showRequired = required && !error && !disabled

    return (
      <SC.Wrapper $error={!!error} $disabled={disabled} className={className}>
        {showRequired && <SC.RequiredStyled data-testid="required" isInputEmpty={isEmpty} />}
        <SC.InputAndIconWrapper $position={iconPosition}>
          <SC.Input
            {...rest}
            ref={inputRef}
            value={value ?? ''}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            role="textbox"
          />
          {!!renderIcon && (
            <SC.IconWrapper $position={iconPosition} $disabled={disabled} $error={!!error}>
              {renderIcon}
            </SC.IconWrapper>
          )}
        </SC.InputAndIconWrapper>
        <InputHint data-testid="hint" hint={hint} disabled={disabled} hideHint={hideHint} />
      </SC.Wrapper>
    )
  })
)
