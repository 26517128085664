import { FC, memo, useCallback } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { DocumentsPaths } from 'bank-documents/src/constants'
import { useDeleteDocumentConfirmation } from 'bank-documents/src/hooks/useDeleteDocumentConfirmation'
import { DocumentSubjectType, TableDataQa } from 'commons/types/enums'
import { Layer, Table } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'
import { Link } from 'ui/components/Link'
import { PageSection } from 'ui/components/Page'

import { ContactDocumentsProps } from './ContactDocumentsProps'
import { columns } from './columns'
import { Filter } from './components'

export const ContactDocuments: FC<ContactDocumentsProps> = memo((props) => {
  const { data, invalidate, config, contactName } = props
  const {
    documents,
    documentsTotal,
    form: { id, docFilter }
  } = data

  const routerState = useRouterState()
  const returnUrl = routerState.location.pathname

  const { onFilterChange, isFetching, order, onOrderChange } = config
  const { page, size } = docFilter

  const onPageChange = useCallback(
    (page: number, size: number) => onFilterChange({ ...data.form.docFilter, page, size }),
    [onFilterChange, data.form.docFilter]
  )

  const { DeleteConfirmation, openDeleteDocumentConfirmation: onDocumentDelete } = useDeleteDocumentConfirmation({
    onSuccess: invalidate
  })

  return (
    <PageSection
      title="Documents"
      anchor="documents"
      actions={
        <Link
          to={DocumentsPaths.NEW}
          search={{
            subjectType: DocumentSubjectType.CONTACT,
            contactId: id,
            subjectName: contactName,
            returnUrl
          }}
        >
          <HeaderButton size="middle">Add New</HeaderButton>
        </Link>
      }
    >
      <DeleteConfirmation />
      <Layer rounded shadowed stretch>
        <Filter data={data} onChange={onFilterChange} />
        <Table
          dataQa={TableDataQa.BP_CONTACT_DOCUMENTS}
          dataSource={documents.map((el) => ({
            item: el
          }))}
          columns={columns({ returnUrl, onDocumentDelete })}
          sorting={{ order, onChange: onOrderChange }}
          pagination={{
            page: page,
            pageSize: size,
            onChange: onPageChange,
            total: documentsTotal
          }}
          loading={isFetching}
        />
      </Layer>
    </PageSection>
  )
})
