import { FC, memo } from 'react'

import { Info, InfoSet } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { CompanyMailingAddressFormValue } from '../../types/forms'

export type CompanyMailingAddressDetailsProps = {
  value: CompanyMailingAddressFormValue
}

export const CompanyMailingAddressDetails: FC<CompanyMailingAddressDetailsProps> = memo(({ value }) => (
  <InfoSet legend="Mailing Address">
    <Row>
      <Col>
        <Info label="Street">{value.mailingStreet}</Info>
      </Col>
      <Col>
        <Info label="City">{value.mailingCity}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="State/Province">{value.mailingState}</Info>
      </Col>
      <Col>
        <Info label="Country">{value.mailingCountry}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="Zip/Postal Code">{value.mailingPostalCode}</Info>
      </Col>
    </Row>
  </InfoSet>
))
