import { UseWebPageProps, WebPage } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'
import { Keys, ReadHook, useStateManager } from 'state-manager'

export const useGetApplicationsWebPage: ReadHook<WebPage, UseWebPageProps> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(
    Keys.BankApplication.ListByCriteria({
      applicationsGeneralStatus: opts?.applicationsGeneralStatus,
      applicationsModifiedSortDirection: opts?.applicationsModifiedSortDirection,
      applicationsPageNumber: opts?.applicationsPageNumber,
      applicationsPerPage: opts?.applicationsPerPage,
      applicationsSearchQuery: opts?.applicationsSearchQuery,
      applicationsCreatedByID: opts?.applicationsCreatedByID,
      applicationsCurrentOwnerID: opts?.applicationsCurrentOwnerID
    }),
    () =>
      Request.post(Request.urls.bank.applicationListWebpages, {
        applicationsGeneralStatus: opts?.applicationsGeneralStatus,
        applicationsModifiedSortDirection: opts?.applicationsModifiedSortDirection,
        applicationsPageNumber: opts?.applicationsPageNumber,
        applicationsPerPage: opts?.applicationsPerPage,
        applicationsSearchQuery: opts?.applicationsSearchQuery,
        applicationsCreatedByID: opts?.applicationsCreatedByID,
        applicationsCurrentOwnerID: opts?.applicationsCurrentOwnerID
      }),
    { keepPreviousData: true }
  )

  return {
    ...rest,
    data
  }
}
