import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { DatePicker } from 'ui/components/DatePicker'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { FF, Validators } from 'ui/components/Form'

import { UploadFileDialogProps } from './UploadFileDialog.types'

import { s } from './styles'

export const UploadFileDialog: FC<UploadFileDialogProps> = memo(({ onSubmit, onClose }: UploadFileDialogProps) => {
  const form = useForm({
    defaultValues: { deliveredAt: '' },
    onSubmit: async ({ value }) => {
      await onSubmit(value.deliveredAt)
    }
  })

  return (
    <Dialog
      id="add-period-file"
      title="Add file"
      face={DialogFace.PRIMARY}
      onClose={onClose}
      footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
      onSubmit={form.handleSubmit}
    >
      <s.Box>
        <form.Field
          name="deliveredAt"
          validators={{
            onSubmit: (v) => Validators.required.field(v)
          }}
          children={(deliveredAtField) => (
            <FF
              label="Delivery at"
              required={true}
              field={deliveredAtField}
              input={(props) => <DatePicker {...props} />}
            />
          )}
        />
      </s.Box>
    </Dialog>
  )
})
