import { FC, memo } from 'react'

import { InfoSetProps } from './InfoSetProps'
import { InfoSetDirection } from './infoSetDirection'

import { Body, Box, Legend } from './styles'

export const InfoSet: FC<InfoSetProps> = memo((props) => {
  const { children, legend, direction = InfoSetDirection.COLUMN, ...rest } = props

  return (
    <Box {...rest}>
      {!!legend && <Legend>{legend}</Legend>}
      <Body $direction={direction}>{children}</Body>
    </Box>
  )
})
