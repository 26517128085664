import { FC, memo } from 'react'

import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { PhysicalMailingAddressProps } from './PhysicalMailingAddressProps'

export const PhysicalMailingAddress: FC<PhysicalMailingAddressProps> = memo(({ contact }) => {
  const { physicalAddress, mailingAddress } = contact

  return (
    <>
      <InfoSet direction={InfoSetDirection.ROW} legend="Physical Address">
        <Row>
          <Col>
            <Info label="Street">{physicalAddress.street}</Info>
          </Col>
          <Col>
            <Info label="State/Province">{physicalAddress.state}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="City">{physicalAddress.city}</Info>
          </Col>
          <Col>
            <Info label="Country">{physicalAddress.country}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="Zip/Postal Code">{physicalAddress.postalCode}</Info>
          </Col>
        </Row>
      </InfoSet>

      <InfoSet direction={InfoSetDirection.ROW} legend="Mailing Address">
        <Row>
          <Col>
            <Info label="Street">{mailingAddress.street}</Info>
          </Col>
          <Col>
            <Info label="State/Province">{mailingAddress.state}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="City">{mailingAddress.city}</Info>
          </Col>
          <Col>
            <Info label="Country">{mailingAddress.country}</Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="Zip/Postal Code">{mailingAddress.postalCode}</Info>
          </Col>
        </Row>
      </InfoSet>
    </>
  )
})
