import { FC, memo } from 'react'

import * as SC from '../styles'
import { Form } from 'brief-form'
import { YesNoRadioGroup } from 'commons/types/enums'
import { Validators } from 'ui/components'
import { IconName, IconSize } from 'ui/components/Icon'
import { InfoSet } from 'ui/components/Info'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Tooltip } from 'ui/components/Tooltip'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { RoleInformationFormProps } from './RoleInformationFormProps'
import { useRoleInformationForm } from './useRoleInformationForm'

export const RoleInformationForm: FC<RoleInformationFormProps> = memo(
  ({ contactIndex, companyIndex, value, state: { isConductorRoleEnable }, onChange }) => {
    const { Field, config } = useRoleInformationForm({ companyIndex, contactIndex, formData: value, onChange })

    return (
      <Form config={config}>
        <InfoSet legend="Role Information">
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.OWNER}
                label={
                  <>
                    Is this individual/entity an&nbsp;<b>Owner</b>?
                  </>
                }
                input={RadioGroup}
                inputProps={{
                  name: `${OnboardingFormFieldNames.OWNER}${companyIndex}${contactIndex}`,
                  'data-qa': 'Is this individual/entity an Owner?',
                  data: [
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ]
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.DEBTHOLDER}
                label={
                  <>
                    Is this individual/entity an&nbsp;<b>Debtholder</b>?
                  </>
                }
                input={RadioGroup}
                inputProps={{
                  name: `${OnboardingFormFieldNames.DEBTHOLDER}${companyIndex}${contactIndex}`,
                  'data-qa': 'Is this individual/entity an Debtholder?',
                  data: [
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ]
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.ACCOUNT_SIGNER}
                label={
                  <>
                    Is this individual/entity an&nbsp;<b>Account Signer</b>?
                  </>
                }
                input={RadioGroup}
                inputProps={{
                  name: `${OnboardingFormFieldNames.ACCOUNT_SIGNER}${companyIndex}${contactIndex}`,
                  'data-qa': 'Is this individual/entity an Account Signer?',
                  data: [
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ]
                }}
              />
            </Col>
          </FormRow>
          {isConductorRoleEnable && (
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.CONDUCTOR}
                  label={
                    <SC.ConductorTooltip>
                      Is this individual/entity an&nbsp;<b>Conductor</b>?
                      <Tooltip
                        id="isConductor"
                        type="dark"
                        content={
                          <>
                            Any individuals (in addition to the account signatories) or companies <br /> that will make
                            deposits to this account, including non-signatory customer employees, <br /> non-employee
                            individuals, and security and armored services.
                          </>
                        }
                      >
                        <SC.ConductorIcon name={IconName.INFO} size={IconSize.S} />
                      </Tooltip>
                    </SC.ConductorTooltip>
                  }
                  input={RadioGroup}
                  inputProps={{
                    name: `${OnboardingFormFieldNames.CONDUCTOR}${companyIndex}${contactIndex}`,
                    'data-qa': 'Is this individual/entity an Conductor?',
                    data: [
                      { value: YesNoRadioGroup.YES, label: 'Yes' },
                      { value: YesNoRadioGroup.NO, label: 'No' }
                    ]
                  }}
                  required
                  validator={Validators.required.notNull}
                />
              </Col>
            </FormRow>
          )}
        </InfoSet>
      </Form>
    )
  }
)
