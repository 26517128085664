import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

type StateProps = {
  index1: number
  index2: number
}

export const State = ({ index1, index2 }: StateProps) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const country = formApi.useStore((s) => s.values.subjectsInfo[index1].addressesInfo[index2].country)

  return (
    <formApi.Field
      name={`subjectsInfo[${index1}].addressesInfo[${index2}].state` as 'subjectsInfo[0].addressesInfo[0].state'}
      validators={{
        onChange: ({ value }) =>
          !!value || isAllInfoUnavailable || country !== 'US'
            ? undefined
            : '"13. State" required when choose "United States of America" as "15. Country"'
      }}
      children={(field) => (
        <FF
          required={country === 'US' && !isAllInfoUnavailable}
          field={field}
          label="13. State"
          input={(p) => (
            <Select
              {...p}
              data={usStates}
              emptyOption={EMPTY_OPTION}
              disabled={isAllInfoUnavailable || country !== 'US'}
              data-qa="13. State"
              options-data-qa="13. State option"
            />
          )}
        />
      )}
    />
  )
}
