import { FC, memo } from 'react'

import { UsPhoneLabel } from 'ui/components'
import { DateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { LicenseDetailsProps } from './LicenseDetailsProps'

export const LicenseDetails: FC<LicenseDetailsProps> = memo(({ license }) => {
  const isLicenseMETRCApiKeyEnabled = 'licenseMETRCApiKey' in license

  return (
    <InfoSet direction={InfoSetDirection.ROW} legend="License Details">
      <Row>
        <Col>
          <Info label="License Name">{license.name}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="License Type">{license.type}</Info>
        </Col>
        <Col>
          <Info label="License Subtype">{license.subtype}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="License Number">{license.number}</Info>
        </Col>
        <Col>
          <Info label="POS Type">{license.posType}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Phone">
            <UsPhoneLabel value={license.licenseePhone} />
          </Info>
        </Col>
        <Col>
          <Info label="POS Type Other">{license.posTypeOther}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="License Issuance Date">
            <DateTime date={license.issuanceDate} />
          </Info>
        </Col>
        <Col>
          <Info label="License Expiration Date">
            <DateTime date={license.expirationDate} />
          </Info>
        </Col>
      </Row>
      {isLicenseMETRCApiKeyEnabled && (
        <Row>
          <Col>
            <Info label="METRC API key">{license.licenseMETRCApiKey}</Info>
          </Col>
        </Row>
      )}
    </InfoSet>
  )
})
