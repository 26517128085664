import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const PriorReportBSAId = () => {
  const { formApi } = useSARFormContext()
  const filingType = formApi.useStore((s) => s.values.generalInfo?.filingType)

  return (
    <formApi.Field
      name="generalInfo.priorReportBsaId"
      validators={{
        onChange: ({ value, fieldApi }) => {
          const { filingType } = fieldApi.form.state.values.generalInfo
          return filingType.includes('filingTypeCorrectAmend') || filingType.includes('filingTypeContinuing')
            ? Validators.required.field(value)
            : undefined
        }
      }}
      children={(field) => (
        <FF
          required={!filingType?.includes('filingTypeInitial')}
          label="Prior report BSA Identifier"
          field={field}
          input={(p) => <TextInput {...p} maxLength={14} disabled={filingType?.includes('filingTypeInitial')} />}
        />
      )}
    />
  )
}
