import { DocumentsUploadingDoc } from 'commons/types/DTO/commons/webpagesOnboardinForm'
import { DocumentPeriodStatusChip } from 'ui/components/Labels'
import { Tooltip } from 'ui/components/Tooltip'

import { DocumentFormFiles } from '../DocumentFormFiles'
import { UploadButton } from '../UploadButton'

import * as SC from './styles'

export const columns = (
  readonly: boolean,
  companyName: string,
  onAddFiles: (files: File[], periodId: number) => void,
  onRemoveFiles: (fileId: number) => void,
  isLoading: boolean,
  isDocumentDeleting: boolean
) =>
  [
    {
      title: 'Document Name',
      align: 'left',
      width: '350px',
      render: (item: DocumentsUploadingDoc) => (
        <>
          {item.name} {!item.optional && <b>&nbsp;*</b>}
        </>
      )
    },
    readonly
      ? null
      : {
          title: 'Status',
          align: 'center',
          width: '165px',
          render: (item: DocumentsUploadingDoc) => <DocumentPeriodStatusChip name={item.documentPeriod.status} />
        },
    {
      title: 'Notes',
      align: 'left',
      render: (item: DocumentsUploadingDoc) => {
        if (item.documentPeriod.notes) {
          return (
            <Tooltip
              id={`${item.id}`}
              type="dark"
              event="hover"
              content={<SC.NotesTooltip>{item.documentPeriod.notes.trim()}</SC.NotesTooltip>}
            >
              <SC.Notes>{item.documentPeriod.notes.trim()}</SC.Notes>
            </Tooltip>
          )
        }
        return '---'
      }
    },
    {
      title: 'Uploaded files',
      align: 'right',
      render: (item: DocumentsUploadingDoc) => {
        if (item.documentPeriod.files.length) {
          return (
            <DocumentFormFiles
              readonly={readonly}
              files={item.documentPeriod.files}
              companyName={companyName}
              documentName={item.name}
              periodId={item.documentPeriod.id}
              onFilesAdd={onAddFiles}
              isLoading={isLoading}
              isDeleting={isDocumentDeleting}
              onDelete={onRemoveFiles}
            />
          )
        }
        return <SC.ItemFilesEmptyMessage>No uploaded files yet</SC.ItemFilesEmptyMessage>
      }
    },
    readonly
      ? null
      : {
          title: 'Action',
          align: 'center',
          render: (item: DocumentsUploadingDoc) => (
            <UploadButton periodId={item.documentPeriod.id} onFilesAdd={onAddFiles} />
          )
        }
  ].filter(Boolean)
