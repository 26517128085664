import { BankName } from 'commons/types/enums'
import { ChoiceOption } from 'ui/types'

import { OnboardingApplicationStatus } from '../../Labels/OnboardingCustomerStatusLabel'
import { SelectListName } from './selectListName'

const regions = [
  'AK',
  'AL',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  'DC'
]

export const getOptionsByListName = (name: SelectListName, opts?: { cbt: boolean }): ChoiceOption[] => {
  if (
    name === SelectListName.WHOLESALE_REPORT_QUEUE_STATUS ||
    name === SelectListName.RETAIL_REPORT_QUEUE_STATUS ||
    name === SelectListName.TAX_RECONCILIATION_REPORT_QUEUE_STATUS
  ) {
    return [
      { label: 'Incomplete', value: 'incomplete' },
      { label: 'Pending Review', value: 'pending_review' }
    ]
  }

  if (name === SelectListName.REPEAT_STATUS) {
    return [
      { label: 'Never', value: 'never' },
      { label: 'Daily', value: 'daily' },
      { label: 'Weekly', value: 'weekly' },
      { label: 'Monthly', value: 'monthly' },
      { label: 'Yearly', value: 'yearly' },
      { label: 'Custom', value: 'custom' }
    ]
  }

  if (name === SelectListName.REMIND_STATUS) {
    return [
      { label: 'Never', value: 'never' },
      { label: '5 minutes', value: '5min' },
      { label: '15 minutes', value: '15min' },
      { label: '30 minutes', value: '30min' },
      { label: '1 hour', value: '1hour' },
      { label: '2 hour', value: '2hour' },
      { label: '12 hour', value: '12hour' },
      { label: 'Day', value: '1day' },
      { label: 'Week', value: '1week' }
    ]
  }

  if (name === SelectListName.INTERNAL_TRANSFER_STATUS) {
    return [
      { label: 'Pending Approval', value: 'pending_approval' },
      { label: 'Approved', value: 'approved' },
      { label: 'Exported', value: 'exported' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'Processed', value: 'processed' }
    ]
  }

  if (name === SelectListName.BOOLEAN_FILTER) {
    return [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }

  if (name === SelectListName.FREQUENCY) {
    return [
      { label: 'Every 5 years', value: 'every_5_years' },
      { label: 'Every 2 years', value: 'every_2_years' },
      { label: 'Every 18 Months', value: 'every_18_months' },
      { label: 'Annual', value: 'annual' },
      { label: 'Semi-Annual', value: 'semi-annual' },
      { label: 'Quarterly', value: 'quarterly' },
      { label: 'Monthly', value: 'monthly' },
      { label: 'One-Time', value: 'one-time' },
      { label: 'Manual Period', value: '' }
    ]
  }

  if (name === SelectListName.INTERNAL_TRANSFER_EXPORT_STATUS) {
    return [
      { label: 'New', value: 'new' },
      { label: 'Processed', value: 'processed' }
    ]
  }

  if (name === SelectListName.CUSTOMER_STATUS) {
    return [
      { label: 'Qualified Lead', value: 'qualified_lead' },
      { label: 'Applicant', value: 'applicant' },
      { label: 'Customer', value: 'customer' },
      { label: 'Denied Customer', value: 'denied_customer' },
      { label: 'Former Customer', value: 'former_customer' },
      { label: 'Pending Customer', value: 'pending_customer' }
    ]
  }

  if (name === SelectListName.CUSTOMER_STATUS_VALLIANCE) {
    return [
      { label: 'Qualified Lead', value: 'qualified_lead' },
      { label: 'Applicant', value: 'applicant' },
      { label: 'Customer', value: 'customer' },
      { label: 'Denied Customer', value: 'denied_customer' },
      { label: 'Former Customer', value: 'former_customer' },
      { label: 'Pending Customer', value: 'pending_customer' },
      { label: 'Watch List', value: 'watch_list' }
    ]
  }

  if (name === SelectListName.ENTITY_TYPE) {
    return [
      { label: 'Corporation', value: 'corporation' },
      { label: 'LLC', value: 'llc' },
      { label: 'Partnership', value: 'partnership' },
      { label: 'Sole Proprietor', value: 'sole_proprietor' }
    ]
  }

  if (name === SelectListName.ENTITY_TYPE_BISON) {
    return [
      { label: 'Corporation', value: 'corporation' },
      { label: 'LLC', value: 'llc' },
      { label: 'Partnership', value: 'partnership' },
      { label: 'Sole Proprietor', value: 'sole_proprietor' },
      { label: 'Nonprofit', value: 'nonprofit' }
    ]
  }

  if (name === SelectListName.ENTITY_TYPE_SHORE) {
    return [
      { label: 'Corporation', value: 'corporation' },
      { label: 'LLC', value: 'llc' },
      { label: 'Partnership', value: 'partnership' },
      { label: 'Sole Proprietor', value: 'sole_proprietor' },
      { label: 'Trust', value: 'trust' }
    ]
  }

  if (name === SelectListName.BUSINESS_TYPE) {
    return [
      { label: 'MRB', value: 'mrb' },
      { label: 'MRB Related', value: 'mrb_related' },
      { label: 'Ancillary', value: 'ancillary' },
      opts?.cbt ? { label: 'Consumer Account', value: 'consumer_account' } : null,
      { label: 'Hemp', value: 'hemp' },
      { label: 'Investment', value: 'investment' },
      { label: 'Tribal MRB', value: 'tribal_mrb' },
      { label: 'Pre License MRB', value: 'pre_license_mrb' },
      { label: 'Management', value: 'management' }
    ].filter(Boolean) as any
  }

  if (name === SelectListName.START_DATE_TYPE) {
    return [
      { label: 'Account Opening', value: 'account_opening' },
      { label: 'Calendar', value: 'calendar' },
      { label: 'License Issue', value: 'license_issue' },
      { label: 'Last Delivery', value: 'last_delivery' },
      { label: 'Manual Period', value: '' }
    ]
  }

  if (name === SelectListName.DOCUMENT_PERIOD_STATUS) {
    return [
      { label: 'Pending Approval', value: 'new' },
      { label: 'Approved', value: 'approved' }
    ]
  }

  if (name === SelectListName.ANNUAL_REVIEWS_STATUS) {
    return [
      { label: 'New', value: 'new' },
      { label: 'Completed', value: 'completed' },
      { label: 'Approved', value: 'approved' }
    ]
  }

  if (name === SelectListName.STATE_OF_INCORPORATION) {
    return regions.map((state) => ({ label: state, value: state }))
  }

  if (name === SelectListName.ONBOARDING_APPLICATION_STATUS) {
    return [
      { label: 'All', value: '' },
      {
        label: 'All Active',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_ALL_ACTIVE
      },
      {
        label: 'All Inactive',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_ALL_IN_ACTIVE
      },
      {
        label: 'RFI Pending',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_RFI_PENDING
      },
      {
        label: 'RFI Review',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_RFI_REVIEW
      },
      {
        label: 'Document Request Pending',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_PENDING
      },
      {
        label: 'Document Request Submitted',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_SUBMITTED
      },
      {
        label: 'Document Request Review',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEW
      },
      {
        label: 'Document Request Reviewed',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEWED
      },
      {
        label: 'Pending Customer Review',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_PENDING_CUSTOMER_REVIEW
      },
      {
        label: 'Customer',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_CUSTOMER
      },
      {
        label: 'Denied Customer',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_DENIED_CUSTOMER
      }
    ]
  }

  if (name === SelectListName.ONBOARDING_APPLICATION_STATUS_FOR_MARTIN_HOOD_AND_CREDIT) {
    return [
      { label: 'All', value: '' },
      {
        label: 'Document Request Review',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEW
      },
      {
        label: 'Document Request Reviewed',
        value: OnboardingApplicationStatus.APPLICATION_STATUS_DOCUMENT_REQUEST_REVIEWED
      }
    ]
  }

  if (name === SelectListName.DUE_START_DATE) {
    return [
      { label: 'Period Start', value: true },
      { label: 'Period End', value: false }
    ]
  }

  if (name === SelectListName.ORGANISATIONS) {
    return [
      { label: 'Development Bank', value: BankName.DEV },
      { label: 'Shore United Bank', value: BankName.SHORE },
      { label: 'CB&T', value: BankName.CBT },
      { label: 'Illinois National Bank', value: BankName.INB },
      { label: 'Metro Phoenix Bank', value: BankName.MPB },
      { label: 'Herring Bank', value: BankName.HERRING },
      { label: 'Transact Bank', value: BankName.TRANSACT },
      { label: 'Champion Bank', value: BankName.CHAMPION },
      { label: 'Demo Bank', value: BankName.DEMO },
      { label: 'MedMen', value: BankName.MED_MEN },
      { label: 'Flagler Bank', value: BankName.FLAGLER },
      { label: 'Valliance Bank', value: BankName.VALLIANCE },
      { label: 'Bison State Bank', value: BankName.BISON }
    ].sort((a, b) => a.label.localeCompare(b.label))
  }

  if (name === SelectListName.SUBJECT) {
    return [
      { label: 'Relationship', value: 'company' },
      { label: 'License', value: 'license' },
      { label: 'Bank Account', value: 'bank_account' },
      { label: 'Contact', value: 'contact' }
    ]
  }

  return []
}
