import { FC, memo } from 'react'

import { Info, InfoSet } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { LicenseAddressInformationFormValue } from '../../types/forms'

export type LicenseAddressDetailsProps = {
  value: LicenseAddressInformationFormValue
}

export const LicenseAddressDetails: FC<LicenseAddressDetailsProps> = memo(({ value }) => (
  <InfoSet legend="Address Information">
    <Row>
      <Col>
        <Info label="Street Address">{value.street}</Info>
      </Col>
      <Col>
        <Info label="City">{value.city}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="Zip/Postal Code">{value.postalCode}</Info>
      </Col>
      <Col>
        <Info label="State/Province">{value.state}</Info>
      </Col>
    </Row>
  </InfoSet>
))
