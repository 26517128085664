import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { useRouter } from '@tanstack/react-router'
import { FF, Toast, Validators, handleFormSubmissionError } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { TextareaInput } from 'ui/components/InputV2'
import { FormRow } from 'ui/themes/globalStyles'
import { useSetDocumentPeriodStatus } from '~bank-documents/service/documentPeriodStatus'

import { ChangeStatusDialogProps } from './ChangeStatusDialog.types'
import { getDialogTitle } from './getDialogTitle'

import * as SC from './styles'

export const ChangeStatusDialog: FC<ChangeStatusDialogProps> = memo((props) => {
  const router = useRouter()

  const { notes, onClose, status, id } = props

  const form = useForm<{ notes: string }>({
    defaultValues: { notes },
    onSubmit: async ({ value }) => (status ? routine({ periodId: Number(id), notes: value.notes, status }) : null)
  })

  const { routine } = useSetDocumentPeriodStatus({
    onSuccess: async () => {
      Toast.successSmall('Document successfully updated')
      onClose()
      router.invalidate()
    },
    onError: (error) => handleFormSubmissionError(error, form)
  })

  if (!status) {
    return null
  }

  return (
    <Dialog face={DialogFace.PRIMARY} id="period-status" title={getDialogTitle(status)} onClose={onClose}>
      <SC.Box>
        <FormRow>
          <form.Field
            name="notes"
            validators={{
              onSubmit: (v) => Validators.required.field(v),
              onChange: (v) => Validators.required.field(v)
            }}
            children={(notesField) => (
              <FF
                label="Notes (visible for client)"
                field={notesField}
                input={(props) => <TextareaInput data-qa="document-notes" {...props} />}
              />
            )}
          />
        </FormRow>
        <SC.Footer>
          <ButtonGroup margin="small">
            <form.Subscribe
              children={({ canSubmit }) => (
                <Button face="positive" data-qa="Save" disabled={!canSubmit} onClick={form.handleSubmit}>
                  Save
                </Button>
              )}
            />
            <Button face="neutral" data-qa="Cancel" onClick={onClose}>
              Cancel
            </Button>
          </ButtonGroup>
        </SC.Footer>
      </SC.Box>
    </Dialog>
  )
})
