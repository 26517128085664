import { Link, getRouteApi, useNavigate } from '@tanstack/react-router'
import { ButtonGroup, IconName } from 'ui/components'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { useDeleteAnnualReviewConfirmation } from '~bank-annual-review/hooks/useDeleteAnnualReviewConfirmation'

const currentRouteId = AnnualReviewPaths.DETAILS

export const HeaderActions = () => {
  const navigate = useNavigate({ from: currentRouteId })
  const { companyId, id } = getRouteApi(currentRouteId).useParams()

  const { openDeleteAnnualReviewConfirmation, DeleteConfirmation } = useDeleteAnnualReviewConfirmation({
    onSuccess: () =>
      navigate({
        to: '/relationships/$companyId',
        params: { companyId }
      })
  })

  return (
    <>
      <DeleteConfirmation />
      <ButtonGroup margin="big">
        <Link to="/annual-review/detail/$companyId/$id/print" params={{ companyId, id }} target="_blank">
          <HeaderButton size="middle" overColoredPad={true}>
            Print PDF
          </HeaderButton>
        </Link>
        <IconButton
          face="neutral"
          icon={IconName.DELETE}
          onClick={() => openDeleteAnnualReviewConfirmation(Number(id))}
        >
          Delete Review
        </IconButton>
      </ButtonGroup>
    </>
  )
}
