import { WriteHook, useMutationManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'

type DeleteFileProps = { invoiceSubmittalId: number; fileKeyIndex: number }

const deleteInvoiceSubmittalFile = (opts: DeleteFileProps) =>
  Request.delete(Request.urls.bank.invoiceSubmittalFileDelete, opts)

export const useDeleteInvoiceSubmittalFile: WriteHook<DeleteFileProps, void> = (opts) =>
  useMutationManager(deleteInvoiceSubmittalFile, opts)
