import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const Time = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const type = formApi.useStore((s) => s.values.suspiciousActivityInfo.cyberEventIndicators[index].type)

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `suspiciousActivityInfo.cyberEventIndicators[${index}].time` as 'suspiciousActivityInfo.cyberEventIndicators[0].time'
      }
      children={(field) => (
        <FF
          label="Timestamp (UTC)"
          field={field}
          input={(p) => (
            <TextInput
              {...p}
              disabled={!(type && ['Command and control IP address', 'Suspicious IP address'].includes(type))}
            />
          )}
        />
      )}
    />
  )
}
