import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../context'
import { PhoneExt } from './PhoneExt'
import { PhoneNumber } from './PhoneNumber'
import { PhoneType } from './PhoneType'

type PhoneNumberProps = {
  index: number
}

export const Phone = ({ index }: PhoneNumberProps) => {
  const { formApi } = useSARFormContext()

  return (
    <>
      <FormRow>
        <Col>
          <h5>Phone (s)</h5>
        </Col>
      </FormRow>
      <formApi.Field
        mode="array"
        name={`subjectsInfo[${index}].phoneNumbers` as 'subjectsInfo[0].phoneNumbers'}
        children={(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {(field.state.value || []).map((_, index2) => (
                <Phones index1={index} index2={index2} parentField={field} key={`${index}-${index2}`} />
              ))}
              <IconButton
                icon={IconName.ADD_GREEN}
                onClick={() => field.pushValue({ phoneNumber: '', phoneExt: '', phoneType: '' })}
                style={{ marginBottom: '15px' }}
              />
            </>
          )
        }}
      />
    </>
  )
}

const Phones = ({
  index1,
  index2,
  parentField
}: {
  index1: number
  index2: number
  parentField: FieldApi<SarEdit, 'subjectsInfo[0].phoneNumbers'>
}) => {
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index2),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <div key={index1} style={{ marginBottom: '15px' }}>
      <Confirmation />
      <FormRow>
        <Col>
          <PhoneNumber index1={index1} index2={index2} />
        </Col>
        <Col>
          <PhoneExt index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <PhoneType index1={index1} index2={index2} />
        </Col>
        {parentField.state.value.length > 1 && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
        )}
      </FormRow>
    </div>
  )
}
