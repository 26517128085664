import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

export const IdFormOther = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const idForm = formApi.useStore((s) => s.values.subjectsInfo[index1].idsInfo[index2].idForm)

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idFormOther`}
      validators={{
        onChange: ({ value }) =>
          !!value || idForm !== 'Other' || isAllInfoUnavailable ? undefined : Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required={idForm === 'Other' && !isAllInfoUnavailable}
          field={field}
          label="Other Type"
          input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable || idForm !== 'Other'} />}
        />
      )}
    />
  )
}
