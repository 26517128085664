import { bankAccountNumberClear } from 'commons/utils'

const notEmptyAndValid = (v?: string | null) => {
  if (!v) {
    return 'Field is required'
  }
  return isValid(v)
}

const isValid = (v: string) => {
  if (!v) {
    return undefined
  }
  const cleared = bankAccountNumberClear(v)
  return !cleared || cleared.length < 5 || cleared.length > 25
    ? 'Bank account number must contain between 5 and 25 digits'
    : undefined
}

export const bankAccountNumber = {
  valid: (v: string) => isValid(v),
  notEmptyAndValid: (v?: string | null) => notEmptyAndValid(v)
}
