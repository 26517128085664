import { UseNavigateResult } from '@tanstack/react-router'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { DocumentPeriodStatusChip } from '~bank-documents/components/DocumentPeriodStatusChip'
import { DocumentsPaths } from '~bank-documents/constants'
import { DocumentDetailsPeriodItem } from '~bank-documents/types/documentDetails'

import { AlertsDueTooltip } from './components/AlertsDueTooltip'

export const columns = ({
  documentId,
  onDeletePeriod,
  navigate
}: {
  documentId: number

  onDeletePeriod: (periodId) => void

  navigate: UseNavigateResult<string>
}): TableColumn<DocumentDetailsPeriodItem>[] => [
  {
    title: 'Period',
    key: 'period',
    render: ({ period, id }) => (
      <Link to={DocumentsPaths.PERIOD_DETAILS} params={{ documentId, periodId: id }} data-qa="document period label">
        {period}
      </Link>
    )
  },
  {
    title: 'Expiration Date',
    key: 'expirationDate',
    render: ({ expirationDate }) => expirationDate
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) => <DocumentPeriodStatusChip withoutMargins={true} name={status} />
  },
  {
    align: 'center',
    title: 'Alerts',
    key: 'alerts',
    render: ({ id, alerts }) => <AlertsDueTooltip id={id} label={alerts} />
  },
  {
    title: 'Actions',
    key: 'id',
    width: '50px',
    render: (period) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            behavior: MenuItemBehavior.BUTTON,
            onClick: () =>
              navigate({
                to: DocumentsPaths.DOCUMENT_DETAILS_EDIT_PERIOD,
                params: { documentId, periodId: period.id },
                resetScroll: false
              })
          },
          {
            key: 2,
            title: 'Delete',
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            behavior: MenuItemBehavior.BUTTON,
            onClick: () => onDeletePeriod(period.id)
          }
        ]}
      />
    )
  }
]
