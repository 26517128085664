import { CSSProperties, memo } from 'react'

import { FormInputProps } from 'brief-form'
import { ChoiceOption, ChoiceValue } from 'ui/types'

import { MultiSelect } from './MultiSelect'

interface Props {
  searchable?: boolean
  className?: string
  filters?: { field: string; type: string; value: ChoiceValue }[]
  emptyOption?: ChoiceOption
  disabled?: boolean
  containerStyle?: CSSProperties
  showId?: boolean
  'data-qa'?: string
}

export interface LicenseMultiSelectProps extends FormInputProps<ChoiceValue[], Props> {}

const defaultProps: Partial<LicenseMultiSelectProps> = {
  searchable: true
}

export const LicenseMultiSelect = memo((props: LicenseMultiSelectProps) => {
  const {
    value,
    onChange,
    searchable,
    className,
    filters,
    emptyOption,
    disabled,
    showId = false,
    ...rest
  } = {
    ...defaultProps,
    ...props
  }

  return (
    <MultiSelect
      {...rest}
      value={value}
      onChange={onChange}
      placeholder="Start entering the license name"
      searchable={searchable}
      // data={[]}
      serverOptions={{
        filters,
        url: '/bank/v1/license-list',
        limit: 500,
        mapper: (item) => ({ label: showId ? `${item.name} (${item?.license_number})` : item.name, value: item.id }),
        searchFields: ['name']
      }}
    />
  )
})
