import styled from 'styled-components'
import { CSS } from 'ui/helpers'

import { CheckboxItemContainerP } from './components/CheckboxItemContainerP'

export const CheckboxItemContainer = styled(CheckboxItemContainerP)<{
  $error?: string
  $readOnly: boolean
}>`
  display: flex;
  align-items: center;
  width: max-content;
  min-width: 16px;
  min-height: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(p) =>
    p.$readOnly
      ? p.theme.uiKit.colors.global.general.secondary
      : p.disabled
      ? p.theme.uiKit.colors.global.grey.grey700
      : p.theme.uiKit.colors.global.general.secondary};

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)}
  user-select: none;
  position: relative;
  padding-left: 26px;
  margin-right: 16px;

  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;

  & input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    cursor: pointer;
  }

  & input:enabled ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};
    border-color: ${(p) =>
      p.$error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.grey.grey500};
  }

  &:hover input:enabled ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
    border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
    border-color: ${(p) =>
      p.$error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) =>
      p.$error
        ? CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)
        : CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};
  }

  &:focus input:enabled ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
    border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
    border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};
  }

  & input:disabled ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};
  }

  & input:checked ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};
    border-color: ${(p) =>
      p.$error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.theme.brand.lighter};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};

    &:after {
      border-color: ${(p) =>
        p.$error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.main};
    }
  }

  &:hover input:checked ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};
    border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
    border-color: ${(p) =>
      p.$error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.theme.brand.primary};
    box-shadow: ${(p) =>
      p.$error
        ? CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)
        : CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};

    &:after {
      border-color: ${(p) =>
        p.$error ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.main};
    }
  }

  &:focus input:checked ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};
    border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
    border-color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};

    &:after {
      border-color: ${(p) => p.theme.uiKit.colors.global.general.main};
    }
  }

  & input:disabled ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};
    border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  }

  & input:disabled:checked ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};
    border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};

    &:after {
      border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
    }
  }

  &:hover input:disabled:checked ~ .checkmark {
    background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
    box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.none.shadowNone)};
  }

  &:focus-within {
    input:not(:checked) ~ .checkmark {
      background-color: ${(p) => p.theme.uiKit.colors.global.general.main};
      box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};
    }

    input:checked ~ .checkmark {
      background-color: ${(p) => p.theme.uiKit.colors.theme.brand.lighter};
      box-shadow: ${(p) => CSS.populateBoxShadow(p.theme.uiKit.boxShadows.offset.offset80Y6)};
    }
  }

  + .formfield_error {
    padding-left: 26px;
  }
`

export const Checkmark = styled.span<{ $error?: string; $hint: string | React.ReactNode }>`
  position: absolute;
  left: 0;
  height: 16px;
  width: 16px;
  flex-shrink: 0;

  ${(p) =>
    p.$hint
      ? `
      top: 3px;
    `
      : `
      top: 50%;
      transform: translateY(-50%);
    `}

  border: ${(p) => p.theme.uiKit.borderWidth.strokeS} solid;
  border-radius: ${(p) => p.theme.uiKit.borderRadius.default};
  border-color: ${(p) => p.theme.uiKit.colors.global.grey.grey300};

  background-color: ${(p) =>
    p.$error
      ? `${p.theme.uiKit.colors.theme.negative.transparent.opacity12} !important`
      : p.theme.uiKit.colors.global.grey.grey300};

  ${(p) =>
    p.$error &&
    `
    border: ${p.theme.uiKit.borderWidth.strokeS} solid ${p.theme.uiKit.colors.theme.negative.main};
  `}

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 9px;
    height: 5px;
    box-sizing: border-box;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: transparent;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

export const LabelsContainer = styled.div``

export const Label = styled.div<{ $error?: string }>`
  ${(p) =>
    p.$error &&
    `
    color: ${p.theme.uiKit.colors.theme.negative.main};
  `}
`

export const Hint = styled.div<{ $error?: string; disabled: boolean }>`
  pointer-events: none;

  > span {
    color: ${(p) => (p.disabled ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.grey.grey900)};
    ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.regular)};
  }
`
