import { WriteHook, useMutationManager, useStateManager } from 'react-query-state-manager'

import { SarEdit } from '~bank-bsa-reporting/types/sar'

import * as Service from '../../service'

export const useSaveSAR: WriteHook<SarEdit, SarEdit> = (opts) => {
  const sm = useStateManager()

  return useMutationManager<SarEdit, SarEdit>((sar) => Service.SAR.save(sar), {
    ...opts,
    onSuccess: async (payload, response) => {
      if (opts?.onSuccess) await opts?.onSuccess(payload, response)
      await sm.invalidate(['sar-edit', { id: payload.id }])
      await sm.invalidate(['tables', 'SARs List'])
      await sm.invalidate(['sars', payload.id])
      await sm.invalidate(['sar-details', { historyPage: 1, historySize: 10, id: payload.id }])
    }
  })
}
