import { filingInstitutionIDTypes } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

export const IDType = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.idType"
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="81. Filing institution ID Type"
          field={field}
          input={(p) => (
            <Select
              {...p}
              data={filingInstitutionIDTypes}
              emptyOption={EMPTY_OPTION}
              data-qa="81. Filing institution ID Type"
              options-data-qa="81. Filing institution ID Type option"
            />
          )}
        />
      )}
    />
  )
}
