import { ReviewFormUploadWrap } from '../ReviewFormUploadWrap'
import { CreditReviewFormUploadProps } from './CreditReviewFormUploadProps'

export const CreditReviewFormUpload = ({ application }: CreditReviewFormUploadProps) => (
  <ReviewFormUploadWrap
    application={application}
    title="Add the Credit Review"
    type="Credit Review"
    onlyNotes={false}
    requiredNotes={true}
    notes={application.reviews?.credit?.Notes || ''}
    buttonName="Add Review"
    fieldName="internalReviewCredit"
  />
)
