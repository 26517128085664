import { useMemo } from 'react'

import { Connection } from 'commons/types/DTO/bank/connections/connection'
import { Spinner, SpinnerSize } from 'ui/components'
import { Layer } from 'ui/components/Layer'

import { ConnectionsListTableProps } from './ConnectionsListTableProps'
import { ConnectionKeyItem } from './components/ConnectionKeyItem'

import * as SC from './styles'

export const ConnectionsListTable = ({ connections, isFetching }: ConnectionsListTableProps) => {
  const mappedConnectionsItems = useMemo(
    () =>
      connections.map((el: Connection, index) => (
        <Layer rounded shadowed stretch key={index}>
          <ConnectionKeyItem connection={el} />
        </Layer>
      )),
    [connections]
  )

  if (isFetching) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  const showConnectionsItems = mappedConnectionsItems && mappedConnectionsItems.length > 0

  return (
    <SC.Wrapper>
      {showConnectionsItems && mappedConnectionsItems}
      {!showConnectionsItems && <SC.EmptyTitle>No Items</SC.EmptyTitle>}
    </SC.Wrapper>
  )
}
