import { FC, memo } from 'react'

import { UsPhoneLabel } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import { ContactDetailsProps } from './ContactDetailsProps'

export const ContactDetails: FC<ContactDetailsProps> = memo(
  ({ value, state: { isMobileEnable, isHomePhoneEnable, isOtherMjRelatedBusinessEnable, isBioEnable } }) => (
    <InfoSet legend="Contact Details">
      <Row>
        <Col>
          <Info label="First Name">{value.firstName}</Info>
        </Col>
        <Col>
          <Info label="Last Name or Entity Name">{value.lastName}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Email">{value.email}</Info>
        </Col>
        <Col>
          <Info label="Phone">
            <UsPhoneLabel value={value.phone} />
          </Info>
        </Col>
      </Row>
      {(isMobileEnable || isHomePhoneEnable) && (
        <Row>
          {isMobileEnable && (
            <Col>
              <Info label="Cell Phone">
                <UsPhoneLabel value={value.mobile} />
              </Info>
            </Col>
          )}
          {isHomePhoneEnable && (
            <Col>
              <Info label="Home Phone">
                <UsPhoneLabel value={value.homePhone} />
              </Info>
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col2x>
          <Info label="Role/Position">{value.title}</Info>
        </Col2x>
      </Row>
      {isOtherMjRelatedBusinessEnable && (
        <Row>
          <Col2x>
            <Info label="Disclosure of other Marijuana Related Business ownership interests">
              {value.otherMJRelatedBusiness}
            </Info>
          </Col2x>
        </Row>
      )}
      {isBioEnable && (
        <Row>
          <Col>
            <Info label="Bio">{value.bio}</Info>
          </Col>
        </Row>
      )}
    </InfoSet>
  )
)
