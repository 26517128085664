import { Form } from 'brief-form'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { QuestionnaireFormProps } from './QuestionnaireFormProps'
import {
  ContinuedReview,
  CurrentSARFilingStatus,
  FollowUpItems,
  PreviousSARFilingStatus,
  RapidMovementOfFunds,
  ReviewAllCheckDebitsForPaymentsWasThere,
  ReviewComments,
  ReviewCreditCardActivityForInternationalWasThere,
  ReviewDebitCardActivityForInternationalWasThere,
  ReviewDepositLog,
  ReviewTheApplicableStateCannabis,
  ReviewTheCashActivity,
  ReviewTheDepositActivityOutState,
  ReviewTheInternationalACHReportWasThere,
  ReviewTheTransactionActivity,
  ReviewWireActivityForTransactionsWireActivity,
  SeeCustomerCommunicationOrAnnualQuestions,
  SignedCompletion
} from './components'
import { ComparePriorAndCurrentPercentage } from './components/ComparePriorAndCurrentPercentage'
import * as SC from './style'
import { useQuestionnaireForm } from './useQuestionnaireForm'

export const QuestionnaireForm = ({ setQuestionnaire, questionnaire }: QuestionnaireFormProps) => {
  const { config, Field } = useQuestionnaireForm(questionnaire, setQuestionnaire)

  return (
    <PageSection face={PageSectionFace.THIRD} title="Red Flags to Distinguish Priority SARs" anchor="red-flags">
      <Form config={config}>
        <SC.Inner>
          <ComparePriorAndCurrentPercentage formValue={questionnaire} Field={Field} />
          <ReviewTheCashActivity formValue={questionnaire} Field={Field} />
          <RapidMovementOfFunds formValue={questionnaire} Field={Field} />
          <ReviewDepositLog formValue={questionnaire} Field={Field} />
          <ReviewTheTransactionActivity formValue={questionnaire} Field={Field} />
          <SeeCustomerCommunicationOrAnnualQuestions Field={Field} />
          <ReviewTheDepositActivityOutState formValue={questionnaire} Field={Field} />
          <ReviewWireActivityForTransactionsWireActivity formValue={questionnaire} Field={Field} />
          <ReviewTheInternationalACHReportWasThere formValue={questionnaire} Field={Field} />
          <ReviewDebitCardActivityForInternationalWasThere formValue={questionnaire} Field={Field} />
          <ReviewCreditCardActivityForInternationalWasThere formValue={questionnaire} Field={Field} />
          <ReviewAllCheckDebitsForPaymentsWasThere formValue={questionnaire} Field={Field} />
          <ReviewTheApplicableStateCannabis formValue={questionnaire} Field={Field} />
          <ContinuedReview Field={Field} />
          <ReviewComments Field={Field} />
          <FollowUpItems Field={Field} />
          <PreviousSARFilingStatus Field={Field} />
          <CurrentSARFilingStatus Field={Field} />
          <SignedCompletion Field={Field} />
        </SC.Inner>
      </Form>
    </PageSection>
  )
}
