import { FC, memo } from 'react'

import { HistoryTable } from 'bank-entity-history'
import { BANK_ACCOUNTS, LICENSES_PATH } from 'commons/constants/routes'
import { TableDataQa } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils'
import { Link, Panel, PanelFace } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Hr } from 'ui/components/Hr'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Page, PageFace, PageSection, PageSectionFace } from 'ui/components/Page'

import { DetailsPageProps } from './DetailsPage.types'

import { Column } from './styles'

export const DetailsPage: FC<DetailsPageProps> = memo((props) => {
  const { data, onCancel, pagination } = props
  const {
    id,
    enableCancel,
    amount,
    createdAt,
    updatedAt,
    showApprovedAt,
    status,
    recipientBankAccountFormattedNumber,
    recipientBankAccountId,
    showRecipientLicenseId,
    showRecipientLicenseName,
    showSenderLicenseId,
    showSenderLicenseName,
    recipientCompanyName,
    senderBankAccountFormattedNumber,
    senderBankAccountId,
    senderCompanyName,
    notes,
    manifestNumber,
    history
  } = data

  return (
    <Page title="Internal Transfer Page" subTitle={`IT-${id}`} face={PageFace.SECONDARY}>
      <PageSection
        title="Internal Transfer Information"
        face={PageSectionFace.SECONDARY}
        actions={
          enableCancel && (
            <Button face="neutral" onClick={onCancel}>
              Cancel
            </Button>
          )
        }
      >
        <Hr />
        <InfoSet direction={InfoSetDirection.ROW} legend="Internal Transfer Details">
          <Column>
            <Info label="Internal transfer number">IT-{id}</Info>
            <Info label="Recipient">
              {showRecipientLicenseId ? (
                <Link to={`/${LICENSES_PATH}/${showRecipientLicenseId}`}>{showRecipientLicenseName}</Link>
              ) : (
                <Link to={`/${BANK_ACCOUNTS}/${recipientBankAccountId}`}>
                  {recipientCompanyName} {recipientBankAccountFormattedNumber}
                </Link>
              )}
            </Info>
            <Info label="Amount">{formatCurrencyCent(amount)}</Info>
            <Info label="Notes">{notes}</Info>
          </Column>
          <Column>
            <Info label="Status">{status}</Info>
            <Info label="Sender">
              {showSenderLicenseId ? (
                <Link to={`/${LICENSES_PATH}/${showSenderLicenseId}`}>{showSenderLicenseName}</Link>
              ) : (
                <Link to={`/${BANK_ACCOUNTS}/${senderBankAccountId}`}>
                  {senderCompanyName} {senderBankAccountFormattedNumber}
                </Link>
              )}
            </Info>
            <Info label="Manifest Number">{manifestNumber}</Info>
          </Column>
        </InfoSet>
        <Hr />
        <InfoSet direction={InfoSetDirection.ROW} legend="System Information">
          <Column>
            <Info label="Created Date">
              <DateTime date={createdAt} />
            </Info>
            <Info label="Last Modified">
              <DateTime date={updatedAt} />
            </Info>
          </Column>
          <Column>
            <Info label="Approval Date">
              <DateTime withTime date={showApprovedAt} />
            </Info>
          </Column>
        </InfoSet>
        <Hr />
      </PageSection>
      <PageSection title="Internal Transfer History" face={PageSectionFace.SECONDARY}>
        <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
          <HistoryTable dataQa={TableDataQa.BP_INTERNAL_TRANSFERS_HISTORY} items={history} pagination={pagination} />
        </Panel>
      </PageSection>
    </Page>
  )
})
