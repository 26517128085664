import { FC, memo } from 'react'

import { HistoryTable } from 'bank-entity-history'
import { TableDataQa } from 'commons/types/enums'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { HistoryProps } from './History.types'

export const History: FC<HistoryProps> = memo((props) => {
  const { data, config } = props
  const { history, historyTotalCount } = data
  const { filter, onFilterChange, isFetching } = config
  const { page, size } = filter

  return (
    <PageSection face={PageSectionFace.THIRD} title="History" anchor="history">
      <HistoryTable
        dataQa={TableDataQa.BP_BSA_REPORTING_SAR_HISTORY}
        items={history}
        pagination={{
          total: historyTotalCount,
          page,
          pageSize: size,
          onChange: onFilterChange
        }}
        loading={isFetching}
      />
    </PageSection>
  )
})
