import { gender } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

export const Gender = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const isEntity = formApi.useStore((s) => s.values.subjectsInfo[index].isEntity)

  return (
    <formApi.Field
      name={`subjectsInfo[${index as 0}].gender`}
      children={(field) => (
        <FF
          field={field}
          label="8. Gender"
          input={(p) => (
            <Select
              {...p}
              data={gender}
              emptyOption={{ label: 'Not selected', value: '' }}
              disabled={isAllInfoUnavailable || isEntity}
            />
          )}
        />
      )}
    />
  )
}
