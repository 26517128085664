import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const Gaming = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="suspiciousActivityInfo.gaming"
      children={(field) => (
        <FF
          field={field}
          label="35. Gaming activities"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'35. Gaming activities'}
              direction={'vertical'}
              data={[
                { label: 'a. Chip walking', value: 'a' },
                { label: 'b. Minimal gaming with large transactions', value: 'b' },
                { label: 'c. Suspicious use of counter checks or markers', value: 'c' },
                { label: 'd. Unknown source of chips', value: 'd' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
