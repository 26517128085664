import { useCallback, useState } from 'react'

import { useDeleteDocument } from 'bank-documents/src/service/deleteDocument'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

export const useDocuments = (onDocumentDeleted: (id: number) => void) => {
  const [tbd, setTBD] = useState<{ id: number; name: string }>({ id: 0, name: '' })

  const { routine: deleteDocument, isLoading: isDeleting } = useDeleteDocument({
    onSuccess: async (id) => {
      onDocumentDeleted(id)
    },
    onError: (error) => Toast.errorSmall(error.message)
  })

  const { Confirmation: DeleteConfirmation, open } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>{tbd.name}</i> document?
      </span>
    ),
    onConfirm: () => deleteDocument(tbd.id),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startDeletion = useCallback(
    (id: number, name: string) => {
      setTBD({ id, name })
      open()
    },
    [open]
  )

  return {
    DeleteConfirmation,
    startDeletion,
    isDeleting
  }
}
