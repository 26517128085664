import { FC, memo } from 'react'

import { PageFace } from '../../pageFace'
import { HeaderProps } from './HeaderProps'

import * as SC from './styles'

export const Header: FC<HeaderProps> = memo(({ title, subTitle, face, actions }) => (
  <SC.Box face={face}>
    <SC.Caption>
      {face === PageFace.PRIMARY ? (
        <>
          {title && <h1 data-qa={title}>{title}</h1>}
          {subTitle && <h5 data-qa={subTitle}>{subTitle}</h5>}
        </>
      ) : (
        <>
          {title && <h5 data-qa={title}>{title}</h5>}
          {subTitle && <h1 data-qa={subTitle}>{subTitle}</h1>}
        </>
      )}
    </SC.Caption>
    {!!actions && <SC.Actions>{actions}</SC.Actions>}
  </SC.Box>
))
