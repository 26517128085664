import { GetClientUserListResponseItem } from 'commons/types/contracts/api/bank/clientUser'
import { IconName, Link, MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, UsPhoneLabel } from 'ui/components'
import { DateTime } from 'ui/components/DateTime'
import { NoWrap } from 'ui/components/NoWrap'
import { TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { Permissions } from '../../../Permissions'

import * as SC from './styles'

export const columns = (
  setShowUserRelationships: (item: GetClientUserListResponseItem) => void,
  setEditClientUser: (item: GetClientUserListResponseItem) => void,
  removeClientUser: (item: GetClientUserListResponseItem) => void,
  sendInviteLink: (id: string) => void
): TableColumn<GetClientUserListResponseItem>[] => [
  {
    title: 'First Name',
    key: 'name',
    align: 'left'
  },
  {
    title: 'Email',
    align: 'left',
    render: ({ email }) => <NoWrap>{email}</NoWrap>
  },
  {
    title: 'Phone',
    align: 'left',
    render: ({ phone }) => (
      <SC.PhoneWrapper>
        <UsPhoneLabel value={phone || ''} />
      </SC.PhoneWrapper>
    )
  },
  {
    title: 'Relationships',
    align: 'left',
    render: (item) =>
      item.relationships.map((relationship, index) => (
        <span key={index} onClick={() => setShowUserRelationships(item)}>
          <Link to="">{relationship.name}</Link>
          {index < item.relationships.length - 1 && ', '}
        </span>
      ))
  },
  {
    title: 'Permissions',
    align: 'left',
    render: (user) => <Permissions id={user.email} value={user.permissions} />
  },
  {
    title: 'Created',
    render: ({ createdAt }) => <DateTime withTime date={createdAt} />
  },
  {
    title: 'Last Login',
    render: ({ lastLoginAt }) => <DateTime withTime date={lastLoginAt} />
  },
  {
    title: 'Action(s)',
    align: 'center',
    render: (item: GetClientUserListResponseItem) =>
      (item.enableResendInviteLink || item.enableEdit || item.enableRemove) && (
        <TableActions
          menuContainerId="userClientsContainer"
          items={[
            ...(item.enableResendInviteLink
              ? [
                  {
                    key: 1,
                    title: 'Resend invite link',
                    textFace: MenuItemTextFace.BLACK,
                    behavior: MenuItemBehavior.BUTTON,
                    onClick: () => sendInviteLink(item.id)
                  }
                ]
              : []),
            ...(item.enableEdit
              ? [
                  {
                    key: 2,
                    title: 'Edit',
                    behavior: MenuItemBehavior.BUTTON,
                    iconName: IconName.EDIT,
                    iconHoverFace: MenuIconHoverFace.PRIMARY,
                    textFace: MenuItemTextFace.GREY,
                    onClick: () => setEditClientUser(item)
                  }
                ]
              : []),
            ...(item.enableRemove
              ? [
                  {
                    key: 3,
                    title: 'Remove User',
                    behavior: MenuItemBehavior.BUTTON,
                    iconName: IconName.DELETE,
                    iconHoverFace: MenuIconHoverFace.DANGER,
                    textFace: MenuItemTextFace.GREY,
                    onClick: () => removeClientUser(item)
                  }
                ]
              : [])
          ]}
        />
      )
  }
]
