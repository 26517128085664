import { Spinner, SpinnerSize } from 'ui/components'

import { DetailsPage } from './components'
import { useBsaBatchDetails } from './hooks'

export default () => {
  const { details, isFetching, options, onHistoryPageChange, startDeleting, DeleteConfirmation } = useBsaBatchDetails()

  if (isFetching) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <>
      <DeleteConfirmation />
      {details && (
        <DetailsPage
          onDelete={startDeleting}
          details={details}
          page={options.historyPage}
          pageSize={options.historySize}
          onHistoryPageChange={onHistoryPageChange}
        />
      )}
    </>
  )
}
