import { useCallback, useState } from 'react'

import { useRemoveClientUser, useSendInviteLinkClientUser } from 'commons/hooks/bank/clientUser'
import { GetClientUserListResponseItem } from 'commons/types/contracts/api/bank/clientUser'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

export const useClientUserEdit = (reloadClientUserList: () => void) => {
  const [isEditClientUserDialogOpen, setIsEditClientUserDialogOpen] = useState<boolean>(false)
  const [clientUserForEditing, setClientUserForEditing] = useState<GetClientUserListResponseItem | undefined>(undefined)

  const openEditClientUserDialog = () => setIsEditClientUserDialogOpen(true)
  const closeEditClientUserDialog = () => {
    setClientUserForEditing(undefined)
    setIsEditClientUserDialogOpen(false)
  }

  const [clientUserForDeleting, setClientUserForDeleting] = useState<GetClientUserListResponseItem | undefined>(
    undefined
  )

  const { routine: removeClientUser } = useRemoveClientUser({
    onSuccess: async () => {
      Toast.successSmall('Client user was successfully removed!')
      reloadClientUserList()
    },
    onError: (error) => handlerServerError(error)
  })

  const { Confirmation: RemoveClientUserConfirmation, open } = useConfirmation({
    message: `Are you sure you want to remove? 
      User will lose access to all companies.`,
    onConfirm: () => (clientUserForDeleting ? removeClientUser(clientUserForDeleting.id) : null),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: true
  })

  const openRemoveClientUserConfirmation = useCallback(
    (item: GetClientUserListResponseItem) => {
      setClientUserForDeleting(item)
      open()
    },
    [setClientUserForDeleting]
  )

  const { routine: sendInviteLink } = useSendInviteLinkClientUser({
    onSuccess: async () => Toast.successSmall('Invite Link was successfully sent!'),
    onError: (error) => handlerServerError(error)
  })

  return {
    isEditClientUserDialogOpen,
    openEditClientUserDialog,
    closeEditClientUserDialog,
    clientUserForEditing,
    setClientUserForEditing,
    RemoveClientUserConfirmation,
    openRemoveClientUserConfirmation,
    sendInviteLink
  }
}
