import { FC } from 'react'

import { BankAccountsPath, ContactsPath, LicensesPath } from 'commons/constants/routes'
import { IconSize, Info, Link, TextMask } from 'ui/components'
import { RelatedCompany } from '~bank-documents/components/RelatedCompany'

import { InfoBlockForExistingDocumentProps } from './InfoBlockForExistingDocument.types'

export const InfoBlockForExistingDocument: FC<InfoBlockForExistingDocumentProps> = ({ appearanceInfo }) => (
  <>
    {appearanceInfo.showOverviewLicenseName !== undefined &&
      appearanceInfo.showOverviewLicenseId !== undefined &&
      appearanceInfo.overviewSubjectType === 'license' && (
        <Info label="License">
          <Link to={LicensesPath.DETAILS} params={{ licenseId: appearanceInfo.showOverviewLicenseId }}>
            {appearanceInfo.showOverviewLicenseName}
          </Link>
        </Info>
      )}

    {appearanceInfo.showOverviewCompanyId !== undefined &&
      appearanceInfo.showOverviewCompanyName !== undefined &&
      appearanceInfo.overviewSubjectType === 'company' && (
        <RelatedCompany
          asInfoBlock={true}
          relatedCompanies={
            appearanceInfo.showOverviewRelatedCompanies || [
              { id: appearanceInfo.showOverviewCompanyId, name: appearanceInfo.showOverviewCompanyName }
            ]
          }
        />
      )}

    {appearanceInfo.showOverviewBankAccountId !== undefined &&
      appearanceInfo.showOverviewBankAccountNumber !== undefined &&
      appearanceInfo.overviewSubjectType === 'bank_account' && (
        <Info label="Bank Account">
          <Link to={BankAccountsPath.DETAILS} params={{ bankAccountId: appearanceInfo.showOverviewBankAccountId }}>
            <TextMask isBankAccount text={appearanceInfo.showOverviewBankAccountNumber} iconSize={IconSize.XS} />
          </Link>
        </Info>
      )}

    {appearanceInfo.showOverviewContactId !== undefined &&
      appearanceInfo.showOverviewContactName !== undefined &&
      appearanceInfo.overviewSubjectType === 'contact' && (
        <Info label="Contact">
          <Link to={ContactsPath.DETAILS} params={{ contactId: appearanceInfo.showOverviewContactId }}>
            {appearanceInfo.showOverviewContactName}
          </Link>
        </Info>
      )}
  </>
)
