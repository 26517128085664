import { FC } from 'react'

import { PageSection } from 'ui/components/Page'
import { LicensingQuestionnaire } from '~bank-annual-review/components/LicensingQuestionnaire'

import { LicensesTable } from '../../../../../components/LicensesTable'

export const Licensing: FC = (): JSX.Element => (
  <PageSection title="Licensing">
    <LicensesTable />
    <LicensingQuestionnaire />
  </PageSection>
)
