import { memo } from 'react'

import { FormInputProps } from 'brief-form'
import IMask from 'imask'

import { BaseMaskedInput } from './BaseMaskedInput'
import { BaseInputOpts } from './props'

type UsPhoneInputProps = FormInputProps<string | null, BaseInputOpts>

export const USPhoneInput = memo((props: UsPhoneInputProps) => {
  // ignore, coz do not know now what cause type exception, all works fine in runtime
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const mask = IMask.createMask({
    mask: '(#00) 000-0000',
    definitions: {
      '#': /[1-9]/
    },
    lazy: true
  })
  return <BaseMaskedInput {...props} mask={mask} />
})
