import { FC, ReactNode, memo, useState } from 'react'

import { IconName } from 'ui/components'

import * as SC from './styles'

type AccordeonProps = {
  title: ReactNode
  action: ReactNode
  content: ReactNode
  opened: boolean
}

export const Accordeon: FC<AccordeonProps> = memo((props: AccordeonProps) => {
  const { title, action, content, opened = false } = props

  const [isOpened, setIsOpened] = useState(opened)

  return (
    <SC.Box>
      <SC.Header onClick={() => setIsOpened(!isOpened)}>
        <SC.Title>
          <SC.WrappedIcon name={isOpened ? IconName.EXPAND_LESS : IconName.EXPAND_MORE} /> {title}
        </SC.Title>
        <SC.Action>{isOpened && action}</SC.Action>
      </SC.Header>
      <SC.Content>{isOpened && content}</SC.Content>
    </SC.Box>
  )
})
