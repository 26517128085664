import { FC, memo, useCallback, useEffect } from 'react'

import { Form, useFormData } from 'brief-form'
import { CheckBox, DatePicker, Validators } from 'ui/components'
import { CurrencyInput, TextInput } from 'ui/components/InputV2'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel } from 'ui/components/Panel'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { CTRTransactionInfo } from '~bank-bsa-reporting/types'

import { AmountAndTypeOfTransactionsProps } from './AmountAndTypeOfTransactions.types'
import { ForeignInOut } from './components'

import { s } from './styles'

export const AmountAndTypeOfTransactions: FC<AmountAndTypeOfTransactionsProps> = memo((props) => {
  const { data, errors, onChange, validateFunctionRefs, markFormAsDirty } = props
  const { transactionInfo } = data
  const { isDirty, config, validate, Field } = useFormData<CTRTransactionInfo>({
    initialValue: transactionInfo,
    initialErrors: errors,
    alwaysSyncWithInitialValueAndErrors: true
  })

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty, markFormAsDirty])

  useEffect(() => {
    validateFunctionRefs.current.transactionsInfo = { validate, registeredFields: config.registeredFields.current }
  }, [config.registeredFields, validate, validateFunctionRefs])

  const originalChangeHandler = config.onChange
  config.onChange = useCallback(
    (v, e) => {
      originalChangeHandler(v, e)
      onChange(v, e)
    },
    [originalChangeHandler, onChange]
  )

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 4. Amount and Type of Transaction(s)" anchor="step4">
      <Panel rounded shadowed collapsible={false} title="Edit Amount and Type of Transaction(s)">
        <Form config={config}>
          <s.Inner>
            <FormRow>
              <Col>
                <Field
                  required
                  name="transactionDate"
                  label="23. Date of transaction"
                  input={DatePicker}
                  validator={Validators.required.field}
                  inputProps={{
                    'data-qa': '23. Date of transaction'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <s.CheckBoxWrapper withLabel>
                  <Field
                    name="sourceArmored"
                    label="24."
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Armored car (FI Contract)'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </s.CheckBoxWrapper>
                <s.CheckBoxWrapper>
                  <Field
                    name="sourceAtm"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'ATM'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </s.CheckBoxWrapper>
                <s.CheckBoxWrapper>
                  <Field
                    name="sourceMail"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Mail deposit or shipment'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </s.CheckBoxWrapper>
                <s.CheckBoxWrapper>
                  <Field
                    name="sourceNight"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Night deposit'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </s.CheckBoxWrapper>
                <s.CheckBoxWrapper>
                  <Field
                    name="sourceAggregated"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Aggregated transactions'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </s.CheckBoxWrapper>
                <s.CheckBoxWrapper>
                  <Field
                    name="sourceSharedBranching"
                    input={CheckBox}
                    inputProps={{
                      checkBoxLabel: 'Shared branching'
                    }}
                    fieldProps={{
                      errorPosition: 'right'
                    }}
                  />
                </s.CheckBoxWrapper>
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <s.ColumnTitle>25. CASH IN: (in U.S. dollar equivalent)</s.ColumnTitle>
              </Col>
              <Col>
                <s.ColumnTitle>27. CASH OUT: (in U.S. dollar equivalent)</s.ColumnTitle>
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInA"
                  label="a Deposit(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    'data-qa': 'a Deposit(s)',
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutA"
                  label="a Withdrawal(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    'data-qa': 'a Withdrawal(s)',
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInB"
                  label="b Payment(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutB"
                  label="b Advance(s) on credit (including markers)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInC"
                  label="c Currency received for funds transfer(s) out"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutC"
                  label="c Currency paid from funds transfer(s) in"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInD"
                  label="d Purchase of negotiable instrument(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutD"
                  label="d Negotiable instrument(s) cashed"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInE"
                  label="e Currency exchange(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutE"
                  label="e Currency exchange(s)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInF"
                  label="f Currency to prepaid access"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutF"
                  label="f Currency from prepaid access"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInG"
                  label="g Purchases of casinos chips, tokens and other gaming instruments"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutG"
                  label="g Redemption(s) of casino chips, tokens, TITO tickets and other gaming instruments"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInH"
                  label="h Currency wager(s) including money plays"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutH"
                  label="h Payment(s) on wager(s) (including race and OTB or sports pool)"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInI"
                  label={
                    <span>
                      i Bills inserted into gaming
                      <br />
                      devices
                    </span>
                  }
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutI"
                  label="i Travel and complimentary expenses and book gaming incentives"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <s.FieldStub />
              </Col>
              <Col>
                <Field
                  name="cashOutJ"
                  label="j Payment for tournament, contest or other promotions"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInTypeZ"
                  label="z Other (specify):"
                  required={!!config.value.cashInZ}
                  validator={() =>
                    config.value.cashInZ ? Validators.required.field(config.value.cashInTypeZ) : undefined
                  }
                  input={TextInput}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutTypeZ"
                  label="z Other (specify):"
                  required={!!config.value.cashOutZ}
                  validator={() =>
                    config.value.cashOutZ ? Validators.required.field(config.value.cashOutTypeZ) : undefined
                  }
                  input={TextInput}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name="cashInZ"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutZ"
                  input={CurrencyInput}
                  inputProps={{
                    allowDecimal: false,
                    maxLength: 15
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <s.WrappedHr thin />
            <FormRow>
              <Col>
                <Field
                  name="cashInTotal"
                  label="Total cash in"
                  input={CurrencyInput}
                  inputProps={{
                    disabled: true,
                    'data-qa': 'Total cash in'
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
              <Col>
                <Field
                  name="cashOutTotal"
                  label="Total cash out"
                  input={CurrencyInput}
                  inputProps={{
                    disabled: true,
                    'data-qa': 'Total cash out'
                  }}
                  validator={Validators.required.notEmpty}
                />
              </Col>
            </FormRow>
            <s.WrappedHr thin />
            <FormRow>
              <Field
                name="foreignIn"
                label="26. Foreign cash in, foreign country (abbreviation)"
                input={ForeignInOut}
                inputProps={{}}
              />
            </FormRow>
            <s.WrappedHr thin />
            <FormRow>
              <Field
                name="foreignOut"
                label="28. Foreign cash out, foreign country (abbreviation)"
                input={ForeignInOut}
                inputProps={{}}
              />
            </FormRow>
          </s.Inner>
        </Form>
      </Panel>
    </PageSection>
  )
})
