import { FC, memo } from 'react'

import * as SC from '../styles'
import { formatCurrency, formatCurrencyCent } from 'commons/utils'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { TextMask } from 'ui/components/TextMask'
import { Col, Row } from 'ui/themes/globalStyles'

import { TransactionsInformationProps } from './TransactionsInformation.types'
import { CashInOut } from './components'

export const TransactionsInformation: FC<TransactionsInformationProps> = memo((props) => {
  const { data } = props
  const {
    transactionsInfo,
    suspiciousActivityInfo: { currentCumulativeAmount, currentAmount },
    previousCumulativeAmount
  } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Transactions Information" anchor="transactions-info">
      {transactionsInfo.map((item, index) => {
        const {
          accountNumber,
          totalBankFees,
          cashIn,
          checkIn,
          achIn,
          transferIn,
          wiresIn,
          miscIn,
          cashOut,
          checkOut,
          achOut,
          transferOut,
          wiresOut,
          debitCardOut,
          returnedChecksOut,
          withdrawalsOut,
          miscOut,
          totalIn,
          totalOut
        } = item

        return (
          <Panel
            rounded
            shadowed
            key={index}
            collapsible={false}
            title={`Account ${index + 1} of ${transactionsInfo?.length}`}
            face={PanelFace.PRIMARY}
          >
            <SC.InnerPanel>
              <Row>
                <Col>
                  <Info label="Bank Account Number">
                    <TextMask isBankAccount text={accountNumber || '-'} iconSize={IconSize.XS} />
                  </Info>
                </Col>
                <Col>
                  <Info label="Total Bank Fees">{formatCurrencyCent(totalBankFees)}</Info>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CashInOut
                    rows={[
                      {
                        label: 'Cash In ',
                        value: formatCurrencyCent(cashIn)
                      },
                      {
                        label: 'Checks In ',
                        value: formatCurrencyCent(checkIn)
                      },
                      {
                        label: 'ACH In ',
                        value: formatCurrencyCent(achIn)
                      },
                      {
                        label: 'Transfer In ',
                        value: formatCurrencyCent(transferIn)
                      },
                      {
                        label: 'Wires In ',
                        value: formatCurrencyCent(wiresIn)
                      },
                      {
                        label: 'Misc Credits ',
                        value: formatCurrencyCent(miscIn)
                      },
                      { label: '', value: '' },
                      { label: '', value: '' },
                      { label: '', value: '' },
                      {
                        label: <b>TOTAL CREDITS</b>,
                        value: <b>{formatCurrencyCent(totalIn || 0)}</b>
                      }
                    ]}
                  />
                </Col>
                <Col>
                  <CashInOut
                    rows={[
                      {
                        label: 'Cash Out ',
                        value: formatCurrencyCent(cashOut)
                      },
                      {
                        label: 'Checks Out ',
                        value: formatCurrencyCent(checkOut)
                      },
                      {
                        label: 'ACH Out ',
                        value: formatCurrencyCent(achOut)
                      },
                      {
                        label: 'Transfer Out ',
                        value: formatCurrencyCent(transferOut)
                      },
                      {
                        label: 'Wires Out ',
                        value: formatCurrencyCent(wiresOut)
                      },
                      {
                        label: 'Debit Card Out ',
                        value: formatCurrencyCent(debitCardOut)
                      },
                      {
                        label: 'Returned Checks Out ',
                        value: formatCurrencyCent(returnedChecksOut)
                      },
                      {
                        label: 'Withdrawals ',
                        value: formatCurrencyCent(withdrawalsOut)
                      },
                      {
                        label: 'Misc Debits ',
                        value: formatCurrencyCent(miscOut)
                      },
                      {
                        label: <b>TOTAL DEBITS</b>,
                        value: <b>{formatCurrencyCent(totalOut || 0)}</b>
                      }
                    ]}
                  />
                </Col>
              </Row>
            </SC.InnerPanel>
          </Panel>
        )
      })}
      <SC.Totals>
        <Row>
          <Info label="Current SAR Amount">{formatCurrency(currentAmount, false)}</Info>
        </Row>
        <Row>
          <Info label="Current SAR Cumulative Amount">{formatCurrency(currentCumulativeAmount, false)}</Info>
        </Row>
        <Row>
          <Info label="Previous SAR Cumulative Amount">{formatCurrencyCent(previousCumulativeAmount, false)}</Info>
        </Row>
      </SC.Totals>
    </PageSection>
  )
})
