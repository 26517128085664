import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { YesNoRadioGroup } from 'commons/types/enums'
import { Hint, Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { TextInput } from 'ui/components/InputV2'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { ATMInfoFormProps } from './ATMInfoFormProps'
import { useATMInfoForm } from './useATMInfoForm'

export const ATMInfoForm: FC<ATMInfoFormProps> = memo(
  ({ value, companyIndex, state: { isAdditionalHintEnable }, onChange }) => {
    const { Field, config } = useATMInfoForm({ companyIndex, formData: value, onChange })

    return (
      <Form config={config}>
        <InfoSet legend="ATM">
          <Col2x>
            <Hint>
              As an owner of a privately operated ATM, it is important for the Bank to fully understand the operation of
              such a service for the purposed of mitigating risk to you and our institution. Please answer the following
              questions.
              {isAdditionalHintEnable && (
                <>&nbsp;Additionally, the bank will require a separate account for a self-owned/self-filled ATM</>
              )}
            </Hint>
          </Col2x>
          <FormRow>
            <Col2x>
              <Field
                name={OnboardingFormFieldNames.ATM_HAVE}
                label="Do you have any ATMs located on premise where you operate?"
                input={RadioGroup}
                inputProps={{
                  name: `${OnboardingFormFieldNames.ATM_HAVE}${companyIndex}`,
                  'data-qa': 'Do you have any ATMs located on premise where you operate?',
                  data: [
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ]
                }}
                required
                validator={Validators.required.notNull}
              />
            </Col2x>
          </FormRow>
          {config.value.ATMHave === YesNoRadioGroup.YES && (
            <>
              <FormRow>
                <Col2x>
                  <Field
                    name={OnboardingFormFieldNames.ATM_OWN}
                    label="Do you own the ATMs?"
                    input={RadioGroup}
                    inputProps={{
                      name: `${OnboardingFormFieldNames.ATM_OWN}${companyIndex}`,
                      'data-qa': 'Do you own the ATMs?',
                      data: [
                        { value: YesNoRadioGroup.YES, label: 'Yes' },
                        { value: YesNoRadioGroup.NO, label: 'No' }
                      ]
                    }}
                    required
                    validator={Validators.required.notNull}
                  />
                </Col2x>
              </FormRow>
              <FormRow>
                <Col2x>
                  <Field
                    name={OnboardingFormFieldNames.ATM_COUNT}
                    label="How many ATMs?"
                    input={TextInput}
                    inputProps={{
                      'data-qa': 'How many ATMs?'
                    }}
                  />
                </Col2x>
              </FormRow>
              <FormRow>
                <Col2x>
                  <Field
                    name={OnboardingFormFieldNames.ATM_REPLENISHING_THIRD_PARTY}
                    label="Do you have any ATMs replenished by a Third Party?"
                    input={RadioGroup}
                    inputProps={{
                      name: `${OnboardingFormFieldNames.ATM_REPLENISHING_THIRD_PARTY}${companyIndex}`,
                      'data-qa': 'Do you have any ATMs replenished by a Third Party?',
                      data: [
                        { value: YesNoRadioGroup.YES, label: 'Yes' },
                        { value: YesNoRadioGroup.NO, label: 'No' }
                      ]
                    }}
                    required
                    validator={Validators.required.notNull}
                  />
                </Col2x>
              </FormRow>
              <FormRow>
                <Col2x>
                  <Field
                    name={OnboardingFormFieldNames.ATM_REPLENISHING_COMPANY}
                    label="Do you have any ATMs replenished by Your Company?"
                    input={RadioGroup}
                    inputProps={{
                      name: `${OnboardingFormFieldNames.ATM_REPLENISHING_COMPANY}${companyIndex}`,
                      'data-qa': 'Do you have any ATMs replenished by Your Company?',
                      data: [
                        { value: YesNoRadioGroup.YES, label: 'Yes' },
                        { value: YesNoRadioGroup.NO, label: 'No' }
                      ]
                    }}
                    required
                    validator={Validators.required.notNull}
                  />
                </Col2x>
              </FormRow>
            </>
          )}
          {config.value.ATMHave === YesNoRadioGroup.YES &&
            config.value.ATMReplenishingCompany === YesNoRadioGroup.YES && (
              <Hint>Please add information for each privately owned ATM.</Hint>
            )}
        </InfoSet>
      </Form>
    )
  }
)
