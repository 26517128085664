import { FC, memo } from 'react'

import { UsPhoneLabel } from 'ui/components'
import { Info } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { DisclosureOfOtherMarijuanaProps } from './DisclosureOfOtherMarijuanaProps'

export const DisclosureOfOtherMarijuana: FC<DisclosureOfOtherMarijuanaProps> = memo(({ contact }) => {
  const { mobile, homePhone, otherMJRelatedBusiness } = contact

  return (
    <>
      <Row>
        <Col>
          <Info label="Cell Phone">
            <UsPhoneLabel value={mobile} />
          </Info>
        </Col>
        <Col>
          <Info label="Home Phone">
            <UsPhoneLabel value={homePhone} />
          </Info>
        </Col>
      </Row>
      <Row>
        <Info label="Disclosure of other Marijuana Related Business ownership interests">{otherMJRelatedBusiness}</Info>
      </Row>
    </>
  )
})
