import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const InsuranceOther = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="suspiciousActivityInfo.insuranceOther"
      children={(field) => <FF field={field} input={(p) => <TextInput {...p} />} />}
    />
  )
}
