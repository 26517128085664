import format from 'date-fns/format'
import styled from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'
import { MenuItemBehavior, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Tooltip } from 'ui/components/Tooltip'

import { MfaDevices } from './selector'

const typesDictionary: { [key: string]: string } = {
  totp: 'Application',
  sms: 'Phone',
  email: 'E-mail'
}

//region Styled

const Box = styled.div`
  display: flex;
  justify-content: center;

  label {
    margin: 0;
  }
`

//endregion

export const columns =
  (onDelete: (item: { id: number; name: string }) => void, onSetDefault: (id: number) => any) =>
  (): TableColumn<MfaDevices>[] =>
    [
      {
        title: 'Name',
        align: 'center',
        key: 'name',
        sortable: false
      },
      {
        title: 'Created Date',
        align: 'center',
        sortable: false,
        render: ({ createdAt }) => format(new Date(createdAt), 'MM/dd/yyyy HH:mm')
      },
      {
        title: 'Last Used Date',
        align: 'center',
        sortable: false,
        render: ({ lastUsedAt }) => format(new Date(lastUsedAt), 'MM/dd/yyyy HH:mm')
      },
      {
        title: 'Type',
        align: 'center',
        sortable: false,
        render: ({ type }) => typesDictionary[type]
      },
      {
        title: 'Use by Default',
        align: 'center',
        sortable: false,
        render: ({ default: isDefault, id }) => (
          <Box>
            <Tooltip
              id="checkbox"
              type="dark"
              event="hover"
              content={
                isDefault ? 'If you want to set another device as default, you must add another device first' : ''
              }
            >
              <CheckBox checkBoxLabel="" value={isDefault} onChange={() => onSetDefault(id)} disabled={isDefault} />
            </Tooltip>
          </Box>
        )
      },
      {
        title: 'Actions',
        sortable: false,
        width: '100px',
        render: (item) => (
          <TableActions
            items={[
              {
                key: 1,
                title: 'Delete',
                behavior: MenuItemBehavior.BUTTON,
                onClick: () => onDelete(item)
              }
            ]}
          />
        )
      }
    ]
