import { ChoiceOption } from 'ui/types'

import { NOT_SELECTED_OPTION_INDEX } from '../constants'

// input value should contain only string
// if you pass null or undefined input will move from controlled to unconrolled state that fire exception
export const getInputValue = (
  inSearch: boolean,
  searchValue: string,
  selectedIndex: number | null,
  data: ChoiceOption[]
): string => {
  if (inSearch) {
    return searchValue
  }

  if (selectedIndex === null) {
    return ''
  }

  if (selectedIndex === NOT_SELECTED_OPTION_INDEX) {
    return ''
  }

  if (!data.length) {
    return ''
  }

  return data[selectedIndex - 1]?.label ?? ''
}
