import { memo } from 'react'

import { useGetBSAReportingPage } from 'commons/hooks/bank/bsa'
import { Spinner, SpinnerSize } from 'ui/components'
import { Hr } from 'ui/components/Hr'
import { Page } from 'ui/components/Page'

import { PageButton } from './components'

import * as SC from './styles'

const Overview = memo(() => {
  const { data } = useGetBSAReportingPage()

  if (!data) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  const { showCTRList, showSARList, showCTRBatchList, showSARBatchList, showParsedTransactionsList } = data

  return (
    <Page title="BSA Reporting">
      <Hr />
      <SC.Buttons>
        {showCTRList && <PageButton text="CTRs List" to="/bsa/ctrs" />}
        {showSARList && <PageButton text="SARs List" to="/bsa/sars" />}
        {showCTRBatchList && <PageButton text="CTR Batch List" to="/bsa/ctr-batch-list" />}
        {showSARBatchList && <PageButton text="SAR Batch List" to="/bsa/sar-batch-list" />}
        {showParsedTransactionsList && <PageButton text="Parsed Cash Transactions" to="/bsa/pct-list" />}
      </SC.Buttons>
    </Page>
  )
})

export default Overview
