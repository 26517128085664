import { FC, memo } from 'react'

import { DatePicker, FF, IconName, Info, TextareaInput, Validators } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'
import { DocumentPeriodStatusChip } from '~bank-documents/components/DocumentPeriodStatusChip'
import { useDeletePeriodConfirmation } from '~bank-documents/hooks/useDeletePeriodConfirmation'

import { ManagePeriodDialogProps } from './ManagePeriodDialog.types'

import { s } from './styles'

export const ManagePeriodDialog: FC<ManagePeriodDialogProps> = memo(
  ({ id, form, dialogTitle, appearanceInfo, onClose }) => {
    const { docPeriodForDelete, DeletePeriodConfirmation, showDeletePeriodConfirmation } = useDeletePeriodConfirmation()

    return (
      <LeaveConfirmation
        preventLeaving={form.useStore((s) => s.isDirty && !s.isSubmitted && !s.isSubmitting && !docPeriodForDelete)}
        ignoreUrlHashChanging
      >
        <Dialog
          id="manage-period"
          face={DialogFace.PRIMARY}
          onClose={onClose}
          title={dialogTitle}
          onSubmit={form.handleSubmit}
          isSubmitting={form.useStore((state) => state.isSubmitting)}
          footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
          customFooterActions={
            id && (
              <IconButton
                data-qa="delete"
                key="delete"
                face="negative"
                icon={IconName.DELETE}
                onClick={() => showDeletePeriodConfirmation(id)}
              >
                Delete Period
              </IconButton>
            )
          }
        >
          <DeletePeriodConfirmation />
          <s.Box>
            <FormRow>
              <Col>
                <form.Field
                  name="startDate"
                  validators={{ onSubmit: (v) => Validators.required.field(v) }}
                  children={(startDateField) => (
                    <FF
                      label="Start Date"
                      field={startDateField}
                      required
                      input={(props) => <DatePicker data-qa="Start date period" {...props} />}
                    />
                  )}
                />
              </Col>
              <Col>
                <form.Field
                  name="endDate"
                  validators={{ onSubmit: (v) => Validators.required.field(v) }}
                  children={(endDateField) => (
                    <FF
                      label="End Date"
                      field={endDateField}
                      required
                      input={(props) => <DatePicker data-qa="End date period" {...props} />}
                    />
                  )}
                />
              </Col>
            </FormRow>
            {appearanceInfo?.enableDeliveredAt && (
              <FormRow>
                <Col>
                  <form.Field
                    name="deliveredAt"
                    children={(deliveredAtField) => (
                      <FF label="Delivered At" field={deliveredAtField} input={(props) => <DatePicker {...props} />} />
                    )}
                  />
                </Col>
              </FormRow>
            )}
            <FormRow>
              <Col>
                <Info label="Document Name">{appearanceInfo.overviewDocumentName}</Info>
              </Col>
              <Col>
                <Info label="Status">
                  {appearanceInfo.status !== undefined && <DocumentPeriodStatusChip name={appearanceInfo.status} />}
                </Info>
              </Col>
            </FormRow>
            <FormRow>
              <Col2x>
                <form.Field
                  name="notes"
                  children={(notesField) => (
                    <FF
                      label="Notes"
                      field={notesField}
                      input={(props: any) => <TextareaInput data-qa="Notes" {...props} />}
                    />
                  )}
                />
              </Col2x>
            </FormRow>
          </s.Box>
        </Dialog>
      </LeaveConfirmation>
    )
  }
)
