import { FC, memo } from 'react'

import { HeaderButton } from 'ui/components/Buttons'
import { Page } from 'ui/components/Page'

import { ClientUsersPageProps } from './ClientUsersPageProps'
import { ClientUsersTable } from './components/ClientUsersTable'
import { CreateUserDialog } from './components/CreateUserDialog'
import { useClientUserEdit } from './hooks'

export const ClientUsersPage: FC<ClientUsersPageProps> = memo((props) => {
  const { data, isFetching, filterForm, reloadClientUserList, clearHandler } = props

  const {
    isEditClientUserDialogOpen,
    openEditClientUserDialog,
    closeEditClientUserDialog,
    clientUserForEditing,
    setClientUserForEditing,
    RemoveClientUserConfirmation,
    openRemoveClientUserConfirmation,
    sendInviteLink
  } = useClientUserEdit(reloadClientUserList)

  const showCreateUserDialog = data && (isEditClientUserDialogOpen || !!clientUserForEditing)

  return (
    <Page
      title="Client Users & Permissions"
      actions={data?.enableAddNew && <HeaderButton onClick={openEditClientUserDialog}>Add New</HeaderButton>}
    >
      {showCreateUserDialog && (
        <CreateUserDialog
          open={isEditClientUserDialogOpen || !!clientUserForEditing}
          onClose={closeEditClientUserDialog}
          clientUserForEditing={clientUserForEditing}
          permissionsStructure={data?.permissions}
          reloadClientUserList={reloadClientUserList}
        />
      )}
      <RemoveClientUserConfirmation />
      <ClientUsersTable
        data={data}
        isFetching={isFetching}
        filterForm={filterForm}
        setEditClientUser={setClientUserForEditing}
        setRemovingClientUser={openRemoveClientUserConfirmation}
        sendInviteLink={sendInviteLink}
        clearHandler={clearHandler}
      />
    </Page>
  )
})
