import styled from 'styled-components'
import { Spinner } from 'ui/components/Spinner'

const WrappedSpinner = styled(Spinner)`
  margin-top: 10px;
`

const FormPaddingWrapper = styled.div`
  padding: 30px 36px 18px 36px;
`

export const s = { WrappedSpinner, FormPaddingWrapper }
