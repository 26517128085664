import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { FilterComponentProps } from 'query-table'
import { TextInput } from 'ui/components/InputV2'
import { Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'

import { DocumentsReportFilter } from '../../../DocumentsReportFilter'

import * as SC from './styles'

export const FilterForm: FC<FilterComponentProps<DocumentsReportFilter>> = memo((props) => {
  const {
    form: { config, isDirty, set, Field },
    onToggle,
    expanded
  } = props

  return (
    <FilterPanel expanded={expanded} isDirty={isDirty} onReset={() => set({ reset: true })} onToggle={onToggle}>
      <SC.FormWrapper>
        <Form config={config}>
          <SC.Row>
            <Field
              name="document_name"
              label="Document Name"
              input={TextInput}
              inputProps={{
                'data-qa': 'Document Name'
              }}
            />
            <Field
              name="active"
              label="Active"
              input={Select}
              inputProps={{
                'data-qa': 'Active',
                'options-data-qa': 'Active options',
                data: [
                  { label: 'All', value: '' },
                  { label: 'Active', value: 'true' },
                  { label: 'Not Active', value: 'false' }
                ]
              }}
            />
            <Field
              name="document_type"
              label="Document type"
              input={Select}
              inputProps={{
                'data-qa': 'Document type',
                'options-data-qa': 'Document type options',
                data: [
                  { label: 'All', value: '' },
                  { label: 'General', value: 'general' },
                  { label: 'Financials', value: 'financials' },
                  { label: 'Licensing', value: 'licensing' },
                  { label: 'Entity', value: 'entity' },
                  { label: 'Misc', value: 'misc' }
                ]
              }}
            />
            <Field
              name="frequency"
              label="Frequency"
              input={Select}
              inputProps={{
                'data-qa': 'Frequency',
                'options-data-qa': 'Frequency options',
                data: [
                  { label: 'All', value: '' },
                  { label: 'Every 5 years', value: 'every_5_years' },
                  { label: 'Every 2 years', value: 'every_2_years' },
                  { label: 'Every 18 Months', value: 'every_18_months' },
                  { label: 'Annual', value: 'annual' },
                  { label: 'Semi-Annual', value: 'semi-annual' },
                  { label: 'Quarterly', value: 'quarterly' },
                  { label: 'Monthly', value: 'monthly' },
                  { label: 'One-Time', value: 'one-time' }
                ]
              }}
            />
            <Field
              name="level"
              label="Level"
              input={Select}
              inputProps={{
                'data-qa': 'Level',
                'options-data-qa': 'Level options',
                data: [
                  { label: 'All', value: '' },
                  { label: 'Company', value: 'company' },
                  { label: 'License', value: 'license' },
                  { label: 'Bank Account', value: 'bank_account' },
                  { label: 'Contact', value: 'contact' }
                ]
              }}
            />
            <Field
              name="start_date_type"
              label="Start Date Type"
              input={Select}
              inputProps={{
                'data-qa': 'Start Date Type',
                'options-data-qa': 'Start Date Type options',
                data: [
                  { label: 'All', value: '' },
                  { label: 'Account Opening', value: 'account_opening' },
                  { label: 'Calendar', value: 'calendar' },
                  { label: 'License Issue', value: 'license_issue' },
                  { label: 'Last Delivery', value: 'last_delivery' }
                ]
              }}
            />
            <Field
              name="state"
              label="State"
              input={Select}
              inputProps={{
                'data-qa': 'State',
                'options-data-qa': 'State options',
                data: [{ label: 'All', value: '' }, ...getOptionsByListName(SelectListName.STATE_OF_INCORPORATION)]
              }}
            />
          </SC.Row>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
