import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const Insurance = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="suspiciousActivityInfo.insurance"
      children={(field) => (
        <FF
          field={field}
          label="39. Insurance"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'39. Insurance'}
              direction={'vertical'}
              data={[
                { label: 'a. Excessive insurance', value: 'a' },
                { label: 'b. Excessive or unusual cash borrowing against policy/annuity', value: 'b' },
                { label: 'c. Proceeds sent to or received from unrelated third party', value: 'c' },
                { label: 'd. Suspicious life settlement sales insurance (e.g.,STOLI’s, Viaticals)', value: 'd' },
                { label: 'e. Suspicious termination of policy or contract', value: 'e' },
                { label: 'f. Unclear or no insurable interest', value: 'f' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
