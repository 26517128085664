import { memo, useCallback } from 'react'

import IMask from 'imask'

import { BaseMaskedInput } from './BaseMaskedInput'
import { IntegerInputProps } from './props'

const MAX_LENGTH_DEFAULT = 15

export const IntegerInput = memo((props: IntegerInputProps) => {
  const { onChange, maxLength, formatted = false, ...rest } = props

  const handleChange = useCallback(
    (v: any, error?: string) => {
      const i = parseInt(v, 10)
      const value = Number.isNaN(i) ? null : i
      if (onChange) {
        onChange(value, error)
      }
    },
    [onChange]
  )

  const mask = IMask.createMask({
    mask: Number,
    thousandsSeparator: formatted ? ',' : '',
    scale: 0
  })

  return <BaseMaskedInput onChange={handleChange} {...rest} mask={mask} maxLength={maxLength || MAX_LENGTH_DEFAULT} />
})
