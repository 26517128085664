import { FC, memo } from 'react'

import * as SC from '../styles'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

export interface FooterProps {
  onCancel: () => void
  onSubmit?: () => void
  isSubmitting: boolean
}

export const Footer: FC<FooterProps> = memo((props) => {
  const { onSubmit, onCancel, isSubmitting = false } = props
  return (
    <SC.Box>
      <ButtonGroup margin="small">
        <Button loading={isSubmitting} face="positive" onClick={onSubmit}>
          Submit
        </Button>
        <Button face="neutral" onClick={onCancel}>
          Cancel
        </Button>
      </ButtonGroup>
    </SC.Box>
  )
})
