import { FC } from 'react'

import { Link } from 'ui/components'

import { HistoryEntityType } from '../../enums'
import { getLinkByEntityType } from '../../utils/getLinkByEntityType'

import { SC } from './styles'

const ApplicationLink: FC<{ id: number; name: string }> = ({ id, name }) => {
  const to = getLinkByEntityType(HistoryEntityType.APPLICATION, id)
  return (
    <SC.Row>
      <span data-qa={`details-application-field-label`}>Application</span> -{' '}
      <Link to={to} data-qa={`details-application-field-value`}>
        {name}
      </Link>
    </SC.Row>
  )
}

export default ApplicationLink
