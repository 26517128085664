import { FC, memo } from 'react'

import { UsPhoneLabel } from 'ui/components'
import { Info } from 'ui/components/Info'
import { Col, Row } from 'ui/themes/globalStyles'

import { NamesProps } from './NamesProps'

export const Names: FC<NamesProps> = memo(({ contact }) => {
  const { firstOrEntityName, lastName, phone, email } = contact

  return (
    <>
      <Row>
        <Col>
          <Info label="First Name/Entity Name">{firstOrEntityName}</Info>
        </Col>
        <Col>
          <Info label="Last Name">{lastName}</Info>
        </Col>
      </Row>
      <Row>
        <Col>
          <Info label="Email">{email}</Info>
        </Col>
        <Col>
          <Info label="Phone">
            <UsPhoneLabel value={phone} />
          </Info>
        </Col>
      </Row>
    </>
  )
})
