import { DateTime, PageSection, PageSectionFace, Panel } from 'ui/components'
import { Label } from 'ui/temp/Label'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { useSARFormContext } from '../../../../context'
import {
  AmountUnknown,
  CUSIPNumberList,
  CommodityTypes,
  CurrentAmount,
  CurrentCumulativeAmount,
  Cyber,
  CyberEventIndicators,
  CyberOther,
  Fraud,
  FraudOther,
  Gaming,
  GamingOther,
  IPAddresses,
  Identification,
  IdentificationOther,
  Insurance,
  InsuranceOther,
  Laundering,
  LaunderingOther,
  MarketWhereTradedList,
  Mortgage,
  MortgageOther,
  NoAmountInvolved,
  OtherActivities,
  OtherActivitiesOther,
  PaymentMechanisms,
  PaymentMechanismsOther,
  ProductInstrumentDescriptionList,
  ProductTypes,
  ProductTypesOther,
  Securities,
  SecuritiesOther,
  Structuring,
  StructuringOther,
  TerrorismFinancing,
  TerrorismFinancingOther
} from './fields'

import { s } from './styles'

export const SuspiciousActivityInfo = () => {
  const { formApi } = useSARFormContext()
  const from = formApi.useStore((s) => s.values.generalInfo?.transactionStartDate)
  const to = formApi.useStore((s) => s.values.generalInfo?.transactionEndDate)
  const suspiciousActivityInfo = formApi.useStore((s) => s.values.suspiciousActivityInfo)

  if (!suspiciousActivityInfo) {
    return null
  }

  const {
    structuring,
    terroristFinancing,
    fraud,
    laundering,
    identification,
    otherActivities,
    productTypes,
    paymentMechanisms,
    gaming,
    insurance,
    mortgage,
    cyber,
    securities
  } = suspiciousActivityInfo

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 4. Suspicious Activity Information" anchor="step4">
      <Panel rounded shadowed collapsible={false} title="Edit Suspicious Activity Information">
        <s.Inner>
          <FormRow>
            <Col2x>
              <Label data-qa="Item29">29. Amount involved in this report (rounded)</Label>
              <AmountUnknown />
              <NoAmountInvolved />
            </Col2x>
          </FormRow>
          <FormRow>
            <Col>
              <CurrentAmount />
            </Col>
          </FormRow>
          {from && to && (
            <>
              <FormRow>
                <Label>30. Date or date range of suspicious activity for this report</Label>
              </FormRow>
              <FormRow>
                <s.ColumnThin>
                  <Label>From</Label>
                  <DateTime date={from} />
                </s.ColumnThin>
                <s.ColumnThin>
                  <Label>To</Label>
                  <DateTime date={to} />
                </s.ColumnThin>
              </FormRow>
            </>
          )}
          {/* 31 */}
          <FormRow>
            <Col>
              <CurrentCumulativeAmount />
            </Col>
          </FormRow>
          {/* 32 */}
          <FormRow>
            <s.ColumnWide>
              <Structuring />
              <s.VisibilityBox $visible={structuring.includes('z')}>
                <s.OtherInputWrapper>
                  <StructuringOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 33 */}
          <FormRow>
            <s.ColumnWide>
              <TerrorismFinancing />
              <s.VisibilityBox $visible={terroristFinancing.includes('z')}>
                <s.OtherInputWrapper>
                  <TerrorismFinancingOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 34 */}
          <FormRow>
            <s.ColumnWide>
              <Fraud />
              <s.VisibilityBox $visible={fraud.includes('z')}>
                <s.OtherInputWrapper>
                  <FraudOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 35 */}
          <FormRow>
            <s.ColumnWide>
              <Gaming />
              <s.VisibilityBox $visible={gaming.includes('z')}>
                <s.OtherInputWrapper>
                  <GamingOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 36 */}
          <FormRow>
            <s.ColumnWide>
              <Laundering />
              <s.VisibilityBox $visible={laundering.includes('z')}>
                <s.OtherInputWrapper>
                  <LaunderingOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 37 */}
          <FormRow>
            <s.ColumnWide>
              <Identification />
              <s.VisibilityBox $visible={identification.includes('z')}>
                <s.OtherInputWrapper>
                  <IdentificationOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 38 */}
          <FormRow>
            <s.ColumnWide>
              <OtherActivities />
              <s.VisibilityBox $visible={otherActivities.includes('z')}>
                <s.OtherInputWrapper>
                  <OtherActivitiesOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 39 */}
          <FormRow>
            <s.ColumnWide>
              <Insurance />
              <s.VisibilityBox $visible={insurance.includes('z')}>
                <s.OtherInputWrapper>
                  <InsuranceOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 40 */}
          <FormRow>
            <s.ColumnWide>
              <Securities />
              <s.VisibilityBox $visible={securities.includes('z')}>
                <s.OtherInputWrapper>
                  <SecuritiesOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 41 */}
          <FormRow>
            <s.ColumnWide>
              <Mortgage />
              <s.VisibilityBox $visible={mortgage.includes('z')}>
                <s.OtherInputWrapper>
                  <MortgageOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          {/* 42 */}
          <FormRow>
            <s.ColumnWide>
              <Cyber />
              <s.VisibilityBox $visible={cyber.includes('z')}>
                <s.OtherInputWrapper>
                  <CyberOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          <FormRow>
            <s.ColumnWide>
              <ProductTypes />
              <s.VisibilityBox $visible={productTypes.includes('z')}>
                <s.OtherInputWrapper>
                  <ProductTypesOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          <FormRow>
            <s.ColumnWide>
              <PaymentMechanisms />
              <s.VisibilityBox $visible={paymentMechanisms.includes('z')}>
                <s.OtherInputWrapper>
                  <PaymentMechanismsOther />
                </s.OtherInputWrapper>
              </s.VisibilityBox>
            </s.ColumnWide>
          </FormRow>
          <IPAddresses />
          <CyberEventIndicators />
          <CommodityTypes />
          <ProductInstrumentDescriptionList />
          <MarketWhereTradedList />
          <CUSIPNumberList />
        </s.Inner>
      </Panel>
    </PageSection>
  )
}
