import { FC, memo, useMemo } from 'react'

import { useDeleteInvoiceSubmittalConfirmation } from 'bank-invoices/src/hooks'
import { LicenseWebPageInvoiceSubmitalItem } from 'commons/types/DTO/bank/license'
import { TableDataQa } from 'commons/types/enums'
import { HeaderButton } from 'ui/components/Buttons'
import { Layer } from 'ui/components/Layer'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Table } from 'ui/components/Table'

import { InvoicesProps } from './InvoicesProps'
import { useColumns } from './useColumns'

export const Invoices: FC<InvoicesProps> = memo((props) => {
  const { data, licenseId, licenseName, returnUrl, config, onInvoiceDeleted } = props
  const dataSource = useMemo(() => data.items.map((item) => ({ item })), [data])
  const { filter, onFilterChange, isFetching } = config
  const { page, size } = filter

  const { DeleteInvoiceSubmittalConfirmation, onShowInvoiceSubmittalConfirmation } =
    useDeleteInvoiceSubmittalConfirmation({
      onSuccess: () => onInvoiceDeleted()
    })

  const columns = useColumns(onShowInvoiceSubmittalConfirmation)

  const pagination = useMemo(
    () => ({ page, pageSize: size, onChange: onFilterChange, total: data.totalCount }),
    [data, size, page, onFilterChange]
  )

  return (
    <PageSection
      title="Invoice Submittal"
      face={PageSectionFace.SECONDARY}
      anchor="invoices"
      actions={
        <Link to="/invoices/add" search={{ licenseId, licenseName, returnUrl }}>
          <HeaderButton size="middle">Add New</HeaderButton>
        </Link>
      }
    >
      <DeleteInvoiceSubmittalConfirmation />
      <Layer rounded shadowed>
        <Table<LicenseWebPageInvoiceSubmitalItem>
          dataQa={TableDataQa.BP_LICENSE_INVOICES}
          loading={isFetching}
          dataSource={dataSource}
          pagination={pagination}
          columns={columns}
        />
      </Layer>
    </PageSection>
  )
})
