import { InputHintProps } from './InputHintProps'

import * as SC from './styles'

const DISABLED_HINT = 'Not available'

export const InputHint = (props: InputHintProps) => {
  const { hint, disabled, hideHint, ...rest } = props

  if (hideHint) {
    return null
  }

  return (
    <>
      {!!hint && (
        <SC.Hint $disabled={disabled} {...rest}>
          {disabled ? DISABLED_HINT : hint}
        </SC.Hint>
      )}
      {!hint && disabled && (
        <SC.Hint $disabled={disabled} {...rest}>
          {DISABLED_HINT}
        </SC.Hint>
      )}
    </>
  )
}
