import { DateTime, Link, TableColumn } from 'ui/components'
import { TableButton } from 'ui/components/Buttons'

import { AlertsReport, ClientUser } from '../../types'

export const columns = (showClientUsersDialog: (clientUsers: ClientUser[]) => void): TableColumn<AlertsReport>[] => [
  {
    title: 'Relationship Name',
    align: 'left',
    render: ({ relationshipName, companyId }) =>
      companyId ? <Link to={`/relationships/${companyId}`}>{relationshipName}</Link> : ''
  },
  {
    title: 'DBA',
    key: 'DBA',
    align: 'left'
  },
  {
    title: 'Holding Company',
    key: 'holdingCompany',
    align: 'left'
  },
  {
    title: 'Business Type',
    key: 'businessTypeName',
    align: 'left'
  },
  {
    title: 'Document Name',
    align: 'left',
    render: ({ documentName, documentId }) =>
      documentId ? <Link to={`/documents/${documentId}`}>{documentName}</Link> : ''
  },
  {
    title: 'Expiration Date',
    align: 'left',
    render: ({ expirationDate }) => <DateTime date={expirationDate} />
  },
  {
    title: 'Contact Name',
    align: 'left',
    render: ({ contactName, contactId }) => (contactId ? <Link to={`/contacts/${contactId}`}>{contactName}</Link> : '')
  },
  {
    title: 'Client Users',
    align: 'left',
    render: ({ clientUsers }) => (
      <TableButton face="neutral" onClick={() => showClientUsersDialog(clientUsers)}>
        Show
      </TableButton>
    )
  }
]
