import { getRouteApi } from '@tanstack/react-router'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { questionnaire2Labels } from '~bank-annual-review/constants/questionnaireLabels'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

export const Questionnaire2 = () => {
  const questionnaire = routeApi.useLoaderData({
    select: (s) => s.showQuestionnaire2
  })

  return (
    <PageSection title="Questionnaire">
      <AnnualReviewQuestionsSet>
        <AnnualReviewQuestionColumn>
          <Q label={questionnaire2Labels.hasMoreRevenue} children={<></>} />
          <Q label={questionnaire2Labels.revenueFromMJOnly} children={<></>} />
          <Q label={questionnaire2Labels.informationForMETRCDump} children={<></>} />
          <Q
            label={questionnaire2Labels.comparingSICSAndBankDepositsRedFlag}
            children={<>{questionnaire?.comparingSICSAndBankDepositsRedFlag || '---'}</>}
          />
          <Q
            label={questionnaire2Labels.comparingSICSAndBankDepositsVariance}
            children={<>{questionnaire?.comparingSICSAndBankDepositsVariance || '---'}</>}
          />
          <Q
            label={questionnaire2Labels.comparingSICSAndBankDepositsComments}
            children={<>{questionnaire?.comparingSICSAndBankDepositsComments || '---'}</>}
          />
          <Q label={questionnaire2Labels.hasMoreCashDeposits} children={<></>} />
          <Q label={questionnaire2Labels.compareCashAmountDump} children={<></>} />
          <Q
            label={questionnaire2Labels.comparingCashDepositsAndTaxReturnsRedFlag}
            children={<>{questionnaire?.comparingCashDepositsAndTaxReturnsRedFlag || '---'}</>}
          />
          <Q
            label={questionnaire2Labels.comparingCashDepositsAndTaxReturnsVariance}
            children={<>{questionnaire?.comparingCashDepositsAndTaxReturnsVariance || '---'}</>}
          />
          <Q
            label={questionnaire2Labels.comparingCashDepositsAndTaxReturnsComments}
            children={<>{questionnaire?.comparingCashDepositsAndTaxReturnsComments || '---'}</>}
          />
          <Q label={questionnaire2Labels.inconsistentFinancialStatement} children={<></>} />
          <Q label={questionnaire2Labels.validateContributionsDump} children={<></>} />
          <Q
            label={questionnaire2Labels.financialStatementValidationRedFlag}
            children={<>{questionnaire?.financialStatementValidationRedFlag || '---'}</>}
          />
          <Q
            label={questionnaire2Labels.financialStatementValidationVariance}
            children={<>{questionnaire?.financialStatementValidationVariance || '---'}</>}
          />
          <Q
            label={questionnaire2Labels.financialStatementValidationComments}
            children={<>{questionnaire?.financialStatementValidationComments || '---'}</>}
          />
          <Q label={questionnaire2Labels.disguisingMRBActivity} children={<></>} />
          <Q label={questionnaire2Labels.inconsistentMRBPurporting} children={<></>} />
          <Q label={questionnaire2Labels.inconsistentMRBPurportingDueDiligenceCompleted} children={<></>} />
          <Q label={questionnaire2Labels.ownerOutsideState} children={<></>} />
          <Q label={questionnaire2Labels.ownerOutsideStateApproved} children={<></>} />
          <Q label={questionnaire2Labels.adverseMediaRequired} children={<></>} />
          <Q label={questionnaire2Labels.adverseMediaReviewed} children={<></>} />
          <Q label={questionnaire2Labels.mrbOnFederalProperty} children={<></>} />
          <Q label={questionnaire2Labels.mrbSchoolProximityConsistent} children={<></>} />
          <Q label={questionnaire2Labels.mrbSchoolProximityDueDiligenceCompleted} children={<></>} />
        </AnnualReviewQuestionColumn>
      </AnnualReviewQuestionsSet>
    </PageSection>
  )
}
