import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'
import { SubjectInformationItemContext } from './SubjectInformationItemContext'
import {
  AddressFields,
  AlternateName,
  BusinessType,
  CorroborativeStatementToFiler,
  Email,
  EntityFields,
  EntityNameOrLastName,
  FormOfIdentification,
  InstitutionInfo,
  IsAllInfoUnavailable,
  IsEntity,
  NAICSCode,
  NoKnownAccountsInvolved,
  Phone,
  RelationshipsInfo,
  RoleInActivity,
  TIN,
  TINType,
  WebSite
} from './fields'

import { s } from './styles'

type SubjectInformationItemProps = {
  index: number
  field: FieldApi<SarEdit, 'subjectsInfo'>
}

export const SubjectInformationItem = (props: SubjectInformationItemProps) => {
  const { index, field } = props
  const { formApi } = useSARFormContext()
  const isAllInfoUnavailable = formApi.useStore((s) => s.values.subjectsInfo[index].isAllInfoUnavailable)

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => field.removeValue(index),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <s.Item
      rounded
      shadowed
      collapsible={false}
      title={`Edit Part I Subject Information ${+index + 1} of ${field.state.value.length}`}
      actions={
        field.state.value.length > 1 && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting}>
            Remove
          </IconButton>
        )
      }
    >
      <SubjectInformationItemContext.Provider value={{ isAllInfoUnavailable }}>
        <Confirmation />
        <s.Inner>
          <FormRow>
            <Col>
              <IsEntity index={index} />
              <IsAllInfoUnavailable index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <EntityNameOrLastName index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <AlternateName index={index} />
            </Col>
          </FormRow>
          <EntityFields index={index} />
          <FormRow>
            <Col>
              <BusinessType index={index} />
            </Col>
            <Col>
              <NAICSCode index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <TIN index={index} />
            </Col>
            <Col>
              <TINType index={index} />
            </Col>
          </FormRow>
          <Phone index={index} />
          <FormRow>
            <Col>
              <Email index={index} />
            </Col>
            <Col>
              <WebSite index={index} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <CorroborativeStatementToFiler index={index} />
            </Col>
            <Col>
              <RoleInActivity index={index} />
            </Col>
          </FormRow>
          <AddressFields index={index} />
          <FormOfIdentification index={index} />
          <RelationshipsInfo index={index} />
          <FormRow>
            <Col>
              <h5>27. Financial Institution TIN and account number(s) affected that are related to subject</h5>
            </Col>
            <Col>
              <NoKnownAccountsInvolved index={index} />
            </Col>
          </FormRow>
          <InstitutionInfo index={index} />
        </s.Inner>
      </SubjectInformationItemContext.Provider>
    </s.Item>
  )
}
