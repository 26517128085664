import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'
import { CurrencyInput, TextInput } from 'ui/components/InputV2'
import { FormRow } from 'ui/themes/globalStyles'

import { ForInOut, ForeignInOutProps } from './ForeignInOut.types'

import { s } from './styles'

export const ForeignInOut: FC<ForeignInOutProps> = memo((props) => {
  const { value, onChange } = props
  const finalValue = useMemo(() => (!value?.length ? [{ cash: 0, country: '' }] : value), [value])
  const listRef = useRef(value)

  useEffect(() => {
    listRef.current = [...finalValue]
  }, [finalValue, listRef])

  const onAdd = useCallback(() => {
    if (typeof onChange === 'function' && listRef.current)
      onChange(listRef.current.concat({ cash: 0, country: '' }), undefined)
  }, [listRef, onChange])

  const onDelete = useCallback(
    (index: number) => {
      if (typeof onChange === 'function' && listRef.current) {
        const copy = [...listRef.current]
        copy.splice(index, 1)
        onChange(copy, undefined)
      }
    },
    [listRef, onChange]
  )

  const onCashChange = useCallback(
    (index: number, v: number, e?: string) => {
      if (typeof onChange === 'function' && listRef.current) {
        const copy = [...listRef.current]
        copy[index].cash = v
        onChange(copy, e)
      }
    },
    [listRef, onChange]
  )

  const onCountryChange = useCallback(
    (index: number, v: string, e?: string) => {
      if (typeof onChange === 'function' && listRef.current) {
        const copy = [...listRef.current]
        copy[index].country = v
        onChange(copy, e)
      }
    },
    [listRef, onChange]
  )

  return (
    <div>
      {finalValue.map((item: ForInOut, index: number) => (
        <FormRow key={index}>
          <s.Inputs>
            <CurrencyInput value={item.cash} onChange={onCashChange.bind(null, index)} allowDecimal={false} />
            <TextInput toUpper value={item.country} onChange={onCountryChange.bind(null, index)} maxLength={2} />
          </s.Inputs>
          {finalValue.length > 1 && (
            <s.DeleteIcon name={IconName.CAN} size={IconSize.M} onClick={() => onDelete(index)} />
          )}
          {index === finalValue.length - 1 && <s.AddIcon name={IconName.ADD_GREEN} size={IconSize.M} onClick={onAdd} />}
        </FormRow>
      ))}
    </div>
  )
})
