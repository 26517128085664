/**
 * Pagination.
 */

export type TablePaginationChangeHandler = (page: number, size: number) => void

export type TablePagination = {
  total: number | null | undefined
  page: number | null | undefined
  pageSize: number | null | undefined
  onChange: TablePaginationChangeHandler
  pageSizeOptions?: string[]
  tooMany?: boolean
}

/**
 * Sorting.
 */

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type TableSortingOrder = { field: string; direction: 'ASC' | 'DESC' | SortDirection }

export type TableSortingChangeHandler = (value: TableSortingOrder) => void

export type TableSorting = {
  order: TableSortingOrder[]
  onChange: TableSortingChangeHandler
}
