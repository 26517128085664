import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { Select } from 'ui/components/Select'
import { FilterPanel } from 'ui/components/Table'
import { TableFilterRow } from 'ui/temp/TableFilterRow'

import { FilterProps } from './FilterProps'
import { useFilter } from './hooks'

import * as SC from './styles'

export const Filter: FC<FilterProps> = memo((props) => {
  const { webPage } = props
  const { isDirty, config, Field, reset } = useFilter(props)

  return (
    <FilterPanel expanded={false} isDirty={isDirty} onReset={reset}>
      <SC.FormWrapper>
        <Form config={config}>
          <TableFilterRow>
            <SC.FieldBox>
              <Field name="internal" label="Internal" input={Select} inputProps={{ data: webPage.internalOptions }} />
            </SC.FieldBox>
            <SC.FieldBox>
              <Field
                name="frequency"
                label="Frequency"
                input={Select}
                inputProps={{ data: webPage.frequencyOptions }}
              />
            </SC.FieldBox>
            <SC.FieldBox>
              <Field name="alerts" label="Alerts" input={Select} inputProps={{ data: webPage.alertsOptions }} />
            </SC.FieldBox>
          </TableFilterRow>
        </Form>
      </SC.FormWrapper>
    </FilterPanel>
  )
})
