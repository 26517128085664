import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReadHook, useStateManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'
import { CTRDetailsRequest, CTRDetailsResponse } from '~bank-bsa-reporting/types'

export const CTR_DETAILS_KEY = 'ctr-details'

const request = (opts: CTRDetailsRequest | undefined) =>
  Request.post<CTRDetailsResponse, CTRDetailsRequest>(Request.urls.bank.ctrDetailsWebpage, opts)

const useGetCTR: ReadHook<CTRDetailsResponse, CTRDetailsRequest> = (opts, callbacks) =>
  useStateManager().use([CTR_DETAILS_KEY, opts], () => request(opts), {
    keepPreviousData: false,
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError
  })

const defaultFilter = (id: number): CTRDetailsRequest => ({
  historyPage: 1,
  historySize: 10,
  id
})

export const useGetCTRDetails = (id: number) => {
  const [filter, setFilter] = useState<CTRDetailsRequest>(defaultFilter(id))

  useEffect(() => setFilter(defaultFilter(id)), [id])

  const [updateCaller, setUpdateCaller] = useState<'' | 'history'>('')
  const resetPending = useCallback(() => setUpdateCaller(''), [])

  const { data, isFetching } = useGetCTR(filter, {
    onSuccess: resetPending,
    onError: resetPending
  })

  const onHistoryFilterChange = useCallback(
    (page: number, size: number) => {
      if (page !== filter.historyPage || size !== filter.historySize) {
        setFilter({ ...filter, historyPage: page, historySize: size })
        setUpdateCaller('history')
      }
    },
    [filter]
  )

  const historyTableConfig = useMemo(
    () => ({
      isFetching: updateCaller === 'history',
      filter: {
        page: filter.historyPage || 1,
        size: filter.historySize || 10
      },
      onFilterChange: onHistoryFilterChange
    }),
    [filter, onHistoryFilterChange, updateCaller]
  )

  return { data, isFetching, historyTableConfig }
}
