import { getCompanyList } from 'commons/service/bank/company'
import { Company } from 'commons/types/DTO/bank'

export const getCompany = (id: number | null): Promise<Company> =>
  id && id > 0
    ? getCompanyList({ _options: { filters: [{ field: 'id', type: 'eq', value: id }] } }).then((res) => res.records[0])
    : Promise.resolve({
        accountingSyncStatus: '',
        active: false,
        bankSyncStatus: '',
        business_type: '',
        cif: '',
        city: '',
        country: 'US',
        created_at: null,
        customer_status: '',
        dateFounded: null,
        dba: '',
        deleted_at: null,
        description: '',
        ein: '',
        employees: null,
        entity_type: '',
        fax: '',
        hasAccountingPlatform: false,
        holding_id: null,
        id: 0,
        is_holding: false,
        lastAccountingSyncAt: null,
        lastBankSyncAt: null,
        lastManualDataUpdateAt: null,
        legal_name: '',
        name: '',
        organization_id: 0,
        phone: '',
        postal_code: '',
        primaryDataSource: '',
        report_alerts_criteria: 0,
        reportedAccountingPlatform: '',
        reportedAccountingPlatformUpdateFrequency: '',
        requiredApprovalsAmount: 0,
        requiredApprovalsCount: 0,
        sf_acc_id: '',
        state: '',
        stateFounded: '',
        street: '',
        updated_at: null,
        website: '',
        mailing_city: '',
        mailing_postal_code: '',
        mailing_state: '',
        mailing_street: '',
        tags: '',
        meta: {
          application_id: 0,
          assigned_bank_users: null,
          holding_name: '',
          holding_group_companies: null
        }
      })
