import { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormData } from 'brief-form'
import { ApiError } from 'commons/types'
import { ClientUserPermission, GetClientUserListResponseItem } from 'commons/types/contracts/api/bank/clientUser'
import { handlerServerError } from 'errors'
import { isEqual } from 'lodash'
import { updateFormAfterApiError } from 'ui/components'

import { ClientUserGeneralInformationType, CreateClientUserFormType, EditClientUserFormType } from '../../../types'
import { useUserFormRequest } from './useUserFormRequest'

export const useCreateUserDialog = (
  clientUserForEditing?: GetClientUserListResponseItem,
  permissionsStructure?: ClientUserPermission[],
  onClose?: () => void,
  reloadClientUserList?: () => void
) => {
  const isModeCreating = useMemo(() => typeof clientUserForEditing === typeof undefined, [clientUserForEditing])

  const generalInformationInitialFormValue: ClientUserGeneralInformationType = {
    email: '',
    firstName: '',
    lastName: '',
    phone: ''
  }

  const [isFormChanged, setIsFormChanged] = useState(false)

  const topSidePermissions: string[] = []

  const permissionsFormValues = {}

  const trueEditPermissions: string[] = []

  clientUserForEditing?.permissions.forEach((permission) => {
    if (isModeCreating || permission.enabled) trueEditPermissions.push(permission.value)
    if (permission.nested.length) {
      permission?.nested.forEach(
        (nestedPermission) =>
          (isModeCreating || nestedPermission.enabled) && trueEditPermissions.push(nestedPermission.value)
      )
    }
  })

  permissionsStructure?.forEach((permission) => {
    permissionsFormValues[permission.value] = trueEditPermissions.includes(permission.value)
    if (permission.nested.length) {
      topSidePermissions.push(permission.value)
      permission.nested?.forEach((nestedPermission) => {
        permissionsFormValues[nestedPermission.value] = trueEditPermissions.includes(nestedPermission.value)
      })
    }
  })

  let initialFormValue = {
    relationships: isModeCreating ? [] : clientUserForEditing?.relationships.map((relationship) => relationship.id),
    ...permissionsFormValues
  }

  if (isModeCreating) initialFormValue = { ...initialFormValue, ...generalInformationInitialFormValue }

  const onBeforeChange = ({ value, errors }) => {
    setIsFormChanged(
      Object.entries(value)
        .map((item) => !topSidePermissions.includes(item[0]) && !isEqual(item[1], initialFormValue[item[0]]))
        .filter(Boolean).length > 0
    )
    return { value, errors }
  }

  const { config, Field, set, validate } = useFormData<EditClientUserFormType | CreateClientUserFormType>({
    initialValue: initialFormValue,
    onBeforeChange
  })

  const handlerFormError = useCallback(
    (error: any) => {
      handlerServerError(error, { form: config })
    },
    [config]
  )

  useEffect(() => {
    set({ reset: true })
  }, [])

  const isFormEnabled = useMemo(
    () =>
      Object.entries(config.value)
        .map((item) => !topSidePermissions.includes(item[0]) && item[1] === true)
        .filter(Boolean).length > 0 && Object.values(validate({ updateFields: false })).filter((v) => !!v).length > 1,
    [config.value]
  )

  const onCloseWrapper = useCallback(() => {
    set({ reset: true })
    if (onClose) {
      onClose()
    }
  }, [set, onClose])

  const { isSubmittingClientUserForm, createClientUser, editClientUser } = useUserFormRequest(
    onCloseWrapper,
    reloadClientUserList,
    clientUserForEditing?.id,
    handlerFormError
  )

  const onSubmitWrapper = useCallback(() => {
    try {
      if (Object.values(validate({ updateFields: true })).filter((v) => !!v).length && isFormEnabled) {
        if (isModeCreating) createClientUser(config?.value as CreateClientUserFormType)
        else editClientUser(config?.value)
      }
    } catch (error: unknown) {
      updateFormAfterApiError(config.value, config.errors, error as ApiError, (v, e) => {
        config.onChange(v, e)
      })
    }
  }, [createClientUser, editClientUser, config, validate, isModeCreating, isFormEnabled])

  return {
    isModeCreating,
    onCloseWrapper,
    onSubmitWrapper,
    isFormEnabled,
    isFormChanged,
    config,
    Field,
    isSubmittingClientUserForm
  }
}
