import { FC, memo } from 'react'

import { ComparisonInfo } from 'commons/types/DTO/bank/reportWebPage'
import { formatCurrencyCent } from 'commons/utils'

import * as SmallTable from '../../../../../components/smallTable.styles'

export const SalesToDepositComparisonTable: FC<{ comparisonInfo: ComparisonInfo }> = memo(({ comparisonInfo }) => (
  <SmallTable.WrappedLayer rounded shadowed>
    <SmallTable.Title>Sales To Deposit Comparison</SmallTable.Title>
    <SmallTable.Table>
      <tbody>
        <tr>
          <SmallTable.Td>Total Cannabis Sales</SmallTable.Td>
          <SmallTable.Td>{formatCurrencyCent(comparisonInfo.sales)}</SmallTable.Td>
        </tr>
        <tr>
          <SmallTable.Td>Non-Cannabis Sales</SmallTable.Td>
          <SmallTable.Td>{formatCurrencyCent(comparisonInfo.nonCannabisSales)}</SmallTable.Td>
        </tr>
        <tr>
          <SmallTable.Td>Estimated Sales Tax</SmallTable.Td>
          <SmallTable.Td>{formatCurrencyCent(comparisonInfo.estimatedSalesTax)}</SmallTable.Td>
        </tr>
        <tr>
          <SmallTable.Td>Total Sales</SmallTable.Td>
          <SmallTable.Td>{formatCurrencyCent(comparisonInfo.totalSales)}</SmallTable.Td>
        </tr>
        <tr>
          <SmallTable.Td>Total Deposits</SmallTable.Td>
          <SmallTable.Td>{formatCurrencyCent(comparisonInfo.totalDeposits)}</SmallTable.Td>
        </tr>
        <tr>
          <SmallTable.Td>$ Difference</SmallTable.Td>
          <SmallTable.Td>{formatCurrencyCent(comparisonInfo.differenceAbsolute)}</SmallTable.Td>
        </tr>
        <tr>
          <SmallTable.Td>% Difference</SmallTable.Td>
          <SmallTable.Td $alerted={comparisonInfo.differenceRelative.alerted}>
            {comparisonInfo.differenceRelative.percent.toString() === '-1.00'
              ? 'N/A'
              : `${comparisonInfo.differenceRelative.percent} %`}
          </SmallTable.Td>
        </tr>
      </tbody>
    </SmallTable.Table>
  </SmallTable.WrappedLayer>
))
