import { FC } from 'react'

import { Form } from 'brief-form'
import { TextareaInput } from 'ui/components/InputV2'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { NotesFormProps } from './types'
import useSaveNotes from './useSaveNotes'

import * as SC from './styles'

const NotesForm: FC<NotesFormProps> = ({ value: formData, showSave = true, onChange, onSave }) => {
  const { Field, config, saveHandler } = useSaveNotes({ formData, onChange, onSave })
  return (
    <Form config={config}>
      <FormRow>
        <Col2x>
          <Field
            label="Add a note for the bank"
            name={OnboardingFormFieldNames.CLIENT_NOTES}
            input={TextareaInput}
            inputProps={{
              rows: 4
            }}
          />
        </Col2x>
      </FormRow>
      {showSave && (
        <FormRow>
          <Col2x>
            <SC.WelcomeButton face="positive" onClick={saveHandler}>
              Save Notes
            </SC.WelcomeButton>
          </Col2x>
        </FormRow>
      )}
    </Form>
  )
}

export { NotesForm }
