import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const InternalControlNumber = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="institutionContactInfo.internalControlNumber"
      children={(field) => (
        <FF label="88. Internal control/file number" field={field} input={(p) => <TextInput {...p} maxLength={50} />} />
      )}
    />
  )
}
