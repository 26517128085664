import { FC } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { Validators } from 'ui/components/Form'
import { Select } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'

type SeeCustomerCommunicationOrAnnualQuestionsProps = {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, Questionnaire>
  ) => JSX.Element
}

export const SeeCustomerCommunicationOrAnnualQuestions: FC<SeeCustomerCommunicationOrAnnualQuestionsProps> = ({
  Field
}) => (
  <>
    <MainText>
      A marijuana-related business engages in international or interstate activity, including by receiving cash deposits
      from locations outside the state in which the business operates, making or receiving frequent or large interstate
      transfers, or otherwise transacting with persons or entities located in different states or countries.
      <br />A surge in activity by third parties offering goods or services to marijuana-related business, such as
      equipment suppliers or shipping servicers.
      <br />
      The business is unable to demonstrate the legitimate source of significant outside investments.
    </MainText>
    <SecondText>Review account activity.</SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="seeCustomerCommunicationOrAnnualQuestionsRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
  </>
)
