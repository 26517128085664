import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import {
  CompanyId,
  FilingName,
  FilingType,
  Notes,
  NotesToFincen,
  PriorReportBSAId,
  Status,
  TransEndDate,
  TransStartDate
} from './fields'

import { s } from './styles'

export const GeneralInformationForm = () => (
  <s.PanelWithBottomMargin rounded shadowed collapsible={false} title="Edit General information" anchor="general">
    <s.FormInner>
      <FormRow>
        <Col2x>
          <FilingName />
        </Col2x>
      </FormRow>
      <FormRow>
        <Col2x>
          <FilingType />
        </Col2x>
      </FormRow>
      <FormRow>
        <Col>
          <PriorReportBSAId />
        </Col>
      </FormRow>
      <FormRow>
        <Col2x>
          <NotesToFincen />
        </Col2x>
      </FormRow>
      <FormRow>
        <Col>
          <CompanyId />
        </Col>
        <Col>
          <Status />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <TransStartDate />
        </Col>
        <Col>
          <TransEndDate />
        </Col>
      </FormRow>
      <FormRow>
        <Col2x>
          <Notes />
        </Col2x>
      </FormRow>
    </s.FormInner>
  </s.PanelWithBottomMargin>
)
