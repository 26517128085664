import { LicenseDeprecated } from 'commons/types/DTO/bank'
import { Keys, ReadHook, useStateManager } from 'state-manager'

import { getHandler } from './getHandler'

export type UseGetLicenseOpts = {
  id: number
  companyId: number
}

export const useGetLicense: ReadHook<LicenseDeprecated, UseGetLicenseOpts> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(Keys.License.ById(opts?.id), getHandler(opts?.id, opts?.companyId), {
    keepPreviousData: true
  })

  return {
    ...rest,
    data: data?.records[0]
  }
}
