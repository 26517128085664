// @ts-ignore
import React, { FC, ReactNode, createContext, useContext, useState } from 'react'

type ProviderProps = {
  children?: ReactNode
}

/**
 * This function should be called once (OUTSIDE OF COMPONENT RENDER!)
 * to generate all necessary stuff.
 * @param {ContextValue?} initialValue - initial context value.
 * @returns
 */
export const generateContext = <ContextValue,>(initialValue?: ContextValue) => {
  // Final context also should have setter for given data type.
  type ExtendedContextValue = { data?: ContextValue; setter: (value: ContextValue) => void }

  // Create context object.
  const Context = createContext<ExtendedContextValue>({
    data: initialValue,
    setter: () => {
      throw new Error('Not implemented!')
    }
  })

  /**
   * Context provider component. Context will be available everywhere indide this component.
   * Also, here we replace initial empty setter implementation with function performing
   * state update.
   */
  const Provider: FC<ProviderProps> = ({ children }) => {
    const [data, setter] = useState<ContextValue | undefined>(initialValue)

    return (
      <Context.Provider
        value={{
          // Default setter throws "not implemented" error.
          // We should provide own function.
          setter,
          data
        }}
      >
        {children}
      </Context.Provider>
    )
  }

  const useContextValue = () => useContext<ExtendedContextValue>(Context)

  return {
    Provider,
    useContextValue
  }
}
