import { useCallback, useState } from 'react'

import { useAssignApplicationOwner } from 'commons/hooks/bank/application'
import { Toast } from 'ui/components'
import { ChoiceOption } from 'ui/types'

import { ApplicationOwnerDialog } from '../ApplicationOwnerDialog'

export const useApplicationOwner = (
  applicationId: number,
  initialValue: ChoiceOption,
  availableOwners: ChoiceOption[],
  onSuccessSubmit: () => Promise<void>
) => {
  const [isApplicationOwnerVisible, setIsApplicationOwnerVisible] = useState(false)
  const openApplicationOwnerDialog = useCallback(
    () => setIsApplicationOwnerVisible(true),
    [setIsApplicationOwnerVisible]
  )
  const closeApplicationOwnerDialog = useCallback(
    () => setIsApplicationOwnerVisible(false),
    [setIsApplicationOwnerVisible]
  )

  const { routine: assignOwner } = useAssignApplicationOwner({
    onSuccess: async () => {
      onSuccessSubmit()
      closeApplicationOwnerDialog()
      Toast.successSmall('Owner was successfully changed')
    },
    onError: async () => Toast.errorSmall('Something was wrong')
  })

  const onSubmit = useCallback(
    (ownerId: string) => assignOwner({ applicationId, ownerId }),
    [assignOwner, applicationId]
  )

  const ApplicationOwner = useCallback(
    () => (
      <ApplicationOwnerDialog
        initialValue={initialValue}
        visible={isApplicationOwnerVisible}
        onClose={closeApplicationOwnerDialog}
        availableOwners={availableOwners}
        onSubmit={onSubmit}
      />
    ),
    [isApplicationOwnerVisible, closeApplicationOwnerDialog]
  )

  return {
    ApplicationOwnerDialog: ApplicationOwner,
    openApplicationOwnerDialog
  }
}
