import { DeepKeys, FormApi } from '@tanstack/react-form'
import { ServerErrorResponse } from 'errors'

import { Toast } from '../Toast'

/**
 * Handles submission error - shows toast with description and optionally highlights form invalid field.
 * @param {unknown} error - server error.
 * @param {FormApi} formApi - tanstack form API.
 */
export const handleFormSubmissionError = <T>(error: unknown, formApi: FormApi<T, undefined>) => {
  const submitError = error as ServerErrorResponse

  Toast.errorSmall(submitError.message)

  if (submitError.meta) {
    Object.entries(submitError.meta).forEach(([key, value]) => {
      formApi.setFieldMeta(key as DeepKeys<T>, (stateMeta) => ({
        ...stateMeta,
        errors: [],
        errorMap: { onSubmit: value.pop() }
      }))
    })

    setTimeout(() => {
      const element = document.querySelector(`[data-field-with-error]`)
      element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }, 0)
  }
}
