import styled from 'styled-components'

export const TV = styled.span<{ $negative?: boolean }>`
  color: ${(p) =>
    p.$negative ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.secondary};
`

export const Table = styled.table`
  width: 100%;

  th {
    height: 32px;
    background: var(--theme-colors-global-grey-grey300);
    font: var(--theme-typography-bodyM-semiBold);
    color: var(--theme-colors-global-general-secondary);
    text-align: left;
    padding-left: 16px;
  }

  td:first-child,
  th:first-child {
    padding-left: 32px;
  }

  th:first-child {
    width: 200px;
  }

  th:nth-of-type(2) {
    width: 160px;
  }

  td {
    height: 44px;
    font: var(--theme-typography-bodyM-regular);
    color: var(--theme-colors-global-general-secondary);
    text-align: left;
    padding-left: 16px;
  }

  tr:nth-child(odd) {
    background: #00000005;
  }

  tr:nth-child(even) {
    background: #0000000d;
  }

  tr:hover {
    background: #00000014;
  }
`

export const Td = styled.td<{ $alerted?: boolean }>`
  ${(p) => (p.$alerted ? 'background: ' + p.theme.uiKit.colors.theme.negative.main : '')};
`
