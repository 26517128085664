import { CSSProperties } from 'react'

import styled from 'styled-components'

import { MAX_OPTIONS_VISIBLE, OPTION_MIN_WIDTH } from '../constants'

const OPTION_HEIGHT = 36
const HEADER_HEIGHT = 40
const TOP_AND_BOTTOM_HEIGHT = 7
const OPTION_LIST_HEIGHT = OPTION_HEIGHT * MAX_OPTIONS_VISIBLE
export const OVERALL_HEIGHT = OPTION_LIST_HEIGHT + 2 * TOP_AND_BOTTOM_HEIGHT + HEADER_HEIGHT

export const PopperStyles = (): CSSProperties => ({
  maxHeight: `${OVERALL_HEIGHT}px`,
  minWidth: `${OPTION_MIN_WIDTH}px`,
  overflow: 'hidden',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  listStyleType: 'none',
  background: '#fff',
  padding: `0`,
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'stretch'
})

export const OptionsList = styled.div`
  scrollbar-width: thin;
  max-height: ${OPTION_LIST_HEIGHT}px;
  overflow-y: auto;
  align-self: stretch;
  flex: 1;
`

export const DropDownHeader = styled.p`
  height: ${HEADER_HEIGHT}px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  padding: 0 25px 0 15px;
  display: flex;
  align-items: center;
`

export const DropDownSubHeader = styled.p`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  padding: 0 25px 0 15px;
  height: 36px;
  display: flex;
  align-items: center;
`

export const Columns = styled.div`
  margin-top: ${TOP_AND_BOTTOM_HEIGHT}px;
  margin-bottom: ${TOP_AND_BOTTOM_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: start;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  & > * {
    flex: 1 0 50%;
    align-self: stretch;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }
  & > *:first-child {
    border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  }
`
