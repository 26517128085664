import { useEffect, useState } from 'react'

import { SelectContext, UseCounterResult } from '../types'

export const useCounter = (context: SelectContext): UseCounterResult => {
  const { selectedValues } = context
  const [count, setCount] = useState<number>(selectedValues.length)

  useEffect(() => {
    setCount(selectedValues.length)
  }, [selectedValues])

  return { count }
}
