import { FF, TextInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const ZipCode = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].zipCode` as 'institutionActivitiesInfo[0].zipCode'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          label="64. ZIP/Postal Code"
          field={field}
          input={(p) => <TextInput {...p} maxLength={9} data-qa="64. ZIP/Postal Code" />}
        />
      )}
    />
  )
}
