import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type BusinessTypeProps = {
  index: number
}

export const BusinessType = ({ index }: BusinessTypeProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index}].businessType` as 'subjectsInfo[0].businessType'}
      children={(field) => (
        <FF field={field} label="10. Occupation or type of business" input={(p) => <TextInput {...p} />} />
      )}
    />
  )
}
