import { FC } from 'react'

import { OnboardingFooterProps } from './OnboardingFooterProps'

import * as SC from './styles'

export const OnboardingFooter: FC<OnboardingFooterProps> = ({ pageLabel, children }) => (
  <SC.Footer>
    <SC.Page>{pageLabel}</SC.Page>
    <SC.Box>{children}</SC.Box>
  </SC.Footer>
)
