import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type CashOutProps = {
  index: number
}

export const CashOut = ({ index }: CashOutProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].cashOut` as 'transactionsInfo[0].cashOut'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => <FF required field={field} label="Cash Out" input={(p) => <CurrencyCentInput {...p} />} />}
    />
  )
}
