import {
  ErrorComponentProps,
  Navigate,
  Outlet,
  createRootRouteWithContext,
  createRoute,
  createRouter
} from '@tanstack/react-router'
import { accountSettingsRoute } from 'auth'
import { routeAdverseMediaTree } from 'bank-adverse-media'
import { routeAlertsTree } from 'bank-alerts'
import { routeAnnualReviewsTree } from 'bank-annual-review'
import { routeBSATree } from 'bank-bsa-reporting'
import { routeClientUsersTree } from 'bank-client-users'
import { routeCompaniesTree } from 'bank-companies'
import { routeConnectionsTree } from 'bank-connections'
import { routeContactsTree } from 'bank-contacts'
import { routeDocumentsTree } from 'bank-documents'
import { routeHistoryTree } from 'bank-entity-history'
import { routeInternalTransfersTree } from 'bank-internal-transfers'
import { routeInvoicesTree } from 'bank-invoices'
import { routeLicensesTree } from 'bank-licenses'
import { routeNextCloudTree } from 'bank-nextcloud-login'
import { routeOnboardingTree } from 'bank-onboarding'
import { routeOnboardingDocumentsTree } from 'bank-onboarding-documents'
import { routeMRTree, routeQRTree } from 'bank-quarterly-report'
import { routeSalesDataTree } from 'bank-salesdata'
import { TanStackRouterDevTools } from 'commons/utils'
import { Logger } from 'commons/utils/logger'
import { ErrorDetails } from 'ui/components/Errors'

import { routeBankAccountsTree } from '@orbit/bank-accounts'
import { routeReportsTree } from '@orbit/bank-reports'
import { bankUsersRoutingNode } from '@orbit/bank-users'

import { NotFound } from '../../404/components/NotFound'
import { BaseRoute } from './BaseRoute'
import { getBPAccessibleRoutingFlags } from './getBPAccessibleRoutingFlags'

const rootRoute = createRootRouteWithContext<BPRoutingContext>()({
  component: () => (
    <>
      <Outlet />
      <TanStackRouterDevTools position="top-right" />
    </>
  ),
  notFoundComponent: () => <NotFound />,
  errorComponent: (props: ErrorComponentProps) => {
    Logger.getInstance().error(props.error.message, props.error)
    return <ErrorDetails />
  }
})

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: () => <Navigate to="/relationships" />
})

const loginRoute = createRoute({
  getParentRoute: () => indexRoute,
  path: 'auth/login',
  component: BaseRoute
})

const routeTree = rootRoute.addChildren([
  routeCompaniesTree(rootRoute),
  routeContactsTree(rootRoute),
  routeAnnualReviewsTree(rootRoute),
  routeBankAccountsTree(rootRoute),
  routeInternalTransfersTree(rootRoute),
  routeDocumentsTree(rootRoute),
  routeSalesDataTree(rootRoute),
  routeMRTree(rootRoute),
  routeQRTree(rootRoute),
  routeAlertsTree(rootRoute),
  routeLicensesTree(rootRoute),
  routeClientUsersTree(rootRoute),
  routeConnectionsTree(rootRoute),
  routeReportsTree(rootRoute),
  routeAdverseMediaTree(rootRoute),
  bankUsersRoutingNode(rootRoute),
  routeHistoryTree(rootRoute),
  routeInvoicesTree(rootRoute),
  routeOnboardingTree(rootRoute),
  routeOnboardingDocumentsTree(rootRoute),
  accountSettingsRoute(rootRoute),
  routeBSATree(rootRoute),
  routeNextCloudTree(rootRoute),
  loginRoute,
  indexRoute
])

const BPRouter = () =>
  createRouter({
    routeTree,
    context: { accessibleRoutingFlags: getBPAccessibleRoutingFlags([]) },
    defaultPendingMs: 0,
    scrollRestoration: true
  })

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof BPRouter
  }
}

export { BPRouter }
