import { FC, memo, useContext } from 'react'

import { SALESDATA_PATH } from 'commons/constants/routes'
import { SalesSourceItem, SalesSourceItemInfo } from 'commons/types/DTO/bank/reportWebPage'
import { TableDataQa } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils'
import { IconName, IconSize, Link, Table, Tooltip } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { TableButton, TextButton } from 'ui/components/Buttons'
import { ResourcePath } from 'ui/temp/ResourcePath'

import { MRContext } from '../../../context'
import { Accordeon } from '../../Accordeon'

import * as SC from './styles'

export const SalesSourcesTable: FC<{
  salesSourceItem: SalesSourceItem
  monthlyAnalyticsId: number
  bankAccountId: number
}> = memo(({ salesSourceItem, monthlyAnalyticsId }) => {
  const context = useContext(MRContext)

  return (
    <Accordeon
      opened={true}
      title={
        <>
          License&nbsp;
          <ResourcePath
            finishedPath={`/licenses/${salesSourceItem.licenseId}`}
            name={salesSourceItem.licenseNumber}
            id={salesSourceItem.licenseId}
          />
        </>
      }
      action={
        <ButtonGroup margin="small">
          {salesSourceItem.enableOverride === false && salesSourceItem.showOverrideButtonTooltip !== undefined && (
            <Tooltip id="edit" type="dark" content={salesSourceItem.showOverrideButtonTooltip}>
              <TextButton disabled>Add Manual Override</TextButton>
            </Tooltip>
          )}
          {salesSourceItem.enableOverride === false && salesSourceItem.showOverrideButtonTooltip === undefined && <></>}
          {salesSourceItem.enableOverride && (
            <Link
              to="/salesdata/bank-source-override/$mrId/$licenseId"
              params={{ mrId: monthlyAnalyticsId, licenseId: salesSourceItem.licenseId }}
            >
              <TextButton>Add Manual Override</TextButton>
            </Link>
          )}
          {salesSourceItem.enableSendTask && (
            <TextButton
              disabled={context?.isSourcesReloading}
              onClick={(e) => {
                e.stopPropagation()
                context?.sendTask(salesSourceItem.licenseId)
              }}
            >
              Send Task
            </TextButton>
          )}
        </ButtonGroup>
      }
      content={
        <>
          <Table<SalesSourceItemInfo>
            loading={context?.isSourcesReloading}
            dataQa={TableDataQa.BP_MONTHLY_ANALYTICS_BANK_ACCOUNT_SALES_SOURCE}
            columns={[
              {
                title: 'Source',
                sortable: false,
                align: 'left',
                width: '180px',
                render: (item) => (
                  <SC.WithIcons>
                    <ResourcePath
                      finishedPath={`${SALESDATA_PATH}/${item.sourceId}`}
                      name={item.sourceType}
                      id={item.sourceId}
                    />
                    {item.isGrouped && (
                      <Tooltip
                        content="Sales Data is commingled across licenses in the source, making it impossible
                          to attribute to a specific one."
                        id={item.sourceId + '_relation'}
                      >
                        <SC.GroupIcon name={IconName.RELATION} />
                      </Tooltip>
                    )}
                    {item.isPrimary && (
                      <Tooltip content="Active data source used in calculations." id={item.sourceId + '_star'}>
                        <SC.PrimaryIcon name={IconName.STAR} />
                      </Tooltip>
                    )}
                  </SC.WithIcons>
                )
              },
              {
                title: 'Total Sales',
                sortable: false,
                align: 'left',
                width: '160px',
                render: ({ totalSales, showAppliedTotalSales }) =>
                  showAppliedTotalSales !== undefined ? (
                    <>
                      <SC.DisabledPrice>{formatCurrencyCent(totalSales)}</SC.DisabledPrice>
                      <br />({formatCurrencyCent(showAppliedTotalSales)})
                    </>
                  ) : (
                    formatCurrencyCent(totalSales)
                  )
              },
              {
                title: 'Cannabis Sales',
                sortable: false,
                align: 'left',
                width: '160px',
                render: ({ cannabisSales, showAppliedCannabisSales }) =>
                  showAppliedCannabisSales !== undefined ? (
                    <>
                      <SC.DisabledPrice>{formatCurrencyCent(cannabisSales)}</SC.DisabledPrice>
                      <br />({formatCurrencyCent(showAppliedCannabisSales)})
                    </>
                  ) : (
                    formatCurrencyCent(cannabisSales)
                  )
              },
              {
                title: 'Non-Cannabis Sales',
                sortable: false,
                align: 'left',
                width: '160px',
                render: ({ nonCannabisSales, showAppliedNonCannabisSales }) =>
                  showAppliedNonCannabisSales !== undefined ? (
                    <>
                      <SC.DisabledPrice>{formatCurrencyCent(nonCannabisSales)}</SC.DisabledPrice>
                      <br />({formatCurrencyCent(showAppliedNonCannabisSales)})
                    </>
                  ) : (
                    formatCurrencyCent(nonCannabisSales)
                  )
              },
              {
                title: 'Estimated Sales Tax',
                sortable: false,
                align: 'left',
                width: '160px',
                render: ({ taxes, showAppliedTaxes }) =>
                  showAppliedTaxes !== undefined ? (
                    <>
                      <SC.DisabledPrice>{formatCurrencyCent(taxes)}</SC.DisabledPrice>
                      <br />({formatCurrencyCent(showAppliedTaxes)})
                    </>
                  ) : (
                    formatCurrencyCent(taxes)
                  )
              },
              {
                title: 'Status',
                key: 'status',
                sortable: false,
                align: 'left',
                width: '160px'
              },
              {
                title: '',
                sortable: false,
                align: 'left',
                render: (source: SalesSourceItemInfo) => (
                  <>
                    {source.showDangerAlertNotes !== undefined && (
                      <SC.Alert>
                        <SC.DangerAlertIcon name={IconName.ERROR} size={IconSize.S} /> {source.showDangerAlertNotes}
                      </SC.Alert>
                    )}
                    {source.showWarningAlertNotes !== undefined && (
                      <SC.Alert>
                        <SC.WarningAlertIcon name={IconName.INFO} size={IconSize.S} /> {source.showWarningAlertNotes}
                      </SC.Alert>
                    )}
                  </>
                )
              },
              {
                title: '',
                sortable: false,
                align: 'right',
                width: '200px',
                render: (source: SalesSourceItemInfo) => (
                  <ButtonGroup margin="small">
                    {source.approvalNeeded && (
                      <Link to="/salesdata/$sourceId" params={{ sourceId: source.sourceId }}>
                        <TableButton face="danger">View & Approve</TableButton>
                      </Link>
                    )}
                    {source.enableEdit === false && source.showEditButtonTooltip !== undefined && (
                      <Tooltip id="edit" type="dark" content={source.showEditButtonTooltip}>
                        <TableButton face="neutral" disabled>
                          Edit
                        </TableButton>
                      </Tooltip>
                    )}
                    {source.enableEdit === false && source.showEditButtonTooltip === undefined && <></>}
                    {source.enableEdit && (
                      <Link to="/salesdata/$sourceId/edit" params={{ sourceId: source.sourceId }}>
                        <TableButton face="brand">Edit</TableButton>
                      </Link>
                    )}
                    {source.enableDelete && (
                      <TableButton face="danger" onClick={() => context?.deleteBankSource(source.sourceId)}>
                        Delete
                      </TableButton>
                    )}
                    {source.showEnableCancelTask && (
                      <TableButton
                        disabled={context?.isSourcesReloading}
                        face="neutral"
                        onClick={() => context?.cancelTask(salesSourceItem.licenseId)}
                      >
                        Cancel Task
                      </TableButton>
                    )}
                  </ButtonGroup>
                )
              }
            ]}
            dataSource={salesSourceItem.sources.map((source) => ({ item: source }))}
          />
        </>
      }
    />
  )
})
