import { FC, memo, useMemo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils'
import { DateTime } from 'ui/components/DateTime'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Table } from 'ui/components/Table'
import { TextMask } from 'ui/components/TextMask'
import { CTRTransaction } from '~bank-bsa-reporting/types'

import { MaskWrapper } from './styles'

type Props = {
  transactions: CTRTransaction[]
  ctrId: number
  ctrName?: string
  onClose: () => void
}

export const Transactions: FC<Props> = memo((props) => {
  const { onClose, transactions, ctrId, ctrName } = props
  const dataSource = useMemo(() => (transactions || []).map((item) => ({ item })), [transactions])

  return ctrId ? (
    <Dialog
      id={`transactions-${ctrId}`}
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title="Transaction Details"
      subTitle={ctrName}
    >
      <Table
        dataQa={TableDataQa.BP_CTR_TRANSACTIONS}
        columns={
          [
            {
              title: 'ID',
              key: 'id',
              align: 'left'
            },
            {
              title: 'Teller',
              key: 'teller',
              align: 'center'
            },
            {
              title: 'Amount',
              align: 'center',
              render: ({ amount }) => formatCurrencyCent(amount)
            },
            {
              title: 'Type',
              key: 'type',
              align: 'center'
            },
            {
              title: 'Description',
              key: 'description',
              align: 'center'
            },
            {
              title: 'Primary Description',
              key: 'descriptionPrimary',
              align: 'center'
            },
            {
              title: 'Acct TIN',
              align: 'center',
              width: '150px',
              render: ({ acctTin }) => (
                <MaskWrapper>
                  <TextMask text={acctTin} />
                </MaskWrapper>
              )
            },
            {
              title: 'Date/Time',
              align: 'center',
              render: ({ postDatetime }) => <DateTime date={postDatetime} withTime />
            },
            {
              title: 'Status',
              key: 'status',
              align: 'center'
            },
            {
              title: 'CIF/ACCT #',
              align: 'center',
              width: '150px',
              render: ({ cifAcct }) => (
                <MaskWrapper>
                  <TextMask text={cifAcct} />
                </MaskWrapper>
              )
            },
            {
              title: 'Acct Avail.',
              align: 'center',
              render: ({ acctAvail }) => formatCurrencyCent(acctAvail)
            },
            {
              title: 'From CD',
              key: 'fromCd',
              align: 'center'
            },
            {
              title: 'To CD',
              key: 'toCd',
              align: 'center'
            },
            {
              title: 'Comment',
              key: 'comment',
              align: 'left'
            }
          ] as any
        }
        dataSource={dataSource}
      />
    </Dialog>
  ) : null
})
