import {
  LicenseDeprecated,
  LicenseManageRequest,
  LicenseWebPageRequest,
  LicenseWebPageResponse
} from 'commons/types/DTO/bank'
import { ApiReadResponse, ServerOptions } from 'commons/types/api'
import { Request } from 'commons/utils/request/request'

export const getLicenseList = (options?: ServerOptions): Promise<ApiReadResponse<LicenseDeprecated>> =>
  Request.post(Request.urls.bank.licenseList, options)

export const deleteLicense = (id: number) => Request.delete(Request.urls.bank.license, { id })

export const saveLicense = (license: LicenseManageRequest): Promise<LicenseManageRequest> => {
  const func = license.id ? Request.put : Request.post
  return func(Request.urls.bank.license, license)
}

export const getLicenseWebPage = (form?: LicenseWebPageRequest): Promise<LicenseWebPageResponse> =>
  Request.post(Request.urls.bank.licenseDetails, form)
