import { ReviewFormUploadWrap } from '../ReviewFormUploadWrap'
import { ArchiveReviewFormUploadProps } from './ArchiveReviewFormUploadProps'

export const ArchiveReviewFormUpload = ({ application, title }: ArchiveReviewFormUploadProps) => (
  <ReviewFormUploadWrap
    application={application}
    title={title}
    onlyNotes={false}
    requiredNotes={false}
    notes=""
    buttonName={title}
    type="Onboarding Documents Archive"
  />
)
