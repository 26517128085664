import { FC, ReactNode, createContext, useMemo, useState } from 'react'

import { CTRBatch } from '~bank-bsa-reporting/types/CTRBatches/CTRBatch'

export type CTRBatchesChosenItemsContextProps = {
  chosenItemsIdList?: number[]
  onToggleChooseItem?: (number) => void
  resetChosenItemsList?: () => void
  chooseSomeItems?: (id: CTRBatch[]) => void
}

export const CTRBatchesChosenItemsContext = createContext<CTRBatchesChosenItemsContextProps>({})

type BatchViewProps = {
  children: ReactNode
}

export const CTRBatchesChosenItemsContextProvider: FC<BatchViewProps> = ({ children }) => {
  const [chosenItemsIdList, setChosenItemsIdList] = useState<number[]>([])

  const onToggleChooseItem = (id: number) => {
    const indexInList = chosenItemsIdList.indexOf(id)

    if (chosenItemsIdList.includes(id)) {
      setChosenItemsIdList(([...prev]) => {
        prev.splice(indexInList, 1)
        return prev
      })
    } else {
      setChosenItemsIdList((prev) => [...prev, id])
    }
  }

  const chooseSomeItems = (idList: CTRBatch[]) => {
    const actList = [...chosenItemsIdList]
    idList.filter(({ id }) => {
      if (!chosenItemsIdList.includes(id)) actList.push(id)
    })
    setChosenItemsIdList(actList)
  }

  const resetChosenItemsList = () => setChosenItemsIdList([])

  const defaultProps = useMemo(
    () => ({
      chosenItemsIdList,
      onToggleChooseItem,
      resetChosenItemsList,
      chooseSomeItems
    }),
    [chosenItemsIdList, chooseSomeItems]
  )

  return <CTRBatchesChosenItemsContext.Provider value={defaultProps}>{children}</CTRBatchesChosenItemsContext.Provider>
}
