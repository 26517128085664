import {
  ApplicationClientUploadedDocuments,
  ApplicationDocuments
} from 'commons/types/DTO/bank/applications/manage/ClientUploadedDocuments'
import { ApplicationWebPageDetail } from 'commons/types/DTO/bank/applications/manage/webPage'
import {
  CheckMark,
  DocumentPeriodStatusChip,
  Link,
  MenuItemBehavior,
  MenuItemTextFace,
  TableActions,
  TableColumn,
  TableColumnAlign,
  Tooltip
} from 'ui/components'

import { Files } from './components/Files'

export const columns = (
  webPage: ApplicationWebPageDetail,
  applicationClientUploadedDocument: ApplicationClientUploadedDocuments,
  readonly: boolean,
  onDeleteDocument: (doc: { id: number; name: string }) => void
) =>
  [
    {
      title: 'Document Name',
      align: TableColumnAlign.LEFT,
      key: 'name',
      render: ({ id, name }) =>
        webPage.isViewableApplicationClientDocuments ? (
          <Link
            to={'/onboarding/applications/$applicationId/document/$documentId'}
            params={{ applicationId: webPage.applicationID, documentId: id }}
          >
            {name}
          </Link>
        ) : (
          <div>{name}</div>
        )
    },
    {
      title: 'Internal',
      align: TableColumnAlign.CENTER,
      key: 'internal',
      width: '100px',
      render: (item) =>
        item.internal && (
          <Tooltip id={`internal_${item.id}`} content="Not visible for an applicant" type="dark">
            <CheckMark value rounded />
          </Tooltip>
        )
    },
    {
      title: 'Status',
      align: TableColumnAlign.CENTER,
      key: 'status',
      width: '200px',
      render: (item) => (item.status ? <DocumentPeriodStatusChip name={item.status} /> : null)
    },
    {
      align: TableColumnAlign.CENTER,
      title: 'Files',
      key: 'id',
      width: '300px',
      render: (item) => {
        if (!applicationClientUploadedDocument.companyName || !item.name) {
          return <></>
        }
        return (
          applicationClientUploadedDocument.companyName &&
          item.name && (
            <Files
              files={item.files}
              companyName={applicationClientUploadedDocument.companyName}
              documentName={item.name}
            />
          )
        )
      }
    },
    readonly
      ? null
      : {
          title: 'Actions',
          key: 'id',
          align: TableColumnAlign.CENTER,
          width: '150px',
          render: (item) => (
            <TableActions
              menuContainerId={`internalDocumentsContainer${applicationClientUploadedDocument.companyUid}`}
              items={[
                ...(webPage.isDeletableApplicationClientDocuments
                  ? [
                      {
                        key: 1,
                        title: 'Delete Document',
                        behavior: MenuItemBehavior.BUTTON,
                        textFace: MenuItemTextFace.GREY,
                        onClick: () => onDeleteDocument({ id: item.id, name: item.name || '' })
                      }
                    ]
                  : [])
              ].filter(Boolean)}
            />
          )
        }
  ].filter(Boolean) as TableColumn<ApplicationDocuments>[]
