import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { MainText, SecondText } from '../../style'
import { ReviewTheApplicableStateCannabisProps } from './ReviewTheApplicableStateCannabisProps'

export const ReviewTheApplicableStateCannabis = ({ formValue, Field }: ReviewTheApplicableStateCannabisProps) => (
  <>
    <MainText>
      The business is unable to produce satisfactory documentation or evidence to demonstrate that it is duly licensed
      and operating consistently with state law.
      <br />
      The business, its owner(s), manager(s), or other related parties are, or have been, subject to an enforcement
      action by the state or local authorities responsible for administering or enforcing marijuana-related laws or
      regulations.
    </MainText>
    <SecondText>
      Review the state licensing website to check for business and owner license types which correspond with the most
      recent annual license review on file and have an “Active” status.
    </SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewTheApplicableStateCannabisRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewTheApplicableStateCannabisChangeInBusinessLicensing"
          label="Was there a change in business or owner/signer licensing?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'No changes',
                value: 'no_changes'
              },
              {
                label: 'Business Licensing Change – See Comments',
                value: 'business_changes_in_licensing'
              },
              {
                label: 'Owner/Signer Licensing Change – See Comments',
                value: 'owner_changes_in_licensing'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={
            formValue.reviewTheApplicableStateCannabisChangeInBusinessLicensing === 'changes_in_licensing' ||
            formValue.reviewTheApplicableStateCannabisChangeInOwnerLicensing === 'see_comments'
          }
          name="reviewTheApplicableStateCannabisComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.reviewTheApplicableStateCannabisChangeInBusinessLicensing === 'changes_in_licensing' ||
            formValue.reviewTheApplicableStateCannabisChangeInOwnerLicensing === 'see_comments'
              ? Validators.required.field
              : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
