import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const Identification = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="suspiciousActivityInfo.identification"
      children={(field) => (
        <FF
          field={field}
          label="37. Identification/Documentation"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'37. Identification/Documentation'}
              direction={'vertical'}
              data={[
                { label: 'a. Changes spelling or arrangement of name', value: 'a' },
                { label: 'b. Multiple individuals with same or similar identities', value: 'b' },
                { label: 'c. Provided questionable or false documentation', value: 'c' },
                { label: 'd. Provided questionable or false identification', value: 'd' },
                { label: 'e. Refused or avoided request for documentations', value: 'e' },
                { label: 'f. Single individual with multiple identities', value: 'f' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
