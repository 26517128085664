import { memo } from 'react'

import { BaseInput } from './BaseInput'
import { BaseInputProps } from './props'

interface TextInputProps extends Omit<BaseInputProps, 'value' | 'onChange'> {
  value?: string
  onChange?: (v: string, e?: string) => void
}

export const TextInput = memo((props: TextInputProps) => {
  return <BaseInput {...props} type="text" />
})
