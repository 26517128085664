import { FC, memo } from 'react'

import { Dialog, DialogFace, IconName } from 'ui/components'
import { OutlineButton } from 'ui/components/Buttons'

import { DocumentsTable } from '../DocumentsTable'
import { UploadButton } from '../UploadButton'
import { DocumentNameDialogProps } from './DocumentNameDialogProps'

export const DocumentNameDialog: FC<DocumentNameDialogProps> = memo((props) => {
  const {
    companyName,
    documentName,
    visible,
    onClose,
    files,
    readonly,
    periodId,
    onFilesAdd,
    isLoading,
    isDeleting,
    onDelete
  } = props

  if (!visible) {
    return null
  }
  return (
    <Dialog
      id="docs"
      face={DialogFace.PRIMARY}
      headerStyle={{ width: 720 }}
      headerActions={
        !readonly && (
          <UploadButton periodId={periodId} onFilesAdd={onFilesAdd}>
            <OutlineButton face="brand" icon={IconName.UPLOAD}>
              Upload
            </OutlineButton>
          </UploadButton>
        )
      }
      title={companyName}
      subTitle={documentName}
      onClose={onClose}
    >
      <DocumentsTable
        readonly={readonly}
        files={files}
        isLoading={isLoading}
        isDeleting={isDeleting}
        onDelete={onDelete}
      />
    </Dialog>
  )
})
