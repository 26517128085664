import { getRouteApi, useRouter } from '@tanstack/react-router'
import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import {
  DateTime,
  DocumentPeriodStatusChip,
  Hr,
  Info,
  InfoSet,
  InfoSetDirection,
  Layer,
  Link,
  Page,
  PageFace,
  PageSection,
  PageSectionFace,
  Table
} from 'ui/components'
import { BPLayout } from 'ui/components/BPLayout'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { FileSelector } from 'ui/components/FileSelector'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import { columns } from './columns'
import { AcceptDialog, ManageFileDialog, RejectDialog } from './components'
import { useAccept, useFilesActions, useReject } from './hooks'
import { ApplicationDocumentWebpage, Files } from './types'

import * as SC from './styles'

const routeApi = getRouteApi('/onboarding/applications/$applicationId/document/$documentId')

const ApplicationDocument = () => {
  const navigate = routeApi.useNavigate()
  const router = useRouter()

  // @todo why type not working, look at this when we get worked router types
  const {
    webPage,
    applicationId,
    documentId
  }: { webPage: ApplicationDocumentWebpage; applicationId: number; documentId: number } = routeApi.useLoaderData()
  const { historyPage, historySize } = routeApi.useSearch()

  const { isShowingAcceptDialog, isAccepting, closeAcceptDialog, openAcceptDialog, accept } = useAccept(
    documentId,
    router.invalidate
  )
  const { isShowingRejectDialog, isRejecting, closeRejectDialog, openRejectDialog, reject } = useReject(
    documentId,
    router.invalidate
  )
  const {
    isUploading,
    isDeleting,
    isUpdating,
    updateFile,
    uploadFiles,
    startDeleting,
    DeleteConfirmation,
    isShowingManageDialog,
    setIsShowingManageDialog,
    startUpdating,
    fileToManage
  } = useFilesActions(documentId, router.invalidate)

  const isReloading = isUploading || isAccepting || isRejecting || isDeleting || isUpdating

  return (
    <BPLayout>
      <Page face={PageFace.SECONDARY} title="Document" subTitle={webPage.name}>
        <PageSection
          face={PageSectionFace.SECONDARY}
          title="Document Information"
          actions={
            <ButtonGroup margin="small">
              {webPage.canAccept && (
                <Button face="positive" onClick={openAcceptDialog}>
                  Accept
                </Button>
              )}
              {webPage.canReject && (
                <Button face="positive" onClick={openRejectDialog}>
                  Reject
                </Button>
              )}
              <Link
                to={`/onboarding/applications/$applicationId/document/$documentId/edit`}
                params={{ applicationId, documentId }}
              >
                <Button face="positive">Edit</Button>
              </Link>
            </ButtonGroup>
          }
        >
          <Hr />
          <InfoSet legend="Client Document Details" direction={InfoSetDirection.ROW}>
            <Row>
              <Col>
                <Info label="Document Name">{webPage.name}</Info>
              </Col>
              <Col>
                <Info label="Status">
                  <DocumentPeriodStatusChip name={webPage.status} />
                </Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="Subject Type">{webPage.subject.type}</Info>
              </Col>
              <Col>
                <Info label={webPage.subject.type}>{webPage.subject.name}</Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="Last Modified Date">
                  <DateTime withTime date={webPage.lastModifiedDate} />
                </Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="Internal">
                  <SC.InternalCheckMark rounded value={webPage.internal} />
                </Info>
              </Col>
              <Col>
                <Info label="Optional">
                  <SC.InternalCheckMark rounded value={webPage.optional} />
                </Info>
              </Col>
            </Row>
            <Row>
              <Col2x>
                <Info label="Notes">{webPage.notes}</Info>
              </Col2x>
            </Row>
          </InfoSet>
        </PageSection>

        <PageSection
          face={PageSectionFace.SECONDARY}
          title="Files"
          actions={<FileSelector onFilesSelect={uploadFiles} mode="single-select" />}
        >
          <Layer rounded shadowed>
            <Table<Files>
              dataQa={TableDataQa.BP_DOCUMENT_PERIOD_FILES}
              columns={columns(startDeleting, startUpdating)}
              dataSource={webPage.files.map((el) => ({ item: el }))}
              loading={isReloading}
            />
          </Layer>
        </PageSection>

        <PageSection title="Document History">
          <Layer rounded shadowed stretch>
            <HistoryTable
              dataQa={TableDataQa.BP_DOCUMENT_HISTORY}
              loading={false}
              items={webPage.history}
              pagination={{
                page: historyPage,
                pageSize: historySize,
                onChange: (page: number, size: number) =>
                  navigate({
                    to: '/onboarding/applications/$applicationId/document/$documentId',
                    params: { applicationId, documentId },
                    search: { historyPage: page, historySize: size }
                  }),
                total: webPage.historyTotalCount
              }}
            />
          </Layer>
        </PageSection>
      </Page>

      {isShowingAcceptDialog && (
        <AcceptDialog isAccepting={isAccepting} notes={webPage.notes} onClose={closeAcceptDialog} onSubmit={accept} />
      )}

      {isShowingRejectDialog && (
        <RejectDialog isRejecting={isRejecting} notes={webPage.notes} onClose={closeRejectDialog} onSubmit={reject} />
      )}

      {isShowingManageDialog && (
        <ManageFileDialog
          onClose={() => setIsShowingManageDialog(false)}
          isUpdating={isUpdating}
          onUpdate={updateFile}
          fileToManage={fileToManage}
        />
      )}

      <DeleteConfirmation />
    </BPLayout>
  )
}

export default ApplicationDocument
