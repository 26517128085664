import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

export const State = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noBranchInvolved = formApi.useStore((s) => s.values.institutionActivitiesInfo[index1].noBranchInvolved)
  const country = formApi.useStore(
    (s) => s.values.institutionActivitiesInfo[index1].institutionActivityBranchesInfo[index2].country
  )

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index1 as 0}].institutionActivityBranchesInfo[${index2 as 0}].state`}
      children={(field) => (
        <FF
          field={field}
          label="72. State"
          input={(p) => (
            <Select
              {...p}
              data={usStates}
              emptyOption={{ label: 'Not selected', value: '' }}
              disabled={noBranchInvolved || country !== 'US'}
              data-qa="72. State"
              options-data-qa="72. State option"
            />
          )}
        ></FF>
      )}
    />
  )
}
