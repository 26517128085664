import { memo, useLayoutEffect, useRef } from 'react'

import * as SC from '../styles'
import { IconName, IconSize, Popper } from 'ui/components'
import { InputHint, Required } from 'ui/core'

import {
  HEIGHT,
  MAX_OPTIONS_VISIBLE,
  SELECT_PLACEHOLDER,
  SELECT_PLACEHOLDER_ADD_OPTIONS,
  SELECT_PLACEHOLDER_SEARCHABLE
} from '../constants'
import { useSelect } from '../hooks/useSelect'
import { MultiSelectProps } from '../types'
import { Option } from './Option'

export const MultiSelect = memo((props: MultiSelectProps) => {
  const {
    searchable = false,
    value,
    onChange,
    required = false,
    error,
    // TODO: default data cause infinite useEffect, make check that we can pass only data or serveroptions
    data,
    serverOptions,
    placeholder,
    disabled = false,
    hint,
    hideHint,
    'data-qa': dataQa,
    ...rest
  } = props
  const listRef = useRef<(HTMLDivElement | null)[]>([])
  const inputRef = useRef(null)
  const initRender = useRef(true)

  const {
    open,
    modelData: items,
    count,
    isSearching,
    isShowRequired,
    isEmpty,
    selectedValues,
    handleClose,
    handleOpen,
    selectValue,
    unselectValue,
    inputValue,
    getReferenceProps,
    getOptionProps,
    getInputProps
  } = useSelect({
    selectedValues: value ?? [],
    open: false,
    data,
    required,
    disabled,
    error: !!error,
    listRef,
    inputRef,
    serverOptions,
    mode: 'list',
    searchable
  })

  useLayoutEffect(() => {
    if (initRender.current) {
      initRender.current = false
      return
    }
    if (onChange) {
      onChange(selectedValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues])

  const renderOptions = () => (
    <>
      {items.map((item, index) => (
        <Option
          key={index}
          ref={(node) => (listRef.current[index] = node)}
          label={item.data.label}
          value={item.data.value}
          disabled={item.data.disabled}
          title={item.data.title}
          selected={item.selected}
          active={item.active}
          onClick={() => (item.selected ? unselectValue(item.data.value) : selectValue(item.data.value))}
          data-qa={dataQa ? `${dataQa} - ${item.data.label}` : undefined}
          {...getOptionProps()}
        />
      ))}
    </>
  )

  const showSpinner = isSearching && open
  let resultedPlaceholder = placeholder

  if (!placeholder) {
    if (searchable) {
      resultedPlaceholder = SELECT_PLACEHOLDER_SEARCHABLE
    } else {
      if (count > 0) {
        resultedPlaceholder = SELECT_PLACEHOLDER_ADD_OPTIONS
      } else {
        resultedPlaceholder = SELECT_PLACEHOLDER
      }
    }
  }

  return (
    <>
      <Popper
        listRef={listRef}
        opened={open}
        onOpen={handleOpen}
        onClose={handleClose}
        scrollClose={true}
        autoSize={true}
        autoSizeWidth={'fill'}
        autoSizeMaxHeight={HEIGHT * MAX_OPTIONS_VISIBLE}
        autoSizeMinHeight={HEIGHT}
        placement="bottom-start"
        style={SC.PopperStyles(items.length === 0)}
        disabled={disabled}
        render={() => (
          <SC.DropDown data-testid="dropdown" $column={true}>
            {renderOptions()}
          </SC.DropDown>
        )}
      >
        <SC.ReferenceOuter
          {...rest}
          role="listbox"
          $isEmpty={isEmpty}
          $error={!!error}
          disabled={disabled}
          {...getReferenceProps()}
        >
          <Required data-testid="required" isInputEmpty={isShowRequired} />
          <SC.InputForMultiSelect
            {...getInputProps()}
            $opened={open}
            value={inputValue}
            ref={inputRef}
            type="text"
            data-qa={dataQa}
            data-testid="search-value"
            placeholder={resultedPlaceholder}
            readOnly={!open || !searchable}
            autoComplete="off"
            disabled={disabled}
          />
          {showSpinner && <SC.Spinner size={22} durationInSeconds={1.0} />}
          {!showSpinner && count > 0 && (
            <SC.Counter $error={!!error} data-testid="counter">
              {count}
            </SC.Counter>
          )}
          <SC.WrappedIcon name={IconName.DOWN} size={IconSize.XS} $open={open} />
        </SC.ReferenceOuter>
      </Popper>
      <InputHint data-testid="hint" hint={hint} disabled={disabled} hideHint={hideHint} />
      {selectedValues.length > 0 && (
        <SC.SelectedRows data-testid="selected-chips">
          {items.map(
            (el, index) =>
              el.selected && (
                <SC.SelectedRow key={index} $disabled={disabled} onClick={() => unselectValue(el.data.value)}>
                  <SC.DeleteIcon name={IconName.CLEAR_THIN} size={12} />
                  {el.data.label}
                </SC.SelectedRow>
              )
          )}
        </SC.SelectedRows>
      )}
    </>
  )
})
