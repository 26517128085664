import { memo, useMemo } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { Search } from 'ui/components'
import { Layer } from 'ui/components/Layer'
import { Page, PageFace } from 'ui/components/Page'
import { AdverseMediaListResponse } from '~bank-adverse-media/types'

import { AdverseMediaListTable } from './components/AdverseMediaListTable'
import { Filter } from './components/Filter'

const routeApi = getRouteApi('/adverse-media/')

const List = memo(() => {
  const navigate = routeApi.useNavigate()

  const data = routeApi.useLoaderData() as AdverseMediaListResponse
  const searchCase = routeApi.useSearch({ select: (s) => s.searchCase })

  const isFetching = useMemo(() => !data, [data])

  return (
    <Page
      face={PageFace.PRIMARY}
      title="Adverse Media"
      isPending={isFetching}
      actions={
        !!data && (
          <Search
            onSearch={(value) =>
              navigate({ to: '.', search: (prev) => ({ ...prev, page: 1, searchCase: value }), resetScroll: false })
            }
            value={searchCase}
            placeholder="Search"
          />
        )
      }
    >
      {!!data && (
        <Layer rounded shadowed>
          <Filter />
          <AdverseMediaListTable />
        </Layer>
      )}
    </Page>
  )
})

export default List
