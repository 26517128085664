import { FC, memo, useCallback, useState } from 'react'

import { Form } from 'brief-form'
import { Select, Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { EmailInput, TextInput, TextareaInput, USPhoneInput } from 'ui/components/InputV2'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { HiddenBox } from '../../components/HiddenBox'
import { OnboardingFormFieldNames } from '../../enums'
import { ContactDetailsFormProps } from './ContactDetailsFormProps'
import { getDataForCopyFromContactSelect } from './getDataForCopyFromContactSelect'
import { useContactDetailsForm } from './useContactDetailsForm'

import * as SC from './styles'

export const ContactDetailsForm: FC<ContactDetailsFormProps> = memo(
  ({
    value,
    contactIndex,
    companyIndex,
    companyContactsEmailDict,
    state: { isMobileEnable, isHomePhoneEnable, isOtherMjRelatedBusinessEnable, isBioEnable },

    contactsList,
    onChange,
    onContactCopy
  }) => {
    const { Field, config } = useContactDetailsForm({ companyIndex, contactIndex, formData: value, onChange })
    const [copySelectValue, setCopySelectValue] = useState<string | undefined>()

    const handlerContactCopySelected = useCallback(
      (selectOnChangeValue) => {
        setCopySelectValue(selectOnChangeValue)
        const [fromCompanyIndex, fromContactIndex] = selectOnChangeValue.split('-')
        onContactCopy({
          toCompanyIndex: companyIndex,
          toContactIndex: +contactIndex,
          fromCompanyIndex: +fromCompanyIndex,
          fromContactIndex: +fromContactIndex
        })
      },
      [onContactCopy, companyIndex, contactIndex]
    )
    const contactsSelectData = [
      { label: 'Choose contact...', value: '-1' },
      ...getDataForCopyFromContactSelect({
        companyIndex,
        contactsList,
        companyContactsEmailDict,
        currentContactEmail: config.value.email
      })
    ]

    return (
      <Form config={config}>
        <HiddenBox>
          <Field
            name={OnboardingFormFieldNames.CONTACT_ID}
            label={OnboardingFormFieldNames.CONTACT_ID}
            input={TextInput}
            inputProps={{
              'data-qa': OnboardingFormFieldNames.CONTACT_ID
            }}
          />
          <Field
            name={OnboardingFormFieldNames.CONTACT_METADATA_ID}
            label={OnboardingFormFieldNames.CONTACT_METADATA_ID}
            input={TextInput}
            inputProps={{
              'data-qa': OnboardingFormFieldNames.CONTACT_METADATA_ID
            }}
          />
        </HiddenBox>
        <InfoSet legend="Contact Details">
          {companyIndex > 0 && (
            <FormRow>
              <Col>
                <SC.SelectLabel>Copy information from existing contact?</SC.SelectLabel>
                <Select
                  value={copySelectValue}
                  data-qa={'Copy information from existing contact?'}
                  options-data-qa={'Copy information from existing contact?'}
                  data={contactsSelectData}
                  onChange={handlerContactCopySelected}
                />
              </Col>
            </FormRow>
          )}

          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.FIRST_NAME}
                label="First Name"
                input={TextInput}
                inputProps={{
                  'data-qa': 'First Name',
                  maxLength: 40
                }}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.LAST_NAME}
                label="Last Name or Entity Name"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Last Name or Entity Name',
                  maxLength: 80
                }}
                required
                validator={Validators.required.field}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.EMAIL}
                label="Email"
                input={EmailInput}
                required
                validator={Validators.required.field}
                inputProps={{
                  'data-qa': 'Email',
                  maxLength: 80
                }}
              />
            </Col>
            <Col>
              <Field
                name={OnboardingFormFieldNames.PHONE}
                label="Phone"
                input={USPhoneInput}
                inputProps={{
                  'data-qa': 'Phone'
                }}
              />
            </Col>
          </FormRow>
          {(isMobileEnable || isHomePhoneEnable) && (
            <FormRow>
              {isMobileEnable && (
                <Col>
                  <Field
                    name={OnboardingFormFieldNames.MOBILE}
                    label="Cell Phone"
                    input={TextInput}
                    inputProps={{
                      'data-qa': 'Cell Phone',
                      maxLength: 40
                    }}
                  />
                </Col>
              )}
              {isHomePhoneEnable && (
                <Col>
                  <Field
                    name={OnboardingFormFieldNames.HOME_PHONE}
                    label="Home Phone"
                    input={TextInput}
                    inputProps={{
                      'data-qa': 'Home Phone'
                    }}
                  />
                </Col>
              )}
            </FormRow>
          )}
          <FormRow>
            <Col>
              <Field
                name={OnboardingFormFieldNames.TITLE}
                label="Role/Position"
                input={TextInput}
                inputProps={{
                  'data-qa': 'Role/Position',
                  maxLength: 255
                }}
              />
            </Col>
          </FormRow>
          {isOtherMjRelatedBusinessEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.OTHER_MJ_RELATED_BUSINESS}
                  label="Disclosure of other Marijuana Related Business ownership interests"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Disclosure of other Marijuana Related Business ownership interests'
                  }}
                />
              </Col2x>
            </FormRow>
          )}
          {isBioEnable && (
            <FormRow>
              <Col2x>
                <Field
                  name={OnboardingFormFieldNames.BIO}
                  label="Bio"
                  input={TextareaInput}
                  inputProps={{
                    'data-qa': 'Bio'
                  }}
                />
              </Col2x>
            </FormRow>
          )}
        </InfoSet>
      </Form>
    )
  }
)
