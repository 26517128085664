import { FC, memo, useCallback, useEffect } from 'react'
import Dropzone from 'react-dropzone'

import { Button } from '../Buttons'
import { FileSelectorProps } from './FileSelectorProps'
import { FileSelectorDialog } from './components/FileSelectorDialog'
import { useComponentEssence } from './hooks'

export const FileSelector: FC<FileSelectorProps> = memo((props) => {
  const { open, openDialog, closeDialog, setLocalFiles, localFiles } = useComponentEssence()
  const {
    children,
    dialogProps,
    mode = 'single-select',
    onFilesSelect,
    showDialogAfterConfirm,
    showSpinner,
    filesWasLoaded
  } = props
  const { title = 'File Management', submitButtonText = 'Submit' } = dialogProps || {}

  const onUploadWrapper = useCallback(
    (files: File[]) => {
      if (mode === 'single-select') {
        onFilesSelect(files.slice(0, 1))
      } else if (mode === 'multi-select') {
        onFilesSelect(files)
      } else {
        openDialog()
        setLocalFiles(files)
      }
    },
    [onFilesSelect, mode, openDialog, setLocalFiles]
  )

  useEffect(() => {
    if (filesWasLoaded) {
      closeDialog()
    }
  }, [closeDialog, filesWasLoaded])

  return (
    <>
      <Dropzone onDrop={onUploadWrapper} multiple={mode !== 'single-select'}>
        {({ getRootProps, getInputProps }) => (
          <span {...getRootProps()}>
            {children ? (
              <>
                {children}
                <input {...getInputProps()} />
              </>
            ) : (
              <Button face="positive">
                <input {...getInputProps()} />
                Upload
              </Button>
            )}
          </span>
        )}
      </Dropzone>
      {mode === 'multi-select-with-dialog' && open && !!localFiles.length && (
        <FileSelectorDialog
          title={title}
          onClose={closeDialog}
          initialFiles={localFiles}
          submitButtonText={submitButtonText}
          onSubmit={onFilesSelect}
          showDialogAfterConfirm={showDialogAfterConfirm}
          showSpinner={showSpinner}
        />
      )}
    </>
  )
})
