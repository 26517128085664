import { useEffect } from 'react'

import throttle from 'lodash/throttle'

export const useViewPortUpdateListener = (handler: () => void, shouldSubscribe: boolean, selectors?: string[]) => {
  const throttledHandler = throttle(handler, 250)

  useEffect(() => {
    const heliosPageContent = document.querySelector('main[data-helios-page]')

    // Window.
    if (shouldSubscribe) {
      window.addEventListener('scroll', throttledHandler)
      window.addEventListener('resize', throttledHandler)
    } else {
      window.removeEventListener('scroll', throttledHandler)
      window.removeEventListener('resize', throttledHandler)
    }

    // BP page content.
    if (heliosPageContent && shouldSubscribe) {
      heliosPageContent.addEventListener('scroll', throttledHandler)
      heliosPageContent.addEventListener('resize', throttledHandler)
    } else if (heliosPageContent && !shouldSubscribe) {
      heliosPageContent.removeEventListener('scroll', throttledHandler)
      heliosPageContent.removeEventListener('resize', throttledHandler)
    }

    // Optional selectors.
    if (selectors && selectors.length) {
      selectors.forEach((node) => {
        const item = document.querySelector(node)

        if (item && shouldSubscribe) {
          item.addEventListener('scroll', throttledHandler)
          item.addEventListener('resize', throttledHandler)
        } else if (item && !shouldSubscribe) {
          item.removeEventListener('scroll', throttledHandler)
          item.removeEventListener('resize', throttledHandler)
        }
      })
    }

    // Unsubscribe.
    return () => {
      window.removeEventListener('scroll', throttledHandler)
      window.removeEventListener('resize', throttledHandler)

      if (heliosPageContent) {
        heliosPageContent.removeEventListener('scroll', throttledHandler)
        heliosPageContent.removeEventListener('resize', throttledHandler)
      }

      if (selectors && selectors.length) {
        selectors.forEach((node) => {
          const item = document.querySelector(node)

          if (item) {
            item.removeEventListener('scroll', throttledHandler)
            item.removeEventListener('resize', throttledHandler)
          }
        })
      }
    }
  }, [throttledHandler])
}
