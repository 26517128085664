import { FC } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Col2x, FormRow } from 'ui/themes/globalStyles'

import { SecondText } from '../../style'

type ReviewCommentsProps = {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, Questionnaire>
  ) => JSX.Element
}

export const ReviewComments: FC<ReviewCommentsProps> = ({ Field }) => (
  <>
    <SecondText>
      <i>Review Comments:</i>
    </SecondText>
    <FormRow>
      <Col2x>
        <Field
          required
          name="reviewCommentsNotes"
          label=""
          input={TextareaInput}
          inputProps={{}}
          validator={Validators.required.field}
        />
      </Col2x>
    </FormRow>
  </>
)
