import { FC, memo } from 'react'
import Dropzone from 'react-dropzone'

import { HeaderButton } from 'ui/components/Buttons'

import { useUpload } from './hooks/useUpload'

export const UploadButton: FC = memo(() => {
  const { InvoiceFileUploadConfirmation, fileInputChangeHandler } = useUpload()

  return (
    <>
      <InvoiceFileUploadConfirmation />
      <Dropzone onDrop={fileInputChangeHandler}>
        {({ getRootProps, getInputProps }) => (
          <HeaderButton {...getRootProps()}>
            Upload
            <input {...getInputProps()} />
          </HeaderButton>
        )}
      </Dropzone>
    </>
  )
})
