import { FormInputProps } from 'brief-form'

import { BaseInputOpts } from '../props'

type CurrencyCentInputOpts = BaseInputOpts & {
  allowDecimal?: boolean
  defaultEmptyValue?: null | ''
}

export type CurrencyCentInputProps = FormInputProps<number | null | undefined, CurrencyCentInputOpts>
