import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const LossToFinInstitution = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `institutionActivitiesInfo[${index}].lossToFinInstitution` as 'institutionActivitiesInfo[0].lossToFinInstitution'
      }
      validators={{
        onChange: ({ value }) => Validators.required.notEmpty(value)
      }}
      children={(field) => (
        <FF
          required
          label="67. Loss to financial institution"
          field={field}
          input={(p) => <CurrencyCentInput {...p} />}
        />
      )}
    />
  )
}
