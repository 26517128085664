import { FC, Fragment, memo } from 'react'

import * as SC from '../styles'
import { formatCurrency } from 'commons/utils'
import { CheckBox } from 'ui/components/CheckBox'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import { SuspiciousActivityInformationProps } from './SuspiciousActivityInformation.types'

export const SuspiciousActivityInformation: FC<SuspiciousActivityInformationProps> = memo((props) => {
  const { data } = props
  const {
    generalInfo: { transactionStartDate, transactionEndDate },
    suspiciousActivityInfo: {
      amountUnknown,
      noAmountInvolved,
      currentAmount,
      currentCumulativeAmount,
      structuring,
      showStructuringOther,
      terroristFinancing,
      showTerroristFinancingOther,
      fraud,
      showFraudOther,
      laundering,
      showLaunderingOther,
      identification,
      showIdentificationOther,
      otherActivities,
      showOtherActivitiesOther,
      productTypes,
      showProductTypesOther,
      paymentMechanisms,
      showPaymentMechanismsOther,
      ipAddresses,
      cyberEventIndicators,
      commodityTypes,
      productInstrumentDesc,
      marketsTraded,
      cusipNumbers,
      gaming,
      showGamingOther,
      showCyberOther,
      insurance,
      showInsuranceOther,
      mortgage,
      showMortgageOther,
      showSecuritiesOther,
      securities
    }
  } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 4. Suspicious Activity Information" anchor="step4">
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <Row>
          <Col>
            <Info label="29. Amount involved in this report">
              {amountUnknown && 'Amount Unknown'}
              {noAmountInvolved && 'No Amount Involved'}
              {!amountUnknown && !noAmountInvolved && formatCurrency(currentAmount)}
            </Info>
          </Col>
          <Col>
            <Info label="30. Date or date range of suspicious activity for this report">
              {`${transactionStartDate} - ${transactionEndDate}`}
            </Info>
          </Col>
        </Row>
        <Row>
          <Info label="31. Current Cumulative">{formatCurrency(currentCumulativeAmount)}</Info>
        </Row>
        <Row>
          <Info label="32. Structuring">
            <CheckBox
              value={structuring.includes('a')}
              checkBoxLabel="a. Alters or cancels transaction to avoid BSA record keeping requirement"
            />
            <CheckBox
              value={structuring.includes('b')}
              checkBoxLabel="b. Alters or cancels transaction to avoid CTR requirement"
            />
            <CheckBox value={structuring.includes('c')} checkBoxLabel="c. Suspicious inquiry by customer" />
            <CheckBox
              value={structuring.includes('d')}
              checkBoxLabel="d. Transaction(s) below BSA record keeping threshold"
            />
            <CheckBox value={structuring.includes('e')} checkBoxLabel="e. Transaction(s) below CTR threshold" />
            <CheckBox value={structuring.includes('z')} checkBoxLabel="z. Other" />
            {showStructuringOther && <SC.Label>{showStructuringOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info label="33. Terrorist Financing">
            <CheckBox
              value={terroristFinancing.includes('a')}
              checkBoxLabel="a. Known or suspected terrorist/terrorist organization"
            />
            <CheckBox value={terroristFinancing.includes('z')} checkBoxLabel="z. Other" />
            {showTerroristFinancingOther && <SC.Label>{showTerroristFinancingOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Col2x>
            <Info label="34. Fraud">
              <SC.CheckBoxes>
                <CheckBox value={fraud.includes('a')} checkBoxLabel="a. ACH" />
                <CheckBox value={fraud.includes('h')} checkBoxLabel="h. Mail" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={fraud.includes('b')} checkBoxLabel="b. Advance fee" />
                <CheckBox value={fraud.includes('i')} checkBoxLabel="i. Mass-marketing" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={fraud.includes('c')} checkBoxLabel="c. Business loan" />
                <CheckBox value={fraud.includes('j')} checkBoxLabel="j. Ponzi scheme" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={fraud.includes('d')} checkBoxLabel="d. Check" />
                <CheckBox value={fraud.includes('k')} checkBoxLabel="k. Pyramid scheme" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={fraud.includes('e')} checkBoxLabel="e. Consumer loan" />
                <CheckBox value={fraud.includes('l')} checkBoxLabel="l. Securities fraud" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={fraud.includes('f')} checkBoxLabel="f. Credit/Debit card" />
                <CheckBox value={fraud.includes('m')} checkBoxLabel="m. Wire" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox
                  value={fraud.includes('g')}
                  checkBoxLabel="g. Healthcare/Public or private health insurance"
                />
                <CheckBox value={fraud.includes('z')} checkBoxLabel="z. Other" />
              </SC.CheckBoxes>
              {showFraudOther && <SC.Label>{showFraudOther}</SC.Label>}
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info label="35. Gaming activities">
              <CheckBox value={gaming.includes('a')} checkBoxLabel="a. Chip walking" />
              <CheckBox value={gaming.includes('b')} checkBoxLabel="b. Minimal gaming with large transactions" />
              <CheckBox value={gaming.includes('c')} checkBoxLabel="c. Suspicious use of counter checks or markers" />
              <CheckBox value={gaming.includes('d')} checkBoxLabel="d. Unknown source of chips" />
              <CheckBox value={gaming.includes('z')} checkBoxLabel="z. Other" />
              {showGamingOther && <SC.Label>{showGamingOther}</SC.Label>}
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Info label="36. Money Laundering">
            <CheckBox
              value={laundering.includes('a')}
              checkBoxLabel="a. Exchange small bills for large bills or vice versa"
            />
            <CheckBox value={laundering.includes('b')} checkBoxLabel="b. Funnel account" />
            <CheckBox
              value={laundering.includes('c')}
              checkBoxLabel="c. Suspicion concerning the physical condition of funds"
            />
            <CheckBox value={laundering.includes('d')} checkBoxLabel="d. Suspicion concerning the source of funds" />
            <CheckBox
              value={laundering.includes('e')}
              checkBoxLabel="e. Suspicious designation of beneficiaries, assignees or joint owners"
            />
            <CheckBox value={laundering.includes('f')} checkBoxLabel="f. Suspicious EFT/wire transfers" />
            <CheckBox value={laundering.includes('g')} checkBoxLabel="g. Suspicious exchange of currencies" />
            <CheckBox
              value={laundering.includes('h')}
              checkBoxLabel="h. Suspicious receipt of government payments/benefits"
            />
            <CheckBox value={laundering.includes('i')} checkBoxLabel="i. Suspicious use of multiple accounts" />
            <CheckBox
              value={laundering.includes('j')}
              checkBoxLabel="j. Suspicious use of noncash monetary instruments"
            />
            <CheckBox
              value={laundering.includes('k')}
              checkBoxLabel="k. Suspicious use of third-party transactors (straw-man)"
            />
            <CheckBox
              value={laundering.includes('l')}
              checkBoxLabel="l. Trade-Based Money Laundering/Black Market Peso Exchange"
            />
            <CheckBox value={laundering.includes('m')} checkBoxLabel="m. Transaction out of pattern for customer(s)" />
            <CheckBox value={laundering.includes('z')} checkBoxLabel="z. Other" />
            {showLaunderingOther && <SC.Label>{showLaunderingOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info label="37. Identification/Documentation">
            <CheckBox value={identification.includes('a')} checkBoxLabel="a. Changes spelling or arrangement of name" />
            <CheckBox
              value={identification.includes('b')}
              checkBoxLabel="b. Multiple individuals with same or similar identities"
            />
            <CheckBox
              value={identification.includes('c')}
              checkBoxLabel="c. Provided questionable or false documentation"
            />
            <CheckBox
              value={identification.includes('d')}
              checkBoxLabel="d. Provided questionable or false identification"
            />
            <CheckBox
              value={identification.includes('e')}
              checkBoxLabel="e. Refused or avoided request for documentation"
            />
            <CheckBox
              value={identification.includes('f')}
              checkBoxLabel="f. Single individual with multiple identities"
            />
            <CheckBox value={identification.includes('z')} checkBoxLabel="z. Other" />
            {showIdentificationOther && <SC.Label>{showIdentificationOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info label="38. Other Suspicious Activities">
            <CheckBox value={otherActivities.includes('a')} checkBoxLabel="a. Account takeover" />
            <CheckBox value={otherActivities.includes('b')} checkBoxLabel="b. Bribery or gratuity" />
            <CheckBox value={otherActivities.includes('c')} checkBoxLabel="c. Counterfeit instruments" />
            <CheckBox value={otherActivities.includes('d')} checkBoxLabel="d. Elder financial exploitation" />
            <CheckBox
              value={otherActivities.includes('e')}
              checkBoxLabel="e. Embezzlement/theft/disappearance of funds"
            />
            <CheckBox value={otherActivities.includes('f')} checkBoxLabel="f. Forgeries" />
            <CheckBox value={otherActivities.includes('g')} checkBoxLabel="g. Human smuggling" />
            <CheckBox value={otherActivities.includes('h')} checkBoxLabel="h. Human trafficking" />
            <CheckBox value={otherActivities.includes('i')} checkBoxLabel="i. Identity theft" />
            <CheckBox
              value={otherActivities.includes('j')}
              checkBoxLabel="j. Little or no concern for product performance penalties, fees, or tax consequences"
            />
            <CheckBox value={otherActivities.includes('k')} checkBoxLabel="k. Misuse of position or self-dealing" />
            <CheckBox
              value={otherActivities.includes('l')}
              checkBoxLabel="l. Suspected public/private corruption (domestic)"
            />
            <CheckBox
              value={otherActivities.includes('m')}
              checkBoxLabel="m. Suspected public/private corruption (foreign)"
            />
            <CheckBox
              value={otherActivities.includes('n')}
              checkBoxLabel="n. Suspicious use of informal value transfer system"
            />
            <CheckBox
              value={otherActivities.includes('o')}
              checkBoxLabel="o. Suspicious use of multiple transaction locations"
            />
            <CheckBox
              value={otherActivities.includes('p')}
              checkBoxLabel="p. Transaction with no apparent economic, business, or lawful purpose"
            />
            <CheckBox
              value={otherActivities.includes('q')}
              checkBoxLabel="q. Transaction(s) involving foreign high risk jurisdiction"
            />
            <CheckBox
              value={otherActivities.includes('r')}
              checkBoxLabel="r. Two or more individuals working together"
            />
            <CheckBox value={otherActivities.includes('s')} checkBoxLabel="s. Unlicensed or unregistered MSB" />
            <CheckBox value={otherActivities.includes('z')} checkBoxLabel="z. Other" />
            {showOtherActivitiesOther && <SC.Label>{showOtherActivitiesOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info label="39. Insurance">
            <CheckBox value={insurance.includes('a')} checkBoxLabel="a. Excessive insurance" />
            <CheckBox
              value={insurance.includes('b')}
              checkBoxLabel="b. Excessive or unusual cash borrowing against policy/annuity"
            />
            <CheckBox
              value={insurance.includes('c')}
              checkBoxLabel="c. Proceeds sent to or received from unrelated third party"
            />
            <CheckBox
              value={insurance.includes('d')}
              checkBoxLabel="d. Suspicious life settlement sales insurance (e.g.,STOLI’s, Viaticals)"
            />
            <CheckBox value={insurance.includes('e')} checkBoxLabel="e. Suspicious termination of policy or contract" />
            <CheckBox value={insurance.includes('f')} checkBoxLabel="f. Unclear or no insurable interest" />
            <CheckBox value={insurance.includes('z')} checkBoxLabel="z. Other" />
            {showInsuranceOther && <SC.Label>{showInsuranceOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info label="40. Securities / Futures / Options">
            <CheckBox value={securities.includes('a')} checkBoxLabel="a. Insider trading" />
            <CheckBox value={securities.includes('b')} checkBoxLabel="b. Market manipulation" />
            <CheckBox value={securities.includes('c')} checkBoxLabel="c. Misappropriation" />
            <CheckBox value={securities.includes('d')} checkBoxLabel="d. Unauthorized pooling" />
            <CheckBox value={securities.includes('e')} checkBoxLabel="e. Wash trading" />
            <CheckBox value={securities.includes('z')} checkBoxLabel="z. Other" />
            {showSecuritiesOther && <SC.Label>{showSecuritiesOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info label="41. Mortgage Fraud">
            <CheckBox value={mortgage.includes('a')} checkBoxLabel="a. Application fraud" />
            <CheckBox value={mortgage.includes('b')} checkBoxLabel="b. Appraisal fraud" />
            <CheckBox value={mortgage.includes('c')} checkBoxLabel="c. Foreclosure/Short sale fraud" />
            <CheckBox value={mortgage.includes('d')} checkBoxLabel="d. Loan Modification fraud" />
            <CheckBox value={mortgage.includes('e')} checkBoxLabel="e. Origination fraud" />
            <CheckBox value={mortgage.includes('z')} checkBoxLabel="z. Other" />
            {showMortgageOther && <SC.Label>{showMortgageOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info label="45. Were any of the following product type(s) involved in the suspicious activity?">
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('a')} checkBoxLabel="a. Bonds/Notes" />
              <CheckBox value={productTypes.includes('k')} checkBoxLabel="k. Home equity loan" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('b')} checkBoxLabel="b. Commercial mortgage" />
              <CheckBox value={productTypes.includes('l')} checkBoxLabel="l. Insurance/Annuity products" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('c')} checkBoxLabel="c. Commercial paper" />
              <CheckBox value={productTypes.includes('m')} checkBoxLabel="m. Microcap securities" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('d')} checkBoxLabel="d. Credit card" />
              <CheckBox value={productTypes.includes('n')} checkBoxLabel="n. Mutual fund" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('e')} checkBoxLabel="e. Debit card" />
              <CheckBox value={productTypes.includes('o')} checkBoxLabel="o. Options on securities" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('f')} checkBoxLabel="f. Deposit account" />
              <CheckBox value={productTypes.includes('p')} checkBoxLabel="p. Prepaid access" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('g')} checkBoxLabel="g. Forex transactions" />
              <CheckBox value={productTypes.includes('q')} checkBoxLabel="q. Residential mortgage" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('h')} checkBoxLabel="h. Futures/Options on futures" />
              <CheckBox value={productTypes.includes('r')} checkBoxLabel="r. Security futures products" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('i')} checkBoxLabel="i. Hedge fund" />
              <CheckBox value={productTypes.includes('s')} checkBoxLabel="s. Stocks" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('j')} checkBoxLabel="j. Home equity line of credit" />
              <CheckBox value={productTypes.includes('t')} checkBoxLabel="t. Swap, hybrid, or other derivatives" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={productTypes.includes('z')} checkBoxLabel="z. Other" />
            </SC.CheckBoxes>
            {showProductTypesOther && <SC.Label>{showProductTypesOther}</SC.Label>}
          </Info>
        </Row>
        <Row>
          <Info
            label="46. Were any of the following instrument type(s)/payment mechanism(s) involved in the suspicious 
            activity?"
          >
            <SC.CheckBoxes>
              <CheckBox value={paymentMechanisms.includes('a')} checkBoxLabel="a. Bank/Cashier's check" />
              <CheckBox value={paymentMechanisms.includes('f')} checkBoxLabel="f. Money orders" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={paymentMechanisms.includes('b')} checkBoxLabel="b. Foreign currency" />
              <CheckBox value={paymentMechanisms.includes('g')} checkBoxLabel="g. Personal/Business check" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={paymentMechanisms.includes('c')} checkBoxLabel="c. Funds transfer" />
              <CheckBox value={paymentMechanisms.includes('h')} checkBoxLabel="h. Travelers checks" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={paymentMechanisms.includes('d')} checkBoxLabel="d. Gaming instruments" />
              <CheckBox value={paymentMechanisms.includes('i')} checkBoxLabel="i. U.S. Currency" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={paymentMechanisms.includes('e')} checkBoxLabel="e. Government payment" />
              <CheckBox value={paymentMechanisms.includes('z')} checkBoxLabel="z. Other" />
            </SC.CheckBoxes>
            {showPaymentMechanismsOther && <SC.Label>{showPaymentMechanismsOther}</SC.Label>}
          </Info>
        </Row>
        {ipAddresses.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>43. IP Address</h5>
                <br />
              </Col2x>
            </Row>
            {ipAddresses.map((ipAddress, index) => (
              <Fragment key={index}>
                <Row>
                  <Col>
                    <Info label="IP">{ipAddress.ip}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="Date">{ipAddress.date}</Info>
                  </Col>
                  <Col>
                    <Info label="Timestamp (UTC)">{ipAddress.time}</Info>
                  </Col>
                </Row>
              </Fragment>
            ))}
          </>
        )}
        {cyberEventIndicators.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>44. Cyber Event Indicators</h5>
                <br />
              </Col2x>
            </Row>
            {cyberEventIndicators.map((item, index) => (
              <Fragment key={index}>
                <Row>
                  <Col>
                    <Info label="Event Type">
                      {item.type === 'Other' && item.typeOther}
                      {item.type !== 'Other' && item.type}
                    </Info>
                  </Col>
                  <Col>
                    <Info label="Event Value">{item.value}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="Date">{item.date}</Info>
                  </Col>
                  <Col>
                    <Info label="Timestamp (UTC)">{item.time}</Info>
                  </Col>
                </Row>
              </Fragment>
            ))}
            {showCyberOther && <SC.Label>{showCyberOther}</SC.Label>}
          </>
        )}
        {commodityTypes.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>47. Commodity type</h5>
                <br />
              </Col2x>
            </Row>
            {commodityTypes.map((item, index) => (
              <Fragment key={index}>
                <Row>
                  <Col>
                    <Info label="Value">{item}</Info>
                  </Col>
                </Row>
              </Fragment>
            ))}
          </>
        )}
        {productInstrumentDesc.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>48. Product/Instrument description</h5>
                <br />
              </Col2x>
            </Row>
            {productInstrumentDesc.map((item, index) => (
              <Fragment key={index}>
                <Row>
                  <Col>
                    <Info label="Value">{item}</Info>
                  </Col>
                </Row>
              </Fragment>
            ))}
          </>
        )}
        {marketsTraded.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>49. Market where traded</h5>
                <br />
              </Col2x>
            </Row>
            {marketsTraded.map((item, index) => (
              <Fragment key={index}>
                <Row>
                  <Col>
                    <Info label="Value">{item}</Info>
                  </Col>
                </Row>
              </Fragment>
            ))}
          </>
        )}
        {cusipNumbers.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>50. CUSIP® number</h5>
                <br />
              </Col2x>
            </Row>
            {cusipNumbers.map((item, index) => (
              <Fragment key={index}>
                <Row>
                  <Col>
                    <Info label="Value">{item}</Info>
                  </Col>
                </Row>
              </Fragment>
            ))}
          </>
        )}
      </InfoSet>
    </PageSection>
  )
})
