import { getRouteApi } from '@tanstack/react-router'
import { LicenseTypeLabel } from 'ui/components/Labels'
import { PageSection } from 'ui/components/Page'
import { LicensingQuestionnaire } from '~bank-annual-review/components/LicensingQuestionnaire'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

import { s } from './styles'

export const Licensing = (): JSX.Element | null => {
  const { licensingItems } = getRouteApi(AnnualReviewPaths.DETAILS).useLoaderData() as AnnualReviewDetails

  if (!licensingItems) return null

  return (
    <PageSection title="Licensing">
      <s.Table>
        <thead>
          <tr>
            <s.Th>License Type</s.Th>
            <s.Th>Issue Date</s.Th>
            <s.Th>Expiration Date</s.Th>
            <s.Th>Number</s.Th>
            <s.Th>Location</s.Th>
          </tr>
        </thead>
        <tbody>
          {licensingItems.map((license, index) => (
            <tr key={index}>
              <s.Td>
                <LicenseTypeLabel name={license.type} />
              </s.Td>
              <s.Td>
                <>{license.issueDate}</>
              </s.Td>
              <s.Td>
                <>{license.expirationDate}</>
              </s.Td>
              <s.Td>{license.licenseNumber}</s.Td>
              <s.Td>{license.location}</s.Td>
            </tr>
          ))}
        </tbody>
      </s.Table>
      <LicensingQuestionnaire />
    </PageSection>
  )
}
