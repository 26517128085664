import { usStates } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

export const IdState = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const country = formApi.useStore((s) => s.values.subjectsInfo[index1].idsInfo[index2].idCountry)

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idState`}
      children={(field) => (
        <FF
          field={field}
          label="Issuing State"
          input={(p) => (
            <Select
              {...p}
              data={usStates}
              emptyOption={{ label: 'Not selected', value: '' }}
              disabled={isAllInfoUnavailable || country !== 'US'}
            />
          )}
        />
      )}
    />
  )
}
