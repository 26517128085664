import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import { Address, City, Country, IdNumber, RoleInTransaction, State, ZipCode } from './fields'

import { s } from './styles'

export const InstitutionActivityBranchesItem = ({
  index,
  index2,
  field
}: {
  index: number
  index2: number
  field: FieldApi<SarEdit, 'institutionActivitiesInfo[0].institutionActivityBranchesInfo'>
}) => {
  const { open: openDeleteConfirmation, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: async () => {
      if (index2 !== -1) {
        await field.removeValue(index2)
      }
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <s.BranchHeader>
        <s.BranchTitle>Branch Information</s.BranchTitle>
        {field.state.value.length > 1 && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={openDeleteConfirmation} />
        )}
      </s.BranchHeader>
      <FormRow style={{ alignItems: 'flex-end' }}>
        <Col>
          <RoleInTransaction index1={index} index2={index2} />
        </Col>
        <Col>
          <Address index1={index} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <City index1={index} index2={index2} />
        </Col>
        <Col>
          <IdNumber index1={index} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <State index1={index} index2={index2} />
        </Col>
        <Col>
          <ZipCode index1={index} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Country index1={index} index2={index2} />
        </Col>
      </FormRow>
    </>
  )
}
