import { FC, memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { QueryTable } from 'ui/components/QueryTable'

import { SICSAPIStatusReportFilterForm } from './SICSAPIStatusReportFilterForm'
import { columns } from './columns'
import { dataSourceSelector } from './dataSourceSelector'
import { filterMapper } from './filterMapper'
import { SICSAPIStatusReportFilterValue, SICSAPIStatusReportTableProps } from './types'

export const SICSAPIStatusReportTable: FC<SICSAPIStatusReportTableProps> = memo((props) => {
  const {
    tableState: { filter, pagination, sorting },
    filterForm
  } = props

  return (
    <QueryTable
      name="Licenses List"
      dataQa={TableDataQa.BP_REPORTS_SICS_API_STATUS_REPORT}
      dataSources={[
        {
          key: 'list',
          url: Request.urls.bank.portalReportLicenses,
          options: (_: any, filter: SICSAPIStatusReportFilterValue) => filterMapper({ filter, pagination })
        }
      ]}
      columns={columns}
      dataSourceSelector={dataSourceSelector}
      sorting={{
        order: sorting.order,
        onChange: sorting.change
      }}
      pagination={{
        page: pagination.page,
        size: pagination.size,
        onChange: pagination.change
      }}
      filter={{
        value: filterForm.config.value,
        component: (
          <SICSAPIStatusReportFilterForm form={filterForm} expanded={filter.expanded} onToggle={filter.toggle} />
        )
      }}
    />
  )
})
