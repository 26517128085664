import { FC, memo, useMemo } from 'react'
import Dropzone from 'react-dropzone'

import { TableDataQa } from 'commons/types/enums'
import { IconName } from 'ui/components/Icon'

import { OutlineButton } from '../../../Buttons'
import { DateTime } from '../../../DateTime'
import { Dialog, DialogFace, DialogFooterActions } from '../../../Dialog'
import { DataSourceItem } from '../../../Table'
import { FileSelectorDialogProps } from './FileSelectorDialogProps'
import { useComponentEssence } from './hooks'

import { ActionRow, ButtonSPart, DeleteButton, FilesTable } from './styles'

export const FileSelectorDialog: FC<FileSelectorDialogProps> = memo((props) => {
  const { title, onClose, initialFiles, onSubmit, showDialogAfterConfirm, showSpinner } = props
  const { files, addFiles, deleteFile, submitHandler } = useComponentEssence({
    initialFiles,
    onSubmit,
    onClose,
    showDialogAfterConfirm
  })

  const mappedFilesSelectorDialog: DataSourceItem<{ file: File; uid: number }>[] = useMemo(
    () =>
      files.map((el) => ({
        item: el
      })),
    [files]
  )

  return (
    <Dialog
      id="file-selector-dialog"
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={title}
      headerActions={
        <ActionRow>
          <Dropzone multiple onDrop={addFiles}>
            {({ getRootProps, getInputProps }) => (
              <span {...getRootProps()}>
                <input {...getInputProps()} />
                {
                  // This hack because we have (s) and style in button about text-transform
                  // in this case text looks like Add File(S) - S is too big
                }
                <OutlineButton face="brand" size="middle" icon={IconName.UPLOAD}>
                  Add file<ButtonSPart>(s)</ButtonSPart>
                </OutlineButton>
              </span>
            )}
          </Dropzone>
        </ActionRow>
      }
      onSubmit={submitHandler}
      isSubmitting={showSpinner}
      footerActions={[DialogFooterActions.SUBMIT]}
    >
      <FilesTable
        dataQa={TableDataQa.FILES_SELECTOR_LIST}
        columns={[
          {
            align: 'left',
            title: 'Filename',
            key: 'name',
            render: ({ file, uid }) => (
              <>
                <DeleteButton onClick={() => deleteFile(uid)}>
                  <span>Delete</span>
                </DeleteButton>
                {file.name}
              </>
            )
          },
          {
            width: '180px',
            align: 'center',
            title: 'Last Modified Date',
            key: 'lastModifiedDate',
            render: ({ file }) => <DateTime withTime date={file.lastModifiedDate} />
          }
        ]}
        dataSource={mappedFilesSelectorDialog}
        loading={showSpinner}
      />
    </Dialog>
  )
})
