import { memo } from 'react'

import { FieldProps, FormInputProps } from 'brief-form'
import SC from 'styled-components'
import { FieldSet } from 'ui/components/FieldSet'
import { Validators } from 'ui/components/Form'
import { Hr } from 'ui/components/Hr'
import { CurrencyCentInput, TextInput } from 'ui/components/InputV2'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { SalesAndDepositFormValue } from './SalesAndDepositFormValue'

interface Props {
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, SalesAndDepositFormValue>
  ) => JSX.Element
  quarterNumber: number
}

const FieldSetSC = SC(FieldSet)`
  margin-top: 35px;
`

export const Deposits = memo((props: Props) => {
  const { Field, quarterNumber } = props

  return (
    <FieldSetSC legend={`Q${quarterNumber} info`}>
      <FormRow>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.label` as any}
            input={TextInput}
            validator={Validators.required.string as any}
            label="Label"
            inputProps={{}}
          />
        </Col>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.sales` as any}
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Sales"
            inputProps={{}}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.cash` as any}
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Cash Deposits"
            inputProps={{}}
          />
        </Col>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.invoices` as any}
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Cash Invoices"
            inputProps={{}}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.transfers` as any}
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Internal Transfers"
            inputProps={{}}
          />
        </Col>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.checks` as any}
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Checks & Remote Deposit"
            inputProps={{}}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.credit_debit` as any}
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Credit / Debit"
            inputProps={{}}
          />
        </Col>
        <Col>
          <Field
            required
            name={`quarter${quarterNumber}_info.other` as any}
            input={CurrencyCentInput}
            validator={Validators.required.number()}
            label="Other"
            inputProps={{}}
          />
        </Col>
      </FormRow>
      <br />
      <Hr />
    </FieldSetSC>
  )
})
