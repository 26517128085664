import { FC, memo } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'

import { s } from './styles'

type Props = {
  warnings: string
}

export const FincenWarnings: FC<Props> = memo(({ warnings }) => (
  <s.Box>
    <s.Title weight={700}>
      <s.WarningIcon name={IconName.ERROR_RED} size={IconSize.M} /> FinCEN Warning(s)
    </s.Title>
    <br />
    <s.Title weight={600}>All FinCEN warnings will be cleared once CTR is corrected and re-submitted.</s.Title>
    <s.List>
      {warnings.split('\n').map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </s.List>
  </s.Box>
))
