import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type MiscInProps = {
  index: number
}

export const MiscIn = ({ index }: MiscInProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].miscIn` as 'transactionsInfo[0].miscIn'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => (
        <FF required field={field} label="Misc Credits" input={(p) => <CurrencyCentInput {...p} />} />
      )}
    />
  )
}
