import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import { getAnnualReviewEditMode } from '~bank-annual-review/service'

export const annualReviewEditRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `/edit/$companyId/$id`,
    beforeLoad: ({ params, search, ...all }) => {
      if ('id' in params && params.id && Number(params.id))
        return { ...all, flatDeps: { id: Number(params.id), ...search } }
      else throw new NotFoundError()
    },
    loader: ({ context: { flatDeps } }) => getAnnualReviewEditMode(Number(flatDeps.id)),
    component: lazyRouteComponent(() => import('./AnnualReviewEdit'))
  })
