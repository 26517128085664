import { memo } from 'react'

import { BankAccount } from 'commons/types/DTO/bank/quarterlyReport/bankAccount'
import { formatCurrencyCent } from 'commons/utils'
import { Layer } from 'ui/components'

import { getPercentValue, renderLicensesList } from './helpers'

import * as SC from './styles'

interface Props {
  bankAccount: BankAccount
}

export const SalesReportTable = memo(({ bankAccount }: Props) => {
  const { showSalesRetail, showSalesWholesale } = bankAccount

  return (
    <Layer shadowed rounded stretch>
      <SC.Table cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th>Name</th>
            <th>{showSalesRetail && <>Retail ({renderLicensesList(showSalesRetail)})</>}</th>
            <th>{showSalesWholesale && <>Wholesale ({renderLicensesList(showSalesWholesale)})</>}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Current Quarter</td>
            <td>{showSalesRetail && formatCurrencyCent(showSalesRetail.currentQuarter.total, false)}</td>
            <td>{showSalesWholesale && formatCurrencyCent(showSalesWholesale.currentQuarter.total, false)}</td>
          </tr>
          <tr>
            <td>Prior Quarter</td>
            <td>{showSalesRetail && formatCurrencyCent(showSalesRetail.priorQuarter.total, false)}</td>
            <td>{showSalesWholesale && formatCurrencyCent(showSalesWholesale.priorQuarter.total, false)}</td>
          </tr>
          <tr>
            <td>Peer Group Average</td>
            <td>{showSalesRetail && formatCurrencyCent(showSalesRetail.peerGroupAverage.total, false)}</td>
            <td>{showSalesWholesale && formatCurrencyCent(showSalesWholesale.peerGroupAverage.total, false)}</td>
          </tr>
          <tr>
            <td>% Change</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Prior Quarter</td>
            <SC.Td $alerted={showSalesRetail && showSalesRetail.changesPriorQuarter.total.alerted}>
              {showSalesRetail && getPercentValue(showSalesRetail.changesPriorQuarter.total.percent)}
            </SC.Td>
            <SC.Td $alerted={showSalesWholesale && showSalesWholesale.changesPriorQuarter.total.alerted}>
              {showSalesWholesale && getPercentValue(showSalesWholesale.changesPriorQuarter.total.percent)}
            </SC.Td>
          </tr>
        </tbody>
      </SC.Table>
    </Layer>
  )
})
