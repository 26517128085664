import { memo, useCallback } from 'react'

import * as SC from './styles/SearchInputStyles'
import { IconName, IconSize } from 'ui/components/Icon'

import { useEmpty } from './hooks'
import { BaseInputProps } from './props'
import { InputIconPosition } from './types'

export const SearchInput = memo((props: BaseInputProps) => {
  const { value, onChange, disabled = false, ...rest } = props
  const { isEmpty, handleChange } = useEmpty(value, onChange)

  // clear value if user click on clear icon
  const handleClear = useCallback(() => {
    if (onChange) {
      onChange('', undefined)
    }
  }, [onChange])

  return (
    <SC.BaseInputStyled
      {...rest}
      disabled={disabled}
      renderIcon={
        <SC.IconWrapper
          name={isEmpty ? IconName.SEARCH : IconName.CROSS_SMALL}
          size={IconSize.S}
          $notEmpty={!isEmpty}
          $disabled={disabled}
          onClick={handleClear}
        />
      }
      iconPosition={InputIconPosition.RIGHT_FAR}
      value={value}
      onChange={handleChange}
    />
  )
})
