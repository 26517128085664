import { useCallback, useState } from 'react'

import { PortalName } from 'commons/types/enums'
import { useConfirmation } from 'ui/hooks'
import { useDeleteDevice } from '~auth/hooks/mfa'

export const useDeletion = (portal: PortalName) => {
  const [tbd, setTbd] = useState<{ id: number; name: string } | null>(null)
  const deleteDeviceMutation = useDeleteDevice(portal)

  const { open, Confirmation } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>{tbd?.name}</i> device?
      </span>
    ),
    onConfirm: () => deleteDeviceMutation.mutateAsync({ id: tbd?.id || 0 }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startDeleting = useCallback(
    (item: any) => {
      setTbd(item)
      open()
    },
    [open, setTbd]
  )

  return {
    Confirmation,
    startDeleting
  }
}
