import { FC, useCallback, useEffect, useState } from 'react'

import { FilterAlertsWebPage } from 'commons/service/bank/alerts'
import { AlertsWebPage } from 'commons/types/DTO/bank'
import { handlerServerError } from 'errors'
import { DocumentsDue } from 'ui/components/Documents'
import { Page } from 'ui/components/Page'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'
import { useFilterAlertsWebPage } from '~bank-alerts/hooks'

import { AlertsPageProps } from './AlertsPageProps'
import {
  AdverseMediaTable,
  AnnualReviewsTable,
  ApplicationsTable,
  BSATable,
  CTRsTable,
  MonthlyAnalyticsTable,
  QuarterlyAnalyticsTable,
  SARsListTable,
  UploadsPendingApproval
} from './components'

const AlertsPage: FC<AlertsPageProps> = (props) => {
  const [webPageData, setWebPageData] = useState<AlertsWebPage | undefined>(undefined)
  const [alertsFilter, setAlertsFilter] = useState<FilterAlertsWebPage | undefined>()

  const { routine: filterAlertsWebPage } = useFilterAlertsWebPage({
    onSuccess: async (_, result) => {
      await setWebPageData(result)
    },
    onError: (error) => handlerServerError(error)
  })

  const filterAlertsWebPageMemoized = useCallback(filterAlertsWebPage, [])
  const onAlertsFilterChange = useCallback(
    async (filter: FilterAlertsWebPage) => {
      const newFilter = { ...alertsFilter, ...filter }
      await filterAlertsWebPageMemoized(newFilter)
      setAlertsFilter(newFilter)
    },
    [alertsFilter, filterAlertsWebPageMemoized]
  )

  useEffect(() => {
    filterAlertsWebPageMemoized({})
  }, [filterAlertsWebPageMemoized])

  if (!webPageData) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <>
      {webPageData && (
        <Page {...props} title="Alerts">
          <DocumentsDue webPageData={webPageData} />
          {webPageData.uploads !== undefined && (
            <UploadsPendingApproval webPage={webPageData} onAlertFilterChange={onAlertsFilterChange} />
          )}
          {webPageData.applications !== undefined && (
            <ApplicationsTable
              applications={webPageData.applications}
              applicationsTotalCount={webPageData.applicationsTotalCount}
              onAlertFilterChange={onAlertsFilterChange}
            />
          )}
          {webPageData.CTRs !== undefined && (
            <CTRsTable
              CTRs={webPageData.CTRs}
              CTRsTotalCount={webPageData.CTRsTotalCount}
              onAlertFilterChange={onAlertsFilterChange}
            />
          )}
          {webPageData.bsaBatches !== undefined && (
            <BSATable
              BSA={webPageData.bsaBatches}
              BSATotalCount={webPageData.bsaBatchesTotalCount}
              onAlertFilterChange={onAlertsFilterChange}
            />
          )}
          {webPageData.SARs !== undefined && (
            <SARsListTable
              SARs={webPageData.SARs}
              SARSTotalCount={webPageData.SARsTotalCount}
              onAlertFilterChange={onAlertsFilterChange}
            />
          )}
          {webPageData.monthlyAnalytics !== undefined && (
            <MonthlyAnalyticsTable webPage={webPageData} onAlertFilterChange={onAlertsFilterChange} />
          )}
          {webPageData.quarterlyAnalytics !== undefined && (
            <QuarterlyAnalyticsTable webPage={webPageData} onAlertFilterChange={onAlertsFilterChange} />
          )}
          {webPageData.annualReviews !== undefined && (
            <AnnualReviewsTable webPage={webPageData} onAlertFilterChange={onAlertsFilterChange} />
          )}
          {webPageData.adverseMedia !== undefined && (
            <AdverseMediaTable webPage={webPageData} onAlertFilterChange={onAlertsFilterChange} />
          )}
        </Page>
      )}
    </>
  )
}

export default AlertsPage
