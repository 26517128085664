import { FC, memo } from 'react'

import { LicenseTypeLabel } from '../Labels'
import { LicenseInfoProps } from './LicenseInfoProps'
import { LicenseInfoDefaultProps } from './licenseInfoDefaultProps'

import { Box, WrappedLink, WrappedType } from './styles'

export const LicenseInfo: FC<LicenseInfoProps> = memo((props) => {
  const {
    licenseId = 0,
    licenseName = '---',
    licenseType = '',
    companyName = '---',
    companyId = 0,
    companyDetailsPath = '',
    licenseDetailsPath = '',
    hideLicenseDetailsUrl
  } = { ...LicenseInfoDefaultProps, ...props }

  const companyDetailsUrl = companyDetailsPath.replace('{companyId}', companyId.toString())

  const licenseDetailsUrl = licenseDetailsPath
    .replace('{companyId}', companyId.toString())
    .replace('{licenseId}', licenseId.toString())

  return (
    <Box>
      <WrappedLink to={companyDetailsUrl}>{companyName}</WrappedLink>
      {!!licenseId && licenseName && !hideLicenseDetailsUrl && (
        <WrappedLink to={licenseDetailsUrl}>{licenseName}</WrappedLink>
      )}
      {hideLicenseDetailsUrl && (
        <WrappedType>
          <LicenseTypeLabel name={licenseName} />
        </WrappedType>
      )}
      <WrappedType>
        <LicenseTypeLabel name={licenseType} />
      </WrappedType>
    </Box>
  )
})
