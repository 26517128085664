import { OnboardingWebPageResponse } from 'commons/types/DTO/commons'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { OnboardingFormFieldNames } from '../../../../enums'
import {
  ATMGeneralInfoFormData,
  AnticipatedMonthlyActivityFormData,
  AnticipatedMonthlyActivityNAFormData,
  AvailableServicesFormData,
  AvailableServicesFormValue,
  CompanyAccountDetailsFormData,
  CompanyInformationFormData,
  CompanyMailingAddressFormData,
  CompanyPhysicalAddressFormData,
  MerchantQuestionnaireFormData,
  ProfessionalPartnershipFormData,
  QuestionnaireFormData
} from '../../../../types/forms'
import { selectCompany, selectQuestionnaire } from '../selectors'

export const getEmptyCompanyInformation = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): CompanyInformationFormData | null => {
  const selectedCompany = selectCompany(responseData?.companies, companyIndex)

  if (!selectedCompany) return null

  return {
    formValues: {
      [OnboardingFormFieldNames.ENTITY_UID]: selectedCompany[OnboardingFormFieldNames.ENTITY_UID],

      [OnboardingFormFieldNames.COMPANY_ID]: selectedCompany[OnboardingFormFieldNames.COMPANY_ID],
      [OnboardingFormFieldNames.LEGAL_NAME]: selectedCompany[OnboardingFormFieldNames.LEGAL_NAME],
      [OnboardingFormFieldNames.DBA]: selectedCompany[OnboardingFormFieldNames.DBA],
      [OnboardingFormFieldNames.PHONE]: selectedCompany[OnboardingFormFieldNames.PHONE],
      [OnboardingFormFieldNames.WEBSITE]: selectedCompany[OnboardingFormFieldNames.WEBSITE],
      [OnboardingFormFieldNames.DESCRIPTION]: selectedCompany[OnboardingFormFieldNames.DESCRIPTION]
    },
    formErrors: {}
  }
}

export const getEmptyCompanyAccountDetails = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): CompanyAccountDetailsFormData | null => {
  const company = selectCompany(responseData?.companies, companyIndex)

  if (!company) return null

  return {
    formValues: {
      businessType: company?.businessType || '',
      entityType: company?.entityType || '',
      stateOfIncorporation: company?.stateOfIncorporation || '',
      EIN: company?.EIN || '',
      preLicenseMRBInfo: company?.preLicenseMRBInfo || ''
    },
    formErrors: {}
  }
}

export const getEmptyPhysicalAddress = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): CompanyPhysicalAddressFormData | null => {
  const company = selectCompany(responseData?.companies, companyIndex)

  if (!company) return null

  return {
    formValues: {
      street: company?.street || '',
      city: company?.city || '',
      state: company?.state || '',
      country: company?.country || '',
      postalCode: company?.postalCode || ''
    },
    formErrors: {}
  }
}

export const getEmptyMailingAddress = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): CompanyMailingAddressFormData | null => {
  const company = selectCompany(responseData?.companies, companyIndex)

  if (!company) return null

  return {
    formValues: {
      mailingStreet: company?.mailingStreet,
      mailingCity: company?.mailingCity,
      mailingState: company?.mailingState,
      mailingCountry: company?.mailingCountry,
      mailingPostalCode: company?.mailingPostalCode
    },
    formErrors: {}
  }
}

export const getEmptyQuestionnaire = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): QuestionnaireFormData | null => {
  const questionnaire = selectQuestionnaire(responseData.companies, companyIndex)

  if (!questionnaire) return null

  return {
    formValues: {
      statesLicensed: questionnaire?.statesLicensed,
      aWholesaleProducts: questionnaire?.aWholesaleProducts,
      aWholesaleProductsDesc: questionnaire?.aWholesaleProductsDesc,
      bTypeOfProducts: questionnaire?.bTypeOfProducts,
      cTypeOfAncillaryProducts: questionnaire?.cTypeOfAncillaryProducts,
      whoSuppliesYou: questionnaire?.whoSuppliesYou,
      whereIsYourSupplier: questionnaire?.whereIsYourSupplier,
      whoDoYourSupplyGrower: questionnaire?.whoDoYourSupplyGrower,
      growerCustomersLocation: questionnaire?.growerCustomersLocation,
      dHowManyLocations: questionnaire?.dHowManyLocations,
      eOnlineOrdersForPickup: questionnaire?.eOnlineOrdersForPickup,
      fMinorSellingEnsure: questionnaire?.fMinorSellingEnsure,
      gPaymentMethods: questionnaire?.gPaymentMethods,
      hTransferToOtherStates: questionnaire?.hTransferToOtherStates,
      hTransferToOtherStatesDesc: questionnaire?.hTransferToOtherStatesDesc,
      iAreYouAffiliated: questionnaire?.iAreYouAffiliated,
      iAreYouAffiliatedDesc: questionnaire?.iAreYouAffiliatedDesc,
      statesLicensedLabel: questionnaire?.statesLicensedLabel,
      contactsForeignConnections: questionnaire?.contactsForeignConnections
    },
    formErrors: {}
  }
}

export const getEmptyProfessionalPartnerships = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): ProfessionalPartnershipFormData | null => {
  const questionnaire = selectQuestionnaire(responseData.companies, companyIndex)

  if (!questionnaire) return null

  return {
    formValues: {
      proPartCurrentlyBanked: questionnaire?.proPartCurrentlyBanked,
      proPartCurrentlyBankedLabel: questionnaire?.proPartCurrentlyBankedLabel,
      proPartPrevClosureReason: questionnaire?.proPartPrevClosureReason,
      proPartCurrentServicesCPAs: questionnaire?.proPartCurrentServicesCPAs,
      proPartCurrentServicesLaw: questionnaire?.proPartCurrentServicesLaw,
      proPartCurrentServicesLogistic: questionnaire?.proPartCurrentServicesLogistic,
      proPartCurrentServicesOther: questionnaire?.proPartCurrentServicesOther,
      proPartCurrentServicesLogisticDesc: questionnaire?.proPartCurrentServicesLogisticDesc,
      proPartCurrentServicesOtherDesc: questionnaire?.proPartCurrentServicesOtherDesc,
      proPartLitigation: questionnaire?.proPartLitigation,
      proPartStateEnforcements: questionnaire?.proPartStateEnforcements,
      proPartInternetGambling: questionnaire?.proPartInternetGambling,
      proPartPayrollSoftware: questionnaire?.proPartPayrollSoftware
    },
    formErrors: {}
  }
}

export const getEmptyAtmGeneralInfo = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): ATMGeneralInfoFormData | null => {
  const questionnaire = selectQuestionnaire(responseData.companies, companyIndex)

  if (!questionnaire) return null

  return {
    formValues: {
      ATMHave: questionnaire?.ATMHave,
      ATMOwn: questionnaire?.ATMOwn,
      ATMCount: questionnaire?.ATMCount,
      ATMReplenishingThirdParty: questionnaire?.ATMReplenishingThirdParty,
      ATMReplenishingCompany: questionnaire?.ATMReplenishingCompany
    },
    formErrors: {}
  }
}

export const getEmptyAvailableServices = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): AvailableServicesFormData | null => {
  const questionnaire = selectQuestionnaire(responseData.companies, companyIndex)

  if (!questionnaire) return null

  const formValues = omitBy<AvailableServicesFormValue>(
    {
      availServiceCheckingOperating: questionnaire?.availServiceCheckingOperating,
      availServiceCheckingPayroll: questionnaire?.availServiceCheckingPayroll,
      availServiceChecking: questionnaire?.availServiceChecking,
      availServiceSavings: questionnaire?.availServiceSavings,
      availServiceACHOrigination: questionnaire?.availServiceACHOrigination,
      availServiceMobileDeposit: questionnaire?.availServiceMobileDeposit,
      availServiceWire: questionnaire?.availServiceWire,
      availServiceRollingCoinChangeOrder: questionnaire?.availServiceRollingCoinChangeOrder,
      availServiceRemoteDepositCapture: questionnaire?.availServiceRemoteDepositCapture,
      availServiceEscrow: questionnaire?.availServiceEscrow,
      availServiceOnlineMobileBanking: questionnaire?.availServiceOnlineMobileBanking,
      availServiceBillPay: questionnaire?.availServiceBillPay,
      availServiceDebitCard: questionnaire?.availServiceDebitCard,
      availServiceCreditCard: questionnaire?.availServiceCreditCard,
      availServiceWireDomestic: questionnaire?.availServiceWireDomestic,
      availServiceWireDomesticOnly: questionnaire?.availServiceWireDomesticOnly,
      availServiceWireForeign: questionnaire?.availServiceWireForeign,
      availServicePersonalChecking: questionnaire?.availServicePersonalChecking,
      availServicePersonalSavings: questionnaire?.availServicePersonalSavings,
      availServiceATMSettlement: questionnaire?.availServiceATMSettlement,
      availServiceHowManyAccountsIsOpening: questionnaire?.availServiceHowManyAccountsIsOpening
    },
    isNil
  ) as AvailableServicesFormValue

  return {
    formValues,
    formErrors: {}
  }
}

export const getEmptyAnticipatedMonthlyActivity = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): AnticipatedMonthlyActivityFormData | null => {
  const questionnaire = selectQuestionnaire(responseData.companies, companyIndex)

  if (!questionnaire) return null

  return {
    formValues: {
      anticipatedActivityACHDeposits: questionnaire?.anticipatedActivityACHDeposits,
      anticipatedActivityACHDepositsAmount: questionnaire?.anticipatedActivityACHDepositsAmount,
      anticipatedActivityACHDepositsFrequency: questionnaire?.anticipatedActivityACHDepositsFrequency,
      anticipatedActivityWiresInternationalCountries: questionnaire?.anticipatedActivityWiresInternationalCountries,
      anticipatedActivityWiresInternational: questionnaire?.anticipatedActivityWiresInternational,
      anticipatedActivityWiresInternationalAmount: questionnaire?.anticipatedActivityWiresInternationalAmount,
      anticipatedActivityWiresInternationalFrequency: questionnaire?.anticipatedActivityWiresInternationalFrequency,
      anticipatedActivityACHOriginating: questionnaire?.anticipatedActivityACHOriginating,
      anticipatedActivityACHOriginatingAmount: questionnaire?.anticipatedActivityACHOriginatingAmount,
      anticipatedActivityACHOriginatingFrequency: questionnaire?.anticipatedActivityACHOriginatingFrequency,
      anticipatedActivityATMWithdrawals: questionnaire?.anticipatedActivityATMWithdrawals,
      anticipatedActivityATMWithdrawalsAmount: questionnaire?.anticipatedActivityATMWithdrawalsAmount,
      anticipatedActivityATMWithdrawalsFrequency: questionnaire?.anticipatedActivityATMWithdrawalsFrequency,
      anticipatedActivityCashDeposits: questionnaire?.anticipatedActivityCashDeposits,
      anticipatedActivityCashDepositsAmount: questionnaire?.anticipatedActivityCashDepositsAmount,
      anticipatedActivityCashDepositsFrequency: questionnaire?.anticipatedActivityCashDepositsFrequency,
      anticipatedActivityCheckDeposits: questionnaire?.anticipatedActivityCheckDeposits,
      anticipatedActivityCheckDepositsAmount: questionnaire?.anticipatedActivityCheckDepositsAmount,
      anticipatedActivityCheckDepositsFrequency: questionnaire?.anticipatedActivityCheckDepositsFrequency,
      anticipatedActivityCashWithdrawals: questionnaire?.anticipatedActivityCashWithdrawals,
      anticipatedActivityCashWithdrawalsAmount: questionnaire?.anticipatedActivityCashWithdrawalsAmount,
      anticipatedActivityCashWithdrawalsFrequency: questionnaire?.anticipatedActivityCashWithdrawalsFrequency,
      anticipatedActivityCheckWithdrawals: questionnaire?.anticipatedActivityCheckWithdrawals,
      anticipatedActivityCheckWithdrawalsAmount: questionnaire?.anticipatedActivityCheckWithdrawalsAmount,
      anticipatedActivityCheckWithdrawalsFrequency: questionnaire?.anticipatedActivityCheckWithdrawalsFrequency,
      anticipatedActivityRemoteDeposits: questionnaire?.anticipatedActivityRemoteDeposits,
      anticipatedActivityRemoteDepositsAmount: questionnaire?.anticipatedActivityRemoteDepositsAmount,
      anticipatedActivityRemoteDepositsFrequency: questionnaire?.anticipatedActivityRemoteDepositsFrequency,
      anticipatedActivityWiresDomesticOnly: questionnaire?.anticipatedActivityWiresDomesticOnly,
      anticipatedActivityWiresDomesticOnlyAmount: questionnaire?.anticipatedActivityWiresDomesticOnlyAmount,
      anticipatedActivityWiresDomesticOnlyFrequency: questionnaire?.anticipatedActivityWiresDomesticOnlyFrequency,
      anticipatedActivityWiresDomestic: questionnaire?.anticipatedActivityWiresDomestic,
      anticipatedActivityWiresDomesticAmount: questionnaire?.anticipatedActivityWiresDomesticAmount,
      anticipatedActivityWiresDomesticFrequency: questionnaire?.anticipatedActivityWiresDomesticFrequency,
      anticipatedActivityATMSettlement: questionnaire?.anticipatedActivityATMSettlement,
      anticipatedActivityATMSettlementAmount: questionnaire?.anticipatedActivityATMSettlementAmount,
      anticipatedActivityATMSettlementFrequency: questionnaire?.anticipatedActivityATMSettlementFrequency
    },
    formErrors: {}
  }
}

export const getEmptyAnticipatedMonthlyActivityNA = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): AnticipatedMonthlyActivityNAFormData | null => {
  const questionnaire = selectQuestionnaire(responseData.companies, companyIndex)

  if (!questionnaire) return null

  return {
    formValues: {
      activityDepositsChecksItems: questionnaire?.activityDepositsChecksItems,
      activityDepositsChecksAmount: questionnaire?.activityDepositsChecksAmount,
      activityDepositsChecksNA: questionnaire?.activityDepositsChecksNA,
      activityDepositsCashItems: questionnaire?.activityDepositsCashItems,
      activityDepositsCashAmount: questionnaire?.activityDepositsCashAmount,
      activityDepositsCashNA: questionnaire?.activityDepositsCashNA,
      activityDepositsOtherItems: questionnaire?.activityDepositsOtherItems,
      activityDepositsOtherAmount: questionnaire?.activityDepositsOtherAmount,
      activityDepositsOtherNA: questionnaire?.activityDepositsOtherNA,
      activityWithdrawalsCashItems: questionnaire?.activityWithdrawalsCashItems,
      activityWithdrawalsCashAmount: questionnaire?.activityWithdrawalsCashAmount,
      activityWithdrawalsCashNA: questionnaire?.activityWithdrawalsCashNA
    },
    formErrors: {}
  }
}

export const getEmptyMerchantQuestionnaire = (
  responseData: Partial<OnboardingWebPageResponse>,
  companyIndex: number
): MerchantQuestionnaireFormData | null => {
  const questionnaire = selectQuestionnaire(responseData.companies, companyIndex)

  if (!questionnaire) return null

  return {
    formValues: {
      legacyCash: questionnaire?.legacyCash,
      merchantProcessing: questionnaire?.merchantProcessing,
      merchantProcessingDesc: questionnaire?.merchantProcessingDesc,
      primaryMethodToFund: questionnaire?.primaryMethodToFund,
      cryptocurrencyTransactions: questionnaire?.cryptocurrencyTransactions,
      realTimePaymentServices: questionnaire?.realTimePaymentServices
    },
    formErrors: {}
  }
}
