import { ApplicationRelationshipLicense } from 'commons/types/DTO/bank/applications'

import { ApplicationBankAccountsDialogState } from '../../types'

export type LicensesCurrentState = {
  licensesWithNewForm: ApplicationRelationshipLicense[]
  licensesWithoutNewForm: ApplicationRelationshipLicense[]
}

export type FooterState = {
  isPrevDisabled: boolean
  isNextDisabled: boolean
  isSaving: boolean
  isFinalizing: boolean
  isShowPrevBtn: boolean
  isShowSubmitBtn: boolean
  onPrev: () => void
  onNext: () => void
  onFinish: () => void
}

export enum AddBankAccountDialogStateActions {
  ON_CHANGE = 'onChange'
}

interface onChangeAddBankAccountDialogState {
  type: AddBankAccountDialogStateActions.ON_CHANGE
  payload: Partial<ApplicationBankAccountsDialogState>
}

export type AddBankAccountDialogStateAction = onChangeAddBankAccountDialogState
