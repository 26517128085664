export interface LeaveConfirmationProps {
  /**
   * sometimes we do not want to ignore hashes, coz on some pages with hash navigation we have a lot of dialogs
   * more info: https://helioscompliance.atlassian.net/browse/MP-4368
   */
  ignoreUrlHashChanging?: boolean
  preventLeaving: boolean
  children: React.ReactNode
  onLeave?: () => void
}
