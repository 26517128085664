import { LicenseManageRequest } from 'commons/types/DTO/bank'

export const transformEditLicenseFormValues = (value: Partial<LicenseManageRequest>): Partial<LicenseManageRequest> => {
  const newValue = { ...value }
  newValue.cityTax = value.cityTax !== undefined && value.cityTax !== null ? +value.cityTax : undefined
  newValue.mjRetailTax = value.mjRetailTax !== undefined && value.mjRetailTax !== null ? +value.mjRetailTax : undefined
  newValue.specialTax = value.specialTax !== undefined && value.specialTax !== null ? +value.specialTax : undefined
  newValue.countyTax = value.countyTax !== undefined && value.countyTax !== null ? +value.countyTax : undefined
  newValue.stateTax = value.stateTax !== undefined && value.stateTax !== null ? +value.stateTax : undefined

  return newValue
}
