import { useCallback, useState } from 'react'

import { ApplicationStatus } from 'commons/types/enums'
import { Button } from 'ui/components/Buttons'

import { RevertToDialog } from '../RevertToDialog'
import { RevertToDocumentRequestPendingProps } from './RevertToDocumentRequestPendingProps'

export const RevertToDocumentRequestPending = ({ application }: RevertToDocumentRequestPendingProps) => {
  const [showModal, setShowModal] = useState(false)

  const onHideModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const onShowModal = useCallback(() => {
    setShowModal(true)
  }, [])

  return (
    <>
      {showModal && (
        <RevertToDialog
          application={application}
          onCloseModal={onHideModal}
          status={ApplicationStatus.DOCUMENT_REQUEST_PENDING}
        />
      )}
      <Button face="neutral" onClick={onShowModal}>
        Revert to Document Request Pending
      </Button>
    </>
  )
}
