import { useCallback, useEffect, useState } from 'react'

import { useNavigate, useRouter } from '@tanstack/react-router'
import { Request } from 'commons/utils/request'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { useDeleteDocumentIsolated } from '../../../../hooks/useDeleteDocumentIsolated'
import { DocumentListProps } from './DocumentListProps'
import { DocumentsTable } from './components/DocumentsTable'

import * as SC from './styles'

type DocumentForDelete = {
  id: number
  name: string
}

export const DocumentsListIsolated = ({ application }: DocumentListProps) => {
  const router = useRouter()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const { routine: deleteDocumentIsolated } = useDeleteDocumentIsolated()
  const [documentForDelete, setDocumentForDelete] = useState<DocumentForDelete | undefined>(undefined)

  const handleError = (message: string) => Toast.error('Error', message)

  const onDeleteDocument = useCallback(async () => {
    setLoading(true)
    try {
      await deleteDocumentIsolated(documentForDelete?.id || 0)
      await router.invalidate()
      Toast.success('Document has been deleted')
      setDocumentForDelete(undefined)
    } catch (error) {
      Toast.error('A trouble was occurred while deleted')
    }
    setLoading(false)
  }, [deleteDocumentIsolated, documentForDelete?.id, router])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>{documentForDelete?.name}</i> document?
      </span>
    ),
    onConfirm: () => onDeleteDocument(),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  useEffect(() => {
    if (documentForDelete) {
      openDeleteConfirmation()
    }
  }, [documentForDelete, openDeleteConfirmation])

  return (
    <SC.Documents>
      <DeleteConfirmation />
      <SC.Header>
        Document Uploading
        {application.enableAddNewIsolatedDocument && (
          <Button
            face="positive"
            onClick={() =>
              navigate({
                to: '/onboarding/applications/$applicationId/document/create',
                params: { applicationId: application.applicationID }
              })
            }
          >
            Add New
          </Button>
        )}
      </SC.Header>
      <div>
        {application.applicationClientUploadedDocuments.map((applicationClientUploadedDocument, index) => {
          const url = Request.urls.bank.applicationZipDocumentsDownload
          return (
            <SC.Company key={applicationClientUploadedDocument.companyUid}>
              <SC.CompanyName>
                Company #{index + 1} &quot;
                {applicationClientUploadedDocument.companyName}
                &quot;:
              </SC.CompanyName>
              <DocumentsTable
                webPage={application}
                applicationClientUploadedDocument={applicationClientUploadedDocument}
                applicationId={application.applicationID}
                readonly={!application.enableClientDocumentsActions}
                onDeleteDocument={(document) => {
                  setDocumentForDelete(document)
                }}
                loading={loading}
              />
              {!!applicationClientUploadedDocument.enableDownloadAllApplicationDocumentFiles && (
                <SC.DownloadLinkBtn
                  direct
                  mimeType="application/zip"
                  name={`ClientDocuments_${application.infoCompanyName}`}
                  parametersForm={{
                    applicationId: application.applicationID,
                    companyUid: applicationClientUploadedDocument.companyUid
                  }}
                  baseUrl={url}
                  handleError={handleError}
                >
                  Download All Client Documents
                </SC.DownloadLinkBtn>
              )}
            </SC.Company>
          )
        })}
      </div>
    </SC.Documents>
  )
}
