import { FC } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'

import { ResultFooterProps } from './ResultFooterProps'

export const ResultFooter: FC<ResultFooterProps> = ({ onClose, onReset }) => (
  <ButtonGroup margin="small" style={{ padding: '18px 32px' }}>
    <Button face="positive" onClick={onReset}>
      Setup Another Key
    </Button>
    <Button face="neutral" onClick={onClose}>
      Close
    </Button>
  </ButtonGroup>
)
