import { useLoaderData } from '@tanstack/react-router'
import {
  CheckBoxGroup,
  DatePicker,
  FF,
  RadioGroup,
  RadioGroupOrientation,
  TextInput,
  TextareaInput
} from 'ui/components'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { questionnaireDemoLabels } from '~bank-annual-review/constants/questionnaireLabels'

import { useAnnualReviewFormContext } from '../../../context/ManageAnnualReviewContext'

export const QuestionnaireDemoForm = () => {
  const { formApi } = useAnnualReviewFormContext()

  const loaderData = useLoaderData({ from: AnnualReviewPaths.EDIT })

  if (!formApi.state.values.questionnaireDemo) return null

  return (
    <>
      <PageSection title="Financials">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.financialsPeriodCollected}
              children={
                <formApi.Field
                  name="questionnaireDemo.financialsPeriodCollected"
                  children={(field) => <FF field={field} input={(inputProps) => <TextInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.financialsBusinessCondition}
              multiline
              children={
                <formApi.Field
                  name="questionnaireDemo.financialsBusinessCondition"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.financialsProfitabilityTrend}
              multiline
              children={
                <formApi.Field
                  name="questionnaireDemo.financialsProfitabilityTrend"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.financialsBecomingFailing}
              multiline
              children={
                <formApi.Field
                  name="questionnaireDemo.financialsBecomingFailing"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.financialsDepositsMatching}
              children={
                <formApi.Field
                  name="questionnaireDemo.financialsDepositsMatching"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.financialsInvestorDebtholders}
              multiline
              children={
                <formApi.Field
                  name="questionnaireDemo.financialsInvestorDebtholders"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Transactions">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.transactionsTypes}
              children={
                <formApi.Field
                  name="questionnaireDemo.transactionsTypes"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <CheckBoxGroup
                          direction="horizontal"
                          data={[
                            {
                              label: 'Cash',
                              value: 'Cash'
                            },
                            {
                              label: 'ATM',
                              value: 'ATM'
                            },
                            {
                              label: 'ACH',
                              value: 'ACH'
                            },
                            {
                              label: 'Check Deposit',
                              value: 'Check Deposit'
                            }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Q
                label={questionnaireDemoLabels.transactionsVolProjected}
                children={
                  <formApi.Field
                    name="questionnaireDemo.transactionsVolProjected"
                    children={(field) => (
                      <FF
                        field={field}
                        input={(inputProps) => (
                          <RadioGroup
                            data={[
                              { label: 'Yes', value: 'Yes' },
                              { label: 'No', value: 'No' }
                            ]}
                            {...inputProps}
                          />
                        )}
                      />
                    )}
                  />
                }
              />
              &nbsp;
              <formApi.Subscribe
                children={({ values }) => (
                  <>
                    {values?.questionnaireDemo?.transactionsVolProjected !== 'No' && (
                      <>
                        <i style={{ marginRight: '1.45rem' }}>(</i>
                        <Q
                          children={
                            <formApi.Field
                              name="questionnaireDemo.transactionsVolProjectedExactly"
                              children={(field) => (
                                <FF
                                  field={field}
                                  input={(inputProps) => (
                                    <RadioGroup
                                      {...inputProps}
                                      orientation={RadioGroupOrientation.VERTICAL}
                                      data={[
                                        { label: 'Higher', value: 'Higher' },
                                        { label: 'Lower', value: 'Lower' }
                                      ]}
                                    />
                                  )}
                                />
                              )}
                            />
                          }
                        />
                        <i style={{ marginRight: '1.45rem' }}>)</i>
                      </>
                    )}
                  </>
                )}
              />
            </div>

            <Q
              label={questionnaireDemoLabels.transactionsExpectExactly}
              children={
                <formApi.Field
                  name="questionnaireDemo.transactionsExpectExactly"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Increase', value: 'Increase' },
                            { label: 'Decrease', value: 'Decrease' },
                            { label: 'Stable', value: 'Stable' },
                            { label: 'Unknown', value: 'Unknown' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values?.questionnaireDemo?.transactionsExpectExactly === 'Unknown' && (
                    <Q
                      label={questionnaireDemoLabels.transactionsExpectExplain}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaireDemo.transactionsExpectExplain"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />

            <Q
              label={questionnaireDemoLabels.transactionsMatchTrans}
              children={
                <formApi.Field
                  name="questionnaireDemo.transactionsMatchTrans"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaireDemo.transactionsMatchTrans === 'No' && (
                    <Q
                      label={questionnaireDemoLabels.transactionsNotes}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaireDemo.transactionsNotes"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
      <PageSection title="Covenants/Agreements">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.covenantComplying}
              children={
                <formApi.Field
                  name="questionnaireDemo.covenantComplying"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.covenantNonComplianceDesc}
              multiline
              children={
                <formApi.Field
                  name="questionnaireDemo.covenantNonComplianceDesc"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.covenantTransparent}
              children={
                <formApi.Field
                  name="questionnaireDemo.covenantTransparent"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
      <PageSection title="Site Visits">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.siteVisitsFrequency}
              children={
                <formApi.Field
                  name="questionnaireDemo.siteVisitsFrequency"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup data={loaderData?.siteVisitsFrequencyOptions} {...inputProps} />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsLastVisitDate}
              children={
                <formApi.Field
                  name="questionnaireDemo.siteVisitsLastVisitDate"
                  children={(field) => <FF field={field} input={(inputProps) => <DatePicker {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsComplyingRegulations}
              children={
                <formApi.Field
                  name="questionnaireDemo.siteVisitsComplyingRegulations"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsViolationRectified}
              children={
                <formApi.Field
                  name="questionnaireDemo.siteVisitsViolationRectified"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsScoresAcceptable}
              children={
                <formApi.Field
                  name="questionnaireDemo.siteVisitsScoresAcceptable"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsScoresAcceptableExactly}
              children={
                <formApi.Field
                  name="questionnaireDemo.siteVisitsScoresAcceptableExactly"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Improving', value: 'Improving' },
                            { label: 'Declining', value: 'Declining' },
                            { label: 'Stable', value: 'Stable' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.siteVisitsShutdownRisk}
              children={
                <formApi.Field
                  name="questionnaireDemo.siteVisitsShutdownRisk"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
      <PageSection title="Ownership & Management">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.ownershipChanges}
              children={
                <formApi.Field
                  name="questionnaireDemo.ownershipChanges"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.ownershipProvidedForms}
              children={
                <formApi.Field
                  name="questionnaireDemo.ownershipProvidedForms"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.ownershipChangesApproved}
              children={
                <formApi.Field
                  name="questionnaireDemo.ownershipChangesApproved"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.ownershipOFACTLOReviewed}
              children={
                <formApi.Field
                  name="questionnaireDemo.ownershipOFACTLOReviewed"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.ownershipBadNews}
              children={
                <formApi.Field
                  name="questionnaireDemo.ownershipBadNews"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaireDemo.ownershipBadNews === 'Yes' && (
                    <Q
                      label={questionnaireDemoLabels.ownershipBadNewsDesc}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaireDemo.ownershipBadNewsDesc"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
            <Q
              label={questionnaireDemoLabels.ownershipBadNewsCleared}
              children={
                <formApi.Field
                  name="questionnaireDemo.ownershipBadNewsCleared"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaireDemo.ownershipBadNewsCleared === 'No' && (
                    <Q
                      label={questionnaireDemoLabels.ownershipBadNewsClearedExplain}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaireDemo.ownershipBadNewsClearedExplain"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
      <PageSection title="Recommendations">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaireDemoLabels.recommendationsRecommended}
              children={
                <formApi.Field
                  name="questionnaireDemo.recommendationsRecommended"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.recommendationsStatus}
              children={
                <formApi.Field
                  name="questionnaireDemo.recommendationsStatus"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Satisfactory', value: 'Satisfactory' },
                            { label: 'Requires Additional Monitoring', value: 'Requires Additional Monitoring' },
                            { label: 'Unsatisfactory', value: 'Unsatisfactory' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaireDemoLabels.recommendationsChangeVisits}
              children={
                <formApi.Field
                  name="questionnaireDemo.recommendationsChangeVisits"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup data={loaderData?.recommendationsChangeVisitsOptions} {...inputProps} />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
    </>
  )
}
