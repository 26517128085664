import { ReviewFormUploadWrap } from '../ReviewFormUploadWrap'
import { ComplianceReviewFormUploadProps } from './ComplianceReviewFormUploadProps'

export const ComplianceReviewFormUpload = ({ application }: ComplianceReviewFormUploadProps) => (
  <ReviewFormUploadWrap
    application={application}
    title="Add the Compliance Review"
    onlyNotes={true}
    requiredNotes={true}
    notes={application.reviews?.compliance?.Notes || ''}
    buttonName="Add Review"
    fieldName="internalReviewCompliance"
  />
)
