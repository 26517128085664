import { RelationshipsReportItem } from 'commons/types/DTO/bank'
import { TableColumn } from 'ui/components'

import { CheckMark } from '../../components'

export const columns: TableColumn<RelationshipsReportItem>[] = [
  {
    title: 'Relationship Name',
    key: 'relationshipName',
    align: 'left'
  },
  {
    title: 'DBA',
    key: 'dba'
  },
  {
    title: 'Holding Company',
    key: 'holdingCompany',
    align: 'left'
  },
  {
    title: 'Industry Sub-Type',
    key: 'businessType'
  },
  {
    title: 'Customer Status',
    key: 'customerStatus'
  },
  {
    title: 'State',
    key: 'state'
  },
  {
    title: 'In Application Process',
    render: ({ inApplicationProcess }) => <CheckMark active={inApplicationProcess} />
  }
]
