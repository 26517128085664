import { HistoryEntity } from '../historyAudit'
import { DailyReport } from './DailyReport'
import { SalesSourceLicense } from './SalesSourceLicense'
import { UploadedFile } from './UploadedFile'

export class SalesDataSourceWebPage {
  dailyReports: DailyReport[]
  dailyReportsPOS: DailyReport[]
  editButtonTooltip: string
  enableApprove: boolean
  enableDelete: boolean
  enableDownloadCSV: boolean
  enableEdit: boolean
  enableReject: boolean
  enableUploadFiles: boolean
  infoClientNotes: string
  infoEnableEditRejectionReason: boolean
  infoInternalNotes: string
  infoInternalNotesLabel: string
  infoLastModified: string
  infoLastRejectionReason: string
  infoLicenses: SalesSourceLicense[]
  infoMonth: string
  infoRejectionReason: string
  infoReportId: number
  infoReportName: string
  infoStatus: string
  history: HistoryEntity[]
  historyTotalCount: number
  monthlyAnalyticsId: number
  monthlyAnalyticsName: string
  quarterlyAnalyticsId: number
  quarterlyAnalyticsName: string
  salesDataSourceId: number
  salesDataSourceName: string
  title: string
  titleOverline: string

  uploadedFiles: UploadedFile[]
  uploadedFilesTotalCount: number

  totalSalesTaxCollectedLabel: string

  totalCannabisSales: number
  totalNonCannabisSales: number
  totalSalesTaxCollected: number
  totalSales: number
}
