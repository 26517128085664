import { useCallback } from 'react'

import { useNavigate, useRouter } from '@tanstack/react-router'
import { BankApplicationPaths } from 'commons/constants/routes'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { HeaderButton, IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'
import { useDeleteApplication } from '~bank-onboarding/hooks/useDeleteApplication'
import { useSubmitDeniedCustomer } from '~bank-onboarding/hooks/useSubmitDeniedCustomer'

import { ActionsProps } from './ActionsProps'

export const Actions = ({ application }: ActionsProps) => {
  const router = useRouter()
  const navigate = useNavigate()
  const { deleteApplication } = useDeleteApplication()
  const { routine: submitDeniedCustomer } = useSubmitDeniedCustomer({
    onSuccess: async () => {
      Toast.success('Application was denied')
      router.invalidate()
    },
    onError: async () => Toast.error('A trouble was occurred while denieding')
  })

  const onDelete = () => {
    deleteApplication(application.applicationID).then(
      () => {
        Toast.success('Application was deleted')
        navigate({ to: BankApplicationPaths.LIST })
      },
      () => {
        Toast.error('Cannot delete application')
      }
    )
  }

  const onDeny = useCallback(async () => {
    await submitDeniedCustomer({ id: application.applicationID })
  }, [application.applicationID, submitDeniedCustomer])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: <>Are you sure want to delete {application.title} application?</>,
    onConfirm: () => onDelete(),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const { Confirmation: DenyConfirmation, open: openDenyConfirmation } = useConfirmation({
    message: <>Are you sure want to deny this application?</>,
    onConfirm: () => onDeny(),
    confirmationButtonText: 'Deny',
    isConfirmationNegative: true
  })

  return (
    <>
      <DeleteConfirmation />
      <DenyConfirmation />
      <ButtonGroup margin="big">
        {application.enableDeleteApplication && (
          <IconButton face="neutral" icon={IconName.DELETE} onClick={openDeleteConfirmation}>
            Delete Application
          </IconButton>
        )}
        {application.enableDenyApplication && (
          <HeaderButton size="middle" overColoredPad={true} onClick={openDenyConfirmation}>
            Deny
          </HeaderButton>
        )}
      </ButtonGroup>
    </>
  )
}
