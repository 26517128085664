import { FC, memo, useEffect, useRef } from 'react'

import * as QRCode from 'qrcode'
import SC from 'styled-components'

//region Styled

const QRBox = SC.div`
  margin: 40px auto 0 auto;
  text-align: center;
  
  canvas {
    border-radius: 8px;
  }
`

//endregion

export interface QRProps {
  url: string
}

export const QR: FC<QRProps> = memo((props) => {
  const { url } = props
  const canvas = useRef(null)

  useEffect(() => {
    if (!!url && !!canvas.current) {
      QRCode.toCanvas(canvas.current, url).then()
    }
  }, [url, canvas])

  if (!url) {
    return null
  }

  return (
    <QRBox>
      <canvas id="qr-code-box" ref={canvas} />
    </QRBox>
  )
})
