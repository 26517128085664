import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const NoBranchInvolved = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].noBranchInvolved` as 'institutionActivitiesInfo[0].noBranchInvolved'}
      children={(field) => (
        <FF
          field={field}
          input={(p) => (
            <CheckBox
              {...p}
              checkBoxLabel="If no branch activity involved, check this box"
              data-qa="form_no_branch_involved"
            />
          )}
        />
      )}
    />
  )
}
