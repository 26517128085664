import { useLoaderData } from '@tanstack/react-router'
import { DatePicker, FF, RadioGroup, TextInput, TextareaInput } from 'ui/components'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { questionnaire1Labels } from '~bank-annual-review/constants/questionnaireLabels'
import { AnnualReviewEditMode } from '~bank-annual-review/types/Manage'

import { useAnnualReviewFormContext } from '../../../context/ManageAnnualReviewContext'

export const Questionnaire1Form = () => {
  const { formApi } = useAnnualReviewFormContext()

  const loaderData = useLoaderData({ from: AnnualReviewPaths.EDIT }) as AnnualReviewEditMode

  if (!formApi.state.values.questionnaire1) return null

  return (
    <>
      <PageSection title="Financials">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.financialsPeriodCollected}
              children={
                <formApi.Field
                  name="questionnaire1.financialsPeriodCollected"
                  children={(field) => <FF field={field} input={(inputProps) => <TextInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaire1Labels.financialsIsMaterialsRegardingIncome}
              multiline
              children={
                <formApi.Field
                  name="questionnaire1.financialsIsMaterialsRegardingIncome"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />

            <Q
              label={questionnaire1Labels.financialsMaterialsNotes}
              multiline
              children={
                <formApi.Field
                  name="questionnaire1.financialsMaterialsNotes"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />

            <Q
              label={questionnaire1Labels.financialsCurrentConditionIsAcceptable}
              children={
                <formApi.Field
                  name="questionnaire1.financialsCurrentConditionIsAcceptable"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire1.financialsCurrentConditionIsAcceptable === 'No' && (
                    <Q
                      label={questionnaire1Labels.financialsAcceptableNotes}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire1.financialsAcceptableNotes"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />

            <Q
              label={questionnaire1Labels.financialsDepositsMatching}
              children={
                <formApi.Field
                  name="questionnaire1.financialsDepositsMatching"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Covenants/Agreements">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.covenantComplying}
              children={
                <formApi.Field
                  name="questionnaire1.covenantComplying"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire1Labels.covenantNonComplianceDesc}
              multiline
              children={
                <formApi.Field
                  name="questionnaire1.covenantNonComplianceDesc"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaire1Labels.covenantTransparent}
              children={
                <formApi.Field
                  name="questionnaire1.covenantTransparent"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Site Visits">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.siteVisitsFrequency}
              children={
                <formApi.Field
                  name="questionnaire1.siteVisitsFrequency"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup data={loaderData?.siteVisitsFrequencyOptions} {...inputProps} />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire1Labels.siteVisitsLastVisitDate}
              children={
                <formApi.Field
                  name="questionnaire1.siteVisitsLastVisitDate"
                  children={(field) => <FF field={field} input={(inputProps) => <DatePicker {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaire1Labels.siteVisitsViolationRectified}
              children={
                <formApi.Field
                  name="questionnaire1.siteVisitsViolationRectified"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire1Labels.siteVisitsScoresAcceptable}
              children={
                <formApi.Field
                  name="questionnaire1.siteVisitsScoresAcceptable"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire1Labels.siteVisitsShutdownRisk}
              children={
                <formApi.Field
                  name="questionnaire1.siteVisitsShutdownRisk"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire1Labels.siteVisitsHasStateAuditAtReviewPeriod}
              children={
                <formApi.Field
                  name="questionnaire1.siteVisitsHasStateAuditAtReviewPeriod"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire1.siteVisitsHasStateAuditAtReviewPeriod === 'Yes' && (
                    <Q
                      label={questionnaire1Labels.siteVisitsStateAuditNotes}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire1.siteVisitsStateAuditNotes"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Ownership & Management">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.ownershipBadNews}
              children={
                <formApi.Field
                  name="questionnaire1.ownershipBadNews"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire1.ownershipBadNews === 'Yes' && (
                    <Q
                      label={questionnaire1Labels.ownershipBadNewsDesc}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire1.ownershipBadNewsDesc"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
            <Q
              label={questionnaire1Labels.ownershipHasMaterialChangesInManagementAtReviewPeriod}
              children={
                <formApi.Field
                  name="questionnaire1.ownershipHasMaterialChangesInManagementAtReviewPeriod"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <formApi.Subscribe
              children={({ values }) => (
                <>
                  {values.questionnaire1.ownershipHasMaterialChangesInManagementAtReviewPeriod === 'Yes' && (
                    <Q
                      label={questionnaire1Labels.ownershipMaterialChangesInManagementNotes}
                      multiline
                      children={
                        <formApi.Field
                          name="questionnaire1.ownershipMaterialChangesInManagementNotes"
                          children={(field) => (
                            <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />
                          )}
                        />
                      }
                    />
                  )}
                </>
              )}
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>

      <PageSection title="Recommendations">
        <AnnualReviewQuestionsSet>
          <AnnualReviewQuestionColumn>
            <Q
              label={questionnaire1Labels.recommendationsRecommended}
              children={
                <formApi.Field
                  name="questionnaire1.recommendationsRecommended"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire1Labels.recommendationsStatus}
              children={
                <formApi.Field
                  name="questionnaire1.recommendationsStatus"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup
                          data={[
                            { label: 'Satisfactory', value: 'Satisfactory' },
                            { label: 'Requires Additional Monitoring', value: 'Requires Additional Monitoring' },
                            { label: 'Unsatisfactory', value: 'Unsatisfactory' }
                          ]}
                          {...inputProps}
                        />
                      )}
                    />
                  )}
                />
              }
            />
            <Q
              label={questionnaire1Labels.recommendationsComments}
              multiline
              children={
                <formApi.Field
                  name="questionnaire1.recommendationsComments"
                  children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
                />
              }
            />
            <Q
              label={questionnaire1Labels.recommendationsChangeVisits}
              children={
                <formApi.Field
                  name="questionnaire1.recommendationsChangeVisits"
                  children={(field) => (
                    <FF
                      field={field}
                      input={(inputProps) => (
                        <RadioGroup data={loaderData?.recommendationsChangeVisitsOptions} {...inputProps} />
                      )}
                    />
                  )}
                />
              }
            />
          </AnnualReviewQuestionColumn>
        </AnnualReviewQuestionsSet>
      </PageSection>
    </>
  )
}
