import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import * as SC from '../../style'
import { ReviewTheTransactionActivityProps } from './ReviewTheTransactionActivityProps'

export const ReviewTheTransactionActivity = ({ formValue, Field }: ReviewTheTransactionActivityProps) => (
  <>
    <SC.MainText>
      Excessive commingling of funds with the personal account of the business’ owner(s), manager(s), or with accounts
      of seemingly unrelated businesses.
    </SC.MainText>
    <SC.SecondText>
      Review the Quarterly Analytics for internal transfers and research the offsetting account.
    </SC.SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewTheTransactionActivityRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewTheTransactionActivityPurposeOfTransferBA"
          label="Purpose of the transfer(s) for business account?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'No Transactions of This Type',
                value: 'no_transactions'
              },
              {
                label: 'Transfer for Tax Purposes',
                value: 'tax_purposes'
              },
              {
                label: 'Transfer for Product Purchases',
                value: 'product_purchases'
              },
              {
                label: 'Transfer for Loan Payment',
                value: 'loan_payment'
              },
              {
                label: 'Transfer for Contribution/Distribution',
                value: 'contribution_distribution'
              },
              {
                label: 'Transfer for Credit Card Payments',
                value: 'card_payments'
              },
              {
                label: 'Unsure of Transfer Purpose – See Quarterly and/or Annual Review Questions',
                value: 'see_quarterly'
              },
              {
                label: 'Other – See Comments',
                value: 'other'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewTheTransactionActivityConsumerAccount"
          label="Does the business owner(s)/account signer(s) have a consumer account?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewTheTransactionActivityPurposeOfTransferCA"
          label="Purpose of transfer(s) for consumer account?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'No Transactions of This Type',
                value: 'no_transactions'
              },
              {
                label: 'Transfer for Tax Purposes',
                value: 'tax_purposes'
              },
              {
                label: 'Transfer for Loan Payment',
                value: 'loan_payment'
              },
              {
                label: 'Transfer for Contribution/Distribution',
                value: 'contribution_distribution'
              },
              {
                label: 'Unsure of Transfer Purpose – See Quarterly and/or Annual Review Questions',
                value: 'see_quarterly'
              },
              {
                label: 'Other – See Comments',
                value: 'other'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          required={
            formValue.reviewTheTransactionActivityPurposeOfTransferBA === 'other' ||
            formValue.reviewTheTransactionActivityPurposeOfTransferCA === 'other'
          }
          name="reviewTheTransactionActivityComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.reviewTheTransactionActivityPurposeOfTransferBA === 'other' ||
            formValue.reviewTheTransactionActivityPurposeOfTransferCA === 'other'
              ? Validators.required.field
              : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
