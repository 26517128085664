import { FieldApi } from '@tanstack/react-form'
import { CheckBoxGroup, FF } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

const FilingTypeRenderer = ({ field }: { field: FieldApi<SarEdit, 'generalInfo.filingType'> }) => {
  const { formApi } = useSARFormContext()

  const userSelectCheckbox = (c: string, v: string[]) => v.includes(c) && !field.state.value.includes(c)

  const deselectCheckbox = (c: string, v: string[]) => {
    const i = v.indexOf(c)
    if (i !== -1) {
      v.splice(i, 1)
    }
  }

  const handleChange = (v: string[]) => {
    const newValue = [...v]

    if (userSelectCheckbox('filingTypeInitial', newValue)) {
      deselectCheckbox('filingTypeCorrectAmend', newValue)
      deselectCheckbox('filingTypeContinuing', newValue)

      formApi.setFieldMeta('generalInfo.priorReportBsaId', (bsaIdMeta) => ({ ...bsaIdMeta, errors: [], errorMap: {} }))
      formApi.setFieldValue('generalInfo.priorReportBsaId', '')
    }

    if (
      userSelectCheckbox('filingTypeCorrectAmend', newValue) ||
      userSelectCheckbox('filingTypeContinuing', newValue)
    ) {
      deselectCheckbox('filingTypeInitial', newValue)
    }

    field.handleChange(newValue)
  }

  return (
    <FF
      label="1. Type of filing"
      field={field}
      input={(p) => (
        <CheckBoxGroup
          {...p}
          data-qa={'1. Type of filing'}
          direction="horizontal"
          data={[
            { label: 'Initial report', value: 'filingTypeInitial' },
            { label: 'Correct/amend prior report', value: 'filingTypeCorrectAmend' },
            { label: 'Continuing activity report', value: 'filingTypeContinuing' },
            { label: 'Joint report', value: 'filingTypeJoint' }
          ]}
          onChange={handleChange}
        />
      )}
    />
  )
}

export const FilingType = () => {
  const { formApi } = useSARFormContext()

  return <formApi.Field name="generalInfo.filingType" children={(field) => <FilingTypeRenderer field={field} />} />
}
