import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react'

import { useOpenHandler } from 'ui/components/Select/hooks'
import { ChoiceOption, ChoiceValue } from 'ui/types'

import { ModelData } from '../types'

export const fillSelectedOptions = (data: ModelData[], selectedValues: ChoiceValue[]) =>
  (selectedValues || [])
    .map((selectedValue) => (data || []).find((el) => el.data.value === selectedValue))
    .filter((el) => el !== undefined)

export const fillModelData = (
  newData: ChoiceOption[],
  oldModelData: ModelData[],
  selectedValues: ChoiceValue[],
  activeIndex?: number | null
) => {
  const selectedOptionsNotInNewData = oldModelData.filter(
    (oldDataEl) =>
      oldDataEl.selected && newData.find((newDataEl) => newDataEl.value === oldDataEl.data.value) === undefined
  )
  const newModelData = selectedOptionsNotInNewData.concat(
    (newData || []).map((el, index) => ({
      data: el,
      index,
      selected: (selectedValues || []).includes(el.value),
      active: activeIndex !== null ? index === activeIndex : false
    }))
  )

  newModelData.forEach((el, index) => (el.index = index))

  return newModelData
}

export const useManageState = (
  inputData: ChoiceOption[],
  inputSelectedValues: ChoiceValue[],
  isPreferServerData = false,
  inputRef: MutableRefObject<HTMLInputElement | null>
) => {
  const [data, setData] = useState<ChoiceOption[]>(inputData || [])
  const [searchText, setSearchText] = useState('')
  const [selectedValues, setSelectedValues] = useState<ChoiceValue[]>(inputSelectedValues || [])
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [isSearching, setIsSearching] = useState(false)
  const { open, handleOpen, handleClose } = useOpenHandler(inputRef)

  // support initial render with passed data options, so fill model data by input data independently
  // from isPreferServerData
  const [modelData, setModelData] = useState<ModelData[]>(
    fillModelData(inputData, [], inputSelectedValues, activeIndex)
  )
  const selectedOptions = useMemo(() => fillSelectedOptions(modelData, selectedValues), [modelData, selectedValues])

  // TODO: make separate useffect for update selected values
  // sync data if it will change outside,
  // only for data mode without fetching from server (isPreferServerData = false) */
  useEffect(() => {
    if (isPreferServerData) {
      return
    }
    setModelData(
      inputData
        ? inputData.map((data, index) => ({
            data,
            index,
            selected: (inputSelectedValues || []).includes(data.value),
            active: index === activeIndex
          }))
        : []
    )
    setData(inputData || [])
    // run effect only on input data changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(inputData)])

  const setDataCallback = useCallback(
    (newData: ChoiceOption[]) => {
      setActiveIndex(null)
      setModelData([...fillModelData(newData, modelData, selectedValues)])
    },
    [selectedValues, modelData]
  )

  const selectValue = useCallback(
    (value: ChoiceValue) => {
      if (isSearching) {
        return
      }
      const newModelData = [...modelData]
      const newSelectedValues = [value].concat(selectedValues)
      const selectedValue = newModelData.find((el) => el.data.value === value)
      if (selectedValue) {
        selectedValue.selected = true
      }

      setModelData(newModelData)
      setSelectedValues(newSelectedValues)
    },
    [modelData, selectedValues, isSearching]
  )

  const unselectValue = useCallback(
    (value: ChoiceValue) => {
      if (isSearching) {
        return
      }

      const indexToRemove = selectedValues.indexOf(value)
      if (indexToRemove === -1) {
        // make exception?
        return
      }

      const newSelectedValues = [...selectedValues]
      const newModelData = [...modelData]

      newSelectedValues.splice(indexToRemove, 1)
      const unselectedValue = newModelData.find((el) => el.data.value === value)
      if (unselectedValue) {
        unselectedValue.selected = false
      }

      setModelData(newModelData)
      setSelectedValues(newSelectedValues)
    },
    [modelData, selectedValues, isSearching]
  )

  const clearValues = useCallback(() => {}, [])

  const handleCloseCallback = useCallback(() => {
    handleClose()
    setActiveIndex(null)
    setSearchText('')
    if (!isPreferServerData) {
      setDataCallback(data)
    }
  }, [handleClose, data, setDataCallback, isPreferServerData])

  return {
    data,
    searchText,
    selectedValues,
    selectedOptions,
    activeIndex,
    isSearching,
    modelData,
    open,
    inputValue: searchText,

    setData: setDataCallback,
    setModelData,
    setIsSearching,
    setActiveIndex,
    setSearchText,
    unselectValue,
    selectValue,
    clearValues,

    handleClose: handleCloseCallback,
    handleOpen
  }
}
