import { PropsWithChildren } from 'react'

import { QuarterlyReportWebPage } from 'commons/types/DTO/bank/quarterlyReport'
import { SaveNotesRequest } from '~bank-quarterly-report/types'

export type GeneralInformationProps = PropsWithChildren<{
  item: QuarterlyReportWebPage
  openMonthly: () => void
  onSaveNotes: (payload: SaveNotesRequest) => Promise<void>
}>
