import { useState } from 'react'

import { useNavigate, useParams } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { DocumentsPaths } from '~bank-documents/constants'
import { useDeleteDocumentPeriod } from '~bank-documents/service/deleteDocumentPeriod'

import { bankDocumentMessages } from '../messages/bankDocumentMessages'

type UseDeletePeriodConfirmationReturn = {
  docPeriodForDelete: number | null
  DeletePeriodConfirmation: () => JSX.Element | null
  showDeletePeriodConfirmation: (periodId: number) => void
}

export const useDeletePeriodConfirmation = (): UseDeletePeriodConfirmationReturn => {
  const navigate = useNavigate()

  const documentId = useParams({ strict: false, select: (s) => s.documentId })

  const [docPeriodForDelete, setDocPeriodForDelete] = useState<number | null>(null)

  const { routine: deleteDocumentPeriod } = useDeleteDocumentPeriod({
    onSuccess: async () => {
      await navigate({ to: DocumentsPaths.DETAILS, params: { documentId }, resetScroll: false })
      Toast.successSmall(bankDocumentMessages['documentPeriod.successDelete'])
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: onShowDeletePeriodConfirmation, Confirmation: DeletePeriodConfirmation } = useConfirmation<{
    periodId: number
  }>({
    message: 'Are you sure you want to delete this period?',
    onConfirm: async (v) => {
      await deleteDocumentPeriod(Number(v?.periodId))
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true,
    onCancel: () => setDocPeriodForDelete(null)
  })

  const showDeletePeriodConfirmation = (periodId: number) => {
    setDocPeriodForDelete(periodId)
    onShowDeletePeriodConfirmation({ periodId })
  }

  return { docPeriodForDelete, DeletePeriodConfirmation, showDeletePeriodConfirmation }
}
