import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const TerrorismFinancing = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="suspiciousActivityInfo.terroristFinancing"
      children={(field) => (
        <FF
          field={field}
          label="33. Terrorist Financing"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'33. Terrorist Financing'}
              direction={'vertical'}
              data={[
                { label: 'a. Known or suspected terrorist/terrorist organization', value: 'a' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
