import { useCallback, useMemo, useState } from 'react'

import { useDeleteDocument } from 'bank-documents/src/service/deleteDocument'
import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { FilterCompanyDocumentsWebPage } from 'commons/service/bank/companyDetails'
import { CompanyDocumentsWebPage, Document } from 'commons/types/DTO/bank/companyDetails/document'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useTableState } from 'ui/components/QueryTable'
import { useConfirmation } from 'ui/hooks'

export const useComponentsEssence = (
  filterCompanyDocumentsWebPage: (filter: FilterCompanyDocumentsWebPage) => void,
  webPage: CompanyDocumentsWebPage
) => {
  const { config } = webPage
  const [loading, setLoading] = useState(false)
  const initialFilter = useMemo(
    () => ({
      alerts: config.alerts,
      frequency: config.frequency,
      limit: config.limit,
      offset: config.offset,
      relationshipID: config.relationshipID,
      sortBy: config.sortBy,
      sortDesc: config.sortDesc,
      subjectType: config.subjectType,
      isInternal: config.isInternal,
      licenseID: config.licenseID,
      bankAccountID: config.bankAccountID,
      contactID: config.contactID
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState<FilterCompanyDocumentsWebPage>(initialFilter)

  const tableState = useTableState({
    filter: {},
    order: [{ field: initialFilter.sortBy, direction: initialFilter.sortDesc ? 'DESC' : 'ASC' }]
  })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = {
        ...tableFilter,
        offset: (page - 1) * size,
        limit: size
      }

      setTableFilter(filter)

      filterCompanyDocumentsWebPage(filter)

      setLoading(false)
    },
    [filterCompanyDocumentsWebPage, tableFilter, tableState]
  )

  const onFilterChange = useCallback(
    async (value: FilterCompanyDocumentsWebPage) => {
      setLoading(true)

      const filter = {
        ...value,
        offset: undefined,
        limit: undefined
      }

      tableState.onPageChange(1, tableState.size)
      tableState.filterForm.set({ value })

      setTableFilter(filter)

      filterCompanyDocumentsWebPage(filter)

      setLoading(false)
    },
    [filterCompanyDocumentsWebPage, tableState]
  )

  const onBeforeChange: BeforeFormChangeHandler<FilterCompanyDocumentsWebPage> = useCallback(
    ({ value, errors }) => {
      if (value.subjectType !== tableFilter.subjectType) {
        value.bankAccountID = initialFilter.bankAccountID
        value.contactID = initialFilter.contactID
        value.licenseID = initialFilter.licenseID
      }
      onFilterChange(value)
      return {
        value,
        errors
      }
    },
    [onFilterChange, tableFilter, initialFilter]
  )

  const filterForm = useFormData<FilterCompanyDocumentsWebPage>({
    initialValue: initialFilter,
    onBeforeChange
  })

  const clearFilter = useCallback(() => {
    onFilterChange({ relationshipID: config.relationshipID })
    tableState.onPageChange(1, tableState.size)
    filterForm.set({ reset: true, value: initialFilter })
  }, [onFilterChange, filterForm, initialFilter, tableState, config.relationshipID])

  const onSort = useCallback(
    async (direction: 'ASC' | 'DESC', field) => {
      const isDesc = direction === 'DESC'

      tableState.onOrderChange({ field, direction })

      setLoading(true)

      const filter = {
        ...tableFilter,
        offset: (tableState.page - 1) * tableState.size,
        limit: tableState.size,
        sortBy: field,
        sortDesc: isDesc
      }

      filterCompanyDocumentsWebPage(filter)

      setLoading(false)
    },
    [filterCompanyDocumentsWebPage, tableState, tableFilter]
  )

  const getCompanyDocumentsAfterDeleteWebPage = () => {
    setLoading(true)

    const isDeletingAnnualReviewLastOnPage =
      webPage.documentsTotalCount === (tableFilter.offset || 0) + 1 && webPage.documentsTotalCount !== 1

    const offset = isDeletingAnnualReviewLastOnPage
      ? (tableFilter?.offset || 0) - (tableFilter?.limit || 0)
      : tableFilter.offset

    filterCompanyDocumentsWebPage({
      ...tableFilter,
      offset: offset
    })

    tableState.onPageChange(isDeletingAnnualReviewLastOnPage ? tableState.page - 1 : tableState.page, tableState.size)

    const filter = {
      ...tableFilter,
      offset: offset
    }

    setTableFilter(filter)

    setLoading(false)
  }

  const [documentForDelete, setDocumentForDelete] = useState<Document | undefined>(undefined)
  const { routine: deleteMutation } = useDeleteDocument({
    onSuccess: async () => {
      await getCompanyDocumentsAfterDeleteWebPage()
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteDocument = useCallback(async () => {
    try {
      await deleteMutation(documentForDelete?.id || 0)

      Toast.successSmall(`Document was successfully deleted.`)
    } catch (error) {
      handlerServerError(error)
    }
  }, [deleteMutation, documentForDelete])

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: (
      <>
        Are you sure want to delete
        <br />
        <i>{documentForDelete?.documentName}</i> document?
      </>
    ),
    onConfirm: deleteDocument,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDeleteDocument = (document: Document | undefined) => {
    setDocumentForDelete(document)
    openDeleteConfirmation()
  }

  return {
    loading,
    tableState,
    filterForm,
    changePagination,
    onSort,
    onDeleteDocument,
    DeleteConfirmation,
    openDeleteConfirmation,
    deleteDocument,
    clearFilter
  }
}
