import { getFrequencyLabel } from './getFrequencyLabel'

export interface FrequencyLabelProps {
  name: string
  dataQa?: string
}

export const FrequencyLabel: React.FunctionComponent<FrequencyLabelProps> = ({ name, dataQa }: FrequencyLabelProps) => (
  <span data-qa={dataQa}>{getFrequencyLabel(name)}</span>
)
