import { ChangeEvent, memo, useCallback, useEffect, useRef, useState } from 'react'

import * as SC from '../styles'
import { IconName, IconSize, Popper } from 'ui/components'
import { InputHint, Required } from 'ui/core'
import { ChoiceOption, ChoiceValue } from 'ui/types'

import {
  HEIGHT,
  MAX_OPTIONS_VISIBLE,
  NOT_SELECTED_OPTION_INDEX,
  SELECT_PLACEHOLDER,
  SELECT_PLACEHOLDER_SEARCHABLE
} from '../constants'
import { useActiveIndex, useOpenHandler, useSelectedIndex } from '../hooks'
import { findSelectedOptionIndex, getInputValue, getOptionsQaAttrs } from '../utils'
import { SelectProps } from './SelectProps'
import { Option } from './components/Option'
import { SelectContext } from './context'

export const Select = memo((props: SelectProps) => {
  const {
    value,
    error,
    required = false,
    onChange,
    data,
    placeholder,
    emptyOption,
    onSearch,
    onOpen,
    hint,
    hideHint,
    searchable = false,
    disabled = false,
    'data-qa': dataQaProps,
    'options-data-qa': optionsDataQaProps,
    'data-testid': dataTestid,
    ...rest
  } = props

  const finalPlaceholder = placeholder || (searchable ? SELECT_PLACEHOLDER_SEARCHABLE : SELECT_PLACEHOLDER)

  if (!data) {
    throw new Error('Data property should be provided.')
  }

  const listBoxRef = useRef<HTMLInputElement | null>(null)
  const listRef = useRef([])
  const { open, handleOpen, handleClose } = useOpenHandler(listBoxRef, onOpen)
  const { activeIndex, setActiveIndex } = useActiveIndex(0)
  const { selectedIndex, setSelectedIndex } = useSelectedIndex(findSelectedOptionIndex(data, value))
  const [filteredData, setFilteredData] = useState(data)
  const [searchValue, setSearchValue] = useState<string>('')
  const [inSearch, setInSearch] = useState(false)

  const selectedOption: ChoiceOption | null = selectedIndex !== null ? data[selectedIndex - 1] : null
  const isEmpty = selectedIndex !== null && !data[selectedIndex - 1] && !searchValue
  const showRequired = required && !error && !disabled

  // sync selected index when data updated outside
  useEffect(() => {
    const index = findSelectedOptionIndex(data, value)
    setSelectedIndex(index)
    setFilteredData(data)
  }, [data, value, setSelectedIndex])

  // filter data on search
  useEffect(() => {
    if (searchable) {
      if (searchValue?.length && inSearch) {
        const filtered = data.filter((i) =>
          searchValue.trim() ? (i.label.toLowerCase() || '').indexOf(searchValue.toLowerCase()) !== -1 : true
        )
        setFilteredData(filtered)
      } else {
        setFilteredData(data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchable, data, searchValue])

  const setSelectedIndexWrapper = useCallback(
    (index: number) => {
      setInSearch(false)
      setSelectedIndex(index)
    },
    [setSelectedIndex]
  )

  const focusAndSelect = () => {
    if (listBoxRef.current instanceof HTMLInputElement) {
      listBoxRef.current.focus()
      listBoxRef.current.select()
    }
  }

  const handleCloseWrapper = useCallback(() => {
    if (inSearch) {
      setInSearch(false)
      setFilteredData(data)
    }
    handleClose()
  }, [handleClose, setFilteredData, data, inSearch])

  const reset = useCallback(() => {
    setActiveIndex(null)
    setSelectedIndex(0)
    setFilteredData(data)
    setInSearch(false)
  }, [setActiveIndex, setSelectedIndex, data])

  const setOption = useCallback(
    (index: number, value: ChoiceValue) => {
      // use Timeout, coz if we update input value, selection is reset
      setTimeout(focusAndSelect, 0)
      if (index == NOT_SELECTED_OPTION_INDEX) {
        reset()
        if (onChange) {
          onChange(null, undefined)
        }
        return
      }
      const selectedValue = filteredData[index - 1].value
      const newIndex = findSelectedOptionIndex(data, selectedValue)
      setSelectedIndex(newIndex)
      setFilteredData(data)
      setActiveIndex(null)
      setInSearch(false)
      if (onChange) {
        onChange(value, undefined)
      }
    },
    [filteredData, data, onChange, reset, setActiveIndex, setSelectedIndex]
  )

  const handleOpenWrapper = useCallback(() => {
    if (onSearch || searchable) {
      if (selectedIndex) {
        setSearchValue(data[selectedIndex - 1].label)
      }
      focusAndSelect()
    }
    handleOpen()
  }, [data, searchable, selectedIndex, handleOpen, onSearch])

  const renderOptions = (close: () => void, getItemProps) => (
    <>
      {!!emptyOption && (
        <Option
          key={0}
          index={0}
          value={emptyOption.value}
          isEmpty={true}
          dataQA={getOptionsQaAttrs(emptyOption, optionsDataQaProps)}
          close={close}
          getItemProps={getItemProps}
        >
          {emptyOption.label}
        </Option>
      )}
      {filteredData.map((option, index) => (
        <Option
          key={index + 1}
          index={index + 1}
          value={option.value}
          dataQA={getOptionsQaAttrs(option, optionsDataQaProps)}
          close={close}
          getItemProps={getItemProps}
        >
          {option.label}
        </Option>
      ))}
    </>
  )

  const isRenderInput = searchable || onSearch

  return (
    <SelectContext.Provider
      value={{
        selectedIndex,
        setSelectedIndex: setSelectedIndexWrapper,
        activeIndex,
        setActiveIndex,
        setOption,
        listRef,
        onChange
      }}
    >
      <Popper
        scrollClose={true}
        onOpen={handleOpenWrapper}
        onClose={handleCloseWrapper}
        autoSize={true}
        autoSizeMaxHeight={HEIGHT * MAX_OPTIONS_VISIBLE}
        autoSizeMinHeight={HEIGHT}
        listRef={listRef}
        activeIndex={activeIndex !== null ? activeIndex : undefined}
        setActiveIndex={setActiveIndex}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndexWrapper}
        placement="bottom-start"
        style={SC.PopperStyles(data.length === 0)}
        disabled={disabled}
        /* popper inside content */
        render={({ close, getItemProps }) => <SC.UlStyled>{renderOptions(close, getItemProps)}</SC.UlStyled>}
      >
        {/* popper show/hide trigger */}
        {/* this container needed by floating ui, without it floating doesn't handle click on select */}
        <SC.ReferenceOuter
          {...rest}
          data-testid={dataTestid}
          tabIndex={isRenderInput ? undefined : 0}
          role="listbox"
          data-qa={isRenderInput ? undefined : dataQaProps}
          $isEmpty={isEmpty}
          $error={!!error}
          disabled={disabled}
        >
          {showRequired && <Required data-testid="required" isInputEmpty={isEmpty} />}
          {isRenderInput && !disabled && (
            <>
              <SC.InputForSelect
                $opened={open}
                disabled={disabled}
                ref={listBoxRef}
                value={getInputValue(inSearch, searchValue, selectedIndex, filteredData)}
                type="text"
                data-qa={dataQaProps}
                data-testid="search-value"
                placeholder={finalPlaceholder}
                readOnly={!open}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setInSearch(true)
                  setSearchValue(event.target.value)
                  if (onSearch) {
                    onSearch(event.target.value)
                  }
                  if ((onSearch || searchable) && filteredData.length) {
                    setActiveIndex(1)
                  }
                }}
                onBlur={(e) => {
                  e.stopPropagation()
                }}
                autoComplete="off"
              />
              <SC.WrappedIcon name={IconName.DOWN} size={IconSize.XS} $open={open} />
            </>
          )}
          {((!onSearch && !searchable) || disabled) && (
            <>
              <SC.SelectedLabel disabled={disabled} data-qa={dataQaProps}>
                {selectedOption?.label || finalPlaceholder}
              </SC.SelectedLabel>
              <SC.WrappedIcon name={IconName.DOWN} size={IconSize.XS} $open={open} />
            </>
          )}
        </SC.ReferenceOuter>
      </Popper>
      <InputHint data-testid="hint" hint={hint} disabled={disabled} hideHint={hideHint} />
    </SelectContext.Provider>
  )
})
