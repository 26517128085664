import { FC, memo } from 'react'

import { YesNoRadioGroup } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils/currency'
import { DateTime, IconSize, RadioGroup, TextMask } from 'ui/components'
import { Info, InfoSet } from 'ui/components/Info'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import { ContactAdditionalInformationDetailsProps } from './ContactAdditionalInformationDetailsProps'

export const ContactAdditionalInformationDetails: FC<ContactAdditionalInformationDetailsProps> = memo((props) => {
  const {
    value,
    state: {
      isDebtholderRole,
      isOwnerRole,
      isAccountSignerRole,
      contactPersonalAddressLabel,
      showOwnerMailingAddress,
      showAccountSignerMailingAddress,
      showOwnerPhysicalAddress,
      conditionPercentOwnerSsn,
      showOwnerSsn
    }
  } = props

  const isMailingAddressEnable = showAccountSignerMailingAddress || showOwnerMailingAddress
  const showOwnerSsnBlock =
    isAccountSignerRole ||
    (isOwnerRole && showOwnerSsn && +(value.ownerOwnershipPercent ?? 0) >= (conditionPercentOwnerSsn ?? 0))
  const showBirthdateBlock = isAccountSignerRole
  const showPhysicalAddressBlock = isAccountSignerRole || (isOwnerRole && showOwnerPhysicalAddress)

  return (
    <InfoSet legend="Additional Information">
      {isDebtholderRole && (
        <>
          <Row>
            <Col>
              <Info label="Debt Amount">{formatCurrencyCent(value.debtholderDebtAmount)}</Info>
            </Col>
            <Col>
              <Info label="Payment Frequency">{value.debtholderPaymentFrequency}</Info>
            </Col>
          </Row>
          <Row>
            <Col>
              <Info label="Payment Amount">{formatCurrencyCent(value.debtholderPaymentAmount)}</Info>
            </Col>
            <Col>
              <Info label="Maturity Date">
                <DateTime date={value.debtholderMaturityDate} />
              </Info>
            </Col>
          </Row>
        </>
      )}

      {isOwnerRole && (
        <>
          <Row>
            <Col>
              <Info label="Investment">{formatCurrencyCent(value.ownerInvestmentAmount)}</Info>
            </Col>
            <Col>
              <Info label="Ownership">{`${value.ownerOwnershipPercent} %`}</Info>
            </Col>
          </Row>
        </>
      )}

      {(showBirthdateBlock || showOwnerSsnBlock) && (
        <>
          <Row>
            <Col>
              {showBirthdateBlock && (
                <Info label="Date of Birth">
                  <DateTime date={value.birthdate} />
                </Info>
              )}
            </Col>
            <Col>
              {showOwnerSsnBlock && (
                <Info label="Social Security Number / Tax ID Number">
                  <TextMask text={value.EINSSN} iconSize={IconSize.XS} valueType="SSN" />
                </Info>
              )}
            </Col>
          </Row>
        </>
      )}
      {showPhysicalAddressBlock && (
        <>
          <InfoSet legend={contactPersonalAddressLabel}>
            <Row>
              <Col>
                <Info label="Street">{value.street}</Info>
              </Col>
              <Col>
                <Info label="City">{value.city}</Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="State">{value.state}</Info>
              </Col>
              <Col>
                <Info label="Country">{value.country}</Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <Info label="Zip/Postal Code">{value.postalCode}</Info>
              </Col>
            </Row>
          </InfoSet>
          {isMailingAddressEnable && (
            <>
              <InfoSet legend="Mailing Address">
                <Row>
                  <Col>
                    <Info label="Street">{value.mailingStreet}</Info>
                  </Col>
                  <Col>
                    <Info label="City">{value.mailingCity}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="State/Province">{value.mailingState}</Info>
                  </Col>
                  <Col>
                    <Info label="Country">{value.mailingCountry}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="Zip/Postal Code">{value.mailingPostalCode}</Info>
                  </Col>
                </Row>
              </InfoSet>
            </>
          )}
          <Row>
            <Col2x>
              <Info label="Is this individual/entity a US Citizen?">
                <RadioGroup
                  readonly
                  data={[
                    { value: YesNoRadioGroup.YES, label: 'Yes' },
                    { value: YesNoRadioGroup.NO, label: 'No' }
                  ]}
                  value={value.USCitizen}
                />
              </Info>
            </Col2x>
          </Row>
        </>
      )}
    </InfoSet>
  )
})
