// types packages/commons/types/DTO/bank/license/licensesSics.ts are dublicated this
export type LicenseDeprecated = {
  active: boolean | null
  city: string | null
  city_tax: number | null
  company_id: number | undefined
  county_tax: number | null
  created_at: string | null
  deleted_at: string | null
  expiration_date: string | null
  id: number
  internal_transfers: boolean
  issue_date: string | null
  license_number: string | null
  mj_retail_tax: number | null
  name: string
  phone: string | null
  pos_type: string | null
  pos_type_other: string | null
  postal_code: string | null
  sf_external_id: string | null
  special_tax: number | null
  state: string | null
  state_tax: number | null
  street_address: string | null
  subtype: string | null
  type: string | null
  updated_at: string | null
}
