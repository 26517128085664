import { FC, PropsWithChildren, memo } from 'react'

import * as SC from './styles'

/*
   !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
   !!! Do NOT use for confirmation dialogs !!!
   !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
export const FormContentWrapper: FC<PropsWithChildren> = memo(({ children }) => {
  return <SC.Wrapper>{children}</SC.Wrapper>
})
