import { FC } from 'react'

import styled from 'styled-components'

const PageContainer: FC<{ $barMinimized: boolean; children: React.ReactNode }> = (props) => (
  <div {...props}>{props.children}</div>
)

const StyledPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  left: 0;
  top: 0;
  height: 100vh;
  transition: padding-left 0.2s ease-in;
  background: #f5f5f5;
  padding-left: ${(props) => (props.$barMinimized ? '72px' : '224px')};
  @media print {
    padding-left: 0;
  }
`

export default StyledPageContainer
