import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const Fraud = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="suspiciousActivityInfo.fraud"
      children={(field) => (
        <FF
          field={field}
          label="34. Fraud"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'34. Fraud'}
              direction={'vertical'}
              data={[
                { label: 'a. ACH', value: 'a' },
                { label: 'b. Advance fee', value: 'b' },
                { label: 'c. Business loan', value: 'c' },
                { label: 'd. Check', value: 'd' },
                { label: 'e. Consumer loan', value: 'e' },
                { label: 'f. Credit/Debit card', value: 'f' },
                { label: 'g. Healthcare/Public or private health insurance', value: 'g' },
                { label: 'h. Mail', value: 'h' },
                { label: 'i. Mass-marketing', value: 'i' },
                { label: 'j. Ponzi scheme', value: 'j' },
                { label: 'k. Pyramid scheme', value: 'k' },
                { label: 'l. Securities fraud', value: 'l' },
                { label: 'm. Wire', value: 'm' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
