import { useCallback, useState } from 'react'

import { getRouteApi, useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { InvoiceSubmittalPaths } from '~bank-invoices/constants'
import { useUploadInvoiceFile } from '~bank-invoices/service/uploadInvoiceFile'

const routeApi = getRouteApi(InvoiceSubmittalPaths.DETAILS)

export const useUpload = () => {
  const router = useRouter()
  const id = routeApi.useParams({ select: (s) => s.id })

  const [invoiceFile, setInvoiceFile] = useState<File | undefined>()

  const { routine: saveInvoiceFile } = useUploadInvoiceFile({
    onSuccess: async () => {
      Toast.successSmall(`Invoice File uploaded success.`)
      router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  const saveInvoiceFileHandler = useCallback(async () => {
    if (invoiceFile) {
      saveInvoiceFile({
        invoiceSubmittalId: String(id),
        file: invoiceFile
      })
    }
  }, [id, saveInvoiceFile, invoiceFile])

  const { Confirmation: InvoiceFileUploadConfirmation, open: openFilesUploadConfirmation } = useConfirmation({
    message: `Please confirm upload file: "${invoiceFile?.name}."`,
    onConfirm: saveInvoiceFileHandler,
    confirmationButtonText: 'Submit'
  })

  const fileInputChangeHandler = (value) => {
    if (!(value?.length > 0)) {
      return
    }
    setInvoiceFile(value[0])
    openFilesUploadConfirmation()
  }

  return { InvoiceFileUploadConfirmation, fileInputChangeHandler }
}
