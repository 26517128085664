import { Validators } from 'ui/components/Form'
import { TextareaInput } from 'ui/components/InputV2'
import { Select } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import * as SC from '../../style'
import { ReviewWireAndACHProps } from './ReviewWireAndACHProps'

export const ReviewWireAndACH = ({ formValue, Field }: ReviewWireAndACHProps) => (
  <>
    <SC.MainText>
      A surge in activity by third parties offering goods or services to marijuana-related business, such as equipment
      suppliers or shipping servicers.
      <br />
      The business is unable to demonstrate the legitimate source of significant outside investments.
    </SC.MainText>
    <SC.SecondText>
      Review Wire & ACH activity for unknown merchant or vendor activity. Google the names to determine if the merchant
      is possibly an equipment supplier or shipping servicer.
      <br />
      Deposit activity – please see “Deposits by third parties...” bullet for listing of conductors reviewed this
      quarter.
    </SC.SecondText>
    <FormRow>
      <Col>
        <Field
          required
          name="reviewWireAndACHRedFlag"
          label="Red Flag?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Yes',
                value: 'yes'
              },
              {
                label: 'No',
                value: 'no'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewWireAndACHWireActivity"
          label="Wire Activity?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Ok',
                value: 'ok'
              },
              {
                label: 'See comments',
                value: 'see_comments'
              },
              {
                label: 'No Transactions of This Type',
                value: 'no_transactions'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
      <Col>
        <Field
          required
          name="reviewWireAndACHActivity"
          label="ACH Activity?"
          input={Select}
          inputProps={{
            data: [
              {
                label: 'Ok',
                value: 'ok'
              },
              {
                label: 'See comments',
                value: 'see_comments'
              },
              {
                label: 'No Transactions of This Type',
                value: 'no_transactions'
              }
            ]
          }}
          validator={Validators.required.field}
        />
      </Col>
    </FormRow>
    <FormRow>
      <Col2x>
        <Field
          debounced
          required={
            formValue.reviewWireAndACHWireActivity === 'see_comments' ||
            formValue.reviewWireAndACHActivity === 'see_comments'
          }
          name="reviewWireAndACHComments"
          label="Comments"
          input={TextareaInput}
          inputProps={{}}
          validator={
            formValue.reviewWireAndACHWireActivity === 'see_comments' ||
            formValue.reviewWireAndACHActivity === 'see_comments'
              ? Validators.required.field
              : undefined
          }
        />
      </Col2x>
    </FormRow>
  </>
)
