import { SelectContext, UseRequiredResult } from '../types'

export const useRequired = (context: SelectContext): UseRequiredResult => {
  const { searchText, selectedValues, required, error, disabled } = context

  const isEmpty = !selectedValues.length && !searchText.length
  const isShowRequired = required && !error && !disabled && isEmpty

  return {
    isShowRequired,
    isEmpty
  }
}
