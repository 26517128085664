import { FC, ReactNode, memo, useContext, useRef, useState } from 'react'

import { useDelayMount } from '../../../hooks/useDelayMount'
import { Portal } from '../../Portal'
import { ConfirmationContext } from './ConfirmationContext'
import { Dialog } from './Dialog'
import { CANCEL_BUTTON, NEGATIVE_SUBMIT_BUTTON, POSITIVE_SUBMIT_BUTTON } from './constants'
import { ConfirmationDialogsProps, showFunctionProps } from './props'

import * as SC from './styles'

export const useConfirmation = () => useContext(ConfirmationContext)

export const ConfirmationDialogs: FC<ConfirmationDialogsProps> = memo((props) => {
  const { children } = props

  const resolver = useRef<(v: boolean) => void>()
  const [opened, setOpened] = useState(false)

  const isShowing = useDelayMount(opened, 200)

  const [message, setMessage] = useState<string | ReactNode>('')
  const [submitButtonText, setSubmitButtonText] = useState<string | undefined>(undefined)
  const [confirmCallback, setConfirmCallback] = useState<(() => Promise<void>) | undefined>(undefined)
  const [isCallbackFinishPending, setIsCallbackFinishPending] = useState(false)
  const [buttons, setButtons] = useState<number>(0)

  const open = () => {
    setOpened(true)
  }

  const close = (result: boolean) => {
    if (isCallbackFinishPending) {
      return
    }
    setOpened(false)
    setConfirmCallback(undefined)
    resolver.current && resolver.current(result)
  }

  const showAskConfirmation = (params: showFunctionProps): Promise<boolean> => {
    setMessage(params.message)
    setSubmitButtonText(params.submitButtonText)
    setConfirmCallback(() => params.confirmCallback)
    setButtons(POSITIVE_SUBMIT_BUTTON | CANCEL_BUTTON)
    open()

    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const showDeleteConfirmation = (params: showFunctionProps): Promise<boolean> => {
    setMessage(params.message)
    setSubmitButtonText(params.submitButtonText)
    setConfirmCallback(() => params.confirmCallback)
    setButtons(NEGATIVE_SUBMIT_BUTTON | CANCEL_BUTTON)
    open()

    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const showDoneConfirmation = (params: showFunctionProps): Promise<boolean> => {
    setMessage(params.message)
    setSubmitButtonText(params.submitButtonText ?? 'Got it!')
    setConfirmCallback(() => params.confirmCallback)
    setButtons(POSITIVE_SUBMIT_BUTTON)
    open()

    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const handleNegativeOrPositiveChoice = async () => {
    if (confirmCallback) {
      setIsCallbackFinishPending(true)
      await confirmCallback().finally(() => setIsCallbackFinishPending(false))
    }
    close(true)
  }

  const handleCancelChoice = () => {
    close(false)
  }

  return (
    <ConfirmationContext.Provider value={{ showAskConfirmation, showDeleteConfirmation, showDoneConfirmation }}>
      {children}
      {isShowing && (
        <Portal id={'confirmation-dialog'}>
          <SC.Overlay $opened={opened}>
            <Dialog
              opened={opened}
              message={message}
              buttons={buttons}
              submitButtonText={submitButtonText}
              isCallbackFinishPending={isCallbackFinishPending}
              handleNegativeOrPositiveChoice={handleNegativeOrPositiveChoice}
              handleCancelChoice={handleCancelChoice}
            />
          </SC.Overlay>
        </Portal>
      )}
    </ConfirmationContext.Provider>
  )
})
