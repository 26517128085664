import styled from 'styled-components'
import { CSS } from 'ui/helpers'

export const Option = styled.div<{ $active: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 10px;
  gap: 10px;
  outline: none;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};

  background: ${(p) =>
    p.$active ? p.theme.uiKit.colors.global.grey.grey100 : p.theme.uiKit.colors.global.general.main};

  transition: ${CSS.populateTransition(['background-color'])};
`

export const DisabledOption = styled.span`
  cursor: not-allowed;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`
