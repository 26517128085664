import { FC, memo, useCallback } from 'react'

import { useFormData } from 'brief-form'
import { Request } from 'commons/utils/request'
import { useTableState } from 'query-table'
import { Page, PageFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'

import { ExportCSVButton, ReportBreadcrumbs } from '../../components'
import { DocumentsReportFilter } from './DocumentsReportFilter'
import { Table } from './components/Table'

const DocumentsReport: FC = memo(() => {
  const tableState = useTableState({
    filter: {},
    order: [{ field: 'document_name', direction: 'ASC' }]
  })

  const filterForm = useFormData<DocumentsReportFilter>({
    initialValue: {
      active: '',
      document_name: '',
      document_type: '',
      frequency: '',
      level: '',
      limit: 0,
      offset: 0,
      start_date_type: '',
      state: ''
    }
  })

  const originalExportsChange = filterForm.config.onChange
  filterForm.config.onChange = useCallback(
    (v, e) => {
      tableState.pagination.change(1, tableState.pagination.size)
      originalExportsChange(v, e)
    },
    [originalExportsChange, tableState]
  )

  const { active, document_name, document_type, frequency, level, start_date_type, state } = filterForm.config.value

  return (
    <Page
      face={PageFace.PRIMARY}
      title={<ReportBreadcrumbs title="Documents Report" />}
      actions={
        <ExportCSVButton
          reportName="Documents"
          url={Request.urls.bank.portalReportDocumentsCSV}
          parametersForm={{
            document_name,
            document_type,
            frequency,
            level,
            start_date_type,
            state,
            active: active.length ? JSON.parse(active) : null
          }}
        />
      }
    >
      <Panel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Table tableState={tableState} filterForm={filterForm} />
      </Panel>
    </Page>
  )
})

export default DocumentsReport
