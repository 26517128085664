import { AnnualReview } from 'commons/types/DTO/bank'
import { Request } from 'commons/utils/request'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

export const annualReviewList = (annualReview: AnnualReview) =>
  Request.post(Request.urls.bank.annualReviewList, annualReview)

export const annualReviewSalesDeposits = (id: number, byId?: boolean) =>
  Request.post(Request.urls.bank.annualReviewSalesDepositsList, {
    [byId ? 'id' : 'annual_review_id']: id,
    _options: {
      filters: [
        {
          field: byId ? 'id' : 'annual_review_id',
          type: 'eq',
          value: id
        }
      ]
    }
  })

interface DeleteAnnualReviewSalesDeposits {
  annual_review_id: number
  company_id: number
  id: number
}

export const deleteAnnualReviewSalesDeposits = (props: DeleteAnnualReviewSalesDeposits) =>
  Request.delete(Request.urls.bank.annualReviewSalesDeposits, { ...props })

type Options = { id: number; historyPage?: number; historySize?: number }

export const getAnnualReviewDetails = (opts: Options) =>
  Request.post<AnnualReviewDetails, Options>(Request.urls.bank.annualReviewDetails, opts)
