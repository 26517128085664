import { financialInstitutionRoles } from 'commons/types/dictionaries'
import { FF, Select } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

export const FinancialInstitutionRole = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].roleInTransaction` as 'institutionActivitiesInfo[0].roleInTransaction'}
      children={(field) => (
        <FF
          field={field}
          label="56. Financial institution's role in transaction"
          input={(p) => <Select {...p} data={financialInstitutionRoles} emptyOption={EMPTY_OPTION} />}
        />
      )}
    />
  )
}
