import { primaryFederalRegulators } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const FederalRegulator = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].federalRegulator` as 'institutionActivitiesInfo[0].federalRegulator'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => (
        <FF
          required
          field={field}
          label="52. Primary federal regulator"
          input={(p) => (
            <Select
              {...p}
              data={primaryFederalRegulators}
              data-qa="52. Primary federal regulator"
              options-data-qa="52. Primary federal regulator option"
            />
          )}
        />
      )}
    />
  )
}
