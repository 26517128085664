import { getRouteApi } from '@tanstack/react-router'
import { HistoryTable } from 'bank-entity-history/src/components/HistoryTable'
import { TableDataQa } from 'commons/types/enums'
import { Layer, PageSection } from 'ui/components'
import { AnnualReviewPaths } from '~bank-annual-review/constants'
import { AnnualReviewDetails } from '~bank-annual-review/types/AnnualReviewDetails'

const routeApi = getRouteApi(AnnualReviewPaths.DETAILS)

export const History = () => {
  const { history, historyTotalCount } = routeApi.useLoaderData() as AnnualReviewDetails
  const flatDeps = routeApi.useRouteContext({ select: (s) => s.flatDeps })
  const navigate = routeApi.useNavigate()

  return (
    <PageSection title="Annual Review History" anchor="history">
      <Layer rounded shadowed stretch>
        <HistoryTable
          dataQa={TableDataQa.BP_ANNUAL_REVIEW_DETAILS_HISTORY}
          items={history}
          pagination={{
            page: flatDeps.historyPage,
            pageSize: flatDeps.historySize,
            onChange: (historyPage, historySize) =>
              navigate({
                from: AnnualReviewPaths.DETAILS,
                to: AnnualReviewPaths.DETAILS,
                resetScroll: false,
                replace: true,
                search: { historyPage, historySize }
              }),
            total: historyTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
}
