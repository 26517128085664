import { FF, RadioGroup, Select, TextareaInput } from 'ui/components'
import { PageSection } from 'ui/components/Page'
import {
  AnnualReviewQuestionColumn,
  AnnualReviewQuestionsSet,
  AnnualReviewQuestion as Q
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { questionnaire2Labels } from '~bank-annual-review/constants/questionnaireLabels'

import { useAnnualReviewFormContext } from '../../../context/ManageAnnualReviewContext'

export const Questionnaire2Form = () => {
  const { formApi } = useAnnualReviewFormContext()

  if (!formApi.state.values.questionnaire2) return null

  return (
    <PageSection title="Questionnaire">
      <AnnualReviewQuestionsSet>
        <AnnualReviewQuestionColumn>
          <Q label={questionnaire2Labels.hasMoreRevenue} children={<br />} />
          <Q label={questionnaire2Labels.revenueFromMJOnly} children={<br />} />
          <Q label={questionnaire2Labels.informationForMETRCDump} children={<br />} />
          <Q
            label={questionnaire2Labels.comparingSICSAndBankDepositsRedFlag}
            children={
              <formApi.Field
                name="questionnaire2.comparingSICSAndBankDepositsRedFlag"
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <RadioGroup
                        data={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
          <Q
            label={questionnaire2Labels.comparingSICSAndBankDepositsVariance}
            children={
              <formApi.Field
                name="questionnaire2.comparingSICSAndBankDepositsVariance"
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <Select
                        data={[
                          { label: 'Within Variance', value: 'Within Variance' },
                          { label: 'Out of Variance - See Comments', value: 'Out of Variance' },
                          { label: 'Wholesale Only - Cannot Compare', value: 'Wholesale Only' },
                          { label: 'Not Operational - Cannot Compare', value: 'Not Operational' }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
          <Q
            label={questionnaire2Labels.comparingSICSAndBankDepositsComments}
            multiline
            children={
              <formApi.Field
                name="questionnaire2.comparingSICSAndBankDepositsComments"
                children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
              />
            }
          />

          <Q label={questionnaire2Labels.hasMoreCashDeposits} children={<br />} />
          <Q label={questionnaire2Labels.compareCashAmountDump} children={<br />} />
          <Q
            label={questionnaire2Labels.comparingCashDepositsAndTaxReturnsRedFlag}
            children={
              <formApi.Field
                name="questionnaire2.comparingCashDepositsAndTaxReturnsRedFlag"
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <RadioGroup
                        data={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
          <Q
            label={questionnaire2Labels.comparingCashDepositsAndTaxReturnsVariance}
            children={
              <formApi.Field
                name="questionnaire2.comparingCashDepositsAndTaxReturnsVariance"
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <Select
                        data={[
                          { label: 'Cash < Sales Tax Returns - OK', value: 'Cash < Sales Tax Returns' },
                          { label: 'Cash > Sales Tax Returns - See Comments', value: 'Cash > Sales Tax Returns' },
                          { label: 'Wholesale Only - Cannot Compare', value: 'Wholesale Only' },
                          { label: 'Not Operational - Cannot Compare', value: 'Not Operational' }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
          <Q
            label={questionnaire2Labels.comparingCashDepositsAndTaxReturnsComments}
            multiline
            children={
              <formApi.Field
                name="questionnaire2.comparingCashDepositsAndTaxReturnsComments"
                children={(field) => <FF field={field} input={(inputProps) => <TextareaInput {...inputProps} />} />}
              />
            }
          />

          <Q label={questionnaire2Labels.inconsistentFinancialStatement} children={<br />} />
          <Q label={questionnaire2Labels.validateContributionsDump} children={<br />} />
          <Q
            label={questionnaire2Labels.financialStatementValidationRedFlag}
            children={
              <formApi.Field
                name="questionnaire2.financialStatementValidationRedFlag"
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <RadioGroup
                        data={[
                          { label: 'Yes', value: 'Yes' },
                          { label: 'No', value: 'No' }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
          <Q
            label={questionnaire2Labels.financialStatementValidationVariance}
            children={
              <formApi.Field
                name="questionnaire2.financialStatementValidationVariance"
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <Select
                        data={[
                          { label: 'Yes - See Comments', value: 'Yes' },
                          { label: 'No Transactions of This Type', value: 'No' }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
          <Q
            label={questionnaire2Labels.financialStatementValidationComments}
            multiline
            children={
              <formApi.Field
                name="questionnaire2.financialStatementValidationComments"
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => <TextareaInput value={inputProps?.value} {...inputProps} />}
                  />
                )}
              />
            }
          />

          <Q label={questionnaire2Labels.disguisingMRBActivity} children={<br />} />
          <Q label={questionnaire2Labels.inconsistentMRBPurporting} children={<br />} />
          <Q label={questionnaire2Labels.inconsistentMRBPurportingDueDiligenceCompleted} children={<br />} />
          <Q label={questionnaire2Labels.ownerOutsideState} children={<br />} />
          <Q label={questionnaire2Labels.ownerOutsideStateApproved} children={<br />} />

          <Q label={questionnaire2Labels.adverseMediaRequired} children={<br />} />
          <Q label={questionnaire2Labels.adverseMediaReviewed} children={<br />} />
          <Q label={questionnaire2Labels.mrbOnFederalProperty} children={<br />} />
          <Q label={questionnaire2Labels.mrbSchoolProximityConsistent} children={<br />} />
          <Q label={questionnaire2Labels.mrbSchoolProximityDueDiligenceCompleted} children={<br />} />
        </AnnualReviewQuestionColumn>
      </AnnualReviewQuestionsSet>
    </PageSection>
  )
}
