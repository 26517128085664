import { useConfirmation } from 'ui/hooks'

import { useDeleteSAR } from '../../../../hooks/sar'
import { routeApi } from '../constants'

export const useDelete = (id: number, setNotDirty: () => void) => {
  const navigate = routeApi.useNavigate()
  const { routine: deleteSAR } = useDeleteSAR({
    onSuccess: async () => {
      setNotDirty()
      setTimeout(() => navigate({ to: '/bsa/sars' }), 0)
    }
  })

  const { open: startDeletion, Confirmation: DeleteConfirmation } = useConfirmation({
    message: `Are you sure you want to delete SAR?`,
    onConfirm: () => deleteSAR(id),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return {
    startDeletion,
    DeleteConfirmation
  }
}
