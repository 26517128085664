import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useInterval } from 'commons/hooks/common'
import { Button } from 'ui/components/Buttons'
import { DialogFace, DialogOverlay } from 'ui/components/Dialog'
import { DialogBackdrop, DialogContent, DialogWindow, Header, Title } from 'ui/components/Dialog/styles'

import { ExpiresTime, useGetSessionExpiresAt } from '../../hooks/user/useManageUserSession'

import * as SC from './styles'

type IdleWarningDialogProps = { hasUser: boolean; onContinue: () => void; onLogout: () => void }

export const IdleWarningDialog: FC<IdleWarningDialogProps> = ({ hasUser, onContinue, onLogout }) => {
  const expiresIn = useGetSessionExpiresAt({ hasUser })

  // after footer's button clicked we should disable btns
  // and while waiting async logout, session prolongation
  const onceDisableFlag = useRef<boolean>(false)
  const isButtonsDisabled = onceDisableFlag.current
  const [expiresInValue, setExpiresInValue] = useState<ExpiresTime>()

  const setExpiresInValues = () => {
    if (expiresIn?.current) setExpiresInValue(expiresIn?.current)
  }

  useInterval(setExpiresInValues)

  const handleContinue = () => {
    onContinue()
    onceDisableFlag.current = true
  }

  const handleLogout = useCallback(() => {
    onLogout()
    onceDisableFlag.current = true
  }, [onLogout])

  useEffect(() => {
    if (onceDisableFlag.current) {
      return
    }
    const remainingSec = (expiresInValue?.min ?? 0) * 60 + (expiresInValue?.minSec ?? 0)
    if (remainingSec < 2) {
      handleLogout()
    }
  }, [expiresInValue, handleLogout])

  if (!expiresIn) {
    return null
  }

  return (
    <DialogBackdrop overlay={DialogOverlay.DARK}>
      <DialogWindow overlay={DialogOverlay.DARK} withFooter={false} aria-modal="true" role="dialog">
        <Header face={DialogFace.PRIMARY}>
          <Title big={false}>Session Expiration Warning</Title>
        </Header>
        <DialogContent>
          <SC.ConfirmationContainer>
            Because you have been inactive, your session is about to expire.
            <br />
            Time remaining: {expiresInValue?.min}:{expiresInValue?.minSec}
          </SC.ConfirmationContainer>
        </DialogContent>
        <SC.Footer>
          <Button
            disabled={isButtonsDisabled}
            face="positive"
            onClick={handleContinue}
            data-qa="prompt-dialog-continue"
          >
            Continue
          </Button>
          <Button disabled={isButtonsDisabled} face="negative" onClick={handleLogout} data-qa="prompt-dialog-logout">
            Logout
          </Button>
        </SC.Footer>
      </DialogWindow>
    </DialogBackdrop>
  )
}
