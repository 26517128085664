import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type TransferOutProps = {
  index: number
}

export const TransferOut = ({ index }: TransferOutProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].transferOut` as 'transactionsInfo[0].transferOut'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => (
        <FF required field={field} label="Transfer Out" input={(p) => <CurrencyCentInput {...p} />} />
      )}
    />
  )
}
