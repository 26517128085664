import { memo } from 'react'

import styled from 'styled-components'

import { Header } from './components'
import { PageSectionFace } from './pageSectionFace'

export interface PageSectionProps {
  face?: PageSectionFace
  bottomIndent?: boolean
  className?: string
  anchor?: string
  title?: React.ReactNode
  titleBefore?: React.ReactNode
  titleAfter?: React.ReactNode
  actions?: React.ReactNode
  children?: React.ReactNode
  first?: boolean
  id?: string
}

const defaultProps: Partial<PageSectionProps> = {
  face: PageSectionFace.PRIMARY,
  bottomIndent: true
}

//region Styles

const Box = styled.section<{ face?: PageSectionFace; bottomIndent?: boolean }>`
  margin-top: ${(p) => (p.face === PageSectionFace.PRIMARY ? '40px' : '0')};
  margin-bottom: ${(p) => (p.face === PageSectionFace.PRIMARY ? '40px' : '0')};

  ${(p) => (p.face === PageSectionFace.SECONDARY && p.bottomIndent ? 'margin-bottom: 50px;' : '')};
  ${(p) => (p.face === PageSectionFace.THIRD && p.bottomIndent ? 'margin-bottom: 50px;' : '')};

  &:first-child {
    margin-top: ${(p) => (p.face === PageSectionFace.PRIMARY ? '28px' : '0')};
  }
  &:last-child {
    margin-bottom: ${(p) => (p.face === PageSectionFace.PRIMARY && p.bottomIndent ? '70px;' : '')};
  }
`

const Content = styled.div``

//endregion

export const PageSection = memo((props: PageSectionProps) => {
  const { children, face, bottomIndent, anchor, title, titleAfter, titleBefore, actions, first, ...rest } = {
    ...defaultProps,
    ...props
  }

  return (
    <Box face={face} bottomIndent={bottomIndent} {...(!!anchor && { id: anchor })} {...rest}>
      <Header
        face={face}
        title={title}
        actions={actions}
        first={first}
        titleAfter={titleAfter}
        titleBefore={titleBefore}
      />
      <Content>{children}</Content>
    </Box>
  )
})
