import { createContext } from 'react'

import { showFunctionProps } from './props'

type ConfirmationContextShape = {
  showAskConfirmation: (params: showFunctionProps) => Promise<boolean>
  showDeleteConfirmation: (params: showFunctionProps) => Promise<boolean>
  showDoneConfirmation: (params: showFunctionProps) => Promise<boolean>
}

export const ConfirmationContext = createContext<ConfirmationContextShape>({
  showAskConfirmation: () => new Promise(() => false),
  showDeleteConfirmation: () => new Promise(() => false),
  showDoneConfirmation: () => new Promise(() => false)
})
