import SC, { css } from 'styled-components'
import { CSS } from 'ui/helpers'

import { Icon } from '../../Icon'
import { BaseInput } from '../../InputV2'

const MAX_ITEMS_VISIBLE = 7
const ITEM_HEIGHT = 38

const ColumnsCss = css`
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  padding: 4px 0;
  outline: none;
`
/*
  TODO: https://helioscompliance.atlassian.net/browse/MP-5793 
  min-height: 80px; is quick hack to fix cutting tooltion 
  when dropdown has single item
*/
const ColumnCss = css`
  flex: 1;
  padding: 10px 0;
  max-height: 252px;
  overflow-y: auto;
  min-height: 80px;
  &:first-child {
    border-right: 1px solid #f0f0f0;
  }
`

const LeftColumnCss = css`
  div {
    &:hover {
      svg {
        fill: #23c698;
      }
    }
  }
`

const RightColumnCss = css`
  div {
    &:hover {
      svg {
        fill: #fa5252;
      }
    }
  }
`

const EmptyCss = css`
  font-size: 14px;
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  padding: 7px 10px;
`

const CountCss = css`
  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
  color: ${(p) => p.theme.uiKit.colors.global.general.main};
  font-weight: 400;
  font-size: 14px;
  padding: 0px 7px;
  line-height: 21px;
  border-radius: 11px;
  margin-right: 20px;
`

const CountLicenseCss = css`
  background-color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 3px 10px;
  border-radius: 50%;
  margin-right: 20px;
`

const ValueSCCss = css`
  background-color: #f5f5f5;
  padding: 7px 10px;
  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;

  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyL.regular)};
`

const TitleCss = css`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
`

const WrappedIconCss = css<{ open: boolean }>`
  height: 12px;
  transform: ${(p) => (p.open ? 'rotate(180deg)' : 'none')};
  right: 8px;
  position: absolute;
  z-index: 1;

  svg {
    fill: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
    color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
    height: 12px;
  }
`

const WrappedInputCss = css`
  margin-right: 30px;

  &:focus {
    border: none;
    z-index: auto;
  }
`

const DropDownCss = css<{ open: boolean }>`
  display: block;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${MAX_ITEMS_VISIBLE * ITEM_HEIGHT}px;
  box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  outline: none;
`

export const Columns = SC.div`${ColumnsCss}`

export const Column = SC.div`${ColumnCss}`

export const LeftColumn = SC(Column)`${LeftColumnCss}`

export const RightColumn = SC(Column)`${RightColumnCss}`

export const Empty = SC.div`${EmptyCss}`

export const Count = SC.span`${CountCss}`

export const CountLicense = SC.span`${CountLicenseCss}`

export const ValueSC = SC.div`${ValueSCCss}`

export const Title = SC.span`${TitleCss}`

export const WrappedIcon = SC(Icon)<{ open: boolean }>`${WrappedIconCss}`

export const WrappedInput = SC(BaseInput)`${WrappedInputCss}`

export const DropDown = SC.div<{ open: boolean }>`${DropDownCss}`
