import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import {
  ACHIn,
  ACHOut,
  AccountNumber,
  CashIn,
  CashOut,
  CheckIn,
  CheckOut,
  DebitCardOut,
  MiscIn,
  MiscOut,
  ReturnedCheckOut,
  TotalBankFees,
  TransferIn,
  TransferOut,
  WiresIn,
  WiresOut,
  WithdrawalsOut
} from './fields'

import { s } from './styles'

type TransactionInfoProps = {
  index: number
  total: number
  field: FieldApi<SarEdit, 'transactionsInfo'>
}

export const TransactionInfo = ({ index, total, field }: TransactionInfoProps) => {
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => field.removeValue(index),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <s.Item
      rounded
      shadowed
      collapsible={false}
      title={`Edit Account ${+index + 1} of ${total}`}
      actions={
        total > 1 && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting}>
            Remove
          </IconButton>
        )
      }
    >
      <Confirmation />
      <s.Inner>
        <FormRow>
          <Col>
            <AccountNumber index={index} />
          </Col>
          <Col>
            <TotalBankFees index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <CashIn index={index} />
          </Col>
          <Col>
            <CashOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <CheckIn index={index} />
          </Col>
          <Col>
            <CheckOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <ACHIn index={index} />
          </Col>
          <Col>
            <ACHOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <TransferIn index={index} />
          </Col>
          <Col>
            <TransferOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <WiresIn index={index} />
          </Col>
          <Col>
            <WiresOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col>
            <MiscIn index={index} />
          </Col>
          <Col>
            <DebitCardOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col />
          <Col>
            <ReturnedCheckOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col />
          <Col>
            <WithdrawalsOut index={index} />
          </Col>
        </FormRow>
        <FormRow>
          <Col />
          <Col>
            <MiscOut index={index} />
          </Col>
        </FormRow>
      </s.Inner>
    </s.Item>
  )
}
