import { ChoiceOption } from 'ui/types'

import { ServerSelectOption } from '../Select'
import { getOptionsByListName } from './optionsSelector'
import { SelectListName } from './selectListName'

export const getDropDownOptions = ({
  listName,
  extra,
  options,
  serverItems
}: {
  listName?: SelectListName
  extra: { cbt: boolean }
  options?: ChoiceOption[]
  serverItems: ServerSelectOption[]
}): ChoiceOption[] => {
  let dropDownOptions: ChoiceOption[] = []
  try {
    const addOptions = listName ? getOptionsByListName(listName, extra) : []
    const addOptionsWithOptions: ChoiceOption[] = options ? addOptions.concat(options) : addOptions
    const addOptionsWithServerItems = addOptionsWithOptions.concat(serverItems).filter(Boolean)
    dropDownOptions = addOptionsWithServerItems
      // filter duplicates
      .filter((el, index) => addOptionsWithServerItems.findIndex((el2) => el.value === el2.value) === index)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('SingleSelectAutoSuggest: calculation options error')
  }

  return dropDownOptions
}
