import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'

export const PrintRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `print`,
    component: lazyRouteComponent(() => import('./PrintCTRList')),
    validateSearch: (search: Record<string, string>) => {
      return {
        date: (search.date as string) || '',
        status: (search.status as string) || null,
        type: (search.type as string) || null
      }
    }
  })
