import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { typesOfFinancialInstitution } from 'commons/types/dictionaries'
import { FF, Select, Validators } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../context'

const TypeRenderer = ({
  field,
  index
}: {
  field: FieldApi<SarEdit, 'institutionActivitiesInfo[0].type'>
  index: number
}) => {
  const { formApi } = useSARFormContext()
  const typeOther = formApi.useStore((state) => state.values.institutionActivitiesInfo[index].typeOther)

  useEffect(() => {
    if (field.state.value === 'Other') {
      formApi.setFieldMeta(`institutionActivitiesInfo[${index as 0}].typeOther`, (typeOtherMeta) => ({
        ...typeOtherMeta,
        errors: [],
        errorMap: {}
      }))
    } else {
      if (typeOther) {
        formApi.setFieldValue(`institutionActivitiesInfo[${index as 0}].typeOther`, '')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.state.value, formApi])

  return (
    <FF
      required
      field={field}
      label="51. Type of financial institution"
      input={(p) => (
        <Select
          {...p}
          data={typesOfFinancialInstitution}
          data-qa="51. Type of financial institution"
          options-data-qa="51. Type of financial institution option"
        />
      )}
    ></FF>
  )
}

export const Type = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].type` as 'institutionActivitiesInfo[0].type'}
      validators={{
        onChange: ({ value }) => Validators.required.field(value)
      }}
      children={(field) => <TypeRenderer field={field} index={index} />}
    />
  )
}
