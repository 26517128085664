import { FC, memo } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'

import * as SC from './styles'

export const Warnings: FC<{ warnings: string[] }> = memo(({ warnings }) => (
  <SC.WarningBox>
    <SC.WarningTitle>
      <SC.WarningIcon name={IconName.ERROR_RED} size={IconSize.M} /> Warning
      <SC.WarningTitleNonCapitalized>(s)</SC.WarningTitleNonCapitalized>
    </SC.WarningTitle>
    <SC.WarningList>
      {warnings.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </SC.WarningList>
  </SC.WarningBox>
))
