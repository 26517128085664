import { FC, useMemo } from 'react'

import { QuarterlyAnalytics } from 'commons/types/DTO/bank/alerts/quarterlyAnalytics'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'

import { QuarterlyAnalyticsTableProps } from './QuarterlyAnalyticsTableProps'
import { columns } from './columns'
import { Filter } from './components'
import { useComponentsEssence } from './hooks'

export const QuarterlyAnalyticsTable: FC<QuarterlyAnalyticsTableProps> = ({ webPage, onAlertFilterChange }) => {
  const { changePagination, loading, onClear, tableState } = useComponentsEssence(onAlertFilterChange, webPage)
  const memoizedColumns = useMemo(columns, [])

  const mappedQA: DataSourceItem<QuarterlyAnalytics>[] = useMemo(
    () =>
      webPage.quarterlyAnalytics.map((el) => ({
        item: el
      })),
    [webPage.quarterlyAnalytics]
  )

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="Quarterly Analytics" anchor="qr">
      <Layer rounded shadowed stretch>
        <Filter
          isExpanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          onClear={onClear}
          form={tableState.filterForm}
          webPage={webPage}
        />
        <Table<QuarterlyAnalytics>
          dataQa={TableDataQa.BP_ALERTS_QUARTERLY_ANALYTICS}
          columns={memoizedColumns}
          dataSource={mappedQA}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: webPage.quarterlyAnalyticsTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
}
