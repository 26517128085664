import { useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'
import { useDeleteSalesDeposit } from '~bank-annual-review/service/AnnualReview/deleteSalesDeposit'

type UseDeleteSalesDepositArgs = { onSuccess?: () => void }

export const useDeleteSalesDepositConfirmation = ({ onSuccess }: UseDeleteSalesDepositArgs) => {
  const router = useRouter()

  const [sdForDelete, setSdForDelete] = useState<number | null>(null)

  const { routine: deleteSalesDeposit } = useDeleteSalesDeposit({
    onSuccess: async () => {
      Toast.successSmall(`Sales Deposit was successfully deleted.`)
      if (onSuccess) onSuccess()
      else router.invalidate()
    },
    onError: (error) => handlerServerError(error)
  })

  const { Confirmation, open } = useConfirmation({
    message: (
      <span>
        Are you sure want to delete <i>SDS-{sdForDelete}</i> Sales and Deposit table?
      </span>
    ),
    onConfirm: () => {
      if (sdForDelete) deleteSalesDeposit(sdForDelete)
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true,
    onCancel: () => setSdForDelete(null)
  })

  const openDeleteSalesDepositConfirmation = (id: number) => {
    setSdForDelete(id)
    open()
  }

  return { DeleteConfirmation: Confirmation, openDeleteSalesDepositConfirmation }
}
