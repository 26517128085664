import { memo } from 'react'

import { InnerMenu } from 'ui/components/SideMenu'
import { useGetAlertsWebPage } from '~bank-alerts/hooks'

const AlertsSideBar = memo(() => {
  const { webPage } = useGetAlertsWebPage()

  return (
    <InnerMenu
      items={[
        { label: 'Documents Due', hash: 'documents-due' },
        ...(webPage?.uploads !== undefined
          ? [{ label: 'Uploads Pending Approval', hash: 'uploads-pending-approval' }]
          : []),
        ...(webPage?.applications !== undefined ? [{ label: 'Applications', hash: 'applications' }] : []),
        ...(webPage?.CTRs !== undefined ? [{ label: 'CTRs with FinCEN warnings', hash: 'ctr' }] : []),
        ...(webPage?.bsaBatches !== undefined ? [{ label: 'BSA Batches to finalize', hash: 'batch' }] : []),
        ...(webPage?.SARs !== undefined ? [{ label: 'SARs List', hash: 'sar' }] : []),
        ...(webPage?.monthlyAnalytics !== undefined ? [{ label: 'Monthly Analytics', hash: 'mr' }] : []),
        ...(webPage?.quarterlyAnalytics !== undefined ? [{ label: 'Quarterly Analytics', hash: 'qr' }] : []),
        ...(webPage?.annualReviews !== undefined ? [{ label: 'Annual Reviews', hash: 'annual-review' }] : []),
        ...(webPage?.adverseMedia !== undefined ? [{ label: 'Adverse Media', hash: 'adverse-media' }] : [])
      ]}
    />
  )
})

export default AlertsSideBar
