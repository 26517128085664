import { ContactListWebPageResponseItem } from 'commons/types/contracts/api/bank/contactList'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Tooltip } from 'ui/components/Tooltip'

import { CONTACTS_PATH } from '../../../../constants'

import { NameLink } from './styles'

export const columns = (
  onDelete: (item: ContactListWebPageResponseItem) => any
): TableColumn<ContactListWebPageResponseItem>[] => [
  {
    align: 'left',
    title: 'Name',
    render: (item) => (
      <NameLink to={`${CONTACTS_PATH}/${item.id}`} data-qa={`contact-name-link-${item.contactInfo.trim()}`}>
        {item.contactInfo}
        {item.isShared && (
          <Tooltip id={`shared${item.id}`} type="dark" content="Shared" event="hover">
            <Icon name={IconName.SHARED} size={IconSize.XS} />
          </Tooltip>
        )}
      </NameLink>
    )
  },
  {
    align: 'center',
    title: 'Email',
    key: 'email'
  },
  {
    align: 'center',
    title: 'Phone',
    key: 'phone'
  },
  {
    title: 'Actions',
    align: 'center',
    width: '50px',
    render: (item) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            url: `${CONTACTS_PATH}/${item.id}/edit`
          },
          {
            key: 2,
            title: 'Delete',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onDelete(item)
          }
        ]}
      />
    )
  }
]
