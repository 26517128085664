import { FC, memo, useMemo } from 'react'

import { DocumentsUploadingDoc } from 'commons/types/DTO/commons/webpagesOnboardinForm'
import { TableDataQa } from 'commons/types/enums'
import { Layer, Panel, Table, TableColumn } from 'ui/components'

import { CompanyDocumentsTitle } from '../CompanyDocumentsTitle'
import { CompanyDocumentsProps } from './CompanyDocumentsProps'
import { columns } from './columns'

export const CompanyDocuments: FC<CompanyDocumentsProps> = memo((props) => {
  const { documentsUploading, index, readonly, isLoading, onAddFiles, onRemoveFile, isDocumentDeleting } = props
  const { name, uploading } = documentsUploading

  const mappedAM = useMemo(
    () =>
      uploading.map((el) => ({
        item: el
      })),
    [uploading]
  )

  return (
    <>
      <Layer rounded shadowed>
        <Panel title={<CompanyDocumentsTitle companyName={name} title={`Company #${index + 1}`} />}>
          <Table
            dataQa={TableDataQa.ONBOARDING_FORM_COMPANY_DOCUMENTS_LIST}
            noDataMessage={'No Documents Necessary'}
            columns={
              columns(
                readonly,
                name,
                onAddFiles,
                onRemoveFile,
                isLoading,
                isDocumentDeleting
              ) as TableColumn<DocumentsUploadingDoc>[]
            }
            dataSource={mappedAM}
            loading={isLoading}
          />
        </Panel>
      </Layer>
      <div style={{ height: '40px' }} />
    </>
  )
})
