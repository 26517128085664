import { ContactDetailsFormValue } from '../../../../types'

// packages/commons/types/DTO/commons/applicationRelationshipContact.ts
// ApplicationContactDetails
export const mapContactDetailsForm = ({
  contactID,
  entityUID,
  contactMetadataID,
  firstName,
  lastName,
  email,
  phone,
  mobile,
  homePhone,
  title,
  otherMJRelatedBusiness,
  bio
}: ContactDetailsFormValue) => ({
  contactID: +(contactID ?? 0),
  entityUID,
  contactMetadataID: +(contactMetadataID ?? 0),
  firstName,
  lastName,
  email,
  phone,
  mobile,
  homePhone,
  title,
  otherMJRelatedBusiness,
  bio
})
