import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

type PhoneExtProps = {
  index1: number
  index2: number
}

export const PhoneExt = ({ index1, index2 }: PhoneExtProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1}].phoneNumbers[${index2}].phoneExt` as 'subjectsInfo[0].phoneNumbers[0].phoneExt'}
      children={(field) => <FF field={field} label="Ext." input={(p) => <TextInput {...p} maxLength={6} />} />}
    />
  )
}
