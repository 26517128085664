import { FC, memo, useCallback, useContext } from 'react'

import { AuthContext } from '../../AuthContext'
import { useResetPassword } from '../../hooks/user'
import { ResetPasswordProps } from './ResetPasswordProps'
import { ResetPasswordForm } from './components'
import { ResetPasswordFormValue } from './components/ResetPasswordForm'

import * as SC from './styles'

export const ResetPassword: FC<ResetPasswordProps> = memo(({ logoElement, footer }) => {
  const { portalName } = useContext(AuthContext)
  const resetPasswordMutation = useResetPassword(portalName)

  const resetPassword = useCallback(
    (value: ResetPasswordFormValue) => resetPasswordMutation.mutateAsync(value),
    [resetPasswordMutation]
  )

  return (
    <SC.Box>
      <ResetPasswordForm onSubmit={resetPassword} logoElement={logoElement} footer={footer} />
    </SC.Box>
  )
})
