import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { SalesDataSourceWebPage } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { Dialog, Hr } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { TextareaInput } from 'ui/components/InputV2'
import { DialogCol, FormRow, Row } from 'ui/themes/globalStyles'

import { useRejectDialog } from './useRejectDialog'

import * as SC from './styles'

type Props = {
  webPage: SalesDataSourceWebPage
  hideRejectDialog: () => void
  invalidate: () => Promise<void>
}

export const RejectDialog: FC<Props> = memo((props) => {
  const { webPage, hideRejectDialog, invalidate } = props
  const { config, Field, reject, isRejecting } = useRejectDialog(webPage, hideRejectDialog, invalidate)

  return (
    <Dialog
      id={'client-source-reject'}
      onClose={hideRejectDialog}
      customHeader
      customFooterActions={
        <>
          <Button face="negative" onClick={() => reject()} loading={isRejecting}>
            Reject
          </Button>
          <Button face="neutral" onClick={() => hideRejectDialog()}>
            Cancel
          </Button>
        </>
      }
    >
      <SC.Inner>
        <Form config={config}>
          <Row>
            <DialogCol>
              <h5>
                <strong>Are you sure you want to proceed?</strong>
              </h5>
              <br />
              <p>Please describe the reason for the rejection so the customer can fix it</p>
              <br />
            </DialogCol>
          </Row>
          {webPage.infoLastRejectionReason !== undefined && (
            <FormRow>
              <DialogCol>
                <SC.InfoWarning>
                  <SC.InfoWarningLabel>
                    <strong>Last Rejection Reason</strong>
                  </SC.InfoWarningLabel>
                  <SC.InfoWarningContent>{webPage.infoLastRejectionReason}</SC.InfoWarningContent>
                </SC.InfoWarning>
              </DialogCol>
            </FormRow>
          )}
          <FormRow>
            <DialogCol>
              <Field
                name="rejectionReason"
                label="Reason(s) for Rejection (visible to customer)"
                input={TextareaInput}
                inputProps={{}}
              />
            </DialogCol>
          </FormRow>
          <FormRow>
            <DialogCol>
              <Hr />
            </DialogCol>
          </FormRow>
          <FormRow>
            <DialogCol>
              <Field
                name="internalNotes"
                label="Internal Notes (not visible to customer)"
                input={TextareaInput}
                inputProps={{}}
              />
            </DialogCol>
          </FormRow>
        </Form>
      </SC.Inner>
    </Dialog>
  )
})
