import { FC, memo } from 'react'

import { sanitize } from 'dompurify'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { NarrativeProps } from './Narrative.types'

export const Narrative: FC<NarrativeProps> = memo((props) => {
  const { data } = props
  const { narrative } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 5. Narrative" anchor="step5">
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <Info>
          <div
            dangerouslySetInnerHTML={{
              __html: narrative ? sanitize(narrative).replace(/\n/g, '<br />') : '--'
            }}
          />
        </Info>
      </InfoSet>
    </PageSection>
  )
})
