import { FC, memo } from 'react'

import { CompanyMultiSelect, Validators } from 'ui/components'

import * as SC from './styles'

export const AssignedRelationshipsForm: FC<any> = memo(({ Field, isModeCreating }) => (
  <SC.Wrapper $biggerTopPadding={!isModeCreating}>
    <SC.Title>Assign Relationships</SC.Title>
    <SC.InputWrapper>
      <Field
        name="relationships"
        label="Relationships"
        required
        validator={Validators.required.array('At least one company is required')}
        input={CompanyMultiSelect}
        inputProps={{
          'data-qa-input': 'Assigned Companies Input',
          'data-qa': 'Assigned Companies',
          filters: [
            {
              field: 'customer_status',
              type: 'eq',
              value: 'customer'
            }
          ]
        }}
      />
    </SC.InputWrapper>
  </SC.Wrapper>
))
