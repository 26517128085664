import { getRouteApi } from '@tanstack/react-router'
import styled from 'styled-components'
import { FF, RadioGroup, TextInput } from 'ui/components'
import {
  AnnualReviewQuestionColumn as Column,
  AnnualReviewQuestion as Q,
  AnnualReviewQuestionsSet as Set
} from '~bank-annual-review/components/AnnualReviewQuestion'
import { AnnualReviewPaths } from '~bank-annual-review/constants/paths'
import { ARQuestionnaireFormSchemaName } from '~bank-annual-review/types/AnnualReviewQuestionnaireForm'
import { AnnualReviewEditMode } from '~bank-annual-review/types/Manage'

import { useAnnualReviewFormContext } from '../../../../context/ManageAnnualReviewContext'

const Questions = styled.div`
  margin-top: 34px;
`

export const LicensingQuestionnaire = (): JSX.Element | null => {
  const loaderData = getRouteApi(AnnualReviewPaths.EDIT).useLoaderData({
    select: (s) => s.form
  }) as AnnualReviewEditMode['form']

  const { formApi } = useAnnualReviewFormContext()

  let schemaName: ARQuestionnaireFormSchemaName | null = null
  if (loaderData?.questionnaire1) {
    schemaName = 'questionnaire1'
  } else if (loaderData?.questionnaire3) {
    schemaName = 'questionnaire3'
  } else if (loaderData?.questionnaireDemo) {
    schemaName = 'questionnaireDemo'
  }

  if (!schemaName) return null

  return (
    <Questions>
      <Set>
        <Column>
          <Q
            label="Any location changes in the last year?"
            children={
              <formApi.Field
                name={`${schemaName}.licensingLocChanges` as any}
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <RadioGroup
                        data={[
                          {
                            label: 'Yes',
                            value: 'Yes'
                          },
                          {
                            label: 'No',
                            value: 'No'
                          }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
        </Column>
        <Column>
          <formApi.Subscribe
            children={({ values }) => (
              <>
                {values[schemaName as string]?.licensingLocChanges === 'Yes' && (
                  <Q
                    label="If yes, approved by MMCC?"
                    children={
                      <formApi.Field
                        name={`${schemaName}.licensingLocChangesMmcc` as any}
                        children={(field) => (
                          <FF
                            field={field}
                            input={(inputProps) => (
                              <RadioGroup
                                data={[
                                  {
                                    label: 'Yes',
                                    value: 'Yes'
                                  },
                                  {
                                    label: 'No',
                                    value: 'No'
                                  }
                                ]}
                                {...inputProps}
                              />
                            )}
                          />
                        )}
                      />
                    }
                  />
                )}
              </>
            )}
          />
        </Column>
      </Set>
      <Set>
        <Column>
          <Q
            label="Any name changes in the last year?"
            children={
              <formApi.Field
                name={`${schemaName}.licensingNameChanges` as any}
                children={(field) => (
                  <FF
                    field={field}
                    input={(inputProps) => (
                      <RadioGroup
                        data={[
                          {
                            label: 'Yes',
                            value: 'Yes'
                          },
                          {
                            label: 'No',
                            value: 'No'
                          }
                        ]}
                        {...inputProps}
                      />
                    )}
                  />
                )}
              />
            }
          />
        </Column>
        <Column>
          <formApi.Subscribe
            children={({ values }) => (
              <>
                {values[schemaName as string]?.licensingNameChanges === 'Yes' && (
                  <Q
                    label="If yes, approved by MMCC?"
                    children={
                      <formApi.Field
                        name={`${schemaName}.licensingNameChangesMmcc` as any}
                        children={(field) => (
                          <FF
                            field={field}
                            input={(inputProps) => (
                              <RadioGroup
                                data={[
                                  {
                                    label: 'Yes',
                                    value: 'Yes'
                                  },
                                  {
                                    label: 'No',
                                    value: 'No'
                                  }
                                ]}
                                {...inputProps}
                              />
                            )}
                          />
                        )}
                      />
                    }
                  />
                )}
              </>
            )}
          />
        </Column>
      </Set>
      <Set>
        <Column>
          <Q
            label="Prior Name:"
            children={
              <formApi.Field
                name={`${schemaName}.licensingNamePrior` as any}
                children={(field) => <FF field={field} input={(inputProps) => <TextInput {...inputProps} />} />}
              />
            }
          />
        </Column>
      </Set>
    </Questions>
  )
}
