import { FC, memo } from 'react'

import { Form } from 'brief-form'
import { CheckBox, Select, Validators } from 'ui/components'
import { InfoSet } from 'ui/components/Info'
import { TextareaInput } from 'ui/components/InputV2'

import { AnticipatedMonthlyActivityFormProps } from './AnticipatedMonthlyActivityFormProps'
import { amountDataSource, frequencyDataSource, getAnticipatedMonthlyActivityList } from './helpers'
import { useAnticipatedMonthlyActivityForm } from './useAnticipatedMonthlyActivityForm'

import * as SC from './styles'

export const AnticipatedMonthlyActivityForm: FC<AnticipatedMonthlyActivityFormProps> = memo(
  ({
    companyIndex,
    value,
    state: { isAnticipatedActivityWiresInternationalCountriesEnable, isAdditionalAgreementRequiredEnable, conditions },
    onChange
  }) => {
    // TODO: https://helioscompliance.atlassian.net/browse/MP-7205
    const anticipatedMonthlyActivityList = getAnticipatedMonthlyActivityList(conditions)

    const { Field, config } = useAnticipatedMonthlyActivityForm({
      companyIndex,
      formData: value,
      onChange,
      anticipatedMonthlyActivityList
    })

    return (
      <Form config={config}>
        <InfoSet legend="Anticipated Monthly Activity">
          <SC.Table>
            <thead>
              <tr>
                <SC.TableTitle>Select all that apply</SC.TableTitle>
                <SC.TableTitle>Dollar Amount</SC.TableTitle>
                <SC.TableTitle>Frequency</SC.TableTitle>
              </tr>
            </thead>
            <tbody>
              {anticipatedMonthlyActivityList.map(({ label, active, amount, frequency, dataQa }) => (
                <SC.TableRow key={label}>
                  <td>
                    <Field
                      name={active as any}
                      input={CheckBox}
                      inputProps={{
                        'data-qa': dataQa ?? label,
                        checkBoxLabel: label
                      }}
                    />
                  </td>
                  <td>
                    <Field
                      required={config.value[active]}
                      name={amount as any}
                      input={Select}
                      inputProps={{
                        'data-qa': `${label} - Dollar Amount`,
                        'options-data-qa': `${label} - Dollar Amount`,
                        data: amountDataSource,
                        disabled: !config.value[active]
                      }}
                      validator={config.value[active] ? Validators.required.field : undefined}
                    />
                  </td>
                  <td>
                    <Field
                      required={config.value[active]}
                      name={frequency as any}
                      input={Select}
                      inputProps={{
                        'data-qa': `${label} - Frequency`,
                        'options-data-qa': `${label} - Frequency`,
                        data: frequencyDataSource,
                        disabled: !config.value[active]
                      }}
                      validator={config.value[active] ? Validators.required.field : undefined}
                    />
                  </td>
                </SC.TableRow>
              ))}
              {isAnticipatedActivityWiresInternationalCountriesEnable && (
                <SC.TableRow>
                  <td>If foreign, what countries:</td>
                  <td colSpan={2}>
                    <Field
                      name="anticipatedActivityWiresInternationalCountries"
                      input={TextareaInput}
                      /*
                        TODO: we have a bug with not enough space above textarea without label, use this hack before fix
                      */
                      label=" "
                      inputProps={{
                        'data-qa': 'If foreign, what countries',
                        disabled: !config.value.anticipatedActivityWiresInternational
                      }}
                    />
                  </td>
                </SC.TableRow>
              )}
            </tbody>
          </SC.Table>
          {isAdditionalAgreementRequiredEnable && <p>* additional agreement required</p>}
        </InfoSet>
      </Form>
    )
  }
)
