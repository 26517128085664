import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const IDNumber = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`institutionActivitiesInfo[${index}].idNumber` as 'institutionActivitiesInfo[0].idNumber'}
      children={(field) => <FF label="ID Number" field={field} input={(p) => <TextInput {...p} />} />}
    />
  )
}
