import styled from 'styled-components'
import { Layer } from 'ui/components/Layer'

export const WrappedLayer = styled(Layer)`
  display: inline-block;
  margin-bottom: 47px;
  width: 100%;
`

export const Box = styled.div`
  border-top: 2px solid #000;
`

export const Title = styled.h2`
  padding: 10px 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
`

export const Columns = styled.div`
  position: relative;
`

export const Labels = styled.div`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  padding: 68px 0 0 0;
  min-width: 124px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 20px 20px rgb(0 0 0 / 20%);
`

export const Label = styled.div`
  padding: 9px 16px;
  font-size: 13px;
  line-height: 16px;
  height: 34px;
`

export const Table = styled.table`
  border-collapse: collapse;
`

export const Th = styled.th<{ $bottomBorder?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 1px 30px;
  border-right: 1px solid #fff;
  ${(p) => (p.$bottomBorder ? 'border-bottom: 1px solid #fff' : '')};
`

export const Td = styled.td<{ $hl?: boolean }>`
  font-size: 13px;
  line-height: 16px;
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$hl ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};

  &:last-child {
    border-right: none;
  }
`

export const TableWrap = styled.div`
  overflow-x: scroll;
  margin-left: 125px;
`

export const TV = styled.span<{ $negative?: boolean }>`
  color: ${(p) =>
    p.$negative ? p.theme.uiKit.colors.theme.negative.main : p.theme.uiKit.colors.global.general.secondary};
`
