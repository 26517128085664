import { FC, forwardRef, memo, useCallback } from 'react'
import DatePicker from 'react-datepicker'

import { formatTheDateYall, getRawDate } from '../../../DateTime'
import { Icon, IconName } from '../../../Icon'
import { BaseInputProps } from '../../../InputV2'
import { MonthPickerProps } from './MonthPickerProps'

import * as SC from './styles'

export const MonthPicker: FC<MonthPickerProps> = memo((props) => {
  const { value, onChange, 'data-qa': dataQA } = props
  const date: Date | null = value ? formatTheDateYall(value) : null

  const onDateChange = useCallback(
    (date: Date) => {
      const value = getRawDate(date)
      if (onChange) {
        onChange(value, undefined)
      }
    },
    [onChange]
  )

  const MonthPickerInput = forwardRef<HTMLInputElement, BaseInputProps>((props: any, ref) => (
    <SC.MonthPickerInputWrapper>
      <SC.MonthPickerInput
        value={props.value}
        onClick={props.onClick}
        onChange={props.onChange}
        ref={ref}
        data-qa={dataQA}
      />
      <SC.MonthPickerIconWrapper>
        <Icon name={IconName.CALENDAR_DOTTED} />
      </SC.MonthPickerIconWrapper>
    </SC.MonthPickerInputWrapper>
  ))

  return (
    <SC.MonthPickerWrapper>
      <DatePicker
        dateFormat="MM/yyyy"
        showMonthYearPicker
        selected={date}
        onChange={(date: Date) => onDateChange(date)}
        customInput={<MonthPickerInput {...props} />}
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => (
          <SC.MonthPickerHeader>
            <SC.MonthPickerHeaderButton onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
              <Icon name={IconName.ARROW_PREVIOUS} />
            </SC.MonthPickerHeaderButton>
            <SC.MonthPickerHeaderDate>{date.getFullYear()}</SC.MonthPickerHeaderDate>
            <SC.MonthPickerHeaderButton onClick={increaseYear} disabled={nextMonthButtonDisabled}>
              <Icon name={IconName.ARROW_NEXT} />
            </SC.MonthPickerHeaderButton>
          </SC.MonthPickerHeader>
        )}
      />
    </SC.MonthPickerWrapper>
  )
})
