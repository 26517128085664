import { memo, useLayoutEffect, useMemo, useRef } from 'react'

import * as SCBase from '../styles'
import { IconName, IconSize, Popper } from 'ui/components'
import { InputHint, Required } from 'ui/core'

import { SELECT_PLACEHOLDER, SELECT_PLACEHOLDER_ADD_OPTIONS, SELECT_PLACEHOLDER_SEARCHABLE } from '../constants'
import { useSelect } from '../hooks/useSelect'
import { MultiSelectProps } from '../types'
import { Option } from './Option'

import * as SC from './styles'

export const ColumnMultiSelect = memo((props: MultiSelectProps) => {
  const {
    searchable = false,
    value,
    onChange,
    required = false,
    error,
    data = [],
    serverOptions,
    placeholder,
    disabled = false,
    hint,
    hideHint,
    'data-qa': dataQa,
    ...rest
  } = props
  const listRef = useRef<(HTMLDivElement | null)[]>([])
  const inputRef = useRef(null)
  const dropDownRef = useRef(null)
  const initRender = useRef(true)

  const {
    open,
    modelData: items,
    count,
    isSearching,
    isShowRequired,
    isEmpty,
    selectedValues,
    selectedOptions,
    handleClose,
    handleOpen,
    selectValue,
    unselectValue,
    getReferenceProps,
    getOptionProps,
    getInputProps,
    inputValue
  } = useSelect({
    selectedValues: value ?? [],
    open: false,
    data,
    required,
    disabled,
    error: !!error,
    listRef,
    inputRef,
    serverOptions,
    mode: 'columns',
    searchable
  })

  useLayoutEffect(() => {
    if (initRender.current) {
      initRender.current = false
      return
    }
    if (onChange) {
      onChange(selectedValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues])

  const selectedItems = useMemo(() => selectedOptions, [selectedOptions])
  const notSelectedItems = useMemo(() => items.filter((el) => !el.selected), [items])

  const renderOptions = () => (
    <SC.Columns>
      <div>
        <SC.DropDownHeader>
          <strong>Enabled options</strong>
        </SC.DropDownHeader>
        {notSelectedItems.length === 0 && <SC.DropDownSubHeader>No more items</SC.DropDownSubHeader>}
        <SC.OptionsList>
          {notSelectedItems.map(
            (item) =>
              item !== undefined && (
                <Option
                  key={item.index}
                  ref={(node) => (listRef.current[item.index] = node)}
                  label={item.data.label}
                  value={item.data.value}
                  disabled={item.data.disabled}
                  title={item.data.title}
                  selected={item.selected}
                  active={item.active}
                  onClick={() => selectValue(item.data.value)}
                  data-qa={dataQa ? `${dataQa} - ${item.data.label}` : undefined}
                />
              )
          )}
        </SC.OptionsList>
      </div>
      <div>
        <SC.DropDownHeader>
          <strong>Added options</strong>
        </SC.DropDownHeader>
        {selectedItems.length === 0 && <SC.DropDownSubHeader>No selected items yet</SC.DropDownSubHeader>}
        <SC.OptionsList>
          {selectedItems.map(
            (item) =>
              item !== undefined && (
                <Option
                  key={item.index}
                  ref={(node) => (listRef.current[item.index] = node)}
                  label={item.data.label}
                  value={item.data.value}
                  disabled={item.data.disabled}
                  title={item.data.title}
                  selected={true}
                  active={item.active}
                  onClick={() => unselectValue(item.data.value)}
                  data-qa={dataQa ? `${dataQa} - ${item.data.label}` : undefined}
                />
              )
          )}
        </SC.OptionsList>
      </div>
    </SC.Columns>
  )

  const showSpinner = isSearching && open
  let resultedPlaceholder = placeholder

  if (!placeholder) {
    if (searchable) {
      resultedPlaceholder = SELECT_PLACEHOLDER_SEARCHABLE
    } else {
      if (count > 0) {
        resultedPlaceholder = SELECT_PLACEHOLDER_ADD_OPTIONS
      } else {
        resultedPlaceholder = SELECT_PLACEHOLDER
      }
    }
  }

  return (
    <>
      <Popper
        listRef={listRef}
        opened={open}
        onOpen={handleOpen}
        onClose={handleClose}
        scrollClose={true}
        autoSize={true}
        autoSizeWidth={'double'}
        autoSizeMaxHeight={SC.OVERALL_HEIGHT}
        autoSizeMinHeight={100}
        placement="bottom-start"
        style={SC.PopperStyles()}
        disabled={disabled}
        render={() => (
          <SCBase.DropDown
            tabIndex={-1}
            ref={dropDownRef}
            {...getOptionProps()}
            $isSearching={isSearching}
            $column={false}
          >
            {renderOptions()}
          </SCBase.DropDown>
        )}
      >
        <SCBase.ReferenceOuter
          {...rest}
          role="listbox"
          $isEmpty={isEmpty}
          $error={!!error}
          disabled={disabled}
          {...getReferenceProps()}
        >
          <Required data-testid="required" isInputEmpty={isShowRequired} />
          <SCBase.InputForMultiSelect
            {...getInputProps()}
            $opened={open}
            ref={inputRef}
            type="text"
            value={inputValue}
            data-qa={dataQa}
            data-testid="search-value"
            placeholder={resultedPlaceholder}
            readOnly={!open || !searchable}
            autoComplete="off"
            disabled={disabled}
          />
          {showSpinner && <SCBase.Spinner size={22} durationInSeconds={1.0} />}
          {!showSpinner && count > 0 && (
            <SCBase.Counter $error={!!error} data-testid="counter">
              {count}
            </SCBase.Counter>
          )}
          <SCBase.WrappedIcon name={IconName.DOWN} size={IconSize.XS} $open={open} />
        </SCBase.ReferenceOuter>
      </Popper>
      <InputHint data-testid="hint" hint={hint} disabled={disabled} hideHint={hideHint} />
    </>
  )
})
