import { memo } from 'react'

import { Link } from '@tanstack/react-router'
import { IconName, IconSize } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { PanelFace } from 'ui/components/Panel'
import { useTableState } from 'ui/components/QueryTable'

import { Table } from '../components'

import * as SC from './styles'

const ParsedCashTransactionList = memo(() => {
  const tableState = useTableState({
    filter: {},
    order: [{ field: 'last_modified_at', direction: 'DESC' }]
  })

  return (
    <Page
      face={PageFace.PRIMARY}
      title={
        <SC.Title>
          <Link to="/bsa">BSA Reporting</Link>
          <SC.Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
          Parsed Cash Transactions
        </SC.Title>
      }
    >
      <SC.TablePanel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <Table tableState={tableState} />
      </SC.TablePanel>
    </Page>
  )
})

export default ParsedCashTransactionList
