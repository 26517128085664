import { useState } from 'react'

export const useActiveIndex = (index: number | null) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(index)

  return {
    activeIndex,
    setActiveIndex
  }
}
