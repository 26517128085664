import { useCallback, useMemo, useState } from 'react'

import { useDeleteAffiliatedCompany } from 'commons/hooks/bank/affiliatedCompany'
import { FilterCompanyAffiliatedCompaniesWebPage } from 'commons/service/bank/companyDetails'
import { handlerServerError } from 'errors'
import { Toast, useTableState } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { MappedAffiliatedCompany } from '../MappedAffiliatedCompany'
import { useAffiliatedCompanyManage } from './useAffiliatedCompanyManage'

export const useComponentEssence = (
  companyId: number,
  filterCompanyAffiliatedCompaniesWebPage: (filter: FilterCompanyAffiliatedCompaniesWebPage) => void,
  affiliatedCompaniesTotalCount: number
) => {
  const [loading, setLoading] = useState(false)

  const defaultFilter = useMemo(
    () => ({
      relationshipID: companyId,
      affCompaniesOffset: undefined,
      affCompaniesLimit: undefined
    }),
    []
  )

  const [tableFilter, setTableFilter] = useState<{
    relationshipID: number
    affCompaniesOffset: number | undefined
    affCompaniesLimit: number | undefined
  }>(defaultFilter)

  const tableState = useTableState({ filter: {} })

  const changePagination = useCallback(
    async (page: number, size: number) => {
      setLoading(true)

      tableState.onPageChange(page, size)

      const filter = { ...tableFilter, affCompaniesOffset: (page - 1) * size, affCompaniesLimit: size }

      setTableFilter(filter)

      await filterCompanyAffiliatedCompaniesWebPage(filter)

      setLoading(false)
    },
    [filterCompanyAffiliatedCompaniesWebPage, tableFilter, tableState]
  )

  const getCompanyAffiliatedCompaniesAfterDeleteWebPage = () => {
    setLoading(true)

    const isDeletingAnnualReviewLastOnPage = affiliatedCompaniesTotalCount === (tableFilter.affCompaniesOffset || 0) + 1

    const offset = isDeletingAnnualReviewLastOnPage
      ? (tableFilter?.affCompaniesOffset || 0) - (tableFilter?.affCompaniesLimit || 0)
      : tableFilter.affCompaniesOffset

    filterCompanyAffiliatedCompaniesWebPage({
      ...tableFilter,
      affCompaniesOffset: offset
    })

    tableState.onPageChange(isDeletingAnnualReviewLastOnPage ? tableState.page - 1 : tableState.page, tableState.size)

    const filter = { ...tableFilter, affCompaniesOffset: offset }

    setTableFilter(filter)

    setLoading(false)
  }

  const [affCompanyToDelete, setAffCompanyToDelete] = useState<MappedAffiliatedCompany | undefined>(undefined)

  const { routine: deleteHandler } = useDeleteAffiliatedCompany({
    onSuccess: async () => {
      getCompanyAffiliatedCompaniesAfterDeleteWebPage()
      Toast.successSmall(`Affiliation with "${affCompanyToDelete?.name}" company successfully deleted.`)
    },
    onError: (error) => handlerServerError(error)
  })

  const deleteAffiliatedCompany = useCallback(
    () => (affCompanyToDelete?.id ? deleteHandler(affCompanyToDelete?.id) : null),
    [affCompanyToDelete, deleteHandler]
  )

  const { Confirmation: DeleteConfirmation, open } = useConfirmation({
    message: `Are you sure want to delete affiliation with "${affCompanyToDelete?.name || 'this'}" company?`,
    onConfirm: deleteAffiliatedCompany,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const openDeleteConfirmation = useCallback(
    (item: MappedAffiliatedCompany) => {
      setAffCompanyToDelete(item)
      open()
    },
    [open, setAffCompanyToDelete]
  )

  const manageData = useAffiliatedCompanyManage(companyId, () => filterCompanyAffiliatedCompaniesWebPage(tableFilter))

  return {
    loading,
    tableState,
    changePagination,
    DeleteConfirmation,
    openDeleteConfirmation,
    manageData
  }
}
