import { useCallback } from 'react'

import { notFound, useNavigate } from '@tanstack/react-router'
import { useGetCompany } from 'commons/hooks/bank/company'
import { useDeleteLicense, useGetLicense, useSaveLicense } from 'commons/hooks/bank/license'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

export const useManage = (licenseId: number, companyId: number, returnUrl: string) => {
  const { data: licenseDeprecated, isFetching, isFetched } = useGetLicense({ id: licenseId, companyId })
  const license = {
    id: licenseDeprecated?.id,
    name: licenseDeprecated?.name,
    active: licenseDeprecated?.active,
    type: licenseDeprecated?.type,
    subtype: licenseDeprecated?.subtype,
    phone: licenseDeprecated?.phone,
    state: licenseDeprecated?.state,
    city: licenseDeprecated?.city,
    companyId: licenseDeprecated?.company_id,
    licenseNumber: licenseDeprecated?.license_number,
    issueDate: licenseDeprecated?.issue_date,
    posType: licenseDeprecated?.pos_type,
    posTypeOther: licenseDeprecated?.pos_type_other,
    cityTax: licenseDeprecated?.city_tax,
    countyTax: licenseDeprecated?.county_tax,
    stateTax: licenseDeprecated?.state_tax,
    mjRetailTax: licenseDeprecated?.mj_retail_tax,
    specialTax: licenseDeprecated?.special_tax,
    streetAddress: licenseDeprecated?.street_address,
    postalCode: licenseDeprecated?.postal_code
  }

  const { data: company } = useGetCompany({ id: license?.companyId, enabled: !!licenseDeprecated })
  const navigate = useNavigate()

  if (isFetched && !licenseDeprecated && licenseId !== 0) {
    throw notFound()
  }

  const { routine: saveLicense, isLoading: isSaving } = useSaveLicense({
    onSuccess: async (_, response) => {
      Toast.successSmall(`License ${licenseId ? 'updated' : 'created'}`)
      if (returnUrl) {
        navigate({ to: returnUrl })
      } else {
        if (response.id) {
          navigate({ to: '/licenses/$licenseId', params: { licenseId: response.id.toString() } })
        } else {
          navigate({ to: '/licenses/$licenseId', params: { licenseId: licenseId } })
        }
      }
    },
    onError: (error) => handlerServerError(error)
  })

  const { routine: deleteLicense } = useDeleteLicense({
    onSuccess: async () => {
      Toast.success(`License ${license?.name} was deleted.`)
      if (license.companyId) {
        navigate({
          to: '/relationships/$companyId',
          params: { companyId: license.companyId.toString() },
          hash: 'licenses'
        })
      }
    },
    onError: (error) => handlerServerError(error)
  })

  const cancelEdit = useCallback(() => {
    if (returnUrl) {
      navigate({ to: returnUrl })
    } else {
      navigate({ to: '/licenses/$licenseId', params: { licenseId: licenseId.toString() } })
    }
  }, [navigate, licenseId, returnUrl])

  return {
    license,
    deleteLicense,
    saveLicense,
    isSaving,
    cancelEdit,
    isFetching,
    companyName: company?.name || ''
  }
}
