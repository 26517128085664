import { FC, memo } from 'react'

import noresult from './assets/img/NoResults.png'

import * as SC from './styles'

export const NoConnections: FC = memo(() => (
  <SC.NoConnections>
    <SC.NoConnectionsTitle>You have no connections yet.</SC.NoConnectionsTitle>
    <img src={noresult} alt="You have no connections yet" />
  </SC.NoConnections>
))
