import { ParsedLocation } from '@tanstack/react-router'

export type LocationParts = Pick<ParsedLocation, 'pathname' | 'search'>

export const getReturnUrl = (location: LocationParts): string => {
  let returnUrl = ''
  if (location.search) {
    returnUrl = `${location.search}`
  }
  if (location.search && location.pathname !== '/') {
    returnUrl = `${encodeURIComponent(`${location.pathname}${location.search}`)}`
  }
  if (!location.search && location.pathname !== '/') {
    returnUrl = `${location.pathname}`
  }
  // case !location.search && location.pathname === '/'
  return returnUrl
}

export const getNoUserRedirectPath = (location: LocationParts): string => {
  const returnUrl = getReturnUrl(location)
  const pathName = '/auth/login'
  return `${pathName}${returnUrl ? `?returnUrl=${returnUrl}` : ``}`
}

// ?returnUrl=/relationships => /relationships
export const extractPathFromReturnUrl = (locationSearch: string): string | undefined => {
  try {
    const searchParams = new URLSearchParams(locationSearch)
    const returnUrl = searchParams.get('returnUrl') ?? undefined // Convert `null` to `undefined`
    return returnUrl
  } catch {
    return undefined
  }
}
