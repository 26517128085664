import { Questionnaire } from 'commons/types/DTO/bank/quarterlyReport/questionnaire'
import { Request } from 'commons/utils/request'
import { ServerOptions } from 'ui/components/QueryTable'
import { SaveNotesRequest } from '~bank-quarterly-report/types'

export const getList = (opts: ServerOptions) => Request.post(Request.urls.bank.reportQuarterlyList, opts)

export const deleteQR = (id: number) => Request.delete(Request.urls.bank.reportQuarterly, { id })

export const update = (body: any, route: string) => {
  const url = {
    quarterly: Request.urls.bank.reportQuarterly,
    acknowledgeWarnings: Request.urls.bank.reportQuarterlyAcknowledgeWarnings,
    review: Request.urls.bank.reportQuarterlyReview,
    saveNotes: Request.urls.bank.reportQuarterlySaveNotes
  }[route]
  return url ? Request.put(url, body) : Promise.resolve()
}

export const updateQuestionnaire = ({ id, item }: { id: number; item: Questionnaire }) =>
  Request.put(Request.urls.bank.quarterlyQuestionnaire, { id, questionnaire: item })

export const saveNotes = ({ id, notes }: SaveNotesRequest) =>
  Request.put(Request.urls.bank.reportQuarterlySaveNotes, { id, notes })

export const reviewQR = (id: number) => Request.put(Request.urls.bank.reportQuarterlyReview, { id })

export const resolveQRWarnings = (id: number) =>
  Request.put(Request.urls.bank.reportQuarterlyAcknowledgeWarnings, { id })
