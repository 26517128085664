import { FC, memo } from 'react'

import { YesNoRadioGroup } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils'
import { Info, InfoSet } from 'ui/components/Info'
import { RadioGroup } from 'ui/components/RadioGroup'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import { ATMServicesDetailsProps } from './ATMServicesDetailsProps'

export const ATMServicesDetails: FC<ATMServicesDetailsProps> = memo(({ value }) => (
  <InfoSet>
    <Row>
      <Col>
        <Info label="Physical address of ATM location">{value.ATMAddress}</Info>
      </Col>
      <Col>
        <Info label="Avg amount replenished per month">{formatCurrencyCent(value.ATMAvgAmountReplenished)}</Info>
      </Col>
    </Row>
    <Row>
      <Col>
        <Info label="ATM size (max amount held)">{formatCurrencyCent(value.ATMMaxAmount)}</Info>
      </Col>
      <Col>
        <Info label="How often will the ATM be replenished?">{value.ATMReplenishFrequency}</Info>
      </Col>
    </Row>
    <Row>
      <Col2x>
        <Info label="Will you be receiving funds for replenishment from the Bank">
          <RadioGroup
            readonly
            value={value.ATMFundsFromBank}
            data={[
              { value: YesNoRadioGroup.YES, label: 'Yes' },
              { value: YesNoRadioGroup.NO, label: 'No' }
            ]}
          />
        </Info>
      </Col2x>
    </Row>
    {value.ATMFundsFromBankFalseDesc && (
      <Row>
        <Col2x>
          <Info label="If no, how will you obtain funds for replenishment?">{value.ATMFundsFromBankFalseDesc}</Info>
        </Col2x>
      </Row>
    )}
  </InfoSet>
))
