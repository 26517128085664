import { CheckBox, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const NoRelationship = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].noRelationship`}
      validators={{
        onChange: ({ value }) => {
          if (value) {
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].accountant`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].agent`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].appraiser`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].attorney`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].borrower`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].customer`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].director`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].employee`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].officer`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].owner`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].other`, false)
            formApi.setFieldValue(`subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].otherDesc`, '')
            formApi.setFieldValue(
              `subjectsInfo[${index1 as 0}].relationshipsInfo[${index2 as 0}].relationshipStatus`,
              ''
            )
          }

          return undefined
        }
      }}
      children={(field) => (
        <FF
          field={field}
          input={(p) => <CheckBox {...p} checkBoxLabel="j. No relationship to institution" />}
          errorPosition="right"
        />
      )}
    />
  )
}
