import { SetupDocumentFieldType } from 'commons/types/DTO/bank/applications/onboardingDocuments/SetupDocumentFieldType'
import { DatePicker, TextInput } from 'ui/components'

type ArgsType = {
  fieldType: SetupDocumentFieldType | undefined

  props: any
}

export const getCorrectFieldInput = ({ fieldType, props }: ArgsType) => {
  switch (fieldType) {
    case 'date':
      return <DatePicker {...props} />

    default:
      return <TextInput {...props} />
  }
}
