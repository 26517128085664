import { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { FF, Select } from 'ui/components'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../../context'

type TypeRendererProps = {
  field: FieldApi<SarEdit, 'suspiciousActivityInfo.cyberEventIndicators[0].type'>
  index: number
}

const TypeRenderer = ({ field, index }: TypeRendererProps) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    if (
      !(field.state.value && ['Command and control IP address', 'Suspicious IP address'].includes(field.state.value))
    ) {
      formApi.setFieldValue(
        // eslint-disable-next-line max-len
        `suspiciousActivityInfo.cyberEventIndicators[${index}].date` as `suspiciousActivityInfo.cyberEventIndicators[0].date`,
        null
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.state.value])

  return (
    <FF
      label="Event Type"
      field={field}
      input={(p) => (
        <Select
          {...p}
          data={[
            { label: 'Command and control IP address', value: 'Command and control IP address' },
            { label: 'Command and control URL/domain', value: 'Command and control URL/domain' },
            {
              label: 'Malware MD5, Malware SHA-1, or Malware SHA-256',
              value: 'Malware MD5, Malware SHA-1, or Malware SHA-256'
            },
            { label: 'Media Access Control (MAC) Address', value: 'Media Access Control (MAC) Address' },
            { label: 'Port', value: 'Port' },
            { label: 'Suspicious e-mail address', value: 'Suspicious e-mail address' },
            { label: 'Suspicious file name', value: 'Suspicious file name' },
            { label: 'Suspicious IP address', value: 'Suspicious IP address' },
            { label: 'Suspicious URL/domain', value: 'Suspicious URL/domain' },
            { label: 'Targeted system', value: 'Targeted system' },
            { label: 'Other', value: 'Other' }
          ]}
          emptyOption={{ label: 'Not selected', value: null }}
        />
      )}
    />
  )
}

export const Type = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        // eslint-disable-next-line max-len
        `suspiciousActivityInfo.cyberEventIndicators[${index}].type` as 'suspiciousActivityInfo.cyberEventIndicators[0].type'
      }
      children={(field) => <TypeRenderer field={field} index={index} />}
    />
  )
}
