import { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { ContactSelect, FF, RadioGroup, Validators } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { CreateMetadataModeFormValue } from '~bank-contacts/types/forms'

import { CreateModeSelectionFormProps } from './CreateModeSelectionFormProps'

import { Footer, FormBody, NextButton, Question } from './styles'

const SelectData = [
  { label: 'Create New Contact', value: 'new' },
  { label: 'Choose an Existing Contact', value: 'existing' }
]

export const CreateModeSelectionForm: FC<CreateModeSelectionFormProps> = memo((props) => {
  const { onSubmit, onCancel } = props

  const form = useForm<CreateMetadataModeFormValue>({
    defaultValues: { mode: 'new', contact_id: null },
    onSubmit: async ({ value }) => {
      await onSubmit(value)
    }
  })

  const isExistingMode = form.useStore(({ values }) => values.mode === 'existing')

  const disabled = form.useStore(
    ({ values }) =>
      !((values.mode === SelectData[1].value && !!values.contact_id) || values.mode === SelectData[0].value)
  )

  return (
    <FormBody>
      <Question>How do you want to add a contact?</Question>
      <FormRow>
        <Col>
          <form.Field
            name="mode"
            children={(modeField) => (
              <FF
                label=""
                field={modeField}
                input={(inputProps) => <RadioGroup name="mode" data-qa="mode" data={SelectData} {...inputProps} />}
              />
            )}
          />
        </Col>
      </FormRow>
      {isExistingMode && (
        <FormRow>
          <Col>
            <form.Field
              name="contact_id"
              validators={{
                onSubmit: ({ value }) => Validators.required.field(value)
              }}
              children={(contactField) => (
                <FF
                  required
                  field={contactField}
                  label="Contact"
                  input={(inputProps) => <ContactSelect {...inputProps} />}
                />
              )}
            />
          </Col>
        </FormRow>
      )}
      <Footer>
        <NextButton face="positive" disabled={disabled} onClick={form.handleSubmit}>
          Next
        </NextButton>
        <Button face="neutral" onClick={onCancel}>
          Cancel
        </Button>
      </Footer>
    </FormBody>
  )
})
