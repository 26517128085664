import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../context'
import { Address, City, Country, PostalCode, State } from './fields'

export const AddressFields = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()

  return (
    <>
      <formApi.Field
        mode="array"
        name={`subjectsInfo[${index as 0}].addressesInfo`}
        children={(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {(field.state.value || []).map((_, index2) => (
                <AddressItem index1={index} index2={index2} parentField={field} key={`${index}-${index2}`} />
              ))}

              <IconButton
                icon={IconName.ADD_GREEN}
                onClick={() => field.pushValue({ address: '', city: '', country: '', postalCode: '', state: '' })}
              />
            </>
          )
        }}
      />
    </>
  )
}

const AddressItem = ({
  index1,
  index2,
  parentField
}: {
  index1: number
  index2: number
  parentField: FieldApi<SarEdit, 'subjectsInfo[0].addressesInfo'>
}) => {
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index2),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <div key={index1} style={{ marginBottom: '15px' }}>
      <Confirmation />
      <FormRow>
        <Col>
          <h5>Subject Address Information</h5>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Address index1={index1} index2={index2} />
        </Col>
        <Col>
          <City index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <State index1={index1} index2={index2} />
        </Col>
        <Col>
          <PostalCode index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Country index1={index1} index2={index2} />
        </Col>
        {parentField.state.value.length > 1 && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
        )}
      </FormRow>
    </div>
  )
}
