export type SalesSourceItemInfo = {
  sourceId: number
  sourceType: string
  isPrimary: boolean
  totalSales: number
  cannabisSales: number
  nonCannabisSales: number
  taxes: number
  showAppliedTotalSales: number
  showAppliedCannabisSales: number
  showAppliedNonCannabisSales: number
  showAppliedTaxes: number
  status: string
  approvalNeeded: boolean
  showWarningAlertNotes: string
  showDangerAlertNotes: string
  enableDelete: boolean
  enableEdit: boolean
  showEditButtonTooltip: string
  enableView: boolean
  showEnableCancelTask: boolean
  isGrouped: number
}
