import { FC } from 'react'

import styled from 'styled-components'
import { Link } from 'ui/components/Link'
import { CSS } from 'ui/helpers'

import { IconName } from '../../../Icon'
import { getIconFile } from '../../../Icon/getIconFile'

const WrappedLink = styled(Link)<{ $minimized: boolean }>`
  text-decoration: none;
  margin-top: 2px;

  transition: ${CSS.populateTransition(['opacity'])};
  opacity: ${(p) => (p.$minimized ? '0' : '1')};

  &:hover,
  &:focus,
  &:visited,
  &:visited:hover {
    text-decoration: none;
  }
`

export const MainBarLogo: FC<{ linkForLogo: string; isMinimized: boolean }> = ({ linkForLogo, isMinimized }) => {
  const file = getIconFile(IconName.MAIN_BAR_LOGO)

  return (
    <WrappedLink to={linkForLogo} $minimized={isMinimized}>
      <i dangerouslySetInnerHTML={{ __html: file }} />
    </WrappedLink>
  )
}
