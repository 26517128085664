import { FC, Fragment, useContext } from 'react'

import { BankNotes, BlockDelimiter, ContactsDescription, WelcomeBlock } from '../'
import cn from 'classnames'
import { YesNoRadioGroup } from 'commons/types/enums'
import { FormFieldClassNames, Hr, IconName, Layer, PageSection, PageSectionFace, Panel } from 'ui/components'
import { HeaderButton, IconButton, TextButton } from 'ui/components/Buttons'

import { NotesDetails } from '../../details'
import {
  ATMInfoForm,
  ATMServicesForm,
  AnticipatedMonthlyActivityForm,
  AnticipatedMonthlyActivityNAForm,
  AvailableServicesForm,
  CompanyAccountDetailsForm,
  CompanyInformationForm,
  CompanyMailingAddressForm,
  CompanyPhysicalAddressForm,
  ContactAdditionalInformationForm,
  ContactDetailsForm,
  LicenseAddressForm,
  LicenseDetailsForm,
  MerchantQuestionnaireForm,
  NotesForm,
  ProfessionalPartnershipsForm,
  QuestionnaireForm,
  RoleInformationForm
} from '../../forms'
import { AddHelp } from '../AddHelp'
import * as SC from '../Common/styles'
import { HiddenBox } from '../HiddenBox'
import { OnboardingFormContext } from './OnboardingFormContext'
import { getCompanyContactsEmailDict } from './helpers/getCompanyContactsEmailDict'

// packages/onboarding-form/src/components/OnboardingForm/
// BankOnboardingForm.tsx
// ClientOnboardingFormContainer.tsx
export const OnboardingForm: FC = () => {
  const onboardingContext = useContext(OnboardingFormContext)
  const { state, handlers, ui } = onboardingContext
  const {
    formValue,
    licenseTypesValues,
    licenseSubTypesValues,
    licensePOSTypesValues,
    businessTypesValues,
    entityTypesValues,
    businessTypesDescription,
    isCurrentStepEditEnable,
    isFormSubmitting,
    firstCompanyError
  } = state

  const { formsState } = state
  const { isCP, isBP } = ui
  const {
    anticipatedMonthlyActivityNA: { isAnticipatedMonthlyActivityNAFormEnable }
  } = formsState

  const { companiesFormValues } = formValue

  const rules = {
    owner: false,
    accountSigner: false
  }

  return (
    <>
      {isCP && (
        <>
          <WelcomeBlock />
          <BlockDelimiter text="Contact with the bank" />
          <BankNotes bankNotes={state.bankNotes} readonly={!isCurrentStepEditEnable} />
          {isCurrentStepEditEnable && (
            <NotesForm
              value={formValue.notes}
              onChange={handlers.handleNotesChange}
              onSave={handlers.handleNotesSave}
            />
          )}
          {!isCurrentStepEditEnable && <NotesDetails value={formValue.notes.formValues} />}
        </>
      )}

      {/* Companies */}
      {/* apps/client-portal/src/modules/onboarding/components/Companies/Companies.tsx */}
      <BlockDelimiter text="COMPANY DETAILS" isBP={isBP} />
      <AddHelp />
      {companiesFormValues.map((company, companyIndex) => {
        // company contacts email list for copy-contact logic
        const companyContactsEmailDict = getCompanyContactsEmailDict(company)
        // actual value of form
        const selectedCompanyCurrentValue = Number.isInteger(companyIndex)
          ? formValue.companiesFormValues[companyIndex]
          : undefined
        const atmGeneralInfoCurrentFormValues = selectedCompanyCurrentValue?.atmGeneralInfo.formValues
        const showAtms =
          atmGeneralInfoCurrentFormValues?.ATMHave === YesNoRadioGroup.YES &&
          atmGeneralInfoCurrentFormValues?.ATMReplenishingCompany === YesNoRadioGroup.YES

        selectedCompanyCurrentValue?.contacts.forEach((contact) => {
          if (contact.roleInformation.formValues.owner === YesNoRadioGroup.YES) {
            rules.owner = true
          }
          if (contact.roleInformation.formValues.accountSigner === YesNoRadioGroup.YES) {
            rules.accountSigner = true
          }
        })

        const contactShowErrorsClasses = cn({
          [FormFieldClassNames.FORM_FIELD_ERROR]: isFormSubmitting && !company.contacts.length,
          [FormFieldClassNames.ERROR_VISIBLE]: isFormSubmitting && !company.contacts.length
        })

        const licenseShowErrorsClasses = cn({
          [FormFieldClassNames.FORM_FIELD_ERROR]: isFormSubmitting && !company.licenses.length,
          [FormFieldClassNames.ERROR_VISIBLE]: isFormSubmitting && !company.licenses.length
        })

        const companyShowErrorsClasses = cn({
          [FormFieldClassNames.FORM_FIELD_ERROR]: isFormSubmitting && Number.isInteger(firstCompanyError),
          [FormFieldClassNames.ERROR_VISIBLE]: isFormSubmitting && Number.isInteger(firstCompanyError)
        })

        return (
          <Fragment key={companyIndex}>
            <Layer rounded shadowed>
              <Panel
                title={`Company ${companyIndex + 1}`}
                expanded={isFormSubmitting === true || void 0}
                actions={
                  companyIndex > 0 && (
                    <IconButton
                      children="Delete Company"
                      face="negative"
                      icon={IconName.DELETE}
                      onClick={() => handlers.handleRemoveCompany({ companyIndex })}
                    />
                  )
                }
              >
                <SC.Wrapper>
                  <HiddenBox className={companyShowErrorsClasses} />
                  <CompanyInformationForm
                    companyIndex={companyIndex}
                    value={company.companyInformation}
                    onChange={handlers.handleCompanyInformationChange}
                  />
                  <CompanyAccountDetailsForm
                    companyIndex={companyIndex}
                    state={state.formsState.accountDetails}
                    value={company.companyAccountDetails}
                    onChange={handlers.handleCompanyAccountDetailsChange}
                    availableBusinessTypes={businessTypesValues}
                    availableEntityTypes={entityTypesValues}
                    businessTypesDescription={businessTypesDescription}
                  />
                  <Hr />
                  <CompanyPhysicalAddressForm
                    companyIndex={companyIndex}
                    value={company.physicalAddress}
                    onChange={handlers.handleCompanyPhysicalAddressChange}
                  />
                  <div>
                    <TextButton
                      title="Use physical address for mailing address"
                      onClick={() => handlers.handleCompanyPhysicalAddressCopyToMailing({ companyIndex })}
                      data-qa="Duplicate"
                    >
                      Duplicate to Mailing Address
                    </TextButton>
                  </div>
                  <CompanyMailingAddressForm
                    companyIndex={companyIndex}
                    value={company.mailingAddress}
                    onChange={handlers.handleCompanyMailingAddressChange}
                  />
                  {company.companyAccountDetails.formValues.businessType === 'mrb' && (
                    <>
                      <Hr />
                      <br />

                      <h3 className={licenseShowErrorsClasses}>License Information</h3>
                      <br />
                      {/* Licenses */}
                      {/* apps/client-portal/src/modules/onboarding/components/LicenseForm/LicenseForm.tsx */}
                      {company.licenses.map((licenseFormData, licenseIndex) => (
                        <PageSection
                          key={licenseIndex}
                          title={`License #${licenseIndex + 1}`}
                          face={PageSectionFace.THIRD}
                          actions={
                            <IconButton
                              children="Delete License"
                              face="negative"
                              icon={IconName.DELETE}
                              onClick={() => handlers.handleRemoveLicense({ companyIndex, licenseIndex })}
                            />
                          }
                        >
                          <LicenseDetailsForm
                            licenseIndex={licenseIndex}
                            companyIndex={companyIndex}
                            state={state.formsState.licenseDetails}
                            value={licenseFormData.details}
                            onChange={handlers.handleLicenseDetailsChange}
                            availableLicenseTypes={licenseTypesValues}
                            availableLicenseSubTypes={licenseSubTypesValues}
                            availableLicensePOSTypes={licensePOSTypesValues}
                          />
                          <Hr />
                          <LicenseAddressForm
                            licenseIndex={licenseIndex}
                            companyIndex={companyIndex}
                            value={licenseFormData.addressInformation}
                            onChange={handlers.handleLicenseAddressInformationChange}
                          />
                        </PageSection>
                      ))}
                      <HeaderButton size="large" onClick={() => handlers.handleAddLicense({ companyIndex })}>
                        Add License
                      </HeaderButton>
                      <br />
                    </>
                  )}
                  <Hr />
                  {/* Contacts */}
                  {/* apps/client-portal/src/modules/onboarding/components/Contacts/Contacts.tsx */}
                  <br />
                  <h3 className={contactShowErrorsClasses}>Contacts</h3>
                  <br />
                  <ContactsDescription
                    isOwner={rules.owner}
                    isAccountSigner={rules.accountSigner}
                    bankPercents={state.bankPercents}
                  />
                  {company.contacts.map((contactFormData, contactIndex) => {
                    const roleInformationCurrentFormValue = company.contacts[contactIndex]?.roleInformation.formValues
                    const showAdditionalInformationForm =
                      roleInformationCurrentFormValue?.accountSigner === YesNoRadioGroup.YES ||
                      roleInformationCurrentFormValue?.owner === YesNoRadioGroup.YES ||
                      roleInformationCurrentFormValue?.debtholder === YesNoRadioGroup.YES

                    return (
                      <PageSection
                        key={contactIndex}
                        title={`Individual/Entity #${contactIndex + 1}`}
                        face={PageSectionFace.THIRD}
                        actions={
                          <IconButton
                            children="Delete Contact"
                            face="negative"
                            icon={IconName.DELETE}
                            onClick={() => handlers.handleRemoveContact({ contactIndex, companyIndex })}
                          />
                        }
                      >
                        <ContactDetailsForm
                          contactIndex={contactIndex}
                          companyIndex={companyIndex}
                          companyContactsEmailDict={companyContactsEmailDict}
                          value={contactFormData.details}
                          contactsList={ui.contactsList}
                          onChange={handlers.handleContactDetailsChange}
                          onContactCopy={handlers.handleContactCopy}
                          state={state.formsState.contactDetails}
                        />
                        <Hr />
                        <RoleInformationForm
                          contactIndex={contactIndex}
                          companyIndex={companyIndex}
                          state={state.formsState.roleInformation}
                          value={contactFormData.roleInformation}
                          onChange={handlers.handleRoleInformationChange}
                        />
                        <Hr />
                        {showAdditionalInformationForm && (
                          <ContactAdditionalInformationForm
                            contactIndex={contactIndex}
                            companyIndex={companyIndex}
                            value={contactFormData.contactAdditionalInformation}
                            onChange={handlers.handleContactAdditionalInformationChange}
                            state={{
                              ...state.formsState.contactAdditionalInformation,
                              isDebtholderRole: roleInformationCurrentFormValue.debtholder === YesNoRadioGroup.YES,
                              isOwnerRole: roleInformationCurrentFormValue.owner === YesNoRadioGroup.YES,
                              isAccountSignerRole: roleInformationCurrentFormValue.accountSigner === YesNoRadioGroup.YES
                            }}
                          />
                        )}
                      </PageSection>
                    )
                  })}
                  <HeaderButton size="large" onClick={() => handlers.handleAddContact({ companyIndex })}>
                    Add Individual/Entity
                  </HeaderButton>
                  <Hr />
                  <QuestionnaireForm
                    companyIndex={companyIndex}
                    state={state.formsState.questionnaire}
                    value={company.questionnaire}
                    onChange={handlers.handleQuestionnaireChange}
                  />
                  <Hr />
                  <ProfessionalPartnershipsForm
                    companyIndex={companyIndex}
                    state={state.formsState.professionalPartnerships}
                    value={company.professionalPartnerships}
                    onChange={handlers.handleProfessionalPartnershipChange}
                  />
                  <Hr />
                  <ATMInfoForm
                    companyIndex={companyIndex}
                    state={state.formsState.atmGeneralInfo}
                    value={company.atmGeneralInfo}
                    onChange={handlers.handleATMGeneralInfoChange}
                  />
                  {/* ATMS */}
                  {showAtms && (
                    <>
                      {company.atms.map((atmItem, atmIndex) => (
                        <PageSection
                          key={atmIndex}
                          title={`ATM #${atmIndex + 1}`}
                          face={PageSectionFace.THIRD}
                          actions={
                            <IconButton
                              children="Delete ATM"
                              face="negative"
                              icon={IconName.DELETE}
                              onClick={() => handlers.handleRemoveATM({ companyIndex, atmIndex })}
                            />
                          }
                        >
                          <ATMServicesForm
                            companyIndex={companyIndex}
                            atmIndex={atmIndex}
                            value={atmItem.atmServices}
                            onChange={handlers.handleATMServicesChange}
                          />
                        </PageSection>
                      ))}
                      <HeaderButton size="large" onClick={() => handlers.handleAddATM({ companyIndex })}>
                        Add ATM
                      </HeaderButton>
                    </>
                  )}
                  <Hr />
                  <AvailableServicesForm
                    companyIndex={companyIndex}
                    state={state.formsState.availableServices}
                    value={company.availableServices}
                    onChange={handlers.handleAvailableServicesChange}
                  />
                  <Hr />
                  {isAnticipatedMonthlyActivityNAFormEnable && (
                    <AnticipatedMonthlyActivityNAForm
                      companyIndex={companyIndex}
                      value={company.anticipatedMonthlyActivityNA}
                      onChange={handlers.handleAnticipatedMonthlyActivityNAChange}
                    />
                  )}
                  {!isAnticipatedMonthlyActivityNAFormEnable && (
                    <AnticipatedMonthlyActivityForm
                      companyIndex={companyIndex}
                      state={state.formsState.anticipatedMonthlyActivity}
                      value={company.anticipatedMonthlyActivity}
                      onChange={handlers.handleAnticipatedMonthlyActivityChange}
                    />
                  )}
                  <Hr />
                  <MerchantQuestionnaireForm
                    companyIndex={companyIndex}
                    value={company.companyMerchantQuestionnaire}
                    state={state.formsState.companyMerchantQuestionnaire}
                    onChange={handlers.handleMerchantQuestionnaireChange}
                  />
                </SC.Wrapper>
              </Panel>
            </Layer>
            <br />
          </Fragment>
        )
      })}
      <br />
      <HeaderButton size="large" onClick={handlers.handleAddCompany}>
        Add Company
      </HeaderButton>
    </>
  )
}
