export const SELECT_PLACEHOLDER = 'Not selected'
export const SELECT_PLACEHOLDER_SEARCHABLE = 'Type to search'
export const SELECT_PLACEHOLDER_ADD_OPTIONS = 'Add Options'

export const NOT_SELECTED_OPTION_INDEX = 0

export const MAX_OPTIONS_VISIBLE = 9
export const OPTION_MIN_WIDTH = 100
export const OPTION_LIST_PADDING = 7
export const HEIGHT = 40
