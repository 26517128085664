import { FC, memo } from 'react'

type EntityHistoryDetailsTextMaskProps = {
  text?: string
}

export const EntityHistoryDetailsTextMask: FC<EntityHistoryDetailsTextMaskProps> = memo(({ text }) => (
  <>
    {text && (
      <label className="entity-history-stars-field">
        <input type="checkbox" hidden className="entity-history-stars-hidden-checkbox" />
        <span className="entity-history-stars-hidden-stars">******</span>
        <span className="entity-history-stars-hidden-value">
          <b data-qa="details-field-value">{text}</b>
        </span>
      </label>
    )}
  </>
))
