import { FC, memo } from 'react'
import Dropzone from 'react-dropzone'

import { Request } from 'commons/utils/request'
import { DownloadLink, Icon, IconFace, IconName, IconSize, Toast } from 'ui/components'
import { OutlineButton } from 'ui/components/Buttons'

import { SalesDataSourceFileUploadProps } from './SalesDataSourceFileUploadProps'

import * as SC from './styles'

export const SalesDataSourceFileUpload: FC<SalesDataSourceFileUploadProps> = memo(
  ({ addFiles, deleteFile, deleteUploadedFile, uploadedFiles, files }) => (
    <>
      {uploadedFiles.map((file, index) => (
        <SC.UploadedFile key={index}>
          {file.enableDelete && (
            <SC.Action onClick={() => deleteUploadedFile(file.id)}>
              <Icon name={IconName.DELETE_RED_CROSS} face={IconFace.DEFAULT} size={IconSize.S} />
            </SC.Action>
          )}
          &nbsp;&nbsp;
          <DownloadLink
            direct
            withIcon={false}
            parametersForm={{ id: file.id }}
            baseUrl={Request.urls.bank.salesDataSourceFileDownload}
            name={file.fileName}
            handleError={(error) => Toast.error('Error', error)}
            method="post"
          >
            {file.fileName}
          </DownloadLink>
        </SC.UploadedFile>
      ))}
      {files.map((el, index) => (
        <SC.File key={index}>
          <SC.Action onClick={() => deleteFile(index)}>
            <Icon name={IconName.DELETE_RED_CROSS} face={IconFace.DEFAULT} size={IconSize.S} />
          </SC.Action>
          &nbsp;&nbsp;
          {el.name}
        </SC.File>
      ))}
      <Dropzone multiple onDrop={addFiles}>
        {({ getRootProps, getInputProps }) => (
          <span {...getRootProps()}>
            <input {...getInputProps()} />
            <OutlineButton face="positive" size="middle">
              Upload
            </OutlineButton>
          </span>
        )}
      </Dropzone>
    </>
  )
)
