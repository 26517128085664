import { CurrencyCentInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type TransferInProps = {
  index: number
}

export const TransferIn = ({ index }: TransferInProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactionsInfo[${index}].transferIn` as 'transactionsInfo[0].transferIn'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => (
        <FF required field={field} label="Transfer In" input={(p) => <CurrencyCentInput {...p} />} />
      )}
    />
  )
}
