import * as React from 'react'

import { s } from './AnnualReviewQuestionStyles'

interface AnnualReviewQuestionProps {
  label?: string | React.JSX.Element
  children: React.ReactNode
  bottomIndent?: boolean
  multiline?: boolean
  labelPadding?: boolean
}

export const AnnualReviewQuestion: React.FC<AnnualReviewQuestionProps> = ({
  label,
  bottomIndent = false,
  labelPadding,
  multiline,
  children
}: AnnualReviewQuestionProps) => (
  <s.Question $bottomIndent={bottomIndent}>
    {label && <s.Label $labelPadding={labelPadding}>{label}</s.Label>}
    {children && (multiline ? <s.Value>{children}</s.Value> : <div>{children}</div>)}
  </s.Question>
)
