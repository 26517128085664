import {
  EditTransactionsInformation,
  FilingInstitutionContactInformationForm,
  FinancialInstitutionWhereActivityOccurredForm,
  GeneralInformationForm,
  Narrative,
  SubjectInformationForm,
  SuspiciousActivityInfo,
  TransactionsInfo
} from './components'

export const Form = () => (
  <>
    <GeneralInformationForm />
    <FilingInstitutionContactInformationForm />
    <FinancialInstitutionWhereActivityOccurredForm />
    <SubjectInformationForm />
    <SuspiciousActivityInfo />
    <TransactionsInfo />
    <EditTransactionsInformation />
    <Narrative />
  </>
)
