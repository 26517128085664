import { FF, SecuredInput, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'

export const TIN = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const noKnownAccountsInvolved = formApi.useStore((s) => s.values.subjectsInfo[index1].noKnownAccountsInvolved)

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].institutionsInfo[${index2 as 0}].tin`}
      validators={{
        onChange: ({ value }) => (noKnownAccountsInvolved ? undefined : Validators.required.field(value))
      }}
      children={(field) => (
        <FF
          required={!noKnownAccountsInvolved}
          field={field}
          label="Institution TIN"
          input={(p) => <SecuredInput {...p} disabled={noKnownAccountsInvolved} maxLength={25} />}
        />
      )}
    />
  )
}
