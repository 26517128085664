import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import * as z from 'zod'
import { getAnnualReviewDetails } from '~bank-annual-review/service'

const sch = z.object({
  historyPage: z.number().int().positive().default(1),
  historySize: z.number().int().positive().default(10)
})

export const annualReviewDetailsRoute = <T extends AnyRoute>(indexRoute: T) => {
  const route = createRoute({
    getParentRoute: () => indexRoute,
    path: 'detail/$companyId/$id',

    loaderDeps: (all) => ({ ...all.search }),
    beforeLoad: ({ params, search, ...all }) => {
      if ('id' in params && params.id && Number(params.id))
        return { ...all, flatDeps: { id: Number(params.id), ...search } }
      else throw new NotFoundError()
    },
    loader: ({ context: { flatDeps } }) => {
      if (flatDeps)
        return getAnnualReviewDetails({
          id: Number(flatDeps?.id),
          historyPage: flatDeps?.historyPage,
          historySize: flatDeps?.historySize
        })
      else throw new NotFoundError()
    }
  })

  const detailsRoute = createRoute({
    getParentRoute: () => route,
    path: '/',
    validateSearch: (search: Record<string, unknown>) => sch.parse(search),
    component: lazyRouteComponent(() => import('./AnnualReviewDetails'))
  })

  const printRoute = createRoute({
    getParentRoute: () => route,
    path: 'print',
    component: lazyRouteComponent(() => import('./components/AnnualReviewPrint/AnnualReviewPrint'))
  })

  return route.addChildren([printRoute, detailsRoute])
}
