import { useCallback, useEffect, useState } from 'react'

import { BeforeFormChangeHandler, useFormData } from 'brief-form'

import { TableState, TableStateConfig } from '../types'

export const useTableState = <F = any>(config: TableStateConfig<F>): TableState<F> => {
  const {
    page = 1,
    size = 10,
    filter: uiFilter,
    order: uiOrder,
    expanded: isExpanded = false,
    onBeforeFilterChange,
    onFilterChanged
  } = config
  const [pagination, setPagination] = useState({ page, size })

  const onBeforeChange: BeforeFormChangeHandler<F> = useCallback(
    ({ value, errors, oldValue, oldErrors }) => {
      setPagination({ ...pagination, page: 1 })

      return onBeforeFilterChange
        ? onBeforeFilterChange({ value, errors, oldValue, oldErrors })
        : {
            value,
            errors
          }
    },
    [setPagination, pagination, onBeforeFilterChange]
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const filterForm = useFormData({ onBeforeChange, onFormChanged: onFilterChanged, initialValue: uiFilter })
  const { set } = filterForm
  const [order, setOrder] = useState(uiOrder)
  const [expanded, setExpanded] = useState(isExpanded)
  const onPageChange = useCallback((page: number, size: number) => setPagination({ page, size }), [setPagination])
  const onFilterToggle = useCallback(() => setExpanded(!expanded), [setExpanded, expanded])
  const onFilterOpen = useCallback(() => setExpanded(true), [setExpanded])

  const onFilterClear = useCallback(() => {
    set({ ...uiFilter } as any)
  }, [set])

  const onOrderChange = useCallback(
    (value: { field: string; direction: 'ASC' | 'DESC' }) => {
      setOrder([value])
    },
    [setOrder]
  )

  useEffect(() => {
    setPagination({ page, size })
  }, [page, size, setPagination])

  return {
    ...pagination,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filterForm,
    expanded,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    order,
    onPageChange,
    onFilterToggle,
    onFilterOpen,
    onFilterClear,
    onOrderChange
  }
}
