import { createContext } from 'react'

export type MRContextType = {
  deleteBankSource: (bankSourceId: number) => void
  cancelTask: (licenseId?: number) => void
  sendTask: (licenseId?: number) => void
  isSourcesReloading: boolean
}

export const MRContext = createContext<MRContextType | undefined>(undefined)
