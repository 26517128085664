import { FC, memo } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { Hr } from 'ui/components/Hr'
import { Page, PageFace } from 'ui/components/Page'

import { LicenseDetailsPageProps } from './LicenseDetailsPageProps'
import { Documents } from './components/Documents'
import { History } from './components/History'
import { Invoices } from './components/Invoices'
import { LicenseDetails } from './components/LicenseDetails'

export const LicenseDetailsPage: FC<LicenseDetailsPageProps> = memo((props) => {
  const { data, invoicesTableConfig, documentsTableConfig, historyTableConfig, onInvoiceDeleted, onDocumentDeleted } =
    props
  const routerState = useRouterState()

  const {
    id,
    active,
    name,
    relationship,
    number,
    issueDate,
    APIConnection,
    type,
    subType,
    posType,
    phone,
    taxRates,
    address,
    createdAt,
    updatedAt,
    invoiceSubmittal,
    history,
    sidebar
  } = data

  const returnUrl = routerState.location.pathname

  const licenseNameNumber = `${name} (${number})`

  return (
    <Page face={PageFace.SECONDARY} subTitle={name} title="License Page">
      {sidebar.includes('general-info') && (
        <LicenseDetails
          id={id}
          active={active}
          name={name}
          relationship={relationship}
          number={number}
          issueDate={issueDate}
          type={type}
          subType={subType}
          posType={posType}
          phone={phone}
          taxRates={taxRates}
          address={address}
          createdAt={createdAt}
          updatedAt={updatedAt}
          apiConnection={APIConnection}
        />
      )}
      {sidebar.includes('invoice-submittal') && (
        <Invoices
          licenseId={id}
          licenseName={licenseNameNumber}
          data={invoiceSubmittal}
          returnUrl={returnUrl}
          config={invoicesTableConfig}
          onInvoiceDeleted={onInvoiceDeleted}
        />
      )}
      {sidebar.includes('documents') && (
        <>
          <Hr />
          <Documents
            licenseId={id}
            companyId={relationship.id}
            returnUrl={returnUrl}
            webPage={data}
            onDocumentDeleted={onDocumentDeleted}
            isFetching={documentsTableConfig.isFetching}
            onFilterChange={documentsTableConfig.onFilterChange}
            order={documentsTableConfig.order}
            onOrderChange={documentsTableConfig.onOrderChange}
          />
        </>
      )}
      {sidebar.includes('history') && (
        <>
          <Hr />
          <History data={history} config={historyTableConfig} />
        </>
      )}
    </Page>
  )
})
