import { memo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { QueryTable, TableState } from 'ui/components/QueryTable'

import { columns } from './columns'
import { dataSourceSelector } from './dataSourceSelector'

interface Props {
  tableState: TableState
}

export const Table = memo((props: Props) => {
  const { tableState } = props

  return (
    <>
      <QueryTable
        name="Parsed Cash Transaction List"
        dataQa={TableDataQa.BP_PARSED_CASH_TRANSACTIONS_LIST}
        dataSources={[
          {
            key: 'list',
            url: Request.urls.bank.parsedTransactions,
            options: () => ({
              _options: {
                offset: 0,
                limit: tableState.size
              }
            })
          }
        ]}
        httpMethod="GET"
        columns={columns()}
        dataSourceSelector={dataSourceSelector}
        sorting={{
          order: tableState.order as any,
          onChange: tableState.onOrderChange
        }}
        pagination={{
          page: tableState.page,
          size: tableState.size,
          onChange: tableState.onPageChange
        }}
      />
    </>
  )
})
