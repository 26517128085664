import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReadHook, useStateManager } from 'react-query-state-manager'

import { Request } from 'commons/utils/request'
import { SarDetails, SarDetailsPayload } from '~bank-bsa-reporting/types'

const request = (opts?: SarDetailsPayload) => Request.post(Request.urls.bank.sarDetails, opts)

const useDetailsPage: ReadHook<SarDetails, SarDetailsPayload> = (payload, callbacks) =>
  useStateManager().use(['sar-details', payload], () => request(payload), {
    keepPreviousData: true,
    onSuccess: callbacks?.onSuccess,
    onError: callbacks?.onError
  })

const defaultFilter = (id: number) => ({
  historyPage: 1,
  historySize: 10,
  id
})

export const useGetSARDetails = (id: number) => {
  const [filter, setFilter] = useState(defaultFilter(id))
  const [updateCaller, setUpdateCaller] = useState<'' | 'history'>('')
  const resetPending = useCallback(() => setUpdateCaller(''), [])

  useEffect(() => {
    setFilter(defaultFilter(id))
  }, [id])

  const onHistoryFilterChange = useCallback(
    (page: number, size: number) => {
      if (page !== filter.historyPage || size !== filter.historySize) {
        setFilter({ ...filter, historyPage: page, historySize: size })
        setUpdateCaller('history')
      }
    },
    [filter]
  )

  const historyTableConfig = useMemo(
    () => ({
      isFetching: updateCaller === 'history',
      filter: {
        page: filter.historyPage,
        size: filter.historySize
      },
      onFilterChange: onHistoryFilterChange
    }),
    [filter.historyPage, filter.historySize, onHistoryFilterChange, updateCaller]
  )

  const { data, isFetching } = useDetailsPage(filter, {
    onSuccess: resetPending,
    onError: resetPending
  })

  return {
    data,
    isFetching,
    historyTableConfig
  }
}
