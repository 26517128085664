import { memo } from 'react'

import SC from 'styled-components'
import { Icon, IconName, IconSize } from 'ui/components/Icon'

interface Props {
  warnings: string[]
  title?: string
}

//region Styled

const Box = SC.div`
  background: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
  padding: 12px;
  border-radius: 8px;
  margin-top: 20px;
`

const WarningIcon = SC(Icon)`
  margin-right: 10px;
`

const Title = SC.b`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
`

const List = SC.ol`
  font-size: 14px;
  line-height: 22px;
  margin: 14px 0 0 0;
  padding: 0 0 0 22px;
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
`

//endregion

export const Warnings = memo(({ warnings, title, ...rest }: Props) => (
  <Box {...rest}>
    <Title>
      <WarningIcon name={IconName.ERROR_RED} size={IconSize.M} /> {title || 'Warning(s)'}
    </Title>
    <List>
      {warnings.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </List>
  </Box>
))
