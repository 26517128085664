import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { DOCUMENTS_PATH } from 'commons/constants/routes'
import isEmpty from 'lodash/isEmpty'
import { DataSourceItem, TableColumn, TablePagination, TableSorting } from 'ui/components'
import { useDeleteDocumentConfirmation } from '~bank-documents/hooks/useDeleteDocumentConfirmation'

import { columns } from './documentListTableColumns'
import { mapValueToRequestPayload } from './helpers/mapValueToRequestPayload'
import {
  DocumentFilterSources,
  DocumentListFilterFormValue,
  DocumentListFilters,
  DocumentListTableConfig,
  TableMappedDocument
} from './types'
import { useLoadDocumentListWebPage } from './useLoadDocumentListWebPage'
import { TableState } from './useTableState/types'
import { useTableState } from './useTableState/useTableState'

type UseDocumentListTableParams = {
  tableId: string
  initFilters?: DocumentListFilters
  externalTableState?: Partial<TableState>
  returnUrl: string
  tableConfig: DocumentListTableConfig
  onExternalFilterChange?: (value: DocumentListFilters) => void
  setIsDirty: Dispatch<SetStateAction<boolean>>
}

type UseDocumentListTableReturn = {
  filterExpanded: boolean
  currentFilterValue: DocumentListFilterFormValue | undefined
  DeleteConfirmation: () => JSX.Element | null
  filterSources: DocumentFilterSources | undefined
  isFetching: boolean
  tableItems: DataSourceItem<TableMappedDocument>[]
  documentColumns: TableColumn<TableMappedDocument>[]
  pagination: TablePagination
  sorting: TableSorting
  shouldClearFilter: Record<PropertyKey, never>
  onFilterChange: ({ filterValue }: { filterValue: DocumentListFilterFormValue }) => void
  onFilterExpanded: (expanded: boolean) => void
  onFilterClearClick: () => void
}
type UseDocumentListTableHook = (params: UseDocumentListTableParams) => UseDocumentListTableReturn

export const useDocumentListTable: UseDocumentListTableHook = ({
  tableId,
  returnUrl,
  initFilters,
  externalTableState,
  tableConfig,
  onExternalFilterChange,
  setIsDirty
}) => {
  const firstLoad = useRef<boolean>(true)
  const [currentFilterValue, setCurrentFilterValue] = useState<DocumentListFilterFormValue | undefined>(undefined)
  // filter data from the doc-list webpage
  const [filterSources, setFilterSources] = useState<DocumentFilterSources | undefined>(undefined)
  const tableState = useTableState({ externalTableState, initFilters })

  const [requestPayload, setRequestPayload] = useState<DocumentListFilters>(
    mapValueToRequestPayload({
      externalFilters: initFilters,
      currentFilter: {},
      tableStatePagination: tableState.pagination,
      tableStateSorting: tableState.sorting.order
    })
  )
  const [filterRequestPayload, setFilterRequestPayload] = useState({})

  const { invalidate, data, isFetching, isFetched } = useLoadDocumentListWebPage({
    tableId,
    requestPayload
  })

  const onFilterChange = useCallback(
    ({ filterValue }: { filterValue: DocumentListFilterFormValue }) => {
      setFilterRequestPayload({ ...initFilters?.filter, ...filterValue })
      tableState.handlers.setFirstPage()
      tableState.handlers.onInvalidate()
    },
    [tableState.handlers, initFilters]
  )

  useEffect(() => {
    if (!isEmpty(initFilters?.filter)) setIsDirty(true)

    if (firstLoad.current) {
      return
    }
    tableState.handlers.onInvalidate()
    // observe changes of initFilters in order to trigger reload ability
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFilters])

  // skip on the first render
  // reload data
  useEffect(() => {
    // duplicate is necessary because isDirty-state reset to false on expanding FilterPanel
    // TO-DO: find way to avoid this declarative state set
    if (!isEmpty(initFilters?.filter)) setIsDirty(true)

    if (firstLoad.current) {
      firstLoad.current = false
      return
    }

    if (!tableState.shouldInvalidate) {
      return
    }

    // reset page to 1 on searching
    if (initFilters?.search !== data?.config.search) {
      tableState.handlers.setFirstPage()
    }

    // MP-7974, hack: in case of onlyMyCompanies: true, CompanySelect return companyId as arrays
    const payload = mapValueToRequestPayload({
      externalFilters: initFilters,
      currentFilter: filterRequestPayload,
      tableStatePagination: {
        ...tableState.pagination,
        // reset page to 1 on searching
        page: initFilters?.search !== data?.config.search ? 1 : tableState.pagination.page
      },
      tableStateSorting: tableState.sorting.order
    })

    setRequestPayload(payload)
    if (onExternalFilterChange) {
      onExternalFilterChange(payload)
    }
    tableState.handlers.offInvalidate()
  }, [filterRequestPayload, tableState, initFilters, onExternalFilterChange])

  // after the page is loaded, we get filter resources
  useEffect(() => {
    if (!(isFetched && data && !isFetching)) {
      return
    }
    // TODO: replace to tableState.handlers.handleWebPage
    setFilterSources({
      internalOptions: data.internalOptions,
      subjectTypeOptions: data.subjectTypeOptions,
      frequencyOptions: data.frequencyOptions,
      alertsOptions: data.alertsOptions
    })
    // TODO: replace to tableState.handlers.handleWebPage
    setCurrentFilterValue({
      internal: data.config.filter?.internal,
      subjectType: data.config.filter?.subjectType,
      frequency: data.config.filter?.frequency,
      alerts: data.config.filter?.alerts,
      // MP-7974, hack: in case of onlyMyCompanies: true, CompanySelect return companyId as array
      companyId: data.config.filter?.companyId || initFilters?.filter?.companyId,
      licenseId: data.config.filter?.licenseId,
      bankAccountId: data.config.filter?.bankAccountId,
      contactId: data.config.filter?.contactId
    })
    tableState.handlers.handleWebPage(data)
  }, [isFetching, isFetched, data, tableState.handlers, initFilters?.filter?.companyId])

  const tableItems: DataSourceItem<TableMappedDocument>[] = useMemo(
    () =>
      data?.items
        ? data.items.map((item) => ({
            item: {
              id: item.id,
              name: item.name,
              contactId: '',
              internal: item.internal,
              frequency: item.frequency,
              expirationDate: item.expirationDate,
              alerts: item.alerts,
              actions: undefined,
              subject: item.subject,
              relationships: item.relationships
            }
          }))
        : [],
    [data]
  )

  const { DeleteConfirmation, openDeleteDocumentConfirmation: onDocumentDelete } = useDeleteDocumentConfirmation({
    onSuccess: invalidate
  })

  const documentColumns = useMemo(
    () =>
      columns({
        baseUrl: DOCUMENTS_PATH,
        returnUrl,
        hideSubjectColumn: tableConfig.hideSubjectColumn ?? false,
        hideRelatedCompanyColumn: tableConfig.hideRelatedCompanyColumn ?? false,
        onDocumentDelete
      }),
    [tableConfig, onDocumentDelete, returnUrl]
  )

  return {
    currentFilterValue,
    DeleteConfirmation,
    filterSources,
    onFilterChange,
    isFetching,
    tableItems,
    documentColumns,
    pagination: tableState.pagination,
    sorting: tableState.sorting,
    filterExpanded: tableState.expanded,
    shouldClearFilter: tableState.shouldClearFilter,
    onFilterExpanded: tableState.handlers.onFilterExpanded,
    onFilterClearClick: tableState.handlers.onFilterClearClick
  }
}
