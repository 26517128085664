import { FC, memo } from 'react'

import { BankApplicationPaths, COMPANIES_PATH } from 'commons/constants/routes'
import { EmptyValue } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { Icon, IconFace, IconName, IconSize } from 'ui/components/Icon'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { CustomerStatusLabel, UsPhoneLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'

import { useCompanyOwners } from '../CompanyOwners'
import { RelationShipInformationProps } from './RelationshipInformationProps'

import { Column, Columns, CompanyOwnersRow, IconInfo } from './styles'

export const RelationShipInformation: FC<RelationShipInformationProps> = memo((props) => {
  const {
    id,
    name,
    legalName,
    dba,
    isHolding,
    holding,
    holding_group_companies,
    customerStatus,
    applicationId,
    assignedBankUsers,
    onSetCompanyOwners,
    phone,
    website,
    tags,
    availableOwners
  } = props

  /*
    Merging availableOwners and assignedBankUsers, because for select we should have all available options for
    correct work.
   */
  const fullOptions = availableOwners.concat(
    ...assignedBankUsers.map((el) => {
      const mappedEl = {
        fullName: el.name,
        id: el.id
      }
      if (availableOwners.find((el) => el.id === mappedEl.id) !== undefined) {
        return []
      }
      return [mappedEl]
    })
  )

  const { CompanyOwnersDialog, openCompanyOwners } = useCompanyOwners(
    id,
    assignedBankUsers.map((i) => i.id) || [],
    assignedBankUsers.map((i) => i.name).join(',') || 'Add user',
    fullOptions,
    onSetCompanyOwners
  )

  const hasSubsidiaries = !!holding_group_companies?.length

  // MP-5531:2 the Holding company has subsidiaries but do not belong to another Holding company
  const showIsHolding = !!holding && hasSubsidiaries

  return (
    <InfoSet legend="Relationship Details" direction={InfoSetDirection.ROW}>
      <Columns>
        <Column>
          <Info label="Company Name">{name}</Info>
          <Info label="Entity Legal Name">{legalName}</Info>
          <Info label="DBA">{dba}</Info>
          {showIsHolding && (
            <IconInfo label="Is Holding">
              <Icon
                face={isHolding ? IconFace.ACTIVE : IconFace.DEFAULT}
                size={IconSize.S}
                name={isHolding ? IconName.CHECK : IconName.CLEAR}
              />
            </IconInfo>
          )}
          <Info label="Subsidiaries">
            {hasSubsidiaries ? (
              holding_group_companies.map((company) => (
                <div key={company.id}>
                  <Link to={`${COMPANIES_PATH}/${company.id}`}>{company.name}</Link>
                </div>
              ))
            ) : (
              <EmptyValue />
            )}
          </Info>

          <Info label="Parent Holding">
            {holding ? <Link to={`${COMPANIES_PATH}/${holding.id}`}>{holding.name}</Link> : <EmptyValue />}
          </Info>
          <Info label="Customer Status">
            <CustomerStatusLabel name={customerStatus} />
          </Info>
          {!!applicationId && (
            <Link to={BankApplicationPaths.DETAILS} params={{ applicationId }}>
              <IconButton face="positive" icon={IconName.APPLICATION}>
                Open Application
              </IconButton>
            </Link>
          )}
        </Column>
        <Column>
          <Info label="Relationship Owner(s)">
            <CompanyOwnersRow>
              <CompanyOwnersDialog />
              {assignedBankUsers.map((item) => item.name).join(', ')}
              <Icon name={IconName.EDIT} size={IconSize.XS} onClick={openCompanyOwners} />
            </CompanyOwnersRow>
          </Info>
          <Info label="Phone">
            <UsPhoneLabel value={phone || ''} />
          </Info>
          <Info label="Website">{website}</Info>
          <Info label="Tags">{tags}</Info>
        </Column>
      </Columns>
    </InfoSet>
  )
})
