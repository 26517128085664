import { FC, memo } from 'react'

import * as SC from '../styles'
import { CheckBox } from 'ui/components/CheckBox'
import { formatDateTime } from 'ui/components/DateTime'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { TextMask } from 'ui/components/TextMask'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import { SubjectInformationProps } from './SubjectInformation.types'

export const SubjectInformation: FC<SubjectInformationProps> = memo((props) => {
  const { data } = props
  const { subjectsInfo } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 3. Subject Information" anchor="step3">
      {subjectsInfo?.map((item, index) => {
        const {
          isEntity,
          isAllInfoUnavailable,
          showEntityName,
          showLastName,
          alternateNames,
          showFirstName,
          showMiddleName,
          showSuffix,
          showGender,
          showBirthdate,
          businessType,
          naicsCode,
          tin,
          tinType,
          phoneNumbers,
          emails,
          websites,
          corroborativeStatementToFiler,
          roleInActivity,
          addressesInfo,
          idsInfo,
          relationshipsInfo,
          institutionsInfo
        } = item

        return (
          <Panel
            rounded
            shadowed
            key={index}
            collapsible={false}
            title={`Part I Subject Information ${index + 1} of ${subjectsInfo?.length}`}
            face={PanelFace.PRIMARY}
          >
            <SC.InnerPanel>
              <Row>
                <SC.CheckOrRadio>
                  <CheckBox value={isEntity} checkBoxLabel="If entity" />
                  <CheckBox
                    value={isAllInfoUnavailable}
                    checkBoxLabel="if all critical* subject information is unavailable"
                  />
                </SC.CheckOrRadio>
              </Row>
              <Row>
                <Col>
                  <Info label="4. Individual's last name or entity's legal name">{showEntityName || showLastName}</Info>
                </Col>
                <Col>
                  <Info label="9. Alternate name">
                    {alternateNames?.map((el, index) => (
                      <div key={index}>{el}</div>
                    ))}
                  </Info>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Info label="5. First name">{showFirstName}</Info>
                </Col>
                <Col>
                  <Info label="6. Middle name">{showMiddleName}</Info>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Info label="7. Suffix">{showSuffix}</Info>
                </Col>
                <Col>
                  <Info label="8. Gender">{showGender}</Info>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Info label="19. Date of birth">{showBirthdate}</Info>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Info label="10. Occupation or type of business">{businessType}</Info>
                </Col>
                <Col>
                  <Info label="10a. NAICS Code">{naicsCode}</Info>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Info label="16. TIN">{tin ? <TextMask text={tin} iconSize={IconSize.XS} /> : null}</Info>
                </Col>
                <Col>
                  <Info label="17. TIN type">{tinType}</Info>
                </Col>
              </Row>
              <SC.Label>Phone (s)</SC.Label>
              {phoneNumbers?.map((el, index) => (
                <div key={index}>
                  <Row>
                    <Col>
                      <Info label="21. Phone number">{el.phoneNumber}</Info>
                    </Col>
                    <Col>
                      <Info label="Ext.">{el.phoneExt}</Info>
                    </Col>
                  </Row>
                  <Row>
                    <Info label="20. Type">{el.phoneType}</Info>
                  </Row>
                </div>
              ))}
              <Row>
                <Col>
                  <Info label="22. E-mail address">
                    {emails?.map((el, index) => (
                      <div key={index}>{el}</div>
                    ))}
                  </Info>
                </Col>
                <Col>
                  <Info label="22a. Website (URL) address">
                    {websites?.map((el, index) => (
                      <div key={index}>{el}</div>
                    ))}
                  </Info>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Info label="23. Corroborative statement to filer?">{corroborativeStatementToFiler}</Info>
                </Col>
                <Col>
                  <Info label="28. Subject's role in suspicious activity">{roleInActivity}</Info>
                </Col>
              </Row>
              <Row>
                <Info label="11-14. Subject Address Information">
                  {addressesInfo?.map((el, index) => (
                    <div key={index}>
                      <SC.Label>Address #{index + 1}</SC.Label>
                      <div>{el?.address}</div>
                      <div>
                        {[el?.city, el?.country === 'US' ? el?.state : null, el?.postalCode]
                          .filter(Boolean)
                          .join(', ') || '-'}
                      </div>
                      <div>{el?.country}</div>
                    </div>
                  ))}
                </Info>
              </Row>
              <Row>
                <Info label="18. Form of identification for subject">
                  {idsInfo?.map((el, index) => (
                    <div key={index}>
                      <SC.Label>Subject #{index + 1}</SC.Label>
                      <div>Country {el.idCountry || '-'}</div>
                      <div>
                        Form {el.idForm || '-'} {el.idForm?.length > 0 ? el.idFormOther || '-' : ''}
                      </div>
                      <div>Number {el.idNumber || '-'}</div>
                      <div>State {el.idState || '-'}</div>
                    </div>
                  ))}
                </Info>
              </Row>
              <Row>
                <Col2x>
                  <Info label="24. Relationship of the subject to an institution listed in Part III or IV">
                    {relationshipsInfo?.map((el, index) => (
                      <div key={index}>
                        <SC.Label>Subject #{index + 1}</SC.Label>
                        <br />
                        <SC.Label>
                          a. Institution TIN
                          {el.tin ? <SC.TextMaskInline text={el.tin} iconSize={IconSize.XS} /> : '-'}
                        </SC.Label>
                        <SC.CheckBoxes>
                          <CheckBox value={el.accountant} checkBoxLabel="b. Accountant" />
                          <CheckBox value={el.director} checkBoxLabel="h. Director" />
                        </SC.CheckBoxes>
                        <SC.CheckBoxes>
                          <CheckBox value={el.agent} checkBoxLabel="c. Agent" />
                          <CheckBox value={el.employee} checkBoxLabel="i. Employee" />
                        </SC.CheckBoxes>
                        <SC.CheckBoxes>
                          <CheckBox value={el.appraiser} checkBoxLabel="d. Appraiser" />
                          <CheckBox value={el.noRelationship} checkBoxLabel="j. No relationship to institution" />
                        </SC.CheckBoxes>
                        <SC.CheckBoxes>
                          <CheckBox value={el.attorney} checkBoxLabel="e. Attorney" />
                          <CheckBox value={el.officer} checkBoxLabel="k. Officer" />
                        </SC.CheckBoxes>
                        <SC.CheckBoxes>
                          <CheckBox value={el.borrower} checkBoxLabel="f. Borrower" />
                          <CheckBox value={el.owner} checkBoxLabel="l. Owner or Controlling Shareholder" />
                        </SC.CheckBoxes>
                        <SC.CheckBoxes>
                          <CheckBox value={el.customer} checkBoxLabel="g. Customer" />
                          <CheckBox value={el.other} checkBoxLabel="z. Other" />
                        </SC.CheckBoxes>
                        <SC.CheckBoxes>
                          <div />
                          <SC.Label>{el.otherDesc}</SC.Label>
                        </SC.CheckBoxes>
                        <br />
                        <Row>
                          <Col>
                            <Info label="25. Status of relationship">{el.relationshipStatus}</Info>
                          </Col>
                          <Col>
                            <Info label="26. Action Date">{formatDateTime(el.actionDate)}</Info>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </Info>
                </Col2x>
              </Row>
              <Row>
                <Info label="27. Financial Institution TIN and account number(s) affected that are related to subject">
                  {institutionsInfo?.map((el, index) => (
                    <div key={index} style={{ marginBottom: 20 }}>
                      <div>
                        Institution TIN
                        {el.tin ? <SC.TextMaskInline text={el.tin} iconSize={IconSize.XS} /> : '-'}
                      </div>
                      {el.accountNumbers.map((v, i: number) => (
                        <SC.AccountNumber key={i}>
                          {v.number && <TextMask text={v.number} isBankAccount iconSize={IconSize.XS} />}
                          <CheckBox value={v.closed} checkBoxLabel="Closed" />
                        </SC.AccountNumber>
                      ))}
                    </div>
                  ))}
                </Info>
              </Row>
            </SC.InnerPanel>
          </Panel>
        )
      })}
    </PageSection>
  )
})
