import { memo, useCallback } from 'react'

import { SalesToDepositsComparison } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/SalesToDepositsComparison'
import { formatCurrencyCent } from 'commons/utils'

import { s } from './styles'

interface Props {
  item: SalesToDepositsComparison
}
export const SalesToDepositComparisonTable = memo((props: Props) => {
  const { item } = props

  const PQ = useCallback((v: string) => {
    if (v === '-1.00') {
      return 'N/A'
    }

    return v.startsWith('-') ? <s.TV $negative>({v})%</s.TV> : <s.TV>{v}%</s.TV>
  }, [])

  return (
    <s.WrappedLayer rounded shadowed>
      <s.Box>
        <s.Title>SALES TO DEPOSIT COMPARISON</s.Title>
        <s.Columns>
          <s.Labels>
            <s.Label>Cannabis Sales</s.Label>
            <s.Label>Non-Cannabis Sales</s.Label>
            <s.Label>Taxes</s.Label>
            <s.Label>Total Sales</s.Label>
            <s.Label>Total Deposits</s.Label>
            <s.Label>$ Difference</s.Label>
            <s.Label>% Difference</s.Label>
          </s.Labels>
          <s.Table>
            <thead>
              <tr>
                <s.Th>Total</s.Th>
                <s.Th>{item.firstMonth.header}</s.Th>
                <s.Th>{item.secondMonth.header}</s.Th>
                <s.Th>{item.thirdMonth.header}</s.Th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <s.Td>{formatCurrencyCent(item.total.sales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.firstMonth.sales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.secondMonth.sales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.thirdMonth.sales, false)}</s.Td>
              </tr>
              <tr>
                <s.Td>{formatCurrencyCent(item.total.nonCannabisSales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.firstMonth.nonCannabisSales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.secondMonth.nonCannabisSales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.thirdMonth.nonCannabisSales, false)}</s.Td>
              </tr>
              <tr>
                <s.Td>{formatCurrencyCent(item.total.estimatedSalesTax, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.firstMonth.estimatedSalesTax, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.secondMonth.estimatedSalesTax, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.thirdMonth.estimatedSalesTax, false)}</s.Td>
              </tr>
              <tr>
                <s.Td>{formatCurrencyCent(item.total.totalSales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.firstMonth.totalSales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.secondMonth.totalSales, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.thirdMonth.totalSales, false)}</s.Td>
              </tr>
              <tr>
                <s.Td>{formatCurrencyCent(item.total.totalDeposits, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.firstMonth.totalDeposits, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.secondMonth.totalDeposits, false)}</s.Td>
                <s.Td>{formatCurrencyCent(item.thirdMonth.totalDeposits, false)}</s.Td>
              </tr>
              <tr>
                <s.Td>{formatCurrencyCent(item.total.differenceAbsolute, false)}</s.Td>
                <s.Stub />
                <s.Stub />
                <s.Stub />
              </tr>
              <tr>
                <s.Td $hl={item.total.differenceRelative.alerted}>{PQ(item.total.differenceRelative.percent)}</s.Td>
                <s.Stub />
                <s.Stub />
                <s.Stub />
              </tr>
              <tr>
                <s.Stub />
                <s.Stub />
                <s.Stub />
              </tr>
            </tbody>
          </s.Table>
        </s.Columns>
      </s.Box>
    </s.WrappedLayer>
  )
})
