import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../context'

type PhoneNumberProps = {
  index1: number
  index2: number
}

export const PhoneNumber = ({ index1, index2 }: PhoneNumberProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={
        `subjectsInfo[${index1}].phoneNumbers[${index2}].phoneNumber` as 'subjectsInfo[0].phoneNumbers[0].phoneNumber'
      }
      children={(field) => (
        <FF field={field} label="21. Phone number" input={(p) => <TextInput {...p} maxLength={16} />} />
      )}
    />
  )
}
