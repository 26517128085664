import { memo, useCallback } from 'react'

import * as SC from './styles'

const bg = require('./assets/bg.svg')

export const NotFound = memo(() => {
  const handleBackLinkClick = useCallback(() => {
    // React routing doesn't configured here, redirect directly (not throught history)
    window.location.href = '/'
  }, [])

  return (
    <SC.Box>
      <SC.Bg dangerouslySetInnerHTML={{ __html: bg }} />
      <SC.Header>Whoops! 404</SC.Header>
      <SC.Message>We couldn&apos;t find the page you&apos;re looking for</SC.Message>
      <SC.BackLink to="/" onClick={handleBackLinkClick}>
        Go to Home page
      </SC.BackLink>
    </SC.Box>
  )
})
