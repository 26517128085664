import * as z from 'zod'
import { AdverseMediaListRequest } from '~bank-adverse-media/types'

export const AdverseMediaListSchema = z.object({
  entityType: z.string().optional(),
  page: z.number().int().positive().default(1),
  size: z.number().int().positive().default(20),
  riskLevel: z.string().optional(),
  searchCase: z.string().optional()
}) as z.ZodType<AdverseMediaListRequest>
