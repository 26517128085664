import { HistoryLink } from 'commons/types/DTO/bank/historyAudit'
import { camelCase } from 'lodash'
import { DownloadLink, Link } from 'ui/components'
import { getDirectDownloadLinkByEntityType } from '~bank-entity-history/utils/getDirectDownloadLinkByEntityType'
import { getLinkByEntityType } from '~bank-entity-history/utils/getLinkByEntityType'

import { SC } from './styles'

type HeaderLinkProps = HistoryLink

export const HeaderLink = ({ definition, title, id, entityType, additionalId, type }: HeaderLinkProps) => {
  switch (type) {
    case 'download':
      return (
        <SC.Row>
          <DownloadLink
            direct
            baseUrl={getDirectDownloadLinkByEntityType(entityType)}
            parametersForm={{ id: Number(id) }}
            handleError={() => ({})}
            name={title}
          >
            <span data-qa={`details-${camelCase(definition)}-field-label`}>{definition} - </span>
            {title}
          </DownloadLink>
        </SC.Row>
      )
    default:
      return (
        <SC.Row>
          <span data-qa={`details-${camelCase(definition)}-field-label`}>{definition}</span> -{' '}
          <Link
            to={id ? getLinkByEntityType(entityType, id, additionalId) : ''}
            data-qa={`details-${camelCase(definition)}-field-value`}
          >
            {title}
          </Link>
        </SC.Row>
      )
  }
}
