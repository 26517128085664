import { FC, Suspense, memo } from 'react'

import { RouterProvider } from '@tanstack/react-router'
import { BPRouterItems } from 'commons/types/enums'
import { NotFound } from 'modules/404'
import { ErrorDetails } from 'ui/components'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { BPRouter } from './BPRouter'
import { getBPAccessibleRoutingFlags } from './getBPAccessibleRoutingFlags'

export interface RoutesProps {
  accessibleRoutes?: BPRouterItems[]
}

export const AppRoutes: FC<RoutesProps> = memo((props) => {
  const { accessibleRoutes } = props

  if (!accessibleRoutes) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
      <RouterProvider
        router={BPRouter()}
        context={{
          accessibleRoutingFlags: getBPAccessibleRoutingFlags(accessibleRoutes)
        }}
        defaultErrorComponent={() => <ErrorDetails />}
        defaultNotFoundComponent={() => <NotFound />}
      />
    </Suspense>
  )
})
