import { FC } from 'react'

import { BankAccountSelect, CompanySelect, ContactSelect, FF, LicenseSelect, Validators } from 'ui/components'

import { SelectsForNewDocumentProps } from './SelectsForNewDocument.types'

export const SelectsForNewDocument: FC<SelectsForNewDocumentProps> = ({ form, formSubjectType, presetSubjectName }) => (
  <>
    {formSubjectType === 'license' && (
      <form.Field
        name="licenseId"
        validators={{
          onSubmitAsync: ({ value }) => Validators.required.field(value)
        }}
        children={(licenseField) => (
          <FF
            label="License"
            field={licenseField}
            required={true}
            input={(props) => (
              <LicenseSelect
                emptyOption={
                  presetSubjectName
                    ? { value: licenseField.state.value as number, label: presetSubjectName }
                    : undefined
                }
                data-qa="License"
                {...props}
              />
            )}
          />
        )}
      />
    )}
    {formSubjectType === 'company' && (
      <form.Field
        name="companyId"
        validators={{
          onSubmitAsync: ({ value }) => Validators.required.field(value)
        }}
        children={(companyField) => (
          <FF
            label="Relationship"
            field={companyField}
            required={true}
            input={(props) => (
              <CompanySelect
                isSearchRequest={true}
                hideMyCompaniesFilter={true}
                options-data-qa="Relationships"
                data-qa="Relationships Type"
                defaultOption={
                  presetSubjectName
                    ? { value: companyField.state.value as number, label: presetSubjectName }
                    : undefined
                }
                {...props}
              />
            )}
          />
        )}
      />
    )}
    {formSubjectType === 'bank_account' && (
      <form.Field
        name="bankAccountId"
        validators={{
          onSubmitAsync: ({ value }) => Validators.required.field(value)
        }}
        children={(bankAccountIdField) => (
          <FF
            label="Bank Account"
            field={bankAccountIdField}
            required={true}
            input={(props) => (
              <BankAccountSelect
                isSearchRequest={true}
                defaultOption={
                  presetSubjectName
                    ? { value: bankAccountIdField.state.value as number, label: presetSubjectName }
                    : undefined
                }
                data-qa="Bank Account"
                {...props}
              />
            )}
          />
        )}
      />
    )}
    {formSubjectType === 'contact' && (
      <form.Field
        name="contactId"
        validators={{
          onSubmitAsync: ({ value }) => Validators.required.field(value)
        }}
        children={(contactIdField) => (
          <FF
            label="Contact"
            field={contactIdField}
            input={(props) => (
              <ContactSelect
                emptyOption={
                  presetSubjectName
                    ? { value: contactIdField.state.value as number, label: presetSubjectName }
                    : undefined
                }
                data-qa="Contact"
                {...props}
              />
            )}
          />
        )}
      />
    )}
  </>
)
