import { FieldApi } from '@tanstack/react-form'
import { IconName } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { useConfirmation } from 'ui/hooks'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'
import { emptySAR } from '~bank-bsa-reporting/constants'
import { SarEdit } from '~bank-bsa-reporting/types'

import { useSARFormContext } from '../../../../../../../context'
import { useSubjectInformationItemContext } from '../../SubjectInformationItemContext'
import { IdCountry, IdForm, IdFormOther, IdNumber, IdState } from './fields'

export const FormOfIdentification = ({ index }: { index: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <>
      <FormRow>
        <Col2x>
          <h5>18. Form of identification for subject</h5>
        </Col2x>
      </FormRow>

      <formApi.Field
        mode="array"
        name={`subjectsInfo[${index as 0}].idsInfo`}
        children={(field) => {
          if (typeof field.state.value === 'undefined') {
            return null
          }

          return (
            <>
              {(field.state.value || []).map((_, index2) => (
                <FormOfIdentificationItem
                  index1={index}
                  index2={index2}
                  parentField={field}
                  key={`${index}-${index2}`}
                />
              ))}

              {!isAllInfoUnavailable && (
                <IconButton
                  icon={IconName.ADD_GREEN}
                  onClick={() => field.pushValue(emptySAR.subjectsInfo[0].idsInfo[0])}
                />
              )}
            </>
          )
        }}
      />
    </>
  )
}

const FormOfIdentificationItem = ({
  index1,
  index2,
  parentField
}: {
  index1: number
  index2: number
  parentField: FieldApi<SarEdit, 'subjectsInfo[0].idsInfo'>
}) => {
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()
  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => parentField.removeValue(index2),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return (
    <>
      <Confirmation />
      <FormRow>
        <Col>
          <IdForm index1={index1} index2={index2} />
        </Col>
        <Col>
          <IdFormOther index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <IdNumber index1={index1} index2={index2} />
        </Col>
        <Col>
          <IdCountry index1={index1} index2={index2} />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <IdState index1={index1} index2={index2} />
        </Col>
        {parentField.state.value.length > 1 && !isAllInfoUnavailable && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting} />
        )}
      </FormRow>
    </>
  )
}
