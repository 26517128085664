import { Suspense, lazy } from 'react'

import { AnyRoute, createRoute, notFound, useLoaderData } from '@tanstack/react-router'
import { getOnboardingDocumentCloneModeWebPage } from 'commons/service/bank/onboardingDocuments'
import { OnboardingDocumentEditModeWebPage } from 'commons/types/DTO/bank/applications/onboardingDocuments'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { Sidebar } from '../../components/Sidebar'

const SetupDocumentsClone = lazy(() => import('./SetupDocumentsClone'))

export const onboardingDocumentsCloneRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '$id/clone',
    loader: async ({ params }) => {
      const { id } = params as { id: string }
      const response: OnboardingDocumentEditModeWebPage = await getOnboardingDocumentCloneModeWebPage(Number(id) || 0)

      if (!response?.companies) {
        throw notFound()
      }

      return {
        webPageData: response
      }
    },
    component: () => {
      const { webPageData } = useLoaderData({ strict: false })

      if (!webPageData) throw notFound()

      return (
        <BPLayout sidebar={<Sidebar companies={webPageData.companies} />}>
          <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
            <SetupDocumentsClone />
          </Suspense>
        </BPLayout>
      )
    }
  })
