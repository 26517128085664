import { IconName, Page, PageFace } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'

import { DetailsPageProps } from './DetailsPage.types'
import { BatchItems, GeneralInformation } from './components'

export const DetailsPage = (props: DetailsPageProps) => {
  const { details, page, pageSize, onHistoryPageChange, onDelete } = props
  const { id, enableDelete, subjectsInfo } = details

  return (
    <Page
      title={`ID ${id}`}
      subTitle="Batch Page"
      face={PageFace.SECONDARY}
      actions={
        enableDelete && (
          <IconButton onClick={onDelete} icon={IconName.DELETE} face="neutral">
            Delete Batch
          </IconButton>
        )
      }
    >
      <GeneralInformation details={details} page={page} pageSize={pageSize} onHistoryPageChange={onHistoryPageChange} />
      <BatchItems items={subjectsInfo} type={details.type} />
    </Page>
  )
}
