import styled, { css, keyframes } from 'styled-components'

const InAnimation = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`

const OutAnimation = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`

export const Wrapper = styled.div<{ $opened: boolean }>`
  /* TODO: predefine z-indexes for all type of components as a constants in the separate file */
  z-index: 1010;

  ${(p) =>
    p.$opened
      ? css`
          opacity: 1;
          animation: ${InAnimation} 100ms ease-in;
        `
      : css`
          opacity: 0;
          animation: ${OutAnimation} 100ms ease-out;
          animation-fill-mode: 'forwards';
        `};
`
