import { FC, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'

import { FooterProps } from './FooterProps'

import * as SC from './styles'

export const Footer: FC<FooterProps> = memo((props) => {
  const { onDelete, onSubmit, onCancel, isSaving } = props

  return (
    <SC.Box>
      {!!onDelete && (
        <SC.DeleteButton data-qa="Delete" icon={IconName.DELETE} face="negative" onClick={onDelete} disabled={isSaving}>
          Delete
        </SC.DeleteButton>
      )}
      <ButtonGroup margin="small">
        <Button face="neutral" onClick={onCancel} disabled={isSaving}>
          Cancel
        </Button>
        <Button data-qa="Submit" onClick={onSubmit} face="positive" loading={isSaving}>
          Submit
        </Button>
      </ButtonGroup>
    </SC.Box>
  )
})
