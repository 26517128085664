import { LicenseWebPageInvoiceSubmitalItem } from 'commons/types/DTO/bank/license'
import { formatCurrencyCent } from 'commons/utils'
import { DateTime } from 'ui/components/DateTime'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { MenuIconHoverFace, MenuItemBehavior, MenuItemTextFace, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

export const useColumns = (
  deleteInvoice: ({ id }: { id: number }) => void
): TableColumn<LicenseWebPageInvoiceSubmitalItem>[] => [
  {
    title: 'Invoice Submittal Number',
    render: ({ id }) => (
      <Link to="/invoices/$id" params={{ id }}>
        IS-{id}
      </Link>
    )
  },
  {
    title: 'Amount',
    render: ({ amount }) => formatCurrencyCent(amount)
  },
  {
    key: 'notes',
    title: 'Notes'
  },
  {
    title: 'Last Modified Date',
    render: ({ updatedAt }) => <DateTime date={updatedAt} />
  },
  {
    title: 'Actions',
    align: 'center',
    width: '50px',
    render: (item) => (
      <TableActions
        menuContainerId="invoiceSubmittalTableMenuContainer"
        items={[
          {
            key: 1,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            url: `/invoices/${item.id}/edit`
          },
          {
            key: 2,
            title: 'Delete',
            iconName: IconName.DELETE,
            behavior: MenuItemBehavior.BUTTON,
            textFace: MenuItemTextFace.GREY,
            onClick: () => deleteInvoice({ id: item.id })
          }
        ]}
      />
    )
  }
]
