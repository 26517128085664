import { Dispatch, SetStateAction } from 'react'
import { useMutation } from 'react-query'

import { Toast } from 'ui/components/Toast'

import { Batch } from '../../routes/CTR/List/types'
import * as Service from '../../service'

export const useXMLGeneration = (setGeneratedBatch: Dispatch<SetStateAction<null | Batch>>) =>
  useMutation((ids: number[]) => Service.CTR.generateXML({ ctr_ids: ids }), {
    onError: ({ message, meta }: { message: string; meta?: { [key: string]: string } }) => {
      const errorMessage = message || 'Error generating XML batch'
      if (!meta) {
        Toast.errorSmall(errorMessage)
      } else {
        const errors: string[] = []
        for (const key in meta) {
          errors.push(meta[key])
        }
        Toast.errorSmall(`${errorMessage}: ${errors.join(',')}`)
      }
    },
    onSuccess: (response) => {
      const batchName = response.s3_key.split('_id__')[1]

      setGeneratedBatch({ id: response.batch_id, name: batchName })
    }
  })
