import { FF, TextInput } from 'ui/components'

import { useSARFormContext } from '../../../../../../../../context'
import { useSubjectInformationItemContext } from '../../../SubjectInformationItemContext'

export const IdNumber = ({ index1, index2 }: { index1: number; index2: number }) => {
  const { formApi } = useSARFormContext()
  const { isAllInfoUnavailable } = useSubjectInformationItemContext()

  return (
    <formApi.Field
      name={`subjectsInfo[${index1 as 0}].idsInfo[${index2 as 0}].idNumber`}
      children={(field) => (
        <FF field={field} label="Number" input={(p) => <TextInput {...p} disabled={isAllInfoUnavailable} />} />
      )}
    />
  )
}
