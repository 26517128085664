import { FC, memo } from 'react'

import { ClampedTextProps } from './ClampedText.types'

import { Box } from './styles'

export const ClampedText: FC<ClampedTextProps> = memo((props) => {
  const { lines = 2, children } = props
  let title = ''

  if (typeof children === 'string') {
    title = children
  }

  return (
    <Box $lines={lines} title={title}>
      {children}
    </Box>
  )
})
