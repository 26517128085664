import { useCallback, useEffect, useMemo, useState } from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { useGetHistoryList } from 'commons/hooks/bank/history'
import { HistoryAuditListFilter } from 'commons/types/DTO/bank/historyAudit'
import { TablePagination, useTableState } from 'ui/components'

import { checkIsTableFilterDirty } from '../utils/checkIsTableFilterDirty'
import { createParamsFromFilter } from '../utils/createParamsFromFilter'

const DEFAULT_FILTER: HistoryAuditListFilter = {
  action: undefined,
  bankUserId: undefined,
  entityType: undefined,
  entityId: undefined,
  page: 1,
  size: 10,
  timeFrom: undefined,
  timeTo: undefined
}

export const useHistoryAuditList = () => {
  const navigate = useNavigate({ from: '/history' })
  const searchParams = getRouteApi('/history').useSearch()

  const [filter, setFilter] = useState<HistoryAuditListFilter>({ ...DEFAULT_FILTER, ...searchParams })

  const { isFetching, data } = useGetHistoryList(filter)

  const syncUrl = useCallback(
    (filter: HistoryAuditListFilter) => {
      const params = createParamsFromFilter(filter)
      navigate({ from: '/history', to: '/history', search: params, resetScroll: false })
    },
    [navigate]
  )

  const onBeforeChange: BeforeFormChangeHandler<HistoryAuditListFilter> = useCallback(
    ({ value, errors }) => {
      const newValue = { ...value, page: 1 }

      // prohibit to set TimeTo without set TimeFrom
      if (newValue?.timeTo && !newValue?.timeFrom) {
        return {
          value: { ...newValue, timeTo: undefined },
          errors: { ...errors, timeFrom: 'This field is necessary' }
        }
      }
      // prohibit to set an early date in TimeTo than in TimeFrom
      if (newValue?.timeFrom && newValue?.timeTo && new Date(newValue?.timeFrom) > new Date(newValue?.timeTo)) {
        return {
          value: { ...newValue, timeTo: undefined },
          errors: { ...errors, timeTo: 'This date must be bigger than Date From' }
        }
      }

      syncUrl(newValue)
      setFilter(newValue)

      return {
        value: newValue,
        errors: {}
      }
    },
    [syncUrl]
  )

  const tableState = useTableState({ filter })

  const filterForm = useFormData<HistoryAuditListFilter>({ initialValue: filter, onBeforeChange })

  useEffect(() => {
    filterForm.set({
      value: { ...DEFAULT_FILTER, ...searchParams },
      dirty: checkIsTableFilterDirty(DEFAULT_FILTER, searchParams)
    })
  }, [searchParams])

  const pagination: TablePagination = useMemo(
    () => ({
      total: data?.totalCount || 10001,
      page: filter.page,
      pageSize: filter.size,
      tooMany: data?.totalCount === 10001,
      onChange: (page: number, size: number) => {
        syncUrl({ ...filter, page, size })
        setFilter({ ...filter, page, size })
      }
    }),
    [filter, data, syncUrl]
  )

  const onClear = useCallback(() => {
    filterForm.set({ value: DEFAULT_FILTER, reset: true })
    syncUrl(DEFAULT_FILTER)
    setFilter(DEFAULT_FILTER)
  }, [filterForm, syncUrl])

  // sync filter by clicking on cell in EntityId-column
  const onClickEntityId = useCallback(
    (entityId: string) => {
      filterForm.config.onChange({ ...filterForm.config.value, entityId }, {})
      setFilter((filter) => ({ ...filter, entityId }))
    },
    [filterForm]
  )

  return {
    isFetching,
    items: data?.items,
    filterForm,
    tableState,
    actionOptions: data?.actionOptions || [],
    bankUserOptions: data?.bankUserOptions || [],
    entityTypeOptions: data?.entityTypeOptions || [],
    pagination,
    onClear,
    onClickEntityId
  }
}
