import { FC } from 'react'

import { DateInFileName } from '../../helpers/dateInFileName'
import { ExportCSVButtonProps } from './ExportCSVButtonProps'

import * as SC from './styles'

export const ExportCSVButton: FC<ExportCSVButtonProps> = ({ url, reportName, parametersForm, method }) => (
  <SC.CSVLink
    direct
    parametersForm={parametersForm}
    handleError={() => ({})}
    mimeType="text/csv"
    baseUrl={url}
    name={`${reportName} Report ${DateInFileName()}.csv`}
    method={method}
  >
    Export .csv
  </SC.CSVLink>
)
