import { MouseEventHandler, forwardRef, useCallback } from 'react'

import { FormInputProps } from 'brief-form'
import formatWithOptions from 'date-fns/fp/formatWithOptions'
import enUS from 'date-fns/locale/en-US'
import SC from 'styled-components'
import { BaseInputOpts, DateInput, TextInput } from 'ui/components/InputV2'

import { Icon, IconName, IconSize } from '../Icon'

type DatePickerInputOpts = Omit<BaseInputOpts, 'onChange' | 'value'> & {
  needValue: boolean
  showTimeSelect?: boolean
  clearDate: MouseEventHandler<HTMLElement>
  hasDate: boolean
  isClearable?: boolean
}

type DatePickerInputProps = FormInputProps<string, DatePickerInputOpts>

// region Styled

const Overlay = SC.div`
  position: relative;
`

const Clear = SC(Icon)<{ $withTime?: boolean }>`
  position: absolute;
  left: ${(p) => (p.$withTime ? 147 : 117)}px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0.5;
  
  &:hover {
    opacity: 1;
  }
`

const Calendar = SC(Icon)<{ $withTime?: boolean; $disabled: boolean }>`
  position: absolute;
  left: ${(p) => (p.$withTime ? 168 : 138)}px;
  top: ${(p) => (p.$disabled ? -19 : 0)}px;
  bottom: 0;
  margin: auto 0;
  z-index: 1;
  pointer-events: none;
  cursor: pointer;
  opacity: 0.5;
`

// endregion

export const DatePickerInput = forwardRef<any, DatePickerInputProps>((props, _) => {
  const {
    value,
    onChange,
    onClick,
    onKeyDown,
    needValue,
    hasDate,
    showTimeSelect,
    clearDate,
    isClearable = true,
    disabled = false,
    ...rest
  } = props

  const viewableDate = useCallback(() => {
    if (!value) {
      return ''
    }

    let date

    if (showTimeSelect) {
      date = new Date(value)
    } else {
      const split = value.substring(0, 10)?.split('-')
      date = new Date(+split[0], +split[1] - 1, +split[2])
    }

    const dateToString = showTimeSelect
      ? formatWithOptions({ locale: enUS }, 'MM/dd/yyyy, hh:mm a')
      : formatWithOptions({ locale: enUS }, 'MM/dd/yyyy')
    return dateToString(date)
  }, [showTimeSelect, value])

  return (
    <Overlay>
      {hasDate && isClearable && (
        <Clear $withTime={showTimeSelect} name={IconName.CLEAR} size={IconSize.XS} onClick={clearDate} />
      )}
      <Calendar $withTime={showTimeSelect} $disabled={disabled} name={IconName.CALENDAR_DOTTED} size={IconSize.XS} />
      {showTimeSelect && (
        <TextInput {...rest} readOnly value={viewableDate()} onClick={onClick} autoComplete="off" disabled={disabled} />
      )}
      {!showTimeSelect && (
        <DateInput
          {...rest}
          readOnly={!isClearable}
          required={needValue}
          value={viewableDate()}
          onClick={onClick}
          onKeyDown={onKeyDown}
          autoComplete="off"
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </Overlay>
  )
})
