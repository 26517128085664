import { Request } from 'commons/utils/request'
import { DownloadLink, Toast } from 'ui/components'

import { BatchXmlLinkProps } from './BatchXmlLink.types'

export const BatchXmlLink = (props: BatchXmlLinkProps) => {
  const { id, showDownloadBatchXML } = props

  if (showDownloadBatchXML) {
    return (
      <DownloadLink
        direct
        parametersForm={{ id, file_type: 'xml' }}
        baseUrl={Request.urls.bank.batchFileDownload}
        name={showDownloadBatchXML}
        handleError={(message) => Toast.error('Error', message)}
      >
        {showDownloadBatchXML}
      </DownloadLink>
    )
  }

  return null
}
