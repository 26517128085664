import { ApplicationStatus } from 'commons/types/enums'
import { Icon, IconName } from 'ui/components/Icon'

import { TimeLineProps } from './TimeLineProps'

import { Item, TimeLineBlock, Trigger, WatchWrapper } from './styles'

export const TimeLine = ({ status }: TimeLineProps) => {
  const statuses = [
    ApplicationStatus.RFI_PENDING,
    ApplicationStatus.RFI_REVIEW,
    ApplicationStatus.DOCUMENT_REQUEST_PENDING,
    ApplicationStatus.DOCUMENT_REQUEST_SUMBITTED,
    ApplicationStatus.DOCUMENT_REQUEST_REVIEW,
    ApplicationStatus.PENDING_CUSTOMER_REVIEW,
    ApplicationStatus.CUSTOMER
  ]

  const index = statuses.indexOf(status)

  return (
    <Trigger>
      <WatchWrapper>
        <Icon name={IconName.WATCH} />
        Show Timeline
      </WatchWrapper>
      <TimeLineBlock>
        <Item $active={index >= 0}>
          RFI
          <br />
          Pending
        </Item>
        {index > 0 ? <Icon name={IconName.DASHED_ARROW_DOWN_GREEN} /> : <Icon name={IconName.DASHED_ARROW_DOWN_GRAY} />}
        <Item $active={index >= 1}>
          RFI
          <br />
          Review
        </Item>
        {index > 1 ? <Icon name={IconName.DASHED_ARROW_UP_GREEN} /> : <Icon name={IconName.DASHED_ARROW_UP_GRAY} />}
        <Item $active={index >= 2}>
          Document Request
          <br />
          Pending
        </Item>
        {index > 2 ? <Icon name={IconName.DASHED_ARROW_DOWN_GREEN} /> : <Icon name={IconName.DASHED_ARROW_DOWN_GRAY} />}
        <Item $active={index >= 3}>
          Document Request
          <br />
          Submitted
        </Item>
        {index > 3 ? <Icon name={IconName.DASHED_ARROW_DOWN_GREEN} /> : <Icon name={IconName.DASHED_ARROW_DOWN_GRAY} />}
        <Item $active={index >= 4}>
          Document Request
          <br />
          Review
        </Item>
        {index > 4 ? <Icon name={IconName.DASHED_ARROW_UP_GREEN} /> : <Icon name={IconName.DASHED_ARROW_UP_GRAY} />}
        <Item $active={index >= 5}>
          Pending Customer
          <br />
          Review
        </Item>
        {index > 5 ? <Icon name={IconName.DASHED_ARROW_DOWN_GREEN} /> : <Icon name={IconName.DASHED_ARROW_DOWN_GRAY} />}
        <Item $active={index >= 6}>Customer</Item>
      </TimeLineBlock>
    </Trigger>
  )
}
