import { DateTime, Hr, Info, InfoSet, InfoSetDirection } from 'ui/components'
import { HeaderButton } from 'ui/components/Buttons'

import { GeneralInformationProps } from './GeneralInformation.types'

import { s } from './styles'

export const GeneralInformation = ({ status, createdAt, sentAt, openSignersModal }: GeneralInformationProps) => (
  <s.GeneralInformationWrapper id="general">
    <s.TitleWrapper>
      <h3>General Information</h3>
      <HeaderButton onClick={openSignersModal}>Signers</HeaderButton>
    </s.TitleWrapper>
    <Hr thin={true} />
    <InfoSet direction={InfoSetDirection.COLUMN}>
      <s.Row>
        <Info label="Status">{status}</Info>
        <Info label="Created">
          <DateTime date={createdAt} withTime />
        </Info>
      </s.Row>
      {!!sentAt && (
        <s.Row>
          <Info label="Sent to Sign">
            <DateTime date={sentAt} withTime />
          </Info>
        </s.Row>
      )}
    </InfoSet>
  </s.GeneralInformationWrapper>
)
